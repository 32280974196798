import { NavigationConfiguration } from '../../App/Main/components/Layout';

export const RANKINGS_CATEGORIZATION_MAIN_ROUTE = '/rankings/categorization';

export const RANKING_TYPES_MAIN_TABLE_ROUTE = '/rankings/categorization/ranking-types';

export const CATEGORIZATION_NAVIGATION: NavigationConfiguration = {
  rankingCategories: {
    exact: true,
    label: 'Ranking categories',
    route: '/ranking-categories',
  },
  rankingTypes: {
    exact: true,
    label: 'Ranking types',
    route: '/ranking-types',
  },
};

export const CATEGORIZATION_DETAILS_ROUTES: NavigationConfiguration = {
  rankingCategoryDetails: {
    label: 'Ranking category details',
    route: '/ranking-categories/:id',
  },
  rankingTypeDetails: {
    label: 'Ranking type details',
    route: '/ranking-types/:id',
  },
};

import { RootState } from '../../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const partnershipsSelector = (state: RootState) => state.schools.partnerships;

export const selectPartnershipsTableData = createDeepEqualSelector(partnershipsSelector, (partnerships) => {
  return partnerships.data;
});

export const selectPartnershipsListPageConfiguration = createDeepEqualSelector(partnershipsSelector, (partnerships) => {
  return {
    filter: partnerships.filter,
    limit: partnerships.pagination.limit,
    order: partnerships.sortOrder.order,
    page: partnerships.pagination.page,
    search: partnerships.search,
    sortBy: partnerships.sortOrder.sortBy,
    totalPages: partnerships.pagination.totalPages,
  };
});

import { CreateRankingDTO, EditRankingDTO, RankingDTO } from './ranking-profile.dto';

export const CREATE_RANKING = 'CREATE_RANKING';
export const LOAD_RANKING = 'LOAD_RANKING';
export const LOAD_RANKING_WELCOME_TEXT = 'LOAD_RANKING_WELCOME_TEXT';
export const LOAD_RANKING_PARTICIPATION_CRITERIA = 'LOAD_RANKING_PARTICIPATION_CRITERIA';
export const EDIT_RANKING = 'EDIT_RANKING';
export const EDIT_RANKING_DETAILS = 'EDIT_RANKING_DETAILS';
export const DELETE_RANKING = 'DELETE_RANKING';
export const CLEAR_CURRENT_RANKING = 'CLEAR_CURRENT_RANKING';
export const SET_RANKING = 'SET_RANKING';
export const SET_RANKING_WELCOME_TEXT = 'SET_RANKING_WELCOME_TEXT';
export const SET_RANKING_PARTICIPATION_CRITERIA = 'SET_RANKING_PARTICIPATION_CRITERIA';
export const PUBLISH_RANKING = 'PUBLISH_RANKING';

export type RankingProfileState = CreateRankingDTO | EditRankingDTO | RankingDTO;

export interface RankingProfileRouteParams {
  id: string;
}

export interface CreateRankingAction {
  type: typeof CREATE_RANKING;
  payload: { data: CreateRankingDTO };
}

export interface LoadRankingAction {
  type: typeof LOAD_RANKING;
  payload: { id: number };
}

export interface LoadRankingWelcomeTextAction {
  type: typeof LOAD_RANKING_WELCOME_TEXT;
  payload: { id: number; prev: boolean };
}

export interface LoadRankingParticipationCriteriaAction {
  type: typeof LOAD_RANKING_PARTICIPATION_CRITERIA;
  payload: { id: number; prev: boolean };
}

export interface EditRankingAction {
  type: typeof EDIT_RANKING;
  payload: { data: EditRankingDTO };
}

export interface EditRankingDetailsAction {
  type: typeof EDIT_RANKING_DETAILS;
  payload: { data: EditRankingDTO };
}

export interface DeleteRankingAction {
  type: typeof DELETE_RANKING;
  payload: { id: number };
}

export interface ClearCurrentRankingAction {
  type: typeof CLEAR_CURRENT_RANKING;
}

export interface SetRankingAction {
  type: typeof SET_RANKING;
  payload: { data: RankingDTO };
}

export interface SetRankingWelcomeTextAction {
  type: typeof SET_RANKING_WELCOME_TEXT;
  payload: { data: string };
}

export interface SetRankingParticipationCriteriaAction {
  type: typeof SET_RANKING_PARTICIPATION_CRITERIA;
  payload: { data: string };
}

export interface PublishRankingAction {
  type: typeof PUBLISH_RANKING;
  payload: { id: number };
}

export type RankingActionTypes =
  | ClearCurrentRankingAction
  | CreateRankingAction
  | DeleteRankingAction
  | EditRankingAction
  | LoadRankingAction
  | LoadRankingWelcomeTextAction
  | LoadRankingParticipationCriteriaAction
  | SetRankingAction
  | SetRankingWelcomeTextAction
  | SetRankingParticipationCriteriaAction
  | EditRankingDetailsAction
  | PublishRankingAction;

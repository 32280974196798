import { FiltersConfig } from '../../../../../components/FilterGroup';

export const SCHOOLS_WITH_LEAD_GEN_TABLE_FILTERS: FiltersConfig = {
  status: {
    title: 'Status',
    options: [],
  },
};

export const SCHOOLS_WITH_LEAD_GEN_FILTER_OPTIONS = [
  {
    key: 0,
    label: 'Active only',
  },
  {
    key: 1,
    label: 'Inactive only',
  },
  {
    key: 2,
    label: 'Expired only',
  },
];

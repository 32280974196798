import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectRequestFormErrors,
  selectRequestIsLoading,
  setRequestSucceededAction,
} from '../../../../shared/state/global-request';
import { EDIT_PRIVACY_DATA } from '../privacy.types';
import { PrivacyDto } from '../privacy.dto';
import { selectPrivacyConditions } from '../privacy.selectors';

import Typography, { TypographyVariants } from '../../../../components/Typography';
import Button, { ButtonSizes } from '../../../../components/Button';
import Form, { FormDataValues } from '../../../../components/Form';
import { getHtmlFromJSON, getRawFromHTML } from '../../../../components/RichTextEditor/RichTextEditor.helpers';
import { editPrivacyAction, loadPrivacyAction } from '../privacy.actions';
import { PRIVACY_POLICY_CONFIG } from './PrivacyPolicy.constants';

export const PrivacyPolicy: FunctionComponent = () => {
  const dispatch = useDispatch();
  const privacyPolicy = useSelector(selectPrivacyConditions);
  const isSaving = useSelector(selectRequestIsLoading(EDIT_PRIVACY_DATA));
  const formErrors = useSelector(selectRequestFormErrors(EDIT_PRIVACY_DATA));
  const [isEdit, setIsEdit] = useState(false);

  const __html = privacyPolicy.description ? getHtmlFromJSON(privacyPolicy.description) : '-';

  useEffect(() => {
    dispatch(loadPrivacyAction());
  }, [dispatch]);

  const updatePrivacyPolicy = (data: FormDataValues): void => {
    dispatch(editPrivacyAction(data as PrivacyDto));
    setIsEdit(false);
  };

  const resetFormState = (): void => {
    setIsEdit(false);
    dispatch(setRequestSucceededAction(EDIT_PRIVACY_DATA));
  };

  return (
    <>
      <div className="mba-heading--wrapper mba-heading--table">
        <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title">
          Privacy Policy
        </Typography>
        {!isEdit && (
          <Button text="Edit Privacy Policy" size={ButtonSizes.big} primary onClick={(): void => setIsEdit(true)} />
        )}
      </div>
      {isEdit ? (
        <div className=" mba-mb-20">
          <Form
            id="terms-form"
            config={PRIVACY_POLICY_CONFIG}
            isLoading={isSaving}
            onSubmit={updatePrivacyPolicy}
            submitButtonText="Save"
            actions={[
              {
                label: 'Cancel',
                onClick: resetFormState,
              },
            ]}
            initialValues={{ description: getRawFromHTML(privacyPolicy.description) }}
            errors={formErrors}
          />
        </div>
      ) : (
        <div dangerouslySetInnerHTML={{ __html }} />
      )}
    </>
  );
};

import {
  CLEAR_CURRENT_SCHOOL_USER,
  CREATE_SCHOOL_USER,
  SchoolUserActionTypes,
  SchoolUserProfileState,
  SET_SCHOOL_USER,
} from './school-user-profile.types';

const initialState: SchoolUserProfileState = {} as SchoolUserProfileState;

const schoolUserProfileReducer = (state = initialState, action: SchoolUserActionTypes): SchoolUserProfileState => {
  switch (action.type) {
    case CLEAR_CURRENT_SCHOOL_USER: {
      return initialState;
    }
    case CREATE_SCHOOL_USER: {
      return {
        ...action.payload.data,
      };
    }
    case SET_SCHOOL_USER: {
      return action.payload.data;
    }
    default: {
      return state;
    }
  }
};

export default schoolUserProfileReducer;

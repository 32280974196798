import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SurveyStep, { SurveyStepProps } from './SurveyStep';
import { SurveyLinksDTO, UPLOAD_SURVEY_LINKS, uploadSurveyLinksAction } from '../../RankingDetails/TimelineSteps';
import FileUpload from '../../../../../../components/FileUpload';
import { MODAL_ANIMATION_DURATION } from '../../../../../../components/Modal';
import {
  selectRequestErrorMessage,
  selectRequestIsLoading,
  selectRequestStatus,
  setRequestSucceededAction,
} from '../../../../../../shared/state/global-request';

const SchoolSurveyStep: FunctionComponent<SurveyStepProps> = (props) => {
  const { rankingId } = props;
  const dispatch = useDispatch();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const errors = useSelector(selectRequestErrorMessage(UPLOAD_SURVEY_LINKS));
  const { isSuccess } = useSelector(selectRequestStatus(UPLOAD_SURVEY_LINKS));
  const isLoading = useSelector(selectRequestIsLoading(UPLOAD_SURVEY_LINKS));

  useEffect(() => {
    if (isSuccess && isUploadModalOpen) {
      closeUploadModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const uploadSurveyLinks = (file: File): void => {
    dispatch(setRequestSucceededAction(UPLOAD_SURVEY_LINKS));

    const formData = new FormData();
    formData.append('file', file);

    const spreadsheetData: SurveyLinksDTO = { rankingId, spreadsheet: formData, type: 'school' };
    dispatch(uploadSurveyLinksAction(spreadsheetData));
  };

  const closeUploadModal = (): void => {
    setIsUploadModalOpen(false);

    setTimeout(() => {
      dispatch(setRequestSucceededAction(UPLOAD_SURVEY_LINKS));
    }, MODAL_ANIMATION_DURATION);
  };

  return (
    <>
      <SurveyStep {...props} triggerUploadModal={setIsUploadModalOpen} />
      <FileUpload
        title="Upload the survey links"
        isLoading={isLoading}
        isOpen={isUploadModalOpen}
        onClose={closeUploadModal}
        onUpload={uploadSurveyLinks}
        errorMessage={errors}
      />
    </>
  );
};

export default SchoolSurveyStep;

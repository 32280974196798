import { RankingFieldDTO } from './ranking-field-details.dto';
import { del, get, post, put } from '../../../../../../../shared/api';
import { BasicResponseDTO, DefaultResponseDTO } from '../../../../../../../shared/constants';

export const createColumnRequest = (rankingId: number, data: RankingFieldDTO): Promise<BasicResponseDTO> => {
  return post<BasicResponseDTO, RankingFieldDTO>(`/ranking/${rankingId}/fields`, data);
};

export const getColumnRequest = (
  rankingId: number,
  rankingFieldId: number,
): Promise<DefaultResponseDTO<RankingFieldDTO>> => {
  return get<DefaultResponseDTO<RankingFieldDTO>>(`/ranking/${rankingId}/fields/${rankingFieldId}`);
};

export const updateColumnRequest = (rankingId: number, columnData: RankingFieldDTO): Promise<BasicResponseDTO> => {
  const { id } = columnData;
  return put<BasicResponseDTO, RankingFieldDTO>(`/ranking/${rankingId}/fields/${id}`, columnData);
};

export const deleteColumnRequest = (rankingId: number, rankingFieldId: number): Promise<BasicResponseDTO> => {
  return del<BasicResponseDTO>(`/ranking/${rankingId}/fields/${rankingFieldId}`);
};

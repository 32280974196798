import { CreateRankingDTO, EditRankingDTO, RankingDTO } from './ranking-profile.dto';
import {
  CLEAR_CURRENT_RANKING,
  ClearCurrentRankingAction,
  CREATE_RANKING,
  CreateRankingAction,
  DELETE_RANKING,
  DeleteRankingAction,
  EDIT_RANKING,
  EditRankingAction,
  EDIT_RANKING_DETAILS,
  LOAD_RANKING,
  LoadRankingAction,
  SET_RANKING,
  SetRankingAction,
  EditRankingDetailsAction,
  SetRankingWelcomeTextAction,
  SET_RANKING_WELCOME_TEXT,
  LOAD_RANKING_WELCOME_TEXT,
  LoadRankingWelcomeTextAction,
  LoadRankingParticipationCriteriaAction,
  LOAD_RANKING_PARTICIPATION_CRITERIA,
  SetRankingParticipationCriteriaAction,
  SET_RANKING_PARTICIPATION_CRITERIA,
  PUBLISH_RANKING,
  PublishRankingAction,
} from './ranking-profile.types';

export const createNewRankingAction = (data: CreateRankingDTO): CreateRankingAction => ({
  type: CREATE_RANKING,
  payload: { data },
});

export const loadRankingAction = (id: number): LoadRankingAction => ({
  type: LOAD_RANKING,
  payload: { id },
});

export const loadRankingWelcomeTextAction = (id: number, prev = false): LoadRankingWelcomeTextAction => ({
  type: LOAD_RANKING_WELCOME_TEXT,
  payload: { id, prev },
});

export const loadRankingParticipationCriteriaAction = (
  id: number,
  prev = false,
): LoadRankingParticipationCriteriaAction => ({
  type: LOAD_RANKING_PARTICIPATION_CRITERIA,
  payload: { id, prev },
});

export const editRankingAction = (data: EditRankingDTO): EditRankingAction => ({
  type: EDIT_RANKING,
  payload: { data },
});

export const editRankingDetailsAction = (data: EditRankingDTO): EditRankingDetailsAction => ({
  type: EDIT_RANKING_DETAILS,
  payload: { data },
});

export const deleteRankingAction = (id: number): DeleteRankingAction => ({
  type: DELETE_RANKING,
  payload: { id },
});

export const setRankingAction = (data: RankingDTO): SetRankingAction => ({
  type: SET_RANKING,
  payload: { data },
});

export const setRankingWelcomeTextAction = (data: string): SetRankingWelcomeTextAction => ({
  type: SET_RANKING_WELCOME_TEXT,
  payload: { data },
});

export const setRankingParticipationCriteriaAction = (data: string): SetRankingParticipationCriteriaAction => ({
  type: SET_RANKING_PARTICIPATION_CRITERIA,
  payload: { data },
});

export const clearCurrentRankingAction = (): ClearCurrentRankingAction => ({
  type: CLEAR_CURRENT_RANKING,
});

export const publishRankingAction = (id: number): PublishRankingAction => ({
  type: PUBLISH_RANKING,
  payload: { id },
});

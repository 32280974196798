import queryString from 'query-string';
import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';

import {
  COMMON_ORIGAMI_COMPONENTS,
  INTERNAL_ORIGAMI_COMPONENTS,
  ORIGAMI_CSS_URL,
  ORIGAMI_JS_URL,
} from './Main.constants';
import { checkForValidToken, redirectUserToSSO, selectIsTokenVerified, VALIDATE_USER_TOKEN } from '../../Auth';
import Layout from './Layout';
import PageLoader from '../../../../components/PageLoader';
import { JWT_TOKEN_COOKIE_NAME } from '../../../../shared/constants';
import { mapOrigamiComponentsToQuery } from '../../../../shared/helpers';
import { getCookie } from '../../../../shared/web-storage';
import { selectRequestIsLoading } from '../../../../shared/state/global-request';

const cookieToken = getCookie(JWT_TOKEN_COOKIE_NAME);
const commonComponent = mapOrigamiComponentsToQuery(COMMON_ORIGAMI_COMPONENTS);
const internalComponent = mapOrigamiComponentsToQuery(INTERNAL_ORIGAMI_COMPONENTS);

const Main: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isTokenVerified = useSelector(selectIsTokenVerified);
  const isLoading = useSelector(selectRequestIsLoading(VALIDATE_USER_TOKEN));
  const { token: newToken } = queryString.parse(location.search);

  useEffect(() => {
    if (!isTokenVerified) {
      if (cookieToken) {
        dispatch(checkForValidToken(cookieToken as string));
      } else if (newToken) {
        dispatch(checkForValidToken(newToken as string));
      } else {
        dispatch(redirectUserToSSO());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTokenVerified, cookieToken]);

  return (
    <HelmetProvider>
      <Helmet>
        <link rel="stylesheet" href={`${ORIGAMI_CSS_URL}${commonComponent}&brand=internal&system_code=mba-rankings`} />
        <link
          rel="stylesheet"
          href={`${ORIGAMI_CSS_URL}${internalComponent}&brand=internal&system_code=mba-rankings`}
        />
        <script src={`${ORIGAMI_JS_URL}${internalComponent}&system_code=mba-rankings`} defer />
        <title>Business school rankings from the Financial Times - FT.com</title>
      </Helmet>
      <ToastProvider placement="bottom-right" autoDismiss={true}>
        <Layout />
      </ToastProvider>
      {isLoading && <PageLoader />}
    </HelmetProvider>
  );
};

export default Main;

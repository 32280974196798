import { getTableListDefaultState } from '../../../../shared/helpers';
import {
  LOAD_SCHOOL_USERS_FOR_SCHOOL,
  SchoolUsersActionTypes,
  SET_SCHOOL_USERS_FOR_SCHOOL,
} from './school-users.types';
import { SchoolUserDTO } from '../../SchoolUserProfile';
import { TableListData } from '../../../../shared/constants';

const initialState: TableListData<SchoolUserDTO> = getTableListDefaultState<SchoolUserDTO>();

const schoolUsersReducer = (state = initialState, action: SchoolUsersActionTypes): TableListData<SchoolUserDTO> => {
  switch (action.type) {
    case LOAD_SCHOOL_USERS_FOR_SCHOOL: {
      return {
        ...state,
        filter: action.payload.pageConfig?.filter || {},
        search: action.payload.pageConfig?.search || '',
      };
    }
    case SET_SCHOOL_USERS_FOR_SCHOOL: {
      return {
        ...state,
        ...action.payload.tableData,
      };
    }
    default: {
      return state;
    }
  }
};

export default schoolUsersReducer;

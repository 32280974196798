import { call, CallEffect, put, PutEffect, select, SelectEffect, takeEvery, takeLatest } from 'redux-saga/effects';

import { loadUsersAction, setUsersAction } from './users.actions';
import { createUserRequest, deleteUserRequest, getUsersRequest, updateUserRequest } from './users.api';
import { UserDTO } from './users.dto';
import { selectUsersPageConfiguration } from './users.selectors';
import {
  CLEAR_CURRENT_USER,
  CREATE_USER,
  CreateUserAction,
  DELETE_USER,
  DeleteUserAction,
  EDIT_USER,
  EditUserAction,
  LOAD_USERS,
  LoadUsersAction,
  UserActionTypes,
  UsersActionTypes,
} from './users.types';
import {
  BasicResponseDTO,
  DefaultResponseDTO,
  PagedResponseDTO,
  PageSearchQueryConfig,
} from '../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../shared/state/global-request';

function* loadUsersTableData(
  action: LoadUsersAction,
): Generator<CallEffect<PagedResponseDTO<UserDTO[]>> | PutEffect<UsersActionTypes> | PutEffect<GlobalRequestActions>> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig } = action.payload;
    const { data, pagination, sortOrder } = (yield call(getUsersRequest, pageConfig)) as PagedResponseDTO<UserDTO[]>;
    yield put(setUsersAction({ data, pagination, sortOrder }));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* createNewUserSaga(
  action: CreateUserAction,
): Generator<
  PutEffect<GlobalRequestActions | UserActionTypes | UsersActionTypes> | CallEffect<DefaultResponseDTO<UserDTO>>
> {
  const {
    type: actionType,
    payload: { data },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(createUserRequest, data);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadUsersAction());
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* editUserSaga(
  action: EditUserAction,
): Generator<PutEffect<GlobalRequestActions | UsersActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect> {
  const {
    type: actionType,
    payload: { data },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateUserRequest, data);
    yield put(setRequestSucceededAction(actionType));
    const pageConfig = (yield select(selectUsersPageConfiguration)) as PageSearchQueryConfig;
    yield put(loadUsersAction(pageConfig));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* deleteUserSaga(
  action: DeleteUserAction,
): Generator<PutEffect<GlobalRequestActions | UsersActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect> {
  const {
    type: actionType,
    payload: { id },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(deleteUserRequest, id);
    yield put(setRequestSucceededAction(actionType));
    const pageConfig = (yield select(selectUsersPageConfiguration)) as PageSearchQueryConfig;
    yield put(loadUsersAction(pageConfig));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* resetUserErrors(): Generator<PutEffect<GlobalRequestActions>> {
  yield put(setRequestSucceededAction(CREATE_USER));
  yield put(setRequestSucceededAction(EDIT_USER));
  yield put(setRequestSucceededAction(DELETE_USER));
}

export default function* usersSaga(): Generator {
  yield takeLatest(CREATE_USER, createNewUserSaga);
  yield takeLatest(EDIT_USER, editUserSaga);
  yield takeLatest(DELETE_USER, deleteUserSaga);
  yield takeEvery(CLEAR_CURRENT_USER, resetUserErrors);
  yield takeLatest(LOAD_USERS, loadUsersTableData);
}

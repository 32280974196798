import { RadioInputsOption } from '../RadioInputs';
import { SelectOption, SelectOptionType } from '../Select';
import { CheckboxInputOption } from '../Checkbox';
import { GridOffsetType } from '../Grid/Grid';

export type FormValue = boolean | number | string | (number | string)[];

export interface FormDataValues {
  [key: string]: FormValue;
}

export interface FormErrors {
  [key: string]: string[];
}

export const FORM_GROUP_TYPE = 'formGroupTitle';

export type FormControlFieldType =
  | 'checkbox'
  | 'date'
  | 'staticLink'
  | 'month'
  | 'formGroupTitle'
  | 'radio'
  | 'select'
  | 'text'
  | 'textarea'
  | 'richText'
  | 'phone'
  | 'email'
  | 'hidden'
  | 'number';

export interface FormControlValidationProps {
  errorText: string;
}

export interface FormControlRegexValidationProps extends FormControlValidationProps {
  rule: RegExp;
}
export interface MinMaxNumberFormControlValidationProps extends FormControlValidationProps {
  minValue?: number;
  maxValue?: number;
}

export interface FormControlValidations {
  required?: FormControlValidationProps;
  regex?: FormControlRegexValidationProps;
  minMaxValue?: MinMaxNumberFormControlValidationProps;
}

export interface FormControl {
  id?: string;
  containerClassName?: string;
  className?: string;
  disabled?: boolean;
  error?: string;
  fieldType?: FormControlFieldType;
  inline?: boolean;
  label?: string;
  multiple?: boolean;
  name?: string;
  options?: (SelectOption | RadioInputsOption | CheckboxInputOption)[];
  placeholder?: string;
  previewFormat?: string;
  required?: boolean;
  rows?: number;
  searchable?: boolean;
  shrunken?: boolean;
  subTitle?: string;
  title?: string;
  validations?: FormControlValidations;
  value?: FormValue;
  hideRemoveButton?: boolean;
  async?: boolean;
  isFileUploadEnabled?: boolean;
  onRequestOptions?: (query: string) => Promise<SelectOptionType[]>;
  offset?: GridOffsetType;
  customDateFormat?: string;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  timeIntervals?: number;
}

export interface FormConfig {
  [key: string]: FormControl;
}

export interface FormGroup {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any[];
}

export interface FormControlProps extends FormControl {
  error?: string;
  touched: boolean;
  valid: boolean;
}

export type FormConfigProps = {
  [key: string]: FormControlProps | FormConfigProps;
};

export interface FormControlElementProps extends FormControlProps {
  id: string;
  name: string;
}

export interface GroupFieldKeyParts {
  baseGroupName: string;
  index: number;
  baseFieldName: string;
}

export type ActionButtonHandler = (groupName: string, index: number) => void;

import { CREATE_RANKING_FIELD, EDIT_RANKING_FIELD } from '../../RankingFieldsList/components/RankingFieldsList';
import { RankingFieldDTO } from './ranking-field-details.dto';
import {
  LoadRankingFieldAction,
  EditRankingFieldDetailsAction,
  SetRankingFieldAction,
  LOAD_RANKING_FIELD,
  EDIT_RANKING_FIELD_DETAILS,
  SET_RANKING_FIELD,
  DeleteRankingFieldAction,
  DELETE_RANKING_FIELD,
  CreateRankingFieldAction,
  EditRankingFieldAction,
} from './ranking-field-details.types';

export const createRankingFieldAction = (rankingId: number, data: RankingFieldDTO): CreateRankingFieldAction => ({
  type: CREATE_RANKING_FIELD,
  payload: { rankingId, data },
});

export const editRankingFieldAction = (rankingId: number, data: RankingFieldDTO): EditRankingFieldAction => ({
  type: EDIT_RANKING_FIELD,
  payload: { rankingId, data },
});

export const loadRankingFieldAction = (rankingId: number, rankingFieldId: number): LoadRankingFieldAction => ({
  type: LOAD_RANKING_FIELD,
  payload: { rankingId, rankingFieldId },
});

export const editRankingFieldDetailsAction = (
  rankingId: number,
  data: RankingFieldDTO,
): EditRankingFieldDetailsAction => ({
  type: EDIT_RANKING_FIELD_DETAILS,
  payload: { rankingId, data },
});

export const setRankingFieldAction = (data: RankingFieldDTO): SetRankingFieldAction => ({
  type: SET_RANKING_FIELD,
  payload: { data },
});

export const deleteRankingFieldAction = (rankingId: number, rankingFieldId: number): DeleteRankingFieldAction => ({
  type: DELETE_RANKING_FIELD,
  payload: { rankingId, rankingFieldId },
});

import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  loadRankingTypesAction,
  RANKING_TYPES_TABLE_COLUMN_DEFINITION,
  selectRankingTypesPageConfiguration,
  selectRankingTypesTableData,
} from '../../../RankingTypesList';
import Pager, { INITIAL_PAGE } from '../../../../../../components/Pager';
import Table from '../../../../../../components/Table';
import { ASCENDING_ORDER_DIRECTION, DESCENDING_ORDER_DIRECTION } from '../../../../../../shared/constants';

type RankingTypesTableProps = {
  rankingCategoryId?: number;
};

const RankingTypesTable: FunctionComponent<RankingTypesTableProps> = ({ rankingCategoryId }) => {
  const dispatch = useDispatch();
  const pageConfig = useSelector(selectRankingTypesPageConfiguration);
  const rankingTypesData = useSelector(selectRankingTypesTableData);

  useEffect(() => {
    if (rankingCategoryId) {
      dispatch(
        loadRankingTypesAction({
          ...pageConfig,
          filter: { categoryId: [rankingCategoryId.toString()] },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, rankingCategoryId]);

  const handlePageChanged = (page: number): void => {
    dispatch(loadRankingTypesAction({ ...pageConfig, page }));
  };

  const handleSortByChanged = (fieldName: string): void => {
    const orderDirection =
      pageConfig.sortBy !== fieldName
        ? DESCENDING_ORDER_DIRECTION
        : pageConfig.order === ASCENDING_ORDER_DIRECTION
        ? DESCENDING_ORDER_DIRECTION
        : ASCENDING_ORDER_DIRECTION;
    dispatch(loadRankingTypesAction({ ...pageConfig, sortBy: fieldName, order: orderDirection }));
  };

  return (
    <>
      <Table
        id="ranking-types-list"
        columns={RANKING_TYPES_TABLE_COLUMN_DEFINITION}
        data={rankingTypesData}
        headingOptions={{ heading: 'Ranking Types' }}
        sortingOptions={{
          order: pageConfig.order || DESCENDING_ORDER_DIRECTION,
          sortBy: pageConfig.sortBy || '',
        }}
        onSortByColumn={handleSortByChanged}
      />
      {pageConfig && pageConfig.totalPages !== INITIAL_PAGE && (
        <Pager
          currentPage={pageConfig.page}
          totalPages={pageConfig.totalPages || INITIAL_PAGE}
          onPageChange={handlePageChanged}
        />
      )}
    </>
  );
};

export default RankingTypesTable;

import { FormConfig } from '../../../../../components/Form';

export const FAQ_FORM_CONFIG: FormConfig = {
  categoryTitle: {
    label: 'Title',
    placeholder: 'Enter FAQ title',
    fieldType: 'textarea',
    required: true,
  },
  description: {
    label: 'Description',
    placeholder: 'Type Description...',
    fieldType: 'richText',
    required: true,
    isFileUploadEnabled: true,
  },
};

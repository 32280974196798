import { CreateUserDTO, EditUserDTO, UserDTO } from './users.dto';
import {
  CLEAR_CURRENT_USER,
  ClearCurrentUserAction,
  CREATE_USER,
  CreateUserAction,
  DELETE_USER,
  DeleteUserAction,
  EDIT_USER,
  EditUserAction,
  LOAD_USERS,
  LoadUsersAction,
  SET_USER,
  SET_USERS,
  SetUserAction,
  SetUsersAction,
} from './users.types';
import { PageSearchQueryConfig, TableListData } from '../../../shared/constants';

export const createNewUserAction = (data: CreateUserDTO): CreateUserAction => ({
  type: CREATE_USER,
  payload: { data },
});

export const editUserAction = (data: EditUserDTO): EditUserAction => ({
  type: EDIT_USER,
  payload: { data },
});

export const deleteUserAction = (id: number): DeleteUserAction => ({
  type: DELETE_USER,
  payload: { id },
});

export const setUserAction = (data: UserDTO): SetUserAction => ({
  type: SET_USER,
  payload: { data },
});

export const clearCurrentUserAction = (): ClearCurrentUserAction => ({
  type: CLEAR_CURRENT_USER,
});

export const loadUsersAction = (pageConfig?: PageSearchQueryConfig): LoadUsersAction => ({
  type: LOAD_USERS,
  payload: { pageConfig },
});

export const setUsersAction = (tableData: TableListData<UserDTO>): SetUsersAction => ({
  type: SET_USERS,
  payload: { tableData },
});

import { RankingTimelineStepDTO, SaveRankingTimelineStepDTO } from './ranking-timeline-steps.dto';
import { del, get, post, put } from '../../../../../../shared/api';
import { BasicResponseDTO, DefaultResponseDTO } from '../../../../../../shared/constants';

export const getRankingTimelineStepsRequest = (
  rankingId: number,
): Promise<DefaultResponseDTO<RankingTimelineStepDTO[]>> => {
  return get<DefaultResponseDTO<RankingTimelineStepDTO[]>>(`/rankings/${rankingId}/timeline`);
};

export const editRankingTimelineStepRequest = (
  rankingId: number,
  stepData: SaveRankingTimelineStepDTO,
): Promise<BasicResponseDTO> => {
  const { id: stepId } = stepData;
  return put<BasicResponseDTO, SaveRankingTimelineStepDTO>(`/rankings/${rankingId}/timeline/${stepId}`, stepData);
};

export const downloadSpreadsheetTemplateRequest = (rankingId: number, type: string): Promise<BasicResponseDTO> => {
  return get<BasicResponseDTO>(`/rankings/${rankingId}/spreadsheets/templates/${type}`, {
    responseType: 'blob',
  });
};

export const uploadSpreadsheetRequest = (
  rankingId: number,
  spreadsheet: FormData,
  type: string,
): Promise<BasicResponseDTO> => {
  return post<BasicResponseDTO, FormData>(`/rankings/${rankingId}/timeline/${type}`, spreadsheet);
};

export const uploadSurveyLinksRequest = (rankingId: number, spreadsheet: FormData): Promise<BasicResponseDTO> => {
  return post<BasicResponseDTO, FormData>(`/rankings/${rankingId}/survey-links`, spreadsheet);
};

export const downloadSpreadsheetRequest = (rankingId: number, type: string): Promise<BasicResponseDTO> => {
  return get<BasicResponseDTO>(`/rankings/${rankingId}/timeline/${type}/spreadsheet`, {
    responseType: 'blob',
  });
};

export const uploadAttachmentRequest = (
  rankingId: number,
  spreadsheet: FormData,
  stepId: number,
): Promise<BasicResponseDTO> => {
  return post<BasicResponseDTO, FormData>(`/rankings/${rankingId}/timeline/${stepId}/attachment`, spreadsheet);
};

export const deleteAttachmentRequest = (rankingId: number, stepId: number): Promise<BasicResponseDTO> => {
  return del<BasicResponseDTO>(`/rankings/${rankingId}/timeline/${stepId}/attachment`);
};

export const downloadAttachmentRequest = (rankingId: number, stepId: number): Promise<Blob> => {
  return get<Blob>(`/rankings/${rankingId}/timeline/${stepId}/attachment`, {
    responseType: 'blob',
  });
};

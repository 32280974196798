import {
  RankingFieldDataItemDTO,
  LoadRankingFieldDataAction,
  LOAD_RANKING_FIELD_DATA,
  SetRankingFieldDataAction,
  SET_RANKING_FIELD_DATA,
  EDIT_RANKING_DATA_ITEM,
  EditRankingFieldDataItemAction,
} from './../RankingFieldData';
import { PageSearchQueryConfig, TableListData } from '../../../../../../../shared/constants';

export const loadRankingFieldDataAction = (
  rankingFieldId: number,
  pageConfig?: PageSearchQueryConfig,
): LoadRankingFieldDataAction => ({
  type: LOAD_RANKING_FIELD_DATA,
  payload: { rankingFieldId, pageConfig },
});

export const setRankingFieldDataAction = (data: TableListData<RankingFieldDataItemDTO>): SetRankingFieldDataAction => ({
  type: SET_RANKING_FIELD_DATA,
  payload: { data },
});

export const editRankingFieldDataItemAction = (
  rankingFieldId: number,
  rankingFieldDataItemId: number,
  value: number,
): EditRankingFieldDataItemAction => ({
  type: EDIT_RANKING_DATA_ITEM,
  payload: { rankingFieldId, rankingFieldDataItemId, value },
});

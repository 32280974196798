import { RankingCalendarDto, RankingTypeOptionsDTO } from '../calendar.dto';
import { FormConfig, FormDataValues } from '../../../../../components/Form';

export const CALENDAR_FORM_CONFIG = (index: number, options: RankingTypeOptionsDTO[]): FormConfig => ({
  [`calendar[${index}][id]`]: {
    label: '',
    placeholder: '',
    fieldType: 'hidden',
  },
  [`calendar[${index}][rankingTypeId]`]: {
    label: 'Name',
    className: 'mba-calendar-edit-main-field',
    placeholder: 'Ranking type name',
    fieldType: 'select',
    inline: false,
    hideRemoveButton: false,
    options,
    required: true,
    searchable: true,
    validations: {
      required: {
        errorText: `Please choose a ranking type!`,
      },
    },
  },
  [`calendar[${index}][openDate]`]: {
    label: 'Open',
    placeholder: 'Open date',
    fieldType: 'month',
    inline: true,
    required: true,
    validations: {
      required: {
        errorText: `Please select open date!`,
      },
    },
  },
  [`calendar[${index}][publicationDate]`]: {
    label: 'Publication',
    placeholder: 'Publication date',
    fieldType: 'month',
    inline: true,
    required: true,
    validations: {
      required: {
        errorText: `Please open publication date!`,
      },
    },
  },
});

export const CREATE_RANKING_CALENDAR_CONFIG: FormConfig = {
  rankingTypeId: {
    label: 'Name',
    placeholder: 'Ranking type name',
    fieldType: 'select',
    options: [],
    required: true,
    searchable: true,
    validations: {
      required: {
        errorText: `Please choose a ranking type!`,
      },
    },
  },
  openDate: {
    label: 'Open',
    placeholder: 'Open date',
    fieldType: 'month',
    inline: true,
    required: true,
    validations: {
      required: {
        errorText: `Please select open date!`,
      },
    },
  },
  publicationDate: {
    label: 'Publication',
    placeholder: 'Publication date',
    fieldType: 'month',
    inline: true,
    required: true,
    validations: {
      required: {
        errorText: `Please open publication date!`,
      },
    },
  },
};

export const CALENDAR_FORM_INITIAL_DATA = (index: number, calendar: RankingCalendarDto): FormDataValues => ({
  [`calendar[${index}][id]`]: calendar.id,
  [`calendar[${index}][rankingTypeId]`]: calendar.rankingTypeId,
  [`calendar[${index}][openDate]`]: calendar.openDate,
  [`calendar[${index}][publicationDate]`]: calendar.publicationDate,
});

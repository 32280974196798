import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button, { ButtonSizes } from '../../../../../components/Button';
import EditableGroup from '../../../../../components/EditableGroup';
import { FormDataValues } from '../../../../../components/Form';
import Modal, { MODAL_ANIMATION_DURATION } from '../../../../../components/Modal';
import Typography, { TypographyVariants } from '../../../../../components/Typography';
import {
  selectRequestErrorMessage,
  selectRequestErrors,
  selectRequestIsLoading,
  setRequestSucceededAction,
} from '../../../../../shared/state/global-request';
import { loadResourcesAction } from '../../../../../shared/state/resources';
import { loadStatusesAction, selectStatuses } from '../../../../../shared/state/statuses';
import CampusesList from '../../CampusesList';
import { deleteSchoolAction, editSchoolDetailsAction } from '../school-details.actions';
import { EditSchoolDTO, SchoolDTO } from '../school-details.dto';
import { selectCurrentSchool } from '../school-details.selectors';
import { DELETE_SCHOOL, EDIT_SCHOOL_DETAILS } from '../school-details.types';
import SchoolAccreditations from './SchoolAccreditations';
import SchoolDeleteConfirmation from './SchoolDeleteConfirmation';
import { SCHOOL_DETAILS_CONFIG } from './SchoolDetails.constants';
import SchoolLogo from './SchoolLogo';

const SchoolDetails: FunctionComponent = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectRequestIsLoading(EDIT_SCHOOL_DETAILS));
  const currentSchool = useSelector(selectCurrentSchool) as SchoolDTO;
  const formErrors = useSelector(selectRequestErrors(EDIT_SCHOOL_DETAILS));
  const schoolStatuses = useSelector(selectStatuses('school'));
  const deleteSchoolError = useSelector(selectRequestErrorMessage(DELETE_SCHOOL));
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const { allowDelete, defaultCampusId, id: schoolId, name, accreditations, logo } = currentSchool as SchoolDTO;
  useEffect(() => {
    dispatch(loadResourcesAction('accreditations'));
    dispatch(loadStatusesAction('campus'));
    dispatch(loadStatusesAction('school'));
  }, [dispatch]);

  const handleDeleteSchoolConfirm = (): void => {
    dispatch(deleteSchoolAction(schoolId));
  };

  const openModal = (): void => {
    setIsConfirmPopupOpen(true);
  };

  const closeModal = (): void => {
    setIsConfirmPopupOpen(false);

    setTimeout(() => {
      dispatch(setRequestSucceededAction(DELETE_SCHOOL));
    }, MODAL_ANIMATION_DURATION);
  };

  const updateSchoolDetails = (data: FormDataValues): void => {
    dispatch(editSchoolDetailsAction({ ...data, id: schoolId } as EditSchoolDTO));
  };

  const resetFormState = (): void => {
    dispatch(setRequestSucceededAction(EDIT_SCHOOL_DETAILS));
  };

  const schoolDetailsFormConfig = {
    ...SCHOOL_DETAILS_CONFIG,
    status: {
      ...SCHOOL_DETAILS_CONFIG.status,
      options: schoolStatuses.map(({ key, label }) => ({ label, value: key })),
    },
  };

  return (
    <>
      <EditableGroup<SchoolDTO>
        columnSize={6}
        data={currentSchool}
        editButtonLabel="Edit details"
        formConfig={schoolDetailsFormConfig}
        formErrors={formErrors}
        isLoading={isLoading}
        heading={
          <Typography component="p" variant={TypographyVariants.h3} className="mba-heading--title-2">
            Details
          </Typography>
        }
        onEditSubmit={updateSchoolDetails}
        onEditCancel={resetFormState}
        prefixColumn={{
          content: <SchoolLogo image={logo} />,
          columnSize: { xs: 12, md: 4, lg: 3 },
        }}
      />
      <hr className="mba-separator" />
      <SchoolAccreditations
        schoolId={schoolId}
        schoolAccreditations={accreditations?.map((accreditation) => +accreditation.key)}
      />
      <hr className="mba-separator" />
      {schoolId && <CampusesList defaultCampusId={defaultCampusId || 0} schoolId={schoolId} />}
      {allowDelete && (
        <>
          <hr className="mba-separator" />
          <div className="mba-heading--wrapper mba-heading--table">
            <Typography component="p" variant={TypographyVariants.h3} className="mba-heading--title-2">
              Danger zone
            </Typography>
            <Button primary danger text="Delete school" size={ButtonSizes.big} onClick={openModal} />
          </div>
          <br />
          <br />
          <Modal title="Delete a school" isOpen={isConfirmPopupOpen} onClose={closeModal}>
            <SchoolDeleteConfirmation
              id={schoolId}
              error={deleteSchoolError}
              name={name}
              onCancel={closeModal}
              onConfirm={handleDeleteSchoolConfirm}
            />
          </Modal>
        </>
      )}
    </>
  );
};

export default SchoolDetails;

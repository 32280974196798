import { PartnershipDataRequestDTO, PartnershipDTO } from './partnerships.types';
import { BasicResponseDTO, PagedResponseDTO, PageSearchQueryConfig } from '../../../../shared/constants';
import { generateSearchQuery } from '../../../../shared/helpers';
import { del, get, put } from '../../../../shared/api';

export const getPartnerships = (
  schoolId: number,
  pageConfig: PageSearchQueryConfig = {},
): Promise<PagedResponseDTO<PartnershipDTO[]>> => {
  const query = generateSearchQuery(pageConfig);
  return get<PagedResponseDTO<PartnershipDTO[]>>(`/schools/${schoolId}/partnerships${query}`);
};

export const getPartnerSchools = (
  schoolId: number,
  pageConfig: PageSearchQueryConfig = {},
): Promise<PagedResponseDTO<PartnershipDTO[]>> => {
  const query = generateSearchQuery(pageConfig);
  return get<PagedResponseDTO<PartnershipDTO[]>>(`/schools/${schoolId}/partners${query}`);
};

export const removePartnershipRequest = (schoolId: number, joinedSchoolId: number): Promise<BasicResponseDTO> => {
  return del<BasicResponseDTO>(`/schools/${schoolId}/partnerships/${joinedSchoolId}`);
};

export const addSchoolToPartnershipRequest = (schoolId: number, joinedSchoolId: number): Promise<BasicResponseDTO> => {
  return put<BasicResponseDTO, PartnershipDataRequestDTO>(`/schools/${schoolId}/partnerships`, { joinedSchoolId });
};

import React, { FunctionComponent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form, { FormDataValues } from '../../../../components/Form';
import { selectRequestFormErrors, selectRequestIsLoading } from '../../../../shared/state/global-request';
import { addMessageAction } from '../onsite-messaging.actions';
import { AddMessageDto } from '../onsite-messaging.dto';
import { ADD_MESSAGE } from '../onsite-messaging.types';
import { CREATE_MESSAGE_FORM_CONFIG } from './CreateMessageForm.constants';

import styles from '../OnsiteMessaging.module.scss';
import moment from 'moment';

type Props = {
  onFormClose: () => void;
};

const CreateMessageForm: FunctionComponent<Props> = ({ onFormClose }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectRequestIsLoading(ADD_MESSAGE));
  const formErrors = useSelector(selectRequestFormErrors(ADD_MESSAGE));
  const [formData, setFormData] = useState({} as FormDataValues);
  const errorCount = Object.keys(formErrors).length;

  const handleChange = (values: FormDataValues): void => {
    setFormData(values);
  };

  const onClose = useCallback((): void => {
    setFormData({} as FormDataValues);
    onFormClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, onFormClose]);

  const createMessage = (data: FormDataValues): void => {
    const messageData = {
      ...data,
      validFrom: moment(data.startDate.toString() + ' ' + data.startTime).toISOString(),
      validTo: moment(data.endDate.toString() + ' ' + data.endTime).toISOString(),
    } as any;
    delete messageData.startDate;
    delete messageData.startTime;
    delete messageData.endDate;
    delete messageData.endTime;
    dispatch(addMessageAction(messageData as AddMessageDto));
    if (!isLoading && errorCount === 0) {
      onClose();
    }
  };

  return (
    <div className={styles.createMessageForm}>
      <Form
        id="create-message-form"
        isLoading={isLoading}
        config={CREATE_MESSAGE_FORM_CONFIG}
        onValuesChanged={handleChange}
        onSubmit={createMessage}
        submitButtonText="Create message"
        actions={[
          {
            label: 'Cancel',
            onClick: onClose,
          },
        ]}
        initialValues={formData}
        errors={formErrors}
      />
    </div>
  );
};

export default CreateMessageForm;

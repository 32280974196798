import { NavigationConfiguration } from '../../../App/Main/components/Layout';

export const SCHOOLS_LEADS_MAIN_ROUTE = '/commercial/lead-gen/:schoolId';

export const SCHOOLS_LEADS_TABS_NAVIGATION: NavigationConfiguration = {
  schoolWithLeadDetails: {
    label: 'School details',
    route: '/details',
    exact: true,
  },
  schoolLeads: {
    label: 'School leads',
    route: '/leads',
    exact: true,
  },
};

import { FormConfig, FormControlFieldType } from '../../../../components/Form';
import { SelectOptionType } from '../../../../components/Select';
import { DATE_FORMAT_REGEX } from '../../../../shared/constants';
import { NavigationConfiguration } from '../../../App/Main/components/Layout';
import { STATUS_LIST } from '../../SchoolWidgetGroups/components/CreateWidgetGroupModal/CreateWidgetGroupModal.constants';

export const SCHOOL_WIDGETS_TABS_NAVIGATION = (schoolWidgetGroupId: number | string): NavigationConfiguration => ({
  schoolWidgetDetails: {
    label: 'Details',
    route: `/commercial/school-widget-groups/detail/${schoolWidgetGroupId}`,
  },
});

export const schoolWidgetConfig = (schools: SelectOptionType[], rankingTypes: SelectOptionType[]): FormConfig => ({
  schoolId: {
    fieldType: 'select' as FormControlFieldType,
    options: schools,
    placeholder: `Enter school's name`,
    required: true,
    title: 'School',
    searchable: true,
    validations: {
      required: {
        errorText: 'Please select a school!',
      },
    },
  },
  activeFromDate: {
    fieldType: 'date',
    placeholder: 'Select date',
    required: true,
    title: 'Active from',
    previewFormat: 'Do MMM YYYY',
    validations: {
      regex: {
        errorText: 'Please select a date',
        rule: DATE_FORMAT_REGEX,
      },
    },
  },
  status: {
    fieldType: 'select',
    placeholder: 'Choose a status',
    required: true,
    options: STATUS_LIST,
    title: 'Status',
    validations: {
      required: {
        errorText: 'Please choose a status!',
      },
    },
  },
  rankingTypeId: {
    fieldType: 'select' as FormControlFieldType,
    options: rankingTypes,
    placeholder: `Enter scope's name`,
    required: true,
    title: 'Scope',
    searchable: true,
    validations: {
      required: {
        errorText: 'Please enter scope',
      },
    },
  },
  activeToDate: {
    fieldType: 'date',
    placeholder: 'Select date',
    required: true,
    title: 'Active to',
    previewFormat: 'Do MMM YYYY',
    validations: {
      regex: {
        errorText: 'Please select a date',
        rule: DATE_FORMAT_REGEX,
      },
    },
  },
  widgets: {
    fieldType: 'text',
    placeholder: 'Widgets',
    disabled: true,
    title: 'Widgets',
  },
  previewLink: {
    fieldType: 'staticLink',
    title: 'Preview Link',
  },
});

export const WIDGET_TYPE = {
  VIDEO: 'video',
  HTML: 'html',
  FILELIST: 'filelist',
  SLIDESHOW: 'slideshow',
};

export interface CreatableWidget {
  details: string;
  html: string;
  type: string;
}

export interface SchoolWidgetDetailsDTO {
  id: number;
  schoolName: string;
  scope: string;
  activeFromDate: string | number;
  activeToDate: string | number;
  status: number;
  schoolId: number | null;
  widgets: number;
  rankingTypeId?: number;
  rankingTypeName?: string;
  previewLink: string;
}

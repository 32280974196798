import {
  EditRankingTimelineStepDTO,
  RankingSpreadsheetDTO,
  RankingSpreadsheetTemplateDTO,
  SurveyLinksDTO,
  RankingTimelineStepDTO,
  TimelineSpreadsheetDTO,
  TimelineAttachmentDTO,
  TimelineAttachmentUploadDTO,
} from './ranking-timeline-steps.dto';

export const EDIT_RANKING_TIMELINE_STEP = 'EDIT_RANKING_TIMELINE_STEP';
export const LOAD_RANKING_TIMELINE_STEPS = 'LOAD_RANKING_TIMELINE_STEPS';
export const SET_RANKING_TIMELINE_STEPS = 'SET_RANKING_TIMELINE_STEPS';
export const CLEAR_CURRENT_RANKING_TIMELINE_STEPS = 'CLEAR_CURRENT_RANKING_TIMELINE_STEPS';
export const SET_CURRENT_RANKING_TIMELINE_STEP = 'SET_CURRENT_RANKING_TIMELINE_STEP';
export const CLEAR_CURRENT_RANKING_TIMELINE_STEP = 'CLEAR_CURRENT_RANKING_TIMELINE_STEP';
export const DOWNLOAD_TEMPLATE = 'DOWNLOAD_TEMPLATE';
export const UPLOAD_SPREADSHEET = 'UPLOAD_SPREADSHEET';
export const UPLOAD_FINAL_RANK_SPREADSHEET = 'UPLOAD_FINAL_RANK_SPREADSHEET';
export const UPLOAD_SURVEY_LINKS = 'UPLOAD_SURVEY_LINKS';
export const DOWNLOAD_SPREADSHEET = 'DOWNLOAD_SPREADSHEET';
export const DOWNLOAD_STEP_ATTACHMENT = 'DOWNLOAD_STEP_ATTACHMENT';
export const UPLOAD_STEP_ATTACHMENT = 'UPLOAD_STEP_ATTACHMENT';
export const DELETE_STEP_ATTACHMENT = 'DELETE_STEP_ATTACHMENT';

export interface RankingTimelineStepsState {
  steps: RankingTimelineStepDTO[];
  currentStep?: RankingTimelineStepDTO;
}

export interface LoadRankingTimelineStepsAction {
  type: typeof LOAD_RANKING_TIMELINE_STEPS;
  payload: { rankingId: number };
}

export interface EditRankingTimelineStepAction {
  type: typeof EDIT_RANKING_TIMELINE_STEP;
  payload: { data: EditRankingTimelineStepDTO; rankingId: number };
}

export interface ClearCurrentRankingTimelineStepsAction {
  type: typeof CLEAR_CURRENT_RANKING_TIMELINE_STEPS;
}

export interface ClearCurrentRankingTimelineStepAction {
  type: typeof CLEAR_CURRENT_RANKING_TIMELINE_STEP;
}

export interface SetRankingTimelineStepsAction {
  type: typeof SET_RANKING_TIMELINE_STEPS;
  payload: { data: RankingTimelineStepDTO[] };
}

export interface SetCurrentRankingTimelineStepAction {
  type: typeof SET_CURRENT_RANKING_TIMELINE_STEP;
  payload: { data: RankingTimelineStepDTO };
}

export interface DownloadSpreadsheetTemplateAction {
  type: typeof DOWNLOAD_TEMPLATE;
  payload: RankingSpreadsheetTemplateDTO;
}

export interface UploadSpreadsheetAction {
  type: typeof UPLOAD_SPREADSHEET | typeof UPLOAD_FINAL_RANK_SPREADSHEET;
  payload: RankingSpreadsheetDTO;
}

export interface UploadSurveyLinksAction {
  type: typeof UPLOAD_SURVEY_LINKS;
  payload: SurveyLinksDTO;
}

export interface DownloadSpreadsheetAction {
  type: typeof DOWNLOAD_SPREADSHEET;
  payload: TimelineSpreadsheetDTO;
}

export interface UploadStepAttachmentAction {
  type: typeof UPLOAD_STEP_ATTACHMENT;
  payload: TimelineAttachmentUploadDTO;
}

export interface DownloadStepAttachmentAction {
  type: typeof DOWNLOAD_STEP_ATTACHMENT;
  payload: TimelineAttachmentDTO;
}

export interface DeleteStepAttachmentAction {
  type: typeof DELETE_STEP_ATTACHMENT;
  payload: TimelineAttachmentDTO;
}

export type RankingTimelineStepsActionTypes =
  | ClearCurrentRankingTimelineStepAction
  | ClearCurrentRankingTimelineStepsAction
  | EditRankingTimelineStepAction
  | LoadRankingTimelineStepsAction
  | SetCurrentRankingTimelineStepAction
  | SetRankingTimelineStepsAction
  | DownloadSpreadsheetTemplateAction
  | UploadSpreadsheetAction
  | UploadSurveyLinksAction
  | DownloadSpreadsheetAction
  | UploadStepAttachmentAction
  | DeleteStepAttachmentAction;

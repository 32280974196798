import { RootState } from '../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const schoolLeadsSelector = (state: RootState) => state.commercial.schoolLeads;

export const selectSchoolLeads = createDeepEqualSelector(schoolLeadsSelector, (schoolLeads) => {
  return schoolLeads.data;
});

export const selectSchoolLeadPageConfiguration = createDeepEqualSelector(schoolLeadsSelector, (schoolLeads) => {
  return {
    filter: schoolLeads.filter,
    limit: schoolLeads.pagination.limit,
    order: schoolLeads.sortOrder.order,
    page: schoolLeads.pagination.page,
    search: schoolLeads.search,
    sortBy: schoolLeads.sortOrder.sortBy,
    totalPages: schoolLeads.pagination.totalPages,
  };
});

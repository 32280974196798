import { call, CallEffect, put, PutEffect, SelectEffect, takeLatest } from 'redux-saga/effects';

import { setTermsAction } from './terms.actions';
import { getTermsRequest, updateTermsRequest } from './terms.api';
import { EDIT_TERMS, EditTermsAction, LOAD_TERMS, LoadTermsAction, TermsActionTypes } from './terms.types';
import { BasicResponseDTO, DefaultResponseDTO } from '../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../shared/state/global-request';

function* loadTermsSaga(
  action: LoadTermsAction,
): Generator<CallEffect<DefaultResponseDTO<string>> | PutEffect<GlobalRequestActions | TermsActionTypes>> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getTermsRequest)) as DefaultResponseDTO<string>;
    yield put(setTermsAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* editTermsSaga(
  action: EditTermsAction,
): Generator<PutEffect<GlobalRequestActions | TermsActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect> {
  const {
    type: actionType,
    payload: { data },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateTermsRequest, data);
    yield put(setRequestSucceededAction(actionType));
    yield put(setTermsAction(data.description as string));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* termsSaga(): Generator {
  yield takeLatest(LOAD_TERMS, loadTermsSaga);
  yield takeLatest(EDIT_TERMS, editTermsSaga);
}

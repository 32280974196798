import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ADD_SCHOOLS_TO_RANKING_FORM_CONFIG } from '../RankingSchools.constants';
import { addSchoolsToRankingAction } from '../../ranking-schools.actions';
import { AddSchoolsToRankingDTO } from '../../ranking-schools.dto';
import { ADD_SCHOOLS_TO_RANKING } from '../../ranking-schools.types';
import { RankingDTO, selectCurrentRanking } from '../../../RankingInfo';
import Form, { FormConfig } from '../../../../../../../components/Form';
import { Resource } from '../../../../../../../shared/constants';
import { selectAreRequestsLoading, selectRequestErrors } from '../../../../../../../shared/state/global-request';

type AddSchoolsToRankingFormProps = {
  onFormClose: () => void;
  availableSchoolsList: Resource[];
};

const AddSchoolsToRankingForm: FunctionComponent<AddSchoolsToRankingFormProps> = ({
  onFormClose,
  availableSchoolsList = [],
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectAreRequestsLoading([ADD_SCHOOLS_TO_RANKING]));
  const { id: rankingId } = useSelector(selectCurrentRanking) as RankingDTO;
  const formErrors = useSelector(selectRequestErrors(ADD_SCHOOLS_TO_RANKING, 'schoolIds'));

  const onAddSchoolsToRankingSubmit = (data: AddSchoolsToRankingDTO): void => {
    dispatch(addSchoolsToRankingAction(data as AddSchoolsToRankingDTO, rankingId));
  };

  const defaultFormConfig: FormConfig = {
    schoolIds: {
      ...ADD_SCHOOLS_TO_RANKING_FORM_CONFIG.schoolIds,
      options: availableSchoolsList.map((schoolData) => ({
        label: schoolData.label,
        value: schoolData.key,
      })),
    },
  };

  return (
    <Form
      id="add-schools-to-ranking-form"
      isLoading={isLoading}
      config={defaultFormConfig}
      onSubmit={onAddSchoolsToRankingSubmit}
      submitButtonText="Add schools"
      actions={[
        {
          label: 'Cancel',
          onClick: onFormClose,
        },
      ]}
      initialValues={[]}
      errors={formErrors}
    />
  );
};

export default AddSchoolsToRankingForm;

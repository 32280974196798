import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  loadRankingsAction,
  selectRankingsPageConfiguration,
  selectRankingsTableData,
} from '../../../../Rankings/RankingsList';
import { RANKINGS_TABLE_COLUMN_DEFINITION } from '../../../../Rankings/RankingsList/components/RankingsList.constants';
import Table from '../../../../../../components/Table';
import { ASCENDING_ORDER_DIRECTION, DESCENDING_ORDER_DIRECTION } from '../../../../../../shared/constants';

type RankingsTableProps = {
  rankingTypeId: string;
};

const RankingsTable: FunctionComponent<RankingsTableProps> = ({ rankingTypeId }: RankingsTableProps) => {
  const dispatch = useDispatch();
  const pageConfig = useSelector(selectRankingsPageConfiguration);
  const rankingsData = useSelector(selectRankingsTableData);

  useEffect(() => {
    if (rankingTypeId) {
      dispatch(loadRankingsAction({ filter: { rankingTypeId: [rankingTypeId] } }));
    }
  }, [dispatch, rankingTypeId]);

  const handleSortByChanged = (fieldName: string): void => {
    const orderDirection =
      pageConfig.sortBy !== fieldName
        ? DESCENDING_ORDER_DIRECTION
        : pageConfig.order === ASCENDING_ORDER_DIRECTION
        ? DESCENDING_ORDER_DIRECTION
        : ASCENDING_ORDER_DIRECTION;

    dispatch(
      loadRankingsAction({
        ...pageConfig,
        filter: { rankingTypeId: [rankingTypeId] },
        sortBy: fieldName,
        order: orderDirection,
      }),
    );
  };

  return (
    <>
      <hr className="mba-separator" />
      <Table
        id="ranking-types-list"
        columns={RANKINGS_TABLE_COLUMN_DEFINITION}
        headingOptions={{ heading: 'Rankings' }}
        data={rankingsData}
        sortingOptions={{
          order: pageConfig.order || DESCENDING_ORDER_DIRECTION,
          sortBy: pageConfig.sortBy || '',
        }}
        onSortByColumn={handleSortByChanged}
      />
    </>
  );
};

export default RankingsTable;

import {
  ADD_PARTNERSHIP,
  AddPartnershipAction,
  CLEAR_CURRENT_PARTNERSHIP,
  ClearCurrentPartnershipAction,
  LOAD_PARTNER_SCHOOLS,
  LOAD_PARTNERSHIPS,
  LoadPartnerSchoolsAction,
  LoadPartnershipsAction,
  PartnershipDTO,
  REMOVE_PARTNERSHIP,
  RemovePartnershipsAction,
  SET_PARTNERSHIPS,
  SetPartnershipsAction,
} from './partnerships.types';
import { SCHOOL_TYPES } from '../../SchoolsList/components/SchoolsList.constants';
import { PageSearchQueryConfig, TableListData } from '../../../../shared/constants';

export const removePartnershipAction = (
  schoolId: number,
  joinedSchoolId: number,
  currentSchoolId: number,
  currentSchoolType: SCHOOL_TYPES,
): RemovePartnershipsAction => ({
  type: REMOVE_PARTNERSHIP,
  payload: {
    currentSchoolId,
    currentSchoolType,
    schoolId,
    joinedSchoolId,
  },
});

export const addPartnershipAction = (
  mainSchoolId: number,
  joinedSchoolId: number,
  currentSchoolId: number,
  currentSchoolType: SCHOOL_TYPES,
): AddPartnershipAction => ({
  type: ADD_PARTNERSHIP,
  payload: { currentSchoolId, currentSchoolType, joinedSchoolId, mainSchoolId },
});

export const loadPartnershipsAction = (
  schoolId: number,
  pageConfig?: PageSearchQueryConfig,
): LoadPartnershipsAction => ({
  type: LOAD_PARTNERSHIPS,
  payload: { schoolId, pageConfig },
});

export const loadPartnerSchoolsAction = (schoolId: number): LoadPartnerSchoolsAction => ({
  type: LOAD_PARTNER_SCHOOLS,
  payload: { schoolId },
});

export const setPartnershipsAction = (tableData: TableListData<PartnershipDTO>): SetPartnershipsAction => ({
  type: SET_PARTNERSHIPS,
  payload: { tableData },
});

export const clearCurrentPartnershipAction = (): ClearCurrentPartnershipAction => ({
  type: CLEAR_CURRENT_PARTNERSHIP,
});

import { TermsDto } from './terms.dto';
import { get, put } from '../../../shared/api';
import { BasicResponseDTO, DefaultResponseDTO } from '../../../shared/constants';

export const getTermsRequest = (): Promise<DefaultResponseDTO<string>> => {
  return get<DefaultResponseDTO<string>>(`/terms`);
};

export const updateTermsRequest = (termsData: TermsDto): Promise<BasicResponseDTO> => {
  return put<BasicResponseDTO, TermsDto>(`/terms`, termsData);
};

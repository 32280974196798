import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';

import TextInput from '../TextInput';
import { PageSearchQueryConfig, SEARCH_DEBOUNCE_TIME } from '../../shared/constants';

type SearchInputProps = {
  id: string;
  onSearchChanged: (pageConfig: PageSearchQueryConfig) => void;
  pageConfig: PageSearchQueryConfig;
  placeholder?: string;
  searchTerm?: string;
};

let searchInterval: number;

const SearchInput: FunctionComponent<SearchInputProps> = ({
  id,
  onSearchChanged,
  pageConfig,
  placeholder,
  searchTerm = '',
}) => {
  const [term, setTerm] = useState(searchTerm);

  useEffect(() => {
    if (!searchTerm) {
      setTerm(searchTerm);
    }
  }, [searchTerm]);

  const handleSearchChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    const search = event.target.value || '';
    setTerm(search);

    if (searchInterval) {
      clearInterval(searchInterval);
    }

    searchInterval = window.setTimeout(() => {
      onSearchChanged({ ...pageConfig, search });
    }, SEARCH_DEBOUNCE_TIME);
  };

  return <TextInput id={id} onChange={handleSearchChanged} placeholder={placeholder} value={term} />;
};

export default SearchInput;

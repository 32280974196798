import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

import { setSchoolsAction } from './schools.actions';
import { getSchoolsRequest } from './schools.api';
import { LOAD_SCHOOLS, LoadSchoolsAction, SchoolsActionTypes } from './schools.types';
import { SchoolDTO } from '../SchoolProfile/SchoolDetails';
import { PagedResponseDTO } from '../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../shared/state/global-request';

function* loadSchoolsTableData(
  action: LoadSchoolsAction,
): Generator<
  CallEffect<PagedResponseDTO<SchoolDTO[]>> | PutEffect<SchoolsActionTypes> | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig } = action.payload;
    const { data, pagination, sortOrder } = (yield call(getSchoolsRequest, pageConfig)) as PagedResponseDTO<
      SchoolDTO[]
    >;
    yield put(setSchoolsAction({ data, pagination, sortOrder }));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* schoolsSaga(): Generator {
  yield takeLatest(LOAD_SCHOOLS, loadSchoolsTableData);
}

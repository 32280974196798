import { TableListData } from '../../../shared/constants';
import { getTableListDefaultState } from '../../../shared/helpers';
import { Registrations } from './registrations.dto';
import { LOAD_REGISTRATIONS, RegistrationsTypes, SET_REGISTRATIONS } from './registrations.types';

const initialState: TableListData<Registrations> = getTableListDefaultState<Registrations>();

const registrationsReducer = (state = initialState, action: RegistrationsTypes): TableListData<Registrations> => {
  switch (action.type) {
    case LOAD_REGISTRATIONS: {
      return {
        ...state,
        filter: action.payload.pageConfig?.filter || {},
        search: action.payload.pageConfig?.search || '',
      };
    }
    case SET_REGISTRATIONS: {
      return {
        ...state,
        ...action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default registrationsReducer;

import { BasicResponseDTO, DefaultResponseDTO } from '../../../shared/constants';
import { get, put } from '../../../shared/api';
import { PrivacyDto } from './privacy.dto';
import { TermsDto } from '../TermsAndConditions';

export const getPrivacyRequest = (): Promise<DefaultResponseDTO<string>> => {
  return get<DefaultResponseDTO<string>>(`/terms/privacy-policy`);
};

export const updatePrivacyRequest = (privacyData: PrivacyDto): Promise<BasicResponseDTO> => {
  return put<BasicResponseDTO, TermsDto>(`/terms?type=2`, privacyData);
};

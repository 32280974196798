import { FormConfig } from '../../../../components/Form';
import { ColumnDefinition, TableHeaderColumnTypes } from '../../../../components/Table';

export const FORM_GROUP_TITLE = '_title_';

export const SCHOOL_TYPE_SINGLE = 1;
export const SCHOOL_TYPE_JOINED = 2;

export enum SCHOOL_TYPES {
  SINGLE = '1',
  JOINED = '2',
}

export const JOINED_SCHOOLS_FIELD_CONFIG: FormConfig = {
  schoolsIds: {
    label: 'Schools to be joined',
    placeholder: `Select schools to join together`,
    fieldType: 'select',
    multiple: true,
    searchable: true,
    options: [],
    validations: {
      required: {
        errorText: `Please select at least one school!`,
      },
    },
  },
};

export const SCHOOL_USERS_FORM_CONFIG: FormConfig = {
  [`schoolUsers[0][${FORM_GROUP_TITLE}]`]: {
    label: 'User Details',
    fieldType: 'formGroupTitle',
  },
  'schoolUsers[0][name]': {
    label: 'Name',
    placeholder: 'Choose name',
    fieldType: 'text',
    validations: {
      required: {
        errorText: `Please enter name!`,
      },
    },
  },
  'schoolUsers[0][role]': {
    label: 'Role',
    placeholder: `Select user's role`,
    fieldType: 'select',
    options: [],
    validations: {
      required: {
        errorText: `Please select a role for the user!`,
      },
    },
  },
  'schoolUsers[0][email]': {
    label: 'E-mail',
    placeholder: "Enter the user's e-mail address",
    fieldType: 'text',
    validations: {
      required: {
        errorText: `Please enter a e-mail address!`,
      },
      regex: {
        errorText: 'Please enter a valid e-mail address!',
        rule: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      },
    },
  },
  'schoolUsers[0][phone]': {
    label: 'Phone',
    placeholder: `Enter user's phone number`,
    fieldType: 'text',
  },
};

export const SCHOOL_CREATE_BASE_FORM_CONFIG: FormConfig = {
  name: {
    label: 'Name',
    placeholder: 'Enter school name',
    fieldType: 'text',
    validations: {
      required: {
        errorText: `Please enter a school name!`,
      },
    },
  },
  address: {
    label: 'Address',
    placeholder: 'Enter school address',
    fieldType: 'text',
  },
  phone: {
    label: 'Phone',
    placeholder: `Enter school's phone number`,
    fieldType: 'phone',
  },
  email: {
    label: 'E-mail',
    placeholder: 'Enter the school e-mail address',
    fieldType: 'email',
    validations: {
      regex: {
        errorText: 'Please enter a valid e-mail address!',
        rule: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      },
    },
  },
  website: {
    label: 'Website',
    placeholder: `Enter school's website URL`,
    fieldType: 'text',
  },
};

export const SCHOOL_CREATE_FORM_CONFIG: FormConfig = {
  type: {
    fieldType: 'radio',
    inline: true,
    label: 'Type',
    shrunken: true,
    options: [
      { value: SCHOOL_TYPES.SINGLE, label: 'Single school' },
      { value: SCHOOL_TYPES.JOINED, label: 'Joint school' },
    ],
    value: SCHOOL_TYPES.SINGLE,
  },
  ...SCHOOL_CREATE_BASE_FORM_CONFIG,
};

export const SCHOOL_EDIT_FORM_CONFIG: FormConfig = {
  ...SCHOOL_CREATE_BASE_FORM_CONFIG,
  status: {
    label: 'Status',
    placeholder: 'Choose status',
    fieldType: 'select',
    options: [],
    required: true,
  },
};

export const SCHOOLS_TABLE_COLUMN_DEFINITION: ColumnDefinition[] = [
  {
    id: 'status',
    title: 'Status',
    type: TableHeaderColumnTypes.status,
    sorting: true,
    width: '90px',
  },
  {
    id: 'id',
    title: 'ID',
    sorting: true,
    width: '70px',
  },
  {
    id: 'name',
    sorting: true,
    title: 'Name',
    type: TableHeaderColumnTypes.link,
    path: '/schools/profile/:id/details',
  },
  {
    id: 'updatedAt',
    sorting: true,
    title: 'Last updated',
    type: TableHeaderColumnTypes.date,
    width: '160px',
  },
];

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { RANKING_SCHOOLS_TABLE_FILTERS } from './RankingSchoolsFiltersAndSearch.constants';
import { loadRankingSchoolsAction } from '../../ranking-schools.actions';
import FilterGroup, { mapInitialValuesToFiltersConfig } from '../../../../../../../components/FilterGroup';
import SearchInput from '../../../../../../../components/SearchInput';
import { PageFilterConfig, PageSearchQueryConfig, Status } from '../../../../../../../shared/constants';

type RankingSchoolsSearchProps = {
  rankingId: number;
  pageConfig: PageSearchQueryConfig;
  filtersData: { participationStatuses: Status[] };
};

const RankingSchoolsFiltersAndSearch: FunctionComponent<RankingSchoolsSearchProps> = ({
  rankingId,
  pageConfig,
  filtersData,
}) => {
  const dispatch = useDispatch();

  const [rankingSchoolFilters, setRankingSchoolFilters] = useState({});
  const { filter, search } = pageConfig;

  useEffect(() => {
    const { participationStatuses } = filtersData;

    setRankingSchoolFilters(
      mapInitialValuesToFiltersConfig(
        RANKING_SCHOOLS_TABLE_FILTERS,
        {
          participationStatus: participationStatuses,
        },
        filter,
        true,
      ),
    );
  }, [filter, filtersData]);

  const handleSearchChange = (newPageConfig: PageSearchQueryConfig): void => {
    dispatch(loadRankingSchoolsAction(rankingId, newPageConfig));
  };

  const handleFiltersChange = (newFilters: PageFilterConfig): void => {
    dispatch(
      loadRankingSchoolsAction(rankingId, {
        ...pageConfig,
        filter: newFilters,
      }),
    );
  };

  return (
    <div className="sticky-sidebar-container">
      <SearchInput
        id="ranking-schools-search"
        pageConfig={pageConfig}
        placeholder="Search by school name or SID"
        onSearchChanged={handleSearchChange}
        searchTerm={search}
      />
      <FilterGroup
        defaultFilters={filter || {}}
        filtersConfig={rankingSchoolFilters}
        onFiltersChanged={handleFiltersChange}
      />
    </div>
  );
};

export default RankingSchoolsFiltersAndSearch;

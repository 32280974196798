import { LOAD_RANKING_TYPES, RankingTypesActionTypes, SET_RANKING_TYPES } from './ranking-types.types';
import { RankingTypeDTO } from '../RankingTypeProfile';
import { TableListData } from '../../../../shared/constants';
import { getTableListDefaultState } from '../../../../shared/helpers';

const initialState: TableListData<RankingTypeDTO> = getTableListDefaultState<RankingTypeDTO>();

const rankingTypesReducer = (state = initialState, action: RankingTypesActionTypes): TableListData<RankingTypeDTO> => {
  switch (action.type) {
    case LOAD_RANKING_TYPES: {
      return {
        ...state,
        filter: action.payload.pageConfig?.filter || {},
        search: action.payload.pageConfig?.search || '',
      };
    }
    case SET_RANKING_TYPES: {
      return {
        ...state,
        ...action.payload.tableData,
      };
    }
    default: {
      return state;
    }
  }
};

export default rankingTypesReducer;

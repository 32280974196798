import { NavigationConfiguration } from '../../App/Main/components/Layout';

export interface SchoolProfileRouteParams {
  id: string;
}

export const SCHOOL_PROFILE_MAIN_ROUTE = '/schools/profile/:id';

export const SCHOOL_PROFILE_TABS_NAVIGATION: NavigationConfiguration = {
  details: {
    label: 'Details',
    route: '/details',
  },
  users: {
    exact: true,
    label: 'Accounts',
    route: '/users',
  },
  partnerships: {
    label: 'Partnerships',
    route: '/partnerships',
  },
  rankings: {
    label: 'Rankings',
    route: '/rankings',
  },
  articles: {
    label: 'Articles',
    route: '/articles',
  },
};

import { isArray } from 'lodash';
import { SchoolLeadAnswer, SchoolLeadsDetails, SchoolLeadsUser } from '../school-leads-details.dto';
import { LEAD_QUESTIONS, SchoolLeadsDetailsDTO, SchoolLeadsSingleViewDTO } from './SchoolLeadsDetails.constants';

export const convertToLeadDetails = (currentLead: SchoolLeadsDetails): SchoolLeadsDetailsDTO => {
  return {
    status: currentLead.status?.label,
    email: currentLead.user?.email,
    name: `${currentLead.user?.firstName} ${currentLead.user?.lastName}`,
    country: currentLead.user?.country.name,
    createdAt: currentLead.user?.createdAt,
    nationality: currentLead.user?.nationality.name,
    role: currentLead.user?.role.name,
    yearsProfessionalExperience: getLeadAnswerValue(
      currentLead.user ?? null,
      LEAD_QUESTIONS.yearsProfessionalExperience,
    ),
    programmeOfInterest: getLeadAnswerValue(currentLead.user ?? null, LEAD_QUESTIONS.progammeOfInterest),
    yearsManagerialExperience: getLeadAnswerValue(currentLead.user ?? null, LEAD_QUESTIONS.yearsManagerialExperience),
    specifyRole: getLeadAnswerValue(currentLead.user ?? null, LEAD_QUESTIONS.specifyRole),
  };
};

export const initializeFields = (
  config: SchoolLeadsSingleViewDTO,
  initialValues = {} as SchoolLeadsDetailsDTO,
): any => {
  return Object.entries(config).reduce((acc, [key, value]) => {
    let fieldValue: string | number = '';
    const fieldKey = value;
    if (initialValues.hasOwnProperty(key)) {
      fieldValue = initialValues[key as keyof SchoolLeadsSingleViewDTO];
    }
    return { ...acc, [fieldKey]: fieldValue };
  }, {});
};

export const getFieldsConfig = (currentLead: SchoolLeadsDetails): any => {
  const specifyRoleFieldValue = getLeadAnswerValue(currentLead.user, LEAD_QUESTIONS.specifyRole);
  const programmeOfInterestFieldValue = getLeadAnswerValue(currentLead.user, LEAD_QUESTIONS.progammeOfInterest);
  const yearsProfessionalExperienceValue = getLeadAnswerValue(
    currentLead.user,
    LEAD_QUESTIONS.yearsProfessionalExperience,
  );
  const yearsManagerialExperienceValue = getLeadAnswerValue(currentLead.user, LEAD_QUESTIONS.yearsManagerialExperience);

  return {
    role: 'Role',
    ...(specifyRoleFieldValue && { specifyRole: LEAD_QUESTIONS.specifyRole }),
    ...(programmeOfInterestFieldValue && { programmeOfInterest: LEAD_QUESTIONS.progammeOfInterest }),
    ...(yearsProfessionalExperienceValue && {
      yearsProfessionalExperience: LEAD_QUESTIONS.yearsProfessionalExperience,
    }),
    ...(yearsManagerialExperienceValue && { yearsManagerialExperience: LEAD_QUESTIONS.yearsManagerialExperience }),
  };
};

const getLeadAnswerValue = (user: SchoolLeadsUser, questionTitle: string): any => {
  if (!user) {
    return null;
  }

  const answer = user.answers.find((answer: SchoolLeadAnswer) => answer.question.title === questionTitle);
  const questionType = answer?.question.type;
  if (questionType === 'multiselect') {
    const mappedResult = user.answers
      .filter((answer: SchoolLeadAnswer) => answer.question.title === questionTitle)
      .map((answer: SchoolLeadAnswer) => answer.value);
    return isArray(mappedResult) ? mappedResult.join(', ') : mappedResult;
  }

  return answer?.value;
};

import { ResourcesActionTypes, ResourcesState, SET_RESOURCES } from './resources.types';

const initialState: ResourcesState = {};

const resourcesReducer = (state = initialState, action: ResourcesActionTypes): ResourcesState => {
  switch (action.type) {
    case SET_RESOURCES: {
      return {
        ...state,
        [action.payload.resourceName]: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default resourcesReducer;

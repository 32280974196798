import { NavigationConfiguration } from '../../../../App/Main/components/Layout';

export const RANKING_PROFILE_SPREADSHEETS_MAIN_ROUTE = '/rankings/profile/:id/spreadsheets';

export const RANKING_PROFILE_SPREADSHEETS_SUB_NAVIGATION: NavigationConfiguration = {
  alumni: {
    label: 'Alumni spreadsheets',
    route: '/alumni',
  },
  faculty: {
    label: 'Faculty spreadsheets',
    route: '/faculty',
  },
  research: {
    label: 'Research papers spreadsheets',
    route: '/research',
  },
};

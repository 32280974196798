import { PageSearchQueryConfig, TableListData } from '../../../shared/constants';
import {
  CreateSchoolWidgetGroupsDto,
  UpdateWidgetGroupDto,
} from './components/CreateWidgetGroupModal/CreateWidgetGroupModal.constants';
import { SchoolWidgetGroupDto } from './school-widget-groups.dto';

export const ADD_SCHOOL_WIDGET_GROUP_TO_LIST = 'ADD_SCHOOL_WIDGET_GROUP_TO_LIST';
export const CREATE_SCHOOL_WIDGET_GROUP = 'CREATE_SCHOOL_WIDGET_GROUPS';
export const DELETE_SCHOOL_WIDGET_GROUP = 'DELETE_SCHOOL_WIDGET_GROUP';
export const LOAD_SCHOOL_WIDGET_GROUPS = 'LOAD_SCHOOL_WIDGET_GROUPS';
export const SET_SCHOOL_WIDGET_GROUPS = 'SET_SCHOOL_WIDGET_GROUPS';
export const UPDATE_SCHOOL_WIDGET_GROUP = 'UPDATE_SCHOOL_WIDGET_GROUP';

export interface AddWidgetGroupToListAction {
  type: typeof ADD_SCHOOL_WIDGET_GROUP_TO_LIST;
  payload: { newWidgetGroup: SchoolWidgetGroupDto };
}

export interface CreateSchoolWidgetGroupAction {
  type: typeof CREATE_SCHOOL_WIDGET_GROUP;
  payload: { savingData: CreateSchoolWidgetGroupsDto };
}

export interface DeleteSchoolWidgetGroupAction {
  type: typeof DELETE_SCHOOL_WIDGET_GROUP;
  payload: { id: number };
}

export interface LoadSchoolWidgetGroupsAction {
  type: typeof LOAD_SCHOOL_WIDGET_GROUPS;
  payload: { pageConfig?: PageSearchQueryConfig };
}

export interface SetSchoolWidgetGroupsAction {
  type: typeof SET_SCHOOL_WIDGET_GROUPS;
  payload: { tableData: TableListData<SchoolWidgetGroupDto> };
}

export interface UpdateSchoolWidgetGroupAction {
  type: typeof UPDATE_SCHOOL_WIDGET_GROUP;
  payload: { id: number; updateWidgetGroupDto: UpdateWidgetGroupDto };
}

export type SchoolWidgetGroupsActionTypes =
  | AddWidgetGroupToListAction
  | CreateSchoolWidgetGroupAction
  | DeleteSchoolWidgetGroupAction
  | LoadSchoolWidgetGroupsAction
  | SetSchoolWidgetGroupsAction
  | UpdateSchoolWidgetGroupAction;

import { RootState } from '../../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const schoolsSelector = (state: RootState) => state.schools;
const schoolsUsersSelector = (state: RootState) => state.schools.users;

export const selectCurrentSchoolUser = createDeepEqualSelector(schoolsSelector, (schools) => {
  return schools.user;
});

export const selectSchoolUsersTableData = createDeepEqualSelector(schoolsUsersSelector, (users) => {
  return users.data;
});

export const selectSchoolUsersPageConfiguration = createDeepEqualSelector(schoolsUsersSelector, (users) => {
  return {
    filter: users.filter,
    limit: users.pagination.limit,
    order: users.sortOrder.order,
    page: users.pagination.page,
    search: users.search,
    sortBy: users.sortOrder.sortBy,
    totalPages: users.pagination.totalPages,
  };
});

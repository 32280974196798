import { OptionTypeBase, Props as ReactSelectProps, StylesConfig } from 'react-select';

import { InputWrapperProps } from '../InputWrapper';

export type SelectProps = ReactSelectProps<SelectOptionType, boolean> &
  InputWrapperProps & {
    errorMessage?: string;
    noOptionsText?: string;
    onValueChanged?: (name: string, value: SelectOptionType | SelectOptionType[]) => void;
    async?: boolean;
    onRequestOptions?: (query: string) => Promise<SelectOptionType[]>;
  };

export type SelectOptionValue = number | string;

export interface SelectOptionType extends OptionTypeBase {
  label: string;
  value: SelectOptionValue;
}

export interface SelectOption {
  label: string;
  value: number | string | boolean;
}

export const getSelectControlCSSProperties = (
  isFocused = false,
  isDisabled = false,
  hasErrors = false,
): ReactSelectProps => {
  const borderColor = isDisabled ? '#e6e6e6' : hasErrors ? '#cc0000' : '#808080';
  const backgroundColor = isDisabled ? '#e6e6e6' : '#ffffff';

  return {
    borderRadius: 0,
    border: `1px solid ${borderColor}`,
    backgroundColor: backgroundColor,
    boxShadow: 'none',
    cursor: isDisabled ? 'default' : 'pointer',
    outline: isFocused ? '-webkit-focus-ring-color auto 1px' : 0,
    '&:hover': { borderColor },
  };
};

export const SELECT_CUSTOM_STYLES: StylesConfig<SelectOptionType, boolean> = {
  singleValue: (provided) => ({
    ...provided,
    color: '#000000',
    fontSize: '16px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#808080',
    '&:hover': { color: '#000000' },
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: '#808080',
    fontSize: '16px',
    cursor: 'pointer',
    '&:hover': { color: '#000000' },
  }),
  valueContainer: (provided) => ({
    ...provided,
    minHeight: '40px',
    padding: '4px 8px',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided, state) => ({
    ...provided,
    ...getSelectControlCSSProperties(state.isFocused, state.isDisabled),
  }),
  menu: (provided) => ({
    ...provided,
    margin: 0,
    color: '#000000',
    fontSize: '16px',
    border: '1px solid #808080',
    borderRadius: 0,
    borderTop: 0,
    backgroundColor: '#ffffff',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
  multiValue: (provided) => ({
    ...provided,
    border: '1px solid #0d7680',
    borderRadius: 0,
    backgroundColor: '#ffffff',
    transition: 'background-color 0.2s',
    cursor: 'default',
    '&:hover': { backgroundColor: 'rgb(13 118 128 / 10%)' },
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    paddingLeft: '11px',
    color: '#000000',
    fontSize: '16px',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    marginLeft: '5px',
    color: '#0d7680',
    borderRadius: 0,
    cursor: 'pointer',
    transition: 'background-color 0.2s',
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#0d7680',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? '#e6e6e6' : '#ffffff',
    color: state.isDisabled ? '#666' : '#000000',
    minHeight: '40px',
    padding: '8px',
    transition: 'background-color 0.2s',
    cursor: state.isDisabled ? 'default' : 'pointer',
    '&:hover': state.isDisabled
      ? {
          backgroundColor: '#e6e6e6',
        }
      : {
          backgroundColor: '#cecece',
        },
  }),
  placeholder: (provided) => ({
    ...provided,
    margin: 0,
    color: '#757575',
    fontSize: '16px',
  }),
};

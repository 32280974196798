import { AddRankingCalendarDto, RankingCalendarDto, RankingCalendarUpdateDTO } from './calendar.dto';
import {
  ADD_RANKING_CALENDAR,
  AddRankingCalendarAction,
  CREATE_RANKING_CALENDAR,
  CreateRankingCalendarAction,
  LOAD_RANKING_CALENDARS,
  LoadRankingCalendarsAction,
  SET_RANKING_CALENDARS,
  SetRankingCalendarsAction,
  UPDATE_RANKING_CALENDARS,
  UpdateRankingCalendarsAction,
} from './calendar.types';

export const createRankingCalendarAction = (data: AddRankingCalendarDto): CreateRankingCalendarAction => ({
  type: CREATE_RANKING_CALENDAR,
  payload: { data },
});

export const addRankingCalendarAction = (data: RankingCalendarDto): AddRankingCalendarAction => ({
  type: ADD_RANKING_CALENDAR,
  payload: { data },
});

export const loadRankingCalendarsAction = (): LoadRankingCalendarsAction => ({
  type: LOAD_RANKING_CALENDARS,
});

export const setRankingCalendarsAction = (data: RankingCalendarDto[]): SetRankingCalendarsAction => ({
  type: SET_RANKING_CALENDARS,
  payload: { data },
});

export const updateRankingCalendarsAction = (data: RankingCalendarUpdateDTO): UpdateRankingCalendarsAction => ({
  type: UPDATE_RANKING_CALENDARS,
  payload: { data },
});

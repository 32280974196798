import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { DefaultResponseDTO } from '../../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../../shared/state/global-request';
import { SchoolsWithLeadGenDTO } from '../../SchoolsWithLeadGen';
import { updateSchoolWithLeadDetailsRequest } from './school-with-lead-details.api';

import {
  SchoolWithLeadDetailsActionTypes,
  UpdateSchoolWithLeadDetailsAction,
  UPDATE_SCHOOL_WITH_LEAD_DETAILS,
} from './school-with-lead-details.types';

function* updateSchoolWithLeadDetailsData(
  action: UpdateSchoolWithLeadDetailsAction,
): Generator<
  | CallEffect<DefaultResponseDTO<SchoolsWithLeadGenDTO>>
  | PutEffect<SchoolWithLeadDetailsActionTypes>
  | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { id: schoolId, updatedData } = action.payload;
    yield call(updateSchoolWithLeadDetailsRequest, schoolId, updatedData);
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* schoolWithLeadDetailsSaga(): Generator {
  yield takeLatest(UPDATE_SCHOOL_WITH_LEAD_DETAILS, updateSchoolWithLeadDetailsData);
}

import React, { FunctionComponent } from 'react';

import { IconNames } from './Icon.constants';

type IconProps = {
  className?: string;
  name: IconNames;
  size?: number;
};

const Icon: FunctionComponent<IconProps> = ({ className, name, size }) => {
  const iconClasses = ['o-icons-icon', `o-icons-icon--${name}`];

  if (className) {
    iconClasses.push(className);
  }

  return <span style={{ width: size, height: size }} className={iconClasses.join(' ')} />;
};

export default Icon;

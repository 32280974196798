import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button, { ButtonSizes } from '../../../../components/Button';
import Form, { FormDataValues } from '../../../../components/Form';
import { getHtmlFromJSON, getRawFromHTML } from '../../../../components/RichTextEditor/RichTextEditor.helpers';
import Typography, { TypographyVariants } from '../../../../components/Typography';
import {
  selectRequestFormErrors,
  selectRequestIsLoading,
  setRequestSucceededAction,
} from '../../../../shared/state/global-request';
import { editTermsAction, loadTermsAction } from '../terms.actions';
import { TermsDto } from '../terms.dto';
import { selectTermsAndConditions } from '../terms.selectors';
import { EDIT_TERMS } from '../terms.types';
import { TERMS_AND_CONDITIONS_CONFIG } from './TermsAndConditions.constants';

const TermsAndConditions: FunctionComponent = () => {
  const dispatch = useDispatch();
  const termsAndConditions = useSelector(selectTermsAndConditions);
  const isSaving = useSelector(selectRequestIsLoading(EDIT_TERMS));
  const formErrors = useSelector(selectRequestFormErrors(EDIT_TERMS));
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(loadTermsAction());
  }, [dispatch]);

  useEffect(() => {
    if (isEdit) {
      resetFormState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termsAndConditions]);

  const updateTerms = (data: FormDataValues): void => {
    dispatch(editTermsAction(data as TermsDto));
  };

  const resetFormState = (): void => {
    setIsEdit(false);
    dispatch(setRequestSucceededAction(EDIT_TERMS));
  };

  const __html = termsAndConditions.description ? getHtmlFromJSON(termsAndConditions.description) : '-';

  return (
    <>
      <div className="mba-heading--wrapper mba-heading--table">
        <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title">
          Terms and Conditions
        </Typography>
        {!isEdit && <Button text="Edit" size={ButtonSizes.big} primary onClick={(): void => setIsEdit(true)} />}
      </div>
      {isEdit ? (
        <div className="mba-mb-20">
          <Form
            id="terms-form"
            config={TERMS_AND_CONDITIONS_CONFIG}
            isLoading={isSaving}
            onSubmit={updateTerms}
            submitButtonText="Save"
            actions={[
              {
                label: 'Cancel',
                onClick: resetFormState,
              },
            ]}
            initialValues={{ description: getRawFromHTML(termsAndConditions.description) }}
            errors={formErrors}
          />
        </div>
      ) : (
        <div dangerouslySetInnerHTML={{ __html }} />
      )}
    </>
  );
};

export default TermsAndConditions;

import { CreateSchoolUserDTO, SchoolUserDTO, SchoolUserRolesAndPermissionsDTO } from './school-user-profile.dto';

export const CREATE_SCHOOL_USER = 'CREATE_SCHOOL_USER';
export const LOAD_SCHOOL_USER = 'LOAD_SCHOOL_USER';
export const UPDATE_SCHOOL_USER_ROLES_AND_PERMISSIONS = 'UPDATE_SCHOOL_USER_ROLES_AND_PERMISSIONS';
export const DELETE_SCHOOL_USER = 'DELETE_SCHOOL_USER';
export const CLEAR_CURRENT_SCHOOL_USER = 'CLEAR_CURRENT_SCHOOL_USER';
export const SET_SCHOOL_USER = 'SET_SCHOOL_USER';
export const EDIT_SCHOOL_USER = 'EDIT_SCHOOL_USER';

export type SchoolUserProfileState = CreateSchoolUserDTO | SchoolUserDTO;

export interface SchoolUserProfileRouteParams {
  id: string;
}

export interface CreateSchoolUserAction {
  type: typeof CREATE_SCHOOL_USER;
  payload: {
    data: CreateSchoolUserDTO;
    schoolId: number;
  };
}

export interface LoadSchoolUserAction {
  type: typeof LOAD_SCHOOL_USER;
  payload: {
    id: number;
    schoolId: number;
  };
}

export interface UpdateSchoolUserRolesAndPermissionsAction {
  type: typeof UPDATE_SCHOOL_USER_ROLES_AND_PERMISSIONS;
  payload: {
    data: SchoolUserRolesAndPermissionsDTO;
    partnershipId: number;
    schoolId: number;
    userId: number;
  };
}

export interface DeleteSchoolUserAction {
  type: typeof DELETE_SCHOOL_USER;
  payload: {
    id: number;
    schoolId: number;
    ignoreWarnings?: boolean;
  };
}

export interface ClearCurrentSchoolUserAction {
  type: typeof CLEAR_CURRENT_SCHOOL_USER;
}

export interface SetSchoolUserAction {
  type: typeof SET_SCHOOL_USER;
  payload: {
    data: SchoolUserDTO;
    schoolId: number;
  };
}

export interface EditSchoolUserAction {
  type: typeof EDIT_SCHOOL_USER;
  payload: {
    data: SchoolUserDTO;
    schoolId: number;
  };
}

export type SchoolUserActionTypes =
  | ClearCurrentSchoolUserAction
  | CreateSchoolUserAction
  | DeleteSchoolUserAction
  | LoadSchoolUserAction
  | SetSchoolUserAction
  | EditSchoolUserAction;

import { PageSearchQueryConfig, TableListData } from '../../../shared/constants';
import { AddMessageDto, MessageDto } from './onsite-messaging.dto';
import {
  AddMessageAction,
  ADD_MESSAGE,
  EditMessageAction,
  EDIT_MESSAGE,
  LoadLatestMessagesAction,
  LoadMessageHistoryAction,
  LOAD_LATEST_MESSAGE,
  LOAD_MESSAGE_HISTORY,
  SetLatestMessageAction,
  SetMessageHistoryAction,
  SET_LATEST_MESSAGE,
  SET_MESSAGE_HISTORY,
} from './onsite-messaging.types';

export const loadMessageHistoryAction = (pageConfig?: PageSearchQueryConfig): LoadMessageHistoryAction => ({
  type: LOAD_MESSAGE_HISTORY,
  payload: { pageConfig },
});

export const loadLatestMessageAction = (): LoadLatestMessagesAction => ({
  type: LOAD_LATEST_MESSAGE,
});

export const setMessageHistoryAction = (tableData: TableListData<MessageDto>): SetMessageHistoryAction => ({
  type: SET_MESSAGE_HISTORY,
  payload: { tableData },
});

export const setLatestMessageAction = (data: MessageDto): SetLatestMessageAction => ({
  type: SET_LATEST_MESSAGE,
  payload: { data },
});

export const addMessageAction = (data: AddMessageDto): AddMessageAction => ({
  type: ADD_MESSAGE,
  payload: { data },
});

export const editMessageAction = (messageId: number, data: AddMessageDto): EditMessageAction => ({
  type: EDIT_MESSAGE,
  payload: { messageId, data },
});

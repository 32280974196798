import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

import { DefaultResponseDTO } from '../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../shared/state/global-request';
import { setSchoolSingleLead } from './school-leads-details.actions';
import { getSchoolSingleLeadRequest } from './school-leads-details.api';
import { SchoolLeadsDetails } from './school-leads-details.dto';
import {
  loadSchoolSingleLeadAction,
  LOAD_SINGLE_SCHOOL_LEAD,
  SingleSchoolLeadActionTypes,
} from './school-leads-details.types';

function* loadSingleSchoolData(
  action: loadSchoolSingleLeadAction,
): Generator<
  | CallEffect<DefaultResponseDTO<SchoolLeadsDetails>>
  | PutEffect<SingleSchoolLeadActionTypes>
  | PutEffect<GlobalRequestActions>
> {
  const {
    type: actionType,
    payload: { leadId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getSchoolSingleLeadRequest, leadId)) as DefaultResponseDTO<SchoolLeadsDetails>;
    yield put(setSchoolSingleLead(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* schoolSingleLeadSaga(): Generator {
  yield takeLatest(LOAD_SINGLE_SCHOOL_LEAD, loadSingleSchoolData);
}

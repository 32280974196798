import { AddRankingCalendarDto, RankingCalendarDto, RankingCalendarUpdateDTO } from './calendar.dto';
import { get, post, put } from '../../../../shared/api';
import { BasicResponseDTO, DefaultResponseDTO } from '../../../../shared/constants';

export const getCalendarsRequest = (): Promise<DefaultResponseDTO<RankingCalendarDto[]>> => {
  return get<DefaultResponseDTO<RankingCalendarDto[]>>(`/calendars`);
};

export const addRankingCalendarRequest = (
  calendar: AddRankingCalendarDto,
): Promise<DefaultResponseDTO<RankingCalendarDto>> => {
  return post<DefaultResponseDTO<RankingCalendarDto>, AddRankingCalendarDto>(`/calendars`, calendar);
};

export const updateCalendarsRequest = (calendars: RankingCalendarUpdateDTO): Promise<BasicResponseDTO> => {
  return put<BasicResponseDTO, RankingCalendarUpdateDTO>(`/calendars`, calendars);
};

import React, { ChangeEvent, FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react';
import { IconDelete } from '../CustomIcons';
import Grid from '../Grid';

import styles from './FileUpload.module.scss';

interface FileUploadInputProps {
  id?: string;
  name?: string;
  enablePreview?: boolean;
  errorMessage?: ReactNode;
  display?: boolean;
  multiple?: boolean;
  defaultPreviewImages?: string[];
  handleChange?: (files: FileList) => void;
  onRemoveFile?: (file: File, index: number) => void;
}

export const FileUploadInput: FunctionComponent<FileUploadInputProps> = ({
  id = '',
  name = 'file-input',
  errorMessage,
  enablePreview = false,
  display = true,
  multiple = false,
  handleChange,
  onRemoveFile,
  defaultPreviewImages,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [previewImages, setPreviewImages] = useState<string[]>(defaultPreviewImages || []);

  useEffect(() => {
    if (fileInputRef?.current?.files) {
      fileInputRef.current.files = null;
      fileInputRef.current.value = '';
      setUploadedFiles([]);
    }
  }, [display]);

  useEffect(() => {
    setPreviewImages(defaultPreviewImages || []);
  }, [defaultPreviewImages]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { files },
    } = event;
    if (files) {
      const newFiles = Object.keys(files).map((key) => files[+key]);
      const uploadFiles = multiple ? [...uploadedFiles, ...newFiles] : newFiles;
      const prevImages = uploadFiles.map((item) => URL.createObjectURL(item));
      setPreviewImages([...previewImages, ...prevImages]);
      setUploadedFiles(uploadFiles);
    }
    handleChange && handleChange(({ ...files } as FileList) || ({} as FileList));
    clearInputFiles();
  };

  const clearInputFiles = (): void => {
    if (fileInputRef?.current?.files) {
      fileInputRef.current.files = null;
      fileInputRef.current.value = '';
    }
  };

  const onRemoveImage = (fileUrl: string, index: number): void => {
    onRemoveFile && onRemoveFile(uploadedFiles[index], index);
    uploadedFiles.splice(index, 1);
    previewImages.splice(index, 1);
    setUploadedFiles([...uploadedFiles]);
    setPreviewImages([...previewImages]);
  };

  return (
    <>
      {display && (
        <>
          <div className={styles.dropzoneWrapper}>
            <div className={styles.dropzone}>
              {uploadedFiles.length > 0 ? (
                <div>
                  {uploadedFiles.map((file, index) => (
                    <p key={`${file?.name} ${index}`} className="mba-text--b2b mba-no-margin mba-font--16">
                      {file?.name}
                    </p>
                  ))}
                </div>
              ) : (
                <div className="mba-text--center">
                  Drag & drop here
                  <br />
                  or
                  <br />
                  <span className={styles.linkButton}>Browse</span>
                </div>
              )}
            </div>
            <input
              name={name}
              multiple={multiple || false}
              id={id}
              type="file"
              ref={fileInputRef}
              className={styles.dropzoneInput}
              onChange={handleFileChange}
            />
          </div>
          {errorMessage && (
            <div>
              <span className="mba-text--error">{errorMessage}</span>
              <br />
              <br />
            </div>
          )}
          {enablePreview && (
            <>
              {previewImages &&
                previewImages.map((imageUrl, index) => (
                  <Grid item key={imageUrl} md={4} xs={4} className="mba-no-padding">
                    <div className={styles.previewImageContainer}>
                      <div className={styles.previewImageWrapper}>
                        <img src={imageUrl} alt={''} className={styles.previewImage} />
                      </div>
                      <button className="mba-button--link" onClick={(): void => onRemoveImage(imageUrl, index)}>
                        <IconDelete width={'15'} height={'15'} />
                      </button>
                    </div>
                  </Grid>
                ))}
            </>
          )}
        </>
      )}
    </>
  );
};

import { AccreditationDTO } from '../AccreditationProfile';
import { AccreditationsActionTypes, LOAD_ACCREDITATIONS, SET_ACCREDITATIONS } from './accreditations.types';
import { getTableListDefaultState } from '../../../../shared/helpers';
import { TableListData } from '../../../../shared/constants';

const initialState: TableListData<AccreditationDTO> = getTableListDefaultState<AccreditationDTO>();

const accreditationReducer = (
  state = initialState,
  action: AccreditationsActionTypes,
): TableListData<AccreditationDTO> => {
  switch (action.type) {
    case LOAD_ACCREDITATIONS: {
      return {
        ...state,
        filter: action.payload.pageConfig?.filter || {},
        search: action.payload.pageConfig?.search || '',
      };
    }
    case SET_ACCREDITATIONS: {
      return {
        ...state,
        ...action.payload.tableData,
      };
    }
    default: {
      return state;
    }
  }
};

export default accreditationReducer;

import React, { FunctionComponent, useEffect, useState } from 'react';

import Button, { ButtonSizes } from '../../../../../components/Button';
import { IconDelete } from '../../../../../components/CustomIcons';
import { FileUploadInput } from '../../../../../components/FileUpload';
import { FormValue } from '../../../../../components/Form';
import Grid from '../../../../../components/Grid';
import Modal from '../../../../../components/Modal';
import ViewInput from '../../../../../components/ViewInput';
import { createSlideshowImageAttachment } from '../../../../../shared/state/attachments/attachments.api';
import { WidgetDTO } from '../EditableWidget/EditableWidget.constants';
import { CreatableWidget, WIDGET_TYPE } from '../SchoolWidgetDetails.constants';
import {
  DEFAULT_HTML_LIST_STRING,
  DEFAULT_HTML_STRING,
  NEW_WIDGET_OPTIONS,
  NewWidgetData,
  WIDGET_STATUS_OPTIONS,
  WIDGET_STATUS_TYPE,
} from './CreateWidgetModal.constants';

import styles from '../EditableWidget/EditableWidget.module.scss';

interface DuplicateWidgetsOptions {
  label: string;
  value: number;
}

interface CreateWidgetProps {
  widgets: WidgetDTO[];
  onSave: (widget: CreatableWidget) => void;
  handleModalClose: () => void;
  isModalOpen: boolean;
  isLoading: boolean;
}

const CreateWidgetModal: FunctionComponent<CreateWidgetProps> = ({
  onSave,
  handleModalClose,
  isModalOpen,
  widgets,
  isLoading,
}) => {
  const [newWidget, setNewWidget] = useState<NewWidgetData>({} as NewWidgetData);
  const [duplicateWidget, setDuplicateWidget] = useState<WidgetDTO>({} as WidgetDTO);
  const [widgetStatus, setWidgetStatus] = useState<number>(WIDGET_STATUS_TYPE.DUPLICATE);
  const [schoolWidgetsOptions, setSchoolWidgetsOptions] = useState<DuplicateWidgetsOptions[]>([]);

  useEffect(() => {
    let widgetsOptions: DuplicateWidgetsOptions[] = [];
    if (widgets && widgets.length > 0) {
      widgetsOptions = widgets.map((item) => {
        const { details } = getDefaultElements(item);
        return { label: `${item.type} - ${details.title ? details.title : ''}`, value: item.id };
      });
    }
    setSchoolWidgetsOptions(widgetsOptions);
    // eslint-disable-next-line
  }, [widgets]);

  const createDefaultElements = (widget?: WidgetDTO): void => {
    const createdElements = getDefaultElements(widget);
    setNewWidget((widgetData) => ({ ...widgetData, ...createdElements }));
  };

  const getDefaultElements = (widget?: WidgetDTO): NewWidgetData => {
    const divEl = document.createElement('div').cloneNode(true) as Element;
    divEl.innerHTML = widget?.html || DEFAULT_HTML_STRING;

    const titleEl = document.createElement('h3').cloneNode(true) as Element;
    titleEl.classList.add('widgettitle');
    titleEl.innerHTML = widget ? divEl.querySelector('.widgettitle')?.textContent || '' : '';

    const listElement = document.createElement('ul').cloneNode(true) as Element;
    listElement.classList.add('linklist');
    listElement.innerHTML = divEl.querySelector('.linklist')?.innerHTML || DEFAULT_HTML_LIST_STRING;

    divEl.remove();
    titleEl.remove();
    listElement.remove();

    return {
      ...newWidget,
      element: divEl,
      details: { title: widget?.details ? JSON.parse(widget?.details || '').title : titleEl.textContent },
      listElement,
      type: widget?.type || '',
      html: widget?.html || '',
    };
  };

  const onSelectWidgetType = (name: string, value: FormValue): void => {
    const widgetDefault = {
      ...newWidget,
      html: '',
    };
    if (name === 'status') {
      setWidgetStatus(+value);
      if (+value === WIDGET_STATUS_TYPE.NEW) {
        setDuplicateWidget({} as WidgetDTO);
        createDefaultElements();
      }
      return;
    }
    if (name === 'type') setNewWidget({ ...widgetDefault, type: value.toString() });
  };

  const onSelectDuplicateWidget = (name: string, value: FormValue): void => {
    const selected = widgets.find((item) => +value === item.id);
    if (selected) {
      setDuplicateWidget(selected);
      createDefaultElements(selected);
    }
  };

  const onCreateWidget = (): void => {
    const content = newWidget.element.querySelector('.htmlcontents');
    if (!content) return;

    let title = content.querySelector('.widgettitle') as Element;
    if (title) {
      title.textContent = newWidget.details.title;
    } else {
      title = document.createElement('h3');
      title.classList.add('widgettitle');
      title.textContent = newWidget.details.title;
      content.insertBefore(title, content.firstChild);
    }

    if (!duplicateWidget.id) {
      newWidget.element.querySelector('.widgetwrapper')?.classList.add(`${newWidget.type}widget`);

      const parser = new DOMParser();
      const doc = parser.parseFromString(newWidget.html, 'text/html');
      content.append(doc.body.childNodes[0]);
    }

    const newAddedWidget = {
      details: JSON.stringify(newWidget.details),
      type: newWidget.type,
      html: newWidget.element.innerHTML.toString(),
    };

    onSave(newAddedWidget);
    createDefaultElements();
    setDuplicateWidget({} as WidgetDTO);
  };

  const onInputChange = (name: string, value: FormValue): void => {
    if (name === 'details') {
      setNewWidget({ ...newWidget, details: { title: value.toString() } });
    }
    if (name === 'html' || name === 'video') {
      setNewWidget({ ...newWidget, html: value.toString() });
    }
  };

  const handleFileChange = async (files: FileList): Promise<void> => {
    const slider = document.createElement('div');
    const sliderContainer = document.createElement('div');
    sliderContainer.classList.add('slidecontainer');
    slider.appendChild(sliderContainer);

    const sliderInner = document.createElement('div');
    sliderInner.classList.add('slidecontainerinner');

    for (const key of Object.keys(files)) {
      const formData = new FormData();
      formData.append('file', files[+key]);
      const { data } = await createSlideshowImageAttachment(formData);

      const image = document.createElement('img');
      image.setAttribute('src', data.s3Url);
      image.setAttribute('data-image-type', newWidget.type);
      image.setAttribute('data-image-name', files[+key].name);
      image.setAttribute('data-image-id', key);
      sliderInner.appendChild(image);
    }

    sliderContainer.appendChild(sliderInner);
    setNewWidget({ ...newWidget, html: slider.innerHTML.toString() });

    slider.remove();
    sliderContainer.remove();
    sliderInner.remove();
  };

  const onRemoveFile = (file: File, index: number): void => {
    const slider = document.createElement('div');
    slider.innerHTML = newWidget.html;
    const sliderInner = slider.querySelectorAll('.slidecontainerinner img');
    sliderInner.item(index).remove();
    setNewWidget({ ...newWidget, html: slider.innerHTML.toString() });
    slider.remove();
  };

  const onLinkValueChange = (value: FormValue, index: number, attr?: string): void => {
    const wrapper = document.createElement('div');
    const listElement = newWidget.listElement.children[index] as Element;
    const linkItem = listElement.querySelector('a') as Element;
    wrapper.appendChild(newWidget.listElement);

    if (attr === 'href') {
      linkItem?.setAttribute(attr, value.toString());
      setNewWidget({
        ...newWidget,
        listElement: listElement.parentElement as Element,
        html: wrapper.innerHTML.toString(),
      });
      return;
    }
    linkItem.textContent = value.toString();
    setNewWidget({ ...newWidget, html: wrapper.innerHTML.toString() });
  };

  const onAddListItem = (): void => {
    const listItem = document.createElement('li');
    const linkItem = document.createElement('a');
    listItem.appendChild(linkItem);
    newWidget.listElement.appendChild(listItem);
    setNewWidget({ ...newWidget });
  };

  const onRemoveListItem = (item: Element, index: number): void => {
    if (newWidget.listElement.children.length === 1) return;
    newWidget.listElement.children[index].remove();
    setNewWidget({ ...newWidget, html: newWidget.listElement.innerHTML.toString() });
  };

  const getListItemUrl = (item: Element): string => {
    const link = item.querySelector('a');
    return link?.getAttribute('href') || '';
  };

  return (
    <Modal title="Add new widget to group" isOpen={isModalOpen} onClose={handleModalClose}>
      <ViewInput
        name="status"
        value={widgetStatus}
        onChange={onSelectWidgetType}
        inline={true}
        fieldType="radio"
        options={WIDGET_STATUS_OPTIONS}
        isEdit={true}
      />
      {widgetStatus === WIDGET_STATUS_TYPE.DUPLICATE && (
        <>
          <ViewInput
            name="duplicate"
            onChange={onSelectDuplicateWidget}
            title="Choose school widget"
            placeholder="Choose existing school widget"
            fieldType="select"
            value={duplicateWidget.id}
            options={schoolWidgetsOptions}
            isEdit={true}
          />
          {duplicateWidget.id && (
            <ViewInput
              name="details"
              value={newWidget.details.title || ''}
              onChange={onInputChange}
              placeholder="Widget name"
              title="Widget name"
              fieldType="text"
              isEdit={true}
            />
          )}
        </>
      )}

      {widgetStatus === WIDGET_STATUS_TYPE.NEW && (
        <>
          <ViewInput
            name="details"
            value={newWidget.details.title || ''}
            onChange={onInputChange}
            placeholder="Widget name"
            title="Widget name"
            fieldType="text"
            isEdit={true}
          />
          <ViewInput
            name="type"
            value={newWidget.type}
            placeholder="Choose widget type"
            onChange={onSelectWidgetType}
            title="Choose widget type"
            fieldType="select"
            options={NEW_WIDGET_OPTIONS}
            isEdit={true}
          />
          {newWidget.type === WIDGET_TYPE.HTML && (
            <ViewInput
              name="html"
              onChange={onInputChange}
              placeholder="Paste HTML code here..."
              title="HTML code"
              fieldType="textarea"
              isEdit={true}
              value={newWidget?.html || ''}
            />
          )}
          {newWidget.type === WIDGET_TYPE.VIDEO && (
            <ViewInput
              name="video"
              onChange={onInputChange}
              placeholder="Paste video embed code..."
              title="Video embed code"
              fieldType="textarea"
              isEdit={true}
              value={newWidget?.html || ''}
            />
          )}
          {newWidget.type === WIDGET_TYPE.SLIDESHOW && (
            <div>
              <FileUploadInput
                handleChange={handleFileChange}
                onRemoveFile={onRemoveFile}
                enablePreview={true}
                multiple={true}
              />
            </div>
          )}
          {newWidget.type === WIDGET_TYPE.FILELIST && (
            <>
              {Array.from(newWidget.listElement.children).map((item, index) => (
                <div key={`${index.toString()}`}>
                  <Grid item md={11} xs={10} className="mba-no-padding">
                    <ViewInput
                      name="title"
                      onChange={(_, value): void => onLinkValueChange(value, index)}
                      title={`Link ${index + 1} title`}
                      fieldType="text"
                      isEdit={true}
                      value={item.textContent || ''}
                    />
                  </Grid>
                  <Grid item md={1} xs={2} className="mba-no-padding">
                    {newWidget.listElement.children.length > 1 && (
                      <button onClick={(): void => onRemoveListItem(item, index)} className={styles.widgetButton}>
                        <IconDelete width="20" height="20" />
                      </button>
                    )}
                  </Grid>
                  <Grid item md={11} xs={10} className="mba-no-padding">
                    <ViewInput
                      name="title"
                      onChange={(_, value): void => onLinkValueChange(value, index, 'href')}
                      title={`Link ${index + 1} URL`}
                      fieldType="text"
                      isEdit={true}
                      value={getListItemUrl(item)}
                    />
                  </Grid>
                </div>
              ))}
              <Grid item md={12} xs={12} className="mba-no-padding mba-mb-20">
                <button onClick={onAddListItem} className={`${styles.widgetButton} ${styles.primary} mba-no-margin`}>
                  + Add link
                </button>
              </Grid>
            </>
          )}
        </>
      )}
      <Grid item md={12} xs={12} className="mba-no-padding mba-mt-20">
        <Button text="Cancel" size={ButtonSizes.big} onClick={handleModalClose} wrapperClass="mba-mr-10" />
        <Button
          primary
          text="Create"
          size={ButtonSizes.big}
          isLoading={isLoading}
          disabled={!newWidget.html}
          onClick={onCreateWidget}
        />
      </Grid>
    </Modal>
  );
};

export default CreateWidgetModal;

import { SchoolLeadsDetails } from './school-leads-details.dto';
import {
  loadSchoolSingleLeadAction,
  LOAD_SINGLE_SCHOOL_LEAD,
  SetSchoolSingleLeadAction,
  SET_SINGLE_SCHOOL_LEAD,
} from './school-leads-details.types';

export const loadSchoolSingleLead = (leadId: string): loadSchoolSingleLeadAction => ({
  type: LOAD_SINGLE_SCHOOL_LEAD,
  payload: { leadId },
});

export const setSchoolSingleLead = (lead: SchoolLeadsDetails): SetSchoolSingleLeadAction => ({
  type: SET_SINGLE_SCHOOL_LEAD,
  payload: lead,
});

import { RootState } from '../../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const selectAccreditations = (state: RootState) => state.rankings.accreditations;

export const selectAccreditationsTableData = createDeepEqualSelector(selectAccreditations, (accreditations) => {
  return accreditations.data;
});

export const selectAccreditationsPageConfiguration = createDeepEqualSelector(selectAccreditations, (accreditations) => {
  return {
    filter: accreditations.filter,
    limit: accreditations.pagination.limit,
    order: accreditations.sortOrder.order,
    page: accreditations.pagination.page,
    search: accreditations.search,
    sortBy: accreditations.sortOrder.sortBy,
    totalPages: accreditations.pagination.totalPages,
  };
});

import React, { FunctionComponent } from 'react';

import Accounts, { schoolAccountsListReducer } from './Accounts';
import SchoolProfile from './SchoolProfile';
import SchoolsList from './SchoolsList/components';
import { PageRouter } from '../App/Main/components/Layout';
import { PageProps } from '../../shared/constants';
import { combineReducers } from 'redux';
import { schoolDetailsReducer } from './SchoolProfile/SchoolDetails';
import { schoolsListReducer } from './SchoolsList';
import { campusProfileReducer } from './SchoolProfile/CampusProfile';
import { campusesReducer } from './SchoolProfile/CampusesList';
import { schoolUserProfileReducer } from './SchoolUserProfile';
import { schoolUsersListReducer } from './SchoolProfile/SchoolUsersList';
import { partnershipsReducer } from './SchoolProfile/SchoolPartnerships';
import { schoolRankingsReducer } from './SchoolProfile/SchoolRankings';

const componentsMap = {
  accounts: Accounts,
  list: SchoolsList,
  profile: SchoolProfile,
};

const Schools: FunctionComponent<PageProps> = ({ navigation, route }) => {
  let schoolsNavigation = { ...navigation };
  let content = <div>Schools</div>;

  if (navigation) {
    schoolsNavigation = {
      ...navigation,
      profile: {
        label: 'School profile',
        route: '/profile/:id',
      },
    };
    content = <PageRouter mainRoute={route} navigation={schoolsNavigation} componentsMap={componentsMap} />;
  }

  return content;
};

export default Schools;

export const schoolsReducer = combineReducers({
  // All School users
  accounts: schoolAccountsListReducer,

  // Schools
  current: schoolDetailsReducer,
  list: schoolsListReducer,

  // Campuses
  campus: campusProfileReducer,
  campuses: campusesReducer,

  // School Users per School
  user: schoolUserProfileReducer,
  users: schoolUsersListReducer,

  // Partnerships
  partnerships: partnershipsReducer,

  // School Rankings
  rankings: schoolRankingsReducer,
});

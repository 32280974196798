import { RootState } from '../../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const schoolsSelector = (state: RootState) => state.schools;

export const selectCurrentSchool = createDeepEqualSelector(schoolsSelector, (schools) => {
  return schools.current;
});

import { RootState } from '../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const schoolsListSelector = (state: RootState) => state.schools.list;

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const selectSchoolsTableData = createDeepEqualSelector(schoolsListSelector, (schoolsLists) => {
  return schoolsLists.data;
});

export const selectSchoolsPageConfiguration = createDeepEqualSelector(schoolsListSelector, (schoolsLists) => {
  return {
    filter: schoolsLists.filter,
    limit: schoolsLists.pagination.limit,
    order: schoolsLists.sortOrder.order,
    page: schoolsLists.pagination.page,
    search: schoolsLists.search,
    sortBy: schoolsLists.sortOrder.sortBy,
    totalPages: schoolsLists.pagination.totalPages,
  };
});

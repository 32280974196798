import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SCHOOL_LEADS_TABLE_FILTERS, schoolLeadStatuses } from './SchoolLeadsFilters.constants';
import { PageFilterConfig, PageSearchQueryConfig, Resource, Status } from '../../../../shared/constants';
import FilterGroup, { mapInitialValuesToFiltersConfig } from '../../../../components/FilterGroup';
import { selectSchoolLeadPageConfiguration } from '../../../../shared/state/leads/leads.selectors';
import { loadSchoolLeadsAction } from '../../../../shared/state/leads/leads.actions';

type SchoolLeadsFiltersProps = {
  schoolId: number;
  pageConfig?: PageSearchQueryConfig;
  filtersData: { countries: Resource[] };
};

const SchoolLeadsFilters: FunctionComponent<SchoolLeadsFiltersProps> = ({ schoolId, filtersData }) => {
  const dispatch = useDispatch();
  const [leadsFilters, setLeadsFilters] = useState({});
  const pageConfig = useSelector(selectSchoolLeadPageConfiguration);

  useEffect(() => {
    const { countries } = filtersData;

    setLeadsFilters(
      mapInitialValuesToFiltersConfig(
        SCHOOL_LEADS_TABLE_FILTERS,
        {
          status: schoolLeadStatuses,
          countryId: countries,
        },
        pageConfig.filter,
        true,
      ),
    );
  }, [pageConfig.filter, filtersData]);

  const handleFiltersChange = (newFilters: PageFilterConfig): void => {
    dispatch(
      loadSchoolLeadsAction(schoolId, {
        ...pageConfig,
        filter: newFilters,
      }),
    );
  };

  return (
    <div className="sticky-sidebar-container">
      <FilterGroup
        defaultFilters={pageConfig.filter || {}}
        filtersConfig={leadsFilters}
        onFiltersChanged={handleFiltersChange}
      />
    </div>
  );
};

export default SchoolLeadsFilters;

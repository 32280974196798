import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import EditableGroup from '../../../../components/EditableGroup';
import { FormDataValues } from '../../../../components/Form';
import PageLoader from '../../../../components/PageLoader';
import Typography, { TypographyVariants } from '../../../../components/Typography';
import { selectRequestStatus } from '../../../../shared/state/global-request';
import { SchoolsWithLeadGenDTO } from '../../SchoolsWithLeadGen';
import { getSchoolWithLeadGenByIdRequest } from '../../SchoolsWithLeadGen/schools-with-lead-gen.api';
import { updateSchoolActiveAction, updateSchoolWithLeadDetailsAction } from './school-with-lead-details.actions';
import { UPDATE_SCHOOL_WITH_LEAD_DETAILS } from './school-with-lead-details.types';
import { SCHOOL_WITH_LEAD_DETAILS_CONFIG, UpdateSchoolWithLeadDetailsDto } from './SchoolWithLeadDetails.constants';
import moment from 'moment';

const SchoolWithLeadDetails: FunctionComponent = () => {
  const { schoolId } = useParams<{ schoolId: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [isStatusSuccess, setIsStatusSuccess] = useState(false);
  const [schoolWithLeadData, setSchoolWithLeadData] = useState({} as SchoolsWithLeadGenDTO);
  const updateStatus = useSelector(selectRequestStatus(UPDATE_SCHOOL_WITH_LEAD_DETAILS));
  const dispatch = useDispatch();

  const getSchoolWithLeadGen = (schoolId: number): void => {
    getSchoolWithLeadGenByIdRequest(+schoolId)
      .then(({ data }) => {
        setIsLoading(false);
        setIsStatusSuccess(true);
        setSchoolWithLeadData(data);
      })
      .catch((err) => {
        setIsStatusSuccess(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getSchoolWithLeadGen(+schoolId);
  }, [schoolId]);

  useEffect(() => {
    if (updateStatus.isSuccess) {
      getSchoolWithLeadGen(+schoolId);
    }
  }, [schoolId, updateStatus.isSuccess]);

  const updateSchoolWithLeadDetails = (data: FormDataValues): void => {
    const activeFromUTC = moment(data.activeFrom.toString()).toISOString() ?? '';
    const activeToUTC = moment(data.activeTo.toString()).toISOString() ?? '';

    const updatedData = {
      activeFrom: data.activeFrom ? activeFromUTC : null,
      activeTo: data.activeTo ? activeToUTC : null,
      status: data.status,
      schoolName: data.schoolName,
    } as UpdateSchoolWithLeadDetailsDto;

    dispatch(updateSchoolWithLeadDetailsAction(+schoolId, updatedData));

    if (data.activeTo && data.activeFrom) {
      const now = Date.now();
      const active =
        new Date(data.activeTo as string | number).getTime() >= now &&
        new Date(data.activeFrom as string | number).getTime() <= now &&
        data.status !== 1;

      dispatch(updateSchoolActiveAction(+schoolId, active));
      return;
    }

    dispatch(updateSchoolActiveAction(+schoolId, data.status === 0));
  };

  return (
    <>
      {isStatusSuccess && (
        <EditableGroup
          columnSize={4}
          data={{
            ...schoolWithLeadData,
            leads: schoolWithLeadData.leadCount,
          }}
          formConfig={SCHOOL_WITH_LEAD_DETAILS_CONFIG}
          heading={
            <Typography component="p" variant={TypographyVariants.h2} className="mba-heading--title-2">
              School Details
            </Typography>
          }
          onEditSubmit={updateSchoolWithLeadDetails}
        />
      )}
      {isLoading && <PageLoader />}
    </>
  );
};

export default SchoolWithLeadDetails;

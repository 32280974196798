import React from 'react';

const NotFound: React.FunctionComponent = () => (
  <h3>
    Oops, looks like you have arrived at a nonexistent URL.
    <br />
    Please go back and try again.
  </h3>
);

export default NotFound;

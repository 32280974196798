import { AccreditationDTO } from '../AccreditationProfile';
import { get } from '../../../../shared/api';
import { PagedResponseDTO, PageSearchQueryConfig } from '../../../../shared/constants';
import { generateSearchQuery } from '../../../../shared/helpers';

export const getAccreditationsRequest = (
  pageConfig: PageSearchQueryConfig = {},
): Promise<PagedResponseDTO<AccreditationDTO[]>> => {
  const query = generateSearchQuery(pageConfig);
  return get<PagedResponseDTO<AccreditationDTO[]>>(`/accreditations${query}`);
};

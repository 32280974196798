import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

import { setAccreditationsAction } from './accreditations.actions';
import { getAccreditationsRequest } from './accreditations.api';
import { AccreditationsActionTypes, LOAD_ACCREDITATIONS, LoadAccreditationsAction } from './accreditations.types';
import { AccreditationDTO } from '../AccreditationProfile';
import { PagedResponseDTO } from '../../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../../shared/state/global-request';

function* loadAccreditationsTableData(
  action: LoadAccreditationsAction,
): Generator<
  | CallEffect<PagedResponseDTO<AccreditationDTO[]>>
  | PutEffect<AccreditationsActionTypes>
  | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig } = action.payload;
    const { data, pagination, sortOrder } = (yield call(getAccreditationsRequest, pageConfig)) as PagedResponseDTO<
      AccreditationDTO[]
    >;
    yield put(setAccreditationsAction({ data, pagination, sortOrder }));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* accreditationsSaga(): Generator {
  yield takeLatest(LOAD_ACCREDITATIONS, loadAccreditationsTableData);
}

import { push, RouterAction } from 'react-router-redux';
import { call, CallEffect, put, PutEffect, select, SelectEffect, takeEvery, takeLatest } from 'redux-saga/effects';

import { loadRankingCategoryAction, setRankingCategoryAction } from './ranking-category-profile.actions';
import {
  createRankingCategoryRequest,
  deleteRankingCategoryRequest,
  getRankingCategoryRequest,
  updateRankingCategoryRequest,
} from './ranking-category-profile.api';
import { RankingCategoryDTO } from './ranking-category-profile.dto';
import {
  CLEAR_CURRENT_RANKING_CATEGORY,
  CREATE_RANKING_CATEGORY,
  CreateRankingCategoryAction,
  DELETE_RANKING_CATEGORY,
  DeleteRankingCategoryAction,
  EDIT_RANKING_CATEGORY,
  EDIT_RANKING_CATEGORY_DETAILS,
  EditRankingCategoryAction,
  EditRankingCategoryDetailsAction,
  LOAD_RANKING_CATEGORY,
  LoadRankingCategoryAction,
  RankingCategoryActionTypes,
} from './ranking-category-profile.types';
import {
  loadRankingCategoriesAction,
  RankingCategoriesActionTypes,
  selectRankingCategoriesPageConfiguration,
} from '../RankingCategoriesList';
import { BasicResponseDTO, DefaultResponseDTO, PageSearchQueryConfig } from '../../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../../shared/state/global-request';

function* createNewRankingCategorySaga(
  action: CreateRankingCategoryAction,
): Generator<
  | PutEffect<GlobalRequestActions | RankingCategoryActionTypes | RankingCategoriesActionTypes>
  | CallEffect<DefaultResponseDTO<RankingCategoryDTO>>
> {
  const {
    type: actionType,
    payload: { data },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(createRankingCategoryRequest, data);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadRankingCategoriesAction());
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* loadRankingCategorySaga(
  action: LoadRankingCategoryAction,
): Generator<
  CallEffect<DefaultResponseDTO<RankingCategoryDTO>> | PutEffect<GlobalRequestActions | RankingCategoryActionTypes>
> {
  const {
    type: actionType,
    payload: { id },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getRankingCategoryRequest, id)) as DefaultResponseDTO<RankingCategoryDTO>;
    yield put(setRankingCategoryAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* editRankingCategorySaga(
  action: EditRankingCategoryAction,
): Generator<
  PutEffect<GlobalRequestActions | RankingCategoriesActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect
> {
  const {
    type: actionType,
    payload: { data },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateRankingCategoryRequest, data);
    yield put(setRequestSucceededAction(actionType));
    const pageConfig = (yield select(selectRankingCategoriesPageConfiguration)) as PageSearchQueryConfig;
    yield put(loadRankingCategoriesAction(pageConfig));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* editRankingCategoryDetailsSaga(
  action: EditRankingCategoryDetailsAction,
): Generator<
  PutEffect<GlobalRequestActions | RankingCategoryActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect
> {
  const {
    type: actionType,
    payload: { data },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateRankingCategoryRequest, data);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadRankingCategoryAction(data.id));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* deleteRankingCategorySaga(
  action: DeleteRankingCategoryAction,
): Generator<PutEffect<GlobalRequestActions | RouterAction> | CallEffect<BasicResponseDTO>> {
  const {
    type: actionType,
    payload: { id },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(deleteRankingCategoryRequest, id);
    yield put(setRequestSucceededAction(actionType));
    yield put(push('/rankings/categorization/ranking-categories'));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* resetRankingCategoryErrors(): Generator<PutEffect<GlobalRequestActions>> {
  yield put(setRequestSucceededAction(CREATE_RANKING_CATEGORY));
  yield put(setRequestSucceededAction(EDIT_RANKING_CATEGORY));
  yield put(setRequestSucceededAction(EDIT_RANKING_CATEGORY_DETAILS));
}

export default function* rankingCategoriesSaga(): Generator {
  yield takeLatest(CREATE_RANKING_CATEGORY, createNewRankingCategorySaga);
  yield takeLatest(LOAD_RANKING_CATEGORY, loadRankingCategorySaga);
  yield takeLatest(EDIT_RANKING_CATEGORY, editRankingCategorySaga);
  yield takeLatest(EDIT_RANKING_CATEGORY_DETAILS, editRankingCategoryDetailsSaga);
  yield takeLatest(DELETE_RANKING_CATEGORY, deleteRankingCategorySaga);
  yield takeEvery(CLEAR_CURRENT_RANKING_CATEGORY, resetRankingCategoryErrors);
}

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import Button, { ButtonSizes } from '../../../../../../components/Button';
import EditableGroup from '../../../../../../components/EditableGroup';
import { FormConfig, FormDataValues } from '../../../../../../components/Form';
import Modal, { MODAL_ANIMATION_DURATION } from '../../../../../../components/Modal';
import Typography, { TypographyVariants } from '../../../../../../components/Typography';
import { compareValues } from '../../../../../../shared/helpers';
import {
  selectRequestErrorMessage,
  selectRequestFormErrors,
  selectRequestIsLoading,
  setRequestSucceededAction,
} from '../../../../../../shared/state/global-request';
import { loadResourcesAction, selectResources } from '../../../../../../shared/state/resources';
import { loadStatusesAction, selectStatuses } from '../../../../../../shared/state/statuses';
import { deleteRankingAction, editRankingDetailsAction, loadRankingAction } from '../ranking-profile.actions';
import { updateRankingSpecialReportRequest } from '../ranking-profile.api';
import { EditRankingDTO, RankingDTO, RankingSpecialReportDTO } from '../ranking-profile.dto';
import { selectCurrentRanking } from '../ranking-profile.selectors';
import { DELETE_RANKING, EDIT_RANKING_DETAILS, RankingProfileRouteParams } from '../ranking-profile.types';
import RankingDeleteConfirmation from './RankingDeleteConfirmation';
import { RANKING_DETAILS_CONFIG, RANKING_NOTE_CONFIG, SPECIAL_REPORT_CONFIG } from './RankingProfile.constants';
import SpecialReportImage from './SpecialReportImage';

const RankingProfile: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id: rankingId } = useParams<RankingProfileRouteParams>();
  const currentRanking = useSelector(selectCurrentRanking) as RankingDTO;
  const deleteRankingError = useSelector(selectRequestErrorMessage(DELETE_RANKING));
  const formErrors = useSelector(selectRequestFormErrors(EDIT_RANKING_DETAILS));
  const isEditSaving = useSelector(selectRequestIsLoading(EDIT_RANKING_DETAILS));
  const rankingStatuses = useSelector(selectStatuses('ranking'));
  const rankingTypes = useSelector(selectResources('ranking-types'));
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [noteInitialValue, setNoteInitialValue] = useState(currentRanking);
  const [detailInitialValue, setDetailInitialValue] = useState(currentRanking);
  const [specialReportInitialValue, setSpecialReportInitialValue] = useState(currentRanking);
  const { allowDelete, id, name } = currentRanking as RankingDTO;

  useEffect(() => {
    if (rankingId) {
      dispatch(loadRankingAction(parseInt(rankingId)));
      dispatch(loadStatusesAction('ranking'));
      dispatch(loadResourcesAction('ranking-types'));
    }
  }, [dispatch, rankingId]);

  useEffect(() => {
    if (!(currentRanking && currentRanking.note === noteInitialValue?.note)) {
      setNoteInitialValue({ note: currentRanking.note } as RankingDTO);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRanking]);

  const rankingDetailsFormConfig: FormConfig = {
    ...RANKING_DETAILS_CONFIG,
    status: {
      ...RANKING_DETAILS_CONFIG.status,
      options: rankingStatuses.map(({ key, label }) => ({ label, value: key })),
    },
    rankingTypeId: {
      ...RANKING_DETAILS_CONFIG.rankingTypeId,
      options: rankingTypes.map(({ key, label }) => ({ label, value: key })),
    },
  };

  useEffect(() => {
    if (!compareValues(currentRanking, detailInitialValue, Object.keys(rankingDetailsFormConfig))) {
      setDetailInitialValue(currentRanking);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRanking]);

  useEffect(() => {
    if (!compareValues(currentRanking, specialReportInitialValue, Object.keys(SPECIAL_REPORT_CONFIG))) {
      setSpecialReportInitialValue(currentRanking);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRanking]);

  const updateRanking = (data: FormDataValues): void => {
    dispatch(editRankingDetailsAction({ ...data, id } as EditRankingDTO));
  };

  const updateSpecialReport = async (data: FormDataValues | RankingSpecialReportDTO): Promise<void> => {
    try {
      const res = await updateRankingSpecialReportRequest(+rankingId, data as RankingSpecialReportDTO);

      if (res) {
        dispatch(loadRankingAction(parseInt(rankingId)));
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const resetFormState = (): void => {
    dispatch(setRequestSucceededAction(EDIT_RANKING_DETAILS));
  };

  const handleDeleteRankingConfirm = (): void => {
    dispatch(deleteRankingAction(id));
  };

  const openModal = (): void => {
    setIsConfirmPopupOpen(true);
  };

  const closeModal = (): void => {
    setIsConfirmPopupOpen(false);

    setTimeout(() => {
      dispatch(setRequestSucceededAction(DELETE_RANKING));
    }, MODAL_ANIMATION_DURATION);
  };

  return (
    <>
      <EditableGroup<RankingDTO>
        columnSize={5}
        data={detailInitialValue}
        editButtonLabel="Edit details"
        formConfig={rankingDetailsFormConfig}
        formErrors={formErrors}
        isLoading={isEditSaving}
        heading={
          <Typography component="p" variant={TypographyVariants.h3} className="mba-heading--title-2">
            Details
          </Typography>
        }
        onEditSubmit={updateRanking}
        onEditCancel={resetFormState}
      />
      <hr className="mba-separator" />
      <EditableGroup<RankingDTO>
        data={noteInitialValue}
        editButtonLabel="Edit notes"
        formConfig={RANKING_NOTE_CONFIG}
        isLoading={isEditSaving}
        formErrors={formErrors}
        heading={
          <Typography component="p" variant={TypographyVariants.h3} className="mba-heading--title-2">
            Notes
          </Typography>
        }
        onEditSubmit={updateRanking}
        onEditCancel={resetFormState}
      />
      <hr className="mba-separator" />

      <EditableGroup<RankingSpecialReportDTO>
        columnSize={6}
        data={specialReportInitialValue}
        editButtonLabel="Edit special report"
        formConfig={SPECIAL_REPORT_CONFIG}
        formErrors={formErrors}
        isLoading={isEditSaving}
        heading={
          <Typography component="p" variant={TypographyVariants.h3} className="mba-heading--title-2">
            Special report
          </Typography>
        }
        onEditSubmit={updateSpecialReport}
        onEditCancel={resetFormState}
        prefixColumn={{
          content: <SpecialReportImage specialReportImageUrl={currentRanking.specialReportImageUrl} />,
          columnSize: { xs: 12, md: 4, lg: 3 },
        }}
      />
      <hr className="mba-separator" />

      {allowDelete && (
        <>
          <hr className="mba-separator" />
          <div className="mba-heading--wrapper mba-heading--table">
            <Typography component="p" variant={TypographyVariants.h3} className="mba-heading--title-2">
              Danger zone
            </Typography>
            <Button primary danger text="Delete ranking" size={ButtonSizes.big} onClick={openModal} />
          </div>
          <br />
          <br />
          <Modal title="Delete a ranking" isOpen={isConfirmPopupOpen} onClose={closeModal}>
            <RankingDeleteConfirmation
              error={deleteRankingError}
              name={name}
              onCancel={closeModal}
              onConfirm={handleDeleteRankingConfirm}
            />
          </Modal>
        </>
      )}
    </>
  );
};

export default RankingProfile;

import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';

import {
  FORM_GROUP_TYPE,
  FormConfig,
  FormConfigProps,
  FormControlProps,
  FormDataValues,
  FormErrors,
  FormValue,
  FormControlFieldType,
} from './Form.constants';
import {
  generateFormFields,
  generateFormGroupFields,
  getErrorMessage,
  getFormValues,
  getGroupControls,
  getSimpleControls,
  initializeFormFields,
} from './Form.helpers';
import Button, { ButtonSizes, ButtonTypes } from '../Button';
import { FORM_GROUP_TITLE } from '../../containers/Schools/SchoolsList/components/SchoolsList.constants';

interface AdditionalFormAction {
  label: string;
  onClick: () => void;
}

type FormProps = {
  id: string;
  isLoading?: boolean;
  config: FormConfig;
  customActions?: ReactNode;
  errors: FormErrors;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValues?: any;
  actions?: AdditionalFormAction[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (data: any) => void;
  onValuesChanged?: (data: FormDataValues) => void;
  onRemove?: (id: number) => void;
  submitButtonText?: string;
  shouldDisplayAddGroupButton?: boolean;
  addGroupButtonText?: string;
  actionsContainerWrapperClass?: string;
};

const Form: FunctionComponent<FormProps> = ({
  id,
  isLoading = false,
  config,
  customActions,
  errors = {},
  initialValues = {},
  actions,
  onSubmit,
  onValuesChanged,
  submitButtonText = 'Submit',
  shouldDisplayAddGroupButton = true,
  addGroupButtonText,
  onRemove,
  actionsContainerWrapperClass,
}) => {
  const [controls, setControls] = useState({} as FormConfigProps);
  const [isFormInvalid, setIsFormInvalid] = useState(true);

  const simpleControls = getSimpleControls(controls);
  const groupControls = getGroupControls(controls);

  useEffect(() => {
    const controlsData = initializeFormFields(controls, config, initialValues, errors);
    const hasError = Object.values(controlsData).some(
      (control) => control && !control.valid && control.fieldType !== FORM_GROUP_TYPE,
    );
    setIsFormInvalid(hasError);
    setControls(controlsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, initialValues, errors]);

  const inputChangedHandler = (name: string, value: FormValue): void => {
    const oldControls: FormConfigProps = controls ? { ...controls } : {};
    const error = getErrorMessage(
      value,
      (controls[name] as FormControlProps).required,
      (controls[name] as FormControlProps).validations,
      (controls[name] as FormControlProps).fieldType,
    );

    const currentControl = {
      [name]: {
        ...(oldControls[name] as FormControlProps),
        error,
        touched: true,
        valid: !error,
        value,
      },
    };

    const updatedControls: FormConfigProps = {
      ...oldControls,
      ...currentControl,
    };

    const hasError = Object.values(updatedControls).some((control) => control && !control.valid);
    setIsFormInvalid(hasError);
    setControls(updatedControls);

    onValuesChanged && onValuesChanged(getFormValues(updatedControls));
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    onSubmit(getFormValues(controls));
  };

  const handleAddButton = (groupName: string, index: number): void => {
    const formGroupConfig = Object.keys(config)
      .filter((fieldKey) => fieldKey.startsWith(`${groupName}[0]`) && !fieldKey.endsWith(`[${FORM_GROUP_TITLE}]`))
      .reduce((obj: FormConfig, key = '') => {
        const fieldKey = key.replace('[0]', `[${index}]`);
        obj[fieldKey] = { ...config[key], id: `form-new-field-${key}` };
        return obj;
      }, {});

    const emptyGroupFields: FormConfigProps = initializeFormFields(controls, formGroupConfig, {}, {});
    setControls({ ...controls, ...emptyGroupFields });
    setIsFormInvalid(false);
  };

  const handleRemoveButton = (groupName: string, index: number): void => {
    if (onRemove) {
      onRemove(index);
    } else {
      const newControls = Object.keys(controls)
        .filter(
          (fieldKey) => !fieldKey.startsWith(`${groupName}[${index}]`) || fieldKey.endsWith(`[${FORM_GROUP_TITLE}]`),
        )
        .reduce((obj: FormConfigProps, key) => {
          obj[key] = controls[key];
          return obj;
        }, {});
      setControls(newControls);
      setIsFormInvalid(false);
    }
  };

  return (
    <form id={id} onSubmit={handleFormSubmit}>
      {generateFormFields(simpleControls, inputChangedHandler)}
      {generateFormGroupFields(
        groupControls,
        inputChangedHandler,
        {
          onClickRemove: handleRemoveButton,
          onClickAdd: handleAddButton,
        },
        shouldDisplayAddGroupButton,
        addGroupButtonText,
      )}
      {customActions ? (
        customActions
      ) : (
        <div className={actionsContainerWrapperClass ? actionsContainerWrapperClass : 'mba-actions'}>
          {actions &&
            actions.map(({ label, onClick }, index: number) => (
              <Button key={`${id}-action-${index}`} text={label} size={ButtonSizes.big} onClick={onClick} />
            ))}
          <Button
            isLoading={isLoading}
            text={submitButtonText}
            size={ButtonSizes.big}
            primary
            type={ButtonTypes.submit}
            disabled={isLoading || isFormInvalid}
          />
        </div>
      )}
    </form>
  );
};

export default Form;

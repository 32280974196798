import { push, RouterAction } from 'react-router-redux';
import { call, CallEffect, put, PutEffect, select, SelectEffect, takeEvery, takeLatest } from 'redux-saga/effects';

import { loadRankingTypeAction, setRankingTypeAction } from './ranking-type-profile.actions';
import {
  createRankingTypeRequest,
  deleteRankingTypeRequest,
  getRankingTypeRequest,
  updateRankingTypeRequest,
} from './ranking-type-profile.api';
import { RankingTypeDTO } from './ranking-type-profile.dto';
import {
  CLEAR_CURRENT_RANKING_TYPE,
  CREATE_RANKING_TYPE,
  CreateRankingTypeAction,
  DELETE_RANKING_TYPE,
  DeleteRankingTypeAction,
  EDIT_RANKING_TYPE,
  EDIT_RANKING_TYPE_DETAILS,
  EditRankingTypeAction,
  EditRankingTypeDetailsAction,
  LOAD_RANKING_TYPE,
  LoadRankingTypeAction,
  RankingTypeActionTypes,
} from './ranking-type-profile.types';
import {
  loadRankingTypesAction,
  RankingTypesActionTypes,
  selectRankingTypesPageConfiguration,
} from '../RankingTypesList';
import { BasicResponseDTO, DefaultResponseDTO, PageSearchQueryConfig } from '../../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../../shared/state/global-request';

function* createNewRankingTypeSaga(
  action: CreateRankingTypeAction,
): Generator<
  | PutEffect<GlobalRequestActions | RankingTypeActionTypes | RankingTypesActionTypes>
  | CallEffect<DefaultResponseDTO<RankingTypeDTO>>
> {
  const {
    type: actionType,
    payload: { data },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(createRankingTypeRequest, data);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadRankingTypesAction());
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* loadRankingTypeSaga(
  action: LoadRankingTypeAction,
): Generator<
  CallEffect<DefaultResponseDTO<RankingTypeDTO>> | PutEffect<GlobalRequestActions | RankingTypeActionTypes>
> {
  const {
    type: actionType,
    payload: { id },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getRankingTypeRequest, id)) as DefaultResponseDTO<RankingTypeDTO>;
    yield put(setRankingTypeAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* editRankingTypeSaga(
  action: EditRankingTypeAction,
): Generator<PutEffect<GlobalRequestActions | RankingTypesActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect> {
  const {
    type: actionType,
    payload: { data },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateRankingTypeRequest, data);
    yield put(setRequestSucceededAction(actionType));
    const pageConfig = (yield select(selectRankingTypesPageConfiguration)) as PageSearchQueryConfig;
    yield put(loadRankingTypesAction(pageConfig));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* editRankingTypeDetailsSaga(
  action: EditRankingTypeDetailsAction,
): Generator<PutEffect<GlobalRequestActions | RankingTypeActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect> {
  const {
    type: actionType,
    payload: { data },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateRankingTypeRequest, data);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadRankingTypeAction(data.id));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* deleteRankingTypeSaga(
  action: DeleteRankingTypeAction,
): Generator<PutEffect<GlobalRequestActions | RouterAction> | CallEffect<BasicResponseDTO> | SelectEffect> {
  const {
    type: actionType,
    payload: { id },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(deleteRankingTypeRequest, id);
    yield put(setRequestSucceededAction(actionType));
    yield put(push('/rankings/categorization/ranking-types'));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* resetRankingTypeErrors(): Generator<PutEffect<GlobalRequestActions>> {
  yield put(setRequestSucceededAction(CREATE_RANKING_TYPE));
  yield put(setRequestSucceededAction(EDIT_RANKING_TYPE));
  yield put(setRequestSucceededAction(DELETE_RANKING_TYPE));
}

export default function* rankingTypeProfileSaga(): Generator {
  yield takeLatest(CREATE_RANKING_TYPE, createNewRankingTypeSaga);
  yield takeLatest(LOAD_RANKING_TYPE, loadRankingTypeSaga);
  yield takeLatest(EDIT_RANKING_TYPE, editRankingTypeSaga);
  yield takeLatest(EDIT_RANKING_TYPE_DETAILS, editRankingTypeDetailsSaga);
  yield takeLatest(DELETE_RANKING_TYPE, deleteRankingTypeSaga);
  yield takeEvery(CLEAR_CURRENT_RANKING_TYPE, resetRankingTypeErrors);
}

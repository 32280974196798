import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ADMIN_USERS_TABLE_FILTERS } from './UserFiltersAndSearch.constants';
import { loadUsersAction } from '../../users.actions';
import FilterGroup, { mapInitialValuesToFiltersConfig } from '../../../../../components/FilterGroup';
import SearchInput from '../../../../../components/SearchInput';
import { PageFilterConfig, PageSearchQueryConfig, Status } from '../../../../../shared/constants';

type FiltersAndSearchProps = {
  pageConfig: PageSearchQueryConfig;
  filtersData: { userRoles: string[]; userStatuses: Status[] };
};

const UserFiltersAndSearch: FunctionComponent<FiltersAndSearchProps> = ({ pageConfig, filtersData }) => {
  const dispatch = useDispatch();
  const [userFilters, setUserFilters] = useState({});
  const { filter, search } = pageConfig;

  useEffect(() => {
    const { userRoles, userStatuses } = filtersData;

    setUserFilters(
      mapInitialValuesToFiltersConfig(
        ADMIN_USERS_TABLE_FILTERS,
        {
          role: userRoles,
          status: userStatuses,
        },
        filter,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, filtersData]);

  const handleSearchChange = (newPageConfig: PageSearchQueryConfig): void => {
    dispatch(loadUsersAction(newPageConfig));
  };

  const handleFiltersChange = (newFilters: PageFilterConfig): void => {
    dispatch(
      loadUsersAction({
        ...pageConfig,
        filter: newFilters,
      }),
    );
  };

  return (
    <div className="sticky-sidebar-container">
      <SearchInput
        id="admin-users-search"
        pageConfig={pageConfig}
        placeholder="Search by name or email"
        onSearchChanged={handleSearchChange}
        searchTerm={search}
      />
      <FilterGroup defaultFilters={filter || {}} filtersConfig={userFilters} onFiltersChanged={handleFiltersChange} />
    </div>
  );
};

export default UserFiltersAndSearch;

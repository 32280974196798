import { isEqual } from 'lodash';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { RootState } from '../../../store/reducers';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const registrationsSelector = (state: RootState) => state.commercial.registrations;

export const selectRegistrations = createDeepEqualSelector(registrationsSelector, (registrations) => {
  return registrations.data;
});

export const selectRegistrationsPageConfiguration = createDeepEqualSelector(registrationsSelector, (registrations) => {
  return {
    filter: registrations.filter,
    limit: registrations.pagination.limit,
    order: registrations.sortOrder.order,
    page: registrations.pagination.page,
    sortBy: registrations.sortOrder.sortBy,
    totalPages: registrations.pagination.totalPages,
  };
});

import { RootState } from '../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const schoolsAccountsSelector = (state: RootState) => state.schools.accounts;

export const selectSchoolAccountsTableData = createDeepEqualSelector(schoolsAccountsSelector, (schoolsAccounts) => {
  return schoolsAccounts.data;
});

export const selectSchoolAccountsPageConfiguration = createDeepEqualSelector(
  schoolsAccountsSelector,
  (schoolsAccounts) => {
    return {
      filter: schoolsAccounts.filter,
      limit: schoolsAccounts.pagination.limit,
      order: schoolsAccounts.sortOrder.order,
      page: schoolsAccounts.pagination.page,
      search: schoolsAccounts.search,
      sortBy: schoolsAccounts.sortOrder.sortBy,
      totalPages: schoolsAccounts.pagination.totalPages,
    };
  },
);

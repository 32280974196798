import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button, { ButtonIcons, ButtonSizes } from '../../../../../components/Button';
import Modal, { MODAL_ANIMATION_DURATION } from '../../../../../components/Modal';
import Pager, { INITIAL_PAGE } from '../../../../../components/Pager';
import Table, { TableHeaderColumnTypes } from '../../../../../components/Table';
import Typography, { TypographyVariants } from '../../../../../components/Typography';
import { ASCENDING_ORDER_DIRECTION, DESCENDING_ORDER_DIRECTION } from '../../../../../shared/constants';
import { loadResourcesAction, selectResources } from '../../../../../shared/state/resources';
import { clearCurrentRankingTypeAction, RankingTypeDTO, setRankingTypeAction } from '../../RankingTypeProfile';
import { loadRankingTypesAction } from '../ranking-types.actions';
import { selectRankingTypesPageConfiguration, selectRankingTypesTableData } from '../ranking-types.selectors';
import RankingTypeForm from './RankingTypeForm';
import RankingTypeFiltersAndSearch from './RankingTypesFiltersAndSearch';
import { RANKING_TYPES_TABLE_COLUMN_DEFINITION } from './RankingTypes.constants';

const RankingTypesList: FunctionComponent = () => {
  const dispatch = useDispatch();
  const pageConfig = useSelector(selectRankingTypesPageConfiguration);
  const rankingTypesData = useSelector(selectRankingTypesTableData);

  const rankingCategories = useSelector(selectResources('categories'));

  const [isRankingTypeEdit, setIsRankingTypeEdit] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  useEffect(() => {
    dispatch(loadRankingTypesAction());
    dispatch(loadResourcesAction('categories'));
    dispatch(clearCurrentRankingTypeAction());
  }, [dispatch]);

  useEffect(() => {
    if (isFormModalOpen) {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rankingTypesData]);

  const handlePageChanged = (page: number): void => {
    dispatch(loadRankingTypesAction({ ...pageConfig, page }));
  };

  const handleSortByChanged = (fieldName: string): void => {
    const orderDirection =
      pageConfig.sortBy !== fieldName
        ? DESCENDING_ORDER_DIRECTION
        : pageConfig.order === ASCENDING_ORDER_DIRECTION
        ? DESCENDING_ORDER_DIRECTION
        : ASCENDING_ORDER_DIRECTION;
    dispatch(loadRankingTypesAction({ ...pageConfig, sortBy: fieldName, order: orderDirection }));
  };

  const openFormModal = (id?: number): void => {
    if (id) {
      const currentRankingType = rankingTypesData.find((ranking: RankingTypeDTO) => ranking.id === id);
      if (currentRankingType) {
        dispatch(setRankingTypeAction(currentRankingType));
        setIsRankingTypeEdit(true);
      }
    } else {
      setIsRankingTypeEdit(false);
    }

    setIsFormModalOpen(true);
  };

  const closeModal = (): void => {
    setIsFormModalOpen(false);

    setTimeout(() => {
      dispatch(clearCurrentRankingTypeAction());
    }, MODAL_ANIMATION_DURATION);
  };

  const rankingTypesTableConfig = [
    ...RANKING_TYPES_TABLE_COLUMN_DEFINITION,
    {
      id: 'actions',
      title: 'Action',
      type: TableHeaderColumnTypes.actions,
      width: '90px',
      actions: [
        {
          disableIfDefault: true,
          label: 'Edit',
          handleOnClick: openFormModal,
        },
      ],
    },
  ];

  return (
    <>
      <div className="mba-heading--wrapper mba-heading--table">
        <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title">
          Ranking Types
        </Typography>
        <Button
          text="Create new ranking type"
          size={ButtonSizes.big}
          icon={ButtonIcons.plus}
          disabled={isFormModalOpen}
          primary
          onClick={(): void => openFormModal()}
        />
      </div>
      <div className="mba-table-wrapper">
        <div className="mba-table-filters">
          <RankingTypeFiltersAndSearch pageConfig={pageConfig} filtersData={{ rankingCategories }} />
        </div>
        <div className="mba-table-container">
          <Table
            id="ranking-types-list"
            columns={rankingTypesTableConfig}
            data={rankingTypesData}
            sortingOptions={{
              order: pageConfig.order || DESCENDING_ORDER_DIRECTION,
              sortBy: pageConfig.sortBy || '',
            }}
            onSortByColumn={handleSortByChanged}
          />
        </div>
      </div>
      {pageConfig && pageConfig.totalPages !== INITIAL_PAGE && (
        <Pager
          currentPage={pageConfig.page}
          totalPages={pageConfig.totalPages || INITIAL_PAGE}
          onPageChange={handlePageChanged}
        />
      )}

      {isFormModalOpen && (
        <Modal
          title={`${isRankingTypeEdit ? 'Edit' : 'Create new'} ranking type`}
          isOpen={isFormModalOpen}
          onClose={closeModal}
        >
          <RankingTypeForm onFormClose={closeModal} isEdit={isRankingTypeEdit} />
        </Modal>
      )}
    </>
  );
};

export default RankingTypesList;

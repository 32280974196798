import { FaqDto } from './frequently-asked-questions.dto';
import { FrequentlyAskedQuestionsTypes, SET_FAQ } from './frequently-asked-questions.types';

const initialState: FaqDto[] = [];

const faqReducer = (state = initialState, action: FrequentlyAskedQuestionsTypes): FaqDto[] => {
  switch (action.type) {
    case SET_FAQ: {
      return [...action.payload.data];
    }
    default: {
      return state;
    }
  }
};

export default faqReducer;

import { getTableListDefaultState } from '../../../../shared/helpers';
import { LOAD_RANKINGS, RankingsActionTypes, SET_RANKINGS } from './rankings.types';
import { RankingDTO } from '../RankingProfile/RankingInfo';
import { TableListData } from '../../../../shared/constants';

const initialState: TableListData<RankingDTO> = getTableListDefaultState<RankingDTO>();

const rankingReducer = (state = initialState, action: RankingsActionTypes): TableListData<RankingDTO> => {
  switch (action.type) {
    case LOAD_RANKINGS: {
      return {
        ...state,
        filter: action.payload.pageConfig?.filter || {},
        search: action.payload.pageConfig?.search || '',
      };
    }
    case SET_RANKINGS: {
      return {
        ...state,
        ...action.payload.tableData,
      };
    }
    default: {
      return state;
    }
  }
};

export default rankingReducer;

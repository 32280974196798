import { DefaultResponseDTO, PagedResponseDTO, PageSearchQueryConfig } from '../../constants';
import { registrationGet, registrationPut } from '../../registrationApi/api';
import { LeadDTO, SchoolLeadStatusDTO } from './lead.dto';
import { generateSearchQuery } from '../../helpers';

export const getSchoolLeadStatusRequest = (schoolId: number): Promise<DefaultResponseDTO<SchoolLeadStatusDTO>> => {
  return registrationGet<DefaultResponseDTO<SchoolLeadStatusDTO>>(`/school/${schoolId}`);
};

export const getSchoolLeadsRequest = (
  schoolId: number,
  pageConfig: PageSearchQueryConfig = {},
): Promise<PagedResponseDTO<LeadDTO[]>> => {
  const query = generateSearchQuery(pageConfig);
  return registrationGet<PagedResponseDTO<LeadDTO[]>>(`/school/${schoolId}/leads${query}`);
};

export const updateSchoolLeadStatusRequest = (schoolLeadStatus: {
  id: number;
  active: boolean;
}): Promise<DefaultResponseDTO<SchoolLeadStatusDTO>> => {
  const { id } = schoolLeadStatus;
  return registrationPut<
    DefaultResponseDTO<SchoolLeadStatusDTO>,
    {
      id: number;
      active: boolean;
    }
  >(`/school/${id}`, schoolLeadStatus);
};

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SCHOOLS_TABLE_FILTERS } from './SchoolsFiltersAndSearch.constants';
import { loadSchoolsAction } from '../../schools.actions';
import FilterGroup, { mapInitialValuesToFiltersConfig } from '../../../../../components/FilterGroup';
import SearchInput from '../../../../../components/SearchInput';
import { PageFilterConfig, PageSearchQueryConfig, Resource, Status } from '../../../../../shared/constants';

type SchoolsSearchProps = {
  pageConfig: PageSearchQueryConfig;
  filtersData: { accreditations: Resource[]; schoolStatuses: Status[] };
};

const SchoolsFiltersAndSearch: FunctionComponent<SchoolsSearchProps> = ({ pageConfig, filtersData }) => {
  const dispatch = useDispatch();
  const [schoolFilters, setSchoolFilters] = useState({});
  const { filter, search } = pageConfig;

  useEffect(() => {
    const { accreditations, schoolStatuses } = filtersData;

    setSchoolFilters(
      mapInitialValuesToFiltersConfig(
        SCHOOLS_TABLE_FILTERS,
        {
          accreditationId: accreditations,
          status: schoolStatuses,
        },
        filter,
        true,
      ),
    );
  }, [filter, filtersData]);

  const handleSearchChange = (newPageConfig: PageSearchQueryConfig): void => {
    dispatch(loadSchoolsAction(newPageConfig));
  };

  const handleFiltersChange = (newFilters: PageFilterConfig): void => {
    dispatch(
      loadSchoolsAction({
        ...pageConfig,
        filter: newFilters,
      }),
    );
  };

  return (
    <div className="sticky-sidebar-container">
      <SearchInput
        id="schools-search"
        pageConfig={pageConfig}
        placeholder="Search by school name or SID"
        onSearchChanged={handleSearchChange}
        searchTerm={search}
      />
      <FilterGroup defaultFilters={filter || {}} filtersConfig={schoolFilters} onFiltersChanged={handleFiltersChange} />
    </div>
  );
};

export default SchoolsFiltersAndSearch;

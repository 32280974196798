import { getTableListDefaultState } from '../../../shared/helpers';
import { MessageDto, OnsiteMessageState } from './onsite-messaging.dto';
import { OnsiteMessagingTypes, SET_LATEST_MESSAGE, SET_MESSAGE_HISTORY } from './onsite-messaging.types';

const initialState: OnsiteMessageState = {
  messageHistory: getTableListDefaultState<MessageDto>(),
  latestMessage: {} as MessageDto,
};

const onsiteMessagingReducer = (state = initialState, action: OnsiteMessagingTypes): OnsiteMessageState => {
  switch (action.type) {
    case SET_MESSAGE_HISTORY: {
      return {
        ...state,
        messageHistory: action.payload.tableData,
      };
    }
    case SET_LATEST_MESSAGE: {
      return {
        ...state,
        latestMessage: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default onsiteMessagingReducer;

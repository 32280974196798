import {
  CLEAR_CURRENT_RANKING_TYPE,
  CREATE_RANKING_TYPE,
  EDIT_RANKING_TYPE,
  RankingTypeActionTypes,
  RankingTypeProfileState,
  SET_RANKING_TYPE,
} from './ranking-type-profile.types';

const initialState: RankingTypeProfileState = {} as RankingTypeProfileState;

const rankingTypeProfileReducer = (state = initialState, action: RankingTypeActionTypes): RankingTypeProfileState => {
  switch (action.type) {
    case CLEAR_CURRENT_RANKING_TYPE: {
      return initialState;
    }
    case CREATE_RANKING_TYPE:
    case EDIT_RANKING_TYPE:
    case SET_RANKING_TYPE: {
      return {
        ...action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default rankingTypeProfileReducer;

import { CreateSchoolDTO, EditSchoolDTO, EditSchoolLogoDTO, SchoolDTO } from './school-details.dto';

export const CREATE_SCHOOL = 'CREATE_SCHOOL';
export const LOAD_SCHOOL = 'LOAD_SCHOOL';
export const EDIT_SCHOOL = 'EDIT_SCHOOL';
export const EDIT_SCHOOL_ACCREDITATIONS = 'EDIT_SCHOOL_ACCREDITATIONS';
export const EDIT_SCHOOL_DETAILS = 'EDIT_SCHOOL_DETAILS';
export const EDIT_SCHOOL_LOGO = 'EDIT_SCHOOL_LOGO';
export const DELETE_SCHOOL = 'DELETE_SCHOOL';
export const CLEAR_CURRENT_SCHOOL = 'CLEAR_CURRENT_SCHOOL';
export const SET_SCHOOL = 'SET_SCHOOL';

export type SchoolProfileState = CreateSchoolDTO | EditSchoolDTO | SchoolDTO;

export interface SchoolProfileRouteParams {
  id: string;
}

export interface CreateSchoolAction {
  type: typeof CREATE_SCHOOL;
  payload: { data: CreateSchoolDTO };
}

export interface LoadSchoolAction {
  type: typeof LOAD_SCHOOL;
  payload: { id: number };
}

export interface EditSchoolAction {
  type: typeof EDIT_SCHOOL;
  payload: { data: EditSchoolDTO };
}

export interface EditSchoolAccreditationsAction {
  type: typeof EDIT_SCHOOL_ACCREDITATIONS;
  payload: { accreditationIds: number[]; schoolId: number };
}

export interface EditSchoolDetailsAction {
  type: typeof EDIT_SCHOOL_DETAILS;
  payload: { data: EditSchoolDTO };
}

export interface EditSchoolLogoAction {
  type: typeof EDIT_SCHOOL_LOGO;
  payload: { data: EditSchoolLogoDTO };
}

export interface DeleteSchoolAction {
  type: typeof DELETE_SCHOOL;
  payload: { id: number };
}

export interface ClearCurrentSchoolAction {
  type: typeof CLEAR_CURRENT_SCHOOL;
}

export interface SetSchoolAction {
  type: typeof SET_SCHOOL;
  payload: { data: SchoolDTO };
}

export type SchoolActionTypes =
  | ClearCurrentSchoolAction
  | CreateSchoolAction
  | EditSchoolAccreditationsAction
  | EditSchoolAction
  | EditSchoolDetailsAction
  | EditSchoolLogoAction
  | DeleteSchoolAction
  | LoadSchoolAction
  | SetSchoolAction;

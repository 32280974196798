import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import Button, { ButtonSizes } from '../../../../../components/Button';
import Typography from '../../../../../components/Typography';
import { selectRequestIsLoading } from '../../../../../shared/state/global-request';
import { DELETE_SCHOOL_WIDGET_GROUP } from '../../../SchoolWidgetGroups';

type RemoveWidgetGroupPopupProps = {
  onCancel: () => void;
  onConfirm: () => void;
};

const RemoveWidgetGroupPopup: FunctionComponent<RemoveWidgetGroupPopupProps> = ({ onCancel, onConfirm }) => {
  const isLoading = useSelector(selectRequestIsLoading(DELETE_SCHOOL_WIDGET_GROUP));

  return (
    <>
      <Typography>Are you sure you want to delete this widget Group?</Typography>
      <div className="mba-actions">
        <Button onClick={onCancel} text="Cancel" size={ButtonSizes.big} />
        <Button onClick={(): void => onConfirm()} primary text="Yes" isLoading={isLoading} size={ButtonSizes.big} />
      </div>
    </>
  );
};

export default RemoveWidgetGroupPopup;

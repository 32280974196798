import { RootState } from '../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const selectrankingTypesDropdown = (state: RootState) => state.rankingTypesDropdown;

export const selectRankingTypesListForDropdown = createDeepEqualSelector(selectrankingTypesDropdown, (dropdown) => {
  return dropdown.rankingTypes;
});

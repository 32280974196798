import { FormConfig } from '../../../../components/Form';

export const SCHOOL_LEAD_MAIN_ROUTE = '/commercial/lead-gen/:schoolId/leads';

export const SCHOOL_LEAD_DETAILS_CONFIG_FIRST: FormConfig = {
  status: {
    title: 'Status',
  },
  createdAt: {
    title: 'Date Created',
  },
  country: {
    title: 'Country',
  },
  name: {
    title: 'Name',
  },
  email: {
    title: 'Email',
  },
  nationality: {
    title: 'Nationality',
  },
};

export interface SchoolLeadsSingleViewDTO {
  role: string;
  yearsProfessionalExperience?: string;
  programmeOfInterest?: string;
  yearsManagerialExperience?: string;
  specifyRole?: string;
}

export interface SchoolLeadsDetailsDTO {
  status: string;
  email: string;
  name: string;
  country: string;
  createdAt: string;
  nationality: string;
  role: string;
  yearsProfessionalExperience: number;
  programmeOfInterest: string;
  yearsManagerialExperience: number;
  specifyRole: string;
}

export enum LEAD_QUESTIONS {
  specifyRole = 'Specify your role',
  progammeOfInterest = 'Programme of interest',
  yearsProfessionalExperience = 'Years of professional experience',
  yearsManagerialExperience = 'Years of managerial experience',
}

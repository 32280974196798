import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CampusForm from './CampusForm';
import {
  CAMPUSES_TABLE_ADDRESS_COLUMN_DEFINITION,
  CAMPUSES_TABLE_DEFAULT_CAMPUS_COLUMN_DEFINITION,
  CAMPUSES_TABLE_NAME_COLUMN_DEFINITION,
  CAMPUSES_TABLE_STATUS_COLUMN_DEFINITION,
} from './CampusesList.constants';
import RemoveCampusPopup from './RemoveCampusPopup';
import { changeDefaultCampusAction, loadCampusesAction } from '../campuses.actions';
import { selectCampusesPageConfiguration, selectCampusesTableData } from '../campuses.selectors';
import { CampusDTO, clearCurrentCampusAction, deleteCampusAction, setCampusAction } from '../../CampusProfile';
import { ButtonIcons } from '../../../../../components/Button';
import Modal, { MODAL_ANIMATION_DURATION } from '../../../../../components/Modal';
import Pager, { INITIAL_PAGE } from '../../../../../components/Pager';
import Table, { TableHeaderColumnTypes, TableHeadingActionType } from '../../../../../components/Table';
import { ASCENDING_ORDER_DIRECTION, DESCENDING_ORDER_DIRECTION } from '../../../../../shared/constants';

type CampusesListProps = {
  defaultCampusId?: number;
  schoolId: number;
};

const CampusesList: FunctionComponent<CampusesListProps> = ({ defaultCampusId, schoolId }) => {
  const dispatch = useDispatch();
  const campusesData = useSelector(selectCampusesTableData);
  const pageConfig = useSelector(selectCampusesPageConfiguration);
  const [campusToRemove, setCampusToRemove] = useState(0);
  const [isCampusEdit, setIsCampusEdit] = useState(false);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (schoolId) {
      dispatch(loadCampusesAction(schoolId));
    }
  }, [dispatch, schoolId]);

  useEffect(() => {
    if (isModalOpen) {
      closeModal();
    }

    if (isConfirmPopupOpen) {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campusesData]);

  const handleDefaultCampusChange = (campusId: number): void => {
    dispatch(changeDefaultCampusAction(schoolId, campusId));
  };

  const handlePageChanged = (page: number): void => {
    dispatch(loadCampusesAction(schoolId, { ...pageConfig, page }));
  };

  const handleSortByChanged = (fieldName: string): void => {
    const orderDirection =
      pageConfig.sortBy !== fieldName
        ? DESCENDING_ORDER_DIRECTION
        : pageConfig.order === ASCENDING_ORDER_DIRECTION
        ? DESCENDING_ORDER_DIRECTION
        : ASCENDING_ORDER_DIRECTION;
    dispatch(loadCampusesAction(schoolId, { ...pageConfig, sortBy: fieldName, order: orderDirection }));
  };

  const handleRemoveCampusConfirm = (campusId: number): void => {
    dispatch(deleteCampusAction(schoolId, campusId));
    closeRemoveCampusPopup();
  };

  const openPopup = (id?: number): void => {
    if (id) {
      const currentCampus = campusesData.find((campus: CampusDTO) => campus.id === id);
      if (currentCampus) {
        dispatch(setCampusAction(currentCampus));
        setIsCampusEdit(true);
      }
    } else {
      setIsCampusEdit(false);
    }

    setIsModalOpen(true);
  };

  const closeModal = (): void => {
    setIsModalOpen(false);
    setTimeout(() => {
      dispatch(clearCurrentCampusAction());
    }, MODAL_ANIMATION_DURATION);
  };

  const openRemoveCampusPopup = (id: number): void => {
    setCampusToRemove(id);
    setIsConfirmPopupOpen(true);
  };

  const closeRemoveCampusPopup = (): void => {
    setIsConfirmPopupOpen(false);
    setTimeout(() => {
      dispatch(clearCurrentCampusAction());
    }, MODAL_ANIMATION_DURATION);
  };

  const campusesTableConfig = [
    { ...CAMPUSES_TABLE_DEFAULT_CAMPUS_COLUMN_DEFINITION },
    { ...CAMPUSES_TABLE_STATUS_COLUMN_DEFINITION },
    { ...CAMPUSES_TABLE_NAME_COLUMN_DEFINITION },
    { ...CAMPUSES_TABLE_ADDRESS_COLUMN_DEFINITION },
    {
      id: 'actions',
      title: 'Action',
      type: TableHeaderColumnTypes.actions,
      actions: [
        {
          label: 'Edit',
          handleOnClick: openPopup,
        },
        {
          label: 'Remove',
          handleOnClick: openRemoveCampusPopup,
        },
      ],
      width: '155px',
    },
  ];

  return (
    <>
      <Table
        id="school-campuses"
        data={campusesData}
        defaultRadioSelected={defaultCampusId || 0}
        columns={campusesTableConfig}
        headingOptions={{
          heading: 'Campuses',
          actions: [
            {
              id: 'create-campus',
              icon: ButtonIcons.plus,
              label: 'Add campus',
              handleOnClick: (): void => openPopup(),
              type: TableHeadingActionType.primary,
            },
          ],
        }}
        sortingOptions={{
          order: pageConfig.order || DESCENDING_ORDER_DIRECTION,
          sortBy: pageConfig.sortBy || '',
        }}
        onRowRadioButtonClick={handleDefaultCampusChange}
        onSortByColumn={handleSortByChanged}
      />
      {pageConfig && pageConfig.totalPages !== INITIAL_PAGE && (
        <Pager
          currentPage={pageConfig.page}
          totalPages={pageConfig.totalPages || INITIAL_PAGE}
          onPageChange={handlePageChanged}
        />
      )}
      <Modal title={`${isCampusEdit ? 'Edit' : 'Add'} campus`} isOpen={isModalOpen} onClose={closeModal}>
        <CampusForm isEdit={isCampusEdit} onFormClose={closeModal} schoolId={schoolId} />
      </Modal>
      <Modal title={`Delete campus`} isOpen={isConfirmPopupOpen} onClose={closeRemoveCampusPopup}>
        <RemoveCampusPopup
          campusId={campusToRemove}
          isDefaultCampus={defaultCampusId === campusToRemove}
          onCancel={closeRemoveCampusPopup}
          onConfirm={handleRemoveCampusConfirm}
        />
      </Modal>
    </>
  );
};

export default CampusesList;

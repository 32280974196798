import { SchoolRankingsDto } from './school-rankings.dto';
import {
  LOAD_SCHOOL_RANKINGS,
  LoadSchoolRankingsAction,
  SET_SCHOOL_RANKINGS,
  SetSchoolRankingsAction,
} from './school-rankings.types';
import { PageSearchQueryConfig, TableListData } from '../../../../shared/constants';

export const loadSchoolRankingsAction = (
  schoolId: number,
  pageConfig?: PageSearchQueryConfig,
): LoadSchoolRankingsAction => ({
  type: LOAD_SCHOOL_RANKINGS,
  payload: { schoolId, pageConfig },
});

export const setSchoolRankingAction = (tableData: TableListData<SchoolRankingsDto>): SetSchoolRankingsAction => ({
  type: SET_SCHOOL_RANKINGS,
  payload: { tableData },
});

import {
  EDIT_PRIVACY_DATA,
  EditPrivacyAction,
  LOAD_PRIVACY_DATA,
  LoadPrivacyAction,
  SET_PRIVACY_DATA,
  SetPrivacyAction,
} from './privacy.types';
import { PrivacyDto } from './privacy.dto';

export const loadPrivacyAction = (): LoadPrivacyAction => ({ type: LOAD_PRIVACY_DATA });
export const setPrivacyAction = (data: string): SetPrivacyAction => ({ type: SET_PRIVACY_DATA, payload: data });
export const editPrivacyAction = (data: PrivacyDto): EditPrivacyAction => ({ type: EDIT_PRIVACY_DATA, payload: data });

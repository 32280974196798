import { SchoolRankingsDto } from './school-rankings.dto';
import { get } from '../../../../shared/api';
import { PagedResponseDTO, PageSearchQueryConfig } from '../../../../shared/constants';
import { generateSearchQuery } from '../../../../shared/helpers';

export const getSchoolRankingRequest = (
  schoolId: number,
  pageConfig: PageSearchQueryConfig = {},
): Promise<PagedResponseDTO<SchoolRankingsDto[]>> => {
  const query = generateSearchQuery(pageConfig);
  return get<PagedResponseDTO<SchoolRankingsDto[]>>(`/schools/${schoolId}/rankings${query}`);
};

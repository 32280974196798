import { NavigationConfiguration } from '../Main/components/Layout';
import { get } from '../../../shared/api';
import { BasicResponseDTO, DefaultResponseDTO } from '../../../shared/constants';

export const checkTokenValidity = (): Promise<BasicResponseDTO> => {
  return get<BasicResponseDTO>('/auth/login/token/check');
};

export const getNavigation = (): Promise<DefaultResponseDTO<NavigationConfiguration>> => {
  return get<DefaultResponseDTO<NavigationConfiguration>>('/navigation');
};

export const logoutUser = (): Promise<BasicResponseDTO> => {
  return get<BasicResponseDTO>('/auth/logout');
};

import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useLocation, useParams } from 'react-router';
import { Route } from 'react-router-dom';

import SpreadsheetList from './components/SpreadsheetList';
import {
  RANKING_PROFILE_SPREADSHEETS_MAIN_ROUTE,
  RANKING_PROFILE_SPREADSHEETS_SUB_NAVIGATION,
} from './Spreadsheets.constants';
import { loadRankingAction, RankingProfileRouteParams } from '../RankingInfo';
import { PageRouter } from '../../../../App/Main/components/Layout';
import LayoutNavigation from '../../../../../components/LayoutNavigation';
import NotFound from '../../../../../components/NotFound';
import { selectRankingSpreadsheetsPageConfiguration } from './spreadsheets.selectors';
import SpreadsheetsSearchAndFilters from './components/SpreadsheetsSearchAndFilters';
import { loadStatusesAction, selectStatuses } from '../../../../../shared/state/statuses';

import styles from './Spreadsheets.module.scss';

const componentsMap = {
  alumni: SpreadsheetList,
  faculty: SpreadsheetList,
  research: SpreadsheetList,
};

const Spreadsheets: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const type = pathname.split('/').pop() || '';
  const pageConfig = useSelector(selectRankingSpreadsheetsPageConfiguration(type));
  const spreadsheetStatuses = useSelector(selectStatuses('spreadsheet'));
  const urlParams = useParams<RankingProfileRouteParams>();
  const { id: rankingId } = urlParams;

  useEffect(() => {
    if (rankingId) {
      dispatch(loadRankingAction(parseInt(rankingId)));
      dispatch(loadStatusesAction('spreadsheet'));
    }
  }, [dispatch, rankingId]);

  const navItems = Object.values(RANKING_PROFILE_SPREADSHEETS_SUB_NAVIGATION).map(({ label, route }) => ({
    label,
    route: `${RANKING_PROFILE_SPREADSHEETS_MAIN_ROUTE.replace(':id', rankingId)}${route}`,
  }));

  return (
    <>
      <div className={styles.sideBar}>
        <LayoutNavigation items={navItems} selectedItem={pathname} />
        <div className="mba-table-filters">
          <SpreadsheetsSearchAndFilters
            rankingId={Number(rankingId)}
            spreadsheetType={type}
            pageConfig={pageConfig}
            filtersData={{ spreadsheetStatuses }}
          />
        </div>
      </div>
      <div className={styles.mainContent}>
        <Switch>
          <PageRouter
            mainRoute={RANKING_PROFILE_SPREADSHEETS_MAIN_ROUTE}
            navigation={RANKING_PROFILE_SPREADSHEETS_SUB_NAVIGATION}
            componentsMap={componentsMap}
          />
          <Route path="*" component={NotFound} />
        </Switch>
      </div>
    </>
  );
};

export default Spreadsheets;

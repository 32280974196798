import { DeleteFaqAction, DELETE_FAQ } from '.';
import { AddFaqDto, FaqDto } from './frequently-asked-questions.dto';
import {
  AddFaqAction,
  ADD_FAQ,
  EditFaqAction,
  EDIT_FAQ,
  LoadFaqAction,
  LOAD_FAQ,
  SetFaqAction,
  SET_FAQ,
} from './frequently-asked-questions.types';

export const loadFaqAction = (): LoadFaqAction => ({
  type: LOAD_FAQ,
});

export const setFaqAction = (data: FaqDto[]): SetFaqAction => ({
  type: SET_FAQ,
  payload: { data },
});

export const editFaqAction = (data: FaqDto): EditFaqAction => ({
  type: EDIT_FAQ,
  payload: { data },
});

export const addFaqAction = (data: AddFaqDto): AddFaqAction => ({
  type: ADD_FAQ,
  payload: { data },
});

export const deleteFaqAction = (id: number): DeleteFaqAction => ({
  type: DELETE_FAQ,
  payload: { id },
});

import { call, CallEffect, put, PutEffect, select, SelectEffect, takeEvery, takeLatest } from 'redux-saga/effects';

import { setAccreditationAction } from './accreditation-profile.actions';
import {
  createAccreditationRequest,
  deleteAccreditationRequest,
  getAccreditationRequest,
  updateAccreditationRequest,
} from './accreditation-profile.api';
import { AccreditationDTO } from './accreditation-profile.dto';
import {
  AccreditationActionTypes,
  CLEAR_CURRENT_ACCREDITATION,
  CREATE_ACCREDITATION,
  CreateAccreditationAction,
  DELETE_ACCREDITATION,
  DeleteAccreditationAction,
  EDIT_ACCREDITATION,
  EditAccreditationAction,
  LOAD_ACCREDITATION,
  LoadAccreditationAction,
} from './accreditation-profile.types';
import {
  AccreditationsActionTypes,
  loadAccreditationsAction,
  selectAccreditationsPageConfiguration,
} from '../AccreditationsList';
import { BasicResponseDTO, DefaultResponseDTO, PageSearchQueryConfig } from '../../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../../shared/state/global-request';

function* createNewAccreditationSaga(
  action: CreateAccreditationAction,
): Generator<
  | PutEffect<GlobalRequestActions | AccreditationActionTypes | AccreditationsActionTypes>
  | CallEffect<DefaultResponseDTO<AccreditationDTO>>
> {
  const {
    type: actionType,
    payload: { data },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(createAccreditationRequest, data);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadAccreditationsAction());
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* loadAccreditationSaga(
  action: LoadAccreditationAction,
): Generator<
  CallEffect<DefaultResponseDTO<AccreditationDTO>> | PutEffect<GlobalRequestActions | AccreditationActionTypes>
> {
  const {
    type: actionType,
    payload: { id },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getAccreditationRequest, id)) as DefaultResponseDTO<AccreditationDTO>;
    yield put(setAccreditationAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* editAccreditationSaga(
  action: EditAccreditationAction,
): Generator<
  PutEffect<GlobalRequestActions | AccreditationsActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect
> {
  const {
    type: actionType,
    payload: { data },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateAccreditationRequest, data);
    yield put(setRequestSucceededAction(actionType));
    const pageConfig = (yield select(selectAccreditationsPageConfiguration)) as PageSearchQueryConfig;
    yield put(loadAccreditationsAction(pageConfig));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* deleteAccreditationSaga(
  action: DeleteAccreditationAction,
): Generator<
  PutEffect<GlobalRequestActions | AccreditationsActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect
> {
  const {
    type: actionType,
    payload: { id },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(deleteAccreditationRequest, id);
    yield put(setRequestSucceededAction(actionType));
    const pageConfig = (yield select(selectAccreditationsPageConfiguration)) as PageSearchQueryConfig;
    yield put(loadAccreditationsAction(pageConfig));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* resetAccreditationErrors(): Generator<PutEffect<GlobalRequestActions>> {
  yield put(setRequestSucceededAction(CREATE_ACCREDITATION));
  yield put(setRequestSucceededAction(DELETE_ACCREDITATION));
  yield put(setRequestSucceededAction(EDIT_ACCREDITATION));
}

export default function* rankingCategoriesSaga(): Generator {
  yield takeLatest(CREATE_ACCREDITATION, createNewAccreditationSaga);
  yield takeLatest(LOAD_ACCREDITATION, loadAccreditationSaga);
  yield takeLatest(EDIT_ACCREDITATION, editAccreditationSaga);
  yield takeLatest(DELETE_ACCREDITATION, deleteAccreditationSaga);
  yield takeEvery(CLEAR_CURRENT_ACCREDITATION, resetAccreditationErrors);
}

import { ColumnDefinition, TableHeaderColumnTypes } from '../../../../components/Table';

export const ONSITE_MESSAGING_HISTORY_TABLE_COLUMN_DEFINITION: ColumnDefinition[] = [
  {
    id: 'title',
    title: 'Name',
  },
  {
    id: 'content',
    title: 'Text',
  },
  {
    id: 'validFrom',
    title: 'Start Date',
    type: TableHeaderColumnTypes.date,
  },
  {
    id: 'validTo',
    title: 'End Date',
    type: TableHeaderColumnTypes.date,
  },
];

import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pager, { INITIAL_PAGE } from '../../../../components/Pager';
import Table from '../../../../components/Table';
import Typography, { TypographyVariants } from '../../../../components/Typography';
import { ASCENDING_ORDER_DIRECTION, DESCENDING_ORDER_DIRECTION } from '../../../../shared/constants';
import { loadSchoolsWithLeadGenAction } from '../schools-with-lead-gen.actions';
import { selectSchoolsWithLeadGen, selectSchoolWithLeadGenPageConfiguration } from '../schools-with-lead-gen.selectors';
import { SCHOOLS_WITH_LEAD_GEN_TABLE_COLUMN_DEFINITION } from './SchoolsWithLeadGenList.constants';
import LeadGenSearchAndFilters from './SchoolsWithLeadGenSearchAndFilters';

const SchoolsWithLeadGenList: FunctionComponent = () => {
  const dispatch = useDispatch();
  const pageConfig = useSelector(selectSchoolWithLeadGenPageConfiguration);
  const schoolsWithLeadGen = useSelector(selectSchoolsWithLeadGen);

  useEffect(() => {
    dispatch(loadSchoolsWithLeadGenAction());
  }, [dispatch]);

  const handleSortByChanged = (fieldName: string): void => {
    const orderDirection =
      pageConfig.sortBy !== fieldName
        ? DESCENDING_ORDER_DIRECTION
        : pageConfig.order === ASCENDING_ORDER_DIRECTION
        ? DESCENDING_ORDER_DIRECTION
        : ASCENDING_ORDER_DIRECTION;
    dispatch(loadSchoolsWithLeadGenAction({ ...pageConfig, sortBy: fieldName, order: orderDirection }));
  };

  const handlePageChanged = (page: number): void => {
    dispatch(loadSchoolsWithLeadGenAction({ ...pageConfig, page }));
  };

  return (
    <>
      <div className="mba-heading-wrapper">
        <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title">
          Schools leads
        </Typography>
      </div>
      <div className="mba-table-wrapper">
        <div className="mba-table-filters">
          <LeadGenSearchAndFilters />
        </div>
        <div className="mba-table-container">
          <Table
            id="adminLeadGen"
            columns={SCHOOLS_WITH_LEAD_GEN_TABLE_COLUMN_DEFINITION}
            data={schoolsWithLeadGen}
            sortingOptions={{
              order: pageConfig.order || DESCENDING_ORDER_DIRECTION,
              sortBy: pageConfig.sortBy || '',
            }}
            onSortByColumn={handleSortByChanged}
          />
        </div>
      </div>
      {pageConfig && pageConfig.totalPages !== INITIAL_PAGE && (
        <Pager
          currentPage={pageConfig.page}
          totalPages={pageConfig.totalPages || INITIAL_PAGE}
          onPageChange={handlePageChanged}
        />
      )}
    </>
  );
};

export default SchoolsWithLeadGenList;

import { FiltersConfig } from '../../../../../components/FilterGroup';

export const ADMIN_USERS_TABLE_FILTERS: FiltersConfig = {
  accreditationIds: {
    isDropdown: true,
    title: 'Accreditations',
    options: [],
  },
  permissions: {
    isDropdown: true,
    title: 'Ranking access',
    options: [],
  },
  role: {
    title: 'Role',
    options: [],
  },
  status: {
    title: 'Status',
    options: [],
  },
};

import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { DefaultResponseDTO, PagedResponseDTO } from '../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../shared/state/global-request';
import {
  loadLatestMessageAction,
  loadMessageHistoryAction,
  setLatestMessageAction,
  setMessageHistoryAction,
} from './onsite-messaging.actions';
import {
  addMessageRequest,
  editMessageRequest,
  getLatestMessageRequest,
  getMessageHistory,
} from './onsite-messaging.api';
import { MessageDto } from './onsite-messaging.dto';
import {
  AddMessageAction,
  ADD_MESSAGE,
  EditMessageAction,
  EDIT_MESSAGE,
  LoadLatestMessagesAction,
  LoadMessageHistoryAction,
  LOAD_LATEST_MESSAGE,
  LOAD_MESSAGE_HISTORY,
  OnsiteMessagingTypes,
} from './onsite-messaging.types';

function* loadMessageHistoryData(
  action: LoadMessageHistoryAction,
): Generator<
  CallEffect<PagedResponseDTO<MessageDto[]>> | PutEffect<OnsiteMessagingTypes> | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;
  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig } = action.payload;
    const { data, pagination, sortOrder } = (yield call(getMessageHistory, pageConfig)) as PagedResponseDTO<
      MessageDto[]
    >;
    yield put(setMessageHistoryAction({ data, pagination, sortOrder }));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* loadLatestMessageData(
  action: LoadLatestMessagesAction,
): Generator<CallEffect<MessageDto> | PutEffect<OnsiteMessagingTypes> | PutEffect<GlobalRequestActions>> {
  const { type: actionType } = action;
  try {
    yield put(setRequestStartedAction(actionType));
    const data = (yield call(getLatestMessageRequest)) as MessageDto;
    yield put(setLatestMessageAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* addMessageData(
  action: AddMessageAction,
): Generator<CallEffect<DefaultResponseDTO<any>> | PutEffect<OnsiteMessagingTypes> | PutEffect<GlobalRequestActions>> {
  const { type: actionType, payload } = action;
  try {
    yield put(setRequestStartedAction(actionType));
    yield call(addMessageRequest, payload.data);

    yield put(setRequestSucceededAction(actionType));
    yield put(loadMessageHistoryAction());
    yield put(loadLatestMessageAction());
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* editMessageData(
  action: EditMessageAction,
): Generator<CallEffect<DefaultResponseDTO<any>> | PutEffect<OnsiteMessagingTypes> | PutEffect<GlobalRequestActions>> {
  const { type: actionType, payload } = action;
  try {
    yield put(setRequestStartedAction(actionType));
    yield call(editMessageRequest, payload.messageId, payload.data);

    yield put(setRequestSucceededAction(actionType));
    yield put(loadLatestMessageAction());
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* onsiteMessagingSaga(): Generator {
  yield takeLatest(LOAD_MESSAGE_HISTORY, loadMessageHistoryData);
  yield takeLatest(LOAD_LATEST_MESSAGE, loadLatestMessageData);
  yield takeLatest(ADD_MESSAGE, addMessageData);
  yield takeLatest(EDIT_MESSAGE, editMessageData);
}

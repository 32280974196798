import { SchoolRankingsDto } from './school-rankings.dto';
import { PageSearchQueryConfig } from '../../../../shared/constants';
import { RootState } from '../../../../store/reducers';

export const selectSchoolRankingsTableData = (state: RootState): SchoolRankingsDto[] => state.schools.rankings.data;

export const selectSchoolRankingsPageConfiguration = (state: RootState): PageSearchQueryConfig => ({
  filter: state.schools.rankings.filter,
  limit: state.schools.rankings.pagination.limit,
  order: state.schools.rankings.sortOrder.order,
  page: state.schools.rankings.pagination.page,
  search: state.schools.rankings.search,
  sortBy: state.schools.rankings.sortOrder.sortBy,
  totalPages: state.schools.rankings.pagination.totalPages,
});

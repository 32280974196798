import { FormConfig } from '../../../../../../components/Form';

export const RANKING_TYPE_DETAILS_CONFIG: FormConfig = {
  name: {
    fieldType: 'text',
    placeholder: 'Enter name',
    required: true,
    title: 'Name',
    validations: {
      required: {
        errorText: `Please enter a name!`,
      },
    },
  },
  categoryId: {
    fieldType: 'select',
    options: [],
    placeholder: 'Choose a ranking category',
    required: true,
    title: 'Ranking category',
    validations: {
      required: {
        errorText: `Please choose a ranking category!`,
      },
    },
  },
  shortName: {
    fieldType: 'text',
    placeholder: 'Enter short name',
    required: true,
    title: 'Short name',
    validations: {
      required: {
        errorText: `Please enter a short name!`,
      },
    },
  },
  slug: {
    fieldType: 'text',
    placeholder: 'Enter slug',
    required: true,
    title: 'Slug',
    validations: {
      required: {
        errorText: `Please enter a valid slug!`,
      },
    },
  },
};

export const RANKING_TYPE_DESCRIPTION_CONFIG: FormConfig = {
  description: {
    fieldType: 'richText',
    placeholder: 'Enter a description',
  },
};

export const RANKING_TYPE_NOTE_CONFIG: FormConfig = {
  note: {
    fieldType: 'textarea',
    rows: 10,
    placeholder: 'Enter notes',
  },
};

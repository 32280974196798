import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { DELETE_FAQ } from '../..';
import Button, { ButtonSizes } from '../../../../../../components/Button';
import Typography from '../../../../../../components/Typography';
import { selectRequestIsLoading } from '../../../../../../shared/state/global-request';

type FaqDeleteConfirmationProps = {
  id: number;
  onCancel: () => void;
  onConfirm: (id: number) => void;
};

const FaqDeleteConfirmation: FunctionComponent<FaqDeleteConfirmationProps> = ({ id, onCancel, onConfirm }) => {
  const isLoading = useSelector(selectRequestIsLoading(DELETE_FAQ));

  const deleteFaq = (): void => {
    onConfirm(id);
  };

  return (
    <>
      <Typography>Are you sure you want to permanently delete this FAQ ?</Typography>
      <div className="mba-actions">
        <Button onClick={onCancel} text="Cancel" size={ButtonSizes.big} />
        <Button onClick={deleteFaq} isLoading={isLoading} primary text="Delete" size={ButtonSizes.big} />
      </div>
    </>
  );
};

export default FaqDeleteConfirmation;

import { SchoolWidgetGroupDto } from '../../../containers/Commercial/SchoolWidgetGroups/school-widget-groups.dto';
import { Resource } from '../../constants';

export const LOAD_SCHOOL_DROPDOWN = 'LOAD_SCHOOL_DROPDOWN';
export const SET_SCHOOL_DROPDOWN = 'SET_SCHOOL_DROPDOWN';
export const LOAD_SINGLE_SCHOOL_WIDGET_GROUPS = 'LOAD_SINGLE_SCHOOL_WIDGET_GROUPS';
export const SET_SINGLE_SCHOOL_WIDGET_GROUPS = 'SET_SINGLE_SCHOOL_WIDGET_GROUPS';

export interface LoadSchoolsDropdownAction {
  type: typeof LOAD_SCHOOL_DROPDOWN;
}

export interface SetSchoolsDropdownAction {
  type: typeof SET_SCHOOL_DROPDOWN;
  payload: { schools: Resource[] };
}

export interface LoadSingleSchoolWidgetGroupsAction {
  type: typeof LOAD_SINGLE_SCHOOL_WIDGET_GROUPS;
  payload: { schoolId: number };
}

export interface SetSingleSchoolWidgetGroupsAction {
  type: typeof SET_SINGLE_SCHOOL_WIDGET_GROUPS;
  payload: { schoolWidgetGroupsDto: SchoolWidgetGroupDto[] };
}

export type SchoolResourcesActionTypes =
  | LoadSchoolsDropdownAction
  | SetSchoolsDropdownAction
  | LoadSingleSchoolWidgetGroupsAction
  | SetSingleSchoolWidgetGroupsAction;

export interface SchoolsResourceState {
  schools: Resource[];
  schoolWidgetGroupsDto: SchoolWidgetGroupDto[];
}

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ImportSchoolUsersForm from './ImportSchoolUsersForm';
import RemoveSchoolUserConfirmation from './RemoveSchoolUserConfirmation';
import {
  SCHOOL_USERS_EMAIL_COLUMN_DEFINITION,
  SCHOOL_USERS_NAME_COLUMN_DEFINITION,
  SCHOOL_USERS_ROLE_COLUMN_DEFINITION,
  SCHOOL_USERS_SCHOOL_COLUMN_DEFINITION,
  SCHOOL_USERS_STATUS_COLUMN_DEFINITION,
} from './SchoolUsers.constants';
import SchoolUserForm from './SchoolUserForm';
import { loadSchoolUsersForSchoolAction } from '../school-users.actions';
import { selectSchoolUsersPageConfiguration, selectSchoolUsersTableData } from '../school-users.selectors';
import { SchoolDTO, selectCurrentSchool } from '../../SchoolDetails';
import { SCHOOL_TYPES } from '../../../SchoolsList/components/SchoolsList.constants';
import { clearCurrentSchoolUserAction, deleteSchoolUserAction, SchoolUserDTO } from '../../../SchoolUserProfile';
import Button, { ButtonIcons, ButtonSizes } from '../../../../../components/Button';
import Modal, { MODAL_ANIMATION_DURATION } from '../../../../../components/Modal';
import Pager, { INITIAL_PAGE } from '../../../../../components/Pager';
import Table, { TableHeaderColumnTypes } from '../../../../../components/Table';
import Typography, { TypographyVariants } from '../../../../../components/Typography';
import { ASCENDING_ORDER_DIRECTION, DESCENDING_ORDER_DIRECTION } from '../../../../../shared/constants';
import { loadResourcesAction, selectResources } from '../../../../../shared/state/resources';

const SchoolUsersList: FunctionComponent = () => {
  const dispatch = useDispatch();
  const pageConfig = useSelector(selectSchoolUsersPageConfiguration);
  const schoolUsersData = useSelector(selectSchoolUsersTableData);
  const currentSchool = useSelector(selectCurrentSchool) as SchoolDTO;
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isImportFormModalOpen, setIsImportFormModalOpen] = useState(false);
  const [userToRemove, setUserToRemove] = useState<SchoolUserDTO | null>(null);

  const schoolId = currentSchool.id;

  const importSchoolUsersData = useSelector(selectResources(`schools/${schoolId}/accounts`));
  const showImportSchoolUsers =
    currentSchool.type?.key === parseInt(SCHOOL_TYPES.JOINED) && importSchoolUsersData.length > 0;

  useEffect(() => {
    if (schoolId) {
      dispatch(loadSchoolUsersForSchoolAction(schoolId));
      dispatch(loadResourcesAction(`schools/${schoolId}/accounts`));
    }
  }, [dispatch, schoolId]);

  useEffect(() => {
    if (isFormModalOpen || isImportFormModalOpen) {
      closeModal();
    }

    if (userToRemove) {
      setUserToRemove(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolUsersData]);

  const handlePageChanged = (page: number): void => {
    dispatch(loadSchoolUsersForSchoolAction(schoolId, { ...pageConfig, page }));
  };

  const handleSortByChanged = (fieldName: string): void => {
    const orderDirection =
      pageConfig.sortBy !== fieldName
        ? DESCENDING_ORDER_DIRECTION
        : pageConfig.order === ASCENDING_ORDER_DIRECTION
        ? DESCENDING_ORDER_DIRECTION
        : ASCENDING_ORDER_DIRECTION;
    dispatch(loadSchoolUsersForSchoolAction(schoolId, { ...pageConfig, sortBy: fieldName, order: orderDirection }));
  };

  const handleOpenRemoveUserConfirmationClick = (id: number): void => {
    const userToRemove = schoolUsersData.find((schoolUser) => schoolUser.id === id);
    if (userToRemove) {
      setUserToRemove(userToRemove);
    }
  };

  const handleUserDelete = (): void => {
    if (userToRemove?.id) {
      dispatch(deleteSchoolUserAction(userToRemove.id, schoolId));
    }
  };

  const schoolUsersTableConfig = [
    { ...SCHOOL_USERS_STATUS_COLUMN_DEFINITION },
    { ...SCHOOL_USERS_NAME_COLUMN_DEFINITION },
    { ...SCHOOL_USERS_ROLE_COLUMN_DEFINITION },
    { ...SCHOOL_USERS_EMAIL_COLUMN_DEFINITION },
  ];

  if (currentSchool && currentSchool.type?.key.toString() === SCHOOL_TYPES.JOINED) {
    schoolUsersTableConfig.push(SCHOOL_USERS_SCHOOL_COLUMN_DEFINITION);
  }

  schoolUsersTableConfig.push({
    id: 'actions',
    title: 'Action',
    type: TableHeaderColumnTypes.actions,
    actions: [
      {
        label: 'Remove',
        handleOnClick: handleOpenRemoveUserConfirmationClick,
      },
    ],
    width: '90px',
  });

  const openFormModal = (): void => {
    setIsFormModalOpen(true);
  };

  const openImportFormModal = (): void => {
    setIsImportFormModalOpen(true);
  };

  const closeModal = (): void => {
    setIsFormModalOpen(false);
    setIsImportFormModalOpen(false);
    setUserToRemove(null);

    setTimeout(() => {
      dispatch(clearCurrentSchoolUserAction());
    }, MODAL_ANIMATION_DURATION);
  };

  return (
    <>
      <div className="mba-heading--wrapper mba-heading--table">
        <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title-2">
          School Users
        </Typography>
        <div className="mba-actions">
          {showImportSchoolUsers && (
            <Button
              text="Import users"
              size={ButtonSizes.big}
              icon={ButtonIcons.plus}
              disabled={isImportFormModalOpen}
              onClick={(): void => openImportFormModal()}
            />
          )}
          <Button
            text="Create new user"
            size={ButtonSizes.big}
            icon={ButtonIcons.plus}
            disabled={isFormModalOpen}
            primary
            onClick={(): void => openFormModal()}
          />
        </div>
      </div>
      <div className="mba-table-wrapper">
        <Table
          id="school-users-list"
          columns={schoolUsersTableConfig}
          data={schoolUsersData}
          sortingOptions={{
            order: pageConfig.order || DESCENDING_ORDER_DIRECTION,
            sortBy: pageConfig.sortBy || '',
          }}
          onSortByColumn={handleSortByChanged}
        />
      </div>
      {pageConfig && pageConfig.totalPages !== INITIAL_PAGE && (
        <Pager
          currentPage={pageConfig.page}
          totalPages={pageConfig.totalPages || INITIAL_PAGE}
          onPageChange={handlePageChanged}
        />
      )}
      <Modal title="Create new school user" isOpen={isFormModalOpen} onClose={closeModal}>
        <SchoolUserForm onFormClose={closeModal} />
      </Modal>
      <Modal title="Import school users" isOpen={isImportFormModalOpen} onClose={closeModal}>
        <ImportSchoolUsersForm onFormClose={closeModal} importUsersList={importSchoolUsersData} />
      </Modal>
      <Modal title="Remove school user" isOpen={!!userToRemove} onClose={closeModal}>
        <RemoveSchoolUserConfirmation
          onCancel={closeModal}
          schoolId={schoolId}
          user={userToRemove}
          onConfirm={handleUserDelete}
        />
      </Modal>
    </>
  );
};

export default SchoolUsersList;

import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { CALENDAR_FORM_CONFIG, CALENDAR_FORM_INITIAL_DATA } from '../Calendar.constants';
import { updateRankingCalendarsAction } from '../../calendar.actions';
import { RankingCalendarDto, RankingTypeOptionsDTO, RankingCalendarUpdateDTO } from '../../calendar.dto';
import { UPDATE_RANKING_CALENDARS } from '../../calendar.types';
import Form, { FormConfig, FormDataValues } from '../../../../../../components/Form';
import {
  clearRequestFailedAction,
  selectRequestFormErrors,
  selectRequestIsLoading,
} from '../../../../../../shared/state/global-request';

type Props = {
  calendars: RankingCalendarDto[];
  rankingTypes: RankingTypeOptionsDTO[];
  onFormClose: () => void;
};

const getInitialData = (calendars: RankingCalendarDto[]): FormDataValues =>
  calendars.reduce(
    (data: FormDataValues, calendar: RankingCalendarDto, index: number) => ({
      ...data,
      ...CALENDAR_FORM_INITIAL_DATA(index, calendar),
    }),
    {},
  );

const CalendarForm: FunctionComponent<Props> = ({ calendars, rankingTypes, onFormClose }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectRequestIsLoading(UPDATE_RANKING_CALENDARS));
  const formErrors = useSelector(selectRequestFormErrors(UPDATE_RANKING_CALENDARS));

  const [calendarData, setCalendarData] = useState(calendars);

  const [formData, setFormData] = useState(() => getInitialData(calendars));

  useEffect(() => {
    setCalendarData(calendars);
    setFormData(getInitialData(calendars));
  }, [calendars]);

  const handleChange = (values: FormDataValues): void => {
    setFormData(values);
  };

  const handleRemove = (index: number): void => {
    // Update Form Data
    let newFormData = { ...formData };
    if (!newFormData.calendar) {
      for (let i = index; i < calendarData.length - 1; i++) {
        newFormData = {
          ...newFormData,
          [`calendar[${i}][id]`]: newFormData[`calendar[${i + 1}][id]`],
          [`calendar[${i}][rankingTypeId]`]: newFormData[`calendar[${i + 1}][rankingTypeId]`],
          [`calendar[${i}][openDate]`]: newFormData[`calendar[${i + 1}][openDate]`],
          [`calendar[${i}][publicationDate]`]: newFormData[`calendar[${i + 1}][publicationDate]`],
        };
      }
      delete newFormData[`calendar[${calendarData.length - 1}][id]`];
      delete newFormData[`calendar[${calendarData.length - 1}][rankingTypeId]`];
      delete newFormData[`calendar[${calendarData.length - 1}][openDate]`];
      delete newFormData[`calendar[${calendarData.length - 1}][publicationDate]`];
    }
    setFormData(newFormData);

    // Update Calendar Data for Form
    const id = calendarData[index].id;
    setCalendarData((d: RankingCalendarDto[]) => d.filter((v: RankingCalendarDto) => v.id !== id));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (data: any): void => {
    const updateList: RankingCalendarDto[] = [];
    const removeList: number[] = [];

    calendars.forEach((cal: RankingCalendarDto) => {
      const index =
        data.calendar && data.calendar.length
          ? data.calendar.findIndex((d: RankingCalendarDto) => d.id === cal.id)
          : -1;

      if (index > -1) {
        updateList.push({
          id: cal.id,
          rankingTypeId: data.calendar[index]['rankingTypeId'],
          openDate: moment(data.calendar[index]['openDate']).format('yyyy-MM'),
          publicationDate: moment(data.calendar[index]['publicationDate']).format('yyyy-MM'),
        } as RankingCalendarDto);
      } else {
        removeList.push(cal.id);
      }
    });

    const requestBody: RankingCalendarUpdateDTO = { updateList, removeList };
    dispatch(updateRankingCalendarsAction(requestBody as RankingCalendarUpdateDTO));
    if (!isLoading && errorCount === 0) {
      onClose();
    }
  };

  const onClose = useCallback((): void => {
    setCalendarData(calendars);
    setFormData(getInitialData(calendars));
    onFormClose();
    dispatch(clearRequestFailedAction(UPDATE_RANKING_CALENDARS));
  }, [dispatch, calendars, onFormClose]);

  const errorCount = Object.keys(formErrors).length;

  const defaultFormConfig: FormConfig = useMemo(
    () =>
      calendarData.reduce(
        (config: FormConfig, calendar: RankingCalendarDto, index: number) => ({
          ...config,
          ...CALENDAR_FORM_CONFIG(index, rankingTypes),
        }),
        {},
      ),
    [calendarData, rankingTypes],
  );

  return (
    <div className="calendar-form">
      <Form
        id="edit-calendar-form"
        isLoading={isLoading}
        config={defaultFormConfig}
        onValuesChanged={handleChange}
        onSubmit={onSubmit}
        submitButtonText="Save"
        actions={[
          {
            label: 'Cancel',
            onClick: onClose,
          },
        ]}
        initialValues={formData}
        errors={formErrors}
        shouldDisplayAddGroupButton={false}
        onRemove={handleRemove}
      />
    </div>
  );
};

export default CalendarForm;

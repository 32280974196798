import { registrationGet } from '../../../shared/registrationApi/api';
import { DefaultResponseDTO, PagedResponseDTO, PageSearchQueryConfig } from '../../../shared/constants';
import { generateSearchQuery } from '../../../shared/helpers';
import { SchoolsWithLeadGenDTO } from './schools-with-lead-gen.dto';
import { put } from '../../../shared/api';

export const getSchoolsWithLeadGenRequest = (
  pageConfig: PageSearchQueryConfig = {},
): Promise<PagedResponseDTO<SchoolsWithLeadGenDTO[]>> => {
  const query = generateSearchQuery(pageConfig);
  return registrationGet<PagedResponseDTO<SchoolsWithLeadGenDTO[]>>(`/school${query}`);
};

export const getSchoolWithLeadGenByIdRequest = (
  schoolId: number,
): Promise<DefaultResponseDTO<SchoolsWithLeadGenDTO>> => {
  return registrationGet<DefaultResponseDTO<SchoolsWithLeadGenDTO>>(`/school/${schoolId}`);
};

export const updateSchoolActiveRequest = (id: number, hasLeadGen: boolean): Promise<DefaultResponseDTO<any>> => {
  return put<DefaultResponseDTO<any>, any>(`/schools/${id}`, { hasLeadGen });
};

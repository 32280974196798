import { DefaultResponseDTO, PagedResponseDTO, PageSearchQueryConfig } from '../../../shared/constants';
import { generateSearchQuery } from '../../../shared/helpers';
import {
  registrationDelete,
  registrationGet,
  registrationPost,
  registrationPut,
} from '../../../shared/registrationApi/api';
import { AddMessageDto, MessageDto } from './onsite-messaging.dto';

export const getMessageHistory = (pageConfig: PageSearchQueryConfig = {}): Promise<PagedResponseDTO<MessageDto[]>> => {
  const query = generateSearchQuery(pageConfig);
  return registrationGet<PagedResponseDTO<MessageDto[]>>(`/osm/${query}`);
};

export const getLatestMessageRequest = (): Promise<MessageDto> => {
  return registrationGet<MessageDto>('/osm/latest');
};

export const addMessageRequest = (message: AddMessageDto): Promise<DefaultResponseDTO<any>> => {
  return registrationPost<DefaultResponseDTO<MessageDto>, AddMessageDto>('/osm', message);
};

export const editMessageRequest = (messageId: number, message: AddMessageDto): Promise<DefaultResponseDTO<any>> => {
  return registrationPut<DefaultResponseDTO<MessageDto>, AddMessageDto>(`/osm/${messageId}`, message);
};

export const deleteMessageRequest = (messageId: number): Promise<DefaultResponseDTO<any>> => {
  return registrationDelete<DefaultResponseDTO<MessageDto>>(`/osm/${messageId}`);
};

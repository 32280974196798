export interface NewWidgetData {
  id?: number;
  type: string;
  html: string;
  element: Element;
  // details: Element;
  details: { [key: string]: string };
  listElement: Element;
}

export const NEW_WIDGET_OPTIONS = [
  { label: 'Html', value: 'html' },
  { label: 'Video', value: 'video' },
  { label: 'Link list', value: 'filelist' },
  { label: 'Slideshow', value: 'slideshow' },
];

export const WIDGET_STATUS_TYPE = {
  DUPLICATE: 1,
  NEW: 2,
};

export const WIDGET_STATUS_OPTIONS = [
  { label: 'Duplicate existing', value: WIDGET_STATUS_TYPE.DUPLICATE },
  { label: 'Create new', value: WIDGET_STATUS_TYPE.NEW },
];

export const DEFAULT_HTML_STRING =
  '<div class="widgetwrapper"><div class="grayborder"><div class="htmlcontents"> <h3 class="widgettitle"></h3></div></div></div>';

export const DEFAULT_HTML_LIST_STRING = '</ul><li><a href=""></a></li>';

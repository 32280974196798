import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button, { ButtonIcons, ButtonSizes } from '../../../../components/Button';
import Pager, { INITIAL_PAGE } from '../../../../components/Pager';
import Table from '../../../../components/Table';
import Typography, { TypographyVariants } from '../../../../components/Typography';
import { ASCENDING_ORDER_DIRECTION, DESCENDING_ORDER_DIRECTION } from '../../../../shared/constants';
import {
  loadRankingTypesDropdownAction,
  selectRankingTypesListForDropdown,
} from '../../../../shared/state/ranking-types-dropdown';
import { loadSchoolsDropdownAction, selectSchoolListForDropdown } from '../../../../shared/state/school-resources';
import { loadSchoolWidgetGroupsAction } from '../school-widget-groups.actions';
import {
  selectSchoolWidgetGroupsTableData,
  selectWidgetGroupsPageConfiguration,
} from '../school-widget-groups.selectors';
import { CreateWidgetGroupModal } from './CreateWidgetGroupModal/CreateWidgetGroupModal';
import { WIDGETS_TABLE_COLUMN_DEFINITION } from './SchoolWidgetGroupsList.constants';
import WidgetsFiltersAndSearch from './WidgetsFiltersAndSearch';

const SchoolWidgetGroupsList: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const rankingTypes = useSelector(selectRankingTypesListForDropdown);
  const pageConfig = useSelector(selectWidgetGroupsPageConfiguration);
  const schoolWidgetGroups = useSelector(selectSchoolWidgetGroupsTableData);
  const schools = useSelector(selectSchoolListForDropdown);

  const _schoolWidgetGroups = schoolWidgetGroups.map((item) => {
    item.scope = item.rankingTypeId ? `Ranking Type: ${item.rankingType}` : 'All Rankings';
    return item;
  });

  useEffect(() => {
    dispatch(loadRankingTypesDropdownAction());
    dispatch(loadSchoolsDropdownAction());
    dispatch(loadSchoolWidgetGroupsAction());
  }, [dispatch]);

  useEffect(() => {
    if (isModalOpen) {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolWidgetGroups]);

  const handlePageChanged = (page: number): void => {
    dispatch(loadSchoolWidgetGroupsAction({ ...pageConfig, page }));
  };

  const handleSortByChanged = (fieldName: string): void => {
    const orderDirection =
      pageConfig.sortBy !== fieldName
        ? DESCENDING_ORDER_DIRECTION
        : pageConfig.order === ASCENDING_ORDER_DIRECTION
        ? DESCENDING_ORDER_DIRECTION
        : ASCENDING_ORDER_DIRECTION;
    dispatch(loadSchoolWidgetGroupsAction({ ...pageConfig, sortBy: fieldName, order: orderDirection }));
  };

  const openModal = (): void => {
    setIsModalOpen(true);
  };

  const closeModal = (): void => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="mba-heading--wrapper">
        <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title">
          School Widget Groups
        </Typography>
        <Button
          text="Create widget group"
          size={ButtonSizes.big}
          icon={ButtonIcons.plus}
          disabled={isModalOpen}
          primary
          onClick={(): void => openModal()}
        />
      </div>
      <div className="mba-table-wrapper">
        <div className="mba-table-filters">
          <WidgetsFiltersAndSearch pageConfig={pageConfig} filtersData={{ rankingTypes }} />
        </div>
        <div className="mba-table-container">
          <Table
            id="admin-widgets"
            columns={WIDGETS_TABLE_COLUMN_DEFINITION}
            data={_schoolWidgetGroups}
            sortingOptions={{
              order: pageConfig.order || DESCENDING_ORDER_DIRECTION,
              sortBy: pageConfig.sortBy || '',
            }}
            onSortByColumn={handleSortByChanged}
          />
        </div>
      </div>
      {pageConfig && pageConfig.totalPages !== INITIAL_PAGE && (
        <Pager
          currentPage={pageConfig.page}
          totalPages={pageConfig.totalPages || INITIAL_PAGE}
          onPageChange={handlePageChanged}
        />
      )}
      {isModalOpen && (
        <CreateWidgetGroupModal
          isModalOpen={isModalOpen}
          handleModalClose={closeModal}
          rankingTypes={rankingTypes}
          schools={schools}
        />
      )}
    </>
  );
};

export default SchoolWidgetGroupsList;

import { TableListData } from '../../../shared/constants';
import { getTableListDefaultState } from '../../../shared/helpers';
import { LogsDTO } from './logs.dto';
import { LoadLogsActionTypes, LOAD_LOGS, SET_LOGS } from './logs.types';

const initialState: TableListData<LogsDTO[]> = getTableListDefaultState<LogsDTO[]>();

const logsReducer = (state = initialState, action: LoadLogsActionTypes): TableListData<LogsDTO[]> => {
  switch (action.type) {
    case LOAD_LOGS: {
      return {
        ...state,
        filter: action.payload.pageConfig?.filter || {},
        search: action.payload.pageConfig?.search || '',
      };
    }
    case SET_LOGS: {
      return {
        ...state,
        ...action.payload.tableData,
      };
    }

    default: {
      return state;
    }
  }
};

export default logsReducer;

import { RootState } from '../../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const rankingListSelector = (state: RootState) => state.rankings.list;

export const selectRankingsTableData = createDeepEqualSelector(rankingListSelector, (rankingList) => {
  return rankingList.data;
});

export const selectRankingsPageConfiguration = createDeepEqualSelector(rankingListSelector, (rankingList) => {
  return {
    filter: rankingList.filter,
    limit: rankingList.pagination.limit,
    order: rankingList.sortOrder.order,
    page: rankingList.pagination.page,
    search: rankingList.search,
    sortBy: rankingList.sortOrder.sortBy,
    totalPages: rankingList.pagination.totalPages,
  };
});

import React, { useEffect, FunctionComponent, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Button, { ButtonSizes } from '../../../../../../../../../components/Button';
import EditableGroup from '../../../../../../../../../components/EditableGroup';
import { FormDataValues } from '../../../../../../../../../components/Form';
import Icon from '../../../../../../../../../components/Icon';
import Modal, { MODAL_ANIMATION_DURATION } from '../../../../../../../../../components/Modal';
import Typography, { TypographyVariants } from '../../../../../../../../../components/Typography';
import {
  selectRequestErrorMessage,
  selectRequestFormErrors,
  selectRequestIsLoading,
  setRequestSucceededAction,
} from '../../../../../../../../../shared/state/global-request';
import { RankingFieldType } from '../../../../RankingFieldsList';
import { DELETE_RANKING_FIELD } from '../../../../RankingFieldsList/components/RankingFieldsList';
import RankingFieldData from '../../../RankingFieldData';
import {
  RankingFieldDTO,
  RankingFieldProfileRouteParams,
  selectCurrentRankingField,
} from '../../../RankingFieldDetails';
import {
  deleteRankingFieldAction,
  editRankingFieldDetailsAction,
  loadRankingFieldAction,
} from '../../ranking-field-details.actions';
import { EDIT_RANKING_FIELD_DETAILS } from '../../ranking-field-details.types';
import ColumnDeleteConfirmation from '../RankingFieldDeleteConfirmation';
import { RANKING_FIELD_DESCRIPTION_CONFIG, RANKING_FIELD_DETAILS_CONFIG } from './RankingFieldDetails.constants';

const RankingFieldDetails: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { rankingId, rankingFieldId } = useParams<RankingFieldProfileRouteParams>();
  const formErrors = useSelector(selectRequestFormErrors(EDIT_RANKING_FIELD_DETAILS));
  const deleteRankingError = useSelector(selectRequestErrorMessage(DELETE_RANKING_FIELD));
  const isEditSaving = useSelector(selectRequestIsLoading(EDIT_RANKING_FIELD_DETAILS));
  const currentColumn = useSelector(selectCurrentRankingField);
  const { name: columnName } = currentColumn;
  const [values, setValues] = useState<FormDataValues>({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    dispatch(loadRankingFieldAction(parseInt(rankingId), parseInt(rankingFieldId)));
  }, [dispatch, rankingId, rankingFieldId]);

  useEffect(() => {
    if (Object.keys(currentColumn).length) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setValues(currentColumn);
    }
  }, [currentColumn]);

  const formConfig = useMemo(() => RANKING_FIELD_DETAILS_CONFIG(values.type as RankingFieldType), [values.type]);

  const updateColumn = (data: FormDataValues): void => {
    dispatch(
      editRankingFieldDetailsAction(parseInt(rankingId), {
        ...data,
        id: parseInt(rankingFieldId),
        /// That simulates form submit validation if type has been changed and the value is not in possible group options
        group: formConfig.group.options?.some(({ value }) => value === data.group) ? data.group : '',
      } as RankingFieldDTO),
    );
  };

  const updateColumnDescription = (data: FormDataValues): void => {
    dispatch(
      editRankingFieldDetailsAction(parseInt(rankingId), {
        ...currentColumn,
        id: parseInt(rankingFieldId),
        ...data,
      } as RankingFieldDTO),
    );
  };

  const resetFormState = (): void => {
    dispatch(setRequestSucceededAction(EDIT_RANKING_FIELD_DETAILS));
  };

  const handleDeleteClick = (): void => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteColumnConfirmed = (): void => {
    dispatch(deleteRankingFieldAction(parseInt(rankingId), parseInt(rankingFieldId)));
  };

  const closeModal = (): void => {
    setIsDeleteModalOpen(false);

    setTimeout(() => {
      dispatch(setRequestSucceededAction(DELETE_RANKING_FIELD));
    }, MODAL_ANIMATION_DURATION);
  };

  return (
    <>
      <EditableGroup<RankingFieldDTO>
        columnSize={4}
        data={currentColumn}
        editButtonLabel="Edit details"
        formConfig={formConfig}
        formErrors={formErrors}
        isLoading={isEditSaving}
        heading={
          <Typography
            component="p"
            variant={TypographyVariants.h3}
            className="mba-heading--title-2 mba-center--vertically"
          >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <span style={{ cursor: 'pointer', height: 30 }} onClick={history.goBack} role="button" tabIndex={0}>
              <Icon name="arrow-left" size={30} />
            </span>
            Details
          </Typography>
        }
        onEditSubmit={updateColumn}
        onEditCancel={resetFormState}
        deleteButtonBlock={
          <div className="mba-actions--right">
            <Button primary danger text="Delete column" size={ButtonSizes.big} onClick={handleDeleteClick} />
          </div>
        }
        onValuesChanged={setValues}
      />
      <hr className="mba-separator" />
      <EditableGroup<RankingFieldDTO>
        data={currentColumn}
        editButtonLabel="Edit description"
        formConfig={RANKING_FIELD_DESCRIPTION_CONFIG}
        formErrors={formErrors}
        isLoading={isEditSaving}
        heading={
          <Typography component="p" variant={TypographyVariants.h3} className="mba-heading--title-2">
            Description
          </Typography>
        }
        onEditSubmit={updateColumnDescription}
        onEditCancel={resetFormState}
      />
      <hr className="mba-separator" />
      <RankingFieldData />
      <Modal title="Delete a column" isOpen={isDeleteModalOpen} onClose={closeModal}>
        <ColumnDeleteConfirmation
          error={deleteRankingError}
          name={columnName}
          onCancel={closeModal}
          onConfirm={handleDeleteColumnConfirmed}
        />
      </Modal>
    </>
  );
};

export default RankingFieldDetails;

import {
  CreateSpreadsheetTemplateFieldDTO,
  EditSpreadsheetTemplateFieldDTO,
  SpreadsheetTemplateFieldDTO,
} from './spreadsheet-template.dto';
import { PageSearchQueryConfig, TableListData } from '../../../shared/constants';

export const CREATE_SPREADSHEET_TEMPLATE_FIELD = 'CREATE_SPREADSHEET_TEMPLATE_FIELD';
export const UPDATE_SPREADSHEET_TEMPLATE_FIELD = 'UPDATE_SPREADSHEET_TEMPLATE_FIELD';
export const LOAD_SPREADSHEET_TEMPLATE_FIELDS = 'LOAD_SPREADSHEET_TEMPLATE_FIELDS';
export const SET_SPREADSHEET_TEMPLATE_FIELDS = 'SET_SPREADSHEET_TEMPLATE_FIELDS';
export const SET_CURRENT_SPREADSHEET_TEMPLATE_FIELDS = 'SET_CURRENT_SPREADSHEET_TEMPLATE_FIELDS';
export const CLEAR_CURRENT_SPREADSHEET_TEMPLATE_FIELDS = 'CLEAR_CURRENT_SPREADSHEET_TEMPLATE_FIELDS';
export const DELETE_SPREADSHEET_TEMPLATE_FIELD = 'DELETE_SPREADSHEET_TEMPLATE_FIELD';

export enum SpreadsheetType {
  alumni,
  faculty,
  research,
}

interface SpreadsheetTemplateType extends TableListData<SpreadsheetTemplateFieldDTO> {
  currentField?: SpreadsheetTemplateFieldDTO;
}

export type SpreadsheetTemplateFieldState = {
  [key: string]: SpreadsheetTemplateType;
};

export interface CreateSpreadsheetTemplateFieldAction {
  type: typeof CREATE_SPREADSHEET_TEMPLATE_FIELD;
  payload: {
    type: string;
    data: CreateSpreadsheetTemplateFieldDTO;
  };
}

export interface UpdateSpreadsheetTemplateFieldAction {
  type: typeof UPDATE_SPREADSHEET_TEMPLATE_FIELD;
  payload: {
    id: number;
    type: string;
    data: EditSpreadsheetTemplateFieldDTO;
  };
}

export interface LoadSpreadsheetTemplateFieldsAction {
  type: typeof LOAD_SPREADSHEET_TEMPLATE_FIELDS;
  payload: {
    type: string;
    pageConfig?: PageSearchQueryConfig;
  };
}

export interface SetSpreadsheetTemplateFieldsAction {
  type: typeof SET_SPREADSHEET_TEMPLATE_FIELDS;
  payload: {
    type: string;
    tableData: TableListData<SpreadsheetTemplateFieldDTO>;
  };
}

export interface SetCurrentFieldAction {
  type: typeof SET_CURRENT_SPREADSHEET_TEMPLATE_FIELDS;
  payload: {
    type: string;
    data: SpreadsheetTemplateFieldDTO;
  };
}

export interface ClearCurrentFieldAction {
  type: typeof CLEAR_CURRENT_SPREADSHEET_TEMPLATE_FIELDS;
  payload: {
    type: string;
  };
}

export interface DeleteSpreadsheetTemplateFieldAction {
  type: typeof DELETE_SPREADSHEET_TEMPLATE_FIELD;
  payload: {
    type: string;
    id: number;
  };
}

export type SpreadsheetTemplateFieldActionTypes =
  | DeleteSpreadsheetTemplateFieldAction
  | LoadSpreadsheetTemplateFieldsAction
  | SetSpreadsheetTemplateFieldsAction
  | SetCurrentFieldAction
  | ClearCurrentFieldAction
  | CreateSpreadsheetTemplateFieldAction
  | UpdateSpreadsheetTemplateFieldAction;

import { AccreditationDTO } from '../AccreditationProfile';
import { PageSearchQueryConfig, TableListData } from '../../../../shared/constants';

export const LOAD_ACCREDITATIONS = 'LOAD_ACCREDITATIONS';
export const SET_ACCREDITATIONS = 'SET_ACCREDITATIONS';

export interface LoadAccreditationsAction {
  type: typeof LOAD_ACCREDITATIONS;
  payload: { pageConfig?: PageSearchQueryConfig };
}

export interface SetAccreditationsAction {
  type: typeof SET_ACCREDITATIONS;
  payload: { tableData: TableListData<AccreditationDTO> };
}

export type AccreditationsActionTypes = LoadAccreditationsAction | SetAccreditationsAction;

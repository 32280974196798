import { getTableListDefaultState } from '../../../shared/helpers';
import { LOAD_SCHOOL_ACCOUNTS, SchoolAccountsActionTypes, SET_SCHOOL_ACCOUNTS } from './school-accounts.types';
import { SchoolUserDTO } from '../SchoolUserProfile';
import { TableListData } from '../../../shared/constants';

const initialState: TableListData<SchoolUserDTO> = getTableListDefaultState<SchoolUserDTO>();

const schoolAccountsReducer = (
  state = initialState,
  action: SchoolAccountsActionTypes,
): TableListData<SchoolUserDTO> => {
  switch (action.type) {
    case LOAD_SCHOOL_ACCOUNTS: {
      return {
        ...state,
        filter: action.payload.pageConfig?.filter || {},
        search: action.payload.pageConfig?.search || '',
      };
    }
    case SET_SCHOOL_ACCOUNTS: {
      return {
        ...state,
        ...action.payload.tableData,
      };
    }
    default: {
      return state;
    }
  }
};

export default schoolAccountsReducer;

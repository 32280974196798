import { RootState } from '../../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const selectRankings = (state: RootState) => state.rankings;

export const selectCalendarsData = createDeepEqualSelector(selectRankings, (rankings) => {
  return rankings.calendar;
});

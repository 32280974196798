import { ImportSchoolUsersDTO } from './school-users.dto';
import { SchoolUserDTO } from '../../SchoolUserProfile';
import { PageSearchQueryConfig, TableListData } from '../../../../shared/constants';

export const LOAD_SCHOOL_USERS_FOR_SCHOOL = 'LOAD_SCHOOL_USERS_FOR_SCHOOL';
export const SET_SCHOOL_USERS_FOR_SCHOOL = 'SET_SCHOOL_USERS_FOR_SCHOOL';
export const IMPORT_SCHOOL_USERS = 'IMPORT_SCHOOL_USERS';

export interface LoadSchoolUsersForSchoolAction {
  type: typeof LOAD_SCHOOL_USERS_FOR_SCHOOL;
  payload: {
    schoolId: number;
    pageConfig?: PageSearchQueryConfig;
  };
}

export interface SetSchoolUsersForSchoolAction {
  type: typeof SET_SCHOOL_USERS_FOR_SCHOOL;
  payload: { tableData: TableListData<SchoolUserDTO> };
}

export interface ImportSchoolUsersAction {
  type: typeof IMPORT_SCHOOL_USERS;
  payload: {
    data: ImportSchoolUsersDTO;
    schoolId: number;
  };
}

export type SchoolUsersActionTypes =
  | LoadSchoolUsersForSchoolAction
  | SetSchoolUsersForSchoolAction
  | ImportSchoolUsersAction;

import {
  DOWNLOAD_SCHOOL_ACCOUNTS_SPREADSHEET,
  DownloadSchoolAccountsSpreadsheetAction,
  LOAD_SCHOOL_ACCOUNTS,
  LoadSchoolAccountsAction,
  SET_SCHOOL_ACCOUNTS,
  SetSchoolAccountsAction,
} from './school-accounts.types';
import { SchoolUserDTO } from '../SchoolUserProfile';
import { PageSearchQueryConfig, TableListData } from '../../../shared/constants';

export const loadSchoolAccountsAction = (pageConfig?: PageSearchQueryConfig): LoadSchoolAccountsAction => ({
  type: LOAD_SCHOOL_ACCOUNTS,
  payload: { pageConfig },
});

export const setSchoolAccountsAction = (tableData: TableListData<SchoolUserDTO>): SetSchoolAccountsAction => ({
  type: SET_SCHOOL_ACCOUNTS,
  payload: { tableData },
});

export const downloadSchoolAccountsSpreadsheetAction = (
  pageConfig?: PageSearchQueryConfig,
): DownloadSchoolAccountsSpreadsheetAction => ({
  type: DOWNLOAD_SCHOOL_ACCOUNTS_SPREADSHEET,
  payload: { pageConfig },
});

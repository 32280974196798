import { ColumnDefinition, TableHeaderColumnTypes } from '../../../../components/Table';

export const REGISTRATIONS_TABLE_COLUMN_DEFINITION: ColumnDefinition[] = [
  {
    id: 'id',
    title: 'Date',
    type: TableHeaderColumnTypes.date,
    sorting: false,
  },
  {
    id: 'newRegistrations',
    title: 'New registrations',
    type: TableHeaderColumnTypes.numeric,
    sorting: false,
  },
  {
    id: 'completedRegistrations',
    title: 'Completed registrations',
    type: TableHeaderColumnTypes.numeric,
    sorting: false,
  },
  {
    id: 'totalRegistrations',
    title: 'Total',
    type: TableHeaderColumnTypes.numeric,
    sorting: false,
  },
];

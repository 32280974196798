import { RankingTimelineStepDTO } from '../RankingDetails/TimelineSteps';

export interface RankingTimelineStepBaseProps extends RankingTimelineStepDTO {
  rankingId: number;
  slug: string;
}

export const MIN_DAYS_FOR_ACTIVE_STATUS = 0;

export const MAX_DAYS_FOR_ACTIVE_SHORT_STATUS = 2;

export const MAX_DAYS_FOR_ACTIVE_MEDIUM_STATUS = 6;

export const TimelineExpandStepStatus: { [key: number]: boolean } = {
  0: false,
  1: true,
  2: true,
  3: true,
  4: false,
};

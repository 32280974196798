import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ASCENDING_ORDER_DIRECTION, Country, DESCENDING_ORDER_DIRECTION } from '../../../shared/constants';
import { LEADS_TABLE_COLUMN_DEFINITION } from './SchoolLeads.constants';
import Pager, { INITIAL_PAGE } from '../../../components/Pager';
import Table from '../../../components/Table';

import { selectSchoolLeadPageConfiguration, selectSchoolLeads } from '../../../shared/state/leads/leads.selectors';
import { loadSchoolLeadsAction } from '../../../shared/state/leads/leads.actions';
import { LeadDTO } from '../../../shared/state/leads/lead.dto';
import SchoolLeadsFilters from './SchoolLeadsFilters/SchoolLeadsFilters';
import { loadResourcesAction, selectResources } from '../../../shared/state/resources';
import { useParams } from 'react-router';
import { SchoolLeadAnswer } from '../SchoolLeadsDetails/school-leads-details.dto';

export const SchoolLeads: FunctionComponent = () => {
  const dispatch = useDispatch();
  const schoolLeads = useSelector(selectSchoolLeads);
  const { schoolId } = useParams<{ schoolId: string }>();
  const pageConfig = useSelector(selectSchoolLeadPageConfiguration);
  const countries = useSelector(selectResources('country')).map((country) => {
    return {
      ...country,
      label: country.key.toString() === '237-USA' ? 'USA' : country.label,
      key: (country as Country).id,
    };
  });

  useEffect(() => {
    dispatch(loadResourcesAction('country', undefined, true));
    dispatch(loadSchoolLeadsAction(+schoolId));
  }, [dispatch, schoolId]);

  const createTableLeadsData = useMemo(() => {
    return (leads: LeadDTO[]) => {
      return leads.map((lead) => {
        const { id, country, firstName, lastName, answers, ...rest } = lead.user;
        return {
          ...lead,
          schoolId: +schoolId,
          'user.name': `${firstName} ${lastName}`,
          userId: id,
          'user.country': country?.name,
          leadId: lead.id,
          'question.experience': answers.find((answer: SchoolLeadAnswer) => answer.question.id === 2)?.value || '-',
          ...rest,
        };
      });
    };
  }, [schoolId]);

  const handleSortByChanged = (fieldName: string): void => {
    const orderDirection =
      pageConfig.sortBy !== fieldName
        ? DESCENDING_ORDER_DIRECTION
        : pageConfig.order === ASCENDING_ORDER_DIRECTION
        ? DESCENDING_ORDER_DIRECTION
        : ASCENDING_ORDER_DIRECTION;
    dispatch(loadSchoolLeadsAction(+schoolId, { ...pageConfig, sortBy: fieldName, order: orderDirection }));
  };

  const handlePageChanged = (page: number): void => {
    dispatch(loadSchoolLeadsAction(+schoolId, { ...pageConfig, page }));
  };

  return (
    <>
      <div className="mba-table-wrapper">
        <div className="mba-table-filters">
          <SchoolLeadsFilters schoolId={+schoolId} filtersData={{ countries }} />
        </div>
        <div className="mba-table-container">
          <Table
            id="adminLeadGen"
            columns={LEADS_TABLE_COLUMN_DEFINITION}
            data={createTableLeadsData(schoolLeads)}
            sortingOptions={{
              order: pageConfig.order || DESCENDING_ORDER_DIRECTION,
              sortBy: pageConfig.sortBy || '',
            }}
            onSortByColumn={handleSortByChanged}
          />
        </div>
      </div>
      {pageConfig && pageConfig.totalPages !== INITIAL_PAGE && (
        <Pager
          currentPage={pageConfig.page}
          totalPages={pageConfig.totalPages || INITIAL_PAGE}
          onPageChange={handlePageChanged}
        />
      )}
    </>
  );
};

import { PageSearchQueryConfig, TableListData } from '../../../shared/constants';
import { Registrations } from './registrations.dto';
import {
  DownloadRegistrationsAction,
  DOWNLOAD_REGISTRATIONS,
  LoadRegistrationsAction,
  LOAD_REGISTRATIONS,
  SetRegistrationsAction,
  SET_REGISTRATIONS,
} from './registrations.types';

export const loadRegistrationsAction = (pageConfig?: PageSearchQueryConfig): LoadRegistrationsAction => ({
  type: LOAD_REGISTRATIONS,
  payload: { pageConfig },
});

export const setRegistrationsAction = (data: TableListData<Registrations>): SetRegistrationsAction => ({
  type: SET_REGISTRATIONS,
  payload: { data },
});

export const downloadRegistrationsAction = (): DownloadRegistrationsAction => ({
  type: DOWNLOAD_REGISTRATIONS,
});

import { PageSearchQueryConfig, TableListData } from '../../../shared/constants';
import { SchoolsWithLeadGenDTO } from './schools-with-lead-gen.dto';

export const LOAD_SCHOOLS_WITH_LEAD_GEN = 'LOAD_SCHOOLS_WITH_LEAD_GEN';
export const SET_SCHOOLS_WITH_LEAD_GEN = 'SET_SCHOOLS_WITH_LEAD_GEN';

export interface LoadSchoolsWithLeadGenAction {
  type: typeof LOAD_SCHOOLS_WITH_LEAD_GEN;
  payload: { pageConfig?: PageSearchQueryConfig };
}

export interface SetSchoolsWithLeadGenAction {
  type: typeof SET_SCHOOLS_WITH_LEAD_GEN;
  payload: { data: TableListData<SchoolsWithLeadGenDTO> };
}

export type LeadGenTypes = LoadSchoolsWithLeadGenAction | SetSchoolsWithLeadGenAction;

import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CREATE_RANKING_CALENDAR_CONFIG } from '../Calendar.constants';
import { createRankingCalendarAction } from '../../calendar.actions';
import { AddRankingCalendarDto, RankingTypeOptionsDTO } from '../../calendar.dto';
import { CREATE_RANKING_CALENDAR } from '../../calendar.types';
import Form, { FormDataValues } from '../../../../../../components/Form';
import {
  clearRequestFailedAction,
  selectRequestFormErrors,
  selectRequestIsLoading,
} from '../../../../../../shared/state/global-request';

type Props = {
  rankingTypes: RankingTypeOptionsDTO[];
  onFormClose: () => void;
};

const AddRankingCalendarForm: FunctionComponent<Props> = ({ rankingTypes, onFormClose }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectRequestIsLoading(CREATE_RANKING_CALENDAR));
  const formErrors = useSelector(selectRequestFormErrors(CREATE_RANKING_CALENDAR));
  const [formData, setFormData] = useState({} as FormDataValues);
  const errorCount = Object.keys(formErrors).length;

  const handleChange = (values: FormDataValues): void => {
    setFormData(values);
  };

  const onClose = useCallback((): void => {
    setFormData({} as FormDataValues);
    onFormClose();
    dispatch(clearRequestFailedAction(CREATE_RANKING_CALENDAR));
  }, [dispatch, onFormClose]);

  const addRankingCalendar = (data: FormDataValues): void => {
    dispatch(
      createRankingCalendarAction({
        rankingTypeId: data.rankingTypeId,
        openDate: data.openDate,
        publicationDate: data.publicationDate,
      } as AddRankingCalendarDto),
    );
    if (!isLoading && errorCount === 0) {
      onClose();
    }
  };

  const defaultFormConfig = {
    ...CREATE_RANKING_CALENDAR_CONFIG,
    rankingTypeId: {
      ...CREATE_RANKING_CALENDAR_CONFIG.rankingTypeId,
      options: rankingTypes,
    },
  };

  return (
    <div className="calendar-form add">
      <Form
        id="add-calendar-form"
        isLoading={isLoading}
        config={defaultFormConfig}
        onValuesChanged={handleChange}
        onSubmit={addRankingCalendar}
        submitButtonText="Save"
        actions={[
          {
            label: 'Discard',
            onClick: onClose,
          },
        ]}
        initialValues={formData}
        errors={formErrors}
      />
    </div>
  );
};

export default AddRankingCalendarForm;

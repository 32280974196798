import React, { FunctionComponent } from 'react';

import Grid from '../../../../../../../components/Grid';

import styles from './SpecialReportImage.module.scss';

type SpecialReportImageProps = {
  specialReportImageUrl?: string;
};

const SpecialReportImage: FunctionComponent<SpecialReportImageProps> = ({ specialReportImageUrl }) => {
  return (
    <Grid container className={styles.schoolLogoWrapper}>
      <Grid item>
        <div className={styles.logoWrapper}>
          {specialReportImageUrl && <img src={specialReportImageUrl} alt="special report" />}
        </div>
      </Grid>
    </Grid>
  );
};

export default SpecialReportImage;

import {
  LOG_OUT_USER,
  LogOutUserAction,
  REDIRECT_USER_TO_SSO,
  RedirectUserToSSOAction,
  SET_USER_NAVIGATION,
  SET_USER_TOKEN,
  SetUserNavigationAction,
  SetUserTokenAction,
  VALIDATE_USER_TOKEN,
  ValidateUserTokenAction,
} from './auth.types';
import { NavigationConfiguration } from '../Main/components/Layout';
import { redirectUserToSSOUrl } from '../../../shared/helpers';

export const checkForValidToken = (token: string): ValidateUserTokenAction => ({
  type: VALIDATE_USER_TOKEN,
  payload: { token },
});

export const logOutUser = (): LogOutUserAction => ({
  type: LOG_OUT_USER,
});

export const redirectUserToSSO = (): RedirectUserToSSOAction => {
  redirectUserToSSOUrl();
  return { type: REDIRECT_USER_TO_SSO };
};

export const setUserToken = (token: string): SetUserTokenAction => ({
  type: SET_USER_TOKEN,
  payload: { token },
});

export const setUserNavigation = (navigation: NavigationConfiguration): SetUserNavigationAction => ({
  type: SET_USER_NAVIGATION,
  payload: { navigation },
});

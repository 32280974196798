import { SettingDto } from './settings.types';
import { get, post } from '../../api';
import { BasicResponseDTO, DefaultResponseDTO } from '../../constants';

export const getSettingRequest = (key: string): Promise<DefaultResponseDTO<SettingDto>> => {
  return get<DefaultResponseDTO<SettingDto>>(`/settings/${key}`);
};

export const updateSettingRequest = (data: SettingDto): Promise<BasicResponseDTO> => {
  return post<BasicResponseDTO, SettingDto>(`/settings`, data);
};

export const clearCacheRequest = (): Promise<BasicResponseDTO> => {
  return get<BasicResponseDTO, SettingDto>(`/settings/cache/invalidate`);
};

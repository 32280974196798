import { SettingsActionTypes, SettingsState, SET_SETTINGS } from './settings.types';

const initialState: SettingsState = {};

const settingsReducer = (state = initialState, action: SettingsActionTypes): SettingsState => {
  switch (action.type) {
    case SET_SETTINGS: {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    }

    default: {
      return state;
    }
  }
};

export default settingsReducer;

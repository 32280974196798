import { BasicResponseDTO, DefaultResponseDTO, PageSearchQueryConfig } from '../../../shared/constants';
import { generateSearchQuery } from '../../../shared/helpers';
import { registrationGet } from '../../../shared/registrationApi/api';
import { RegistrationResponseDTO } from './registrations.dto';

export const getRegistrationsRequest = (pageConfig: PageSearchQueryConfig = {}): Promise<RegistrationResponseDTO> => {
  const query = generateSearchQuery(pageConfig);
  return registrationGet<RegistrationResponseDTO>(`/insights/${query}`);
};

export const downloadRegistrationsRequest = (): Promise<BasicResponseDTO> => {
  return registrationGet<BasicResponseDTO>(`/insights/spreadsheets`, {
    responseType: 'blob',
  });
};

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { RANKING_TYPES_TABLE_FILTERS } from './RankingTypesFiltersAndSearch.constants';
import { loadRankingTypesAction } from '../../ranking-types.actions';
import FilterGroup, { mapInitialValuesToFiltersConfig } from '../../../../../../components/FilterGroup';
import SearchInput from '../../../../../../components/SearchInput';
import { PageFilterConfig, PageSearchQueryConfig, Resource } from '../../../../../../shared/constants';

type RankingTypesSearchProps = {
  pageConfig: PageSearchQueryConfig;
  filtersData: { rankingCategories: Resource[] };
};

const RankingTypesFiltersAndSearch: FunctionComponent<RankingTypesSearchProps> = ({ pageConfig, filtersData }) => {
  const dispatch = useDispatch();

  const [rankingFilters, setRankingFilters] = useState({});
  const { filter, search } = pageConfig;

  useEffect(() => {
    const { rankingCategories } = filtersData;

    setRankingFilters(
      mapInitialValuesToFiltersConfig(
        RANKING_TYPES_TABLE_FILTERS,
        {
          categoryId: rankingCategories,
        },
        filter,
        true,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, filtersData]);

  const handleSearchChange = (newPageConfig: PageSearchQueryConfig): void => {
    dispatch(loadRankingTypesAction(newPageConfig));
  };

  const handleFiltersChange = (newFilters: PageFilterConfig): void => {
    dispatch(
      loadRankingTypesAction({
        ...pageConfig,
        filter: newFilters,
      }),
    );
  };

  return (
    <div className="sticky-sidebar-container">
      <SearchInput
        id="ranking-types-search"
        pageConfig={pageConfig}
        placeholder="Search by name"
        onSearchChanged={handleSearchChange}
        searchTerm={search}
      />
      <FilterGroup
        defaultFilters={filter || {}}
        filtersConfig={rankingFilters}
        onFiltersChanged={handleFiltersChange}
      />
    </div>
  );
};

export default RankingTypesFiltersAndSearch;

import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button, { ButtonIcons, ButtonSizes } from '../../../../components/Button';
import Table from '../../../../components/Table';
import Typography, { TypographyVariants } from '../../../../components/Typography';
import { downloadRegistrationsAction, loadRegistrationsAction } from '../registrations.actions';
import { selectRegistrations, selectRegistrationsPageConfiguration } from '../registrations.selectors';
import RegistrationsFilters from './RegistrationsFilters';
import { REGISTRATIONS_TABLE_COLUMN_DEFINITION } from './RegistrationsList.constants';
import Pager, { INITIAL_PAGE } from '../../../../components/Pager';

const RegistrationsList: FunctionComponent = () => {
  const dispatch = useDispatch();
  const pageConfig = useSelector(selectRegistrationsPageConfiguration);
  const registrations = useSelector(selectRegistrations);

  useEffect(() => {
    dispatch(loadRegistrationsAction({ sortBy: 'date', order: 'ASC' }));
  }, [dispatch]);

  const handleDownloadClick = (): void => {
    dispatch(downloadRegistrationsAction());
  };

  const handlePageChanged = (page: number): void => {
    dispatch(loadRegistrationsAction({ ...pageConfig, page }));
  };

  return (
    <>
      <div className="mba-heading--wrapper">
        <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title">
          Registrations
        </Typography>
        {registrations.length > 0 && (
          <div className="mba-actions">
            <Button
              text="Download"
              size={ButtonSizes.big}
              icon={ButtonIcons.download}
              primary
              onClick={handleDownloadClick}
            />
          </div>
        )}
      </div>
      <div className="mba-table-wrapper">
        <div className="mba-table-filters">
          <RegistrationsFilters />
        </div>
        <div className="mba-table-container">
          <Table id="registartions" columns={REGISTRATIONS_TABLE_COLUMN_DEFINITION} data={registrations} />
        </div>
      </div>
      {pageConfig && pageConfig.totalPages !== INITIAL_PAGE && (
        <Pager
          currentPage={pageConfig.page}
          totalPages={pageConfig.totalPages || INITIAL_PAGE}
          onPageChange={handlePageChanged}
        />
      )}
    </>
  );
};

export default RegistrationsList;

import { getTableListDefaultState } from '../../../../shared/helpers';
import {
  LOAD_RANKING_CATEGORIES,
  RankingCategoriesActionTypes,
  SET_RANKING_CATEGORIES,
} from './ranking-categories.types';
import { RankingCategoryDTO } from '../RankingCategoryProfile';
import { TableListData } from '../../../../shared/constants';

const initialState: TableListData<RankingCategoryDTO> = getTableListDefaultState<RankingCategoryDTO>();

const rankingCategoriesReducer = (
  state = initialState,
  action: RankingCategoriesActionTypes,
): TableListData<RankingCategoryDTO> => {
  switch (action.type) {
    case LOAD_RANKING_CATEGORIES: {
      return {
        ...state,
        filter: action.payload.pageConfig?.filter || {},
        search: action.payload.pageConfig?.search || '',
      };
    }
    case SET_RANKING_CATEGORIES: {
      return {
        ...state,
        ...action.payload.tableData,
      };
    }
    default: {
      return state;
    }
  }
};

export default rankingCategoriesReducer;

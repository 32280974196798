import { AccreditationDTO, SaveAccreditationDTO } from './accreditation-profile.dto';
import { del, get, post, put } from '../../../../shared/api';
import { BasicResponseDTO, DefaultResponseDTO } from '../../../../shared/constants';

export const createAccreditationRequest = (
  accreditationData: SaveAccreditationDTO,
): Promise<DefaultResponseDTO<AccreditationDTO>> => {
  return post<DefaultResponseDTO<AccreditationDTO>, SaveAccreditationDTO>('/accreditations', accreditationData);
};

export const getAccreditationRequest = (accreditationId: number): Promise<DefaultResponseDTO<AccreditationDTO>> => {
  return get<DefaultResponseDTO<AccreditationDTO>>(`/accreditations/${accreditationId}`);
};

export const updateAccreditationRequest = (accreditationData: SaveAccreditationDTO): Promise<BasicResponseDTO> => {
  const { id } = accreditationData;
  return put<BasicResponseDTO, SaveAccreditationDTO>(`/accreditations/${id}`, accreditationData);
};

export const deleteAccreditationRequest = (accreditationId: number): Promise<BasicResponseDTO> => {
  return del<BasicResponseDTO>(`/accreditations/${accreditationId}`);
};

import React, { PropsWithChildren, ReactNode, useEffect, useMemo, useState } from 'react';

import { compareValues, getFormErrors, getPropertyValue } from '../../shared/helpers';
import Button, { ButtonSizes } from '../Button';
import {
  FormConfig,
  FormConfigProps,
  FormControlProps,
  FormDataValues,
  FormErrors,
  FormValue,
  getErrorMessage,
  getFormValues,
  initializeFormFields,
} from '../Form';
import Grid, { DEFAULT_GRID_COLSPAN_SIZE, GridColSpanSize } from '../Grid';
import Typography, { TypographyVariants } from '../Typography';
import ViewInput from '../ViewInput';
import { calculateGroupColumnSize, getColumnDetailClassName } from './EditableGroup.helpers';
import { GridOffsetType } from '../Grid/Grid';

interface PrefixColumn {
  columnSize?: {
    xs?: boolean | GridColSpanSize;
    sm?: boolean | GridColSpanSize;
    md?: boolean | GridColSpanSize;
    lg?: boolean | GridColSpanSize;
    xl?: boolean | GridColSpanSize;
  };
  content: ReactNode;
}

export interface EditableGroupProps<T> {
  id?: string;
  columnSize?: GridColSpanSize;
  data: T & object;
  editButtonLabel?: string;
  formConfig: FormConfig;
  formErrors?: FormErrors;
  heading?: ReactNode;
  headingType?: 'normal' | 'back';
  isEditable?: boolean;
  isLoading?: boolean;
  onEditableChange?: (data: boolean) => void;
  onEditCancel?: () => void;
  onEditSubmit?: (data: FormDataValues) => void;
  onValuesChanged?: (data: FormDataValues) => void;
  prefixColumn?: PrefixColumn;
  deleteButtonBlock?: ReactNode;
  additionalActions?: ReactNode;
}

function EditableGroup<T>({
  id,
  columnSize = DEFAULT_GRID_COLSPAN_SIZE as GridColSpanSize,
  data,
  editButtonLabel = 'Edit',
  formConfig,
  formErrors = {} as FormErrors,
  heading,
  headingType = 'normal',
  isLoading = false,
  isEditable = true,
  onEditCancel,
  onEditSubmit,
  onEditableChange,
  onValuesChanged,
  prefixColumn,
  deleteButtonBlock,
  additionalActions,
}: PropsWithChildren<EditableGroupProps<T>>): JSX.Element {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [controls, setControls] = useState({} as FormConfigProps);
  const [formValue, setFormValue] = useState<FormDataValues>({});

  const initialValues = useMemo(
    () =>
      Object.keys(data).reduce((acc, key: string) => {
        const currentValue = getPropertyValue(data, key as keyof T);
        return {
          ...acc,
          [key]: currentValue,
        };
      }, {} as any),
    [data],
  );

  useEffect(() => {
    if (Object.keys(formValue).length === 0) setFormValue(initialValues);
    else if (formValue?.isOptional) setIsChanged(true);
    else setIsChanged(!compareValues(initialValues, formValue, Object.keys(formConfig)));
  }, [formConfig, initialValues, formValue]);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      handleEditCancel();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    onEditableChange && onEditableChange(isEditOpen);

    if (!isEditOpen) {
      setIsChanged(false);
      setFormValue({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditOpen]);

  useEffect(() => {
    const controlsData = initializeFormFields(controls, formConfig, initialValues, {});
    setControls(controlsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formConfig, initialValues]);

  const handleChange = (name: string, value: FormValue): void => {
    const oldControls: FormConfigProps = controls ? { ...controls } : {};
    const error = getErrorMessage(
      value,
      (controls[name] as FormControlProps).required,
      (controls[name] as FormControlProps).validations,
      (controls[name] as FormControlProps).fieldType,
    );
    const currentControl = {
      [name]: {
        ...(oldControls[name] as FormControlProps),
        error,
        touched: true,
        valid: !error,
        value,
      },
    };

    const updatedControls: FormConfigProps = {
      ...oldControls,
      ...currentControl,
    };

    setHasErrors(checkForErrors(updatedControls));
    setControls(updatedControls);

    setFormValue(getFormValues(updatedControls));
    onValuesChanged && onValuesChanged(getFormValues(updatedControls));
  };

  const handleEditCancel = (): void => {
    setIsEditOpen(false);
    onEditCancel && onEditCancel();

    if (Object.keys(data).length) {
      const initialValues = Object.keys(data).reduce((acc, key: string) => {
        const currentValue = getPropertyValue(data, key as keyof T);
        return {
          ...acc,
          [key]: currentValue,
        };
      }, {});

      const controlsData = initializeFormFields(controls, formConfig, initialValues, {});
      setControls(controlsData);
    }
  };

  const handleEditSubmit = (event: React.FormEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    onEditSubmit && onEditSubmit(getFormValues(controls));
  };

  const checkForErrors = (controls: FormConfigProps): boolean => {
    const controlKeys = Object.keys(controls);
    return !!controlKeys.find((key) => controls[key].error);
  };

  const editButtons: ReactNode = isEditable && (
    <>
      {isEditOpen ? (
        <div className="mba-actions">
          <Button
            primary
            text="Save"
            size={ButtonSizes.big}
            disabled={formValue?.isOptional ? !isChanged : hasErrors || !isChanged}
            isLoading={isLoading}
            onClick={handleEditSubmit}
          />
          <Button text="Cancel" size={ButtonSizes.big} onClick={handleEditCancel} />
        </div>
      ) : (
        <div>
          {additionalActions}
          <Button
            primary
            text={editButtonLabel}
            size={ButtonSizes.big}
            onClick={(): void => {
              setIsEditOpen(true);
            }}
          />
        </div>
      )}
    </>
  );

  const headingBlock =
    headingType === 'back' ? (
      <Typography component="h1" variant={TypographyVariants.h1} className="mba-heading--back">
        <div className="mba-space-between">
          <div>{heading}</div>
          {editButtons}
        </div>
      </Typography>
    ) : (
      <div className="mba-heading--wrapper mba-heading--table">
        {heading}
        {editButtons}
      </div>
    );

  const groupColumns = Object.entries(controls).map(([key, control], index) => {
    const fieldErrors = getFormErrors(formErrors, key);
    const columnClasses = ['mba-details-column'];

    if (control.error) {
      fieldErrors.push(control.error as string);
    }

    const customColumnClass = getColumnDetailClassName(columnSize, index, control.offset as GridOffsetType);
    if (customColumnClass) {
      columnClasses.push(customColumnClass);
    }

    return (
      <Grid
        item
        xs={DEFAULT_GRID_COLSPAN_SIZE}
        sm={columnSize}
        key={key}
        className={columnClasses.join(' ')}
        offset={control.offset as GridOffsetType}
      >
        <ViewInput
          {...(control as FormControlProps)}
          id={id}
          name={key}
          isEdit={isEditOpen}
          onChange={handleChange}
          error={fieldErrors[0]}
        />
      </Grid>
    );
  });

  let gridContent: ReactNode;
  if (prefixColumn) {
    const { columnSize: prefixSize, content } = prefixColumn;

    gridContent = (
      <>
        <Grid item {...prefixSize} className="mba-details-column mba-details-column--first">
          {content}
        </Grid>
        <Grid
          item
          className="mba-details-column  mba-details-column--last"
          xs={calculateGroupColumnSize(prefixSize?.xs || DEFAULT_GRID_COLSPAN_SIZE, DEFAULT_GRID_COLSPAN_SIZE)}
          sm={calculateGroupColumnSize(prefixSize?.sm)}
          md={calculateGroupColumnSize(prefixSize?.md)}
          lg={calculateGroupColumnSize(prefixSize?.lg)}
          xl={calculateGroupColumnSize(prefixSize?.xl)}
        >
          <Grid container compact className="mba-column-group">
            {groupColumns}
          </Grid>
        </Grid>
      </>
    );
  } else {
    gridContent = groupColumns;
  }

  return (
    <>
      {headingBlock}
      <Grid container compact className="mba-column-group">
        {gridContent}
      </Grid>
      {isEditOpen && deleteButtonBlock}
    </>
  );
}

export default EditableGroup;

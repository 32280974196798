import { PrivacyDto } from './privacy.dto';
import { PrivacyActionTypes, SET_PRIVACY_DATA } from './privacy.types';

const initialState: PrivacyDto = {};

const privacyReducer = (state = initialState, action: PrivacyActionTypes): PrivacyDto => {
  switch (action.type) {
    case SET_PRIVACY_DATA: {
      return {
        ...state,
        description: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default privacyReducer;

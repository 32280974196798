import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ADMIN_USERS_TABLE_FILTERS } from './AccountsFiltersAndSearch.constants';
import { loadSchoolAccountsAction } from '../../school-accounts.actions';
import FilterGroup, { mapInitialValuesToFiltersConfig } from '../../../../../components/FilterGroup';
import SearchInput from '../../../../../components/SearchInput';
import { PageFilterConfig, PageSearchQueryConfig } from '../../../../../shared/constants';
import { selectResources } from '../../../../../shared/state/resources';
import { selectRoles } from '../../../../../shared/state/roles';
import { selectStatuses } from '../../../../../shared/state/statuses';

type FiltersAndSearchProps = {
  pageConfig: PageSearchQueryConfig;
};

const AccountsFiltersAndSearch: FunctionComponent<FiltersAndSearchProps> = ({ pageConfig }) => {
  const dispatch = useDispatch();
  const accreditations = useSelector(selectResources('accreditations'));
  const rankingTypes = useSelector(selectResources('ranking-types'));
  const schoolUserRoles = useSelector(selectRoles('school-users'));
  const schoolUserStatuses = useSelector(selectStatuses('user'));
  const [userFilters, setUserFilters] = useState({});
  const { filter, search } = pageConfig;

  useEffect(() => {
    setUserFilters(
      mapInitialValuesToFiltersConfig(
        ADMIN_USERS_TABLE_FILTERS,
        {
          accreditationIds: accreditations,
          permissions: rankingTypes,
          role: schoolUserRoles,
          status: schoolUserStatuses,
        },
        filter,
        true,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const handleSearchChange = (newPageConfig: PageSearchQueryConfig): void => {
    dispatch(loadSchoolAccountsAction(newPageConfig));
  };

  const handleFiltersChange = (newFilters: PageFilterConfig): void => {
    dispatch(
      loadSchoolAccountsAction({
        ...pageConfig,
        filter: newFilters,
      }),
    );
  };

  return (
    <div className="sticky-sidebar-container">
      <SearchInput
        id="accounts-search"
        pageConfig={pageConfig}
        placeholder="Search by name, email or SID"
        onSearchChanged={handleSearchChange}
        searchTerm={search}
      />
      <FilterGroup defaultFilters={filter || {}} filtersConfig={userFilters} onFiltersChanged={handleFiltersChange} />
    </div>
  );
};

export default AccountsFiltersAndSearch;

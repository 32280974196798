import { SchoolRankingsDto } from './school-rankings.dto';
import { PageSearchQueryConfig, TableListData } from '../../../../shared/constants';

export const LOAD_SCHOOL_RANKINGS = 'LOAD_SCHOOL_RANKINGS';
export const SET_SCHOOL_RANKINGS = 'SET_SCHOOL_RANKINGS';

export interface LoadSchoolRankingsAction {
  type: typeof LOAD_SCHOOL_RANKINGS;
  payload: {
    schoolId: number;
    pageConfig?: PageSearchQueryConfig;
  };
}

export interface SetSchoolRankingsAction {
  type: typeof SET_SCHOOL_RANKINGS;
  payload: { tableData: TableListData<SchoolRankingsDto> };
}

export type SchoolRankingsActionTypes = LoadSchoolRankingsAction | SetSchoolRankingsAction;

import { FormConfig } from '../../../../../components/Form';
import { ColumnDefinition, TableHeaderColumnTypes } from '../../../../../components/Table';
import { DATE_FORMAT_REGEX } from '../../../../../shared/constants';

export const RANKINGS_LIST_MAIN_ROUTE = '/rankings/list';

export const RANKING_CREATE_FORM_CONFIG: FormConfig = {
  name: {
    label: 'Name',
    placeholder: 'Enter ranking name',
    fieldType: 'text',
    validations: {
      required: {
        errorText: `Please enter a ranking name!`,
      },
    },
  },
  note: {
    label: 'Notes',
    placeholder: 'Enter notes',
    fieldType: 'textarea',
  },
  rankingTypeId: {
    label: 'Ranking type',
    placeholder: 'Choose a ranking type',
    fieldType: 'select',
    searchable: true,
    validations: {
      required: {
        errorText: `Please choose a ranking type!`,
      },
    },
    options: [],
  },
  publicationDate: {
    label: 'Publication date',
    placeholder: 'DD/MM/YYYY',
    fieldType: 'date',
    validations: {
      regex: {
        errorText: `Please enter a valid date DD/MM/YYYY!`,
        // eslint-disable-next-line no-useless-escape
        rule: DATE_FORMAT_REGEX,
      },
    },
  },
};

export const RANKING_EDIT_FORM_CONFIG: FormConfig = {
  ...RANKING_CREATE_FORM_CONFIG,
  status: {
    label: 'Status',
    placeholder: 'Choose status',
    fieldType: 'select',
    options: [],
    required: true,
  },
};

export const RANKINGS_TABLE_COLUMN_DEFINITION: ColumnDefinition[] = [
  {
    id: 'status',
    title: 'Status',
    type: TableHeaderColumnTypes.status,
    sorting: true,
    width: '90px',
  },
  {
    id: 'name',
    sorting: true,
    title: 'Name',
    type: TableHeaderColumnTypes.link,
    path: '/rankings/profile/:id/timeline',
  },
  {
    id: 'publicationDate',
    sorting: true,
    title: 'Publication date',
    type: TableHeaderColumnTypes.date,
    width: '160px',
  },
  {
    id: 'updatedAt',
    sorting: true,
    title: 'Last updated',
    type: TableHeaderColumnTypes.date,
    width: '160px',
  },
];

import React, { FunctionComponent } from 'react';

import { LabelSize, LabelStatus, LabelTier } from './Label.constants';

import styles from './Label.module.scss';

type LabelProps = {
  color?: string;
  fullWidth?: boolean;
  tier?: LabelTier;
  size?: LabelSize;
  status?: LabelStatus;
};

const Label: FunctionComponent<LabelProps> = ({ children, color, fullWidth = false, size, status, tier }) => {
  let classes = 'o-labels';
  if (size) {
    classes += ` o-labels--${size}`;
  }

  if (tier !== undefined) {
    classes += ` o-labels--${tier}`;
  }

  if (status !== undefined) {
    classes += ` o-labels--${status}`;
  }

  if (fullWidth) {
    classes += ` ${styles.fullWidth}`;
  }

  return (
    <span className={classes} style={{ backgroundColor: color }} data-testid={'label'}>
      {children}
    </span>
  );
};

export default Label;

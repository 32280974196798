import { TermsDto } from './terms.dto';

export const LOAD_TERMS = 'LOAD_TERMS';
export const SET_TERMS = 'SET_TERMS';
export const EDIT_TERMS = 'EDIT_TERMS';

export interface LoadTermsAction {
  type: typeof LOAD_TERMS;
}

export interface SetTermsAction {
  type: typeof SET_TERMS;
  payload: { data: string };
}

export interface EditTermsAction {
  type: typeof EDIT_TERMS;
  payload: { data: TermsDto };
}

export type TermsActionTypes = LoadTermsAction | SetTermsAction | EditTermsAction;

import { SchoolWidgetGroupDto } from '../SchoolWidgetGroups/school-widget-groups.dto';
import { WidgetDTO } from './components/EditableWidget/EditableWidget.constants';
import {
  DELETE_WIDGET_FROM_LIST,
  LOAD_SCHOOL_WIDGETS,
  SchoolWidgetDetailsActionTypes,
  SET_SCHOOL_WIDGETS,
  UPDATE_SCHOOL_WIDGET,
  ADD_WIDGET_TO_LIST,
  SET_SCHOOL_WIDGET_GROUP,
} from './school-widget-details.types';

const initialState: { widgets: WidgetDTO[]; widgetGroup: SchoolWidgetGroupDto } = {
  widgets: [],
  widgetGroup: {} as SchoolWidgetGroupDto,
};

const schoolWidgetReducer = (
  state = initialState,
  action: SchoolWidgetDetailsActionTypes,
): { widgets: WidgetDTO[]; widgetGroup: SchoolWidgetGroupDto } => {
  switch (action.type) {
    case LOAD_SCHOOL_WIDGETS: {
      return {
        ...state,
      };
    }
    case SET_SCHOOL_WIDGETS: {
      return {
        ...state,
        widgets: action.payload,
      };
    }
    case UPDATE_SCHOOL_WIDGET: {
      return {
        ...state,
        widgets: state.widgets.map((widget) => (widget.id === action.payload.id ? action.payload : widget)),
      };
    }
    case ADD_WIDGET_TO_LIST: {
      const { id, html, type, isHalfWidth, sortIndex, details } = action.payload;
      return {
        ...state,
        widgets: [{ id, html, type, isHalfWidth, sortIndex, details }, ...state.widgets],
        widgetGroup: { ...state.widgetGroup, widgetCount: +state.widgetGroup.widgetCount + 1 },
      };
    }
    case DELETE_WIDGET_FROM_LIST: {
      return {
        ...state,
        widgets: state.widgets.filter((widget) => widget.id !== action.payload),
        widgetGroup: { ...state.widgetGroup, widgetCount: +state.widgetGroup.widgetCount - 1 },
      };
    }
    case SET_SCHOOL_WIDGET_GROUP: {
      return {
        ...state,
        widgetGroup: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default schoolWidgetReducer;

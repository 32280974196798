import { RootState } from '../../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const rankingsTypeSelector = (state: RootState) => state.rankings.types;

export const selectRankingTypesTableData = createDeepEqualSelector(rankingsTypeSelector, (rankingsType) => {
  return rankingsType.data;
});

export const selectRankingTypesPageConfiguration = createDeepEqualSelector(rankingsTypeSelector, (rankingsType) => {
  return {
    filter: rankingsType.filter,
    limit: rankingsType.pagination.limit,
    order: rankingsType.sortOrder.order,
    page: rankingsType.pagination.page,
    search: rankingsType.search,
    sortBy: rankingsType.sortOrder.sortBy,
    totalPages: rankingsType.pagination.totalPages,
  };
});

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import RankingTypeDeleteConfirmation from './RankingTypeDeleteConfirmation/RankingTypeDeleteConfirmation';
import RankingTypeProfileContent from './RankingTypeProfileContent';
import { deleteRankingTypeAction, loadRankingTypeAction } from '../ranking-type-profile.actions';
import { RankingTypeDTO } from '../ranking-type-profile.dto';
import { selectCurrentRankingType } from '../ranking-type-profile.selectors';
import { LOAD_RANKING_TYPE, RankingTypeProfileRouteParams } from '../ranking-type-profile.types';
import { DELETE_RANKING_CATEGORY } from '../../RankingCategoryProfile';
import RankingsTable from '../../RankingTypesList/components/RankingsTable';
import Button, { ButtonSizes } from '../../../../../components/Button';
import Typography, { TypographyVariants } from '../../../../../components/Typography';
import Modal, { MODAL_ANIMATION_DURATION } from '../../../../../components/Modal';
import {
  selectRequestErrorMessage,
  selectRequestIsLoading,
  setRequestSucceededAction,
} from '../../../../../shared/state/global-request';
import NotFound from '../../../../../components/NotFound';
import PageLoader from '../../../../../components/PageLoader';

const RankingTypeProfile: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id: rankingTypeId } = useParams<RankingTypeProfileRouteParams>();
  const { id, isDefault, name } = useSelector(selectCurrentRankingType) as RankingTypeDTO;
  const deleteRankingCategoryError = useSelector(selectRequestErrorMessage(DELETE_RANKING_CATEGORY));
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const isLoading = useSelector(selectRequestIsLoading(LOAD_RANKING_TYPE));

  useEffect(() => {
    if (rankingTypeId) {
      dispatch(loadRankingTypeAction(+rankingTypeId));
    }
  }, [dispatch, rankingTypeId]);

  const handleDeleteRankingTypeConfirm = (): void => {
    dispatch(deleteRankingTypeAction(id));
  };

  const openModal = (): void => {
    setIsConfirmPopupOpen(true);
  };

  const closeModal = (): void => {
    setIsConfirmPopupOpen(false);

    setTimeout(() => {
      dispatch(setRequestSucceededAction(DELETE_RANKING_CATEGORY));
    }, MODAL_ANIMATION_DURATION);
  };

  return (
    <>
      {id ? (
        <>
          <RankingTypeProfileContent rankingTypeId={parseInt(rankingTypeId)} />
          <RankingsTable rankingTypeId={rankingTypeId.toString()} />
          {!isDefault && (
            <>
              <hr className="mba-separator" />
              <div className="mba-heading--wrapper mba-heading--table">
                <Typography component="p" variant={TypographyVariants.h3} className="mba-heading--title-2">
                  Danger zone
                </Typography>
                <Button primary danger text="Delete ranking type" size={ButtonSizes.big} onClick={openModal} />
              </div>
              <br />
              <br />
              <Modal title="Delete a ranking type" isOpen={isConfirmPopupOpen} onClose={closeModal}>
                <RankingTypeDeleteConfirmation
                  error={deleteRankingCategoryError}
                  name={name}
                  onCancel={closeModal}
                  onConfirm={handleDeleteRankingTypeConfirm}
                />
              </Modal>
            </>
          )}
        </>
      ) : isLoading || !rankingTypeId ? (
        <PageLoader />
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default RankingTypeProfile;

import { PageSearchQueryConfig, TableListData } from '../../constants';
import { LeadDTO } from './lead.dto';

export const LOAD_SCHOOL_LEADS = 'LOAD_SCHOOL_LEADS';
export const SET_SCHOOL_LEADS = 'SET_SCHOOL_LEADS';

export interface LoadSchoolLeadsAction {
  type: typeof LOAD_SCHOOL_LEADS;
  payload: { schoolId: number; pageConfig?: PageSearchQueryConfig };
}

export interface SetSchoolLeadsAction {
  type: typeof SET_SCHOOL_LEADS;
  payload: { data: TableListData<LeadDTO> };
}

export type LeadGenTypes = LoadSchoolLeadsAction | SetSchoolLeadsAction;

import { RankingCategoryDTO } from '../RankingCategoryProfile';
import { PageSearchQueryConfig, TableListData } from '../../../../shared/constants';

export const LOAD_RANKING_CATEGORIES = 'LOAD_RANKING_CATEGORIES';
export const SET_RANKING_CATEGORIES = 'SET_RANKING_CATEGORIES';

export interface LoadRankingCategoriesAction {
  type: typeof LOAD_RANKING_CATEGORIES;
  payload: { pageConfig?: PageSearchQueryConfig };
}

export interface SetRankingCategoriesAction {
  type: typeof SET_RANKING_CATEGORIES;
  payload: { tableData: TableListData<RankingCategoryDTO> };
}

export type RankingCategoriesActionTypes = LoadRankingCategoriesAction | SetRankingCategoriesAction;

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AccreditationDeleteConfirmation from './AccreditationDeleteConfirmation';
import AccreditationForm from './AccreditationForm';
import { ACCREDITATIONS_TABLE_COLUMN_DEFINITION } from './AccreditationsList.constants';
import { loadAccreditationsAction } from '../accreditations.actions';
import { selectAccreditationsPageConfiguration, selectAccreditationsTableData } from '../accreditations.selectors';
import {
  AccreditationDTO,
  clearCurrentAccreditationAction,
  deleteAccreditationAction,
  setAccreditationAction,
} from '../../AccreditationProfile';
import Button, { ButtonIcons, ButtonSizes } from '../../../../../components/Button';
import Modal, { MODAL_ANIMATION_DURATION } from '../../../../../components/Modal';
import Pager, { INITIAL_PAGE } from '../../../../../components/Pager';
import Table, { TableHeaderColumnTypes } from '../../../../../components/Table';
import Typography, { TypographyVariants } from '../../../../../components/Typography';
import { ASCENDING_ORDER_DIRECTION, DESCENDING_ORDER_DIRECTION } from '../../../../../shared/constants';

const AccreditationsList: FunctionComponent = () => {
  const dispatch = useDispatch();
  const pageConfig = useSelector(selectAccreditationsPageConfiguration);
  const accreditationsData = useSelector(selectAccreditationsTableData);
  const [isAccreditationEdit, setIsAccreditationEdit] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [accreditationToRemove, setAccreditationToRemove] = useState(0);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);

  useEffect(() => {
    dispatch(loadAccreditationsAction());
  }, [dispatch]);

  useEffect(() => {
    if (isFormModalOpen || isConfirmPopupOpen) {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accreditationsData]);

  const handlePageChanged = (page: number): void => {
    dispatch(loadAccreditationsAction({ ...pageConfig, page }));
  };

  const handleSortByChanged = (fieldName: string): void => {
    const orderDirection =
      pageConfig.sortBy !== fieldName
        ? DESCENDING_ORDER_DIRECTION
        : pageConfig.order === ASCENDING_ORDER_DIRECTION
        ? DESCENDING_ORDER_DIRECTION
        : ASCENDING_ORDER_DIRECTION;
    dispatch(loadAccreditationsAction({ ...pageConfig, sortBy: fieldName, order: orderDirection }));
  };

  const handleRemoveCampusConfirm = (accreditationId: number): void => {
    dispatch(deleteAccreditationAction(accreditationId));
  };

  const openFormModal = (id?: number): void => {
    if (id) {
      const currentAccreditation = accreditationsData.find((ranking: AccreditationDTO) => ranking.id === id);
      if (currentAccreditation) {
        dispatch(setAccreditationAction(currentAccreditation));
        setIsAccreditationEdit(true);
      }
    } else {
      setIsAccreditationEdit(false);
    }

    setIsFormModalOpen(true);
  };

  const closeModal = (): void => {
    setIsConfirmPopupOpen(false);
    setIsFormModalOpen(false);

    setTimeout(() => {
      dispatch(clearCurrentAccreditationAction());
    }, MODAL_ANIMATION_DURATION);
  };

  const openAccreditationDeletePopup = (id: number): void => {
    setAccreditationToRemove(id);
    setIsConfirmPopupOpen(true);
  };

  const accreditationsTableConfig = [
    ...ACCREDITATIONS_TABLE_COLUMN_DEFINITION,
    {
      id: 'actions',
      title: 'Action',
      type: TableHeaderColumnTypes.actions,
      width: '155px',
      actions: [
        {
          label: 'Edit',
          handleOnClick: openFormModal,
        },
        {
          label: 'Delete',
          handleOnClick: openAccreditationDeletePopup,
        },
      ],
    },
  ];

  return (
    <>
      <div className="mba-heading--wrapper mba-heading--table">
        <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title">
          Accreditations
        </Typography>
        <Button
          text="Create new accreditation"
          size={ButtonSizes.big}
          icon={ButtonIcons.plus}
          disabled={isFormModalOpen}
          primary
          onClick={(): void => openFormModal()}
        />
      </div>
      <div className="mba-table-wrapper">
        <Table
          id="accreditations-list"
          columns={accreditationsTableConfig}
          data={accreditationsData}
          sortingOptions={{
            order: pageConfig.order || DESCENDING_ORDER_DIRECTION,
            sortBy: pageConfig.sortBy || '',
          }}
          onSortByColumn={handleSortByChanged}
        />
      </div>
      {pageConfig && pageConfig.totalPages !== INITIAL_PAGE && (
        <Pager
          currentPage={pageConfig.page}
          totalPages={pageConfig.totalPages || INITIAL_PAGE}
          onPageChange={handlePageChanged}
        />
      )}
      <Modal
        title={`${isAccreditationEdit ? 'Edit' : 'Create new'} accreditation`}
        isOpen={isFormModalOpen}
        onClose={closeModal}
      >
        <AccreditationForm onFormClose={closeModal} isEdit={isAccreditationEdit} />
      </Modal>
      <Modal title="Delete accreditation" isOpen={isConfirmPopupOpen} onClose={closeModal}>
        <AccreditationDeleteConfirmation
          id={accreditationToRemove}
          onCancel={closeModal}
          onConfirm={handleRemoveCampusConfirm}
        />
      </Modal>
    </>
  );
};

export default AccreditationsList;

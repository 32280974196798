import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware as createRouterMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';

import { rootReducer } from './reducers';
import { termsSaga } from '../containers/Admin/TermsAndConditions';
import { usersSaga } from '../containers/Admin/Users';
import { authSaga } from '../containers/App/Auth';
import { schoolWidgetsSaga } from '../containers/Commercial/SchoolWidgetDetails';
import { schoolWidgetGroupsSaga } from '../containers/Commercial/SchoolWidgetGroups';
import { accreditationProfileSaga } from '../containers/Rankings/Accreditation/AccreditationProfile';
import { accreditationsSaga } from '../containers/Rankings/Accreditation/AccreditationsList';
import { calendarSaga } from '../containers/Rankings/AdditionalInformation/Calendar';
import { rankingCategoriesSaga } from '../containers/Rankings/Categorization/RankingCategoriesList';
import { rankingCategoryProfileSaga } from '../containers/Rankings/Categorization/RankingCategoryProfile';
import { rankingTypeProfileSaga } from '../containers/Rankings/Categorization/RankingTypeProfile';
import { rankingTypesSaga } from '../containers/Rankings/Categorization/RankingTypesList';
import { rankingsListSaga } from '../containers/Rankings/Rankings/RankingsList';
import { rankingFieldDataSaga } from '../containers/Rankings/Rankings/RankingProfile/RankingData/RankingFieldProfile/RankingFieldData';
import { rankingFieldProfileSaga } from '../containers/Rankings/Rankings/RankingProfile/RankingData/RankingFieldProfile/RankingFieldDetails';
import { rankingFieldsSaga } from '../containers/Rankings/Rankings/RankingProfile/RankingData/RankingFieldsList';
import { rankingTimelineStepsSaga } from '../containers/Rankings/Rankings/RankingProfile/RankingDetails/TimelineSteps';
import { rankingProfileSaga } from '../containers/Rankings/Rankings/RankingProfile/RankingInfo';
import { rankingSchoolsSaga } from '../containers/Rankings/Rankings/RankingProfile/RankingSchools';
import { rankingSpreadsheetsSaga } from '../containers/Rankings/Rankings/RankingProfile/Spreadsheets';
import { spreadsheetTemplateFieldSaga } from '../containers/Rankings/SpreadsheetTemplate';
import { schoolAccountsListSaga } from '../containers/Schools/Accounts';
import { campusesSaga } from '../containers/Schools/SchoolProfile/CampusesList';
import { campusProfileSaga } from '../containers/Schools/SchoolProfile/CampusProfile';
import { schoolDetailsSaga } from '../containers/Schools/SchoolProfile/SchoolDetails';
import { partnershipsSaga } from '../containers/Schools/SchoolProfile/SchoolPartnerships';
import { schoolRankingsSaga } from '../containers/Schools/SchoolProfile/SchoolRankings';
import { schoolUsersListSaga } from '../containers/Schools/SchoolProfile/SchoolUsersList';
import { schoolsListSaga } from '../containers/Schools/SchoolsList';
import { schoolUserProfileSaga } from '../containers/Schools/SchoolUserProfile';
import history from '../shared/history';
import { attachmentsSaga } from '../shared/state/attachments';
import { globalRequestSaga } from '../shared/state/global-request';
import { rankingTypesDropdownSaga } from '../shared/state/ranking-types-dropdown';
import { resourcesSaga } from '../shared/state/resources';
import { rolesSaga } from '../shared/state/roles';
import { schoolResourcesSaga } from '../shared/state/school-resources';
import { statusesSaga } from '../shared/state/statuses';
import { settingsSaga } from '../shared/state/settings';
import { privacySaga } from '../containers/Admin/PrivacyPolicy';
import { faqSaga } from '../containers/Rankings/AdditionalInformation/FAQs';
import { schoolsWithLeadGenSaga } from '../containers/Commercial/SchoolsWithLeadGen';
import schoolLeadsSaga from '../shared/state/leads/lead.sagas';
import schoolSingleLeadSaga from '../containers/Commercial/SchoolLeadsDetails/school-leads-details.sagas';
import schoolWithLeadDetailsSaga from '../containers/Commercial/SchoolLeads/SchoolWithLeadDetails/school-with-lead-details.sagas';
import { logsSaga } from '../containers/Admin/Logs';
import { registrationsSaga } from '../containers/Commercial/Registrations';
import { onsiteMessagingSaga } from '../containers/Admin/OnsiteMessaging';
import { updateSchoolLeadActiveSaga } from '../containers/Commercial/SchoolsWithLeadGen/schools-with-lead-gen.sagas';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const routerMiddleware = createRouterMiddleware(history);

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(routerMiddleware, sagaMiddleware)));

// Auth
sagaMiddleware.run(authSaga);

// Common
sagaMiddleware.run(attachmentsSaga);
sagaMiddleware.run(globalRequestSaga);
sagaMiddleware.run(rankingTypesDropdownSaga);
sagaMiddleware.run(resourcesSaga);
sagaMiddleware.run(rolesSaga);
sagaMiddleware.run(schoolResourcesSaga);
sagaMiddleware.run(statusesSaga);
sagaMiddleware.run(settingsSaga);

// Admin
sagaMiddleware.run(usersSaga);
sagaMiddleware.run(termsSaga);
sagaMiddleware.run(privacySaga);
sagaMiddleware.run(onsiteMessagingSaga);

// School accounts
sagaMiddleware.run(schoolAccountsListSaga);

// Schools
sagaMiddleware.run(schoolDetailsSaga);
sagaMiddleware.run(schoolsListSaga);
sagaMiddleware.run(schoolUserProfileSaga);
sagaMiddleware.run(schoolUsersListSaga);

// School campuses
sagaMiddleware.run(campusesSaga);
sagaMiddleware.run(campusProfileSaga);

// School partnerships
sagaMiddleware.run(partnershipsSaga);

// School Rankings
sagaMiddleware.run(schoolRankingsSaga);

// Categorization
sagaMiddleware.run(rankingCategoriesSaga);
sagaMiddleware.run(rankingCategoryProfileSaga);
sagaMiddleware.run(rankingTypeProfileSaga);
sagaMiddleware.run(rankingTypesSaga);

// Rankings
sagaMiddleware.run(rankingProfileSaga);
sagaMiddleware.run(rankingsListSaga);
sagaMiddleware.run(rankingFieldsSaga);

// Timeline Steps
sagaMiddleware.run(rankingTimelineStepsSaga);

// Ranking Schools
sagaMiddleware.run(rankingSchoolsSaga);

// Ranking Spreadsheets
sagaMiddleware.run(rankingSpreadsheetsSaga);

// Accreditations
sagaMiddleware.run(accreditationProfileSaga);
sagaMiddleware.run(accreditationsSaga);

// Columns and Filters / Fields
sagaMiddleware.run(rankingFieldProfileSaga);

// Ranking Field Data
sagaMiddleware.run(rankingFieldDataSaga);

// Additional Information
sagaMiddleware.run(calendarSaga);
sagaMiddleware.run(faqSaga);

// Commercial
sagaMiddleware.run(schoolWidgetGroupsSaga);
sagaMiddleware.run(schoolWidgetsSaga);
sagaMiddleware.run(schoolsWithLeadGenSaga);
sagaMiddleware.run(schoolLeadsSaga);
sagaMiddleware.run(schoolSingleLeadSaga);
sagaMiddleware.run(logsSaga);
sagaMiddleware.run(registrationsSaga);

sagaMiddleware.run(schoolWithLeadDetailsSaga);

sagaMiddleware.run(updateSchoolLeadActiveSaga);

// Spreadsheet Template Field
sagaMiddleware.run(spreadsheetTemplateFieldSaga);

export default store;

import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import Button, { ButtonSizes } from '../../../../../../components/Button';
import Typography from '../../../../../../components/Typography';
import { selectRequestIsLoading } from '../../../../../../shared/state/global-request';
import { REMOVE_PARTNERSHIP } from '../../partnerships.types';

type PartnershipRemoveConfirmationProps = {
  onCancel: () => void;
  onConfirm: () => void;
};

const PartnershipRemoveConfirmation: FunctionComponent<PartnershipRemoveConfirmationProps> = ({
  onCancel,
  onConfirm,
}) => {
  const isLoading = useSelector(selectRequestIsLoading(REMOVE_PARTNERSHIP));
  return (
    <>
      <Typography>Are you sure you want to remove this school from the selected partnership?</Typography>
      <div className="mba-actions">
        <Button onClick={onCancel} text="Cancel" size={ButtonSizes.big} />
        <Button onClick={onConfirm} isLoading={isLoading} primary text="Yes" size={ButtonSizes.big} />
      </div>
    </>
  );
};

export default PartnershipRemoveConfirmation;

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Button, { ButtonSizes } from '../../../../../../components/Button';
import Typography from '../../../../../../components/Typography';
import { selectRequestIsLoading } from '../../../../../../shared/state/global-request';
import { DELETE_SCHOOL_USER, SchoolUserDTO } from '../../../../SchoolUserProfile';

type RemoveSchoolUserConfirmationProps = {
  onCancel: () => void;
  onConfirm: () => void;
  schoolId: number;
  user: SchoolUserDTO | null;
};

const RemoveSchoolUserConfirmation: FunctionComponent<RemoveSchoolUserConfirmationProps> = ({
  onCancel,
  onConfirm,
  schoolId,
  user,
}) => {
  const isLoading = useSelector(selectRequestIsLoading(DELETE_SCHOOL_USER));
  const [userName, setUserName] = useState('this user');
  const [mainSchoolId, setMainSchoolId] = useState(0);

  useEffect(() => {
    if (user) {
      setUserName(user.name);
      setMainSchoolId(user.school.key);
    }
  }, [user]);

  let message: React.ReactNode = `Are you sure you want to remove ${userName} from the list of users for this school?`;
  if (mainSchoolId === schoolId) {
    message = (
      <>
        {message}
        <br />
        <br />
        This is the main school for this user. If you proceed,{' '}
        <strong>all relations with other joined schools will be removed</strong> as well.
      </>
    );
  }

  return (
    <>
      <Typography>{message}</Typography>
      <div className="mba-actions">
        <Button onClick={onCancel} text="Cancel" size={ButtonSizes.big} />
        <Button isLoading={isLoading} onClick={onConfirm} primary text="Yes" size={ButtonSizes.big} />
      </div>
    </>
  );
};

export default RemoveSchoolUserConfirmation;

import { SchoolWidgetGroupDto } from '../SchoolWidgetGroups/school-widget-groups.dto';
import { NewWidgetDTO, WidgetDTO, WidgetOrderDTO } from './components/EditableWidget/EditableWidget.constants';

export const LOAD_SCHOOL_WIDGETS = 'LOAD_SCHOOL_WIDGETS';
export const SET_SCHOOL_WIDGETS = 'SET_SCHOOL_WIDGETS';
export const UPDATE_SCHOOL_WIDGET = 'UPDATE_SCHOOL_WIDGET';
export const UPDATE_SCHOOL_WIDGET_ORDER = 'UPDATE_SCHOOL_WIDGET_ORDER';
export const CREATE_SCHOOL_WIDGET = 'CREATE_SCHOOL_WIDGET';
export const DELETE_SCHOOL_WIDGET = 'DELETE_SCHOOL_WIDGET';
export const ADD_WIDGET_TO_LIST = 'ADD_WIDGET_TO_LIST';
export const DELETE_WIDGET_FROM_LIST = 'DELETE_WIDGET_FROM_LIST';
export const LOAD_SCHOOL_WIDGET_GROUP = 'LOAD_SCHOOL_WIDGET_GROUP';
export const SET_SCHOOL_WIDGET_GROUP = 'SET_SCHOOL_WIDGET_GROUP';

export interface LoadSchoolWidgetsAction {
  type: typeof LOAD_SCHOOL_WIDGETS;
  payload: { schoolWidgetGroupId: string };
}

export interface SetSchoolWidgetsAction {
  type: typeof SET_SCHOOL_WIDGETS;
  payload: WidgetDTO[];
}

export interface UpdateSchoolWidgetAction {
  type: typeof UPDATE_SCHOOL_WIDGET;
  payload: WidgetDTO;
}

export interface UpdateSchoolWidgetOrderAction {
  type: typeof UPDATE_SCHOOL_WIDGET_ORDER;
  payload: { schoolWidgetGroupId: string; widgets: WidgetOrderDTO[] };
}

export interface CreateSchoolWidgetAction {
  type: typeof CREATE_SCHOOL_WIDGET;
  payload: NewWidgetDTO;
}

export interface DeleteSchoolWidgetAction {
  type: typeof DELETE_SCHOOL_WIDGET;
  payload: number;
}

export interface AddWidgetToListAction {
  type: typeof ADD_WIDGET_TO_LIST;
  payload: WidgetDTO;
}

export interface DeleteWidgetFromListAction {
  type: typeof DELETE_WIDGET_FROM_LIST;
  payload: number;
}

export interface LoadSchoolWidgetGroupAction {
  type: typeof LOAD_SCHOOL_WIDGET_GROUP;
  payload: { id: number };
}

export interface SetSchoolWidgetGroupAction {
  type: typeof SET_SCHOOL_WIDGET_GROUP;
  payload: { data: SchoolWidgetGroupDto };
}

export type SchoolWidgetDetailsActionTypes =
  | LoadSchoolWidgetsAction
  | SetSchoolWidgetsAction
  | UpdateSchoolWidgetAction
  | UpdateSchoolWidgetOrderAction
  | CreateSchoolWidgetAction
  | DeleteSchoolWidgetAction
  | DeleteWidgetFromListAction
  | AddWidgetToListAction
  | LoadSchoolWidgetGroupAction
  | SetSchoolWidgetGroupAction;

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TimelineIndicator from './TimelineIndicator';
import TimelineStepDates from './TimelineStepDates';
import {
  downloadSpreadsheetAction,
  RankingSpreadsheetDTO,
  RankingTimelineStepDTO,
  TimelineSpreadsheetDTO,
  UPLOAD_SPREADSHEET,
  uploadSpreadsheetAction,
} from '../../RankingDetails/TimelineSteps';
import Button, { ButtonIcons } from '../../../../../../components/Button';
import FileUpload from '../../../../../../components/FileUpload';
import Grid from '../../../../../../components/Grid';
import { MODAL_ANIMATION_DURATION } from '../../../../../../components/Modal';
import {
  selectRequestErrorMessage,
  selectRequestIsLoading,
  setRequestSucceededAction,
} from '../../../../../../shared/state/global-request';
import { TimelineExpandStepStatus } from '../RankingTimeline.constants';

const EmbargoTableStep: FunctionComponent<RankingTimelineStepDTO> = ({
  endDate,
  name,
  rankingId,
  startDate,
  status,
  spreadsheet,
}) => {
  const dispatch = useDispatch();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [expand, setExpand] = useState<boolean>(false);

  const errors = useSelector(selectRequestErrorMessage(UPLOAD_SPREADSHEET));
  const isLoading = useSelector(selectRequestIsLoading(UPLOAD_SPREADSHEET));

  const uploadEmbargoTable = (file: File): void => {
    dispatch(setRequestSucceededAction(UPLOAD_SPREADSHEET));

    const formData = new FormData();
    formData.append('file', file);

    const spreadsheetData: RankingSpreadsheetDTO = { rankingId, spreadsheet: formData, type: 'embargo' };
    dispatch(uploadSpreadsheetAction(spreadsheetData));
  };

  const closeUploadModal = (): void => {
    setIsUploadModalOpen(false);

    setTimeout(() => {
      dispatch(setRequestSucceededAction(UPLOAD_SPREADSHEET));
    }, MODAL_ANIMATION_DURATION);
  };

  const handleDownload = (): void => {
    const spreadsheetData: TimelineSpreadsheetDTO = { rankingId, type: 'embargo' };
    dispatch(downloadSpreadsheetAction(spreadsheetData));
  };

  useEffect(() => {
    setExpand(TimelineExpandStepStatus[status]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (isUploadModalOpen) {
      closeUploadModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spreadsheet]);

  return (
    <>
      <Grid container key={name}>
        <Grid item xs={5}>
          <TimelineStepDates
            endDate={endDate}
            endDateLabel="End"
            isEndDateRequired={true}
            startDate={startDate}
            startDateLabel="Start"
            isStartDateRequired={true}
          />
        </Grid>
        <Grid item xs={1} className="mba-no-padding">
          <TimelineIndicator timeIndication={status} />
        </Grid>
        <Grid item xs={6} className="mba-no-padding">
          <button
            className="mba-text--accent mba-no-margin mba-text--semi-bold mba-timeline-button--link"
            onClick={(): void => setExpand(!expand)}
          >
            {name}
          </button>
          {expand && (
            <>
              <br />
              {spreadsheet && (
                <div className="mba-download-spreadsheet">
                  <Button text={spreadsheet.originalName} icon={ButtonIcons.download} onClick={handleDownload} />
                </div>
              )}

              <br />
              <div className="mba-actions">
                <Button text="Upload embargo table" onClick={(): void => setIsUploadModalOpen(true)} />
              </div>
              <br />
            </>
          )}
        </Grid>
      </Grid>
      <FileUpload
        title={`Upload the embargo table`}
        isLoading={isLoading}
        isOpen={isUploadModalOpen}
        onClose={closeUploadModal}
        onUpload={uploadEmbargoTable}
        errorMessage={errors}
      />
    </>
  );
};

export default EmbargoTableStep;

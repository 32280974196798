import { NavigationConfiguration } from '../../App/Main/components/Layout';
import { FormConfig } from '../../../components/Form';

export enum SPREADSHEETS_TYPES {
  'alumni' = 'alumni',
  'faculty' = 'faculty',
  'research' = 'research',
  'embargo' = 'embargo',
  'open-executive-education' = 'open-executive-education',
  'custom-executive-education' = 'custom-executive-education',
}

export const SPREADSHEET_TEMPLATE_MAIN_ROUTE = '/rankings/spreadsheet-template';

export const SPREADSHEET_TEMPLATE_SUB_NAVIGATION: NavigationConfiguration = {
  alumni: {
    label: 'Alumni spreadsheet',
    route: '/alumni',
  },
  faculty: {
    label: 'Faculty spreadsheet',
    route: '/faculty',
  },
  research: {
    label: 'Research papers spreadsheet',
    route: '/research',
  },
  embargo: {
    label: 'Embargo spreadsheet',
    route: '/embargo',
  },
  'custom-executive-education': {
    label: 'Custom Executive Education spreadsheet',
    route: '/custom-executive-education',
  },
  'open-executive-education': {
    label: 'Open Executive Education spreadsheet',
    route: '/open-executive-education',
  },
};

export const FIELD_CREATE_FORM_CONFIG: FormConfig = {
  label: {
    label: 'Column label',
    placeholder: 'Enter field name',
    fieldType: 'text',
    validations: {
      required: {
        errorText: `Please enter a ranking name!`,
      },
    },
  },
  dummyValue: {
    label: 'Default Value',
    placeholder: 'Enter dummy value',
    fieldType: 'textarea',
  },
  isRequired: {
    label: 'Is required',
    fieldType: 'checkbox',
  },
  isEmail: {
    label: 'Is email',
    fieldType: 'checkbox',
  },
  isLanguage: {
    label: 'Is language',
    fieldType: 'checkbox',
  },
};

import React, { FunctionComponent, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import SecondaryNavigation from './SecondaryNavigation';
import { NavigationConfiguration } from '../Layout.constants';
import { ClearCacheButton } from './ClearCacheButton/ClearCacheButton';

type NavigationProps = {
  routes: NavigationConfiguration;
  onLogoutClick?: () => void;
};

const Navigation: FunctionComponent<NavigationProps> = ({ routes, onLogoutClick }) => {
  const location = useLocation();
  const currentPageKey = location.pathname.split('/')[1] || '';
  let subNavigation = null;
  const navElementRef = useRef<HTMLDivElement>(null);

  if (currentPageKey && routes.hasOwnProperty(currentPageKey) && routes[currentPageKey].navigation) {
    subNavigation = <SecondaryNavigation pageName={currentPageKey} {...routes[currentPageKey]} />;
  }

  const closeNavMenu = (): void => {
    if (
      navElementRef &&
      navElementRef.current &&
      navElementRef.current.classList.contains('o-header-services__primary-nav--open')
    ) {
      navElementRef.current.classList.remove('o-header-services__primary-nav--open');
    }
  };

  return (
    <>
      <nav
        ref={navElementRef}
        style={{ zIndex: '999' }}
        className="o-header-services__primary-nav"
        aria-label="primary"
      >
        <ul className="o-header-services__primary-nav-list">
          {Object.entries(routes).map(([key, { label, route }]) => (
            <li key={key}>
              <NavLink exact={route === '/'} to={route} onClick={closeNavMenu}>
                {label}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
      {subNavigation}
      <div>
        {onLogoutClick && (
          <ul className="o-header-services__related-content">
            <li className="mba-center--vertically">
              <ClearCacheButton handleNavElementClick={closeNavMenu} />
            </li>
            <li>
              <a href="/logout" onClick={onLogoutClick}>
                Sign out
              </a>
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

export default Navigation;

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SPREADSHEETS_TABLE_FILTERS } from './SpreadsheetsSearchAndFilters.constants';
import FilterGroup, { mapInitialValuesToFiltersConfig } from '../../../../../../../components/FilterGroup';
import SearchInput from '../../../../../../../components/SearchInput';
import { PageFilterConfig, PageSearchQueryConfig, Status } from '../../../../../../../shared/constants';
import { loadRankingSpreadsheetsAction } from '../../spreadsheets.actions';

type SpreadsheetsSearchAndFilters = {
  rankingId: number;
  spreadsheetType: string;
  pageConfig: PageSearchQueryConfig;
  filtersData: { spreadsheetStatuses: Status[] };
};

const SpreadsheetsSearchAndFilters: FunctionComponent<SpreadsheetsSearchAndFilters> = ({
  rankingId,
  spreadsheetType,
  pageConfig,
  filtersData,
}) => {
  const dispatch = useDispatch();

  const [spreadsheetsFilters, setSpreadsheetsFilters] = useState({});
  const { filter, search } = pageConfig;

  useEffect(() => {
    const { spreadsheetStatuses } = filtersData;

    setSpreadsheetsFilters(
      mapInitialValuesToFiltersConfig(
        SPREADSHEETS_TABLE_FILTERS,
        {
          spreadsheetStatus: spreadsheetStatuses,
        },
        filter,
        true,
      ),
    );
  }, [filter, filtersData]);

  const handleSearchChange = (newPageConfig: PageSearchQueryConfig): void => {
    dispatch(loadRankingSpreadsheetsAction(rankingId, spreadsheetType, newPageConfig));
  };

  const handleFiltersChange = (newFilters: PageFilterConfig): void => {
    dispatch(
      loadRankingSpreadsheetsAction(rankingId, spreadsheetType, {
        ...pageConfig,
        filter: newFilters,
      }),
    );
  };

  return (
    <div className="sticky-sidebar-container">
      <SearchInput
        id="ranking-schools-search"
        pageConfig={pageConfig}
        placeholder="Search by school name or SID"
        onSearchChanged={handleSearchChange}
        searchTerm={search}
      />
      <FilterGroup
        defaultFilters={filter || {}}
        filtersConfig={spreadsheetsFilters}
        onFiltersChanged={handleFiltersChange}
      />
    </div>
  );
};

export default SpreadsheetsSearchAndFilters;

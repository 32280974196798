import { PageSearchQueryConfig, TableListData } from '../../../shared/constants';
import {
  CreateSchoolWidgetGroupsDto,
  UpdateWidgetGroupDto,
} from './components/CreateWidgetGroupModal/CreateWidgetGroupModal.constants';
import { SchoolWidgetGroupDto } from './school-widget-groups.dto';
import {
  ADD_SCHOOL_WIDGET_GROUP_TO_LIST,
  AddWidgetGroupToListAction,
  CREATE_SCHOOL_WIDGET_GROUP,
  CreateSchoolWidgetGroupAction,
  DELETE_SCHOOL_WIDGET_GROUP,
  DeleteSchoolWidgetGroupAction,
  LOAD_SCHOOL_WIDGET_GROUPS,
  LoadSchoolWidgetGroupsAction,
  SET_SCHOOL_WIDGET_GROUPS,
  SetSchoolWidgetGroupsAction,
  UPDATE_SCHOOL_WIDGET_GROUP,
  UpdateSchoolWidgetGroupAction,
} from './school-widget-groups.types';

export const addWidgetGroupToListAction = (widgetGroup: SchoolWidgetGroupDto): AddWidgetGroupToListAction => ({
  type: ADD_SCHOOL_WIDGET_GROUP_TO_LIST,
  payload: { newWidgetGroup: widgetGroup },
});

export const createSchoolWidgetGroupsAction = (
  savingData: CreateSchoolWidgetGroupsDto,
): CreateSchoolWidgetGroupAction => ({
  type: CREATE_SCHOOL_WIDGET_GROUP,
  payload: { savingData },
});

export const loadSchoolWidgetGroupsAction = (pageConfig?: PageSearchQueryConfig): LoadSchoolWidgetGroupsAction => ({
  type: LOAD_SCHOOL_WIDGET_GROUPS,
  payload: { pageConfig },
});

export const setSchoolWidgetGroupsAction = (
  tableData: TableListData<SchoolWidgetGroupDto>,
): SetSchoolWidgetGroupsAction => ({
  type: SET_SCHOOL_WIDGET_GROUPS,
  payload: { tableData },
});

export const updateSchoolWidgetGroupAction = (
  id: number,
  updateWidgetGroupDto: UpdateWidgetGroupDto,
): UpdateSchoolWidgetGroupAction => ({
  type: UPDATE_SCHOOL_WIDGET_GROUP,
  payload: { id, updateWidgetGroupDto },
});

export const deleteSchoolWidgetGroupAction = (id: number): DeleteSchoolWidgetGroupAction => ({
  type: DELETE_SCHOOL_WIDGET_GROUP,
  payload: { id },
});

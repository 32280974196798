import { call, CallEffect, select, put, PutEffect, SelectEffect, takeLatest } from 'redux-saga/effects';

import {
  BasicResponseDTO,
  DefaultResponseDTO,
  PagedResponseDTO,
  PageSearchQueryConfig,
} from '../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../shared/state/global-request';
import { setLogsAction } from './logs.actions';
import { getLogsRequest } from './logs.api';
import { LogsDTO } from './logs.dto';
import { LoadLogsAction, LoadLogsActionTypes, LOAD_LOGS } from './logs.types';

function* loadLogsTableData(
  action: LoadLogsAction,
): Generator<
  CallEffect<PagedResponseDTO<LogsDTO[]>> | PutEffect<LoadLogsActionTypes> | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig } = action.payload;
    const { data, pagination, sortOrder } = (yield call(getLogsRequest, pageConfig)) as PagedResponseDTO<LogsDTO[]>;
    yield put(setLogsAction({ data, pagination, sortOrder }));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* logsSaga(): Generator {
  yield takeLatest(LOAD_LOGS, loadLogsTableData);
}

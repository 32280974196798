import { call, CallEffect, put, PutEffect, SelectEffect, takeLatest } from 'redux-saga/effects';

import { loadRankingSchoolsAction, setRankingSchoolsAction } from './ranking-schools.actions';
import {
  addSchoolsToRankingRequest,
  downloadParticipationSpreadsheetRequest,
  getRankingSchoolsRequest,
  importSchoolsToRankingRequest,
  updateParticipationStatusRequest,
} from './ranking-schools.api';
import { RankingSchoolDTO } from './ranking-schools.dto';
import {
  ADD_SCHOOLS_TO_RANKING,
  AddSchoolsToRankingAction,
  DOWNLOAD_PARTICIPATION_SPREADSHEET,
  DownloadParticipationSpreadsheetAction,
  IMPORT_SCHOOLS_TO_RANKING,
  ImportSchoolsToRankingAction,
  LOAD_RANKING_SCHOOLS,
  LoadRankingSchoolsAction,
  RankingSchoolsActionTypes,
  UPDATE_PARTICIPATION_STATUS,
  UpdateParticipationStatusAction,
} from './ranking-schools.types';
import { BasicResponseDTO, PagedResponseDTO } from '../../../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../../../shared/state/global-request';
import { loadResourcesAction, ResourcesActionTypes } from '../../../../../shared/state/resources';

function* loadRankingSchoolsTableData(
  action: LoadRankingSchoolsAction,
): Generator<
  | CallEffect<PagedResponseDTO<RankingSchoolDTO[]>>
  | PutEffect<RankingSchoolsActionTypes>
  | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig, rankingId } = action.payload;
    const { data, pagination, sortOrder } = (yield call(
      getRankingSchoolsRequest,
      rankingId,
      pageConfig,
    )) as PagedResponseDTO<RankingSchoolDTO[]>;
    yield put(setRankingSchoolsAction({ data, pagination, sortOrder }));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* downloadParticipationSpreadsheet(
  action: DownloadParticipationSpreadsheetAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<RankingSchoolsActionTypes> | PutEffect<GlobalRequestActions>> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig, rankingId } = action.payload;
    yield call(downloadParticipationSpreadsheetRequest, rankingId, pageConfig);
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* addSchoolsToRankingSaga(
  action: AddSchoolsToRankingAction,
): Generator<
  PutEffect<GlobalRequestActions | RankingSchoolsActionTypes | ResourcesActionTypes> | CallEffect<BasicResponseDTO>
> {
  const {
    type: actionType,
    payload: { data, rankingId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(addSchoolsToRankingRequest, data, rankingId);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadRankingSchoolsAction(rankingId));
    yield put(loadResourcesAction(`rankings/${rankingId}/available-schools`));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* importSchoolsToRankingSaga(
  action: ImportSchoolsToRankingAction,
): Generator<
  PutEffect<GlobalRequestActions | RankingSchoolsActionTypes | ResourcesActionTypes> | CallEffect<BasicResponseDTO>
> {
  const {
    type: actionType,
    payload: { rankingId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(importSchoolsToRankingRequest, rankingId);
    yield put(loadRankingSchoolsAction(rankingId));
    yield put(loadResourcesAction(`rankings/${rankingId}/available-schools`));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* updateParticipationStatusSaga(
  action: UpdateParticipationStatusAction,
): Generator<
  PutEffect<GlobalRequestActions | RankingSchoolsActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect
> {
  const {
    type: actionType,
    payload: { rankingId, schoolId, data },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateParticipationStatusRequest, rankingId, schoolId, data);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadRankingSchoolsAction(rankingId));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* rankingSchoolsSaga(): Generator {
  yield takeLatest(LOAD_RANKING_SCHOOLS, loadRankingSchoolsTableData);
  yield takeLatest(DOWNLOAD_PARTICIPATION_SPREADSHEET, downloadParticipationSpreadsheet);
  yield takeLatest(ADD_SCHOOLS_TO_RANKING, addSchoolsToRankingSaga);
  yield takeLatest(IMPORT_SCHOOLS_TO_RANKING, importSchoolsToRankingSaga);
  yield takeLatest(UPDATE_PARTICIPATION_STATUS, updateParticipationStatusSaga);
}

import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { BasicResponseDTO, OrderDirection } from '../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../shared/state/global-request';
import { setRegistrationsAction } from './registrations.actions';
import { downloadRegistrationsRequest, getRegistrationsRequest } from './registrations.api';
import { RegistrationResponseDTO } from './registrations.dto';
import {
  DownloadRegistrationsAction,
  DOWNLOAD_REGISTRATIONS,
  LoadRegistrationsAction,
  LOAD_REGISTRATIONS,
  RegistrationsTypes,
} from './registrations.types';

function* loadRegistrationsData(
  action: LoadRegistrationsAction,
): Generator<CallEffect<RegistrationResponseDTO> | PutEffect<RegistrationsTypes> | PutEffect<GlobalRequestActions>> {
  const { type: actionType } = action;
  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig } = action.payload;
    const response = (yield call(getRegistrationsRequest, pageConfig)) as RegistrationResponseDTO;

    const registrationsDataKeys = Object.keys(response.data);
    const registrationsData: any = [];
    registrationsDataKeys.forEach((key) =>
      registrationsData.push({
        completedRegistrations: response.data[key]['ft'],
        newRegistrations: response.data[key]['mba-rankings'],
        totalRegistrations: response.data[key]['total'],
        id: key,
      }),
    );

    const pagination = { ...response.pagination };

    const sortOrder = {
      order: 'ASC' as OrderDirection,
      sortBy: 'date',
    };

    yield put(setRegistrationsAction({ data: registrationsData, pagination, sortOrder }));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    console.log(error);
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* downloadRegistrations(
  action: DownloadRegistrationsAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<RegistrationsTypes> | PutEffect<GlobalRequestActions>> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(downloadRegistrationsRequest);
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* registrationsSaga(): Generator {
  yield takeLatest(LOAD_REGISTRATIONS, loadRegistrationsData);
  yield takeLatest(DOWNLOAD_REGISTRATIONS, downloadRegistrations);
}

import { ChangeEventHandler, FocusEventHandler } from 'react';

export type DatePickerProps = {
  autofocus?: boolean;
  disabled?: boolean;
  error?: string | boolean;
  fullWidth?: boolean;
  id?: string;
  name?: string;
  onBlur?: FocusEventHandler<HTMLElement>;
  onFocus?: FocusEventHandler<HTMLElement>;
  onChange?: ChangeEventHandler<HTMLElement>;
  placeholder?: string;
  required?: boolean;
  small?: boolean;
  title?: string;
  touched?: boolean;
  type?: 'month' | 'date' | 'dateTime';
  valid?: boolean;
  value?: string;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  startDate?: Date | null;
  timeInputLabel?: string;
  timeIntervals?: number;
  customDateFormat?: string;
};

export const DEFAULT_DATE_FORMAT = {
  date: 'dd/MM/yyyy',
  month: 'MMMM yyyy',
  dateTime: 'dd/MM/yyyy HH:mm',
};

import React, { FunctionComponent } from 'react';

import Button, { ButtonSizes } from '../../../../../../../components/Button';
import Typography from '../../../../../../../components/Typography';

type RankingDeleteConfirmationProps = {
  error: string;
  name: string;
  onCancel: () => void;
  onConfirm: () => void;
};

const RankingDeleteConfirmation: FunctionComponent<RankingDeleteConfirmationProps> = ({
  error,
  name,
  onCancel,
  onConfirm,
}) => (
  <>
    <Typography>
      {error ? (
        <span className="mba-text--error">
          {error}
          <br />
        </span>
      ) : (
        <>
          Are you sure you want to delete:
          <br />
          <strong>&quot;{name}&quot;</strong>
          <br />
          <br />
          All data, collected from schools, participating in this ranking, will be deleted as well!
        </>
      )}
    </Typography>
    <div className="mba-actions">
      <Button onClick={onCancel} danger text="Cancel" size={ButtonSizes.big} />
      {!error && <Button onClick={onConfirm} primary danger text="Confirm" size={ButtonSizes.big} />}
    </div>
  </>
);

export default RankingDeleteConfirmation;

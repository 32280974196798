import React, { FunctionComponent } from 'react';

import { FormControlElementProps, FormValue } from '../Form.constants';
import Select, { SelectOption, SelectOptionType } from '../../Select';
import Icon from '../../Icon';

type SelectFieldProps = {
  control: FormControlElementProps;
  inputChangedHandler: (name: string, value: FormValue) => void;
  onClickHandler?: () => void;
};

const SelectField: FunctionComponent<SelectFieldProps> = ({ control, inputChangedHandler, onClickHandler }) => {
  const {
    error,
    fieldType,
    id,
    label,
    multiple,
    options = [],
    required,
    searchable,
    touched,
    valid,
    validations,
    value,
    hideRemoveButton,
    ...elementProps
  } = control;

  const selectedOption = multiple
    ? (options as SelectOption[]).filter(
        (option) => Array.isArray(value) && (value as (number | string | boolean)[]).includes(option.value),
      )
    : (options as any[]).filter((option) => option.value === value);

  const handleOnChange = (name: string, selectedValue: SelectOptionType | SelectOptionType[]): void => {
    let value: FormValue = multiple ? [] : '';

    if (selectedValue) {
      if (multiple && Array.isArray(selectedValue)) {
        value = (selectedValue as SelectOptionType[]).map((option: SelectOptionType) => option.value);
      } else {
        value = (selectedValue as SelectOptionType).value;
      }
    }

    inputChangedHandler(name, value);
  };

  return (
    <div key={`${fieldType}-${id}`} className="form-select-wrapper">
      <Select
        id={id}
        isSearchable={searchable}
        isMulti={multiple}
        required={required || !!validations?.required}
        title={label}
        error={touched && !valid ? error : ''}
        value={selectedOption}
        options={options as SelectOptionType[]}
        onValueChanged={handleOnChange}
        {...elementProps}
      />
      {hideRemoveButton === false && (
        <button type="button" className="form-group-remove-btn" onClick={onClickHandler}>
          <Icon name="cross" size={24} />
        </button>
      )}
    </div>
  );
};

export default SelectField;

import {
  CreateSpreadsheetTemplateFieldDTO,
  EditSpreadsheetTemplateFieldDTO,
  SpreadsheetTemplateFieldDTO,
} from './spreadsheet-template.dto';
import { get, del, put, post } from '../../../shared/api';
import { BasicResponseDTO, PagedResponseDTO, PageSearchQueryConfig } from '../../../shared/constants';
import { generateSearchQuery } from '../../../shared/helpers';

export const getSpreadsheetTemplateFieldsRequest = (
  type: string,
  pageConfig: PageSearchQueryConfig = {},
): Promise<PagedResponseDTO<SpreadsheetTemplateFieldDTO[]>> => {
  let query = generateSearchQuery(pageConfig);
  query = query ? `${query}&templateId=${type}` : `?templateId=${type}`;
  return get<PagedResponseDTO<SpreadsheetTemplateFieldDTO[]>>(`/spreadsheet-template-field${query}`);
};

export const createSpreadsheetTemplateFieldRequest = (
  type: string,
  columnData: CreateSpreadsheetTemplateFieldDTO,
): Promise<BasicResponseDTO> => {
  return post<BasicResponseDTO, CreateSpreadsheetTemplateFieldDTO>(
    `/spreadsheet-template-field/template/${type}`,
    columnData,
  );
};

export const updateSpreadsheetTemplateFieldRequest = (
  id: number,
  columnData: EditSpreadsheetTemplateFieldDTO,
): Promise<BasicResponseDTO> => {
  return put<BasicResponseDTO, EditSpreadsheetTemplateFieldDTO>(`/spreadsheet-template-field/${id}`, columnData);
};

export const deleteSpreadsheetTemplateFieldRequest = (id: number): Promise<BasicResponseDTO> => {
  return del<BasicResponseDTO>(`/spreadsheet-template-field/${id}`);
};

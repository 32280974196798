export const DEFAULT_GRID_COLSPAN_SIZE = 12;

export const MAX_GRID_COLSPAN_SIZE_FOR_SINGLE_ROW = 6;

export enum GRID_COLSPAN_SIZES {
  'SIZE_1' = 1,
  'SIZE_2' = 2,
  'SIZE_3' = 3,
  'SIZE_4' = 4,
  'SIZE_5' = 5,
  'SIZE_6' = 6,
  'SIZE_7' = 7,
  'SIZE_8' = 8,
  'SIZE_9' = 9,
  'SIZE_10' = 10,
  'SIZE_11' = 11,
  'SIZE_12' = 12,
}

export type GridColSpanSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export type GridOffsetPull =
  | 'pull1'
  | 'pull2'
  | 'pull3'
  | 'pull4'
  | 'pull5'
  | 'pull6'
  | 'pull7'
  | 'pull8'
  | 'pull9'
  | 'pull10'
  | 'pull11';
export type GridOffsetPush =
  | 'push1'
  | 'push2'
  | 'push3'
  | 'push4'
  | 'push5'
  | 'push6'
  | 'push7'
  | 'push8'
  | 'push9'
  | 'push10'
  | 'push11';

import { LeadDTO } from './lead.dto';

import { PageSearchQueryConfig, TableListData } from '../../constants';
import { LOAD_SCHOOL_LEADS, SET_SCHOOL_LEADS, LoadSchoolLeadsAction, SetSchoolLeadsAction } from './leads.types';

export const loadSchoolLeadsAction = (schoolId: number, pageConfig?: PageSearchQueryConfig): LoadSchoolLeadsAction => ({
  type: LOAD_SCHOOL_LEADS,
  payload: { schoolId, pageConfig },
});

export const setSchoolLeadsAction = (data: TableListData<LeadDTO>): SetSchoolLeadsAction => ({
  type: SET_SCHOOL_LEADS,
  payload: { data },
});

import { ReactNode } from 'react';

import {
  ActionButtonHandler,
  FormConfig,
  FormConfigProps,
  FormControlElementProps,
  FormControlFieldType,
  FormControlProps,
  FormControlValidations,
  FormDataValues,
  FormGroup,
  FormValue,
  GroupFieldKeyParts,
} from './Form.constants';
import {
  renderCheckboxField,
  renderDatePickerInputField,
  renderFormGroup,
  renderFormGroupTitle,
  renderRadioButtons,
  renderSelectField,
  renderTextAreaField,
  renderTextInputField,
  renderRichTextField,
  renderPhoneEmailInputField,
} from './FormFields';
import { FORM_GROUP_TITLE } from '../../containers/Schools/SchoolsList/components/SchoolsList.constants';
import moment from 'moment';

export const getErrorMessage = (
  value?: FormValue,
  isRequired?: boolean,
  validations?: FormControlValidations,
  fieldType?: FormControlFieldType,
): string => {
  return validations || isRequired ? getFieldError(value, isRequired, validations, fieldType) : '';
};

export const initializeFormFields = (
  controls: FormConfigProps,
  config: FormConfig,
  initialValues: FormDataValues = {},
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any,
): FormConfigProps => {
  const controlsData: FormConfigProps = {};

  // generate form config for group fields and match initial values
  const { groupConfig, groupInitialValues } = mapInitialValuesForGroupFields(config, initialValues);
  config = { ...config, ...groupConfig };
  initialValues = { ...initialValues, ...groupInitialValues };

  Object.entries(config).forEach(([controlKey, controlConfig]) => {
    let errorMessage = '';
    let value: FormValue = controlConfig.value || '';

    if (initialValues.hasOwnProperty(controlKey)) {
      value = initialValues[controlKey];
      errorMessage =
        controlConfig.validations || controlConfig.required
          ? getErrorMessage(value, controlConfig.required, controlConfig.validations, controlConfig.fieldType)
          : '';
    }

    // handle field group errors
    const isGroupField = controlKey.endsWith(']');
    const shouldValidateField = isGroupField ? !controlKey.endsWith(`[${FORM_GROUP_TITLE}]`) : true;

    if (isGroupField) {
      const { baseGroupName, index, baseFieldName } = getGroupFieldKeyParts(controlKey);

      const groupErrors = errors[baseGroupName];
      const isGroupError = groupErrors && typeof groupErrors[0] === 'string';
      if (controlKey.endsWith(`[${FORM_GROUP_TITLE}]`) && isGroupError) {
        // show general group errors
        errorMessage = errors[baseGroupName][0];
      } else if (errors[baseGroupName] && errors[baseGroupName][index] && errors[baseGroupName][index][baseFieldName]) {
        // show specific field group error
        errorMessage = errors[baseGroupName][index][baseFieldName][0];
      }
    }

    if (errors.hasOwnProperty(controlKey) && errors[controlKey].length > 0) {
      errorMessage = errors[controlKey][0];
    }
    controlsData[controlKey] = {
      ...controlConfig,
      error: errorMessage,
      touched: !!controls[controlKey]?.touched,
      valid: shouldValidateField ? !errorMessage : true,
      value,
    };
  });

  return controlsData;
};

export const generateFormFields = (
  config: FormConfigProps,
  inputChangedHandler: (name: string, value: FormValue) => void,
  groupName: string | null = null,
  index: number | null = null,
  actionButtonHandlers?: { onClickRemove: ActionButtonHandler; onClickAdd: ActionButtonHandler },
): ReactNode => {
  if (!config) {
    return null;
  }

  const formElementsArray: FormControlElementProps[] = [];
  Object.entries(config).forEach(([controlKey, controlConfig]) => {
    if (groupName) {
      controlKey = `${groupName}[${index}][${controlKey}]`;
    }

    formElementsArray.push({
      ...(controlConfig as FormControlElementProps),
      id: (controlConfig as FormControlElementProps).id || controlKey,
      name: controlKey,
    });
  });

  return formElementsArray.map((control) => {
    const onClickHandler = (): void => {
      if (actionButtonHandlers) {
        actionButtonHandlers.onClickRemove(groupName || '', index || 0);
      }
    };

    switch (control.fieldType) {
      case 'select':
        return renderSelectField({ control, inputChangedHandler, onClickHandler });
      case 'text':
        return renderTextInputField({ control, inputChangedHandler });
      case 'textarea':
        return renderTextAreaField({ control, inputChangedHandler });
      case 'formGroupTitle':
        return renderFormGroupTitle({ control });
      case 'radio':
        return renderRadioButtons({ control, inputChangedHandler });
      case 'checkbox':
        return renderCheckboxField({ control, inputChangedHandler });
      case 'date':
        return renderDatePickerInputField({ control, inputChangedHandler });
      case 'month':
        return renderDatePickerInputField({ control, inputChangedHandler, type: 'month' });
      case 'richText':
        return renderRichTextField({ control, inputChangedHandler });
      case 'phone':
        return renderPhoneEmailInputField({ control, inputChangedHandler });
      case 'email':
        return renderPhoneEmailInputField({ control, inputChangedHandler });
      case 'number':
        return renderTextInputField({ control, inputChangedHandler });
      default:
        return null;
    }
  });
};

export const generateFormGroupFields = (
  formGroupFields: FormConfigProps,
  inputChangedHandler: (name: string, value: FormValue) => void,
  actionButtonHandlers: {
    onClickRemove: ActionButtonHandler;
    onClickAdd: ActionButtonHandler;
  },
  shouldDisplayAddGroupButton?: boolean,
  addGroupButtonText?: string,
): ReactNode =>
  Object.keys(formGroupFields).map((groupName) =>
    renderFormGroup({
      controls: formGroupFields[groupName] as FormConfigProps,
      inputChangedHandler,
      groupName,
      actionButtonHandlers,
      shouldDisplayAddGroupButton,
      addGroupButtonText,
    }),
  );

export const getSimpleControls = (controls: FormConfigProps): FormConfigProps =>
  Object.keys(controls)
    .filter((fieldKey) => !fieldKey.endsWith(']'))
    .reduce((obj: FormConfigProps, key) => {
      obj[key] = controls[key];
      return obj;
    }, {});

export const getGroupFieldKeyParts = (fieldKey: string): GroupFieldKeyParts => {
  const baseGroupName = fieldKey.split('[')[0];
  //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const index: number = parseInt(fieldKey.match(/\[(.*?)\]/g)![0]?.replace(/\[|\]/g, ''));
  //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const baseFieldName: string = fieldKey.match(/\[(.*?)\]/g)![1]?.replace(/\[|\]/g, '');

  return {
    baseGroupName,
    index,
    baseFieldName,
  };
};

/* Transform group controls from flat to nested format */
export const getGroupControls = (controls: FormConfigProps, withFormGroupTitle = true): FormConfigProps => {
  return (
    Object.keys(controls)
      .filter((fieldKey) =>
        withFormGroupTitle
          ? fieldKey.endsWith(']')
          : fieldKey.endsWith(']') && !fieldKey.endsWith(`[${FORM_GROUP_TITLE}]`),
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .reduce((obj: { [key: string]: any }, key) => {
        const { baseGroupName, index, baseFieldName } = getGroupFieldKeyParts(key);

        if (!obj[baseGroupName]) {
          obj[baseGroupName] = {};
        }
        if (!obj[baseGroupName][index]) {
          obj[baseGroupName][index] = {};
        }
        obj[baseGroupName][index][baseFieldName] = controls[key];
        return obj;
      }, {})
  );
};

export const getGroupValues = (groupControls: FormConfigProps): FormGroup => {
  const groups: FormGroup = {};
  Object.keys(groupControls).forEach((groupName) => {
    groups[groupName] = Object.values(groupControls[groupName]).map((controls: FormConfigProps) => {
      const valueControls: FormDataValues = {};
      Object.keys(controls).forEach((fieldKey) => {
        if (controls.hasOwnProperty(fieldKey)) {
          valueControls[fieldKey] = (controls[fieldKey] as FormControlProps).value || '';
        }
      });
      return valueControls;
    });
  });
  return groups;
};

export const mapInitialValuesForGroupFields = (
  config: FormConfig,
  initialValues: FormDataValues,
): { groupConfig: FormConfig; groupInitialValues: FormDataValues } => {
  const groupConfig: FormConfig = {};
  const groupInitialValues: FormDataValues = {};

  Object.keys(initialValues).forEach((fieldKey: string) => {
    if (Array.isArray(initialValues[fieldKey])) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (initialValues[fieldKey] as any[]).forEach((row: FormDataValues, index: number) => {
        Object.keys(row).forEach((baseFieldName: string) => {
          const controlKey = `${fieldKey}[${index}][${baseFieldName}]`;
          const baseGroupKey = `${fieldKey}[0][${baseFieldName}]`;
          if (!config[baseGroupKey]) {
            return;
          }
          groupConfig[controlKey] = config[baseGroupKey];
          groupInitialValues[controlKey] = row[baseFieldName];
        });
      });
    }
  });

  return {
    groupConfig,
    groupInitialValues,
  };
};

export const getFormValues = (controls: FormConfigProps): FormDataValues => {
  const formData: FormDataValues = {};

  Object.entries(controls as FormConfigProps).forEach(([fieldKey, props]) => {
    const isGroup = fieldKey.endsWith(']');
    if (isGroup) {
      return;
    }

    formData[fieldKey] = (props as FormControlProps).value ?? '';
  });

  const groupedControls = getGroupControls(controls, false);
  const groupedValues = getGroupValues(groupedControls);
  Object.keys(groupedValues).forEach((groupName) => {
    formData[groupName] = groupedValues[groupName];
  });

  return formData;
};

const checkIsRequiredFieldFilled = (fieldValue: any): boolean => {
  if (typeof fieldValue === 'string') {
    return !!fieldValue;
  }
  if (Array.isArray(fieldValue) && fieldValue.length === 0) {
    return false;
  }
  return fieldValue !== null && fieldValue !== undefined;
};

const checkIfFieldMatchesRegex = (fieldType = '', fieldValue: string | number, pattern: RegExp): boolean => {
  if (fieldType === 'date' && typeof fieldValue === 'number') {
    const formattedDate = moment(fieldValue).format('DD/MM/YYYY');
    return pattern.test(formattedDate);
  }
  return pattern.test(fieldValue.toString());
};

const checkIsNumberFieldValid = (fieldValue: number, minValue?: number, maxValue?: number): boolean => {
  if (minValue && maxValue) {
    return fieldValue > minValue && fieldValue < maxValue;
  }
  if (minValue) {
    return fieldValue > minValue;
  }
  if (maxValue) {
    return fieldValue < maxValue;
  }
  return true;
};

const getFieldError = (
  fieldValue: any,
  isRequired?: boolean,
  validations?: FormControlValidations,
  fieldType?: FormControlFieldType,
): string => {
  let errorMessage = '';

  if ((isRequired || validations?.required) && !checkIsRequiredFieldFilled(fieldValue)) {
    errorMessage = validations?.required?.errorText || 'This field is required!';
  }
  if (validations?.regex && fieldValue && !checkIfFieldMatchesRegex(fieldType, fieldValue, validations.regex.rule)) {
    errorMessage = validations.regex.errorText;
  }
  if (
    fieldType === 'number' &&
    validations?.minMaxValue &&
    fieldValue &&
    !checkIsNumberFieldValid(fieldValue as number, validations.minMaxValue.minValue, validations.minMaxValue.maxValue)
  ) {
    errorMessage = validations.minMaxValue.errorText;
  }

  return errorMessage;
};

import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ACCREDITATION_FORM_CONFIG } from '../AccreditationsList.constants';
import {
  CREATE_ACCREDITATION,
  CreateAccreditationDTO,
  createNewAccreditationAction,
  DELETE_ACCREDITATION,
  EDIT_ACCREDITATION,
  editAccreditationAction,
  EditAccreditationDTO,
  selectCurrentAccreditation,
} from '../../../AccreditationProfile';
import Form from '../../../../../../components/Form';
import { selectAreRequestsLoading, selectRequestFormErrors } from '../../../../../../shared/state/global-request';

type AccreditationFormProps = {
  isEdit?: boolean;
  onFormClose: () => void;
};

const AccreditationForm: FunctionComponent<AccreditationFormProps> = ({ onFormClose, isEdit = false }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    selectAreRequestsLoading([CREATE_ACCREDITATION, DELETE_ACCREDITATION, EDIT_ACCREDITATION]),
  );
  const currentAccreditation = useSelector(selectCurrentAccreditation);
  const formErrors = useSelector(selectRequestFormErrors(isEdit ? EDIT_ACCREDITATION : CREATE_ACCREDITATION));

  const onAccreditationSaveSubmit = (data: CreateAccreditationDTO | EditAccreditationDTO): void => {
    if (isEdit) {
      const { id } = currentAccreditation as EditAccreditationDTO;
      dispatch(editAccreditationAction({ ...data, id }));
    } else {
      dispatch(createNewAccreditationAction({ ...data } as CreateAccreditationDTO));
    }
  };

  return (
    <Form
      id="accreditation-form"
      isLoading={isLoading}
      config={ACCREDITATION_FORM_CONFIG}
      onSubmit={onAccreditationSaveSubmit}
      submitButtonText={isEdit ? 'Save' : 'Create accreditation'}
      actions={[
        {
          label: 'Cancel',
          onClick: onFormClose,
        },
      ]}
      initialValues={currentAccreditation}
      errors={formErrors}
    />
  );
};

export default AccreditationForm;

import { NavigationConfiguration } from '../Main/components/Layout';

export const LOG_OUT_USER = 'LOG_OUT_USER';
export const REDIRECT_UNAUTHORIZED_USER = 'REDIRECT_UNAUTHORIZED_USER';
export const REDIRECT_USER_TO_SSO = 'REDIRECT_USER_TO_SSO';
export const SET_USER_NAVIGATION = 'SET_USER_NAVIGATION';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const VALIDATE_USER_TOKEN = 'VALIDATE_USER_TOKEN';

export interface AuthState {
  isTokenVerified: boolean;
  navigation: NavigationConfiguration;
  token: string;
}

export interface LogOutUserAction {
  type: typeof LOG_OUT_USER;
}

export interface RedirectUserToSSOAction {
  type: typeof REDIRECT_USER_TO_SSO;
}

export interface SetUserNavigationAction {
  type: typeof SET_USER_NAVIGATION;
  payload: { navigation: NavigationConfiguration };
}

export interface SetUserTokenAction {
  type: typeof SET_USER_TOKEN;
  payload: { token: string };
}

export interface ValidateUserTokenAction {
  type: typeof VALIDATE_USER_TOKEN;
  payload: { token: string };
}

export type AuthActionTypes =
  | LogOutUserAction
  | RedirectUserToSSOAction
  | SetUserNavigationAction
  | SetUserTokenAction
  | ValidateUserTokenAction;

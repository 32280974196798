import React, { FunctionComponent, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import Form, { FormDataValues } from '../../../../../../../../components/Form';
import { selectAreRequestsLoading, selectRequestFormErrors } from '../../../../../../../../shared/state/global-request';
import {
  RankingFieldProfileRouteParams,
  createRankingFieldAction,
  editRankingFieldAction,
} from '../../../RankingFieldProfile/RankingFieldDetails';
import { RankingFieldDTO } from '../../ranking-fields-list.dto';
import { RankingFieldType } from '../../ranking-fields-list.types';
import { CREATE_RANKING_FIELD, EDIT_RANKING_FIELD } from '../RankingFieldsList';
import { FIELD_FORM_CONFIG } from './RankingFieldForm.constants';

type Props = {
  rankingField?: RankingFieldDTO;
  onFormClose: () => void;
};

const RankingFieldForm: FunctionComponent<Props> = ({ onFormClose, rankingField = {} }) => {
  const dispatch = useDispatch();
  const { rankingId } = useParams<RankingFieldProfileRouteParams>();
  const isLoading = useSelector(selectAreRequestsLoading([CREATE_RANKING_FIELD, EDIT_RANKING_FIELD]));
  const isEdit = !!Object.keys(rankingField).length;
  const formErrors = useSelector(selectRequestFormErrors(isEdit ? EDIT_RANKING_FIELD : CREATE_RANKING_FIELD));
  const [beforeSubmitErrors, setBeforeSubmitErrors] = useState({});
  const [values, setValues] = useState(() => ({ type: 'Column', ...rankingField }));
  const [initialValues, setInitialValues] = useState(() => ({ type: 'Column', ...rankingField }));

  const handleSubmit = (values: FormDataValues): void => {
    if (!config.group.options?.some(({ value }) => value === values.group)) {
      setBeforeSubmitErrors({ group: ['Please select group'] });
    } else if (rankingField.id !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch(editRankingFieldAction(parseInt(rankingId), { ...values, id: rankingField.id } as any));
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch(createRankingFieldAction(parseInt(rankingId), values as any));
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const config = useMemo(() => FIELD_FORM_CONFIG(values.type as RankingFieldType), [values.type]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const errors = useMemo(
    () => ({ ...beforeSubmitErrors, ...(!isLoading ? formErrors : {}) }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [beforeSubmitErrors, isLoading],
  );

  const handleOnValuesChanged = (newValues: FormDataValues): void => {
    if (values.type === newValues.type) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setValues(newValues as any);
    } else {
      const newValuesNoGroup = {
        ...Object.entries(newValues).reduce((acc, [k, v]) => ({ ...acc, [k]: v || undefined }), {}),
        group: undefined,
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setValues(newValuesNoGroup as any);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setInitialValues(newValuesNoGroup as any);
    }
  };

  return (
    <Form
      key={values.type as string}
      id="column-filter-form"
      isLoading={isLoading}
      config={config}
      onSubmit={handleSubmit}
      submitButtonText={isEdit ? 'Save' : 'Create'}
      actions={[
        {
          label: 'Cancel',
          onClick: onFormClose,
        },
      ]}
      initialValues={initialValues}
      errors={errors}
      onValuesChanged={handleOnValuesChanged}
    />
  );
};

export default RankingFieldForm;

import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadFaqAction } from '..';
import Button, { ButtonSizes } from '../../../../../components/Button';
import Modal from '../../../../../components/Modal';
import Typography, { TypographyVariants } from '../../../../../components/Typography';
import { selectFaq } from '../frequently-asked-questions.selectors';
import AddFAQForm from './AddFAQForm';
import FAQ from './FAQ';
import styles from './FAQs.module.scss';

const FAQs: FunctionComponent = () => {
  const dispatch = useDispatch();
  const frequentlyAskedQuestions = useSelector(selectFaq);
  const [isAddFAQModalOpen, setIsAddFAQModalOpen] = useState(false);

  useEffect(() => {
    dispatch(loadFaqAction());
  }, [dispatch]);

  const openAddFAQModal = (): void => {
    setIsAddFAQModalOpen(true);
  };

  const closeAddFAQModal = (): void => {
    setIsAddFAQModalOpen(false);
  };

  return (
    <>
      <div className={styles.faqListContainer}>
        <div className="mba-heading--wrapper">
          <Typography component="p" variant={TypographyVariants.h1} className={`${styles.faqListHeading}`}>
            FAQs
          </Typography>

          {<Button text="Add new FAQ" size={ButtonSizes.big} primary onClick={openAddFAQModal} />}
        </div>

        <div className={styles.faqList}>
          {frequentlyAskedQuestions &&
            frequentlyAskedQuestions.map((faq) => (
              <div key={faq.id}>
                <FAQ {...faq} />
              </div>
            ))}
        </div>
      </div>
      <Modal title="Add new FAQ" isOpen={isAddFAQModalOpen} onClose={closeAddFAQModal}>
        <AddFAQForm onFormClose={closeAddFAQModal} />
      </Modal>
    </>
  );
};

export default FAQs;

import { RankingTypeDTO, SaveRankingTypeDTO } from './ranking-type-profile.dto';
import { del, get, post, put } from '../../../../shared/api';
import { BasicResponseDTO, DefaultResponseDTO } from '../../../../shared/constants';

export const createRankingTypeRequest = (
  rankingTypeData: SaveRankingTypeDTO,
): Promise<DefaultResponseDTO<RankingTypeDTO>> => {
  return post<DefaultResponseDTO<RankingTypeDTO>, SaveRankingTypeDTO>('/ranking-types', rankingTypeData);
};

export const getRankingTypeRequest = (rankingTypeId: number): Promise<DefaultResponseDTO<RankingTypeDTO>> => {
  return get<DefaultResponseDTO<RankingTypeDTO>>(`/ranking-types/${rankingTypeId}`);
};

export const updateRankingTypeRequest = (rankingTypeData: SaveRankingTypeDTO): Promise<BasicResponseDTO> => {
  const { id } = rankingTypeData;
  return put<BasicResponseDTO, SaveRankingTypeDTO>(`/ranking-types/${id}`, rankingTypeData);
};

export const deleteRankingTypeRequest = (rankingTypeId: number): Promise<BasicResponseDTO> => {
  return del<BasicResponseDTO>(`/ranking-types/${rankingTypeId}`);
};

import { TermsDto } from './terms.dto';
import { EDIT_TERMS, EditTermsAction, LOAD_TERMS, LoadTermsAction, SET_TERMS, SetTermsAction } from './terms.types';

export const loadTermsAction = (): LoadTermsAction => ({
  type: LOAD_TERMS,
});

export const setTermsAction = (data: string): SetTermsAction => ({
  type: SET_TERMS,
  payload: { data },
});

export const editTermsAction = (data: TermsDto): EditTermsAction => ({
  type: EDIT_TERMS,
  payload: { data },
});

import { ColumnDefinition, TableHeaderColumnTypes } from '../../../../../components/Table';

export const PARTNERSHIPS_TABLE_COLUMN_DEFINITION: ColumnDefinition[] = [
  {
    id: 'id',
    title: 'ID',
    sorting: true,
    width: '40px',
  },
  {
    id: 'name',
    path: '/schools/profile/:id',
    sorting: true,
    title: 'Name',
    type: TableHeaderColumnTypes.link,
  },
  {
    id: 'schools',
    title: 'Schools',
  },
];

export const PARTNER_SCHOOLS_TABLE_COLUMN_DEFINITION: ColumnDefinition[] = [
  {
    id: 'id',
    title: 'ID',
    width: '40px',
  },
  {
    id: 'name',
    path: '/schools/profile/:id',
    title: 'Name',
    type: TableHeaderColumnTypes.link,
  },
];

import React, { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Form, { FormDataValues } from '../../../../../components/Form';
import { selectAreRequestsLoading, selectRequestErrors } from '../../../../../shared/state/global-request';
import {
  createSpreadsheetTemplateFieldAction,
  updateSpreadsheetTemplateFieldAction,
} from '../../spreadsheet-template.actions';
import { FIELD_CREATE_FORM_CONFIG } from '../../spreadsheet-template.constants';
import { CreateSpreadsheetTemplateFieldDTO, EditSpreadsheetTemplateFieldDTO } from '../../spreadsheet-template.dto';
import { selectCurrentSpreadsheetTemplateField } from '../../spreadsheet-template.selectors';
import { CREATE_SPREADSHEET_TEMPLATE_FIELD, UPDATE_SPREADSHEET_TEMPLATE_FIELD } from '../../spreadsheet-template.types';

type TemplateFieldFormProps = {
  isEdit?: boolean;
  type: string;
  onFormClose: () => void;
};

const TemplateFieldForm: FunctionComponent<TemplateFieldFormProps> = ({ onFormClose, isEdit = false, type }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    selectAreRequestsLoading([CREATE_SPREADSHEET_TEMPLATE_FIELD, UPDATE_SPREADSHEET_TEMPLATE_FIELD]),
  );
  const templateField = useSelector(selectCurrentSpreadsheetTemplateField(type));
  const formRequestErrors = useSelector(
    selectRequestErrors(isEdit ? UPDATE_SPREADSHEET_TEMPLATE_FIELD : CREATE_SPREADSHEET_TEMPLATE_FIELD),
  );

  const [formValues, setFormValues] = useState<FormDataValues>({});

  const onFieldSaveSubmit = (data: CreateSpreadsheetTemplateFieldDTO | EditSpreadsheetTemplateFieldDTO): void => {
    if (isEdit) {
      const { id } = templateField as EditSpreadsheetTemplateFieldDTO;
      dispatch(updateSpreadsheetTemplateFieldAction(id, type, { ...data, id }));
    } else {
      dispatch(createSpreadsheetTemplateFieldAction(type, data));
    }
  };

  const onValueChange = (data: FormDataValues): void => {
    setFormValues(data);
  };

  const initialValues = isEdit
    ? { ...templateField, ...formValues }
    : {
        label: '',
        dummyValue: '',
        isRequired: false,
        isEmail: false,
        isLanguage: false,
        ...formValues,
      };

  return (
    <>
      <Form
        id="template-field-form"
        config={FIELD_CREATE_FORM_CONFIG}
        onSubmit={onFieldSaveSubmit}
        onValuesChanged={onValueChange}
        submitButtonText={isEdit ? 'Save' : 'Create field'}
        actions={[
          {
            label: 'Cancel',
            onClick: onFormClose,
          },
        ]}
        isLoading={isLoading}
        initialValues={initialValues}
        errors={formRequestErrors}
      />
      {formRequestErrors.name && (
        <div className="o-forms-input--invalid mba-mb-20 mba-mt-10">
          <span className="o-forms-input__error">{formRequestErrors.name}</span>
        </div>
      )}
    </>
  );
};

export default TemplateFieldForm;

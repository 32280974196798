import { FormConfig } from '../../../../components/Form';

export interface SchoolUserProfileRouteParams {
  id: string;
  userId: string;
}

export const SCHOOL_USER_DETAILS_CONFIG: FormConfig = {
  name: {
    fieldType: 'text',
    placeholder: `Enter user's name`,
    required: true,
    title: 'Name',
    validations: {
      required: {
        errorText: 'Please enter a name!',
      },
    },
  },
  school: {
    disabled: true,
    fieldType: 'select',
    required: true,
    title: 'School',
    options: [],
  },
  email: {
    fieldType: 'text',
    placeholder: `Enter user's e-mail address`,
    required: true,
    title: 'E-mail',
    validations: {
      required: {
        errorText: `Please enter user's e-mail address!`,
      },
      regex: {
        errorText: 'Please enter a valid e-mail address!',
        rule: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      },
    },
  },
  status: {
    fieldType: 'select',
    placeholder: 'Choose a status',
    required: true,
    options: [],
    title: 'Status',
    validations: {
      required: {
        errorText: 'Please choose a status!',
      },
    },
  },
  phone: {
    fieldType: 'text',
    title: 'Phone',
    placeholder: 'Enter phone number',
  },
};

import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

import { setRankingTypesAction } from './ranking-types.actions';
import { getRankingTypesRequest } from './ranking-types.api';
import { LOAD_RANKING_TYPES, LoadRankingTypesAction, RankingTypesActionTypes } from './ranking-types.types';
import { RankingTypeDTO } from '../RankingTypeProfile';
import { PagedResponseDTO } from '../../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../../shared/state/global-request';

function* loadRankingTypesTableData(
  action: LoadRankingTypesAction,
): Generator<
  CallEffect<PagedResponseDTO<RankingTypeDTO[]>> | PutEffect<RankingTypesActionTypes> | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig } = action.payload;
    const { data, pagination, sortOrder } = (yield call(getRankingTypesRequest, pageConfig)) as PagedResponseDTO<
      RankingTypeDTO[]
    >;
    yield put(setRankingTypesAction({ data, pagination, sortOrder }));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* rankingTypesSaga(): Generator {
  yield takeLatest(LOAD_RANKING_TYPES, loadRankingTypesTableData);
}

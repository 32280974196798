import React, { Fragment, FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';

import EditableGroup from '../../../../components/EditableGroup';
import { FormConfig, FormDataValues } from '../../../../components/Form';
import Icon from '../../../../components/Icon';
import Label, { LabelStatus } from '../../../../components/Label';
import PageLoader from '../../../../components/PageLoader';
import Typography, { TypographyVariants } from '../../../../components/Typography';
import {
  selectAreRequestsLoading,
  selectRequestErrors,
  selectRequestIsLoading,
  setRequestSucceededAction,
} from '../../../../shared/state/global-request';
import { loadResourcesAction, selectResources } from '../../../../shared/state/resources';
import { loadRolesAction, selectRoles } from '../../../../shared/state/roles';
import { loadStatusesAction, selectStatuses } from '../../../../shared/state/statuses';
import { SCHOOL_PROFILE_MAIN_ROUTE } from '../../SchoolProfile';
import { LOAD_SCHOOL } from '../../SchoolProfile/SchoolDetails';
import { selectCurrentSchoolUser } from '../../SchoolProfile/SchoolUsersList';
import { editSchoolUserAction, loadSchoolUserAction } from '../school-user-profile.actions';
import { SchoolUserDTO } from '../school-user-profile.dto';
import { EDIT_SCHOOL_USER, LOAD_SCHOOL_USER } from '../school-user-profile.types';
import { SCHOOL_USER_DETAILS_CONFIG, SchoolUserProfileRouteParams } from './SchoolUserProfile.constants';
import SchoolUserRolesAndPermissions from './SchoolUserRolesAndPermissions';
import NotFound from '../../../../components/NotFound';

const SchoolUserProfile: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id: schoolId, userId } = useParams<SchoolUserProfileRouteParams>();
  const isLoading = useSelector(selectAreRequestsLoading([LOAD_SCHOOL, LOAD_SCHOOL_USER]));
  const isEditLoading = useSelector(selectRequestIsLoading(EDIT_SCHOOL_USER));
  const rankingTypes = useSelector(selectResources('ranking-types'));
  const schoolUserRoles = useSelector(selectRoles('school-users'));
  const userStatuses = useSelector(selectStatuses('user'));
  const currentSchoolUser = useSelector(selectCurrentSchoolUser) as SchoolUserDTO;
  const formErrors = useSelector(selectRequestErrors(EDIT_SCHOOL_USER));
  const { id: currentUserId, name, joinedSchools, permissions, role, status, school } = currentSchoolUser;

  useEffect(() => {
    if (schoolId && userId) {
      dispatch(loadResourcesAction('ranking-types'));
      dispatch(loadRolesAction('school-users'));
      dispatch(loadStatusesAction('user'));
      dispatch(loadSchoolUserAction(parseInt(userId), parseInt(schoolId)));
    }
  }, [dispatch, schoolId, userId]);

  const schoolUserDetailsFormConfig: FormConfig = {
    ...SCHOOL_USER_DETAILS_CONFIG,
    school: {
      ...SCHOOL_USER_DETAILS_CONFIG.school,
      options: [{ label: school?.label, value: school?.key }],
    },
    status: {
      ...SCHOOL_USER_DETAILS_CONFIG.status,
      options: userStatuses.map(({ key, label }) => ({ label, value: key })),
    },
  };

  const resetFormState = (): void => {
    dispatch(setRequestSucceededAction(EDIT_SCHOOL_USER));
  };

  const updateRankingCategory = (data: FormDataValues): void => {
    dispatch(editSchoolUserAction({ ...data, id: parseInt(userId) } as SchoolUserDTO, parseInt(schoolId)));
  };

  return (
    <>
      {currentUserId ? (
        <>
          <EditableGroup<SchoolUserDTO>
            columnSize={6}
            data={currentSchoolUser}
            editButtonLabel="Edit user details"
            formConfig={schoolUserDetailsFormConfig}
            formErrors={formErrors}
            headingType="back"
            heading={
              <div>
                <Link
                  className="o-layout__unstyled-element mba-back-arrow"
                  title="Go back to the school profile"
                  to={SCHOOL_PROFILE_MAIN_ROUTE.replace(':id', schoolId.toString())}
                >
                  <Icon name="arrow-left" size={30} />
                </Link>
                <span className="mba-center--vertically">
                  {name}
                  &nbsp;
                  {status && (
                    <Label color={status.color} status={LabelStatus.active}>
                      {status.label}
                    </Label>
                  )}
                </span>
              </div>
            }
            isLoading={isEditLoading}
            onEditSubmit={updateRankingCategory}
            onEditCancel={resetFormState}
          />
          <hr className="mba-separator" />
          <div className="mba-heading--wrapper mba-heading--table">
            <Typography component="p" variant={TypographyVariants.h2} className="mba-heading--title-2">
              Role and access to rankings
            </Typography>
          </div>
          <SchoolUserRolesAndPermissions
            partnershipId={school && school.key ? school.key : 0}
            schoolId={school && school.key ? school.key : 0}
            label={school && school.label ? school.label : ''}
            permissions={permissions}
            permissionsSubTitle="Permissions to access ranking types"
            rankingTypes={rankingTypes}
            role={role}
            rolesSubTitle="Role in main school"
            schoolUserRoles={schoolUserRoles}
            userId={currentUserId}
          />
          {joinedSchools && joinedSchools.length > 0 && (
            <>
              <hr className="mba-separator" />
              <div className="mba-heading--wrapper mba-heading--table">
                <Typography component="p" variant={TypographyVariants.h2} className="mba-heading--title-2">
                  Partnership role and access to rankings
                </Typography>
              </div>
              {joinedSchools.map(({ key, ...joinedSchool }, index) => (
                <Fragment key={`partnership-roles-${index}`}>
                  <SchoolUserRolesAndPermissions
                    {...joinedSchool}
                    partnershipId={key}
                    schoolId={school && school.key ? school.key : 0}
                    permissionsSubTitle="Permissions to access ranking types"
                    rankingTypes={rankingTypes}
                    rolesSubTitle="Role in partnership school"
                    schoolUserRoles={schoolUserRoles}
                    userId={currentUserId}
                  />
                  <br />
                </Fragment>
              ))}
            </>
          )}
        </>
      ) : isLoading || !schoolId ? (
        <PageLoader />
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default SchoolUserProfile;

import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import Button, { ButtonSizes } from '../../../../../../components/Button';
import Typography from '../../../../../../components/Typography';
import { selectRequestIsLoading } from '../../../../../../shared/state/global-request';
import { DELETE_ACCREDITATION } from '../../../AccreditationProfile';

type RemoveCampusPopupProps = {
  id: number;
  onCancel: () => void;
  onConfirm: (id: number) => void;
};

const AccreditationDeleteConfirmation: FunctionComponent<RemoveCampusPopupProps> = ({ id, onCancel, onConfirm }) => {
  const isLoading = useSelector(selectRequestIsLoading(DELETE_ACCREDITATION));
  return (
    <>
      <Typography>
        Are you sure you want to delete this accreditation.
        <br />
        It will be removed from all school profiles as well!
      </Typography>
      <div className="mba-actions">
        <Button onClick={onCancel} text="Cancel" size={ButtonSizes.big} />
        <Button onClick={(): void => onConfirm(id)} isLoading={isLoading} primary text="Yes" size={ButtonSizes.big} />
      </div>
    </>
  );
};

export default AccreditationDeleteConfirmation;

import React, { FunctionComponent } from 'react';

const StepErrors: FunctionComponent<{ errors?: string[] }> = ({ errors = [] }) => (
  <div className="o-forms-input--invalid">
    {errors.map((error) => (
      <span className="o-forms-input__error" key={error}>
        {error}
      </span>
    ))}
  </div>
);

export default StepErrors;

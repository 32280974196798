import { push, RouterAction } from 'react-router-redux';
import { call, CallEffect, put, PutEffect, select, SelectEffect, takeEvery, takeLatest } from 'redux-saga/effects';

import { BasicResponseDTO, DefaultResponseDTO, PageSearchQueryConfig } from '../../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../../shared/state/global-request';
import { loadSchoolsAction, SchoolsActionTypes, selectSchoolsPageConfiguration } from '../../SchoolsList';
import { loadSchoolAction, setSchoolAction } from './school-details.actions';
import {
  createSchoolRequest,
  deleteSchoolRequest,
  getSchoolRequest,
  updateSchoolAccreditations,
  updateSchoolLogoRequest,
  updateSchoolRequest,
} from './school-details.api';
import { SchoolDTO } from './school-details.dto';
import { selectCurrentSchool } from './school-details.selectors';
import {
  CLEAR_CURRENT_SCHOOL,
  CREATE_SCHOOL,
  CreateSchoolAction,
  DELETE_SCHOOL,
  DeleteSchoolAction,
  EDIT_SCHOOL,
  EDIT_SCHOOL_ACCREDITATIONS,
  EDIT_SCHOOL_DETAILS,
  EDIT_SCHOOL_LOGO,
  EditSchoolAccreditationsAction,
  EditSchoolAction,
  EditSchoolDetailsAction,
  EditSchoolLogoAction,
  LOAD_SCHOOL,
  LoadSchoolAction,
  SchoolActionTypes,
} from './school-details.types';

function* createNewSchoolSaga(
  action: CreateSchoolAction,
): Generator<
  PutEffect<GlobalRequestActions | SchoolActionTypes | SchoolsActionTypes> | CallEffect<DefaultResponseDTO<SchoolDTO>>
> {
  const {
    type: actionType,
    payload: { data },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(createSchoolRequest, data);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadSchoolsAction());
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* loadSchoolSaga(
  action: LoadSchoolAction,
): Generator<CallEffect<DefaultResponseDTO<SchoolDTO>> | PutEffect<GlobalRequestActions | SchoolActionTypes>> {
  const {
    type: actionType,
    payload: { id },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getSchoolRequest, id)) as DefaultResponseDTO<SchoolDTO>;
    yield put(setSchoolAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* editSchoolSaga(
  action: EditSchoolAction,
): Generator<PutEffect<GlobalRequestActions | SchoolsActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect> {
  const {
    type: actionType,
    payload: { data },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateSchoolRequest, data);
    yield put(setRequestSucceededAction(actionType));
    const pageConfig = (yield select(selectSchoolsPageConfiguration)) as PageSearchQueryConfig;
    yield put(loadSchoolsAction(pageConfig));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* editSchoolDetailsSaga(
  action: EditSchoolDetailsAction,
): Generator<PutEffect<GlobalRequestActions | SchoolActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect> {
  const {
    type: actionType,
    payload: { data },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateSchoolRequest, data);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadSchoolAction(data.id));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* editSchoolAccreditationsSaga(
  action: EditSchoolAccreditationsAction,
): Generator<PutEffect<GlobalRequestActions | SchoolActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect> {
  const {
    type: actionType,
    payload: { accreditationIds, schoolId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateSchoolAccreditations, schoolId, accreditationIds);
    const { data } = (yield call(getSchoolRequest, schoolId)) as DefaultResponseDTO<SchoolDTO>;

    const currentSchool = (yield select(selectCurrentSchool)) as SchoolDTO;
    currentSchool.accreditations = data.accreditations;

    yield put(setSchoolAction(currentSchool));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* editSchoolLogoSaga(
  action: EditSchoolLogoAction,
): Generator<PutEffect<GlobalRequestActions | SchoolActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect> {
  const {
    type: actionType,
    payload: { data },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateSchoolLogoRequest, data);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadSchoolAction(data.id));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* deleteSchoolSaga(
  action: DeleteSchoolAction,
): Generator<PutEffect<GlobalRequestActions | RouterAction> | CallEffect<BasicResponseDTO>> {
  const {
    type: actionType,
    payload: { id },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(deleteSchoolRequest, id);
    yield put(setRequestSucceededAction(actionType));
    yield put(push('/schools/list'));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* resetSchoolErrors(): Generator<PutEffect<GlobalRequestActions>> {
  yield put(setRequestSucceededAction(CREATE_SCHOOL));
  yield put(setRequestSucceededAction(EDIT_SCHOOL));
  yield put(setRequestSucceededAction(EDIT_SCHOOL_ACCREDITATIONS));
  yield put(setRequestSucceededAction(EDIT_SCHOOL_LOGO));
}

export default function* schoolDetailsSaga(): Generator {
  yield takeLatest(CREATE_SCHOOL, createNewSchoolSaga);
  yield takeLatest(LOAD_SCHOOL, loadSchoolSaga);
  yield takeLatest(EDIT_SCHOOL, editSchoolSaga);
  yield takeLatest(EDIT_SCHOOL_ACCREDITATIONS, editSchoolAccreditationsSaga);
  yield takeLatest(EDIT_SCHOOL_LOGO, editSchoolLogoSaga);
  yield takeLatest(EDIT_SCHOOL_DETAILS, editSchoolDetailsSaga);
  yield takeLatest(DELETE_SCHOOL, deleteSchoolSaga);
  yield takeEvery(CLEAR_CURRENT_SCHOOL, resetSchoolErrors);
}

import { Resource } from '../../constants';
import {
  RankingTypeDropdownState,
  RankingTypesActionDropDownTypes,
  SET_RANKING_TYPES_DROPDOWN,
} from './ranking-types-dropdown.types';

const initialState: RankingTypeDropdownState = {
  rankingTypes: [] as Resource[],
};

const rankingTypesDropdownReducer = (
  state = initialState,
  action: RankingTypesActionDropDownTypes,
): RankingTypeDropdownState => {
  switch (action.type) {
    case SET_RANKING_TYPES_DROPDOWN: {
      return {
        ...state,
        rankingTypes: action.payload.rankingTypes || [],
      };
    }
    default: {
      return state;
    }
  }
};

export default rankingTypesDropdownReducer;

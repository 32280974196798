import { call, CallEffect, put, PutEffect, takeEvery, takeLatest } from 'redux-saga/effects';

import { redirectUserToSSO, setUserNavigation, setUserToken } from './auth.actions';
import { checkTokenValidity, getNavigation, logoutUser } from './auth.api';
import {
  AuthActionTypes,
  LOG_OUT_USER,
  SET_USER_TOKEN,
  SetUserTokenAction,
  VALIDATE_USER_TOKEN,
  ValidateUserTokenAction,
} from './auth.types';
import { NavigationConfiguration } from '../Main/components/Layout';
import { updateAxiosBearerToken } from '../../../shared/api';
import { BasicResponseDTO, DefaultResponseDTO, JWT_TOKEN_COOKIE_NAME } from '../../../shared/constants';
import { deleteCookie, setCookie } from '../../../shared/web-storage';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../shared/state/global-request';

function* validateUserToken(
  action: ValidateUserTokenAction,
): Generator<
  | void
  | CallEffect<BasicResponseDTO | DefaultResponseDTO<NavigationConfiguration>>
  | PutEffect<AuthActionTypes>
  | PutEffect<GlobalRequestActions>
> {
  const { token } = action.payload;

  if (token) {
    yield updateAxiosBearerToken(token);

    try {
      yield put(setRequestStartedAction(VALIDATE_USER_TOKEN));
      yield call(checkTokenValidity);
      yield put(setUserToken(token));
      const { data: navigation } = (yield call(getNavigation)) as DefaultResponseDTO<NavigationConfiguration>;

      // TODO: need to remove after update navigation on API
      const customNavigation = {
        ...navigation,
        rankings: {
          ...navigation.rankings,
          navigation: {
            ...navigation.rankings.navigation,
            information: {
              label: 'Additional Information',
              route: '/information',
            },
          },
        },
        commercial: {
          ...navigation.commercial,
          navigation: {
            schoolWidgetGroups: {
              exact: true,
              label: 'School Widget Groups',
              route: '/school-widget-groups',
            },
            schoolsWithLeadGen: {
              exact: true,
              label: 'Schools leads',
              route: '/lead-gen',
            },
            registrations: {
              label: 'Registrations',
              exact: true,
              route: '/registrations',
            },
            schoolLeadsProfile: {
              label: '',
              route: '/lead-gen/:schoolId',
            },
            schoolWidgetDetails: {
              label: '',
              route: '/school-widget-groups/detail/:schoolWidgetGroupId',
            },
          },
        },
      };

      yield put(setRequestSucceededAction(VALIDATE_USER_TOKEN));
      yield put(setUserNavigation(customNavigation));
    } catch (error: any) {
      yield put(setRequestFailedAction(VALIDATE_USER_TOKEN, error));
    }
  }
}

function* setUserTokenInCookie(action: SetUserTokenAction): Generator<void> {
  const token = action.payload.token;

  if (token) {
    yield setCookie(JWT_TOKEN_COOKIE_NAME, token);
    yield updateAxiosBearerToken(token as string);
  }
}

function* deleteUserToken(): Generator<void | CallEffect<BasicResponseDTO> | PutEffect<AuthActionTypes>> {
  yield call(logoutUser);
  yield deleteCookie(JWT_TOKEN_COOKIE_NAME);
  yield put(setUserToken(''));
  yield updateAxiosBearerToken('');
  yield put(redirectUserToSSO());
}

export default function* authSaga(): Generator {
  yield takeEvery(LOG_OUT_USER, deleteUserToken);
  yield takeLatest(SET_USER_TOKEN, setUserTokenInCookie);
  yield takeLatest(VALIDATE_USER_TOKEN, validateUserToken);
}

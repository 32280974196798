import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ADMIN_USER_CREATE_FORM_CONFIG, ADMIN_USER_EDIT_FORM_CONFIG } from '../UsersList.constants';
import { createNewUserAction, editUserAction } from '../../users.actions';
import { CreateUserDTO, EditUserDTO, UserDTO } from '../../users.dto';
import { CREATE_USER, EDIT_USER } from '../../users.types';
import Form from '../../../../../components/Form';
import { SelectOption } from '../../../../../components/Select';
import { selectAreRequestsLoading, selectRequestFormErrors } from '../../../../../shared/state/global-request';
import { capitalizeText } from '../../../../../shared/helpers';
import { selectRoles } from '../../../../../shared/state/roles';
import { selectStatuses } from '../../../../../shared/state/statuses';

type UserFormProps = {
  isEdit?: boolean;
  currentUser?: CreateUserDTO | UserDTO;
  onFormClose: () => void;
};

const UserForm: FunctionComponent<UserFormProps> = ({ onFormClose, currentUser, isEdit = false }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectAreRequestsLoading([CREATE_USER, EDIT_USER]));
  const formErrors = useSelector(selectRequestFormErrors(isEdit ? EDIT_USER : CREATE_USER));
  const roles = useSelector(selectRoles('users'));
  const statuses = useSelector(selectStatuses('user'));

  const roleOptions: SelectOption[] = roles
    ? roles.map((roleName: string) => ({ label: capitalizeText(roleName), value: roleName }))
    : [];

  const defaultFormConfig = isEdit
    ? {
        ...ADMIN_USER_EDIT_FORM_CONFIG,
        role: {
          ...ADMIN_USER_EDIT_FORM_CONFIG.role,
          options: roleOptions,
        },
        status: {
          ...ADMIN_USER_EDIT_FORM_CONFIG.status,
          options: statuses ? statuses.map(({ key, label }) => ({ label, value: key })) : [],
        },
      }
    : {
        ...ADMIN_USER_CREATE_FORM_CONFIG,
        role: {
          ...ADMIN_USER_CREATE_FORM_CONFIG.role,
          options: roleOptions,
        },
      };

  const userInitialData =
    isEdit && (currentUser as UserDTO).status && (currentUser as UserDTO).status.key
      ? {
          ...currentUser,
          status: (currentUser as UserDTO).status.key,
        }
      : currentUser;

  const onUserSaveSubmit = (data: CreateUserDTO | EditUserDTO): void => {
    if (isEdit) {
      const { id } = currentUser as EditUserDTO;
      const { status } = data as EditUserDTO;
      dispatch(editUserAction({ ...data, id, status: +status } as EditUserDTO));
    } else {
      dispatch(createNewUserAction(data as CreateUserDTO));
    }
  };

  return (
    <Form
      id="admin-user-form"
      isLoading={isLoading}
      config={defaultFormConfig}
      onSubmit={onUserSaveSubmit}
      submitButtonText={isEdit ? 'Save' : 'Create user'}
      actions={[
        {
          label: 'Cancel',
          onClick: onFormClose,
        },
      ]}
      initialValues={userInitialData}
      errors={formErrors}
    />
  );
};

export default UserForm;

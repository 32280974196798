import { PageSearchQueryConfig, TableListData } from '../../../shared/constants';
import { AddMessageDto, MessageDto } from './onsite-messaging.dto';

export const LOAD_MESSAGE_HISTORY = 'LOAD_MESSAGE_HISTORY';
export const LOAD_LATEST_MESSAGE = 'LOAD_LATEST_MESSAGE';
export const SET_MESSAGE_HISTORY = 'SET_MESSAGE_HISTORY';
export const SET_LATEST_MESSAGE = 'SET_LATEST_MESSAGE';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const EDIT_MESSAGE = 'EDIT_MESSAGE';

export interface LoadMessageHistoryAction {
  type: typeof LOAD_MESSAGE_HISTORY;
  payload: { pageConfig?: PageSearchQueryConfig };
}

export interface LoadLatestMessagesAction {
  type: typeof LOAD_LATEST_MESSAGE;
}

export interface SetMessageHistoryAction {
  type: typeof SET_MESSAGE_HISTORY;
  payload: { tableData: TableListData<MessageDto> };
}

export interface SetLatestMessageAction {
  type: typeof SET_LATEST_MESSAGE;
  payload: { data: MessageDto };
}

export interface AddMessageAction {
  type: typeof ADD_MESSAGE;
  payload: { data: AddMessageDto };
}

export interface EditMessageAction {
  type: typeof EDIT_MESSAGE;
  payload: { messageId: number; data: AddMessageDto };
}

export type OnsiteMessagingTypes =
  | LoadMessageHistoryAction
  | LoadLatestMessagesAction
  | SetMessageHistoryAction
  | SetLatestMessageAction
  | AddMessageAction
  | EditMessageAction;

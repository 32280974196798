import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import ParticipationCriteria from './ParticipationCriteria';
import { RANKING_PROFILE_DETAILS_MAIN_ROUTE, RANKING_PROFILE_DETAILS_SUBNAVIGATION } from './RankingDetails.constants';
import TimelineSteps from './TimelineSteps';
import WelcomeText from './WelcomeText';
import { loadRankingAction, RankingProfileRouteParams } from '../RankingInfo';
import { PageRouter } from '../../../../App/Main/components/Layout';
import Grid from '../../../../../components/Grid';
import LayoutNavigation from '../../../../../components/LayoutNavigation';

const RankingDetails: FunctionComponent = () => {
  const dispatch = useDispatch();
  const urlParams = useParams<RankingProfileRouteParams>();
  const { id: rankingId } = urlParams;
  const { pathname } = useLocation();

  const componentsMap = {
    welcomeText: WelcomeText,
    participationCriteria: ParticipationCriteria,
    instructions: TimelineSteps,
  };

  useEffect(() => {
    if (rankingId) {
      dispatch(loadRankingAction(parseInt(rankingId)));
    }
  }, [dispatch, rankingId]);

  const navItems = Object.values(RANKING_PROFILE_DETAILS_SUBNAVIGATION).map(({ label, route }) => ({
    label,
    route: `${RANKING_PROFILE_DETAILS_MAIN_ROUTE.replace(':id', rankingId)}${route}`,
  }));

  return (
    <>
      <Grid container>
        <Grid item lg={3}>
          <LayoutNavigation items={navItems} selectedItem={pathname} />
        </Grid>
        <Grid item lg={9}>
          <PageRouter
            mainRoute={RANKING_PROFILE_DETAILS_MAIN_ROUTE}
            navigation={RANKING_PROFILE_DETAILS_SUBNAVIGATION}
            componentsMap={componentsMap}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default RankingDetails;

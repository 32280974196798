import moment from 'moment';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditableGroup from '../../../../components/EditableGroup';
import { FormDataValues } from '../../../../components/Form';
import Typography, { TypographyVariants } from '../../../../components/Typography';
import { editMessageAction, loadLatestMessageAction } from '../onsite-messaging.actions';
import { AddMessageDto } from '../onsite-messaging.dto';
import { selectLatestMessage } from '../onsite-messaging.selectors';
import { EDIT_MESSAGE_FORM_CONFIG } from './Active.constants';

const Active: FunctionComponent = () => {
  const dispatch = useDispatch();
  const latestMessage = useSelector(selectLatestMessage);

  useEffect(() => {
    dispatch(loadLatestMessageAction());
  }, [dispatch]);

  const editMessage = (data: FormDataValues): void => {
    const editedMessage = {
      ...data,
      validFrom: moment(data.startDate.toString()).toISOString(),
      validTo: moment(data.endDate.toString()).toISOString(),
    } as any;
    delete editedMessage.startDate;
    delete editedMessage.endDate;
    dispatch(editMessageAction(+latestMessage.id, editedMessage as AddMessageDto));
  };

  return (
    <>
      <EditableGroup
        columnSize={4}
        data={{
          ...latestMessage,
          startDate: latestMessage.validFrom,
          endDate: latestMessage.validTo,
        }}
        formConfig={EDIT_MESSAGE_FORM_CONFIG}
        heading={
          <Typography component="p" variant={TypographyVariants.h2} className="mba-heading--title-2">
            Details
          </Typography>
        }
        editButtonLabel="Edit details"
        onEditSubmit={editMessage}
      />
    </>
  );
};

export default Active;

import React, { FunctionComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { NavigationConfiguration } from '../Layout.constants';

type PageRouterProps = {
  componentsMap: { [key: string]: FunctionComponent };
  mainRoute: string;
  navigation: NavigationConfiguration;
};

const PageRouter: FunctionComponent<PageRouterProps> = ({ componentsMap, mainRoute, navigation }) => {
  const navigationConfig = Object.entries(navigation);
  return (
    <Switch>
      <Redirect exact from={mainRoute} to={`${mainRoute}${navigationConfig[0][1].route}`} />
      {navigationConfig.map(([key, { route: subRoute, exact }]) => (
        <Route exact={exact} key={key} path={`${mainRoute}${subRoute}`} component={componentsMap[key]} />
      ))}
    </Switch>
  );
};

export default PageRouter;

import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import Button, { ButtonSizes } from '../../../../../components/Button';
import Typography from '../../../../../components/Typography';
import { selectAreRequestsLoading } from '../../../../../shared/state/global-request';
import { DELETE_SPREADSHEET_TEMPLATE_FIELD } from '../../spreadsheet-template.types';
import { CONFIRMATION_MESSAGE } from './RemoveTemplateFieldPopup.constants';

type RemoveColumnPopupProps = {
  onCancel: () => void;
  onConfirm: (id: number) => void;
  columnId: number;
};

const RemoveTemplateFieldPopup: FunctionComponent<RemoveColumnPopupProps> = ({ columnId, onCancel, onConfirm }) => {
  const isLoading = useSelector(selectAreRequestsLoading([DELETE_SPREADSHEET_TEMPLATE_FIELD]));

  return (
    <>
      <Typography>{CONFIRMATION_MESSAGE}</Typography>
      <div className="mba-actions">
        <Button onClick={onCancel} text="Cancel" size={ButtonSizes.big} />
        <Button
          primary
          text="Yes"
          size={ButtonSizes.big}
          isLoading={isLoading}
          onClick={(): void => onConfirm(columnId)}
        />
      </div>
    </>
  );
};

export default RemoveTemplateFieldPopup;

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button, { ButtonSizes } from '../../../../../../components/Button';
import FileUpload from '../../../../../../components/FileUpload';
import Grid from '../../../../../../components/Grid';
import { MODAL_ANIMATION_DURATION } from '../../../../../../components/Modal';
import { AttachmentDTO } from '../../../../../../shared/state/attachments';
import {
  selectRequestErrorMessage,
  selectRequestIsLoading,
  setRequestSucceededAction,
} from '../../../../../../shared/state/global-request';
import { editSchoolLogoAction } from '../../school-details.actions';
import { EditSchoolLogoDTO, SchoolDTO } from '../../school-details.dto';
import { selectCurrentSchool } from '../../school-details.selectors';
import { EDIT_SCHOOL_LOGO } from '../../school-details.types';

import styles from './SchoolLogo.module.scss';

type SchoolLogoProps = {
  image: AttachmentDTO;
};

const SchoolLogo: FunctionComponent<SchoolLogoProps> = ({ image }) => {
  const dispatch = useDispatch();

  const school = useSelector(selectCurrentSchool) as SchoolDTO;
  const isLoading = useSelector(selectRequestIsLoading(EDIT_SCHOOL_LOGO));
  const requestErrors = useSelector(selectRequestErrorMessage(EDIT_SCHOOL_LOGO));

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  useEffect(() => {
    if (!requestErrors && !isLoading) {
      setIsUploadModalOpen(false);
    }
  }, [requestErrors, isLoading]);

  const handleUpload = (file: File): void => {
    dispatch(setRequestSucceededAction(EDIT_SCHOOL_LOGO));

    const formData = new FormData();
    formData.append('logo', file);

    const schoolUpdateData: EditSchoolLogoDTO = { id: school.id, logo: formData };
    dispatch(editSchoolLogoAction(schoolUpdateData));
  };

  const closeUploadModal = (): void => {
    setIsUploadModalOpen(false);

    setTimeout(() => {
      dispatch(setRequestSucceededAction(EDIT_SCHOOL_LOGO));
    }, MODAL_ANIMATION_DURATION);
  };

  const onClickRemove = (): void => {
    if (image) {
      const schoolUpdateData: EditSchoolLogoDTO = { id: school.id, logo: null };
      dispatch(editSchoolLogoAction(schoolUpdateData));
    }
  };

  return (
    <>
      <Grid container className={styles.schoolLogoWrapper}>
        <Grid item>
          <div className={styles.logoWrapper}>{image?.s3Url && <img src={image.s3Url} alt={image.name} />}</div>
        </Grid>
        <Grid item>
          <div className={styles.buttonsWrapper}>
            <Button
              isLoading={isLoading}
              text="Upload school logo"
              size={ButtonSizes.big}
              onClick={(): void => setIsUploadModalOpen(true)}
            />
            <Button
              text="Remove school logo"
              size={ButtonSizes.big}
              disabled={!image || isLoading}
              onClick={onClickRemove}
            />
          </div>
        </Grid>

        <FileUpload
          title={`Update the school logo`}
          isLoading={isLoading}
          isOpen={isUploadModalOpen}
          onClose={closeUploadModal}
          onUpload={handleUpload}
          errorMessage={requestErrors}
        />
      </Grid>
    </>
  );
};

export default SchoolLogo;

import { RankingSpreadsheetDTO } from './spreadsheets.dto';
import { PageSearchQueryConfig, TableListData } from '../../../../../shared/constants';

export const LOAD_RANKING_SPREADSHEETS = 'LOAD_RANKING_SPREADSHEETS';
export const SET_RANKING_SPREADSHEETS = 'SET_RANKING_SPREADSHEETS';
export const DOWNLOAD_RANKING_SPREADSHEET = 'DOWNLOAD_RANKING_SPREADSHEET';
export const DOWNLOAD_RANKING_SPREADSHEET_CONTACTS = 'DOWNLOAD_RANKING_SPREADSHEET_CONTACTS';
export const DELETE_SPREADSHEET = 'DELETE_SPREADSHEET';
export const DELETE_ALL_SPREADSHEETS = 'DELETE_ALL_SPREADSHEETS';

export type SpreadsheetsState = {
  [key: string]: TableListData<RankingSpreadsheetDTO>;
};

export interface LoadRankingSpreadsheetsAction {
  type: typeof LOAD_RANKING_SPREADSHEETS;
  payload: {
    rankingId: number;
    type: string;
    pageConfig?: PageSearchQueryConfig;
  };
}

export interface SetRankingSpreadsheetsAction {
  type: typeof SET_RANKING_SPREADSHEETS;
  payload: {
    type: string;
    tableData: TableListData<RankingSpreadsheetDTO>;
  };
}

export interface DownloadRankingSpreadsheetAction {
  type: typeof DOWNLOAD_RANKING_SPREADSHEET;
  payload: {
    rankingId: number;
    type: string;
    pageConfig?: PageSearchQueryConfig;
  };
}

export interface DownloadRankingSpreadsheetContactsAction {
  type: typeof DOWNLOAD_RANKING_SPREADSHEET_CONTACTS;
  payload: {
    rankingId: number;
    type: string;
    pageConfig?: PageSearchQueryConfig;
  };
}

export interface DeleteSpreadsheetAction {
  type: typeof DELETE_SPREADSHEET;
  payload: {
    rankingId: number;
    id: string | undefined;
  };
}

export interface DeleteAllSpreadsheetsAction {
  type: typeof DELETE_ALL_SPREADSHEETS;
  payload: {
    rankingId: number;
    type: string;
  };
}

export type RankingSpreadsheetActionTypes =
  | LoadRankingSpreadsheetsAction
  | SetRankingSpreadsheetsAction
  | DownloadRankingSpreadsheetAction
  | DownloadRankingSpreadsheetContactsAction
  | DeleteSpreadsheetAction
  | DeleteAllSpreadsheetsAction;

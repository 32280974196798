import { FormConfig } from '../../../../../components/Form';
import { ColumnDefinition, TableHeaderColumnTypes } from '../../../../../components/Table';

export const ACCREDITATION_FORM_CONFIG: FormConfig = {
  name: {
    fieldType: 'textarea',
    label: 'Name',
    placeholder: 'Enter name',
    validations: {
      required: {
        errorText: `Please enter a name!`,
      },
    },
  },
  shortName: {
    fieldType: 'text',
    label: 'Short name',
    placeholder: 'Enter short name',
    validations: {
      required: {
        errorText: `Please enter a short name!`,
      },
    },
  },
  website: {
    fieldType: 'text',
    label: 'Website',
    placeholder: 'Enter website',
  },
  description: {
    fieldType: 'textarea',
    label: 'Description',
    placeholder: 'Enter description',
    rows: 10,
  },
};

export const ACCREDITATIONS_TABLE_COLUMN_DEFINITION: ColumnDefinition[] = [
  {
    id: 'shortName',
    sorting: true,
    title: 'Short name',
    width: '160px',
  },
  {
    id: 'name',
    sorting: true,
    title: 'Name',
  },
  {
    id: 'updatedAt',
    sorting: true,
    title: 'Last updated',
    type: TableHeaderColumnTypes.date,
    width: '160px',
  },
];

import { PageSearchQueryConfig, TableListData } from '../../../../../../shared/constants';
import { RankingFieldDTO } from './ranking-fields-list.dto';
import { get } from '../../../../../../shared/api';
import { generateSearchQuery } from '../../../../../../shared/helpers';

export const getRankingFieldsRequest = (
  rankingId: number,
  pageConfig: PageSearchQueryConfig = {},
): Promise<TableListData<RankingFieldDTO>> => {
  const query = generateSearchQuery(pageConfig);
  return get<TableListData<RankingFieldDTO>>(`/ranking/${rankingId}/fields${query}`);
};

import { PageSearchQueryConfig, TableListData } from '../../../shared/constants';
import { LogsDTO } from './logs.dto';
import { LoadLogsAction, LOAD_LOGS, SetLogsAction, SET_LOGS } from './logs.types';

export const loadLogsAction = (pageConfig?: PageSearchQueryConfig): LoadLogsAction => ({
  type: LOAD_LOGS,
  payload: { pageConfig },
});

export const setLogsAction = (tableData: TableListData<LogsDTO>): SetLogsAction => {
  return {
    type: SET_LOGS,
    payload: { tableData },
  };
};

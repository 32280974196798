import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button, { ButtonIcons, ButtonSizes } from '../../../../../components/Button';
import Modal, { MODAL_ANIMATION_DURATION } from '../../../../../components/Modal';
import Pager, { INITIAL_PAGE } from '../../../../../components/Pager';
import Table, { TableHeaderColumnTypes } from '../../../../../components/Table';
import Typography, { TypographyVariants } from '../../../../../components/Typography';
import { ASCENDING_ORDER_DIRECTION, DESCENDING_ORDER_DIRECTION } from '../../../../../shared/constants';
import { loadResourcesAction, selectResources } from '../../../../../shared/state/resources';
import { loadStatusesAction, selectStatuses } from '../../../../../shared/state/statuses';
import { clearCurrentRankingAction, RankingDTO, setRankingAction } from '../../RankingProfile/RankingInfo';
import { loadRankingsAction } from '../rankings.actions';
import { selectRankingsPageConfiguration, selectRankingsTableData } from '../rankings.selectors';
import RankingForm from './RankingForm';
import RankingsFiltersAndSearch from './RankingsFiltersAndSearch';
import { RANKINGS_TABLE_COLUMN_DEFINITION } from './RankingsList.constants';

let modalInterval: number;

const RankingsList: FunctionComponent = () => {
  const dispatch = useDispatch();
  const pageConfig = useSelector(selectRankingsPageConfiguration);
  const rankingsData = useSelector(selectRankingsTableData);
  const [isRankingEdit, setIsRankingEdit] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  const rankingStatuses = useSelector(selectStatuses('ranking'));
  const rankingTypes = useSelector(selectResources('ranking-types'));
  const rankingYears = useSelector(selectResources('rankings/publication-years'));

  useEffect(() => {
    dispatch(loadRankingsAction());
    dispatch(loadResourcesAction('ranking-types'));
    dispatch(loadResourcesAction('rankings/publication-years'));
    dispatch(loadStatusesAction('ranking'));
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearCurrentRankingAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFormModalOpen) {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rankingsData]);

  const handlePageChanged = (page: number): void => {
    dispatch(loadRankingsAction({ ...pageConfig, page }));
  };

  const handleSortByChanged = (fieldName: string): void => {
    const orderDirection =
      pageConfig.sortBy !== fieldName
        ? DESCENDING_ORDER_DIRECTION
        : pageConfig.order === ASCENDING_ORDER_DIRECTION
        ? DESCENDING_ORDER_DIRECTION
        : ASCENDING_ORDER_DIRECTION;
    dispatch(loadRankingsAction({ ...pageConfig, sortBy: fieldName, order: orderDirection }));
  };

  const openFormModal = (id?: number): void => {
    if (id) {
      const currentRanking = rankingsData.find((ranking: RankingDTO) => ranking.id === id);
      if (currentRanking) {
        if (modalInterval) {
          clearInterval(modalInterval);
        }

        dispatch(setRankingAction(currentRanking));
        setIsRankingEdit(true);
      }
    } else {
      setIsRankingEdit(false);
    }

    setIsFormModalOpen(true);
  };

  const closeModal = (): void => {
    setIsFormModalOpen(false);

    modalInterval = window.setTimeout(() => {
      dispatch(clearCurrentRankingAction());
    }, MODAL_ANIMATION_DURATION);
  };

  const rankingsTableConfig = [
    ...RANKINGS_TABLE_COLUMN_DEFINITION,
    {
      id: 'actions',
      title: 'Action',
      type: TableHeaderColumnTypes.actions,
      width: '90px',
      actions: [
        {
          label: 'Edit',
          handleOnClick: openFormModal,
        },
      ],
    },
  ];

  return (
    <>
      <div className="mba-heading--wrapper">
        <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title">
          Rankings
        </Typography>
        <Button
          text="Create new ranking"
          size={ButtonSizes.big}
          icon={ButtonIcons.plus}
          disabled={isFormModalOpen}
          primary
          onClick={(): void => openFormModal()}
        />
      </div>
      <div className="mba-table-wrapper">
        <div className="mba-table-filters">
          <RankingsFiltersAndSearch
            pageConfig={pageConfig}
            filtersData={{ rankingStatuses, rankingYears, rankingTypes }}
          />
        </div>
        <div className="mba-table-container">
          <Table
            id="rankings-list"
            columns={rankingsTableConfig}
            data={rankingsData}
            sortingOptions={{
              order: pageConfig.order || DESCENDING_ORDER_DIRECTION,
              sortBy: pageConfig.sortBy || '',
            }}
            onSortByColumn={handleSortByChanged}
          />
        </div>
      </div>
      {pageConfig && pageConfig.totalPages !== INITIAL_PAGE && (
        <Pager
          currentPage={pageConfig.page}
          totalPages={pageConfig.totalPages || INITIAL_PAGE}
          onPageChange={handlePageChanged}
        />
      )}

      {isFormModalOpen && (
        <Modal title={`${isRankingEdit ? 'Edit' : 'Create new'} ranking`} isOpen={isFormModalOpen} onClose={closeModal}>
          <RankingForm onFormClose={closeModal} isEdit={isRankingEdit} />
        </Modal>
      )}
    </>
  );
};

export default RankingsList;

import { combineReducers } from 'redux';

import { adminReducer } from '../containers/Admin';
import { authReducer } from '../containers/App/Auth';
import { commercialReducer } from '../containers/Commercial';
import { rankingsReducer } from '../containers/Rankings';
import { schoolsReducer } from '../containers/Schools';
import { attachmentsReducer } from '../shared/state/attachments';
import { currentRequestReducer } from '../shared/state/global-request';
import { rankingTypesDropdownReducer } from '../shared/state/ranking-types-dropdown';
import { resourcesReducer } from '../shared/state/resources';
import { rolesReducer } from '../shared/state/roles';
import { schoolResourcesReducer } from '../shared/state/school-resources';
import { statusesReducer } from '../shared/state/statuses';
import { settingsReducer } from '../shared/state/settings';

export const rootReducer = combineReducers({
  admin: adminReducer,
  auth: authReducer,
  commercial: commercialReducer,
  rankings: rankingsReducer,
  schools: schoolsReducer,
  attachments: attachmentsReducer,
  currentRequest: currentRequestReducer,
  rankingTypesDropdown: rankingTypesDropdownReducer,
  resources: resourcesReducer,
  roles: rolesReducer,
  schoolResources: schoolResourcesReducer,
  statuses: statusesReducer,
  settings: settingsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RANKING_TYPE_CREATE_FORM_CONFIG, RANKING_TYPE_EDIT_FORM_CONFIG } from '../RankingTypes.constants';
import {
  CREATE_RANKING_TYPE,
  createNewRankingTypeAction,
  CreateRankingTypeDTO,
  EDIT_RANKING_TYPE,
  editRankingTypeAction,
  EditRankingTypeDTO,
  selectCurrentRankingType,
} from '../../../RankingTypeProfile';
import Form, { FormConfig } from '../../../../../../components/Form';
import { selectAreRequestsLoading, selectRequestErrors } from '../../../../../../shared/state/global-request';
import { selectResources } from '../../../../../../shared/state/resources';

type RankingTypeFormProps = {
  isEdit?: boolean;
  onFormClose: () => void;
};

const RankingTypeForm: FunctionComponent<RankingTypeFormProps> = ({ onFormClose, isEdit = false }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectAreRequestsLoading([CREATE_RANKING_TYPE, EDIT_RANKING_TYPE]));
  const currentRankingType = useSelector(selectCurrentRankingType);
  const formErrors = useSelector(selectRequestErrors(isEdit ? EDIT_RANKING_TYPE : CREATE_RANKING_TYPE));
  const rankingCategories = useSelector(selectResources('categories'));

  const onRankingTypeSaveSubmit = (data: CreateRankingTypeDTO | EditRankingTypeDTO): void => {
    if (isEdit) {
      const { id } = currentRankingType as EditRankingTypeDTO;
      dispatch(editRankingTypeAction({ ...data, id }));
    } else {
      dispatch(createNewRankingTypeAction({ ...data } as CreateRankingTypeDTO));
    }
  };

  const defaultFormConfig: FormConfig = {
    ...RANKING_TYPE_CREATE_FORM_CONFIG,
    categoryId: {
      ...RANKING_TYPE_EDIT_FORM_CONFIG.categoryId,
      options: rankingCategories.map(({ key, label }) => ({ label, value: key })),
    },
  };

  const rankingInitialData = isEdit
    ? {
        ...currentRankingType,
        rankingTypeId: currentRankingType.categoryId,
      }
    : { ...currentRankingType };

  return (
    <Form
      id="ranking-type-form"
      isLoading={isLoading}
      config={defaultFormConfig}
      onSubmit={onRankingTypeSaveSubmit}
      submitButtonText={isEdit ? 'Save' : 'Create ranking type'}
      actions={[
        {
          label: 'Cancel',
          onClick: onFormClose,
        },
      ]}
      initialValues={rankingInitialData}
      errors={formErrors}
    />
  );
};

export default RankingTypeForm;

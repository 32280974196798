import { FiltersConfig } from '../../../../../components/FilterGroup';

export const REGISTRATIONS_FILTERS: FiltersConfig = {
  role: {
    title: 'Role',
    options: [],
    isDropdown: true,
  },
};

export const REGISTRATIONS_ROLE_FILTER_OPTIONS = [
  {
    key: 1,
    label: 'Prospective student',
  },
  {
    key: 2,
    label: 'Business school staff',
  },
  {
    key: 3,
    label: 'Alumni',
  },
  {
    key: 4,
    label: 'Other',
  },
];

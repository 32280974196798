import { ContentBlock, ContentState, convertFromHTML, convertFromRaw, RawDraftContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

export function getBlockStyle(block: ContentBlock): string {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    default:
      return '';
  }
}

export function findLinkEntities(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
): void {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
}

export function getHtmlFromJSON(content: string): string {
  // Added backwards compatibility, prior to draft-js
  try {
    const contentState = convertFromRaw(JSON.parse(content) as unknown as RawDraftContentState);
    // It's already a JSON, meaning the new format. Handle it.
    return contentState.hasText() ? stateToHTML(contentState) : '-';
  } catch (e: any) {
    // Otherwise, it means it's the old plain format, prior to draft-js
    return content;
  }
}

export function getRawFromHTML(content?: string): RawDraftContentState {
  // Added backwards compatibility, prior to draft-js
  let result;
  try {
    JSON.parse(content || '');
    // It's already a JSON, meaning the new format. Pass it directly
    result = content;
  } catch (e: any) {
    // Otherwise, it means it's the old plain format, prior to draft-js. Handle it.
    const { contentBlocks: blocks, ...rawObj } = convertFromHTML(content || '');
    result = JSON.stringify({ ...rawObj, blocks });
  }

  return result as unknown as RawDraftContentState;
}

import { RootState } from '../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const selectStatuses = (statusType: string) =>
  createDeepEqualSelector(
    (state: RootState) => state.statuses,
    (statuses) => {
      return statuses.hasOwnProperty(statusType) ? statuses[statusType] : [];
    },
  );

import { TableListData } from '../../constants';
import { getTableListDefaultState } from '../../helpers';
import { LeadDTO } from './lead.dto';
import { LeadGenTypes, LOAD_SCHOOL_LEADS, SET_SCHOOL_LEADS } from './leads.types';

const initialState: TableListData<LeadDTO> = getTableListDefaultState<LeadDTO>();

const schoolLeadsReducer = (state = initialState, action: LeadGenTypes): TableListData<LeadDTO> => {
  switch (action.type) {
    case LOAD_SCHOOL_LEADS: {
      return {
        ...state,
        filter: action.payload.pageConfig?.filter || {},
        search: action.payload.pageConfig?.search || '',
      };
    }
    case SET_SCHOOL_LEADS: {
      return {
        ...state,
        ...action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default schoolLeadsReducer;

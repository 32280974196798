import {
  LOAD_RESOURCES,
  LoadResourcesAction,
  ResourceQueryProps,
  SET_RESOURCES,
  SetResourcesAction,
} from './resources.types';
import { Resource } from '../../constants';

export const loadResourcesAction = (
  resourceName: string,
  queryProps?: ResourceQueryProps,
  isForRegistrationAPI?: boolean,
): LoadResourcesAction => ({
  type: LOAD_RESOURCES,
  payload: { queryProps, resourceName, isForRegistrationAPI },
});

export const setResourcesAction = (resourceName: string, data: Resource[]): SetResourcesAction => ({
  type: SET_RESOURCES,
  payload: { data, resourceName },
});

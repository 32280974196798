import { AddSchoolsToRankingDTO, RankingSchoolDTO, UpdateParticipationStatusDTO } from './ranking-schools.dto';
import { PageSearchQueryConfig, TableListData } from '../../../../../shared/constants';

export const LOAD_RANKING_SCHOOLS = 'LOAD_RANKING_SCHOOLS';
export const SET_RANKING_SCHOOLS = 'SET_RANKING_SCHOOLS';
export const DOWNLOAD_PARTICIPATION_SPREADSHEET = 'DOWNLOAD_PARTICIPATION_SPREADSHEET';
export const ADD_SCHOOLS_TO_RANKING = 'ADD_SCHOOLS_TO_RANKING';
export const IMPORT_SCHOOLS_TO_RANKING = 'IMPORT_SCHOOLS_TO_RANKING';
export const UPDATE_PARTICIPATION_STATUS = 'UPDATE_PARTICIPATION_STATUS';

export interface LoadRankingSchoolsAction {
  type: typeof LOAD_RANKING_SCHOOLS;
  payload: {
    rankingId: number;
    pageConfig?: PageSearchQueryConfig;
  };
}

export interface SetRankingSchoolsAction {
  type: typeof SET_RANKING_SCHOOLS;
  payload: { tableData: TableListData<RankingSchoolDTO> };
}

export interface DownloadParticipationSpreadsheetAction {
  type: typeof DOWNLOAD_PARTICIPATION_SPREADSHEET;
  payload: {
    rankingId: number;
    pageConfig?: PageSearchQueryConfig;
  };
}

export interface AddSchoolsToRankingAction {
  type: typeof ADD_SCHOOLS_TO_RANKING;
  payload: {
    data: AddSchoolsToRankingDTO;
    rankingId: number;
  };
}

export interface ImportSchoolsToRankingAction {
  type: typeof IMPORT_SCHOOLS_TO_RANKING;
  payload: {
    rankingId: number;
  };
}

export interface UpdateParticipationStatusAction {
  type: typeof UPDATE_PARTICIPATION_STATUS;
  payload: {
    rankingId: number;
    schoolId: number;
    data: UpdateParticipationStatusDTO;
  };
}

export type RankingSchoolsActionTypes =
  | LoadRankingSchoolsAction
  | SetRankingSchoolsAction
  | DownloadParticipationSpreadsheetAction
  | AddSchoolsToRankingAction
  | ImportSchoolsToRankingAction
  | UpdateParticipationStatusAction;

import { call, CallEffect, put, PutEffect, SelectEffect, takeLatest } from 'redux-saga/effects';

import { BasicResponseDTO, DefaultResponseDTO } from '../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../shared/state/global-request';
import { SchoolWidgetGroupDto } from '../SchoolWidgetGroups/school-widget-groups.dto';
import { WidgetDTO } from './components/EditableWidget/EditableWidget.constants';
import {
  addWidgetToListAction,
  deleteWidgetFromListAction,
  loadSchoolWidgetsAction,
  setSchoolWidgetGroupAction,
  setSchoolWidgetsAction,
} from './school-widget-details.actions';
import {
  createSchoolRankingWidgetRequest,
  deleteSchoolRankingWidgetRequest,
  getSchoolRankingWidgetsRequest,
  getSchoolWidgetGroupRequest,
  updateSchoolRankingWidgetRequest,
  updateSchoolWidgetOrderRequest,
} from './school-widget-details.api';
import {
  CREATE_SCHOOL_WIDGET,
  CreateSchoolWidgetAction,
  DELETE_SCHOOL_WIDGET,
  DeleteSchoolWidgetAction,
  LOAD_SCHOOL_WIDGET_GROUP,
  LOAD_SCHOOL_WIDGETS,
  LoadSchoolWidgetGroupAction,
  LoadSchoolWidgetsAction,
  SchoolWidgetDetailsActionTypes,
  UPDATE_SCHOOL_WIDGET,
  UPDATE_SCHOOL_WIDGET_ORDER,
  UpdateSchoolWidgetAction,
  UpdateSchoolWidgetOrderAction,
} from './school-widget-details.types';

function* loadSchoolWidgetsData(
  action: LoadSchoolWidgetsAction,
): Generator<
  | CallEffect<DefaultResponseDTO<WidgetDTO[]>>
  | PutEffect<SchoolWidgetDetailsActionTypes>
  | PutEffect<GlobalRequestActions>
> {
  const {
    type: actionType,
    payload: { schoolWidgetGroupId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getSchoolRankingWidgetsRequest, schoolWidgetGroupId)) as DefaultResponseDTO<
      WidgetDTO[]
    >;
    yield put(setSchoolWidgetsAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* updateSchoolWidgetSaga(
  action: UpdateSchoolWidgetAction,
): Generator<
  PutEffect<GlobalRequestActions | SchoolWidgetDetailsActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect
> {
  const { type: actionType, payload: widget } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateSchoolRankingWidgetRequest, widget);
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* updateSchoolWidgetOrderSaga(
  action: UpdateSchoolWidgetOrderAction,
): Generator<
  PutEffect<GlobalRequestActions | SchoolWidgetDetailsActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect
> {
  const {
    type: actionType,
    payload: { schoolWidgetGroupId, widgets },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateSchoolWidgetOrderRequest, schoolWidgetGroupId, widgets);
    yield put(loadSchoolWidgetsAction(schoolWidgetGroupId));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* createSchoolWidgetSaga(
  action: CreateSchoolWidgetAction,
): Generator<
  PutEffect<GlobalRequestActions | SchoolWidgetDetailsActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect
> {
  const { type: actionType, payload: widget } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(createSchoolRankingWidgetRequest, widget)) as DefaultResponseDTO<WidgetDTO>;
    yield put(addWidgetToListAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* deleteSchoolWidgetSaga(
  action: DeleteSchoolWidgetAction,
): Generator<
  PutEffect<GlobalRequestActions | SchoolWidgetDetailsActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect
> {
  const { type: actionType, payload: id } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(deleteSchoolRankingWidgetRequest, id);
    yield put(deleteWidgetFromListAction(id));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* loadSchoolWidgetGroupData(
  action: LoadSchoolWidgetGroupAction,
): Generator<
  | CallEffect<DefaultResponseDTO<SchoolWidgetGroupDto>>
  | PutEffect<SchoolWidgetDetailsActionTypes>
  | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { id } = action.payload;
    const { data } = (yield call(getSchoolWidgetGroupRequest, id)) as DefaultResponseDTO<SchoolWidgetGroupDto>;
    yield put(setSchoolWidgetGroupAction(data || {}));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* schoolWidgetsSaga(): Generator {
  yield takeLatest(LOAD_SCHOOL_WIDGETS, loadSchoolWidgetsData);
  yield takeLatest(UPDATE_SCHOOL_WIDGET, updateSchoolWidgetSaga);
  yield takeLatest(UPDATE_SCHOOL_WIDGET_ORDER, updateSchoolWidgetOrderSaga);
  yield takeLatest(CREATE_SCHOOL_WIDGET, createSchoolWidgetSaga);
  yield takeLatest(DELETE_SCHOOL_WIDGET, deleteSchoolWidgetSaga);
  yield takeLatest(LOAD_SCHOOL_WIDGET_GROUP, loadSchoolWidgetGroupData);
}

import { isEqual } from 'lodash';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { RootState } from '../../../store/reducers';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const onsiteMessagingSelector = (state: RootState) => state.admin.onsiteMessaging;

export const selectMessageHistory = createDeepEqualSelector(onsiteMessagingSelector, (onsiteMessaging) => {
  return onsiteMessaging.messageHistory.data;
});

export const selectLatestMessage = createDeepEqualSelector(onsiteMessagingSelector, (onsiteMessaging) => {
  return onsiteMessaging.latestMessage;
});

export const selectMessageHistoryPageConfiguration = createDeepEqualSelector(
  onsiteMessagingSelector,
  (onsiteMessaging) => {
    return {
      filter: onsiteMessaging.messageHistory.filter,
      limit: onsiteMessaging.messageHistory.pagination.limit,
      order: onsiteMessaging.messageHistory.sortOrder.order,
      page: onsiteMessaging.messageHistory.pagination.page,
      search: onsiteMessaging.messageHistory.search,
      sortBy: onsiteMessaging.messageHistory.sortOrder.sortBy,
      totalPages: onsiteMessaging.messageHistory.pagination.totalPages,
    };
  },
);

import {
  UpdateSchoolWithLeadDetailsAction,
  UPDATE_SCHOOL_WITH_LEAD_DETAILS,
  UPDATE_SCHOOL_LEAD_ACTIVE,
  UpdateSchoolLeadActiveAction,
} from './school-with-lead-details.types';
import { UpdateSchoolWithLeadDetailsDto } from './SchoolWithLeadDetails.constants';

export const updateSchoolWithLeadDetailsAction = (
  id: number,
  updatedData: UpdateSchoolWithLeadDetailsDto,
): UpdateSchoolWithLeadDetailsAction => ({
  type: UPDATE_SCHOOL_WITH_LEAD_DETAILS,
  payload: { id, updatedData },
});

export const updateSchoolActiveAction = (id: number, hasLeadGen: boolean): UpdateSchoolLeadActiveAction => ({
  type: UPDATE_SCHOOL_LEAD_ACTIVE,
  payload: { id, hasLeadGen },
});

import { FormConfig, FormControlFieldType } from '../../../../../../components/Form';
import { ColumnDefinition, TableHeaderColumnTypes } from '../../../../../../components/Table';

export const RANKING_STATUS_DRAFT_KEY = 2;
export const PARTICIPATION_STATUS_REJECTED_KEY = 4;
export const PARTICIPATION_STATUS_PENDING_KEY = 3;

export enum STATUS {
  ACTIVE = 1,
  DRAFT = 2,
  ARCHIVED = 3,
  PUBLISHED = 4,
  SCHEDULED = 5,
}

export const RANKING_SCHOOLS_TABLE_COLUMN_DEFINITION: ColumnDefinition[] = [
  {
    id: 'schoolId',
    title: 'ID',
    sorting: true,
    width: '70px',
  },
  {
    id: 'schoolName',
    sorting: true,
    title: 'School Name',
    type: TableHeaderColumnTypes.link,
    path: '/schools/profile/:id/details',
  },
  {
    id: 'updatedAt',
    sorting: true,
    title: 'Last updated',
    type: TableHeaderColumnTypes.date,
    width: '160px',
  },
  {
    id: 'participationStatus',
    title: 'Response',
    type: TableHeaderColumnTypes.status,
    sorting: true,
    width: '90px',
  },
];

export const ADD_SCHOOLS_TO_RANKING_FORM_CONFIG: FormConfig = {
  schoolIds: {
    label: 'Add schools to ranking',
    placeholder: `Select available schools to add to the ranking`,
    fieldType: 'select' as FormControlFieldType,
    multiple: true,
    searchable: true,
    options: [],
    validations: {
      required: {
        errorText: `Please select schools to add!`,
      },
    },
  },
};

import { CreateRankingTypeDTO, EditRankingTypeDTO, RankingTypeDTO } from './ranking-type-profile.dto';

export const CREATE_RANKING_TYPE = 'CREATE_RANKING_TYPE';
export const LOAD_RANKING_TYPE = 'LOAD_RANKING_TYPE';
export const EDIT_RANKING_TYPE = 'EDIT_RANKING_TYPE';
export const EDIT_RANKING_TYPE_DETAILS = 'EDIT_RANKING_TYPE_DETAILS';
export const DELETE_RANKING_TYPE = 'DELETE_RANKING_TYPE';
export const CLEAR_CURRENT_RANKING_TYPE = 'CLEAR_CURRENT_RANKING_TYPE';
export const SET_RANKING_TYPE = 'SET_RANKING_TYPE';

export type RankingTypeProfileState = CreateRankingTypeDTO | EditRankingTypeDTO | RankingTypeDTO;

export interface RankingTypeProfileRouteParams {
  id: string;
}

export interface CreateRankingTypeAction {
  type: typeof CREATE_RANKING_TYPE;
  payload: { data: CreateRankingTypeDTO };
}

export interface LoadRankingTypeAction {
  type: typeof LOAD_RANKING_TYPE;
  payload: { id: number };
}

export interface EditRankingTypeAction {
  type: typeof EDIT_RANKING_TYPE;
  payload: { data: EditRankingTypeDTO };
}

export interface EditRankingTypeDetailsAction {
  type: typeof EDIT_RANKING_TYPE_DETAILS;
  payload: { data: EditRankingTypeDTO };
}

export interface DeleteRankingTypeAction {
  type: typeof DELETE_RANKING_TYPE;
  payload: { id: number };
}

export interface ClearCurrentRankingTypeAction {
  type: typeof CLEAR_CURRENT_RANKING_TYPE;
}

export interface SetRankingTypeAction {
  type: typeof SET_RANKING_TYPE;
  payload: { data: RankingTypeDTO };
}

export type RankingTypeActionTypes =
  | ClearCurrentRankingTypeAction
  | CreateRankingTypeAction
  | DeleteRankingTypeAction
  | EditRankingTypeAction
  | EditRankingTypeDetailsAction
  | LoadRankingTypeAction
  | SetRankingTypeAction;

import { RootState } from '../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';
import { SchoolsWithLeadGenDTO } from './schools-with-lead-gen.dto';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const schoolsWithLeadGenSelector = (state: RootState) => state.commercial.schoolsWithLeadGen;

export const selectSchoolsWithLeadGen = createDeepEqualSelector(schoolsWithLeadGenSelector, (schoolsWithLeadGen) => {
  return schoolsWithLeadGen.data;
});

export const selectSchoolById = createDeepEqualSelector(
  [(state: RootState) => state.commercial.schoolsWithLeadGen.data, (state: RootState, schoolId: string) => schoolId],
  (data: SchoolsWithLeadGenDTO[], schoolId: string) => {
    return data.find((school) => school.id.toString() === schoolId);
  },
);

export const selectSchoolWithLeadGenPageConfiguration = createDeepEqualSelector(
  schoolsWithLeadGenSelector,
  (schoolsWithLeadGen) => {
    return {
      filter: schoolsWithLeadGen.filter,
      limit: schoolsWithLeadGen.pagination.limit,
      order: schoolsWithLeadGen.sortOrder.order,
      page: schoolsWithLeadGen.pagination.page,
      search: schoolsWithLeadGen.search,
      sortBy: schoolsWithLeadGen.sortOrder.sortBy,
      totalPages: schoolsWithLeadGen.pagination.totalPages,
    };
  },
);

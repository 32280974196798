import { SchoolUserDTO } from '../SchoolUserProfile';
import { get } from '../../../shared/api';
import { BasicResponseDTO, PagedResponseDTO, PageSearchQueryConfig } from '../../../shared/constants';
import { generateSearchQuery } from '../../../shared/helpers';

export const getSchoolAccountsRequest = (
  pageConfig: PageSearchQueryConfig = {},
): Promise<PagedResponseDTO<SchoolUserDTO[]>> => {
  const query = generateSearchQuery(pageConfig);
  return get<PagedResponseDTO<SchoolUserDTO[]>>(`/school-users${query}`);
};

export const downloadSchoolAccountsSpreadsheetRequest = (
  pageConfig: PageSearchQueryConfig = {},
): Promise<BasicResponseDTO> => {
  const query = generateSearchQuery(pageConfig);
  return get<BasicResponseDTO>(`/school-users/spreadsheets${query}`, {
    responseType: 'blob',
  });
};

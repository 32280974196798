import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IMPORT_SCHOOL_USERS_FORM_CONFIG } from '../SchoolUsers.constants';
import { importSchoolUsersAction } from '../../school-users.actions';
import { ImportSchoolUsersDTO } from '../../school-users.dto';
import { IMPORT_SCHOOL_USERS } from '../../school-users.types';
import { SchoolDTO, selectCurrentSchool } from '../../../SchoolDetails';
import Form, { FormConfig } from '../../../../../../components/Form';
import { Resource } from '../../../../../../shared/constants';
import { selectAreRequestsLoading, selectRequestFormErrors } from '../../../../../../shared/state/global-request';

type ImportSchoolUsersFormProps = {
  onFormClose: () => void;
  importUsersList: Resource[];
};

const ImportSchoolUsersForm: FunctionComponent<ImportSchoolUsersFormProps> = ({
  onFormClose,
  importUsersList = [],
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectAreRequestsLoading([IMPORT_SCHOOL_USERS]));
  const currentSchool = useSelector(selectCurrentSchool) as SchoolDTO;
  const formErrors = useSelector(selectRequestFormErrors(IMPORT_SCHOOL_USERS));
  const schoolId = currentSchool.id;

  const onImportSchoolUsersSubmit = (data: ImportSchoolUsersDTO): void => {
    dispatch(importSchoolUsersAction(data as ImportSchoolUsersDTO, schoolId));
  };

  const defaultFormConfig: FormConfig = {
    userIds: {
      ...IMPORT_SCHOOL_USERS_FORM_CONFIG.userIds,
      options: importUsersList.map((userData) => ({
        label: userData.label,
        value: userData.key,
      })),
    },
  };

  return (
    <Form
      id="import-school-user-form"
      isLoading={isLoading}
      config={defaultFormConfig}
      onSubmit={onImportSchoolUsersSubmit}
      submitButtonText="Import school users"
      actions={[
        {
          label: 'Cancel',
          onClick: onFormClose,
        },
      ]}
      initialValues={[]}
      errors={formErrors}
    />
  );
};

export default ImportSchoolUsersForm;

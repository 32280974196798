import React, { FunctionComponent } from 'react';

import { renderFormGroupActionButton } from './index';
import { ActionButtonHandler, FormConfigProps, FormValue } from '../Form.constants';
import { generateFormFields } from '../Form.helpers';
import { FORM_GROUP_TITLE } from '../../../containers/Schools/SchoolsList/components/SchoolsList.constants';

type FormGroupProps = {
  actionButtonHandlers: { onClickRemove: ActionButtonHandler; onClickAdd: ActionButtonHandler };
  controls: FormConfigProps;
  groupName: string;
  inputChangedHandler: (name: string, value: FormValue) => void;
  shouldDisplayRemoveButtons?: boolean;
  shouldDisplayAddGroupButton?: boolean;
  addGroupButtonText?: string;
};

const FormGroup: FunctionComponent<FormGroupProps> = ({
  controls,
  groupName,
  inputChangedHandler,
  actionButtonHandlers,
  shouldDisplayRemoveButtons = true,
  shouldDisplayAddGroupButton = true,
  addGroupButtonText,
}) => {
  let currentIndex: number;
  const groupFields = Object.keys(controls);
  if (groupFields.length > 0 && Object.keys(controls[groupFields[0]]).length > 1) {
    currentIndex = groupFields.length;
  } else {
    currentIndex = 0;
  }

  const onClickAdd = (): void => actionButtonHandlers.onClickAdd(groupName, currentIndex);

  return (
    <div key={groupName} className="form-group-wrapper">
      {Object.entries(controls).map(([index, formGroup]) => {
        const indexNr = parseInt(index);
        const onClickRemove = (): void => actionButtonHandlers.onClickRemove(groupName, indexNr);

        const removable: boolean = Object.values(formGroup).find(
          (fieldValue) => fieldValue.filedType !== 'formGroupTitle',
        )?.hideRemoveButton;
        const shouldDisplayRemoveButton =
          Object.keys(formGroup).filter((fieldKey) => fieldKey !== FORM_GROUP_TITLE).length > 0 && removable;

        return (
          <div key={`${groupName}-${indexNr}`} className="form-group">
            {generateFormFields(
              formGroup as FormConfigProps,
              inputChangedHandler,
              groupName,
              indexNr,
              actionButtonHandlers,
            )}
            {shouldDisplayRemoveButton &&
              shouldDisplayRemoveButtons &&
              renderFormGroupActionButton({ label: '- Remove Account', onClick: onClickRemove })}
          </div>
        );
      })}
      {shouldDisplayAddGroupButton &&
        renderFormGroupActionButton({ label: addGroupButtonText || '+ Add Account', onClick: onClickAdd })}
    </div>
  );
};

export default FormGroup;

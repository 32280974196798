import { RankingTypeDTO } from '../RankingTypeProfile';
import { PageSearchQueryConfig, TableListData } from '../../../../shared/constants';

export const LOAD_RANKING_TYPES = 'LOAD_RANKING_TYPES';
export const SET_RANKING_TYPES = 'SET_RANKING_TYPES';

export interface LoadRankingTypesAction {
  type: typeof LOAD_RANKING_TYPES;
  payload: { pageConfig?: PageSearchQueryConfig };
}

export interface SetRankingTypesAction {
  type: typeof SET_RANKING_TYPES;
  payload: { tableData: TableListData<RankingTypeDTO> };
}

export type RankingTypesActionTypes = LoadRankingTypesAction | SetRankingTypesAction;

import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import Button, { ButtonSizes } from '../../../../../../components/Button';
import Form from '../../../../../../components/Form';
import Grid from '../../../../../../components/Grid';
import { getHtmlFromJSON, getRawFromHTML } from '../../../../../../components/RichTextEditor/RichTextEditor.helpers';
import Typography, { TypographyVariants } from '../../../../../../components/Typography';
import { selectRequestIsLoading } from '../../../../../../shared/state/global-request';
import {
  EDIT_RANKING_DETAILS,
  editRankingDetailsAction,
  EditRankingDTO,
  EditWelcomeTextDTO,
  LOAD_RANKING_WELCOME_TEXT,
  loadRankingWelcomeTextAction,
  RankingDTO,
  RankingProfileRouteParams,
  selectCurrentRanking,
} from '../../RankingInfo';

const WelcomeText: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id: rankingId } = useParams<RankingProfileRouteParams>();
  const currentRanking = useSelector(selectCurrentRanking) as RankingDTO;
  const isLoading = useSelector(selectRequestIsLoading(EDIT_RANKING_DETAILS));
  const isPreviousLoading = useSelector(selectRequestIsLoading(LOAD_RANKING_WELCOME_TEXT));

  const [currentWelcomeText, setCurrentWelcomeText] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [isLoadFromPreviousActive, setIsLoadFromPreviousActive] = useState(false);
  const { welcomeText } = currentRanking;
  const welcomeTextContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (rankingId) {
      dispatch(loadRankingWelcomeTextAction(parseInt(rankingId)));
    }
  }, [dispatch, rankingId]);

  useEffect(() => {
    if (!isLoadFromPreviousActive) {
      if (isEdit) {
        setIsEdit(false);
      }

      setCurrentWelcomeText(currentRanking.welcomeText || '');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRanking, isLoadFromPreviousActive]);

  const handleEditSubmit = (data: EditWelcomeTextDTO): void => {
    setIsLoadFromPreviousActive(false);
    dispatch(
      editRankingDetailsAction({
        ...data,
        id: rankingId,
      } as unknown as EditRankingDTO),
    );
  };

  const handleLoadFromPreviousRanking = (): void => {
    dispatch(loadRankingWelcomeTextAction(parseInt(rankingId), true));
    setIsLoadFromPreviousActive(true);
  };

  const handleEditCancel = (): void => {
    dispatch(loadRankingWelcomeTextAction(parseInt(rankingId)));
    setIsEdit(false);
    setIsLoadFromPreviousActive(false);
  };

  const handleCopyToClipboard = (): void => {
    const windowSelection = window.getSelection();
    if (windowSelection && welcomeTextContainer.current) {
      windowSelection.removeAllRanges();
      const range = document.createRange();
      range.selectNode(welcomeTextContainer.current);
      windowSelection.addRange(range);
      document.execCommand('copy');
      windowSelection.removeAllRanges();
    }
  };

  const __html = currentWelcomeText ? getHtmlFromJSON(currentWelcomeText) : '-';

  return (
    <Grid container>
      <Grid item compact xs={12} md={11}>
        <div className="mba-heading--wrapper mba-heading--table">
          <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title">
            Welcome text
          </Typography>
          {isEdit ? (
            <Button
              primary
              text="Load from previous ranking"
              size={ButtonSizes.big}
              isLoading={isPreviousLoading}
              onClick={handleLoadFromPreviousRanking}
            />
          ) : (
            <div className="mba-actions">
              <Button text="Copy to clipboard" size={ButtonSizes.big} onClick={handleCopyToClipboard} />
              <Button text="Edit welcome text" size={ButtonSizes.big} primary onClick={(): void => setIsEdit(true)} />
            </div>
          )}
        </div>
        {isEdit ? (
          <Form
            id="welcome-text-form"
            config={{
              welcomeText: {
                fieldType: 'richText',
                placeholder: 'Fill in the welcome text',
              },
            }}
            isLoading={isLoading}
            onSubmit={handleEditSubmit}
            submitButtonText="Save"
            actions={[
              {
                label: 'Cancel',
                onClick: handleEditCancel,
              },
            ]}
            initialValues={{ welcomeText: getRawFromHTML(welcomeText) }}
            errors={{}}
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html }} ref={welcomeTextContainer} />
        )}
      </Grid>
    </Grid>
  );
};

export default WelcomeText;

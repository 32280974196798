import { RootState } from '../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';
import { LogsDTO } from './logs.dto';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const logsSelector = (state: RootState) => state.admin.logs;

export const selectLogsTableData = createDeepEqualSelector(logsSelector, (logs) => {
  return logs.data;
});

export const selectLogsPageConfiguration = createDeepEqualSelector(logsSelector, (logs) => {
  return {
    filter: logs.filter,
    limit: logs.pagination.limit,
    order: logs.sortOrder.order,
    page: logs.pagination.page,
    search: logs.search,
    sortBy: logs.sortOrder.sortBy,
    totalPages: logs.pagination.totalPages,
  };
});

import { RootState } from '../../../../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const rankingFieldsSelector = (state: RootState) => state.rankings.rankingFields;

export const selectRankingFieldsData = createDeepEqualSelector(rankingFieldsSelector, (rankingFields) => {
  return rankingFields.data;
});

export const selectRankingFieldsPageConfiguration = createDeepEqualSelector(rankingFieldsSelector, (rankingFields) => {
  return {
    filter: rankingFields.filter,
    limit: rankingFields.pagination.limit,
    order: rankingFields.sortOrder.order,
    page: rankingFields.pagination.page,
    search: rankingFields.search,
    sortBy: rankingFields.sortOrder.sortBy,
    totalPages: rankingFields.pagination.totalPages,
  };
});

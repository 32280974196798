import { FormConfig } from '../../../../../../../../../components/Form';
import { GROUP_OPTIONS } from '../../../../RankingData.constants';
import { RankingFieldType } from '../../../../RankingFieldsList';

export const RANKING_FIELD_DETAILS_CONFIG = (type: RankingFieldType): FormConfig => ({
  name: {
    fieldType: 'text',
    placeholder: 'Enter column name',
    required: true,
    title: 'Name',
    validations: {
      required: {
        errorText: `Please enter ${type} name!`,
      },
    },
  },
  type: {
    fieldType: 'select',
    placeholder: 'Choose a type',
    required: true,
    options: [
      { value: 'Column', label: 'Column' },
      { value: 'Filter', label: 'Filter' },
    ],
    title: 'Type',
    validations: {
      required: {
        errorText: `Please choose a type!`,
      },
    },
  },
  visibility: {
    fieldType: 'select',
    options: [
      { value: 'Default', label: 'Default' },
      { value: 'Featured', label: 'Featured' },
      { value: 'Not displayed', label: 'Not displayed' },
    ],
    required: true,
    placeholder: 'Enter visibility',
    title: 'Visibility',
    validations: {
      required: {
        errorText: `Please choose visibility!`,
      },
    },
  },
  shortName: {
    fieldType: 'text',
    placeholder: 'Enter short name',
    required: false,
    title: 'Short name',
  },
  group: {
    fieldType: 'select',
    options: GROUP_OPTIONS(type),
    required: true,
    placeholder: 'Select a group',
    title: 'Group',
    validations: {
      required: {
        errorText: `Please choose a group!`,
      },
    },
  },
});

export const RANKING_FIELD_DESCRIPTION_CONFIG: FormConfig = {
  description: {
    fieldType: 'textarea',
    rows: 10,
    placeholder: 'Enter description',
  },
};

export const DEFAULT_HOME_ROUTE = '/';

export interface PageConfiguration {
  exact?: boolean;
  label: string;
  route: string;
  navigation?: NavigationConfiguration;
}

export interface NavigationConfiguration {
  [key: string]: PageConfiguration;
}

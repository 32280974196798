import { AccreditationDTO, CreateAccreditationDTO, EditAccreditationDTO } from './accreditation-profile.dto';
import {
  CLEAR_CURRENT_ACCREDITATION,
  ClearCurrentAccreditationAction,
  CREATE_ACCREDITATION,
  CreateAccreditationAction,
  DELETE_ACCREDITATION,
  DeleteAccreditationAction,
  EDIT_ACCREDITATION,
  EditAccreditationAction,
  LOAD_ACCREDITATION,
  LoadAccreditationAction,
  SET_ACCREDITATION,
  SetAccreditationAction,
} from './accreditation-profile.types';

export const createNewAccreditationAction = (data: CreateAccreditationDTO): CreateAccreditationAction => ({
  type: CREATE_ACCREDITATION,
  payload: { data },
});

export const loadAccreditationAction = (id: number): LoadAccreditationAction => ({
  type: LOAD_ACCREDITATION,
  payload: { id },
});

export const editAccreditationAction = (data: EditAccreditationDTO): EditAccreditationAction => ({
  type: EDIT_ACCREDITATION,
  payload: { data },
});

export const deleteAccreditationAction = (id: number): DeleteAccreditationAction => ({
  type: DELETE_ACCREDITATION,
  payload: { id },
});

export const setAccreditationAction = (data: AccreditationDTO): SetAccreditationAction => ({
  type: SET_ACCREDITATION,
  payload: { data },
});

export const clearCurrentAccreditationAction = (): ClearCurrentAccreditationAction => ({
  type: CLEAR_CURRENT_ACCREDITATION,
});

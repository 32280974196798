import { FormConfig } from '../../../../components/Form';

export const EDIT_MESSAGE_FORM_CONFIG: FormConfig = {
  title: {
    title: 'Heading',
    placeholder: 'Enter message heading',
    fieldType: 'text',
    required: true,
    validations: {
      required: {
        errorText: 'Please enter a heading!',
      },
    },
  },
  buttonAction: {
    title: 'Button URL',
    placeholder: 'Enter button URL',
    fieldType: 'text',
    required: true,
    validations: {
      required: {
        errorText: 'Please enter a button URL!',
      },
    },
  },
  startDate: {
    title: 'Start date',
    placeholder: 'Enter start date',
    fieldType: 'date',
    required: true,
    showTimeSelect: true,
    timeIntervals: 60,
    customDateFormat: 'dd MMM yyyy HH:mm',
    validations: {
      required: {
        errorText: 'Please enter a start date!',
      },
    },
  },
  content: {
    title: 'Text',
    placeholder: 'Enter text message',
    fieldType: 'textarea',
    required: true,
    validations: {
      required: {
        errorText: 'Please enter a text!',
      },
    },
  },
  linkName: {
    title: 'Secondary button name',
    placeholder: 'Enter button name',
    fieldType: 'text',
  },
  endDate: {
    title: 'End date',
    placeholder: 'Enter end date',
    fieldType: 'date',
    required: true,
    showTimeSelect: true,
    timeIntervals: 60,
    customDateFormat: 'dd MMM yyyy HH:mm',
    validations: {
      required: {
        errorText: 'Please enter an end date!',
      },
    },
  },
  buttonName: {
    title: 'Button Name',
    placeholder: 'Enter button name',
    fieldType: 'text',
    required: true,
    validations: {
      required: {
        errorText: 'Please enter a button name!',
      },
    },
  },
  linkAction: {
    title: 'Secondary button URL',
    placeholder: 'Enter button URL',
    fieldType: 'text',
  },
};

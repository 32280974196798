import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import PartnershipForm from './PartnershipForm';
import PartnershipRemoveConfirmation from './PartnershipRemoveConfirmation';
import {
  PARTNER_SCHOOLS_TABLE_COLUMN_DEFINITION,
  PARTNERSHIPS_TABLE_COLUMN_DEFINITION,
} from './PartnershipsList.constants';
import {
  clearCurrentPartnershipAction,
  loadPartnerSchoolsAction,
  loadPartnershipsAction,
  removePartnershipAction,
} from '../partnerships.actions';
import { selectPartnershipsListPageConfiguration, selectPartnershipsTableData } from '../partnerships.selectors';
import { SchoolDTO, SchoolProfileRouteParams, selectCurrentSchool } from '../../SchoolDetails';
import { SCHOOL_TYPES } from '../../../SchoolsList/components/SchoolsList.constants';
import Button, { ButtonIcons, ButtonSizes } from '../../../../../components/Button';
import Modal, { MODAL_ANIMATION_DURATION } from '../../../../../components/Modal';
import Pager, { INITIAL_PAGE } from '../../../../../components/Pager';
import Table, { TableHeaderColumnTypes } from '../../../../../components/Table';
import Typography, { TypographyVariants } from '../../../../../components/Typography';
import { ASCENDING_ORDER_DIRECTION, DESCENDING_ORDER_DIRECTION } from '../../../../../shared/constants';
import { loadResourcesAction } from '../../../../../shared/state/resources';

const PartnershipsList: FunctionComponent = () => {
  const dispatch = useDispatch();
  const pageConfig = useSelector(selectPartnershipsListPageConfiguration);
  const partnershipsData = useSelector(selectPartnershipsTableData);
  const { id: schoolId } = useParams<SchoolProfileRouteParams>();
  const { id, type } = useSelector(selectCurrentSchool) as SchoolDTO;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [partnershipToDeleteId, setPartnershipToDeleteId] = useState(0);

  const tableData = partnershipsData.map(({ id, name, schools }) => ({ id, name, schools: schools?.join(', ') }));

  useEffect(() => {
    if (id && type) {
      if (type.key?.toString() === SCHOOL_TYPES.SINGLE) {
        dispatch(loadPartnershipsAction(id));
        dispatch(loadResourcesAction('schools', { name: 'type', value: SCHOOL_TYPES.JOINED }));
      } else if (type.key?.toString() === SCHOOL_TYPES.JOINED) {
        dispatch(loadPartnerSchoolsAction(id));
        dispatch(loadResourcesAction('schools', { name: 'type', value: SCHOOL_TYPES.SINGLE }));
      }
    }
  }, [dispatch, id, type]);

  const openModal = (): void => {
    setIsModalOpen(true);
  };

  const openRemovePartnershipModal = (id: number): void => {
    setPartnershipToDeleteId(id);
    setIsConfirmModalOpen(true);
  };

  const closeModal = (): void => {
    setIsModalOpen(false);
    setIsConfirmModalOpen(false);
    setPartnershipToDeleteId(0);

    setTimeout(() => {
      dispatch(clearCurrentPartnershipAction());
    }, MODAL_ANIMATION_DURATION);
  };

  useEffect(() => {
    if (isModalOpen || isConfirmModalOpen) {
      closeModal();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnershipsData]);

  const partnershipsTableConfig = [
    ...(type?.key?.toString() === SCHOOL_TYPES.SINGLE
      ? PARTNERSHIPS_TABLE_COLUMN_DEFINITION
      : PARTNER_SCHOOLS_TABLE_COLUMN_DEFINITION),
    {
      id: 'actions',
      title: 'Action',
      type: TableHeaderColumnTypes.actions,
      width: '90px',
      actions: [
        {
          label: 'Remove',
          handleOnClick: openRemovePartnershipModal,
        },
      ],
    },
  ];

  const handleRemovePartnership = (): void => {
    if (schoolId) {
      dispatch(removePartnershipAction(id, partnershipToDeleteId, id, type.key.toString() as SCHOOL_TYPES));
    }
  };

  // This is allowed only for single schools
  const handleSortByChanged = (fieldName: string): void => {
    const orderDirection =
      pageConfig.sortBy !== fieldName
        ? DESCENDING_ORDER_DIRECTION
        : pageConfig.order === ASCENDING_ORDER_DIRECTION
        ? DESCENDING_ORDER_DIRECTION
        : ASCENDING_ORDER_DIRECTION;
    dispatch(loadPartnershipsAction(id, { ...pageConfig, sortBy: fieldName, order: orderDirection }));
  };

  // This is allowed only for single schools
  const handlePageChanged = (page: number): void => {
    dispatch(loadPartnershipsAction(id, { ...pageConfig, page }));
  };

  return (
    <>
      <div className="mba-heading--wrapper mba-heading--table">
        <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title-2">
          {type.key?.toString() === SCHOOL_TYPES.SINGLE ? 'Partnerships' : 'Partner schools'}
        </Typography>
        <Button text="Add partnership" size={ButtonSizes.big} icon={ButtonIcons.plus} primary onClick={openModal} />
      </div>
      <div className="mba-table-wrapper">
        <Table
          id="partnerships-list"
          columns={partnershipsTableConfig}
          data={tableData}
          sortingOptions={{
            order: pageConfig.order || DESCENDING_ORDER_DIRECTION,
            sortBy: pageConfig.sortBy || '',
          }}
          onSortByColumn={handleSortByChanged}
        />
      </div>
      {pageConfig && pageConfig.totalPages !== INITIAL_PAGE && (
        <Pager
          currentPage={pageConfig.page}
          totalPages={pageConfig.totalPages || INITIAL_PAGE}
          onPageChange={handlePageChanged}
        />
      )}
      <Modal title="Add school to partnership" isOpen={isModalOpen} onClose={closeModal}>
        <PartnershipForm schoolId={id} type={type.key.toString() as SCHOOL_TYPES} onFormClose={closeModal} />
      </Modal>
      <Modal title="Remove school from partnership" isOpen={isConfirmModalOpen} onClose={closeModal}>
        <PartnershipRemoveConfirmation onCancel={closeModal} onConfirm={handleRemovePartnership} />
      </Modal>
    </>
  );
};

export default PartnershipsList;

import { CampusDTO, CreateCampusDTO, EditCampusDTO } from './campus-profile.dto';
import {
  CLEAR_CURRENT_CAMPUS,
  ClearCurrentCampusAction,
  CREATE_CAMPUS,
  CreateCampusAction,
  DELETE_CAMPUS,
  DeleteCampusAction,
  EDIT_CAMPUS,
  EDIT_CAMPUS_DETAILS,
  EditCampusAction,
  EditCampusDetailsAction,
  LOAD_CAMPUS,
  LoadCampusAction,
  SET_CAMPUS,
  SetCampusAction,
} from './campus-profile.types';

export const createNewCampusAction = (schoolId: number, data: CreateCampusDTO): CreateCampusAction => ({
  type: CREATE_CAMPUS,
  payload: { data, schoolId },
});

export const loadCampusAction = (schoolId: number, campusId: number): LoadCampusAction => ({
  type: LOAD_CAMPUS,
  payload: { campusId, schoolId },
});

export const editCampusAction = (schoolId: number, data: EditCampusDTO): EditCampusAction => ({
  type: EDIT_CAMPUS,
  payload: { data, schoolId },
});

export const editCampusDetailsAction = (schoolId: number, data: EditCampusDTO): EditCampusDetailsAction => ({
  type: EDIT_CAMPUS_DETAILS,
  payload: { data, schoolId },
});

export const deleteCampusAction = (schoolId: number, campusId: number): DeleteCampusAction => ({
  type: DELETE_CAMPUS,
  payload: { campusId, schoolId },
});

export const setCampusAction = (data: CampusDTO): SetCampusAction => ({
  type: SET_CAMPUS,
  payload: { data },
});

export const clearCurrentCampusAction = (): ClearCurrentCampusAction => ({
  type: CLEAR_CURRENT_CAMPUS,
});

import { RootState } from '../../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const campusesSelector = (state: RootState) => state.schools.campuses;

export const selectCampusesTableData = createDeepEqualSelector(campusesSelector, (campuses) => {
  return campuses.data;
});

export const selectCampusesPageConfiguration = createDeepEqualSelector(campusesSelector, (campuses) => {
  return {
    filter: campuses.filter,
    limit: campuses.pagination.limit,
    order: campuses.sortOrder.order,
    page: campuses.pagination.page,
    search: campuses.search,
    sortBy: campuses.sortOrder.sortBy,
    totalPages: campuses.pagination.totalPages,
  };
});

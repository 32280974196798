import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SCHOOL_RANKINGS_TABLE_COLUMN_DEFINITION } from './SchoolRankings.constants';
import { loadSchoolRankingsAction } from '../school-rankings.actions';
import { selectSchoolRankingsPageConfiguration, selectSchoolRankingsTableData } from '../school-rankings.selectors';
import { SchoolDTO, selectCurrentSchool } from '../../SchoolDetails';
import Pager, { INITIAL_PAGE } from '../../../../../components/Pager';
import Table from '../../../../../components/Table';
import Typography, { TypographyVariants } from '../../../../../components/Typography';
import { ASCENDING_ORDER_DIRECTION, DESCENDING_ORDER_DIRECTION } from '../../../../../shared/constants';

const SchoolRankings: FunctionComponent = () => {
  const dispatch = useDispatch();
  const pageConfig = useSelector(selectSchoolRankingsPageConfiguration);
  const schoolRankingsData = useSelector(selectSchoolRankingsTableData);
  const currentSchool = useSelector(selectCurrentSchool) as SchoolDTO;
  const schoolId = currentSchool.id;

  useEffect(() => {
    dispatch(loadSchoolRankingsAction(schoolId));
  }, [dispatch, schoolId]);

  const handlePageChanged = (page: number): void => {
    dispatch(loadSchoolRankingsAction(schoolId, { ...pageConfig, page }));
  };

  const handleSortByChanged = (fieldName: string): void => {
    const orderDirection =
      pageConfig.sortBy !== fieldName
        ? DESCENDING_ORDER_DIRECTION
        : pageConfig.order === ASCENDING_ORDER_DIRECTION
        ? DESCENDING_ORDER_DIRECTION
        : ASCENDING_ORDER_DIRECTION;
    dispatch(loadSchoolRankingsAction(schoolId, { ...pageConfig, sortBy: fieldName, order: orderDirection }));
  };

  return (
    <>
      <div className="mba-heading--wrapper mba-heading--table">
        <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title-2">
          Rankings
        </Typography>
      </div>
      <div className="mba-table-wrapper">
        <Table
          id="school-rankings-list"
          columns={SCHOOL_RANKINGS_TABLE_COLUMN_DEFINITION}
          data={schoolRankingsData}
          sortingOptions={{
            order: pageConfig.order || DESCENDING_ORDER_DIRECTION,
            sortBy: pageConfig.sortBy || '',
          }}
          onSortByColumn={handleSortByChanged}
        />
      </div>
      {pageConfig && pageConfig.totalPages !== INITIAL_PAGE && (
        <Pager
          currentPage={pageConfig.page}
          totalPages={pageConfig.totalPages || INITIAL_PAGE}
          onPageChange={handlePageChanged}
        />
      )}
    </>
  );
};

export default SchoolRankings;

import { RankingCategoryDTO, SaveRankingCategoryDTO } from './ranking-category-profile.dto';
import { del, get, post, put } from '../../../../shared/api';
import { BasicResponseDTO, DefaultResponseDTO } from '../../../../shared/constants';

export const createRankingCategoryRequest = (
  rankingTypeData: SaveRankingCategoryDTO,
): Promise<DefaultResponseDTO<RankingCategoryDTO>> => {
  return post<DefaultResponseDTO<RankingCategoryDTO>, SaveRankingCategoryDTO>('/categories', rankingTypeData);
};

export const getRankingCategoryRequest = (rankingTypeId: number): Promise<DefaultResponseDTO<RankingCategoryDTO>> => {
  return get<DefaultResponseDTO<RankingCategoryDTO>>(`/categories/${rankingTypeId}`);
};

export const updateRankingCategoryRequest = (rankingTypeData: SaveRankingCategoryDTO): Promise<BasicResponseDTO> => {
  const { id } = rankingTypeData;
  return put<BasicResponseDTO, SaveRankingCategoryDTO>(`/categories/${id}`, rankingTypeData);
};

export const deleteRankingCategoryRequest = (rankingCategoryId: number): Promise<BasicResponseDTO> => {
  return del<BasicResponseDTO>(`/categories/${rankingCategoryId}`);
};

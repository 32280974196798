import {
  CLEAR_CURRENT_RANKING_TIMELINE_STEP,
  CLEAR_CURRENT_RANKING_TIMELINE_STEPS,
  RankingTimelineStepsActionTypes,
  RankingTimelineStepsState,
  SET_CURRENT_RANKING_TIMELINE_STEP,
  SET_RANKING_TIMELINE_STEPS,
} from './ranking-timeline-steps.types';

const initialState: RankingTimelineStepsState = {
  steps: [],
};

const rankingTimelineStepsReducer = (
  state = initialState,
  action: RankingTimelineStepsActionTypes,
): RankingTimelineStepsState => {
  switch (action.type) {
    case CLEAR_CURRENT_RANKING_TIMELINE_STEPS: {
      return initialState;
    }
    case SET_RANKING_TIMELINE_STEPS: {
      return {
        ...state,
        steps: action.payload.data,
      };
    }
    case CLEAR_CURRENT_RANKING_TIMELINE_STEP: {
      return {
        ...state,
        currentStep: undefined,
      };
    }
    case SET_CURRENT_RANKING_TIMELINE_STEP: {
      return {
        ...state,
        currentStep: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default rankingTimelineStepsReducer;

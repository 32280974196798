export enum CreateSchoolWidgetGroupScope {
  all = 'all',
  rankingType = 'rankingType',
  ranking = 'ranking',
}

export enum CreateSchoolWidgetGroupStatus {
  Inactive = '0',
  Active = '1',
  Expired = '2',
}

export enum CreateSchoolWidgetGroupActionType {
  duplicate = 'duplicate',
  create = 'create',
}

export const STATUS_LIST = [
  { label: 'Inactive', color: '#6B6B6B', value: +CreateSchoolWidgetGroupStatus.Inactive },
  { label: 'Active', color: '#006B36', value: +CreateSchoolWidgetGroupStatus.Active },
  { label: 'Expired', color: '#686868', value: +CreateSchoolWidgetGroupStatus.Expired },
];

export const STATUS_LIST_BY_KEY = {
  [+CreateSchoolWidgetGroupStatus.Inactive]: { label: 'Inactive', color: '#6B6B6B' },
  [+CreateSchoolWidgetGroupStatus.Active]: { label: 'Active', color: '#006B36' },
  [+CreateSchoolWidgetGroupStatus.Expired]: { label: 'Expired', color: '#686868' },
};

export interface CreateSchoolWidgetGroupsDto {
  action: CreateSchoolWidgetGroupActionType;
  activeFromDate: number;
  activeToDate: number;
  group?: number;
  rankingId?: number;
  rankingTypeId?: number;
  school: number;
  scope?: CreateSchoolWidgetGroupScope;
  status: CreateSchoolWidgetGroupStatus;
}

export interface UpdateWidgetGroupDto {
  activeFromDate?: string;
  activeToDate?: string;
  rankingTypeId?: number;
  isActive?: boolean;
  schoolId?: number;
}

import { ImportSchoolUsersDTO } from './school-users.dto';
import { SchoolUserDTO } from '../../SchoolUserProfile';
import { get, post } from '../../../../shared/api';
import { BasicResponseDTO, PagedResponseDTO, PageSearchQueryConfig } from '../../../../shared/constants';
import { generateSearchQuery } from '../../../../shared/helpers';

export const getSchoolUsersForSchoolRequest = (
  schoolId: number,
  pageConfig: PageSearchQueryConfig = {},
): Promise<PagedResponseDTO<SchoolUserDTO[]>> => {
  const query = generateSearchQuery(pageConfig);
  return get<PagedResponseDTO<SchoolUserDTO[]>>(`/schools/${schoolId}/accounts${query}`);
};

export const importSchoolUsersRequest = (
  importSchoolUserData: ImportSchoolUsersDTO,
  schoolId: number,
): Promise<BasicResponseDTO> => {
  return post<BasicResponseDTO, ImportSchoolUsersDTO>(`schools/${schoolId}/accounts/import`, importSchoolUserData);
};

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  JOINED_SCHOOLS_FIELD_CONFIG,
  SCHOOL_CREATE_FORM_CONFIG,
  SCHOOL_EDIT_FORM_CONFIG,
  SCHOOL_TYPES,
  SCHOOL_USERS_FORM_CONFIG,
} from '../SchoolsList.constants';
import {
  CREATE_SCHOOL,
  createNewSchoolAction,
  CreateSchoolDTO,
  EDIT_SCHOOL,
  editSchoolAction,
  EditSchoolDTO,
  LOAD_SCHOOL,
  SchoolDTO,
  selectCurrentSchool,
  setSchoolAction,
} from '../../../SchoolProfile/SchoolDetails';
import Form, { FormConfig, FormDataValues } from '../../../../../components/Form';
import { selectAreRequestsLoading, selectRequestFormErrors } from '../../../../../shared/state/global-request';
import { capitalizeText } from '../../../../../shared/helpers';
import { loadResourcesAction, selectResources } from '../../../../../shared/state/resources';
import { loadRolesAction, selectRoles } from '../../../../../shared/state/roles';
import { selectStatuses } from '../../../../../shared/state/statuses';

type SchoolFormProps = {
  isEdit?: boolean;
  isOpen?: boolean;
  onFormClose: () => void;
};

const SchoolForm: FunctionComponent<SchoolFormProps> = ({ onFormClose, isEdit = false, isOpen }) => {
  const dispatch = useDispatch();
  const [schoolType, setSchoolType] = useState(SCHOOL_TYPES.SINGLE);
  const isLoading = useSelector(selectAreRequestsLoading([CREATE_SCHOOL, EDIT_SCHOOL, LOAD_SCHOOL]));
  const formErrors = useSelector(selectRequestFormErrors(isEdit ? EDIT_SCHOOL : CREATE_SCHOOL));
  const currentSchool = useSelector(selectCurrentSchool) as SchoolDTO;
  const schoolUserRoles = useSelector(selectRoles('school-users'));
  const schoolStatuses = useSelector(selectStatuses('school'));
  const singleSchoolsList = useSelector(selectResources(`schools/type-${SCHOOL_TYPES.SINGLE}`));
  const [defaultFormConfig, setDefaultFormConfig] = useState({} as FormConfig);

  useEffect(() => {
    dispatch(loadRolesAction('school-users'));
    dispatch(loadResourcesAction('schools', { name: 'type', value: SCHOOL_TYPES.SINGLE }));
  }, [dispatch]);

  useEffect(() => {
    if (schoolUserRoles.length > 0 && schoolStatuses.length > 0) {
      if (isEdit) {
        setDefaultFormConfig({
          ...SCHOOL_EDIT_FORM_CONFIG,
          status: {
            ...SCHOOL_EDIT_FORM_CONFIG.status,
            options: schoolStatuses.map(({ key, label }) => ({ label, value: key })),
          },
        });
      } else {
        if (schoolType === SCHOOL_TYPES.SINGLE) {
          setDefaultFormConfig({
            ...SCHOOL_CREATE_FORM_CONFIG,
            ...SCHOOL_USERS_FORM_CONFIG,
            'schoolUsers[0][role]': {
              ...SCHOOL_USERS_FORM_CONFIG['schoolUsers[0][role]'],
              options: schoolUserRoles.map((roleName: string) => ({
                label: capitalizeText(roleName),
                value: roleName,
              })),
            },
          });
        } else {
          setDefaultFormConfig({
            ...SCHOOL_CREATE_FORM_CONFIG,
            ...JOINED_SCHOOLS_FIELD_CONFIG,
            schoolsIds: {
              ...JOINED_SCHOOLS_FIELD_CONFIG.schoolsIds,
              options: singleSchoolsList.map(({ key, label }) => ({ label, value: key })),
            },
          });
        }
      }
    }
  }, [isEdit, singleSchoolsList, schoolType, schoolStatuses, schoolUserRoles]);

  useEffect(() => {
    return (): void => {
      setSchoolType(SCHOOL_TYPES.SINGLE);

      if (isOpen) {
        dispatch(loadResourcesAction('schools', { name: 'type', value: SCHOOL_TYPES.SINGLE }));
      }
    };
  }, [dispatch, isOpen]);

  const onSchoolSaveSubmit = (data: CreateSchoolDTO | EditSchoolDTO): void => {
    if (isEdit) {
      const { id, type } = currentSchool as SchoolDTO;
      const { status } = data as EditSchoolDTO;
      dispatch(editSchoolAction({ ...data, id, status: +status, type: +type.key }));
    } else {
      const { schoolUsers, type } = data as CreateSchoolDTO;
      dispatch(createNewSchoolAction({ ...data, schoolUsers: schoolUsers || [], type: +type } as CreateSchoolDTO));
    }
  };

  const handleValuesChanged = (values: FormDataValues): void => {
    const currentValues = { ...values };

    if (currentValues.hasOwnProperty('type') && currentValues.type !== schoolType) {
      delete currentValues.schoolUsers;
      delete currentValues.schoolsIds;

      dispatch(setSchoolAction(currentValues as unknown as SchoolDTO));

      setSchoolType(currentValues.type as SCHOOL_TYPES);
    }
  };

  const schoolInitialData =
    isEdit && (currentSchool as SchoolDTO).status && (currentSchool as SchoolDTO).status.key
      ? {
          ...currentSchool,
          status: (currentSchool as SchoolDTO).status.key,
        }
      : {
          ...currentSchool,
          type: currentSchool.type ? currentSchool.type.toString() : SCHOOL_TYPES.SINGLE,
        };

  return (
    <Form
      id="school-form"
      isLoading={isLoading}
      config={defaultFormConfig}
      onSubmit={onSchoolSaveSubmit}
      onValuesChanged={handleValuesChanged}
      submitButtonText={isEdit ? 'Save' : 'Create school'}
      actions={[
        {
          label: 'Cancel',
          onClick: onFormClose,
        },
      ]}
      initialValues={schoolInitialData}
      errors={formErrors}
    />
  );
};

export default SchoolForm;

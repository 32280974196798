import { RankingFieldDTO } from './ranking-field-details.dto';
import {
  RankingFieldProfileActionTypes,
  RankingFieldProfileState,
  SET_RANKING_FIELD,
} from './ranking-field-details.types';

const initialState: RankingFieldProfileState = {
  profile: {} as RankingFieldDTO,
};

const rankingFieldDetailsReducer = (
  state = initialState,
  action: RankingFieldProfileActionTypes,
): RankingFieldProfileState => {
  switch (action.type) {
    case SET_RANKING_FIELD: {
      return {
        ...state,
        profile: { ...action.payload.data },
      };
    }
    default: {
      return state;
    }
  }
};

export default rankingFieldDetailsReducer;

import { RankingSpreadsheetDTO } from './spreadsheets.dto';
import {
  LOAD_RANKING_SPREADSHEETS,
  RankingSpreadsheetActionTypes,
  SET_RANKING_SPREADSHEETS,
  SpreadsheetsState,
} from './spreadsheets.types';
import { getTableListDefaultState } from '../../../../../shared/helpers';

const initialState: SpreadsheetsState = {
  alumni: getTableListDefaultState<RankingSpreadsheetDTO>(),
  faculty: getTableListDefaultState<RankingSpreadsheetDTO>(),
  research: getTableListDefaultState<RankingSpreadsheetDTO>(),
};

const spreadsheetsReducer = (state = initialState, action: RankingSpreadsheetActionTypes): SpreadsheetsState => {
  switch (action.type) {
    case LOAD_RANKING_SPREADSHEETS: {
      return {
        ...state,
        [action.payload.type]: {
          ...getTableListDefaultState<RankingSpreadsheetDTO>(),
          filter: action.payload.pageConfig?.filter || {},
          search: action.payload.pageConfig?.search || '',
        },
      };
    }
    case SET_RANKING_SPREADSHEETS: {
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          ...action.payload.tableData,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default spreadsheetsReducer;

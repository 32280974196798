import { RankingFieldDataItemDTO } from './ranking-field-data.dto';
import { get, put } from '../../../../../../../shared/api';
import { BasicResponseDTO, PagedResponseDTO } from '../../../../../../../shared/constants';
import { generateSearchQuery } from '../../../../../../../shared/helpers';

export const getRankingFieldDataRequest = ({
  rankingFieldId,
  pageConfig,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any): Promise<PagedResponseDTO<RankingFieldDataItemDTO[]>> => {
  const query = pageConfig ? generateSearchQuery(pageConfig) : '';
  return get<PagedResponseDTO<RankingFieldDataItemDTO[]>>(`/ranking/data/${rankingFieldId}${query}`);
};

export const editRankingFieldDataItemRequest = (
  rankingFieldDataItemId: number,
  value: number,
): Promise<BasicResponseDTO> => {
  return put<BasicResponseDTO, { value: number }>(`/ranking/data/${rankingFieldDataItemId}`, { value });
};

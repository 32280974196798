import React, { FunctionComponent, useEffect, useState } from 'react';

import TimelineIndicator from './TimelineIndicator';
import TimelineStepDates from './TimelineStepDates';
import { RankingTimelineStepBaseProps, TimelineExpandStepStatus } from '../RankingTimeline.constants';
import Button from '../../../../../../components/Button';
import Grid from '../../../../../../components/Grid';

export interface SurveyStepProps extends RankingTimelineStepBaseProps {
  surveyType: string;
  triggerUploadModal?: (open: boolean) => void;
}

const SurveyStep: FunctionComponent<SurveyStepProps> = ({
  endDate,
  name,
  startDate,
  status,
  surveyId,
  surveyType,
  triggerUploadModal,
}) => {
  const [expand, setExpand] = useState<boolean>(false);
  useEffect(() => {
    setExpand(TimelineExpandStepStatus[status]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
  return (
    <Grid container key={name + surveyType}>
      <Grid item xs={5}>
        <TimelineStepDates
          endDate={endDate}
          endDateLabel="End"
          isEndDateRequired={true}
          startDate={startDate}
          startDateLabel="Start"
          isStartDateRequired={true}
        />
      </Grid>
      <Grid item xs={1} className="mba-no-padding">
        <TimelineIndicator timeIndication={status} />
      </Grid>
      <Grid item xs={6} className="mba-no-padding">
        <button
          className="mba-text--accent mba-no-margin mba-text--semi-bold mba-timeline-button--link"
          onClick={(): void => setExpand(!expand)}
        >
          {name}
        </button>
        {expand && (
          <>
            <p className="mba-text--semi-bold mba-font--16">
              ID: {surveyId || <span className="mba-text--error">Not set!</span>}
            </p>
            {triggerUploadModal ? (
              <div className="mba-actions mba-no-margin">
                <Button
                  disabled={!startDate || (new Date(startDate).getTime() - Date.now()) / (3600 * 1000) > 3}
                  text="Upload survey links"
                  onClick={(): void => triggerUploadModal(true)}
                />
              </div>
            ) : (
              <div className="mba-actions mba-no-margin">
                <Button primary text="Go to survey" onClick={(): void => alert('TODO')} />
              </div>
            )}
            <br />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default SurveyStep;

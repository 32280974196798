import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

import { setRankingFieldsAction } from './ranking-fields-list.actions';
import { PagedResponseDTO, TableListData } from '../../../../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../../../../shared/state/global-request';
import { RankingFieldDTO } from './ranking-fields-list.dto';
import { LOAD_RANKING_FIELDS, LoadRankingFieldsAction, RankingFieldsActionTypes } from './ranking-fields-list.types';
import { getRankingFieldsRequest } from './ranking-fields-list.api';

function* loadRankingFields(
  action: LoadRankingFieldsAction,
): Generator<
  CallEffect<TableListData<RankingFieldDTO>> | PutEffect<RankingFieldsActionTypes> | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig, rankingId } = action.payload;
    const { data, pagination, sortOrder } = (yield call(
      getRankingFieldsRequest,
      rankingId,
      pageConfig,
    )) as PagedResponseDTO<RankingFieldDTO[]>;
    yield put(setRankingFieldsAction({ data, pagination, sortOrder }));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* rankingFieldsSaga(): Generator {
  yield takeLatest(LOAD_RANKING_FIELDS, loadRankingFields);
}

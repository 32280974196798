import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

import { setRankingSpreadsheetsAction } from './spreadsheets.actions';
import {
  deleteAllSpreadsheetsRequest,
  deleteSpreadsheetRequest,
  downloadRankingSpreadsheetContactsRequest,
  downloadRankingSpreadsheetRequest,
  getRankingSpreadsheetsRequest,
} from './spreadsheets.api';
import { RankingSpreadsheetDTO } from './spreadsheets.dto';
import {
  DELETE_ALL_SPREADSHEETS,
  DELETE_SPREADSHEET,
  DeleteAllSpreadsheetsAction,
  DeleteSpreadsheetAction,
  DOWNLOAD_RANKING_SPREADSHEET,
  DOWNLOAD_RANKING_SPREADSHEET_CONTACTS,
  DownloadRankingSpreadsheetAction,
  DownloadRankingSpreadsheetContactsAction,
  LOAD_RANKING_SPREADSHEETS,
  LoadRankingSpreadsheetsAction,
  RankingSpreadsheetActionTypes,
} from './spreadsheets.types';
import { BasicResponseDTO, PagedResponseDTO } from '../../../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../../../shared/state/global-request';

function* loadRankingSpreadsheetsTableData(
  action: LoadRankingSpreadsheetsAction,
): Generator<
  | CallEffect<PagedResponseDTO<RankingSpreadsheetDTO[]>>
  | PutEffect<RankingSpreadsheetActionTypes>
  | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig, rankingId, type } = action.payload;
    const { data, pagination, sortOrder } = (yield call(
      getRankingSpreadsheetsRequest,
      rankingId,
      type,
      pageConfig,
    )) as PagedResponseDTO<RankingSpreadsheetDTO[]>;
    yield put(setRankingSpreadsheetsAction(type, { data, pagination, sortOrder }));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* downloadRankingSpreadsheet(
  action: DownloadRankingSpreadsheetAction,
): Generator<
  CallEffect<BasicResponseDTO> | PutEffect<RankingSpreadsheetActionTypes> | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig, rankingId, type } = action.payload;
    yield call(downloadRankingSpreadsheetRequest, rankingId, type, pageConfig);
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* deleteSpreadsheet(
  action: DeleteSpreadsheetAction,
): Generator<
  CallEffect<BasicResponseDTO> | PutEffect<RankingSpreadsheetActionTypes> | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { rankingId, id } = action.payload;
    yield call(deleteSpreadsheetRequest, rankingId, id);
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* deleteAllSpreadsheets(
  action: DeleteAllSpreadsheetsAction,
): Generator<
  CallEffect<BasicResponseDTO> | PutEffect<RankingSpreadsheetActionTypes> | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { rankingId, type } = action.payload;
    yield call(deleteAllSpreadsheetsRequest, rankingId, type);
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* downloadRankingSpreadsheetContacts(
  action: DownloadRankingSpreadsheetContactsAction,
): Generator<
  CallEffect<BasicResponseDTO> | PutEffect<RankingSpreadsheetActionTypes> | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig, rankingId, type } = action.payload;
    yield call(downloadRankingSpreadsheetContactsRequest, rankingId, type, pageConfig);
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* rankingSpreadsheetsSaga(): Generator {
  yield takeLatest(LOAD_RANKING_SPREADSHEETS, loadRankingSpreadsheetsTableData);
  yield takeLatest(DOWNLOAD_RANKING_SPREADSHEET, downloadRankingSpreadsheet);
  yield takeLatest(DOWNLOAD_RANKING_SPREADSHEET_CONTACTS, downloadRankingSpreadsheetContacts);
  yield takeLatest(DELETE_SPREADSHEET, deleteSpreadsheet);
  yield takeLatest(DELETE_ALL_SPREADSHEETS, deleteAllSpreadsheets);
}

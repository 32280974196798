import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import {
  loadRankingFieldDataAction,
  RankingFieldDataItemDTO,
  selectRankingFieldData,
  selectRankingFieldDataPageConfiguration,
} from '../../../RankingFieldData';
import Table, { TableHeaderColumnTypes } from '../../../../../../../../../components/Table';
import { DESCENDING_ORDER_DIRECTION, ASCENDING_ORDER_DIRECTION } from '../../../../../../../../../shared/constants';
import { RANKING_FIELD_DATA_TABLE_COLUMN_DEFINITION } from './RankingFieldData.constants';
import { RankingFieldProfileRouteParams } from '../../../RankingFieldDetails';
import Modal from '../../../../../../../../../components/Modal';
import RankingFieldDataForm from './RankingFieldDataForm';
import Pager, { INITIAL_PAGE } from '../../../../../../../../../components/Pager';

const RankingFieldData: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { rankingId, rankingFieldId } = useParams<RankingFieldProfileRouteParams>();
  const pageConfig = useSelector(selectRankingFieldDataPageConfiguration);
  const rankingFieldData = useSelector(selectRankingFieldData);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [currentDataItem, setCurrentDataItem] = useState({} as RankingFieldDataItemDTO);

  useEffect(() => {
    dispatch(loadRankingFieldDataAction(parseInt(rankingFieldId)));
  }, [dispatch, rankingId, rankingFieldId]);

  useEffect(() => {
    setIsFormModalOpen(false);
  }, [rankingFieldData]);

  const handleSortByChanged = (fieldName: string): void => {
    const orderDirection =
      pageConfig.sortBy !== fieldName
        ? DESCENDING_ORDER_DIRECTION
        : pageConfig.order === ASCENDING_ORDER_DIRECTION
        ? DESCENDING_ORDER_DIRECTION
        : ASCENDING_ORDER_DIRECTION;
    dispatch(
      loadRankingFieldDataAction(parseInt(rankingFieldId), {
        ...pageConfig,
        sortBy: fieldName,
        order: orderDirection,
      }),
    );
  };

  const handlePageChanged = (page: number): void => {
    dispatch(loadRankingFieldDataAction(parseInt(rankingFieldId), { ...pageConfig, page }));
  };

  const closeModal = (): void => {
    setIsFormModalOpen(false);
  };

  const openFormModal = (id?: number): void => {
    if (id) {
      const currentRankingFieldDataItem = rankingFieldData.find(
        (rankingFieldDataItem: RankingFieldDataItemDTO) => rankingFieldDataItem.id === id,
      );
      if (currentRankingFieldDataItem) {
        setCurrentDataItem(currentRankingFieldDataItem);
      }
    }

    setIsFormModalOpen(true);
  };

  const rankingFieldDataTableConfig = [
    ...RANKING_FIELD_DATA_TABLE_COLUMN_DEFINITION,
    {
      id: 'actions',
      title: 'Action',
      type: TableHeaderColumnTypes.actions,
      width: '90px',
      actions: [
        {
          label: 'Edit',
          handleOnClick: openFormModal,
        },
      ],
    },
  ];

  return (
    <>
      <div className="mba-table-container">
        <Table
          id="column-data-view-list"
          columns={rankingFieldDataTableConfig}
          data={rankingFieldData}
          sortingOptions={{
            order: pageConfig.order || DESCENDING_ORDER_DIRECTION,
            sortBy: pageConfig.sortBy || '',
          }}
          onSortByColumn={handleSortByChanged}
        />
      </div>
      {pageConfig && pageConfig.totalPages !== INITIAL_PAGE && (
        <Pager
          currentPage={pageConfig.page}
          totalPages={pageConfig.totalPages || INITIAL_PAGE}
          onPageChange={handlePageChanged}
        />
      )}
      <Modal title="Edit data" isOpen={isFormModalOpen} onClose={closeModal}>
        <RankingFieldDataForm onFormClose={closeModal} rankingFieldDataItem={currentDataItem} />
      </Modal>
    </>
  );
};

export default RankingFieldData;

import { get } from '../../api';
import { DefaultResponseDTO, Resource } from '../../constants';
import { registrationGet } from '../../registrationApi/api';

export const getResourcesRequest = (
  resourceName: string,
  query?: string,
  isForRegistrationAPI?: boolean,
): Promise<DefaultResponseDTO<Resource[]>> => {
  return !isForRegistrationAPI
    ? get<DefaultResponseDTO<Resource[]>>(`/${resourceName}/dropdown?${query}`)
    : registrationGet<DefaultResponseDTO<Resource[]>>(`/${resourceName}/dropdown?${query}`);
};

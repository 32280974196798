import React, { FunctionComponent } from 'react';
import { combineReducers } from 'redux';

import { accreditationsReducer } from './Accreditation/AccreditationsList';
import { accreditationProfileReducer } from './Accreditation/AccreditationProfile';
import Accreditation from './Accreditation';
import { calendarReducer } from './AdditionalInformation/Calendar';
import AdditionalInformation from './AdditionalInformation';
import { rankingCategoriesReducer } from './Categorization/RankingCategoriesList';
import { rankingCategoryProfileReducer } from './Categorization/RankingCategoryProfile';
import { rankingTypesReducer } from './Categorization/RankingTypesList';
import { rankingTypeProfileReducer } from './Categorization/RankingTypeProfile';
import Categorization from './Categorization';
import { rankingTimelineStepsReducer } from './Rankings/RankingProfile/RankingDetails/TimelineSteps';
import { rankingProfileReducer } from './Rankings/RankingProfile/RankingInfo';
import { rankingSchoolsReducer } from './Rankings/RankingProfile/RankingSchools';
import rankingFieldDataReducer from './Rankings/RankingProfile/RankingData/RankingFieldProfile/RankingFieldData/ranking-field-data.reducer';
import { rankingFieldProfileReducer } from './Rankings/RankingProfile/RankingData/RankingFieldProfile/RankingFieldDetails';
import { rankingFieldsReducer } from './Rankings/RankingProfile/RankingData/RankingFieldsList';
import { rankingSpreadsheetsReducer } from './Rankings/RankingProfile/Spreadsheets';
import RankingProfile from './Rankings/RankingProfile';
import RankingsList, { rankingsListReducer } from './Rankings/RankingsList';
import Scheduler from './Scheduler';
import SpreadsheetTemplate, { spreadsheetTemplateFieldReducer } from './SpreadsheetTemplate';
import { PageRouter } from '../App/Main/components/Layout';
import { PageProps } from '../../shared/constants';
import { faqReducer } from './AdditionalInformation/FAQs';

const componentsMap = {
  profile: RankingProfile,
  list: RankingsList,
  categorization: Categorization,
  accreditation: Accreditation,
  scheduler: Scheduler,
  information: AdditionalInformation,
  spreadsheetTemplate: SpreadsheetTemplate,
};

const Rankings: FunctionComponent<PageProps> = ({ navigation, route }) => {
  let rankingsNavigation = { ...navigation };
  let content = <div>Rankings</div>;

  if (navigation) {
    rankingsNavigation = {
      ...navigation,
      profile: {
        label: 'Ranking profile',
        route: '/profile/:id',
      },
      information: {
        label: 'Additional Information',
        route: '/information',
      },
    };
    content = <PageRouter mainRoute={route} navigation={rankingsNavigation} componentsMap={componentsMap} />;
  }

  return content;
};

export default Rankings;

export const rankingsReducer = combineReducers({
  // Rankings
  details: rankingProfileReducer,
  list: rankingsListReducer,

  // Accreditation
  currentAccreditation: accreditationProfileReducer,
  accreditations: accreditationsReducer,

  // Category
  currentCategory: rankingCategoryProfileReducer,
  categories: rankingCategoriesReducer,

  // Type
  currentType: rankingTypeProfileReducer,
  types: rankingTypesReducer,

  // Timeline steps
  timelineSteps: rankingTimelineStepsReducer,

  // Ranking Schools
  schools: rankingSchoolsReducer,

  // Ranking Spreadsheets
  spreadsheets: rankingSpreadsheetsReducer,

  // Ranking Field Profile
  rankingFieldProfile: rankingFieldProfileReducer,

  // Ranking Field Data
  rankingFieldData: rankingFieldDataReducer,

  // Calendar
  calendar: calendarReducer,

  // Ranking Data / Fields
  rankingFields: rankingFieldsReducer,

  // Spreadsheet Template Fields
  spreadsheetTemplateField: spreadsheetTemplateFieldReducer,

  // Frequently Asked Questions Fields
  faq: faqReducer,
});

import { AddFaqDto, FaqDto, FaqImageDto } from '.';
import { del, get, post, put } from '../../../../shared/api';
import { BasicResponseDTO, DefaultResponseDTO } from '../../../../shared/constants';

export const getFAQsRequest = (): Promise<DefaultResponseDTO<FaqDto[]>> => {
  return get<DefaultResponseDTO<FaqDto[]>>('/faq');
};

export const addFAQRequest = (faq: AddFaqDto): Promise<DefaultResponseDTO<FaqDto>> => {
  return post<DefaultResponseDTO<FaqDto>, AddFaqDto>('/faq', faq);
};

export const putFAQRequest = (faqId: number, faq: AddFaqDto): Promise<DefaultResponseDTO<FaqDto>> => {
  return put<DefaultResponseDTO<FaqDto>, AddFaqDto>(`/faq/${faqId}`, faq);
};

export const deleteFAQRequest = (faqId: number): Promise<BasicResponseDTO> => {
  return del<BasicResponseDTO>(`/faq/${faqId}`);
};

export const uploadFaqImage = (formData: FormData): Promise<FaqImageDto> => {
  return put<FaqImageDto, FormData>('/faq/upload-image', formData);
};

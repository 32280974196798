import { CreateUserDTO, EditUserDTO, UserDTO } from './users.dto';
import { PageSearchQueryConfig, TableListData } from '../../../shared/constants';

export const CREATE_USER = 'CREATE_USER';
export const LOAD_USER = 'LOAD_USER';
export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';
export const SET_USER = 'SET_USER';

export const LOAD_USERS = 'LOAD_USERS';
export const SET_USERS = 'SET_USERS';

export type SingleUserState = CreateUserDTO | EditUserDTO | UserDTO;

export interface CreateUserAction {
  type: typeof CREATE_USER;
  payload: { data: CreateUserDTO };
}

export interface EditUserAction {
  type: typeof EDIT_USER;
  payload: { data: EditUserDTO };
}

export interface DeleteUserAction {
  type: typeof DELETE_USER;
  payload: { id: number };
}

export interface ClearCurrentUserAction {
  type: typeof CLEAR_CURRENT_USER;
}

export interface SetUserAction {
  type: typeof SET_USER;
  payload: { data: UserDTO };
}

export type UserActionTypes =
  | ClearCurrentUserAction
  | CreateUserAction
  | EditUserAction
  | DeleteUserAction
  | SetUserAction;

export interface LoadUsersAction {
  type: typeof LOAD_USERS;
  payload: { pageConfig?: PageSearchQueryConfig };
}

export interface SetUsersAction {
  type: typeof SET_USERS;
  payload: { tableData: TableListData<UserDTO> };
}

export type UsersActionTypes = LoadUsersAction | SetUsersAction;

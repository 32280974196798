import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button, { ButtonIcons, ButtonSizes } from '../../../../../../components/Button';
import Modal from '../../../../../../components/Modal';
import Pager, { INITIAL_PAGE } from '../../../../../../components/Pager';
import Table, { TableHeaderColumnTypes } from '../../../../../../components/Table';
import Typography, { TypographyVariants } from '../../../../../../components/Typography';
import { ASCENDING_ORDER_DIRECTION, DESCENDING_ORDER_DIRECTION } from '../../../../../../shared/constants';
import { loadStatusesAction, selectStatuses } from '../../../../../../shared/state/statuses';
import { loadResourcesAction, selectResources } from '../../../../../../shared/state/resources';
import { selectRequestIsLoading } from '../../../../../../shared/state/global-request';
import { RankingDTO, selectCurrentRanking } from '../../RankingInfo';
import {
  downloadParticipationSpreadsheetAction,
  loadRankingSchoolsAction,
  updateParticipationStatusAction,
} from '../ranking-schools.actions';
import { selectRankingSchoolsPageConfiguration, selectRankingSchoolsTableData } from '../ranking-schools.selectors';
import { UPDATE_PARTICIPATION_STATUS } from '../ranking-schools.types';
import AddSchoolsToRankingForm from './AddSchoolsToRankingForm';
import RankingSchoolsFiltersAndSearch from './RankingSchoolsFiltersAndSearch';
import {
  PARTICIPATION_STATUS_PENDING_KEY,
  PARTICIPATION_STATUS_REJECTED_KEY,
  RANKING_SCHOOLS_TABLE_COLUMN_DEFINITION,
  RANKING_STATUS_DRAFT_KEY,
  STATUS,
} from './RankingSchools.constants';
import UpdateParticipationStatusConfirmation from './UpdateParticipationStatusConfirmation';
import ImportSchoolsToRanking from './ImportSchoolsToRanking';

const RankingSchools: FunctionComponent = () => {
  const dispatch = useDispatch();
  const pageConfig = useSelector(selectRankingSchoolsPageConfiguration);
  const isLoading = useSelector(selectRequestIsLoading(UPDATE_PARTICIPATION_STATUS));
  const rankingSchoolsData = useSelector(selectRankingSchoolsTableData);
  const { id: rankingId, status } = useSelector(selectCurrentRanking) as RankingDTO;
  const schoolsForImport = useSelector(selectResources(`rankings/${rankingId}/available-schools`));

  const participationStatuses = useSelector(selectStatuses('participation'));

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [isRenewModalOpen, setIsRenewModalOpen] = useState(false);
  const [selectedSchoolId, setSelectedSchoolId] = useState(0);

  useEffect(() => {
    if (rankingId) {
      dispatch(loadRankingSchoolsAction(rankingId));
      dispatch(loadStatusesAction('participation'));
      dispatch(loadResourcesAction(`rankings/${rankingId}/available-schools`));
    }
  }, [dispatch, rankingId]);

  useEffect(() => {
    if (isFormModalOpen) {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rankingSchoolsData]);

  const handlePageChanged = (page: number): void => {
    dispatch(loadRankingSchoolsAction(rankingId, { ...pageConfig, page }));
  };

  const handleSortByChanged = (fieldName: string): void => {
    const orderDirection =
      pageConfig.sortBy !== fieldName
        ? DESCENDING_ORDER_DIRECTION
        : pageConfig.order === ASCENDING_ORDER_DIRECTION
        ? DESCENDING_ORDER_DIRECTION
        : ASCENDING_ORDER_DIRECTION;
    dispatch(loadRankingSchoolsAction(rankingId, { ...pageConfig, sortBy: fieldName, order: orderDirection }));
  };

  const handleDownloadAllClick = (): void => {
    dispatch(downloadParticipationSpreadsheetAction(rankingId, pageConfig));
  };

  const handleDownloadSingleClick = (id: number): void => {
    const schoolId = [id.toString()];
    dispatch(downloadParticipationSpreadsheetAction(rankingId, { ...pageConfig, filter: { schoolId } }));
  };

  const handleRejectClick = (): void => {
    dispatch(
      updateParticipationStatusAction(rankingId, selectedSchoolId, {
        participationStatus: PARTICIPATION_STATUS_REJECTED_KEY,
      }),
    );
    closeRejectModal();
  };

  const handleRenewClick = (): void => {
    dispatch(
      updateParticipationStatusAction(rankingId, selectedSchoolId, {
        participationStatus: PARTICIPATION_STATUS_PENDING_KEY,
      }),
    );
    closeRenewModal();
  };

  const openFormModal = (): void => {
    setIsFormModalOpen(true);
  };

  const closeModal = (): void => {
    setIsFormModalOpen(false);
  };

  const openRejectModal = (id: number): void => {
    setSelectedSchoolId(id);
    setIsRejectModalOpen(true);
  };

  const closeRejectModal = (): void => {
    setIsRejectModalOpen(false);
  };

  const openRenewFormModal = (id: number): void => {
    setSelectedSchoolId(id);
    setIsRenewModalOpen(true);
  };

  const closeRenewModal = (): void => {
    setIsRenewModalOpen(false);
  };

  const rankingSchoolsTableConfig = [
    ...RANKING_SCHOOLS_TABLE_COLUMN_DEFINITION,
    {
      id: 'actions',
      title: 'Action',
      type: TableHeaderColumnTypes.actions,
      width: '170px',
      actions: [
        {
          label: 'Download',
          handleOnClick: handleDownloadSingleClick,
        },
        {
          label: 'Reject',
          handleOnClick: openRejectModal,
          dependentActions: {
            property: 'participationStatus',
            action: {
              [PARTICIPATION_STATUS_REJECTED_KEY]: {
                label: 'Renew',
                handleOnClick: openRenewFormModal,
              },
            },
          },
        },
      ],
    },
  ];

  const rankingSchoolsList = rankingSchoolsData.map((rankingSchool) => {
    return {
      ...rankingSchool,
      id: rankingSchool.schoolId,
    };
  });

  const showImportSchoolsSection = rankingSchoolsList.length === 0;

  return (
    <>
      <div className="mba-heading--wrapper mba-heading--table">
        <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title-2">
          Participation Response
        </Typography>
        {(rankingSchoolsList.length > 0 ||
          (rankingSchoolsList.length === 0 && (status.key === STATUS.ACTIVE || status.key === STATUS.DRAFT))) && (
          <div className="mba-actions">
            {schoolsForImport.length > 0 && (
              <Button
                text="Add schools"
                size={ButtonSizes.big}
                disabled={isFormModalOpen}
                onClick={(): void => openFormModal()}
              />
            )}
            <Button
              text="Download contact information"
              size={ButtonSizes.big}
              icon={ButtonIcons.download}
              primary
              onClick={handleDownloadAllClick}
              disabled={rankingSchoolsList.length === 0}
            />
          </div>
        )}
      </div>
      <div className="mba-table-wrapper">
        <div className="mba-table-filters">
          <RankingSchoolsFiltersAndSearch
            rankingId={rankingId}
            pageConfig={pageConfig}
            filtersData={{ participationStatuses }}
          />
        </div>
        <div className="mba-table-container">
          {showImportSchoolsSection ? (
            <ImportSchoolsToRanking onManualAddClick={openFormModal} />
          ) : (
            <Table
              id="ranking-schools"
              columns={rankingSchoolsTableConfig}
              data={rankingSchoolsList}
              sortingOptions={{
                order: pageConfig.order || DESCENDING_ORDER_DIRECTION,
                sortBy: pageConfig.sortBy || '',
              }}
              onSortByColumn={handleSortByChanged}
            />
          )}
        </div>
      </div>
      {pageConfig && pageConfig.totalPages !== INITIAL_PAGE && (
        <Pager
          currentPage={pageConfig.page}
          totalPages={pageConfig.totalPages || INITIAL_PAGE}
          onPageChange={handlePageChanged}
        />
      )}

      {isFormModalOpen && (
        <Modal title="Add schools to ranking" isOpen={isFormModalOpen} onClose={closeModal}>
          <AddSchoolsToRankingForm onFormClose={closeModal} availableSchoolsList={schoolsForImport} />
        </Modal>
      )}

      {isRejectModalOpen && (
        <Modal title="Reject school" isOpen={isRejectModalOpen} onClose={closeRejectModal}>
          <UpdateParticipationStatusConfirmation
            isLoading={isLoading}
            onCancel={closeRejectModal}
            onConfirm={handleRejectClick}
            message={
              <>
                Are you sure you want to reject this school?
                <br />
                <br />
                This school will no longer be able to fill in the ranking steps!
              </>
            }
          />
        </Modal>
      )}

      {isRenewModalOpen && (
        <Modal title="Renew school" isOpen={isRenewModalOpen} onClose={closeRenewModal}>
          <UpdateParticipationStatusConfirmation
            isLoading={isLoading}
            onCancel={closeRenewModal}
            onConfirm={handleRenewClick}
            message={
              <>
                Are you sure you want to renew this school?
                <br />
                <br />
                It&apos;s participation status will be set to &quot;Pending&quot;!
              </>
            }
          />
        </Modal>
      )}
    </>
  );
};

export default RankingSchools;

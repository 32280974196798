import React, { FunctionComponent, ReactNode, SyntheticEvent } from 'react';

type StylesButtonProps = {
  onToggle: (s: string) => void;
  style: string;
  active: boolean;
  label: ReactNode;
};

const StyleButton: FunctionComponent<StylesButtonProps> = ({ onToggle, style, active, label }) => {
  const handleOnToggle = (e: SyntheticEvent): void => {
    e.preventDefault();
    onToggle(style);
  };
  let className = 'RichEditor-styleButton';
  if (active) {
    className += ' RichEditor-activeButton';
  }
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <span className={className} onMouseDown={handleOnToggle}>
      {label}
    </span>
  );
};

export default StyleButton;

import { SchoolDTO } from '../SchoolProfile/SchoolDetails';
import { PageSearchQueryConfig, TableListData } from '../../../shared/constants';

export const LOAD_SCHOOLS = 'LOAD_SCHOOLS';
export const SET_SCHOOLS = 'SET_SCHOOLS';

export interface LoadSchoolsAction {
  type: typeof LOAD_SCHOOLS;
  payload: { pageConfig?: PageSearchQueryConfig };
}

export interface SetSchoolsAction {
  type: typeof SET_SCHOOLS;
  payload: { tableData: TableListData<SchoolDTO> };
}

export type SchoolsActionTypes = LoadSchoolsAction | SetSchoolsAction;

import {
  LOAD_RANKING_CATEGORIES,
  LoadRankingCategoriesAction,
  SET_RANKING_CATEGORIES,
  SetRankingCategoriesAction,
} from './ranking-categories.types';
import { RankingCategoryDTO } from '../RankingCategoryProfile';
import { PageSearchQueryConfig, TableListData } from '../../../../shared/constants';

export const loadRankingCategoriesAction = (pageConfig?: PageSearchQueryConfig): LoadRankingCategoriesAction => ({
  type: LOAD_RANKING_CATEGORIES,
  payload: { pageConfig },
});

export const setRankingCategoriesAction = (
  tableData: TableListData<RankingCategoryDTO>,
): SetRankingCategoriesAction => ({
  type: SET_RANKING_CATEGORIES,
  payload: { tableData },
});

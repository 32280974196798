import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import EditableGroup from '../../../../../../components/EditableGroup';
import { FormConfig, FormDataValues } from '../../../../../../components/Form';
import Icon from '../../../../../../components/Icon';
import Typography, { TypographyVariants } from '../../../../../../components/Typography';
import {
  selectRequestFormErrors,
  selectRequestIsLoading,
  setRequestSucceededAction,
} from '../../../../../../shared/state/global-request';
import { loadResourcesAction, selectResources } from '../../../../../../shared/state/resources';
import { RANKING_TYPES_MAIN_TABLE_ROUTE } from '../../../Categorization.constants';
import { editRankingTypeDetailsAction, loadRankingTypeAction } from '../../ranking-type-profile.actions';
import { EditRankingTypeDTO, RankingTypeDTO } from '../../ranking-type-profile.dto';
import { selectCurrentRankingType } from '../../ranking-type-profile.selectors';
import { EDIT_RANKING_TYPE_DETAILS } from '../../ranking-type-profile.types';
import {
  RANKING_TYPE_DESCRIPTION_CONFIG,
  RANKING_TYPE_DETAILS_CONFIG,
  RANKING_TYPE_NOTE_CONFIG,
} from './RankingTypeProfileContent.constants';

type RankingTypeProfileContentProps = {
  rankingTypeId: number;
};

const RankingTypeProfileContent: FunctionComponent<RankingTypeProfileContentProps> = ({ rankingTypeId }) => {
  const dispatch = useDispatch();
  const currentRankingType = useSelector(selectCurrentRankingType) as RankingTypeDTO;
  const rankingCategories = useSelector(selectResources('categories'));
  const formErrors = useSelector(selectRequestFormErrors(EDIT_RANKING_TYPE_DETAILS));
  const isEditSaving = useSelector(selectRequestIsLoading(EDIT_RANKING_TYPE_DETAILS));
  const { isDefault, name } = currentRankingType;

  const rankingDetailsFormConfig: FormConfig = {
    ...RANKING_TYPE_DETAILS_CONFIG,
    categoryId: {
      ...RANKING_TYPE_DETAILS_CONFIG.categoryId,
      options: rankingCategories.map(({ key, label }) => ({ label, value: key })),
    },
  };

  useEffect(() => {
    if (rankingTypeId) {
      dispatch(loadResourcesAction('categories'));
    }
  }, [dispatch, rankingTypeId]);

  const updateRankingType = (data: FormDataValues): void => {
    dispatch(
      editRankingTypeDetailsAction({
        ...data,
        id: rankingTypeId,
      } as EditRankingTypeDTO),
    );
  };

  const resetFormState = (): void => {
    dispatch(setRequestSucceededAction(EDIT_RANKING_TYPE_DETAILS));
  };

  return (
    <>
      <EditableGroup<RankingTypeDTO>
        columnSize={5}
        data={currentRankingType}
        editButtonLabel="Edit details"
        formConfig={rankingDetailsFormConfig}
        formErrors={formErrors}
        headingType="back"
        isEditable={!isDefault}
        isLoading={isEditSaving}
        heading={
          <div>
            <Link
              className="o-layout__unstyled-element mba-back-arrow"
              title="Go back to all ranking types"
              to={RANKING_TYPES_MAIN_TABLE_ROUTE}
            >
              <Icon name="arrow-left" size={30} />
            </Link>
            <span className="mba-center--vertically">{name}</span>
          </div>
        }
        onEditSubmit={updateRankingType}
        onEditCancel={resetFormState}
      />
      <hr className="mba-separator" />
      <EditableGroup<RankingTypeDTO>
        data={currentRankingType}
        editButtonLabel="Edit description"
        formConfig={RANKING_TYPE_DESCRIPTION_CONFIG}
        formErrors={formErrors}
        isEditable={!isDefault}
        isLoading={isEditSaving}
        heading={
          <Typography component="p" variant={TypographyVariants.h3} className="mba-heading--title-2">
            Description
          </Typography>
        }
        onEditSubmit={updateRankingType}
        onEditCancel={resetFormState}
      />
      <hr className="mba-separator" />
      <EditableGroup<RankingTypeDTO>
        data={currentRankingType}
        editButtonLabel="Edit note"
        formConfig={RANKING_TYPE_NOTE_CONFIG}
        formErrors={formErrors}
        isEditable={!isDefault}
        isLoading={isEditSaving}
        heading={
          <Typography component="p" variant={TypographyVariants.h3} className="mba-heading--title-2">
            Note
          </Typography>
        }
        onEditSubmit={updateRankingType}
        onEditCancel={resetFormState}
      />
    </>
  );
};

export default RankingTypeProfileContent;

import { ColumnDefinition, TableHeaderColumnTypes } from '../../../../../components/Table';

export const RANKING_SPREADSHEETS_TABLE_COLUMN_DEFINITION: ColumnDefinition[] = [
  {
    id: 'label',
    sorting: true,
    title: 'Label',
  },
  {
    id: 'dummyValue',
    sorting: true,
    title: 'Default Value',
  },
  {
    id: 'isRequired',
    title: 'Is required',
    sorting: false,
    type: TableHeaderColumnTypes.boolean,
    width: '70px',
  },
  {
    id: 'isEmail',
    title: 'Is email',
    sorting: false,
    type: TableHeaderColumnTypes.boolean,
    width: '70px',
  },
  {
    id: 'isLanguage',
    title: 'Is language',
    sorting: false,
    type: TableHeaderColumnTypes.boolean,
    width: '70px',
  },
  {
    id: 'createdAt',
    title: 'Created date',
    type: TableHeaderColumnTypes.date,
    sorting: true,
    width: '130px',
  },
];

import { CreateSchoolUserDTO, SchoolUserDTO, SchoolUserRolesAndPermissionsDTO } from './school-user-profile.dto';
import {
  CLEAR_CURRENT_SCHOOL_USER,
  ClearCurrentSchoolUserAction,
  CREATE_SCHOOL_USER,
  CreateSchoolUserAction,
  DELETE_SCHOOL_USER,
  DeleteSchoolUserAction,
  LOAD_SCHOOL_USER,
  LoadSchoolUserAction,
  SET_SCHOOL_USER,
  SetSchoolUserAction,
  UPDATE_SCHOOL_USER_ROLES_AND_PERMISSIONS,
  UpdateSchoolUserRolesAndPermissionsAction,
  EDIT_SCHOOL_USER,
  EditSchoolUserAction,
} from './school-user-profile.types';

export const createNewSchoolUserAction = (data: CreateSchoolUserDTO, schoolId: number): CreateSchoolUserAction => ({
  type: CREATE_SCHOOL_USER,
  payload: { data, schoolId },
});

export const loadSchoolUserAction = (id: number, schoolId: number): LoadSchoolUserAction => ({
  type: LOAD_SCHOOL_USER,
  payload: { id, schoolId },
});

export const updateSchoolUserRoleAndPermissionsAction = (
  data: SchoolUserRolesAndPermissionsDTO,
  partnershipId: number,
  schoolId: number,
  userId: number,
): UpdateSchoolUserRolesAndPermissionsAction => ({
  type: UPDATE_SCHOOL_USER_ROLES_AND_PERMISSIONS,
  payload: { data, partnershipId, schoolId, userId },
});

export const deleteSchoolUserAction = (
  id: number,
  schoolId: number,
  ignoreWarnings = false,
): DeleteSchoolUserAction => ({
  type: DELETE_SCHOOL_USER,
  payload: { id, ignoreWarnings, schoolId },
});

export const setSchoolUserAction = (data: SchoolUserDTO, schoolId: number): SetSchoolUserAction => ({
  type: SET_SCHOOL_USER,
  payload: { data, schoolId },
});

export const clearCurrentSchoolUserAction = (): ClearCurrentSchoolUserAction => ({
  type: CLEAR_CURRENT_SCHOOL_USER,
});

export const editSchoolUserAction = (data: SchoolUserDTO, schoolId: number): EditSchoolUserAction => ({
  type: EDIT_SCHOOL_USER,
  payload: { data, schoolId },
});

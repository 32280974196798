import moment from 'moment-timezone';
import { push, RouterAction } from 'react-router-redux';
import { call, CallEffect, put, PutEffect, select, SelectEffect, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  loadRankingAction,
  loadRankingParticipationCriteriaAction,
  loadRankingWelcomeTextAction,
  setRankingAction,
  setRankingParticipationCriteriaAction,
  setRankingWelcomeTextAction,
} from './ranking-profile.actions';
import {
  createRankingRequest,
  deleteRankingRequest,
  getRankingParticipationCriteriaRequest,
  getRankingRequest,
  getRankingWelcomeTextRequest,
  publishRankingRequest,
  updateRankingRequest,
} from './ranking-profile.api';
import { RankingDTO, SaveRankingDTO } from './ranking-profile.dto';
import {
  CLEAR_CURRENT_RANKING,
  CREATE_RANKING,
  CreateRankingAction,
  DELETE_RANKING,
  DeleteRankingAction,
  EDIT_RANKING,
  EDIT_RANKING_DETAILS,
  EditRankingAction,
  EditRankingDetailsAction,
  LOAD_RANKING,
  LoadRankingAction,
  RankingActionTypes,
  LOAD_RANKING_WELCOME_TEXT,
  LoadRankingWelcomeTextAction,
  LOAD_RANKING_PARTICIPATION_CRITERIA,
  LoadRankingParticipationCriteriaAction,
  PublishRankingAction,
  PUBLISH_RANKING,
} from './ranking-profile.types';
import { loadRankingsAction, RankingsActionTypes, selectRankingsPageConfiguration } from '../../RankingsList';
import { BasicResponseDTO, DefaultResponseDTO, PageSearchQueryConfig, Resource } from '../../../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../../../shared/state/global-request';
import {
  getResourcesRequest,
  LOAD_RESOURCES,
  loadResourcesAction,
  ResourcesActionTypes,
  setResourcesAction,
} from '../../../../../shared/state/resources';

function* updateYearFiltersSaga(): Generator<
  | PutEffect<GlobalRequestActions | ResourcesActionTypes | RankingsActionTypes>
  | CallEffect<BasicResponseDTO | DefaultResponseDTO<Resource[]>>
  | SelectEffect
> {
  const pageConfig = (yield select(selectRankingsPageConfiguration)) as PageSearchQueryConfig;
  yield put(setRequestStartedAction(LOAD_RESOURCES));
  const { data: publicationYearsFilters } = (yield call(
    getResourcesRequest,
    'rankings/publication-years',
  )) as DefaultResponseDTO<Resource[]>;
  yield put(setResourcesAction('rankings/publication-years', publicationYearsFilters));
  yield put(setRequestSucceededAction(LOAD_RESOURCES));

  const filterableYears = publicationYearsFilters.map((resource) => resource.key);
  const currentSelectedYears = pageConfig.filter && pageConfig.filter.year ? pageConfig.filter.year : [];

  yield put(
    loadRankingsAction({
      ...pageConfig,
      filter: {
        ...pageConfig.filter,
        year: currentSelectedYears.filter((year) => filterableYears.indexOf(+year) >= 0),
      },
    }),
  );
}

function* createNewRankingSaga(
  action: CreateRankingAction,
): Generator<
  | PutEffect<GlobalRequestActions | RankingActionTypes | RankingsActionTypes | ResourcesActionTypes>
  | CallEffect<DefaultResponseDTO<RankingDTO>>
> {
  const {
    type: actionType,
    payload: {
      data: { publicationDate, ...rest },
    },
  } = action;

  let rankingData: SaveRankingDTO = {
    ...rest,
  };

  if (publicationDate) {
    const date = moment(publicationDate, 'DD/MM/YYYY');
    const endTimeTimestamp = date.endOf('day').unix() * 1000;

    rankingData = {
      ...rankingData,
      publicationDate: endTimeTimestamp,
    };
  }

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(createRankingRequest, rankingData);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadResourcesAction('rankings/publication-years'));
    yield put(loadRankingsAction());
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* loadRankingSaga(
  action: LoadRankingAction,
): Generator<CallEffect<DefaultResponseDTO<RankingDTO>> | PutEffect<GlobalRequestActions | RankingActionTypes>> {
  const {
    type: actionType,
    payload: { id },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getRankingRequest, id)) as DefaultResponseDTO<RankingDTO>;
    yield put(setRankingAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* loadRankingWelcomeTextSaga(
  action: LoadRankingWelcomeTextAction,
): Generator<CallEffect<DefaultResponseDTO<string>> | PutEffect<GlobalRequestActions | RankingActionTypes>> {
  const {
    type: actionType,
    payload: { id, prev },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getRankingWelcomeTextRequest, id, prev)) as DefaultResponseDTO<string>;
    yield put(setRankingWelcomeTextAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* loadRankingParticipationCriteriaSaga(
  action: LoadRankingParticipationCriteriaAction,
): Generator<CallEffect<DefaultResponseDTO<string>> | PutEffect<GlobalRequestActions | RankingActionTypes>> {
  const {
    type: actionType,
    payload: { id, prev },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getRankingParticipationCriteriaRequest, id, prev)) as DefaultResponseDTO<string>;
    yield put(setRankingParticipationCriteriaAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* editRankingSaga(
  action: EditRankingAction,
): Generator<PutEffect<GlobalRequestActions | RankingsActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect> {
  const {
    type: actionType,
    payload: {
      data: { publicationDate, ...rest },
    },
  } = action;

  let rankingData: SaveRankingDTO = {
    ...rest,
  };

  if (publicationDate) {
    const date = moment(publicationDate, 'DD/MM/YYYY');
    const endTimeTimestamp = date.endOf('day').unix() * 1000;

    rankingData = {
      ...rankingData,
      publicationDate: endTimeTimestamp,
    };
  }

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateRankingRequest, rankingData);
    yield put(setRequestSucceededAction(actionType));
    const pageConfig = (yield select(selectRankingsPageConfiguration)) as PageSearchQueryConfig;
    yield put(loadRankingsAction(pageConfig));
    yield call(updateYearFiltersSaga);
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* editRankingDetailsSaga(
  action: EditRankingDetailsAction,
): Generator<
  | PutEffect<
      | GlobalRequestActions
      | RankingsActionTypes
      | LoadRankingAction
      | LoadRankingWelcomeTextAction
      | LoadRankingParticipationCriteriaAction
    >
  | CallEffect<BasicResponseDTO>
> {
  const {
    type: actionType,
    payload: { data },
  } = action;
  let updatedData: SaveRankingDTO = { ...data };

  if (data.publicationDate) {
    const date = moment(data.publicationDate);
    const endTimeTimestamp = date.endOf('day').unix() * 1000;

    updatedData = {
      ...updatedData,
      publicationDate: endTimeTimestamp,
    };
  }

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateRankingRequest, updatedData);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadRankingAction(data.id));
    yield put(loadRankingWelcomeTextAction(data.id));
    yield put(loadRankingParticipationCriteriaAction(data.id));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* publishRankingSaga(
  action: PublishRankingAction,
): Generator<PutEffect<GlobalRequestActions | RankingsActionTypes | LoadRankingAction> | CallEffect<BasicResponseDTO>> {
  const {
    type: actionType,
    payload: { id },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(publishRankingRequest, id);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadRankingAction(id));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* deleteRankingSaga(
  action: DeleteRankingAction,
): Generator<PutEffect<GlobalRequestActions | RouterAction> | CallEffect<BasicResponseDTO>> {
  const {
    type: actionType,
    payload: { id },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(deleteRankingRequest, id);
    yield put(setRequestSucceededAction(actionType));
    yield put(push('/rankings'));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* resetRankingErrors(): Generator<PutEffect<GlobalRequestActions>> {
  yield put(setRequestSucceededAction(CREATE_RANKING));
  yield put(setRequestSucceededAction(EDIT_RANKING));
  yield put(setRequestSucceededAction(EDIT_RANKING_DETAILS));
  yield put(setRequestSucceededAction(DELETE_RANKING));
}

export default function* rankingProfileSaga(): Generator {
  yield takeLatest(CREATE_RANKING, createNewRankingSaga);
  yield takeLatest(LOAD_RANKING, loadRankingSaga);
  yield takeLatest(LOAD_RANKING_WELCOME_TEXT, loadRankingWelcomeTextSaga);
  yield takeLatest(LOAD_RANKING_PARTICIPATION_CRITERIA, loadRankingParticipationCriteriaSaga);
  yield takeLatest(EDIT_RANKING, editRankingSaga);
  yield takeLatest(EDIT_RANKING_DETAILS, editRankingDetailsSaga);
  yield takeLatest(DELETE_RANKING, deleteRankingSaga);
  yield takeEvery(CLEAR_CURRENT_RANKING, resetRankingErrors);
  yield takeLatest(PUBLISH_RANKING, publishRankingSaga);
}

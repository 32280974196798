import { RolesActionTypes, RolesState, SET_ROLES } from './roles.types';

const initialState: RolesState = {};

const rolesReducer = (state = initialState, action: RolesActionTypes): RolesState => {
  switch (action.type) {
    case SET_ROLES: {
      return {
        ...state,
        [action.payload.rolesType]: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default rolesReducer;

import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import Button, { ButtonSizes } from '../../../../../../components/Button';
import Typography from '../../../../../../components/Typography';
import { selectRequestIsLoading } from '../../../../../../shared/state/global-request';
import { DELETE_RANKING_TYPE } from '../../ranking-type-profile.types';

type RankingTypeConfirmationProps = {
  error: string;
  name: string;
  onCancel: () => void;
  onConfirm: () => void;
};

const RankingTypeDeleteConfirmation: FunctionComponent<RankingTypeConfirmationProps> = ({
  error,
  name,
  onCancel,
  onConfirm,
}) => {
  const isDeleting = useSelector(selectRequestIsLoading(DELETE_RANKING_TYPE));

  return (
    <>
      <Typography>
        {error ? (
          <span className="mba-text--error">
            {error}
            <br />
          </span>
        ) : (
          <>
            Are you sure you want to delete:
            <br />
            <strong>&quot;{name}&quot;</strong>
            <br />
            <br />
            After deletion, all rankings under this ranking type will become <strong>&quot;Unclassified&quot;</strong>!
          </>
        )}
      </Typography>
      <div className="mba-actions">
        <Button onClick={onCancel} danger text="Cancel" size={ButtonSizes.big} />
        {!error && (
          <Button onClick={onConfirm} primary danger text="Confirm" isLoading={isDeleting} size={ButtonSizes.big} />
        )}
      </div>
    </>
  );
};

export default RankingTypeDeleteConfirmation;

import { del, get, post, put } from '../../../shared/api';
import { DefaultResponseDTO } from '../../../shared/constants';
import { SchoolWidgetGroupDto } from '../SchoolWidgetGroups/school-widget-groups.dto';
import {
  NewWidgetDTO,
  WidgetDTO,
  WidgetOrderBodyDTO,
  WidgetOrderDTO,
} from './components/EditableWidget/EditableWidget.constants';

export const getSchoolRankingWidgetsRequest = (
  schoolWidgetGroupId: string,
): Promise<DefaultResponseDTO<WidgetDTO[]>> => {
  return get<DefaultResponseDTO<WidgetDTO[]>>(`/school-ranking-widget/group/${schoolWidgetGroupId}`);
};

export const updateSchoolRankingWidgetRequest = (widget: WidgetDTO): Promise<DefaultResponseDTO<WidgetDTO>> => {
  return put<DefaultResponseDTO<WidgetDTO>, WidgetDTO>(`/school-ranking-widget/${widget.id}`, widget);
};

export const updateSchoolWidgetOrderRequest = (
  schoolWidgetGroupId: string,
  widgets: WidgetOrderDTO[],
): Promise<DefaultResponseDTO<undefined>> => {
  return put<DefaultResponseDTO<undefined>, WidgetOrderBodyDTO>(
    `/school-ranking-widget/group/${schoolWidgetGroupId}/order`,
    { widgetOrders: widgets },
  );
};

export const createSchoolRankingWidgetRequest = (widget: NewWidgetDTO): Promise<DefaultResponseDTO<WidgetDTO>> => {
  return post(`/school-ranking-widget`, widget);
};

export const deleteSchoolRankingWidgetRequest = (widgetId: number): Promise<DefaultResponseDTO<boolean>> => {
  return del(`/school-ranking-widget/${widgetId}`);
};

export const getSchoolWidgetGroupRequest = (id: number): Promise<DefaultResponseDTO<SchoolWidgetGroupDto>> => {
  return get<DefaultResponseDTO<SchoolWidgetGroupDto>>(`/school-ranking-widget-group/${id}`);
};

import React, {
  Fragment,
  FunctionComponent,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import Button, { ButtonSizes } from '../../../../../../../components/Button';
import { IconDelete } from '../../../../../../../components/CustomIcons';
import EditableGroup from '../../../../../../../components/EditableGroup';
import { EditableGroupProps } from '../../../../../../../components/EditableGroup/EditableGroup';
import FileUpload from '../../../../../../../components/FileUpload';
import { FormDataValues } from '../../../../../../../components/Form';
import Grid from '../../../../../../../components/Grid';
import Typography, { TypographyVariants } from '../../../../../../../components/Typography';
import {
  selectRequestErrorMessage,
  selectRequestFormErrors,
  selectRequestIsLoading,
  selectRequestStatus,
} from '../../../../../../../shared/state/global-request';
import { RankingDTO, selectCurrentRanking } from '../../../RankingInfo';
import {
  deleteStepAttachmentAction,
  downloadStepAttachmentAction,
  uploadAttachmentAction,
} from '../ranking-timeline-steps.actions';
import { RankingTimelineStepDTO } from '../ranking-timeline-steps.dto';
import { DOWNLOAD_STEP_ATTACHMENT, UPLOAD_STEP_ATTACHMENT } from '../ranking-timeline-steps.types';
import { DOWNLOAD_STEP_ATTACHMENT_ERROR, UPLOAD_STEP_ATTACHMENT_SUCCESS } from './TimelineSteps.constants';
import ErrorMessage from '../../../../../../../components/ErrorMessage/ErrorMessage';

type EditableTimelineStepProps = EditableGroupProps<RankingTimelineStepDTO> & {
  uploadButtonLabel?: string;
};

const EditableAttachmentStep: FunctionComponent<EditableTimelineStepProps> = ({
  data: step,
  onEditCancel,
  onEditSubmit,
  uploadButtonLabel,
  ...editableOptions
}) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const currentRanking = useSelector(selectCurrentRanking) as RankingDTO;

  const downloadAttachmentStatus = useSelector(selectRequestStatus(DOWNLOAD_STEP_ATTACHMENT));
  const isLoading = useSelector(selectRequestIsLoading(UPLOAD_STEP_ATTACHMENT));
  const { isSuccess } = useSelector(selectRequestStatus(UPLOAD_STEP_ATTACHMENT));
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);

  const errors = useSelector(selectRequestFormErrors(UPLOAD_STEP_ATTACHMENT));
  const errorMessage = useSelector(selectRequestErrorMessage(UPLOAD_STEP_ATTACHMENT));

  const { id: rankingId } = currentRanking;

  useEffect(() => {
    if (!downloadAttachmentStatus.isLoading && downloadAttachmentStatus.isSuccess === false) {
      addToast(DOWNLOAD_STEP_ATTACHMENT_ERROR, { appearance: 'error' });
    }
  }, [addToast, downloadAttachmentStatus]);

  useEffect(() => {
    if (isUploadModalOpen && isSuccess) {
      setIsUploadModalOpen(false);
      addToast(UPLOAD_STEP_ATTACHMENT_SUCCESS, { appearance: 'success' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    setIsDeleted(false);
  }, [step]);

  const additionalActions = step.endDate ? (
    <Button
      text={uploadButtonLabel ?? 'Upload attachment'}
      size={ButtonSizes.big}
      wrapperClass="mba-mr-10"
      onClick={(): void => {
        openUploadModal();
      }}
    />
  ) : (
    <></>
  );

  const handleDownload = (event: MouseEvent): void => {
    event.preventDefault();
    dispatch(
      downloadStepAttachmentAction({
        rankingId,
        stepId: step.id,
        attachmentName: step.attachment?.name,
      }),
    );
  };

  const handleEditableChange = (isEditOpen: boolean): void => {
    setIsEditing(isEditOpen);
  };

  const handleSave = (data: FormDataValues): void => {
    onEditSubmit && onEditSubmit(data);

    if (isDeleted) {
      deleteAttachmentFile(step.id);
    }
  };

  const handleCancel = (): void => {
    setIsDeleted(false);
    onEditCancel && onEditCancel();
  };

  const uploadAttachment = (file: File): void => {
    const formData = new FormData();
    formData.append('file', file);

    dispatch(uploadAttachmentAction({ rankingId, stepId: step.id, spreadsheet: formData }));
  };

  const openUploadModal = (): void => {
    setIsUploadModalOpen(true);
  };

  const closeUploadModal = (): void => {
    setIsUploadModalOpen(false);
  };

  const deleteAttachmentFile = useCallback(
    (stepId: number): void => {
      if (stepId) {
        dispatch(deleteStepAttachmentAction({ rankingId, stepId }));
      }
    },
    [dispatch, rankingId],
  );

  const renderAttachment = useMemo((): ReactNode => {
    return step && step.attachment ? (
      <Grid container>
        <div className="form-group-title">
          <Typography component="span" variant={TypographyVariants.h5}>
            Attachments
          </Typography>
          {!isDeleted && step.attachment && (
            <div className="mba-font--14 mba-mt-10 mba-flex mba-item-center">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
              <a role="button" className="mba-mr-20" onClick={handleDownload}>
                {step.attachment.name}
              </a>
              {isEditing && (
                <button
                  className="mba-text--danger mba-button--non-styled mba-flex mba-item-center mba-font--14"
                  onClick={(): void => setIsDeleted((prevState) => !prevState)}
                >
                  <IconDelete width="14" height="14" />
                  <span className="mba-ml-5">Delete</span>
                </button>
              )}
            </div>
          )}
        </div>
      </Grid>
    ) : (
      <></>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, isDeleted, isEditing]);

  return (
    <div>
      <EditableGroup<RankingTimelineStepDTO>
        data={step}
        additionalActions={additionalActions}
        onEditableChange={handleEditableChange}
        onEditSubmit={handleSave}
        onEditCancel={handleCancel}
        {...editableOptions}
      />
      {renderAttachment}
      <FileUpload
        title={uploadButtonLabel ?? 'Upload attachment'}
        isLoading={isLoading}
        isOpen={isUploadModalOpen}
        onClose={closeUploadModal}
        onUpload={uploadAttachment}
        errorMessage={<ErrorMessage errors={errors} errorMessage={errorMessage} />}
      />
    </div>
  );
};

export default EditableAttachmentStep;

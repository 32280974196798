import { ColumnDefinition, TableHeaderColumnTypes } from '../../../components/Table';

export const LEADS_TABLE_COLUMN_DEFINITION: ColumnDefinition[] = [
  {
    id: 'status',
    title: 'Status',
    type: TableHeaderColumnTypes.status,
    sorting: true,
    width: '90px',
  },
  {
    id: 'user.name',
    sorting: true,
    title: 'Name',
    type: TableHeaderColumnTypes.compositeLink,
    path: '/commercial/lead-gen/:schoolId/leads/:leadId',
  },
  {
    id: 'question.experience',
    sorting: true,
    title: 'Years of professional experience',
    type: TableHeaderColumnTypes.link,
  },
  {
    id: 'user.country',
    sorting: true,
    title: 'Country',
    type: TableHeaderColumnTypes.link,
  },
  {
    id: 'createdAt',
    sorting: true,
    title: 'Date created',
    type: TableHeaderColumnTypes.date,
  },
];

import React, { FunctionComponent } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { TabNavigationLink } from './TabsNavigation.constants';

import styles from './TabsNavigation.module.scss';

type TabsNavigationProps = {
  big?: boolean;
  inverse?: boolean;
  tabs: TabNavigationLink[];
};

const TabsNavigation: FunctionComponent<TabsNavigationProps> = ({ big = false, inverse = false, tabs }) => {
  const { pathname } = useLocation();
  const wrapperClasses = [
    'o-tabs',
    'o-tabs--buttontabs',
    'o-tabs--secondary',
    'o-layout__unstyled-element',
    styles.tabLinkWrapper,
  ];
  const tabClasses = [styles.tabLink, 'o-layout__unstyled-element'];

  if (big) {
    wrapperClasses.push('o-tabs--big');
    tabClasses.push(styles.bigTab);
  }

  if (inverse) {
    wrapperClasses.push('o-tabs--inverse');
  }

  return (
    <div data-o-component="o-tabs" className={wrapperClasses.join(' ')} role="tablist" data-o-tabs--js="">
      {tabs.map(({ label, route }, index: number) => (
        <NavLink
          className={tabClasses.join(' ')}
          to={route}
          role="tab"
          key={`tab-${index}`}
          aria-selected={pathname && pathname.includes(route) ? 'true' : 'false'}
        >
          {label}
        </NavLink>
      ))}
    </div>
  );
};

export default TabsNavigation;

import { RankingDTO, RankingSpecialReportDTO, SaveRankingDTO } from './ranking-profile.dto';
import { del, get, post, put } from '../../../../../shared/api';
import { BasicResponseDTO, DefaultResponseDTO } from '../../../../../shared/constants';

export const createRankingRequest = (rankingData: SaveRankingDTO): Promise<DefaultResponseDTO<RankingDTO>> => {
  return post<DefaultResponseDTO<RankingDTO>, SaveRankingDTO>('/rankings', rankingData);
};

export const getRankingRequest = (rankingId: number): Promise<DefaultResponseDTO<RankingDTO>> => {
  return get<DefaultResponseDTO<RankingDTO>>(`/rankings/${rankingId}`);
};

export const getRankingWelcomeTextRequest = (rankingId: number, prev: boolean): Promise<DefaultResponseDTO<string>> => {
  return get<DefaultResponseDTO<string>>(`/rankings/${rankingId}/welcome-text?previous=${prev}`);
};

export const getRankingParticipationCriteriaRequest = (
  rankingId: number,
  prev: boolean,
): Promise<DefaultResponseDTO<string>> => {
  return get<DefaultResponseDTO<string>>(`/rankings/${rankingId}/participation-criteria?previous=${prev}`);
};

export const updateRankingRequest = (rankingData: SaveRankingDTO): Promise<BasicResponseDTO> => {
  const { id } = rankingData;
  return put<BasicResponseDTO, SaveRankingDTO>(`/rankings/${id}`, rankingData);
};

export const updateRankingSpecialReportRequest = (
  rankingId: number,
  reportData: RankingSpecialReportDTO,
): Promise<BasicResponseDTO> => {
  return put<BasicResponseDTO, RankingSpecialReportDTO>(`/rankings/${rankingId}/special-report`, reportData);
};

export const publishRankingRequest = (id: number): Promise<BasicResponseDTO> => {
  return put<BasicResponseDTO, SaveRankingDTO>(`/rankings/${id}/publish`);
};

export const deleteRankingRequest = (rankingId: number): Promise<BasicResponseDTO> => {
  return del<BasicResponseDTO>(`/rankings/${rankingId}`);
};

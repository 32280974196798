import { FormConfig, FormControlFieldType } from '../../../../components/Form';
import { ColumnDefinition, TableHeaderColumnTypes } from '../../../../components/Table';

export const USERS_TABLE_COLUMN_DEFINITION: ColumnDefinition[] = [
  {
    id: 'status',
    title: 'Status',
    type: TableHeaderColumnTypes.status,
    sorting: true,
  },
  {
    id: 'name',
    title: 'Name',
    sorting: true,
    width: '180px',
  },
  {
    id: 'email',
    title: 'E-mail',
    sorting: true,
  },
  {
    id: 'role',
    title: 'Role',
    type: TableHeaderColumnTypes.capitalize,
    width: '120px',
  },
  {
    id: 'createdAt',
    title: 'Created',
    sorting: true,
    type: TableHeaderColumnTypes.date,
    width: '110px',
  },
];

export const ADMIN_USER_CREATE_FORM_CONFIG: FormConfig = {
  name: {
    label: 'Name',
    placeholder: `Enter user's name`,
    fieldType: 'text' as FormControlFieldType,
    validations: {
      required: {
        errorText: `Please enter user's name!`,
      },
    },
  },
  email: {
    label: 'E-mail',
    placeholder: `Enter user's e-mail address`,
    fieldType: 'text' as FormControlFieldType,
    validations: {
      required: {
        errorText: `Please enter user's e-mail address!`,
      },
      regex: {
        errorText: 'Please enter a valid e-mail address!',
        rule: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      },
    },
  },
  role: {
    label: 'User role',
    placeholder: `Select user's role`,
    fieldType: 'select' as FormControlFieldType,
    options: [],
    validations: {
      required: {
        errorText: `Please select a role for the user!`,
      },
    },
  },
};

export const ADMIN_USER_EDIT_FORM_CONFIG: FormConfig = {
  name: {
    label: 'Name',
    placeholder: `Enter user's name`,
    fieldType: 'text' as FormControlFieldType,
    validations: {
      required: {
        errorText: `Please enter user's name!`,
      },
    },
  },
  role: {
    label: 'User role',
    placeholder: `Select user's role`,
    fieldType: 'select' as FormControlFieldType,
    options: [],
    validations: {
      required: {
        errorText: `Please select a role for the user!`,
      },
    },
  },
  status: {
    label: 'Status',
    placeholder: `Select user's status`,
    fieldType: 'select' as FormControlFieldType,
    options: [],
    required: true,
  },
};

export const USER_CREATION_CONFIRM_MSG = 'The account has been created successfully.';

import {
  LOAD_RANKING_TYPES,
  LoadRankingTypesAction,
  SET_RANKING_TYPES,
  SetRankingTypesAction,
} from './ranking-types.types';
import { RankingTypeDTO } from '../RankingTypeProfile';
import { PageSearchQueryConfig, TableListData } from '../../../../shared/constants';

export const loadRankingTypesAction = (pageConfig?: PageSearchQueryConfig): LoadRankingTypesAction => ({
  type: LOAD_RANKING_TYPES,
  payload: { pageConfig },
});

export const setRankingTypesAction = (tableData: TableListData<RankingTypeDTO>): SetRankingTypesAction => ({
  type: SET_RANKING_TYPES,
  payload: { tableData },
});

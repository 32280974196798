import React from 'react';

import Typography, { TypographyVariants } from '../../components/Typography';

const Home: React.FunctionComponent = () => (
  <Typography component="h1" variant={TypographyVariants.h1}>
    MBA Rankings Admin v2.0.0
  </Typography>
);

export default Home;

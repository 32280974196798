import { FormConfig } from '../../../../../../../components/Form';
import { DATE_FORMAT_REGEX } from '../../../../../../../shared/constants';

export const RANKING_TIMELINE_STEP_1_CONFIG: FormConfig = {
  endDate: {
    fieldType: 'date',
    placeholder: 'Enter date DD/MM/YYYY',
    previewFormat: 'Do MMM YYYY',
    required: true,
    title: 'Deadline',
    validations: {
      regex: {
        errorText: 'Please enter a valid date DD/MM/YYYY',
        // eslint-disable-next-line no-useless-escape
        rule: DATE_FORMAT_REGEX,
      },
    },
  },
  isOptional: {
    fieldType: 'checkbox',
    label: 'If checked, this step will not appear in the School portal',
    title: 'Exclude from current ranking',
  },
};

export const RANKING_TIMELINE_STEP_2_CONFIG: FormConfig = {
  endDate: {
    fieldType: 'date',
    placeholder: 'Enter date DD/MM/YYYY',
    previewFormat: 'Do MMM YYYY',
    required: true,
    title: 'Deadline',
    validations: {
      regex: {
        errorText: 'Please enter a valid date DD/MM/YYYY',
        // eslint-disable-next-line no-useless-escape
        rule: DATE_FORMAT_REGEX,
      },
    },
  },
  isOptional: {
    fieldType: 'checkbox',
    label: 'If checked, this step will not appear in the School portal',
    title: 'Exclude from current ranking',
  },
  templateId: {
    fieldType: 'select',
    placeholder: 'Choose a template',
    required: true,
    title: 'Spreadsheet template',
    options: [],
  },
  instructions: {
    fieldType: 'richText',
    placeholder: 'Enter instructions',
    title: 'Instructions',
  },
};

export const RANKING_TIMELINE_STEP_3_CONFIG: FormConfig = {
  endDate: {
    fieldType: 'date',
    placeholder: 'Enter date DD/MM/YYYY',
    previewFormat: 'Do MMM YYYY',
    required: true,
    title: 'Deadline',
    validations: {
      regex: {
        errorText: 'Please enter a valid date DD/MM/YYYY',
        // eslint-disable-next-line no-useless-escape
        rule: DATE_FORMAT_REGEX,
      },
    },
  },
  isOptional: {
    fieldType: 'checkbox',
    label: 'If checked, this step will not appear in the School portal',
    title: 'Exclude from current ranking',
  },
  templateId: {
    fieldType: 'select',
    placeholder: 'Choose a template',
    required: true,
    title: 'Spreadsheet template',
    options: [],
  },
  instructions: {
    fieldType: 'richText',
    placeholder: 'Enter instructions',
    title: 'Instructions',
  },
};

export const RANKING_TIMELINE_STEP_4_CONFIG: FormConfig = {
  endDate: {
    fieldType: 'date',
    placeholder: 'Enter date DD/MM/YYYY',
    previewFormat: 'Do MMM YYYY',
    required: true,
    title: 'Deadline',
    validations: {
      regex: {
        errorText: 'Please enter a valid date DD/MM/YYYY',
        // eslint-disable-next-line no-useless-escape
        rule: DATE_FORMAT_REGEX,
      },
    },
  },
  isOptional: {
    fieldType: 'checkbox',
    label: 'If checked, this step will not appear in the School portal',
    title: 'Exclude from current ranking',
  },
  templateId: {
    fieldType: 'select',
    placeholder: 'Choose a template',
    required: true,
    title: 'Spreadsheet template',
    options: [],
  },
  instructions: {
    fieldType: 'richText',
    placeholder: 'Enter instructions',
    title: 'Instructions',
  },
};

export const RANKING_TIMELINE_STEP_5_CONFIG: FormConfig = {
  startDate: {
    fieldType: 'date',
    placeholder: 'DD/MM/YYYY',
    previewFormat: 'Do MMM YYYY',
    required: true,
    title: 'Start',
    validations: {
      regex: {
        errorText: 'Please enter a valid date DD/MM/YYYY',
        // eslint-disable-next-line no-useless-escape
        rule: DATE_FORMAT_REGEX,
      },
    },
  },
  endDate: {
    fieldType: 'date',
    placeholder: 'DD/MM/YYYY',
    previewFormat: 'Do MMM YYYY',
    required: true,
    title: 'End',
    validations: {
      regex: {
        errorText: 'Please enter a valid date DD/MM/YYYY',
        // eslint-disable-next-line no-useless-escape
        rule: DATE_FORMAT_REGEX,
      },
    },
  },
  isOptional: {
    fieldType: 'checkbox',
    label: 'If checked, this step will not appear in the School portal',
    title: 'Exclude from current ranking',
  },
  surveyId: {
    fieldType: 'text',
    placeholder: 'Enter survey ID',
    title: 'Survey ID',
  },
};

export const RANKING_TIMELINE_STEP_6_CONFIG: FormConfig = {
  startDate: {
    fieldType: 'date',
    placeholder: 'DD/MM/YYYY',
    previewFormat: 'Do MMM YYYY',
    required: true,
    title: 'Start',
    validations: {
      regex: {
        errorText: 'Please enter a valid date DD/MM/YYYY',
        // eslint-disable-next-line no-useless-escape
        rule: DATE_FORMAT_REGEX,
      },
    },
  },
  endDate: {
    fieldType: 'date',
    placeholder: 'DD/MM/YYYY',
    previewFormat: 'Do MMM YYYY',
    required: true,
    title: 'End',
    validations: {
      regex: {
        errorText: 'Please enter a valid date DD/MM/YYYY',
        // eslint-disable-next-line no-useless-escape
        rule: DATE_FORMAT_REGEX,
      },
    },
  },
  isOptional: {
    fieldType: 'checkbox',
    label: 'If checked, this step will not appear in the School portal',
    title: 'Exclude from current ranking',
  },
  surveyId: {
    fieldType: 'text',
    placeholder: 'Enter survey ID',
    title: 'Survey ID',
  },
};

export const RANKING_TIMELINE_STEP_7_CONFIG: FormConfig = {
  startDate: {
    fieldType: 'date',
    placeholder: 'DD/MM/YYYY',
    previewFormat: 'Do MMM YYYY',
    required: true,
    title: 'Start',
    validations: {
      regex: {
        errorText: 'Please enter a valid date DD/MM/YYYY',
        // eslint-disable-next-line no-useless-escape
        rule: DATE_FORMAT_REGEX,
      },
    },
  },
  endDate: {
    fieldType: 'date',
    placeholder: 'DD/MM/YYYY',
    previewFormat: 'Do MMM YYYY',
    required: true,
    title: 'End',
    validations: {
      regex: {
        errorText: 'Please enter a valid date DD/MM/YYYY',
        // eslint-disable-next-line no-useless-escape
        rule: DATE_FORMAT_REGEX,
      },
    },
  },
};

export const RANKING_TIMELINE_STEP_8_CONFIG: FormConfig = {
  publicationDate: {
    fieldType: 'date',
    placeholder: 'Enter date DD/MM/YYYY',
    previewFormat: 'Do MMM YYYY',
    required: true,
    title: 'Date',
    validations: {
      regex: {
        errorText: 'Please enter a valid date DD/MM/YYYY',
        // eslint-disable-next-line no-useless-escape
        rule: DATE_FORMAT_REGEX,
      },
    },
  },
};

export const RANKING_TIMELINE_STEP_9_CONFIG: FormConfig = {
  startDate: {
    fieldType: 'date',
    placeholder: 'DD/MM/YYYY',
    previewFormat: 'Do MMM YYYY',
    required: true,
    title: 'Start',
    validations: {
      regex: {
        errorText: 'Please enter a valid date DD/MM/YYYY',
        // eslint-disable-next-line no-useless-escape
        rule: DATE_FORMAT_REGEX,
      },
    },
  },
  endDate: {
    fieldType: 'date',
    placeholder: 'Enter date DD/MM/YYYY',
    previewFormat: 'Do MMM YYYY',
    required: true,
    title: 'Deadline',
    validations: {
      regex: {
        errorText: 'Please enter a valid date DD/MM/YYYY',
        // eslint-disable-next-line no-useless-escape
        rule: DATE_FORMAT_REGEX,
      },
    },
  },
  isOptional: {
    fieldType: 'checkbox',
    label: 'If checked, this step will not appear in the School portal',
    title: 'Exclude from current ranking',
    value: true,
  },
  templateId: {},
  instructions: {
    fieldType: 'richText',
    placeholder: 'Enter instructions',
    title: 'Instructions',
  },
};

export const RANKING_TIMELINE_STEP_10_CONFIG: FormConfig = {
  endDate: {
    fieldType: 'date',
    placeholder: 'Enter date DD/MM/YYYY',
    previewFormat: 'Do MMM YYYY',
    required: true,
    title: 'Deadline',
    validations: {
      regex: {
        errorText: 'Please enter a valid date DD/MM/YYYY',
        // eslint-disable-next-line no-useless-escape
        rule: DATE_FORMAT_REGEX,
      },
    },
  },
  isOptional: {
    fieldType: 'checkbox',
    label: 'If checked, this step will not appear in the School portal',
    title: 'Exclude from current ranking',
    value: true,
  },
  instructions: {
    fieldType: 'richText',
    placeholder: 'Enter instructions',
    title: 'Instructions',
  },
};

export const RANKING_TIMELINE_STEP_11_CONFIG: FormConfig = {
  startDate: {
    fieldType: 'date',
    placeholder: 'DD/MM/YYYY',
    previewFormat: 'Do MMM YYYY',
    required: true,
    title: 'Start',
    validations: {
      regex: {
        errorText: 'Please enter a valid date DD/MM/YYYY',
        // eslint-disable-next-line no-useless-escape
        rule: DATE_FORMAT_REGEX,
      },
    },
  },
  endDate: {
    fieldType: 'date',
    placeholder: 'Enter date DD/MM/YYYY',
    previewFormat: 'Do MMM YYYY',
    required: true,
    title: 'End',
    validations: {
      regex: {
        errorText: 'Please enter a valid date DD/MM/YYYY',
        // eslint-disable-next-line no-useless-escape
        rule: DATE_FORMAT_REGEX,
      },
    },
  },
  isOptional: {
    fieldType: 'checkbox',
    label: 'If checked, this step will not appear in the School portal',
    title: 'Exclude from current ranking',
    value: true,
  },
  instructions: {
    fieldType: 'richText',
    placeholder: 'Enter instructions',
    title: 'Instructions',
  },
  content: {
    fieldType: 'richText',
    placeholder: 'Enter the alumni letter',
    title: 'Alumni letter',
  },
};

export const RANKING_TIMELINE_STEPS = [
  {
    id: 1,
    config: RANKING_TIMELINE_STEP_1_CONFIG,
  },
  {
    id: 2,
    config: RANKING_TIMELINE_STEP_2_CONFIG,
  },
  {
    id: 3,
    config: RANKING_TIMELINE_STEP_3_CONFIG,
  },
  {
    id: 4,
    config: RANKING_TIMELINE_STEP_4_CONFIG,
  },
  {
    id: 5,
    config: RANKING_TIMELINE_STEP_5_CONFIG,
  },
  {
    id: 6,
    config: RANKING_TIMELINE_STEP_6_CONFIG,
  },
  {
    id: 7,
    config: RANKING_TIMELINE_STEP_7_CONFIG,
  },
  {
    id: 8,
    config: RANKING_TIMELINE_STEP_8_CONFIG,
  },
  {
    id: 9,
    config: RANKING_TIMELINE_STEP_9_CONFIG,
  },
  {
    id: 10,
    config: RANKING_TIMELINE_STEP_10_CONFIG,
  },
  {
    id: 11,
    config: RANKING_TIMELINE_STEP_11_CONFIG,
  },
];

export const DOWNLOAD_STEP_ATTACHMENT_ERROR = 'Failed to download attachment';

export const UPLOAD_STEP_ATTACHMENT_SUCCESS = 'Attachment saved successfully.';

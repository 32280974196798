import moment from 'moment';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Pager, { INITIAL_PAGE } from '../../../../components/Pager';
import Table, { ColumnDefinition } from '../../../../components/Table';
import Typography, { TypographyVariants } from '../../../../components/Typography';
import { ASCENDING_ORDER_DIRECTION, DESCENDING_ORDER_DIRECTION } from '../../../../shared/constants';

import { loadLogsAction } from '../logs.actions';
import { LogsDTO, LogsTableDTO } from '../logs.dto';
import { selectLogsPageConfiguration, selectLogsTableData } from '../logs.selectors';
import { LOGS_TABLE_COLUMN_DEFINITION } from './Logs.constants';
import LogsFiltersAndSearch from './LogsFiltersAndSearch';

const Logs: FunctionComponent = () => {
  const dispatch = useDispatch();
  const pageConfig = useSelector(selectLogsPageConfiguration);
  const selectedTableData = useSelector(selectLogsTableData);

  const convertToLogsData = (selectedTableData: any): LogsTableDTO[] => {
    return selectedTableData.map((log: LogsDTO) => {
      let description = log.event;
      const time = log.date.split('T')[1].split('.')[0];
      const splitedEvent = log.event.split(',');
      const scheduled = splitedEvent.find((item) => item.includes('scheduled'));

      if (scheduled) {
        const scheduledIndex = splitedEvent.findIndex((item) => item.includes('scheduled'));
        const getDate = scheduled.split(':')[1];
        const scheduledDate =
          !!getDate && !Number.isNaN(Number(getDate)) ? moment(Number(getDate)).format('YYYY-MM-DD hh:mm a') : 'no';
        splitedEvent[scheduledIndex] = `scheduled: ${scheduledDate}`;
        description = splitedEvent.join(', ');
      }

      return {
        id: log.id,
        userEmail: log.userEmail,
        description,
        date: log.date,
        time,
      };
    });
  };

  const logsData: any = useMemo(() => convertToLogsData(selectedTableData), [selectedTableData]);

  useEffect(() => {
    dispatch(loadLogsAction());
  }, [dispatch]);

  const handlePageChanged = (page: number): void => {
    dispatch(loadLogsAction({ ...pageConfig, page }));
  };

  const handleSortByChanged = (fieldName: string): void => {
    if (fieldName === 'time') {
      fieldName = 'date';
    }

    if (fieldName === 'description') {
      fieldName = 'id';
    }

    const orderDirection =
      pageConfig.sortBy !== fieldName
        ? DESCENDING_ORDER_DIRECTION
        : pageConfig.order === ASCENDING_ORDER_DIRECTION
        ? DESCENDING_ORDER_DIRECTION
        : ASCENDING_ORDER_DIRECTION;
    dispatch(loadLogsAction({ ...pageConfig, sortBy: fieldName, order: orderDirection }));
  };

  return (
    <>
      <div className="mba-heading--wrapper">
        <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title">
          Logs
        </Typography>
      </div>
      <div className="mba-table-wrapper">
        <div className="mba-table-filters">
          <LogsFiltersAndSearch pageConfig={pageConfig} />
        </div>
        <div className="mba-table-container">
          <Table
            id="logs"
            columns={LOGS_TABLE_COLUMN_DEFINITION}
            data={logsData}
            sortingOptions={{
              order: pageConfig.order || DESCENDING_ORDER_DIRECTION,
              sortBy: pageConfig.sortBy || '',
            }}
            onSortByColumn={handleSortByChanged}
          />
        </div>
      </div>
      {pageConfig && pageConfig.totalPages !== INITIAL_PAGE && (
        <Pager
          currentPage={pageConfig.page}
          totalPages={pageConfig.totalPages || INITIAL_PAGE}
          onPageChange={handlePageChanged}
        />
      )}
    </>
  );
};

export default Logs;

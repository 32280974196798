import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { RANKING_SPREADSHEETS_TABLE_COLUMN_DEFINITION } from './TemplateFieldList.constants';
import TemplateFieldForm from '../TemplateFieldForm/TemplateFieldForm';
import RemoveTemplateFieldPopup from '../RemoveTemplateFieldPopup';
import { SPREADSHEET_TEMPLATE_SUB_NAVIGATION, SPREADSHEETS_TYPES } from '../../spreadsheet-template.constants';
import { SpreadsheetTemplateFieldDTO } from '../../spreadsheet-template.dto';
import {
  clearCurrentSpreadsheetTemplateFieldAction,
  deleteSpreadsheetTemplateFieldAction,
  loadSpreadsheetTemplateFieldsAction,
  setCurrentSpreadsheetTemplateFieldAction,
} from '../../spreadsheet-template.actions';
import {
  selectSpreadsheetTemplateFieldsPageConfiguration,
  selectSpreadsheetTemplateFieldsTableData,
} from '../../spreadsheet-template.selectors';
import Grid from '../../../../../components/Grid';
import Pager, { INITIAL_PAGE } from '../../../../../components/Pager';
import Table, { TableHeaderColumnTypes } from '../../../../../components/Table';
import Typography, { TypographyVariants } from '../../../../../components/Typography';
import { ASCENDING_ORDER_DIRECTION, DESCENDING_ORDER_DIRECTION } from '../../../../../shared/constants';
import Modal, { MODAL_ANIMATION_DURATION } from '../../../../../components/Modal';
import Button, { ButtonIcons, ButtonSizes } from '../../../../../components/Button';

import styles from '../../SpreadsheetTemplate.module.scss';

const TemplateList: FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const [isRankingEdit, setIsRankingEdit] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isFormRemoveModalOpen, setIsFormRemoveModalOpen] = useState(false);
  const [columnIdToRemove, setColumnIdToRemove] = useState(0);

  const dispatch = useDispatch();

  const type = location.pathname.split('/')?.pop() || '';

  if (!(type in SPREADSHEETS_TYPES)) {
    history.push(`/not-found`);
  }

  const pageConfig = useSelector(selectSpreadsheetTemplateFieldsPageConfiguration(type));
  const templateFields = useSelector(selectSpreadsheetTemplateFieldsTableData(type));

  useEffect(() => {
    if (isFormModalOpen) {
      handleCloseModal();
    }

    if (isFormRemoveModalOpen) {
      handleCloseRemovePopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateFields]);

  useEffect(() => {
    if (type) {
      dispatch(loadSpreadsheetTemplateFieldsAction(type));
    }
  }, [dispatch, type]);

  const handlePageChanged = (page: number): void => {
    dispatch(loadSpreadsheetTemplateFieldsAction(type, { ...pageConfig, page }));
  };

  const handleSortByChanged = (fieldName: string): void => {
    const orderDirection =
      pageConfig.sortBy !== fieldName
        ? DESCENDING_ORDER_DIRECTION
        : pageConfig.order === ASCENDING_ORDER_DIRECTION
        ? DESCENDING_ORDER_DIRECTION
        : ASCENDING_ORDER_DIRECTION;
    dispatch(loadSpreadsheetTemplateFieldsAction(type, { ...pageConfig, sortBy: fieldName, order: orderDirection }));
  };

  const handleOpenFormModal = (id?: number): void => {
    if (id) {
      const currentField = templateFields.find((field: SpreadsheetTemplateFieldDTO) => field.id === id);

      if (currentField) {
        dispatch(setCurrentSpreadsheetTemplateFieldAction(type, currentField));
        setIsRankingEdit(true);
      }
    } else {
      setIsRankingEdit(false);
    }

    setIsFormModalOpen(true);
  };

  const handleOpenRemoveModal = (id: number): void => {
    if (id) {
      const currentField = templateFields.find((field: SpreadsheetTemplateFieldDTO) => field.id === id);

      if (currentField) {
        setColumnIdToRemove(id);
        setIsFormRemoveModalOpen(true);
      }
    }
  };

  const handleCloseModal = (): void => {
    setIsFormModalOpen(false);

    setTimeout(() => {
      dispatch(clearCurrentSpreadsheetTemplateFieldAction(type));
    }, MODAL_ANIMATION_DURATION);
  };

  const handleCloseRemovePopup = (): void => {
    setIsFormRemoveModalOpen(false);
    setColumnIdToRemove(0);
  };

  const handleRemoveColumn = (id: number): void => {
    dispatch(deleteSpreadsheetTemplateFieldAction(type, id));
  };

  const spreadsheetFiledTableConfig = [
    ...RANKING_SPREADSHEETS_TABLE_COLUMN_DEFINITION,
    {
      id: 'actions',
      title: 'Action',
      type: TableHeaderColumnTypes.actions,
      width: '150px',
      actions: [
        {
          disableIfEmpty: true,
          label: 'Edit',
          handleOnClick: handleOpenFormModal,
        },
        {
          label: 'Delete',
          handleOnClick: handleOpenRemoveModal,
        },
      ],
    },
  ];

  return (
    <>
      <Grid container>
        <Grid item compact xs={12} md={11}>
          <div className="mba-heading--wrapper">
            <Typography component="p" variant={TypographyVariants.h1} className={styles.subTitle}>
              {SPREADSHEET_TEMPLATE_SUB_NAVIGATION[type]?.label} template columns
            </Typography>
            <Button
              text="Create new column"
              size={ButtonSizes.big}
              icon={ButtonIcons.plus}
              disabled={isFormModalOpen}
              primary
              onClick={(): void => handleOpenFormModal()}
            />
          </div>
          <div className="mba-table-wrapper">
            <Table
              id="spreadsheet-list"
              columns={spreadsheetFiledTableConfig}
              data={templateFields}
              sortingOptions={{
                order: pageConfig.order || DESCENDING_ORDER_DIRECTION,
                sortBy: pageConfig.sortBy || '',
              }}
              onSortByColumn={handleSortByChanged}
            />
          </div>
          {pageConfig && pageConfig.totalPages !== INITIAL_PAGE && (
            <Pager
              currentPage={pageConfig.page}
              totalPages={pageConfig.totalPages || INITIAL_PAGE}
              onPageChange={handlePageChanged}
            />
          )}
        </Grid>
      </Grid>

      {isFormModalOpen && (
        <Modal
          title={`${isRankingEdit ? 'Edit' : 'Create new'} spreadsheet column`}
          isOpen={isFormModalOpen}
          onClose={handleCloseModal}
        >
          <TemplateFieldForm onFormClose={handleCloseModal} isEdit={isRankingEdit} type={type} />
        </Modal>
      )}

      {isFormRemoveModalOpen && (
        <Modal title="Delete Spreadsheet column" isOpen={isFormRemoveModalOpen} onClose={handleCloseRemovePopup}>
          <RemoveTemplateFieldPopup
            columnId={columnIdToRemove}
            onCancel={handleCloseRemovePopup}
            onConfirm={handleRemoveColumn}
          />
        </Modal>
      )}
    </>
  );
};

export default TemplateList;

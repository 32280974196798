import React, { FunctionComponent } from 'react';
import { ContentState } from 'draft-js';

const Link: FunctionComponent<{ contentState: ContentState; entityKey: string }> = ({
  contentState,
  entityKey,
  children,
}) => {
  const { url } = contentState.getEntity(entityKey).getData();
  return <a href={url}>{children}</a>;
};
export default Link;

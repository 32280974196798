import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button, { ButtonSizes, ButtonTypes } from '../../../../../../components/Button';
import Form, { FormConfig, FormDataValues } from '../../../../../../components/Form';
import Grid from '../../../../../../components/Grid';
import Typography, { TypographyVariants } from '../../../../../../components/Typography';
import {
  selectRequestErrors,
  selectRequestIsLoading,
  setRequestSucceededAction,
} from '../../../../../../shared/state/global-request';
import { selectResources } from '../../../../../../shared/state/resources';
import { editSchoolAccreditationsAction } from '../../school-details.actions';
import { EDIT_SCHOOL_ACCREDITATIONS } from '../../school-details.types';

type SchoolAccreditationsProps = {
  schoolAccreditations?: number[];
  schoolId: number;
};

const SchoolAccreditations: FunctionComponent<SchoolAccreditationsProps> = ({
  schoolAccreditations = [],
  schoolId,
}) => {
  const dispatch = useDispatch();
  const accreditations = useSelector(selectResources('accreditations'));
  const isLoading = useSelector(selectRequestIsLoading(EDIT_SCHOOL_ACCREDITATIONS));
  const formErrors = useSelector(selectRequestErrors(EDIT_SCHOOL_ACCREDITATIONS));
  const [isEditing, setIsEditing] = useState(false);
  const [currentAccreditations, setCurrentAccreditations] = useState(schoolAccreditations);

  useEffect(() => {
    if (schoolAccreditations.sort().toString() === currentAccreditations.sort().toString()) {
      setIsEditing(false);
    }
  }, [currentAccreditations, schoolAccreditations]);

  const handleDiscardChangesClick = (): void => {
    dispatch(setRequestSucceededAction(EDIT_SCHOOL_ACCREDITATIONS));

    setIsEditing(false);
    setCurrentAccreditations(schoolAccreditations);
  };

  const handleFormSubmit = (values: FormDataValues): void => {
    const { accreditationIds } = values;

    dispatch(
      editSchoolAccreditationsAction(
        schoolId,
        accreditationIds && Array.isArray(accreditationIds)
          ? accreditationIds.map((accreditationId) => +accreditationId)
          : schoolAccreditations,
      ),
    );
  };

  const handleValuesChanged = (values: FormDataValues): void => {
    if (!isEditing) {
      setIsEditing(true);
    }

    const { accreditationIds: newAccreditationIds } = values;
    if (newAccreditationIds && Array.isArray(newAccreditationIds)) {
      setCurrentAccreditations(newAccreditationIds.map((accreditationId) => +accreditationId));
    }
  };

  const formConfig: FormConfig = {
    accreditationIds: {
      fieldType: 'checkbox',
      options: accreditations.map(({ key, shortLabel, label }) => ({
        text: `${shortLabel} - ${label}`,
        name: key.toString(),
      })),
    },
  };

  const formActions = isEditing ? (
    <div className="mba-actions mba-actions--top-right">
      <Button isLoading={isLoading} type={ButtonTypes.submit} text="Save" primary size={ButtonSizes.big} />
      <Button text="Discard" size={ButtonSizes.big} onClick={handleDiscardChangesClick} />
    </div>
  ) : (
    <div />
  );

  return (
    <>
      <div className="mba-heading--wrapper mba-heading--table">
        <Typography component="p" variant={TypographyVariants.h3} className="mba-heading--title-2">
          Accreditations
        </Typography>
      </div>
      <Grid container compact className="mba-column-group mba-no-margin">
        <Grid item>
          <Form
            id={`school-${schoolId}-accreditations`}
            onSubmit={handleFormSubmit}
            config={formConfig}
            onValuesChanged={handleValuesChanged}
            customActions={formActions}
            initialValues={{ accreditationIds: currentAccreditations }}
            errors={formErrors}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SchoolAccreditations;

import { PageSearchQueryConfig, TableListData } from '../../../shared/constants';
import { Registrations } from './registrations.dto';

export const LOAD_REGISTRATIONS = 'LOAD_REGISTRATIONS';
export const SET_REGISTRATIONS = 'SET_REGISTRATIONS';
export const DOWNLOAD_REGISTRATIONS = 'DOWNLOAD_REGISTRATIONS';

export interface LoadRegistrationsAction {
  type: typeof LOAD_REGISTRATIONS;
  payload: { pageConfig?: PageSearchQueryConfig };
}

export interface SetRegistrationsAction {
  type: typeof SET_REGISTRATIONS;
  payload: { data: TableListData<Registrations> };
}

export interface DownloadRegistrationsAction {
  type: typeof DOWNLOAD_REGISTRATIONS;
}

export type RegistrationsTypes = LoadRegistrationsAction | SetRegistrationsAction | DownloadRegistrationsAction;

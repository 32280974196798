import {
  CLEAR_CURRENT_SPREADSHEET_TEMPLATE_FIELDS,
  ClearCurrentFieldAction,
  CREATE_SPREADSHEET_TEMPLATE_FIELD,
  CreateSpreadsheetTemplateFieldAction,
  DELETE_SPREADSHEET_TEMPLATE_FIELD,
  DeleteSpreadsheetTemplateFieldAction,
  LOAD_SPREADSHEET_TEMPLATE_FIELDS,
  LoadSpreadsheetTemplateFieldsAction,
  SET_CURRENT_SPREADSHEET_TEMPLATE_FIELDS,
  SET_SPREADSHEET_TEMPLATE_FIELDS,
  SetCurrentFieldAction,
  SetSpreadsheetTemplateFieldsAction,
  UPDATE_SPREADSHEET_TEMPLATE_FIELD,
  UpdateSpreadsheetTemplateFieldAction,
} from './spreadsheet-template.types';
import { PageSearchQueryConfig, TableListData } from '../../../shared/constants';
import {
  CreateSpreadsheetTemplateFieldDTO,
  EditSpreadsheetTemplateFieldDTO,
  SpreadsheetTemplateFieldDTO,
} from './spreadsheet-template.dto';

export const loadSpreadsheetTemplateFieldsAction = (
  type: string,
  pageConfig?: PageSearchQueryConfig,
): LoadSpreadsheetTemplateFieldsAction => ({
  type: LOAD_SPREADSHEET_TEMPLATE_FIELDS,
  payload: { type, pageConfig },
});

export const setSpreadsheetTemplateFieldsAction = (
  type: string,
  tableData: TableListData<SpreadsheetTemplateFieldDTO>,
): SetSpreadsheetTemplateFieldsAction => ({
  type: SET_SPREADSHEET_TEMPLATE_FIELDS,
  payload: { type, tableData },
});

export const createSpreadsheetTemplateFieldAction = (
  type: string,
  data: CreateSpreadsheetTemplateFieldDTO,
): CreateSpreadsheetTemplateFieldAction => ({
  type: CREATE_SPREADSHEET_TEMPLATE_FIELD,
  payload: { type, data },
});

export const updateSpreadsheetTemplateFieldAction = (
  id: number,
  type: string,
  data: EditSpreadsheetTemplateFieldDTO,
): UpdateSpreadsheetTemplateFieldAction => ({
  type: UPDATE_SPREADSHEET_TEMPLATE_FIELD,
  payload: { id, type, data },
});

export const setCurrentSpreadsheetTemplateFieldAction = (
  type: string,
  data: SpreadsheetTemplateFieldDTO,
): SetCurrentFieldAction => ({
  type: SET_CURRENT_SPREADSHEET_TEMPLATE_FIELDS,
  payload: { type, data },
});

export const clearCurrentSpreadsheetTemplateFieldAction = (type: string): ClearCurrentFieldAction => ({
  type: CLEAR_CURRENT_SPREADSHEET_TEMPLATE_FIELDS,
  payload: { type },
});

export const deleteSpreadsheetTemplateFieldAction = (
  type: string,
  id: number,
): DeleteSpreadsheetTemplateFieldAction => ({
  type: DELETE_SPREADSHEET_TEMPLATE_FIELD,
  payload: { type, id },
});

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import Button, { ButtonIcons, ButtonSizes } from '../../../../../../../components/Button';
import Grid from '../../../../../../../components/Grid';
import Pager, { INITIAL_PAGE } from '../../../../../../../components/Pager';
import Table, { TableHeaderColumnTypes } from '../../../../../../../components/Table';
import Typography, { TypographyVariants } from '../../../../../../../components/Typography';
import { ASCENDING_ORDER_DIRECTION, DESCENDING_ORDER_DIRECTION } from '../../../../../../../shared/constants';
import { selectRequestIsLoading } from '../../../../../../../shared/state/global-request';
import { RankingDTO, selectCurrentRanking } from '../../../RankingInfo';
import {
  deleteAllSpreadsheetAction,
  deleteSpreadsheetAction,
  downloadRankingSpreadsheetAction,
  downloadRankingSpreadsheetContactsAction,
  loadRankingSpreadsheetsAction,
} from '../../spreadsheets.actions';
import { RANKING_PROFILE_SPREADSHEETS_SUB_NAVIGATION } from '../../Spreadsheets.constants';
import {
  selectRankingSpreadsheetsPageConfiguration,
  selectRankingSpreadsheetsTableData,
} from '../../spreadsheets.selectors';
import { DOWNLOAD_RANKING_SPREADSHEET, DOWNLOAD_RANKING_SPREADSHEET_CONTACTS } from '../../spreadsheets.types';
import { RANKING_SPREADSHEETS_TABLE_COLUMN_DEFINITION, RANKING_SPREADSHEETS_TYPES } from './SpreadsheetList.constants';
import Modal from '../../../../../../../components/Modal';
import styles from './SpreadsheetList.module.scss';
import { useWindowSize } from '../../../../../../../shared/helpersHooks';

const DELETE_SPREADSHEET_WARNING_TEXT = 'Are you sure you want to delete selected spreadsheet?';
const DELETE_ALL_SREADSHEETS_WARNING_TEXT = 'Are you sure you want to delete all spreadsheets?';

const defaultModalProps = {
  openModal: false,
  id: '',
  isDeleted: false,
  schoolName: '',
  deleteAll: false,
};

interface DeleteModalProps {
  openModal: boolean;
  id: string | undefined;
  isDeleted: boolean;
  schoolName: string | undefined;
  deleteAll: boolean;
}

const SpreadsheetList: FunctionComponent = () => {
  const { width } = useWindowSize();
  const location = useLocation();
  const history = useHistory();
  const type = location.pathname.split('/').pop() || '';

  useEffect(() => {
    if (!(type in RANKING_SPREADSHEETS_TYPES)) {
      history.push(`/not-found`);
    }
  }, [history, type]);

  const btnGroupWrapper = ['mba-actions'];
  const wrapperClasses = width < 1130 ? ['mba-mt-5', 'mba-mb-5'] : ['mba-mt-10', 'mba-mb-10'];

  if (width < 1105) {
    btnGroupWrapper.push(styles.mobileActions);
  }

  const dispatch = useDispatch();
  const [deleteModalProps, setDeleteModalProps] = useState<DeleteModalProps>({ ...defaultModalProps });
  const isDownloading = useSelector(selectRequestIsLoading(DOWNLOAD_RANKING_SPREADSHEET));
  const isDownloadingContacts = useSelector(selectRequestIsLoading(DOWNLOAD_RANKING_SPREADSHEET_CONTACTS));
  const pageConfig = useSelector(selectRankingSpreadsheetsPageConfiguration(type));
  const rankingSchoolsData = useSelector(selectRankingSpreadsheetsTableData(type));
  const currentRanking = useSelector(selectCurrentRanking) as RankingDTO;
  const rankingId = currentRanking.id;
  const pageTitle = RANKING_PROFILE_SPREADSHEETS_SUB_NAVIGATION[type]?.label;

  useEffect(() => {
    if (rankingId && type) {
      dispatch(loadRankingSpreadsheetsAction(rankingId, type));
    }
  }, [dispatch, rankingId, type]);

  const handlePageChanged = (page: number): void => {
    dispatch(loadRankingSpreadsheetsAction(rankingId, type, { ...pageConfig, page }));
  };

  const handleSortByChanged = (fieldName: string): void => {
    const orderDirection =
      pageConfig.sortBy !== fieldName
        ? DESCENDING_ORDER_DIRECTION
        : pageConfig.order === ASCENDING_ORDER_DIRECTION
        ? DESCENDING_ORDER_DIRECTION
        : ASCENDING_ORDER_DIRECTION;
    dispatch(
      loadRankingSpreadsheetsAction(rankingId, type, { ...pageConfig, sortBy: fieldName, order: orderDirection }),
    );
  };

  const handleDownloadAllClick = (): void => {
    dispatch(downloadRankingSpreadsheetAction(rankingId, type, pageConfig));
  };

  const handleDeleteAllClick = (): void => {
    setDeleteModalProps({
      ...defaultModalProps,
      openModal: true,
      deleteAll: true,
    });
  };

  const handleDownloadSingleClick = (id: number): void => {
    const schoolId = [id.toString()];
    dispatch(downloadRankingSpreadsheetAction(rankingId, type, { ...pageConfig, filter: { schoolId } }));
  };

  const deleteSchoolSpreadsheet = (id: number): void => {
    const spreadSheetData = rankingSchoolsData.find((rankingSchool) => rankingSchool.schoolId === id);
    setDeleteModalProps({
      ...defaultModalProps,
      openModal: true,
      id: spreadSheetData?.id.toString(),
      schoolName: spreadSheetData?.schoolName,
    });
  };

  const handleDownloadParticipants = (): void => {
    dispatch(downloadRankingSpreadsheetContactsAction(rankingId, type, pageConfig));
  };

  const closeDeleteModal = (): void => {
    setDeleteModalProps(defaultModalProps);
  };

  const modalDeleteSpreadsheet = () => {
    const id = deleteModalProps.id;
    dispatch(deleteSpreadsheetAction(rankingId, id));
    dispatch(loadRankingSpreadsheetsAction(rankingId, type));
    setDeleteModalProps(defaultModalProps);
  };

  const modalDeleteAllSpreadsheet = () => {
    dispatch(deleteAllSpreadsheetAction(rankingId, type));
    dispatch(loadRankingSpreadsheetsAction(rankingId, type));
    setDeleteModalProps(defaultModalProps);
  };

  const rankingSpreadsheetsTableConfig = [
    ...RANKING_SPREADSHEETS_TABLE_COLUMN_DEFINITION,
    {
      id: 'actions',
      title: 'Action',
      type: TableHeaderColumnTypes.actions,
      width: '90px',
      actions: [
        {
          disableIfEmpty: true,
          label: 'Download',
          handleOnClick: handleDownloadSingleClick,
        },
        {
          disableIfEmpty: true,
          label: 'Delete',
          handleOnClick: deleteSchoolSpreadsheet,
        },
      ],
    },
  ];

  const rankingSpreadsheetList = rankingSchoolsData.map((rankingSchool) => {
    return {
      ...rankingSchool,
      id: rankingSchool.schoolId,
    };
  });

  return (
    <Grid container>
      <Grid item compact xs={12} md={11}>
        <div className="mba-heading--wrapper mba-heading--table">
          <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title-2">
            {pageTitle}
          </Typography>
          {rankingSchoolsData.length > 0 && (
            <div className={btnGroupWrapper.join(' ')}>
              <Button
                primary
                text="Download contacts"
                size={ButtonSizes.big}
                icon={ButtonIcons.download}
                wrapperClass={wrapperClasses.join(' ')}
                isLoading={isDownloadingContacts}
                onClick={handleDownloadParticipants}
              />
              <Button
                primary
                wrapperClass={wrapperClasses.join(' ')}
                text="Download all spreadsheets"
                size={ButtonSizes.big}
                icon={ButtonIcons.download}
                isLoading={isDownloading}
                onClick={handleDownloadAllClick}
              />
              <div className={styles.deleteAllWrapper}>
                <Button text="Delete all spreadsheets" size={ButtonSizes.big} onClick={handleDeleteAllClick} />
              </div>
            </div>
          )}
        </div>
        <div className="mba-table-wrapper">
          <Table
            id="spreadsheet-list"
            columns={rankingSpreadsheetsTableConfig}
            data={rankingSpreadsheetList}
            sortingOptions={{
              order: pageConfig.order || DESCENDING_ORDER_DIRECTION,
              sortBy: pageConfig.sortBy || '',
            }}
            onSortByColumn={handleSortByChanged}
          />
        </div>
        <Modal
          title={`Delete ${deleteModalProps.schoolName} ${type.charAt(0).toUpperCase() + type.slice(1)} spreadsheet${
            deleteModalProps.deleteAll ? 's' : ''
          }`}
          isOpen={deleteModalProps.openModal}
          onClose={closeDeleteModal}
        >
          <p>{deleteModalProps.deleteAll ? DELETE_ALL_SREADSHEETS_WARNING_TEXT : DELETE_SPREADSHEET_WARNING_TEXT}</p>
          <Grid item md={12} xs={12} className="mba-no-padding mba-mt-20">
            <Button text="Cancel" size={ButtonSizes.big} onClick={closeDeleteModal} wrapperClass="mba-mr-10" />
            <Button
              disabled={deleteModalProps.isDeleted}
              text="Yes"
              size={ButtonSizes.big}
              danger
              onClick={deleteModalProps.deleteAll ? modalDeleteAllSpreadsheet : modalDeleteSpreadsheet}
            />
          </Grid>
        </Modal>
        {pageConfig && pageConfig.totalPages !== INITIAL_PAGE && (
          <Pager
            currentPage={pageConfig.page}
            totalPages={pageConfig.totalPages || INITIAL_PAGE}
            onPageChange={handlePageChanged}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default SpreadsheetList;

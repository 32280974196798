import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SCHOOL_ACCOUNTS_TABLE_COLUMN_DEFINITION } from './Accounts.constants';
import AccountsFiltersAndSearch from './AccountsFiltersAndSearch';
import { downloadSchoolAccountsSpreadsheetAction, loadSchoolAccountsAction } from '../school-accounts.actions';
import { selectSchoolAccountsPageConfiguration, selectSchoolAccountsTableData } from '../school-accounts.selectors';
import Button, { ButtonIcons, ButtonSizes } from '../../../../components/Button';
import Pager, { INITIAL_PAGE } from '../../../../components/Pager';
import Table from '../../../../components/Table';
import Typography, { TypographyVariants } from '../../../../components/Typography';
import { ASCENDING_ORDER_DIRECTION, DESCENDING_ORDER_DIRECTION } from '../../../../shared/constants';
import { loadResourcesAction } from '../../../../shared/state/resources';
import { loadRolesAction } from '../../../../shared/state/roles';
import { loadStatusesAction } from '../../../../shared/state/statuses';

const Accounts: FunctionComponent = () => {
  const dispatch = useDispatch();
  const pageConfig = useSelector(selectSchoolAccountsPageConfiguration);
  const schoolAccountsData = useSelector(selectSchoolAccountsTableData);

  useEffect(() => {
    dispatch(loadResourcesAction('accreditations'));
    dispatch(loadResourcesAction('ranking-types'));
    dispatch(loadRolesAction('school-users'));
    dispatch(loadSchoolAccountsAction());
    dispatch(loadStatusesAction('user'));
  }, [dispatch]);

  const handlePageChanged = (page: number): void => {
    dispatch(loadSchoolAccountsAction({ ...pageConfig, page }));
  };

  const handleSortByChanged = (fieldName: string): void => {
    const orderDirection =
      pageConfig.sortBy !== fieldName
        ? DESCENDING_ORDER_DIRECTION
        : pageConfig.order === ASCENDING_ORDER_DIRECTION
        ? DESCENDING_ORDER_DIRECTION
        : ASCENDING_ORDER_DIRECTION;
    dispatch(loadSchoolAccountsAction({ ...pageConfig, sortBy: fieldName, order: orderDirection }));
  };

  const handleDownloadClick = (): void => {
    dispatch(downloadSchoolAccountsSpreadsheetAction(pageConfig));
  };

  return (
    <>
      <div className="mba-heading--wrapper">
        <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title">
          School user accounts
        </Typography>
        {schoolAccountsData.length > 0 && (
          <div className="mba-actions">
            <Button
              text="Download contact information"
              size={ButtonSizes.big}
              icon={ButtonIcons.download}
              primary
              onClick={handleDownloadClick}
            />
          </div>
        )}
      </div>
      <div className="mba-table-wrapper">
        <div className="mba-table-filters">
          <AccountsFiltersAndSearch pageConfig={pageConfig} />
        </div>
        <div className="mba-table-container">
          <Table
            id="school-accounts-list"
            columns={SCHOOL_ACCOUNTS_TABLE_COLUMN_DEFINITION}
            data={schoolAccountsData}
            sortingOptions={{
              order: pageConfig.order || DESCENDING_ORDER_DIRECTION,
              sortBy: pageConfig.sortBy || '',
            }}
            onSortByColumn={handleSortByChanged}
          />
        </div>
      </div>
      {pageConfig && pageConfig.totalPages !== INITIAL_PAGE && (
        <Pager
          currentPage={pageConfig.page}
          totalPages={pageConfig.totalPages || INITIAL_PAGE}
          onPageChange={handlePageChanged}
        />
      )}
    </>
  );
};

export default Accounts;

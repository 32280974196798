import { FormConfig } from '../../../../../components/Form';

export interface CampusDetailsRouteParams {
  id: string;
  campusId: string;
}

export const CAMPUS_DETAILS_CONFIG: FormConfig = {
  name: {
    fieldType: 'text',
    placeholder: 'Enter name',
    required: true,
    title: 'Name',
    validations: {
      required: {
        errorText: 'Please enter a name!',
      },
    },
  },
  email: {
    fieldType: 'text',
    title: 'E-mail',
    placeholder: 'Enter e-mail address',
    validations: {
      regex: {
        errorText: 'Please enter a valid e-mail address!',
        rule: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      },
    },
  },
  status: {
    fieldType: 'select',
    placeholder: 'Choose a status',
    required: true,
    options: [],
    title: 'Status',
    validations: {
      required: {
        errorText: 'Please choose a status!',
      },
    },
  },
  phone: {
    fieldType: 'text',
    title: 'Phone',
    placeholder: 'Enter phone number',
  },
  contact: {
    fieldType: 'text',
    placeholder: 'Enter contact details',
    title: 'Contact details',
  },
  address: {
    fieldType: 'textarea',
    title: 'Address',
    placeholder: `Enter school's main address`,
  },
};

import { RootState } from '../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const selectRoles = (resourceName: string) =>
  createDeepEqualSelector(
    (state: RootState): any => state.roles,
    (roles) => {
      return roles.hasOwnProperty(resourceName) ? roles[resourceName] : [];
    },
  );

import { RankingTypeDTO } from '../RankingTypeProfile';
import { get } from '../../../../shared/api';
import { PagedResponseDTO, PageSearchQueryConfig } from '../../../../shared/constants';
import { generateSearchQuery } from '../../../../shared/helpers';

export const getRankingTypesRequest = (
  pageConfig: PageSearchQueryConfig = {},
): Promise<PagedResponseDTO<RankingTypeDTO[]>> => {
  const query = generateSearchQuery(pageConfig);
  return get<PagedResponseDTO<RankingTypeDTO[]>>(`/ranking-types${query}`);
};

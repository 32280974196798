import { AuthActionTypes, AuthState, SET_USER_NAVIGATION, SET_USER_TOKEN } from './auth.types';
import { NavigationConfiguration } from '../Main/components/Layout';

const initialState: AuthState = {
  isTokenVerified: false,
  navigation: {} as NavigationConfiguration,
  token: '',
};

const authReducer = (state = initialState, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case SET_USER_NAVIGATION: {
      return {
        ...state,
        navigation: action.payload.navigation,
      };
    }
    case SET_USER_TOKEN: {
      return {
        ...state,
        isTokenVerified: true,
        token: action.payload.token,
      };
    }
    default: {
      return state;
    }
  }
};

export default authReducer;

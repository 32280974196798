import { RankingFieldDataItemDTO } from './ranking-field-data.dto';
import { PageSearchQueryConfig, TableListData } from '../../../../../../../shared/constants';

export const LOAD_RANKING_FIELD_DATA = 'LOAD_RANKING_FIELD_DATA';
export const SET_RANKING_FIELD_DATA = 'SET_RANKING_FIELD_DATA';
export const EDIT_RANKING_DATA_ITEM = 'EDIT_RANKING_DATA_ITEM';

export interface RankingFieldDataState {
  rankingFieldData: TableListData<RankingFieldDataItemDTO>;
}

export interface LoadRankingFieldDataAction {
  type: typeof LOAD_RANKING_FIELD_DATA;
  payload: { rankingFieldId: number; pageConfig?: PageSearchQueryConfig };
}

export interface SetRankingFieldDataAction {
  type: typeof SET_RANKING_FIELD_DATA;
  payload: { data: TableListData<RankingFieldDataItemDTO> };
}

export interface EditRankingFieldDataItemAction {
  type: typeof EDIT_RANKING_DATA_ITEM;
  payload: { rankingFieldId: number; rankingFieldDataItemId: number; value: number };
}

export type RankingFieldDataActionTypes =
  | LoadRankingFieldDataAction
  | SetRankingFieldDataAction
  | EditRankingFieldDataItemAction;

export const LOAD_SETTING = 'LOAD_SETTING';
export const SET_SETTINGS = 'SET_SETTINGS';
export const UPDATE_SETTING = 'UPDATE_SETTING';
export const CLEAR_CACHE = 'CLEAR_CACHE';

export type SettingsState = {
  [key: string]: string;
};

export interface SettingDto {
  key: string;
  value: string;
}

export enum SettingKeys {
  methodology = 'methodology',
}

export interface LoadSettingAction {
  type: typeof LOAD_SETTING;
  payload: {
    key: string;
  };
}

export interface SetSettingsAction {
  type: typeof SET_SETTINGS;
  payload: SettingDto;
}

export interface UpdateSettingAction {
  type: typeof UPDATE_SETTING;
  payload: SettingDto;
}

export interface ClearCacheAction {
  type: typeof CLEAR_CACHE;
}

export type SettingsActionTypes = LoadSettingAction | SetSettingsAction | UpdateSettingAction | ClearCacheAction;

import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SCHOOL_USER_CREATE_FORM_CONFIG } from '../SchoolUsers.constants';
import { SchoolDTO, selectCurrentSchool } from '../../../SchoolDetails';
import {
  CREATE_SCHOOL_USER,
  createNewSchoolUserAction,
  CreateSchoolUserDTO,
  selectCurrentSchoolUser,
} from '../../../../SchoolUserProfile';
import Form, { FormConfig } from '../../../../../../components/Form';
import { selectAreRequestsLoading, selectRequestFormErrors } from '../../../../../../shared/state/global-request';
import { capitalizeText } from '../../../../../../shared/helpers';
import { loadRolesAction, selectRoles } from '../../../../../../shared/state/roles';

type SchoolUserFormProps = {
  isEdit?: boolean;
  onFormClose: () => void;
};

const SchoolUserForm: FunctionComponent<SchoolUserFormProps> = ({ onFormClose, isEdit = false }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectAreRequestsLoading([CREATE_SCHOOL_USER]));
  const currentSchoolUser = useSelector(selectCurrentSchoolUser);
  const currentSchool = useSelector(selectCurrentSchool) as SchoolDTO;
  const schoolUserRoles = useSelector(selectRoles('school-users'));
  const formErrors = useSelector(selectRequestFormErrors(CREATE_SCHOOL_USER));
  const schoolId = currentSchool.id;

  useEffect(() => {
    dispatch(loadRolesAction('school-users'));
  }, [dispatch]);

  const onSchoolUserSaveSubmit = (data: CreateSchoolUserDTO): void => {
    dispatch(createNewSchoolUserAction(data as CreateSchoolUserDTO, schoolId));
  };

  const defaultFormConfig: FormConfig = {
    ...SCHOOL_USER_CREATE_FORM_CONFIG,
    role: {
      ...SCHOOL_USER_CREATE_FORM_CONFIG.role,
      options: schoolUserRoles.map((roleName: string) => ({
        label: capitalizeText(roleName),
        value: roleName,
      })),
    },
  };

  const rankingInitialData = { ...currentSchoolUser };

  return (
    <Form
      id="school-user-form"
      isLoading={isLoading}
      config={defaultFormConfig}
      onSubmit={onSchoolUserSaveSubmit}
      submitButtonText={isEdit ? 'Save' : 'Create school user'}
      actions={[
        {
          label: 'Cancel',
          onClick: onFormClose,
        },
      ]}
      initialValues={rankingInitialData}
      errors={formErrors}
    />
  );
};

export default SchoolUserForm;

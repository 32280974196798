import React, { FC, Fragment, FunctionComponent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import slug from 'slug';

import EditableGroup from '../../../../../../../components/EditableGroup';
import { FormDataValues } from '../../../../../../../components/Form';
import Grid from '../../../../../../../components/Grid';
import Typography, { TypographyVariants } from '../../../../../../../components/Typography';
import { selectRequestErrors, selectRequestIsLoading } from '../../../../../../../shared/state/global-request';
import { loadResourcesAction, selectResources } from '../../../../../../../shared/state/resources';
import {
  EDIT_RANKING_DETAILS,
  editRankingDetailsAction,
  EditRankingDTO,
  RankingDTO,
  selectCurrentRanking,
} from '../../../RankingInfo';
import {
  editRankingTimelineStepAction,
  loadRankingTimelineStepsAction,
  setCurrentRankingTimelineStepAction,
} from '../ranking-timeline-steps.actions';
import { EditRankingTimelineStepDTO, RankingTimelineStepDTO } from '../ranking-timeline-steps.dto';
import { selectCurrentTimelineStep, selectCurrentTimelineSteps } from '../ranking-timeline-steps.selectors';
import { EDIT_RANKING_TIMELINE_STEP } from '../ranking-timeline-steps.types';
import EditableAttachmentStep from './EditableAttachmentStep';
import { RANKING_TIMELINE_STEPS } from './TimelineSteps.constants';
import StepErrors from './TimelineStepErrors';
import moment from 'moment-timezone';

const TimelineSteps: FunctionComponent = () => {
  const dispatch = useDispatch();
  const currentRanking = useSelector(selectCurrentRanking) as RankingDTO;
  const rankingErrors = useSelector(selectRequestErrors(EDIT_RANKING_DETAILS, 'publicationDate'));
  const timelineSteps = useSelector(selectCurrentTimelineSteps) as RankingTimelineStepDTO[];
  const timelineStepErrors = useSelector(selectRequestErrors(EDIT_RANKING_TIMELINE_STEP));
  const alumniTemplates = useSelector(selectResources('spreadsheets/templates/alumni'));
  const facultyTemplates = useSelector(selectResources('spreadsheets/templates/faculty'));
  const researchTemplates = useSelector(selectResources('spreadsheets/templates/research'));
  const [errors, setErrors] = useState<{ [key: number]: string[] }>({});
  const isEditLoading = useSelector(selectRequestIsLoading(EDIT_RANKING_TIMELINE_STEP));
  const isPublicationLoading = useSelector(selectRequestIsLoading(EDIT_RANKING_DETAILS));
  const editingStep = useSelector(selectCurrentTimelineStep);
  const lastEdited = useRef<number>(NaN);

  const { id: rankingId } = currentRanking;

  useEffect(() => {
    if (rankingId) {
      dispatch(loadResourcesAction('spreadsheets/templates/alumni'));
      dispatch(loadResourcesAction('spreadsheets/templates/faculty'));
      dispatch(loadResourcesAction('spreadsheets/templates/research'));
      dispatch(loadRankingTimelineStepsAction(rankingId));
    }
  }, [dispatch, rankingId]);

  useEffect(() => {
    if (!Number.isNaN(lastEdited.current)) {
      setErrors((errors) => ({ ...errors, [lastEdited.current]: timelineStepErrors.name }));
    }
    // @TODO Why?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(timelineStepErrors)]);

  const createHandleStepEditSave =
    (stepId: number) =>
    (stepData: FormDataValues): void => {
      if (stepId) {
        lastEdited.current = stepId;
        const stepObject = {
          ...stepData,
          id: stepId,
          ...(stepData.hasOwnProperty('isOptional') ? { isOptional: !!stepData.isOptional } : {}),
        } as EditRankingTimelineStepDTO;
        dispatch(setCurrentRankingTimelineStepAction(stepObject as RankingTimelineStepDTO));
        dispatch(editRankingTimelineStepAction(rankingId, stepObject));
      }
    };

  // @TODO This doesn't do anything
  const createHandleEditCancel = (stepId: number) => (): void => {
    if (editingStep?.id === stepId) {
      // setErrors((errors) => ({ ...errors, [stepId]: [] }));
    }
  };

  const handlePublicationDateEditSave = (data: FormDataValues): void => {
    dispatch(editRankingDetailsAction({ ...data, id: rankingId } as EditRankingDTO));
  };

  const RenderStep: FC<{ stepData: RankingTimelineStepDTO }> = ({ stepData }) => {
    const stepConfig = RANKING_TIMELINE_STEPS.find((config) => config.id === stepData.id);

    if (!stepConfig) return <></>;

    const templates: { label: string; value: string }[] = [];

    if (stepData.id === 2) {
      templates.push(...alumniTemplates.map(({ key, label }) => ({ label, value: key.toString() })));
    } else if (stepData.id === 3) {
      templates.push(...facultyTemplates.map(({ key, label }) => ({ label, value: key.toString() })));
    } else if (stepData.id === 4) {
      templates.push(...researchTemplates.map(({ key, label }) => ({ label, value: key.toString() })));
    }

    if (stepData.id === 5 || stepData.id === 6) {
      stepData.name += ' (Qualtrics)';
    } else if (stepData.id === 7) {
      stepData.name = "Ranking's embargo";
    }

    const Component = () => {
      switch (true) {
        case stepData.id === 1:
          return (
            <EditableAttachmentStep
              columnSize={6}
              data={stepData || ({} as any)}
              formErrors={timelineStepErrors}
              formConfig={stepConfig.config}
              isLoading={editingStep?.id === stepData.id && isEditLoading}
              onEditSubmit={createHandleStepEditSave(stepData.id)}
              onEditCancel={createHandleEditCancel(stepData.id)}
              editButtonLabel="Edit step"
              heading={
                <Typography component="p" variant={TypographyVariants.h3} className="mba-text--accent mba-no-margin">
                  {stepData.name}
                </Typography>
              }
            />
          );
        case [2, 3, 4].includes(stepData.id):
          return (
            <EditableAttachmentStep
              columnSize={12}
              data={stepData || ({} as any)}
              formErrors={timelineStepErrors}
              formConfig={{
                ...stepConfig.config,
                templateId: {
                  ...stepConfig.config.templateId,
                  options: templates,
                },
              }}
              isLoading={editingStep?.id === stepData.id && isEditLoading}
              onEditSubmit={createHandleStepEditSave(stepData.id)}
              onEditCancel={createHandleEditCancel(stepData.id)}
              editButtonLabel="Edit step"
              heading={
                <Typography component="p" variant={TypographyVariants.h3} className="mba-text--accent mba-no-margin">
                  {stepData.name}
                </Typography>
              }
            />
          );
        case stepData.id === 5:
          return (
            <EditableAttachmentStep
              columnSize={6}
              data={stepData || ({} as any)}
              formErrors={timelineStepErrors}
              formConfig={stepConfig.config}
              isLoading={editingStep?.id === stepData.id && isEditLoading}
              onEditSubmit={createHandleStepEditSave(stepData.id)}
              onEditCancel={createHandleEditCancel(stepData.id)}
              editButtonLabel="Edit step"
              heading={
                <Typography component="p" variant={TypographyVariants.h3} className="mba-text--accent mba-no-margin">
                  {stepData.name}
                </Typography>
              }
            />
          );
        case [6, 7].includes(stepData.id):
          return (
            <EditableGroup<RankingTimelineStepDTO>
              columnSize={6}
              data={stepData || ({} as any)}
              formErrors={timelineStepErrors}
              formConfig={stepConfig.config}
              isLoading={editingStep?.id === stepData.id && isEditLoading}
              onEditSubmit={createHandleStepEditSave(stepData.id)}
              onEditCancel={createHandleEditCancel(stepData.id)}
              editButtonLabel="Edit step"
              heading={
                <Typography component="p" variant={TypographyVariants.h3} className="mba-text--accent mba-no-margin">
                  {stepData.name}
                </Typography>
              }
            />
          );
        case stepData.id === 8:
          return (
            <EditableGroup<RankingDTO>
              columnSize={6}
              heading={
                <Typography component="p" variant={TypographyVariants.h3} className="mba-text--accent mba-no-margin">
                  {stepData.name}
                </Typography>
              }
              data={currentRanking}
              editButtonLabel="Edit step"
              formErrors={rankingErrors}
              formConfig={stepConfig.config}
              isLoading={isPublicationLoading}
              onEditSubmit={handlePublicationDateEditSave}
            />
          );
        case [9, 10].includes(stepData.id):
          return (
            <EditableAttachmentStep
              columnSize={12}
              data={stepData || ({} as any)}
              formErrors={timelineStepErrors}
              formConfig={stepConfig.config}
              isLoading={editingStep?.id === stepData.id && isEditLoading}
              onEditSubmit={createHandleStepEditSave(stepData.id)}
              onEditCancel={createHandleEditCancel(stepData.id)}
              editButtonLabel="Edit step"
              uploadButtonLabel={'Upload spreadsheet'}
              heading={
                <span>
                  <Typography component="p" variant={TypographyVariants.h3} className="mba-text--accent mba-no-margin">
                    {stepData.name}
                  </Typography>
                  <span className="mba-font--16" style={{ color: '#afafaf' }}>
                    {stepData.progress && stepData.progress.current > 0
                      ? `Last updated at ${moment(stepData.updatedAt).tz('Etc/GMT').format('Do MMM YYYY HH:mm')} GMT`
                      : `There were no updates so far`}
                  </span>
                </span>
              }
            />
          );
        case stepData.id === 11:
          return (
            <EditableGroup<RankingTimelineStepDTO>
              columnSize={12}
              data={stepData || ({} as any)}
              formErrors={timelineStepErrors}
              formConfig={stepConfig.config}
              isLoading={editingStep?.id === stepData.id && isEditLoading}
              onEditSubmit={createHandleStepEditSave(stepData.id)}
              onEditCancel={createHandleEditCancel(stepData.id)}
              editButtonLabel="Edit step"
              heading={
                <Typography component="p" variant={TypographyVariants.h3} className="mba-text--accent mba-no-margin">
                  {stepData.name}
                </Typography>
              }
            />
          );
        default:
          return <></>;
      }
    };

    return (
      <>
        <div id={slug(stepData.name + '-step')} />
        <Component />
      </>
    );
  };

  return (
    <Grid container>
      <Grid item compact xs={12} md={11}>
        <div className="mba-heading--wrapper mba-heading--table">
          <Typography component="p" variant={TypographyVariants.h3} className="mba-heading--title">
            Instructions and deadlines
          </Typography>
        </div>
        {timelineSteps.map((timelineStep) => (
          <Fragment key={timelineStep.id}>
            <RenderStep stepData={{ ...timelineStep }} />
            {timelineStep.id !== 8 ? (
              <>
                <StepErrors errors={errors[timelineStep.id]} />
                <hr className="mba-separator" />
              </>
            ) : (
              <></>
            )}
          </Fragment>
        ))}
        <br />
      </Grid>
    </Grid>
  );
};

export default TimelineSteps;

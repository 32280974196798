import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

import { addRankingCalendarAction, setRankingCalendarsAction } from './calendar.actions';
import { addRankingCalendarRequest, getCalendarsRequest, updateCalendarsRequest } from './calendar.api';
import { RankingCalendarDto } from './calendar.dto';
import {
  LoadRankingCalendarsAction,
  CalendarActionTypes,
  LOAD_RANKING_CALENDARS,
  CreateRankingCalendarAction,
  CREATE_RANKING_CALENDAR,
  UpdateRankingCalendarsAction,
  UPDATE_RANKING_CALENDARS,
} from './calendar.types';
import { BasicResponseDTO, DefaultResponseDTO } from '../../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../../shared/state/global-request';

function* loadCalendarsData(
  action: LoadRankingCalendarsAction,
): Generator<
  | CallEffect<DefaultResponseDTO<RankingCalendarDto[]>>
  | PutEffect<CalendarActionTypes>
  | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getCalendarsRequest)) as DefaultResponseDTO<RankingCalendarDto[]>;

    data.sort((a: RankingCalendarDto, b: RankingCalendarDto) => a.id - b.id);

    yield put(setRankingCalendarsAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* createRankingCalendarData(
  action: CreateRankingCalendarAction,
): Generator<
  CallEffect<DefaultResponseDTO<RankingCalendarDto>> | PutEffect<CalendarActionTypes> | PutEffect<GlobalRequestActions>
> {
  const { type: actionType, payload } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(addRankingCalendarRequest, payload.data)) as DefaultResponseDTO<RankingCalendarDto>;

    yield put(addRankingCalendarAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* saveCalendarsData(
  action: UpdateRankingCalendarsAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<CalendarActionTypes | GlobalRequestActions>> {
  const {
    type: actionType,
    payload: { data },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));

    const res = (yield call(updateCalendarsRequest, data)) as BasicResponseDTO;

    if (res.message === 'Success!') {
      yield put({ type: LOAD_RANKING_CALENDARS });
    }

    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* calendarsSaga(): Generator {
  yield takeLatest(LOAD_RANKING_CALENDARS, loadCalendarsData);
  yield takeLatest(CREATE_RANKING_CALENDAR, createRankingCalendarData);
  yield takeLatest(UPDATE_RANKING_CALENDARS, saveCalendarsData);
}

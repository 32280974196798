import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import RankingData from './RankingData';
import RankingDetails from './RankingDetails';
import RankingInfo, { LOAD_RANKING, loadRankingAction, RankingDTO, selectCurrentRanking } from './RankingInfo';
import {
  RANKING_PROFILE_MAIN_ROUTE,
  RANKING_PROFILE_TABS_NAVIGATION,
  RankingProfileRouteParams,
} from './RankingProfile.constants';
import RankingSchools from './RankingSchools';
import RankingTimeline from './RankingTimeline';
import Spreadsheets from './Spreadsheets';
import { PageRouter } from '../../../App/Main/components/Layout';
import Label, { LabelStatus } from '../../../../components/Label';
import PageLoader from '../../../../components/PageLoader';
import TabsNavigation from '../../../../components/TabsNavigation';
import Typography, { TypographyVariants } from '../../../../components/Typography';
import { selectRequestStatus } from '../../../../shared/state/global-request';
import NotFound from '../../../../components/NotFound';

const componentsMap = {
  details: RankingDetails,
  info: RankingInfo,
  rankingData: RankingData,
  schools: RankingSchools,
  timeline: RankingTimeline,
  surveyData: Spreadsheets,
};

const RankingProfile: FunctionComponent = () => {
  const dispatch = useDispatch();
  const requestStatus = useSelector(selectRequestStatus(LOAD_RANKING));
  const { id: rankingId } = useParams<RankingProfileRouteParams>();
  const { id, name, status } = useSelector(selectCurrentRanking) as RankingDTO;
  const tabsNavigation = Object.values(RANKING_PROFILE_TABS_NAVIGATION).map(({ label, route }) => ({
    label,
    route: `${RANKING_PROFILE_MAIN_ROUTE.replace(':id', rankingId)}${route}`,
  }));

  useEffect(() => {
    if (rankingId) {
      dispatch(loadRankingAction(parseInt(rankingId)));
    }
  }, [dispatch, rankingId]);

  return (
    <>
      {id ? (
        <>
          <Typography component="h1" variant={TypographyVariants.h1} className="mba-center--vertically">
            (ID: {id})&nbsp;{name}&nbsp;
            {status && (
              <Label color={status.color} status={LabelStatus.active}>
                {status.label}
              </Label>
            )}
          </Typography>
          <TabsNavigation tabs={tabsNavigation} big />
          <PageRouter
            mainRoute={RANKING_PROFILE_MAIN_ROUTE}
            navigation={RANKING_PROFILE_TABS_NAVIGATION}
            componentsMap={componentsMap}
          />
        </>
      ) : requestStatus.isLoading || !rankingId ? (
        <PageLoader />
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default RankingProfile;

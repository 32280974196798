import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { LeadDTO } from './lead.dto';
import { LeadGenTypes, LOAD_SCHOOL_LEADS, LoadSchoolLeadsAction } from './leads.types';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../global-request';
import { PagedResponseDTO } from '../../constants';
import { getSchoolLeadsRequest } from './leads.api';
import { setSchoolLeadsAction } from './leads.actions';

function* loadSchoolLeadsData(
  action: LoadSchoolLeadsAction,
): Generator<CallEffect<PagedResponseDTO<LeadDTO[]>> | PutEffect<LeadGenTypes> | PutEffect<GlobalRequestActions>> {
  const { type: actionType } = action;
  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig, schoolId } = action.payload;
    const { data, pagination, sortOrder } = (yield call(
      getSchoolLeadsRequest,
      schoolId,
      pageConfig,
    )) as PagedResponseDTO<LeadDTO[]>;

    yield put(setSchoolLeadsAction({ data, pagination, sortOrder }));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* schoolLeadsSaga(): Generator {
  yield takeLatest(LOAD_SCHOOL_LEADS, loadSchoolLeadsData);
}

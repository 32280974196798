import React, { FunctionComponent, ReactNode } from 'react';

import { InputWrapperProps } from './InputWrapper.constants';

import styles from './InputWrapper.module.scss';

const InputWrapper: FunctionComponent<InputWrapperProps> = ({
  id,
  inline = false,
  inverse = false,
  optional = false,
  required = false,
  subTitle,
  shrunken = false,
  title,
  groupedElement = false,
  verticallyCentered,
  className,
  children,
}) => {
  const labelClasses = ['o-forms-field'];
  const titleClasses = ['o-forms-title'];
  const InputWrapperElement = groupedElement ? 'div' : 'label';

  if (optional && !required) {
    labelClasses.push('o-forms-field--optional');
  }

  if (inverse) {
    labelClasses.push('o-forms-field--inverse');
  }

  if (inline) {
    labelClasses.push('o-forms-field--inline');
  }

  if (className) {
    labelClasses.push(className);
  }

  if (shrunken) {
    titleClasses.push('o-forms-title--shrink');

    if (verticallyCentered) {
      titleClasses.push('o-forms-title--vertical-center');
    }
  }

  const renderLabel = (): ReactNode =>
    (title || subTitle) && (
      // <div role={'presentation'} className={titleClasses.join(' ')} onClick={(e): void => e.preventDefault()}>
      <div className={titleClasses.join(' ')}>
        {title && (
          <span className="o-forms-title__main">
            {title}
            &nbsp;
            {required && <span className={styles.requiredIndicator}>*</span>}
          </span>
        )}
        {subTitle && <span className="o-forms-title__prompt">{subTitle}</span>}
      </div>
    );

  return (
    <InputWrapperElement className={labelClasses.join(' ')} id={`${id}-label`}>
      {renderLabel()}
      {children}
    </InputWrapperElement>
  );
};

export default InputWrapper;

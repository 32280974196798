import { ColumnDefinition, TableHeaderColumnTypes } from '../../../../components/Table';

export const LOGS_TABLE_COLUMN_DEFINITION: ColumnDefinition[] = [
  {
    id: 'userEmail',
    title: 'Email',
    sorting: true,
    width: '180px',
  },
  {
    id: 'description',
    title: 'Description',
    sorting: false,
  },
  {
    id: 'date',
    title: 'Date',
    sorting: true,
    type: TableHeaderColumnTypes.date,
    width: '120px',
  },
  {
    id: 'time',
    title: 'Time',
    sorting: true,
    width: '110px',
  },
];

export const logsNav = { label: 'Logs', route: '/logs' };

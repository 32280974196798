import { NavigationConfiguration } from '../../../App/Main/components/Layout';

export interface RankingProfileRouteParams {
  id: string;
}

export const RANKING_PROFILE_MAIN_ROUTE = '/rankings/profile/:id';

export const RANKING_PROFILE_TABS_NAVIGATION: NavigationConfiguration = {
  timeline: {
    label: 'Timeline',
    route: '/timeline',
  },
  schools: {
    label: 'Schools',
    route: '/schools',
  },
  surveyData: {
    label: 'Spreadsheets',
    route: '/spreadsheets',
  },
  details: {
    label: 'Survey Details',
    route: '/details',
  },
  info: {
    label: 'Info',
    route: '/info',
  },
  rankingData: {
    label: 'Data',
    route: '/ranking-data',
  },
};

import { getTableListDefaultState } from '../../../shared/helpers';
import { LOAD_SCHOOLS, SchoolsActionTypes, SET_SCHOOLS } from './schools.types';
import { SchoolDTO } from '../SchoolProfile/SchoolDetails';
import { TableListData } from '../../../shared/constants';

const initialState: TableListData<SchoolDTO> = getTableListDefaultState<SchoolDTO>();

const schoolsReducer = (state = initialState, action: SchoolsActionTypes): TableListData<SchoolDTO> => {
  switch (action.type) {
    case LOAD_SCHOOLS: {
      return {
        ...state,
        filter: action.payload.pageConfig?.filter || {},
        search: action.payload.pageConfig?.search || '',
      };
    }
    case SET_SCHOOLS: {
      return {
        ...state,
        ...action.payload.tableData,
      };
    }
    default: {
      return state;
    }
  }
};

export default schoolsReducer;

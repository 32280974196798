import React, { FunctionComponent } from 'react';
import Button, { ButtonSizes } from '../../../../components/Button';

import styles from '../OnsiteMessaging.module.scss';

interface ErrorMessageProps {
  closeErrorMessageModal: () => void;
}

const ErrorMessage: FunctionComponent<ErrorMessageProps> = ({ closeErrorMessageModal }) => {
  return (
    <>
      <p>
        You cannot add another on-site message until the current active one has not expired. If you want to override the
        currently active message with this one, please go back and use the Edit option to change the expiration date.
      </p>
      <Button
        wrapperClass={styles.errorModalButton}
        text="OK"
        primary
        size={ButtonSizes.big}
        onClick={closeErrorMessageModal}
      />
    </>
  );
};

export default ErrorMessage;

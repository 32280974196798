import { RootState } from '../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const widgetGroupsSelector = (state: RootState) => state.commercial.widgetGroups;

export const selectSchoolWidgetGroupsTableData = createDeepEqualSelector(widgetGroupsSelector, (widgetGroups) => {
  return widgetGroups.data;
});

export const selectWidgetGroupsPageConfiguration = createDeepEqualSelector(widgetGroupsSelector, (widgetGroups) => {
  return {
    filter: widgetGroups.filter,
    limit: widgetGroups.pagination.limit,
    order: widgetGroups.sortOrder.order,
    page: widgetGroups.pagination.page,
    search: widgetGroups.search,
    sortBy: widgetGroups.sortOrder.sortBy,
    totalPages: widgetGroups.pagination.totalPages,
  };
});

import { RootState } from '../../../store/reducers';

export const selectSetting =
  (key: string) =>
  (state: RootState): string => {
    if (state.settings.hasOwnProperty(key)) {
      return state.settings[key];
    }

    return '';
  };

import React, { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';

import RankingFieldProfile from './RankingFieldProfile';
import RankingFieldList from './RankingFieldsList';
import { RANKING_PROFILE_DATA_MAIN_ROUTE, RANKING_PROFILE_DATA_NAVIGATION } from './RankingData.constants';

const RankingData: FunctionComponent = () => {
  const componentsMap = {
    list: RankingFieldList,
    profile: RankingFieldProfile,
  } as { [key: string]: FunctionComponent };

  return (
    <Switch>
      {Object.entries(RANKING_PROFILE_DATA_NAVIGATION).map(([key, { route: subRoute }]) => (
        <Route
          exact={true}
          key={key}
          path={`${RANKING_PROFILE_DATA_MAIN_ROUTE}${subRoute}`}
          component={componentsMap[key]}
        />
      ))}
    </Switch>
  );
};

export default RankingData;

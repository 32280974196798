import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

import { setRankingCategoriesAction } from './ranking-categories.actions';
import { getRankingCategoriesRequest } from './ranking-categories.api';
import {
  LOAD_RANKING_CATEGORIES,
  LoadRankingCategoriesAction,
  RankingCategoriesActionTypes,
} from './ranking-categories.types';
import { RankingCategoryDTO } from '../RankingCategoryProfile';
import { PagedResponseDTO } from '../../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../../shared/state/global-request';

function* loadRankingCategoriesTableData(
  action: LoadRankingCategoriesAction,
): Generator<
  | CallEffect<PagedResponseDTO<RankingCategoryDTO[]>>
  | PutEffect<RankingCategoriesActionTypes>
  | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig } = action.payload;
    const { data, pagination, sortOrder } = (yield call(getRankingCategoriesRequest, pageConfig)) as PagedResponseDTO<
      RankingCategoryDTO[]
    >;
    yield put(setRankingCategoriesAction({ data, pagination, sortOrder }));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* rankingTypesSaga(): Generator {
  yield takeLatest(LOAD_RANKING_CATEGORIES, loadRankingCategoriesTableData);
}

import React, { FunctionComponent } from 'react';
import { combineReducers } from 'redux';

import { PageProps } from '../../shared/constants';
import { PageRouter } from '../App/Main/components/Layout';
import SchoolsWithLeadGenList, { schoolsWithLeadGenReducer } from './SchoolsWithLeadGen';
import SchoolWidgetDetails, { schoolWidgetsReducer } from './SchoolWidgetDetails';
import { SCHOOL_WIDGETS_TABS_NAVIGATION } from './SchoolWidgetDetails/components/SchoolWidgetDetails.constants';
import SchoolWidgetGroupsList, { widgetGroupsReducer } from './SchoolWidgetGroups';
import schoolLeadsReducer from '../../shared/state/leads/leads.reducer';
import schoolSingleLeadReducer from './SchoolLeadsDetails/school-leads-details.reducer';
import SchoolLeadsProfile from './SchoolLeads/ShoolLeadsProfile/SchoolLeadsProfile';
import RegistrationsList, { registrationsReducer } from './Registrations';

const componentsMap = {
  schoolWidgetGroups: SchoolWidgetGroupsList,
  schoolWidgetDetails: SchoolWidgetDetails,
  schoolsWithLeadGen: SchoolsWithLeadGenList,
  schoolLeadsProfile: SchoolLeadsProfile,
  registrations: RegistrationsList,
};

const Commercial: FunctionComponent<PageProps> = ({ navigation, route }) => {
  return navigation ? (
    <PageRouter
      mainRoute={route}
      navigation={{ ...navigation, ...SCHOOL_WIDGETS_TABS_NAVIGATION }}
      componentsMap={componentsMap}
    />
  ) : null;
};

export const commercialReducer = combineReducers({
  widgetGroups: widgetGroupsReducer,
  schoolWidgets: schoolWidgetsReducer,
  schoolsWithLeadGen: schoolsWithLeadGenReducer,
  schoolLeads: schoolLeadsReducer,
  schoolSingleLead: schoolSingleLeadReducer,
  registrations: registrationsReducer,
});

export default Commercial;

import { RankingCategoryDTO } from '../RankingCategoryProfile';
import { get } from '../../../../shared/api';
import { PagedResponseDTO, PageSearchQueryConfig } from '../../../../shared/constants';
import { generateSearchQuery } from '../../../../shared/helpers';

export const getRankingCategoriesRequest = (
  pageConfig: PageSearchQueryConfig = {},
): Promise<PagedResponseDTO<RankingCategoryDTO[]>> => {
  const query = generateSearchQuery(pageConfig);
  return get<PagedResponseDTO<RankingCategoryDTO[]>>(`/categories${query}`);
};

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { API_BASE_URL } from './api.config';
import { JWT_TOKEN_COOKIE_NAME } from './constants';
import { redirectUserToForbiddenUrl, redirectUserToSSOUrl, validateFilename } from './helpers';
import { deleteCookie, getJWTToken } from './web-storage';

const api = axios.create({
  baseURL: API_BASE_URL,
});

const token = getJWTToken();
if (token) {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export const withToken = (config?: AxiosRequestConfig): AxiosRequestConfig => {
  const token = getJWTToken();

  if (!config)
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    },
  };
};

api.interceptors.request.use((param: AxiosRequestConfig) => ({
  ...param,
  baseURL: API_BASE_URL,
}));

export const getCurrentBearerHeader = (): string | number | boolean => {
  return api.defaults.headers.common['Authorization'];
};

export const updateAxiosBearerToken = (newToken: string): void => {
  api.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
};

/**
 *
 * @template T - type.
 * @param {AxiosResponse<T>} response - axios response.
 * @returns {T} - expected object.
 */
const success = <T>(response: AxiosResponse<T>): T => {
  if (response.headers['content-type'] === 'application/octet-stream') {
    const contentDisposition = response.headers['content-disposition'];
    const originalFileName =
      typeof contentDisposition === 'string' ? contentDisposition.split('filename=')[1] : 'download';
    const filename = validateFilename(originalFileName);
    const url = window.URL.createObjectURL(new Blob([response.data as unknown as BlobPart]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }

  return response.data;
};

const error = (error: AxiosError<Error>): AxiosError<Error> => {
  if (error.response?.status === 401) {
    deleteCookie(JWT_TOKEN_COOKIE_NAME);
    redirectUserToSSOUrl();
  } else if (error.response?.status === 403) {
    deleteCookie(JWT_TOKEN_COOKIE_NAME);
    redirectUserToForbiddenUrl();
  }

  throw error;
};

/**
 * HTTP GET method, used to fetch data `statusCode`: 200.
 *
 * @access public
 * @template T - `TYPE`: expected object.
 * @template R - `RESPONSE`: expected object inside a axios response format.
 * @param {string} url - endpoint you want to reach.
 * @param {AxiosRequestConfig} [config] - axios request configuration.
 * @returns {Promise<T>} HTTP `axios` response payload.
 */
export const get = <T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<T> => {
  return api.get(url, config).then(success).catch(error);
};

/**
 * HTTP POST method `statusCode`: 201 Created.
 *
 * @access public
 * @template T - `TYPE`: expected object.
 * @template B - `BODY`: body request object.
 * @template R - `RESPONSE`: expected object inside a axios response format.
 * @param {string} url - endpoint you want to reach.
 * @param {B} data - payload to be send as the `request body`,
 * @param {AxiosRequestConfig} [config] - axios request configuration.
 * @returns {Promise<R>} - HTTP [axios] response payload.
 */
export const post = <T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<T> => {
  return api.post(url, data, config).then(success).catch(error);
};

/**
 * HTTP PUT method.
 *
 * @access public
 * @template T - `TYPE`: expected object.
 * @template B - `BODY`: body request object.
 * @template R - `RESPONSE`: expected object inside a axios response format.
 * @param {string} url - endpoint you want to reach.
 * @param {B} data - payload to be send as the `request body`,
 * @param {AxiosRequestConfig} [config] - axios request configuration.
 * @returns {Promise<R>} - HTTP [axios] response payload.
 */
export const put = <T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<T> => {
  return api.put(url, data, config).then(success).catch(error);
};

/**
 * HTTP DELETE method, `statusCode`: 204 No Content.
 *
 * @access public
 * @template T - `TYPE`: expected object.
 * @template R - `RESPONSE`: expected object inside a axios response format.
 * @param {string} url - endpoint you want to reach.
 * @param {AxiosRequestConfig} [config] - axios request configuration.
 * @returns {Promise<R>} - HTTP [axios] response payload.
 */
export const del = <T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<T> => {
  return api.delete(url, config).then(success).catch(error);
};

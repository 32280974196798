import { SchoolWidgetGroupDto } from '../../../containers/Commercial/SchoolWidgetGroups/school-widget-groups.dto';
import { Resource } from '../../constants';
import {
  SchoolsResourceState,
  SchoolResourcesActionTypes,
  SET_SCHOOL_DROPDOWN,
  SET_SINGLE_SCHOOL_WIDGET_GROUPS,
} from './school-resources.types';

const initialState: SchoolsResourceState = {
  schools: [] as Resource[],
  schoolWidgetGroupsDto: [] as SchoolWidgetGroupDto[],
};

const schoolsResourcesReducer = (state = initialState, action: SchoolResourcesActionTypes): SchoolsResourceState => {
  switch (action.type) {
    case SET_SCHOOL_DROPDOWN: {
      return {
        ...state,
        schools: action.payload.schools || [],
      };
    }
    case SET_SINGLE_SCHOOL_WIDGET_GROUPS: {
      return {
        ...state,
        schoolWidgetGroupsDto: action.payload.schoolWidgetGroupsDto || [],
      };
    }
    default: {
      return state;
    }
  }
};

export default schoolsResourcesReducer;

import { FiltersConfig } from '../../../../../components/FilterGroup';

export const SCHOOLS_TABLE_FILTERS: FiltersConfig = {
  accreditationId: {
    isDropdown: true,
    title: 'Accreditations',
    options: [],
  },
  status: {
    title: 'Status',
    options: [],
  },
};

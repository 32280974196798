import React, { FunctionComponent } from 'react';

import Button, { ButtonThemes } from '../../Button';

type FormGroupActionButtonProps = {
  label: string;
  onClick: () => void;
};

const FormGroupActionButton: FunctionComponent<FormGroupActionButtonProps> = ({ label, onClick }) => (
  <>
    <Button theme={ButtonThemes.basic} text={label} onClick={(): void => onClick()} />
    <br />
    <br />
  </>
);

export default FormGroupActionButton;

import { CreateRankingCategoryDTO, EditRankingCategoryDTO, RankingCategoryDTO } from './ranking-category-profile.dto';
import {
  CLEAR_CURRENT_RANKING_CATEGORY,
  ClearCurrentRankingCategoryAction,
  CREATE_RANKING_CATEGORY,
  CreateRankingCategoryAction,
  DELETE_RANKING_CATEGORY,
  DeleteRankingCategoryAction,
  EDIT_RANKING_CATEGORY,
  EDIT_RANKING_CATEGORY_DETAILS,
  EditRankingCategoryAction,
  EditRankingCategoryDetailsAction,
  LOAD_RANKING_CATEGORY,
  LoadRankingCategoryAction,
  SET_RANKING_CATEGORY,
  SetRankingCategoryAction,
} from './ranking-category-profile.types';

export const createNewRankingCategoryAction = (data: CreateRankingCategoryDTO): CreateRankingCategoryAction => ({
  type: CREATE_RANKING_CATEGORY,
  payload: { data },
});

export const loadRankingCategoryAction = (id: number): LoadRankingCategoryAction => ({
  type: LOAD_RANKING_CATEGORY,
  payload: { id },
});

export const editRankingCategoryAction = (data: EditRankingCategoryDTO): EditRankingCategoryAction => ({
  type: EDIT_RANKING_CATEGORY,
  payload: { data },
});

export const editRankingCategoryDetailsAction = (data: EditRankingCategoryDTO): EditRankingCategoryDetailsAction => ({
  type: EDIT_RANKING_CATEGORY_DETAILS,
  payload: { data },
});

export const deleteRankingCategoryAction = (id: number): DeleteRankingCategoryAction => ({
  type: DELETE_RANKING_CATEGORY,
  payload: { id },
});

export const setRankingCategoryAction = (data: RankingCategoryDTO): SetRankingCategoryAction => ({
  type: SET_RANKING_CATEGORY,
  payload: { data },
});

export const clearCurrentRankingCategoryAction = (): ClearCurrentRankingCategoryAction => ({
  type: CLEAR_CURRENT_RANKING_CATEGORY,
});

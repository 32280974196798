import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button, { ButtonSizes } from '../../../../../../components/Button';
import DatePicker from '../../../../../../components/DatePicker';
import Grid from '../../../../../../components/Grid';
import Modal from '../../../../../../components/Modal';
import RadioInputs, { RadioInputsOption } from '../../../../../../components/RadioInputs';
import { loadRankingAction, RankingDTO, selectCurrentRanking } from '../../RankingInfo';
import { updateSchedulerRequest } from '../RankingTimeline.api';

const SCHEDULE_STATUS_NO_SCHEDULE = 1;
const SCHEDULE_STATUS_PUBLISH = 2;

const RADIO_INPUT_OPTIONS: RadioInputsOption[] = [
  { label: 'No schedule', value: SCHEDULE_STATUS_NO_SCHEDULE },
  { label: 'Schedule publish (local time)', value: SCHEDULE_STATUS_PUBLISH },
];

interface CalendarChangeValue {
  name: string;
  value: number;
}

interface ScheduleRankingModalProps {
  isModalOpened: boolean;
  toggleModal: (open: boolean) => void;
}

export const ScheduleRankingModal: FunctionComponent<ScheduleRankingModalProps> = ({ isModalOpened, toggleModal }) => {
  const dispatch = useDispatch();
  const { id: rankingId, publicationDate, status } = useSelector(selectCurrentRanking) as RankingDTO;
  const [isLoading, setIsLoading] = useState(false);
  const [scheduleRadioValue, setScheduleRadioValue] = useState<number>(
    status.key === 5 ? SCHEDULE_STATUS_PUBLISH : SCHEDULE_STATUS_NO_SCHEDULE,
  );
  const [scheduleDateTime, setScheduleDateTime] = useState<CalendarChangeValue>({} as CalendarChangeValue);

  const onRadioChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setScheduleRadioValue(+value);
    +value === SCHEDULE_STATUS_NO_SCHEDULE && setScheduleDateTime({} as CalendarChangeValue);
  };

  const handleDateTimeChange = ({ target }: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = target;
    setScheduleDateTime({ name, value: +value });
  };

  const handleScheduleSave = async (): Promise<void> => {
    const scheduledDate = scheduleDateTime.value ? new Date(scheduleDateTime.value).toISOString() : null;
    setIsLoading(true);
    await updateSchedulerRequest(rankingId, scheduledDate).then(() => {
      setIsLoading(false);
      dispatch(loadRankingAction(rankingId));
      toggleModal(false);
    });
  };

  const setDatepickerValue = (): string => {
    return publicationDate ? publicationDate : new Date(scheduleDateTime.value).toISOString();
  };

  return (
    <Modal title="Schedule ranking" isOpen={isModalOpened} onClose={(): void => toggleModal(false)}>
      <RadioInputs name="status" value={scheduleRadioValue} onChange={onRadioChanged} options={RADIO_INPUT_OPTIONS} />
      {scheduleRadioValue === SCHEDULE_STATUS_PUBLISH && (
        <DatePicker
          name="Schedule"
          value={setDatepickerValue()}
          required={true}
          showTimeSelect={true}
          type="dateTime"
          onChange={handleDateTimeChange}
          timeIntervals={60}
        />
      )}
      <Grid item md={12} xs={12} className="mba-no-padding mba-mt-20">
        <Button
          text="Cancel"
          wrapperClass="mba-mr-10"
          size={ButtonSizes.big}
          onClick={(): void => toggleModal(false)}
        />
        <Button
          primary
          text="Save"
          size={ButtonSizes.big}
          isLoading={isLoading}
          disabled={scheduleRadioValue === SCHEDULE_STATUS_PUBLISH && !scheduleDateTime.value}
          onClick={handleScheduleSave}
        />
      </Grid>
    </Modal>
  );
};

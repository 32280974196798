import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import Button, { ButtonSizes } from '../../../../../../components/Button';
import Form from '../../../../../../components/Form';
import Grid from '../../../../../../components/Grid';
import { getHtmlFromJSON, getRawFromHTML } from '../../../../../../components/RichTextEditor/RichTextEditor.helpers';
import Typography, { TypographyVariants } from '../../../../../../components/Typography';
import { selectRequestIsLoading } from '../../../../../../shared/state/global-request';
import {
  EDIT_RANKING_DETAILS,
  EditParticipationCriteriaDTO,
  editRankingDetailsAction,
  EditRankingDTO,
  LOAD_RANKING_PARTICIPATION_CRITERIA,
  loadRankingParticipationCriteriaAction,
  RankingDTO,
  RankingProfileRouteParams,
  selectCurrentRanking,
} from '../../RankingInfo';

const ParticipationCriteria: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id: rankingId } = useParams<RankingProfileRouteParams>();

  const currentRanking = useSelector(selectCurrentRanking) as RankingDTO;
  const isLoading = useSelector(selectRequestIsLoading(EDIT_RANKING_DETAILS));
  const isPreviousLoading = useSelector(selectRequestIsLoading(LOAD_RANKING_PARTICIPATION_CRITERIA));
  // const ranking = useSelector(selectCurrentRanking); // TODO in need to generate the PDF

  const [currentCriteria, setCurrentCriteria] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [isLoadFromPreviousActive, setIsLoadFromPreviousActive] = useState(false);
  const htmlContainer = useRef<HTMLDivElement>(null);

  const { participationCriteria } = currentRanking;

  useEffect(() => {
    if (rankingId) {
      dispatch(loadRankingParticipationCriteriaAction(parseInt(rankingId)));
    }
  }, [dispatch, rankingId]);

  useEffect(() => {
    if (!isLoadFromPreviousActive) {
      if (isEdit) {
        setIsEdit(false);
      }

      setCurrentCriteria(currentRanking.participationCriteria || '');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRanking, isLoadFromPreviousActive]);

  const handleEditSubmit = (data: EditParticipationCriteriaDTO): void => {
    setIsLoadFromPreviousActive(false);
    dispatch(
      editRankingDetailsAction({
        ...data,
        id: rankingId,
      } as unknown as EditRankingDTO),
    );
  };

  const handleLoadFromPreviousRanking = (): void => {
    dispatch(loadRankingParticipationCriteriaAction(parseInt(rankingId), true));
    setIsLoadFromPreviousActive(true);
  };

  const handleEditCancel = (): void => {
    dispatch(loadRankingParticipationCriteriaAction(parseInt(rankingId)));
    setIsEdit(false);
    setIsLoadFromPreviousActive(false);
  };

  const __html = currentCriteria ? getHtmlFromJSON(currentCriteria) : '-';

  // TODO when we fix proper generating of PDF
  // const downloadPDF = (): void => {
  //   const pdfName = `${ranking.name}_${new Date().toDateString()}_Participation_Criteria.pdf`;

  //   new jsPDF('p', 'px', 'a4').html(htmlContainer.current || '', {
  //     x: 50,
  //     y: 50,
  //     callback: (doc) => {
  //       doc.save(pdfName);
  //     },
  //   });
  // };

  return (
    <Grid container>
      <Grid item compact xs={12} md={11}>
        <div className="mba-heading--wrapper mba-heading--table">
          <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title">
            Participation criteria
          </Typography>
          {isEdit ? (
            <Button
              primary
              text="Load from previous ranking"
              size={ButtonSizes.big}
              isLoading={isPreviousLoading}
              onClick={handleLoadFromPreviousRanking}
            />
          ) : (
            <div className="mba-actions">
              {/* <Button text="Download as PDF" size={ButtonSizes.big} onClick={downloadPDF} /> */}
              <Button text="Edit criteria" size={ButtonSizes.big} primary onClick={(): void => setIsEdit(true)} />
            </div>
          )}
        </div>
        {isEdit ? (
          <Form
            id="participation-criteria-form"
            config={{
              participationCriteria: {
                fieldType: 'richText',
                placeholder: 'Fill in the participation criteria',
              },
            }}
            isLoading={isLoading}
            onSubmit={handleEditSubmit}
            submitButtonText="Save"
            actions={[
              {
                label: 'Cancel',
                onClick: handleEditCancel,
              },
            ]}
            initialValues={{ participationCriteria: getRawFromHTML(participationCriteria) }}
            errors={{}}
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html }} ref={htmlContainer} />
        )}
      </Grid>
    </Grid>
  );
};

export default ParticipationCriteria;

import React, { FunctionComponent } from 'react';

import { NavigationConfiguration } from '../Layout.constants';
import Navigation from '../Navigation';

type HeaderProps = {
  onLogoutClick?: () => void;
  routes?: NavigationConfiguration;
  tagline?: string;
  title?: string;
};

const Header: FunctionComponent<HeaderProps> = ({ onLogoutClick, routes, tagline, title }) => (
  <>
    <header className="o-header-services" data-o-component="o-header-services">
      <div className="o-header-services__top">
        <div className="o-header-services__hamburger">
          <a className="o-header-services__hamburger-icon" href="#core-nav-fallback" role="button">
            <span className="o-header-services__visually-hidden">Open primary navigation</span>
          </a>
        </div>
        <div className="o-header-services__logo" />
        <div className="o-header-services__title">
          {title && (
            <a className="o-header-services__product-name" href="/">
              {title}
            </a>
          )}
          {tagline && <span className="o-header-services__product-tagline">{tagline}</span>}
        </div>
      </div>
      {routes && <Navigation routes={routes} onLogoutClick={onLogoutClick} />}
    </header>
  </>
);

export default Header;

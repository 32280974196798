import { RankingFieldDTO } from './ranking-fields-list.dto';
import { TableListData } from '../../../../../../shared/constants';
import { getTableListDefaultState } from '../../../../../../shared/helpers';
import { LOAD_RANKING_FIELDS, RankingFieldsActionTypes, SET_RANKING_FIELDS } from './ranking-fields-list.types';

const initialState: TableListData<RankingFieldDTO> = getTableListDefaultState<RankingFieldDTO>();

const rankingFieldsListReducer = (
  state = initialState,
  action: RankingFieldsActionTypes,
): TableListData<RankingFieldDTO> => {
  switch (action.type) {
    case LOAD_RANKING_FIELDS: {
      return {
        ...state,
        filter: action.payload.pageConfig?.filter || {},
      };
    }
    case SET_RANKING_FIELDS: {
      return {
        ...state,
        ...action.payload.tableData,
      };
    }
    default: {
      return state;
    }
  }
};

export default rankingFieldsListReducer;

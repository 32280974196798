import { RankingSpreadsheetDTO } from './spreadsheets.dto';
import { PageSearchQueryConfig } from '../../../../../shared/constants';
import { RootState } from '../../../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const spreadsheetsSelector = (state: RootState) => state.rankings.spreadsheets;

export const selectRankingSpreadsheetsTableData =
  (type: string) =>
  (state: RootState): RankingSpreadsheetDTO[] => {
    let spreadsheets = [] as RankingSpreadsheetDTO[];

    if (state.rankings.spreadsheets.hasOwnProperty(type)) {
      spreadsheets = state.rankings.spreadsheets[type].data;
    }

    return spreadsheets;
  };

export const selectRankingSpreadsheetsPageConfiguration = (type: string) =>
  createDeepEqualSelector(spreadsheetsSelector, (spreadsheets) => {
    let pageConfig = {} as PageSearchQueryConfig;
    if (spreadsheets.hasOwnProperty(type)) {
      pageConfig = {
        filter: spreadsheets[type].filter,
        limit: spreadsheets[type].pagination.limit,
        order: spreadsheets[type].sortOrder.order,
        page: spreadsheets[type].pagination.page,
        search: spreadsheets[type].search,
        sortBy: spreadsheets[type].sortOrder.sortBy,
        totalPages: spreadsheets[type].pagination.totalPages,
      };
    }
    return pageConfig;
  });

import { NavigationConfiguration } from '../../../../App/Main/components/Layout';

export const RANKING_PROFILE_DETAILS_MAIN_ROUTE = '/rankings/profile/:id/details';

export const RANKING_PROFILE_DETAILS_SUBNAVIGATION: NavigationConfiguration = {
  welcomeText: {
    label: 'Welcome text',
    route: '/welcome-text',
  },
  participationCriteria: {
    label: 'Participation criteria',
    route: '/participation-criteria',
  },
  instructions: {
    label: 'Instructions and deadlines',
    route: '/instructions-and-deadlines',
  },
};

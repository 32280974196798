import { PageSearchQueryConfig, TableListData } from '../../../shared/constants';
import { SchoolsWithLeadGenDTO } from './schools-with-lead-gen.dto';
import {
  LoadSchoolsWithLeadGenAction,
  LOAD_SCHOOLS_WITH_LEAD_GEN,
  SetSchoolsWithLeadGenAction,
  SET_SCHOOLS_WITH_LEAD_GEN,
} from './schools-with-lead-gen.types';

export const loadSchoolsWithLeadGenAction = (pageConfig?: PageSearchQueryConfig): LoadSchoolsWithLeadGenAction => ({
  type: LOAD_SCHOOLS_WITH_LEAD_GEN,
  payload: { pageConfig },
});

export const setSchoolsWithLeadGenAction = (
  data: TableListData<SchoolsWithLeadGenDTO>,
): SetSchoolsWithLeadGenAction => ({
  type: SET_SCHOOLS_WITH_LEAD_GEN,
  payload: { data },
});

import { NavigationConfiguration } from '../../App/Main/components/Layout';

export const RANKINGS_ADDITIONAL_INFORMATION_MAIN_ROUTE = '/rankings/information';

export const ADDITIONAL_INFORMATION_NAVIGATION: NavigationConfiguration = {
  calendar: {
    exact: true,
    label: 'Calendar',
    route: '/calendar',
  },
  methodology: {
    exact: true,
    label: 'Methodology',
    route: '/methodology',
  },
  faqs: {
    exact: true,
    label: 'FAQs',
    route: '/faq',
  },
};

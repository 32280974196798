import { FormConfig, FormControlFieldType } from '../../../../../components/Form';
import { ColumnDefinition, TableHeaderColumnTypes } from '../../../../../components/Table';

export const SCHOOL_USERS_STATUS_COLUMN_DEFINITION: ColumnDefinition = {
  id: 'status',
  sorting: true,
  title: 'Status',
  type: TableHeaderColumnTypes.status,
};

export const SCHOOL_USERS_NAME_COLUMN_DEFINITION: ColumnDefinition = {
  id: 'name',
  path: '/schools/profile/:school.key/users/:id',
  sorting: true,
  title: 'Name',
  type: TableHeaderColumnTypes.compositeLink,
  width: '180px',
};

export const SCHOOL_USERS_EMAIL_COLUMN_DEFINITION: ColumnDefinition = {
  id: 'email',
  title: 'E-mail',
  sorting: true,
};

export const SCHOOL_USERS_ROLE_COLUMN_DEFINITION: ColumnDefinition = {
  id: 'role',
  title: 'Role',
  type: TableHeaderColumnTypes.capitalize,
  width: '110px',
};

export const SCHOOL_USERS_SCHOOL_COLUMN_DEFINITION: ColumnDefinition = {
  id: 'school',
  title: 'School',
  type: TableHeaderColumnTypes.compositeLink,
  path: '/schools/profile/:school.key/details',
};

export const SCHOOL_USER_CREATE_FORM_CONFIG: FormConfig = {
  name: {
    label: 'Name',
    placeholder: `Enter user's name`,
    fieldType: 'text' as FormControlFieldType,
    validations: {
      required: {
        errorText: `Please enter name!`,
      },
    },
  },
  role: {
    label: 'Role',
    placeholder: `Select user's role`,
    fieldType: 'select' as FormControlFieldType,
    options: [],
    validations: {
      required: {
        errorText: `Please select a role for the user!`,
      },
    },
  },
  email: {
    label: 'Email',
    placeholder: `Enter user's email`,
    fieldType: 'text' as FormControlFieldType,
    validations: {
      required: {
        errorText: `Please enter user's e-mail address!`,
      },
      regex: {
        errorText: 'Please enter a valid e-mail address!',
        rule: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      },
    },
  },
  phone: {
    label: 'Phone',
    placeholder: `Enter user's phone`,
    fieldType: 'text' as FormControlFieldType,
  },
};

export const IMPORT_SCHOOL_USERS_FORM_CONFIG: FormConfig = {
  userIds: {
    label: 'Partnered school users',
    placeholder: `Select users from partnered schools`,
    fieldType: 'select' as FormControlFieldType,
    multiple: true,
    searchable: true,
    options: [],
    validations: {
      required: {
        errorText: `Please select users to import!`,
      },
    },
  },
};

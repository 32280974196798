import { RankingCalendarDto } from './calendar.dto';
import { CalendarActionTypes, ADD_RANKING_CALENDAR, SET_RANKING_CALENDARS } from './calendar.types';

const initialState: RankingCalendarDto[] = [];

const calendarReducer = (state = initialState, action: CalendarActionTypes): RankingCalendarDto[] => {
  switch (action.type) {
    case ADD_RANKING_CALENDAR: {
      return [...state, action.payload.data];
    }
    case SET_RANKING_CALENDARS: {
      return action.payload.data;
    }
    default: {
      return state;
    }
  }
};

export default calendarReducer;

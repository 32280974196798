import React, { FunctionComponent } from 'react';
import { combineReducers } from 'redux';

import UsersList, { singleUserReducer, usersReducer } from './Users';
import TermsAndConditions, { termsReducer } from './TermsAndConditions';
import { PrivacyPolicy, privacyReducer } from './PrivacyPolicy';
import { PageRouter } from '../App/Main/components/Layout';
import { PageProps } from '../../shared/constants';
import Logs, { logsReducer } from './Logs';
import { logsNav } from './Logs/components/Logs.constants';
import OnsiteMessaging, { onsiteMessagingReducer, ONSITE_MESSAGING_NAV } from './OnsiteMessaging';

const componentsMap = {
  users: UsersList,
  termsAndConditions: TermsAndConditions,
  privacyPolicy: PrivacyPolicy,
  logsNav: Logs,
  onsiteMessaging: OnsiteMessaging,
};

const Admin: FunctionComponent<PageProps> = ({ navigation, route }) => {
  let content = <div>Admin</div>;

  if (navigation) {
    content = (
      <PageRouter
        mainRoute={route}
        navigation={{ ...navigation, logsNav, onsiteMessaging: ONSITE_MESSAGING_NAV }}
        componentsMap={componentsMap}
      />
    );
  }

  return content;
};

export default Admin;

export const adminReducer = combineReducers({
  user: singleUserReducer,
  users: usersReducer,
  terms: termsReducer,
  privacy: privacyReducer,
  logs: logsReducer,
  onsiteMessaging: onsiteMessagingReducer,
});

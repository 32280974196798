import { FormConfig } from '../../../../../../components/Form';
import { DATE_FORMAT_REGEX } from '../../../../../../shared/constants';

export const RANKING_DETAILS_CONFIG: FormConfig = {
  name: {
    fieldType: 'text',
    placeholder: 'Enter ranking name',
    required: true,
    title: 'Name',
    validations: {
      required: {
        errorText: `Please enter ranking name!`,
      },
    },
  },
  rankingTypeId: {
    fieldType: 'select',
    options: [],
    placeholder: 'Choose a ranking type',
    required: true,
    searchable: true,
    title: 'Ranking type',
    validations: {
      required: {
        errorText: `Please choose a ranking type!`,
      },
    },
  },
  status: {
    fieldType: 'select',
    placeholder: 'Choose a status',
    required: true,
    options: [],
    title: 'Status',
    validations: {
      required: {
        errorText: `Please choose a status!`,
      },
    },
  },
  publicationDate: {
    fieldType: 'date',
    placeholder: 'Enter date DD/MM/YYYY',
    previewFormat: 'Do MMM YYYY',
    title: 'Publication date',
    validations: {
      regex: {
        errorText: 'Please enter a valid date DD/MM/YYYY',
        rule: DATE_FORMAT_REGEX,
      },
    },
  },
  slug: {
    fieldType: 'text',
    placeholder: 'Enter slug',
    required: true,
    title: 'Slug',
    validations: {
      required: {
        errorText: `Please enter a valid slug!`,
      },
    },
  },
  updatedAt: {
    disabled: true,
    previewFormat: 'Do MMM YYYY',
    fieldType: 'text',
    title: 'Last updated',
  },
};

export const RANKING_NOTE_CONFIG: FormConfig = {
  note: {
    fieldType: 'richText',
    rows: 10,
    placeholder: 'Enter notes',
  },
};

export const SPECIAL_REPORT_CONFIG: FormConfig = {
  specialReportName: {
    fieldType: 'text',
    placeholder: 'Enter ranking name',
    title: 'Name',
    validations: {
      required: {
        errorText: 'Please enter ranking name!',
      },
    },
  },
  specialReportSummary: {
    fieldType: 'textarea',
    placeholder: 'Enter report preview text',
    rows: 4,
    title: 'Preview text',
    validations: {
      required: {
        errorText: 'Please enter report preview text!',
      },
    },
  },
  specialReportLink: {
    fieldType: 'text',
    placeholder: 'Enter url',
    title: 'Link',
    validations: {
      required: {
        errorText: 'Please enter ranking url!',
      },
    },
  },
  specialReportImageUrl: {
    fieldType: 'text',
    placeholder: 'Enter photo URL',
    title: 'Photo URL',
    validations: {
      required: {
        errorText: 'Please enter photo url!',
      },
    },
  },
};

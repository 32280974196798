import { FormConfig } from '../../../../../components/Form';
import { ColumnDefinition, TableHeaderColumnTypes } from '../../../../../components/Table';

export const RANKING_TYPE_CREATE_FORM_CONFIG: FormConfig = {
  name: {
    label: 'Name',
    placeholder: 'Enter name',
    fieldType: 'text',
    validations: {
      required: {
        errorText: `Please enter a name!`,
      },
    },
  },
  shortName: {
    label: 'Short name',
    placeholder: 'Enter short name',
    fieldType: 'text',
    validations: {
      required: {
        errorText: `Please enter a short name!`,
      },
    },
  },
  categoryId: {
    label: 'Ranking category',
    placeholder: 'Choose a ranking category',
    fieldType: 'select',
    validations: {
      required: {
        errorText: `Please choose a ranking category!`,
      },
    },
    options: [],
  },
};

export const RANKING_TYPE_EDIT_FORM_CONFIG: FormConfig = {
  ...RANKING_TYPE_CREATE_FORM_CONFIG,
  status: {
    label: 'Status',
    placeholder: 'Choose status',
    fieldType: 'select',
    options: [],
  },
};

export const RANKING_TYPES_TABLE_COLUMN_DEFINITION: ColumnDefinition[] = [
  {
    id: 'id',
    title: 'SID',
    sorting: true,
    width: '40px',
  },
  {
    id: 'name',
    sorting: true,
    title: 'Name',
    type: TableHeaderColumnTypes.link,
    path: '/rankings/categorization/ranking-types/:id/details',
  },
  {
    id: 'count',
    sorting: true,
    title: 'Rankings',
    width: '120px',
  },
  {
    id: 'updatedAt',
    sorting: true,
    title: 'Last updated',
    type: TableHeaderColumnTypes.date,
    width: '160px',
  },
];

import { Resource } from '../../constants';
import {
  LOAD_RANKING_TYPES_DROPDOWN,
  LoadRankingTypesDropdownAction,
  SET_RANKING_TYPES_DROPDOWN,
  SetRankingTypesDropdownAction,
} from './ranking-types-dropdown.types';

export const loadRankingTypesDropdownAction = (): LoadRankingTypesDropdownAction => ({
  type: LOAD_RANKING_TYPES_DROPDOWN,
});

export const setRankingTypesDropdownAction = (rankingTypes: Resource[]): SetRankingTypesDropdownAction => ({
  type: SET_RANKING_TYPES_DROPDOWN,
  payload: { rankingTypes },
});

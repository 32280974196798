import { RankingDTO } from '.';
import {
  CLEAR_CURRENT_RANKING,
  CREATE_RANKING,
  EDIT_RANKING,
  RankingActionTypes,
  RankingProfileState,
  SET_RANKING,
  SET_RANKING_PARTICIPATION_CRITERIA,
  SET_RANKING_WELCOME_TEXT,
} from './ranking-profile.types';

const initialState: RankingProfileState = {} as RankingDTO;

const rankingProfileReducer = (state = initialState, action: RankingActionTypes): RankingProfileState => {
  switch (action.type) {
    case CLEAR_CURRENT_RANKING: {
      return initialState;
    }
    case CREATE_RANKING:
    case EDIT_RANKING:
    case SET_RANKING: {
      return {
        ...state,
        ...action.payload.data,
      };
    }
    case SET_RANKING_WELCOME_TEXT: {
      return {
        ...state,
        welcomeText: action.payload.data,
      };
    }
    case SET_RANKING_PARTICIPATION_CRITERIA: {
      return {
        ...state,
        participationCriteria: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default rankingProfileReducer;

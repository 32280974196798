import {
  LOAD_ACCREDITATIONS,
  LoadAccreditationsAction,
  SET_ACCREDITATIONS,
  SetAccreditationsAction,
} from './accreditations.types';
import { AccreditationDTO } from '../AccreditationProfile';
import { PageSearchQueryConfig, TableListData } from '../../../../shared/constants';

export const loadAccreditationsAction = (pageConfig?: PageSearchQueryConfig): LoadAccreditationsAction => ({
  type: LOAD_ACCREDITATIONS,
  payload: { pageConfig },
});

export const setAccreditationsAction = (tableData: TableListData<AccreditationDTO>): SetAccreditationsAction => ({
  type: SET_ACCREDITATIONS,
  payload: { tableData },
});

import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { PagedResponseDTO } from '../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../shared/state/global-request';
import { setSchoolsWithLeadGenAction } from './schools-with-lead-gen.actions';
import { getSchoolsWithLeadGenRequest, updateSchoolActiveRequest } from './schools-with-lead-gen.api';
import { SchoolsWithLeadGenDTO } from './schools-with-lead-gen.dto';
import { LeadGenTypes, LoadSchoolsWithLeadGenAction, LOAD_SCHOOLS_WITH_LEAD_GEN } from './schools-with-lead-gen.types';
import {
  UPDATE_SCHOOL_LEAD_ACTIVE,
  UpdateSchoolLeadActiveAction,
} from '../SchoolLeads/SchoolWithLeadDetails/school-with-lead-details.types';
import { updateSchoolActiveAction } from '../SchoolLeads/SchoolWithLeadDetails/school-with-lead-details.actions';

function* loadSchoolsWithLeadGenData(
  action: LoadSchoolsWithLeadGenAction,
): Generator<
  CallEffect<PagedResponseDTO<SchoolsWithLeadGenDTO[]>> | PutEffect<LeadGenTypes> | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;
  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig } = action.payload;
    const { data, pagination, sortOrder } = (yield call(getSchoolsWithLeadGenRequest, pageConfig)) as PagedResponseDTO<
      SchoolsWithLeadGenDTO[]
    >;

    yield put(setSchoolsWithLeadGenAction({ data, pagination, sortOrder }));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    console.log(error);
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* updateSchoolLeadActiveGenerator(action: UpdateSchoolLeadActiveAction): Generator {
  const { type: actionType, payload } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateSchoolActiveRequest, payload.id, payload.hasLeadGen);
    yield put(setRequestSucceededAction(actionType));
  } catch (e: any) {
    yield put(setRequestFailedAction(actionType, e));
  }
}

export function* updateSchoolLeadActiveSaga(): Generator {
  yield takeLatest(UPDATE_SCHOOL_LEAD_ACTIVE, updateSchoolLeadActiveGenerator);
}

export default function* schoolsWithLeadGenSaga(): Generator {
  yield takeLatest(LOAD_SCHOOLS_WITH_LEAD_GEN, loadSchoolsWithLeadGenData);
}

import { CreateRankingTypeDTO, EditRankingTypeDTO, RankingTypeDTO } from './ranking-type-profile.dto';
import {
  CLEAR_CURRENT_RANKING_TYPE,
  ClearCurrentRankingTypeAction,
  CREATE_RANKING_TYPE,
  CreateRankingTypeAction,
  DELETE_RANKING_TYPE,
  DeleteRankingTypeAction,
  EDIT_RANKING_TYPE,
  EDIT_RANKING_TYPE_DETAILS,
  EditRankingTypeAction,
  EditRankingTypeDetailsAction,
  LOAD_RANKING_TYPE,
  LoadRankingTypeAction,
  SET_RANKING_TYPE,
  SetRankingTypeAction,
} from './ranking-type-profile.types';

export const createNewRankingTypeAction = (data: CreateRankingTypeDTO): CreateRankingTypeAction => ({
  type: CREATE_RANKING_TYPE,
  payload: { data },
});

export const loadRankingTypeAction = (id: number): LoadRankingTypeAction => ({
  type: LOAD_RANKING_TYPE,
  payload: { id },
});

export const editRankingTypeAction = (data: EditRankingTypeDTO): EditRankingTypeAction => ({
  type: EDIT_RANKING_TYPE,
  payload: { data },
});

export const editRankingTypeDetailsAction = (data: EditRankingTypeDTO): EditRankingTypeDetailsAction => ({
  type: EDIT_RANKING_TYPE_DETAILS,
  payload: { data },
});

export const deleteRankingTypeAction = (id: number): DeleteRankingTypeAction => ({
  type: DELETE_RANKING_TYPE,
  payload: { id },
});

export const setRankingTypeAction = (data: RankingTypeDTO): SetRankingTypeAction => ({
  type: SET_RANKING_TYPE,
  payload: { data },
});

export const clearCurrentRankingTypeAction = (): ClearCurrentRankingTypeAction => ({
  type: CLEAR_CURRENT_RANKING_TYPE,
});

import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

import { loadSchoolUsersForSchoolAction, setSchoolUsersForSchoolAction } from './school-users.actions';
import { getSchoolUsersForSchoolRequest, importSchoolUsersRequest } from './school-users.api';
import {
  IMPORT_SCHOOL_USERS,
  ImportSchoolUsersAction,
  LOAD_SCHOOL_USERS_FOR_SCHOOL,
  LoadSchoolUsersForSchoolAction,
  SchoolUsersActionTypes,
} from './school-users.types';
import { SchoolUserDTO } from '../../SchoolUserProfile';
import { BasicResponseDTO, PagedResponseDTO } from '../../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../../shared/state/global-request';
import { loadResourcesAction, ResourcesActionTypes } from '../../../../shared/state/resources';

function* loadSchoolUsersTableDataForSchool(
  action: LoadSchoolUsersForSchoolAction,
): Generator<
  CallEffect<PagedResponseDTO<SchoolUserDTO[]>> | PutEffect<SchoolUsersActionTypes> | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig, schoolId } = action.payload;
    const { data, pagination, sortOrder } = (yield call(
      getSchoolUsersForSchoolRequest,
      schoolId,
      pageConfig,
    )) as PagedResponseDTO<SchoolUserDTO[]>;
    yield put(setSchoolUsersForSchoolAction({ data, pagination, sortOrder }));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* importSchoolUsersSaga(
  action: ImportSchoolUsersAction,
): Generator<
  PutEffect<GlobalRequestActions | SchoolUsersActionTypes | ResourcesActionTypes> | CallEffect<BasicResponseDTO>
> {
  const {
    type: actionType,
    payload: { data, schoolId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(importSchoolUsersRequest, data, schoolId);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadSchoolUsersForSchoolAction(schoolId));
    yield put(loadResourcesAction(`schools/${schoolId}/accounts`));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* schoolUsersListSaga(): Generator {
  yield takeLatest(LOAD_SCHOOL_USERS_FOR_SCHOOL, loadSchoolUsersTableDataForSchool);
  yield takeLatest(IMPORT_SCHOOL_USERS, importSchoolUsersSaga);
}

import { CheckboxInputOption } from '../../../../../components/Checkbox';
import { FiltersConfig } from '../../../../../components/FilterGroup';

export const WIDGETS_TABLE_FILTERS: FiltersConfig = {
  status: {
    options: [
      { name: 'all', text: 'Show all', value: true },
      { name: 'active', text: 'Show active only', value: false },
      { name: 'inactive', text: 'Show inactive only', value: false },
      { name: 'expired', text: 'Show expired only', value: false },
    ],
    title: 'Status',
  },
  rankingType: {
    isDropdown: true,
    title: 'Scope',
    options: [],
  },
};

export const defaultRankingOption: CheckboxInputOption = { name: 'all', text: 'All ranking types', value: false };

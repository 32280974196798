import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import Button, { ButtonSizes } from '../../../../../components/Button';
import EditableGroup from '../../../../../components/EditableGroup';
import { FormDataValues } from '../../../../../components/Form';
import Icon from '../../../../../components/Icon';
import Modal, { MODAL_ANIMATION_DURATION } from '../../../../../components/Modal';
import PageLoader from '../../../../../components/PageLoader';
import Typography, { TypographyVariants } from '../../../../../components/Typography';
import {
  selectRequestErrorMessage,
  selectRequestFormErrors,
  selectRequestIsLoading,
  setRequestSucceededAction,
} from '../../../../../shared/state/global-request';
import { RANKING_CATEGORIES_MAIN_TABLE_ROUTE } from '../../RankingCategoriesList/components';
import { RANKING_CATEGORY_DETAILS_CONFIG } from '../RankingCategoryProfile.constants';
import {
  deleteRankingCategoryAction,
  editRankingCategoryDetailsAction,
  loadRankingCategoryAction,
} from '../ranking-category-profile.actions';
import { EditRankingCategoryDTO, RankingCategoryDTO } from '../ranking-category-profile.dto';
import { selectCurrentRankingCategory } from '../ranking-category-profile.selectors';
import {
  DELETE_RANKING_CATEGORY,
  EDIT_RANKING_CATEGORY_DETAILS,
  LOAD_RANKING_CATEGORY,
  RankingCategoryProfileRouteParams,
} from '../ranking-category-profile.types';
import RankingCategoryDeleteConfirmation from './RankingCategoryDeleteConfirmation';
import RankingTypesTable from './RankingTypesTable';
import NotFound from '../../../../../components/NotFound';

const RankingCategoryProfile: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id } = useParams<RankingCategoryProfileRouteParams>();
  const formErrors = useSelector(selectRequestFormErrors(EDIT_RANKING_CATEGORY_DETAILS));
  const isLoading = useSelector(selectRequestIsLoading(LOAD_RANKING_CATEGORY));
  const isEditSaving = useSelector(selectRequestIsLoading(EDIT_RANKING_CATEGORY_DETAILS));
  const currentRankingCategory = useSelector(selectCurrentRankingCategory) as RankingCategoryDTO;
  const deleteRankingCategoryError = useSelector(selectRequestErrorMessage(DELETE_RANKING_CATEGORY));
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const { id: rankingCategoryId, name, isDefault } = currentRankingCategory;

  useEffect(() => {
    if (id) {
      dispatch(loadRankingCategoryAction(parseInt(id)));
    }
  }, [dispatch, id]);

  const resetFormState = (): void => {
    dispatch(setRequestSucceededAction(EDIT_RANKING_CATEGORY_DETAILS));
  };

  const updateRankingCategory = (data: FormDataValues): void => {
    dispatch(
      editRankingCategoryDetailsAction({
        ...data,
        id: rankingCategoryId,
      } as EditRankingCategoryDTO),
    );
  };

  const handleDeleteSchoolConfirm = (): void => {
    dispatch(deleteRankingCategoryAction(rankingCategoryId));
  };

  const openModal = (): void => {
    setIsConfirmPopupOpen(true);
  };

  const closeModal = (): void => {
    setIsConfirmPopupOpen(false);

    setTimeout(() => {
      dispatch(setRequestSucceededAction(DELETE_RANKING_CATEGORY));
    }, MODAL_ANIMATION_DURATION);
  };

  return (
    <>
      {rankingCategoryId ? (
        <>
          <EditableGroup<RankingCategoryDTO>
            columnSize={4}
            data={currentRankingCategory}
            editButtonLabel="Edit details"
            formConfig={RANKING_CATEGORY_DETAILS_CONFIG}
            formErrors={formErrors}
            headingType="back"
            isEditable={!isDefault}
            isLoading={isEditSaving}
            heading={
              <div>
                <Link
                  className="o-layout__unstyled-element mba-back-arrow"
                  title="Go back to all ranking categories"
                  to={RANKING_CATEGORIES_MAIN_TABLE_ROUTE}
                >
                  <Icon name="arrow-left" size={30} />
                </Link>
                <span className="mba-center--vertically">{name}</span>
              </div>
            }
            onEditSubmit={updateRankingCategory}
            onEditCancel={resetFormState}
          />
          <hr className="mba-separator" />
          {rankingCategoryId && <RankingTypesTable rankingCategoryId={rankingCategoryId} />}
          {!isDefault && (
            <>
              <hr className="mba-separator" />
              <div className="mba-heading--wrapper mba-heading--table">
                <Typography component="p" variant={TypographyVariants.h3} className="mba-heading--title-2">
                  Danger zone
                </Typography>
                <Button primary danger text="Delete ranking category" size={ButtonSizes.big} onClick={openModal} />
              </div>
              <br />
              <br />
              <Modal title="Delete a ranking category" isOpen={isConfirmPopupOpen} onClose={closeModal}>
                <RankingCategoryDeleteConfirmation
                  error={deleteRankingCategoryError}
                  name={name}
                  onCancel={closeModal}
                  onConfirm={handleDeleteSchoolConfirm}
                />
              </Modal>
            </>
          )}
        </>
      ) : isLoading || !id ? (
        <PageLoader />
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default RankingCategoryProfile;

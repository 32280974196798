import { ColumnDefinition, TableHeaderColumnTypes } from '../../../../../../../components/Table';

export enum RANKING_SPREADSHEETS_TYPES {
  'alumni' = 'alumni',
  'faculty' = 'faculty',
  'research' = 'research',
}

export const RANKING_SPREADSHEETS_TABLE_COLUMN_DEFINITION: ColumnDefinition[] = [
  {
    id: 'schoolName',
    sorting: true,
    title: 'School Name',
    type: TableHeaderColumnTypes.link,
    path: '/schools/profile/:id/details',
  },
  {
    id: 'rowsCount',
    title: 'Rows',
    sorting: true,
    type: TableHeaderColumnTypes.numeric,
    width: '70px',
  },
  {
    id: 'createdAt',
    title: 'Upload date',
    type: TableHeaderColumnTypes.date,
    sorting: true,
    width: '160px',
  },
];

import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { CAMPUS_DETAILS_CONFIG, CampusDetailsRouteParams } from './CampusProfile.constants';
import { editCampusDetailsAction, loadCampusAction } from '../campus-profile.actions';
import { CampusDTO, EditCampusDTO } from '../campus-profile.dto';
import { selectCurrentCampus } from '../campus-profile.selectors';
import { EDIT_CAMPUS_DETAILS, LOAD_CAMPUS } from '../campus-profile.types';
import { LOAD_SCHOOL } from '../../SchoolDetails';
import { SCHOOL_PROFILE_MAIN_ROUTE } from '../../SchoolProfile.constants';
import EditableGroup from '../../../../../components/EditableGroup';
import { FormConfig, FormDataValues } from '../../../../../components/Form';
import Icon from '../../../../../components/Icon';
import Label, { LabelStatus } from '../../../../../components/Label';
import PageLoader from '../../../../../components/PageLoader';
import {
  selectAreRequestsLoading,
  selectRequestErrors,
  setRequestSucceededAction,
} from '../../../../../shared/state/global-request';
import { loadStatusesAction, selectStatuses } from '../../../../../shared/state/statuses';
import NotFound from '../../../../../components/NotFound';

const CampusProfile: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id: schoolId, campusId } = useParams<CampusDetailsRouteParams>();
  const campusStatuses = useSelector(selectStatuses('campus'));
  const currentCampus = useSelector(selectCurrentCampus) as CampusDTO;
  const isLoading = useSelector(selectAreRequestsLoading([LOAD_CAMPUS, LOAD_SCHOOL]));
  const formErrors = useSelector(selectRequestErrors(EDIT_CAMPUS_DETAILS));
  const { id, name, status } = currentCampus;

  useEffect(() => {
    if (schoolId && campusId) {
      dispatch(loadCampusAction(parseInt(schoolId), parseInt(campusId)));
      dispatch(loadStatusesAction('campus'));
    }
  }, [campusId, dispatch, schoolId]);

  const campusDetailsFormConfig: FormConfig = {
    ...CAMPUS_DETAILS_CONFIG,
    status: {
      ...CAMPUS_DETAILS_CONFIG.status,
      options: campusStatuses.map(({ key, label }) => ({ label, value: key })),
    },
  };

  const resetFormState = (): void => {
    dispatch(setRequestSucceededAction(EDIT_CAMPUS_DETAILS));
  };

  const updateRankingCategory = (data: FormDataValues): void => {
    dispatch(editCampusDetailsAction(parseInt(schoolId), { ...data, id } as EditCampusDTO));
  };

  return (
    <>
      {id ? (
        <EditableGroup<CampusDTO>
          columnSize={5}
          data={currentCampus}
          editButtonLabel="Edit details"
          formConfig={campusDetailsFormConfig}
          formErrors={formErrors}
          headingType="back"
          heading={
            <div>
              <Link
                className="o-layout__unstyled-element mba-back-arrow"
                title="Go back to the school profile"
                to={SCHOOL_PROFILE_MAIN_ROUTE.replace(':id', schoolId.toString())}
              >
                <Icon name="arrow-left" size={30} />
              </Link>
              <span className="mba-center--vertically">
                {name}
                &nbsp;
                {status && (
                  <Label color={status.color} status={LabelStatus.active}>
                    {status.label}
                  </Label>
                )}
              </span>
            </div>
          }
          onEditSubmit={updateRankingCategory}
          onEditCancel={resetFormState}
        />
      ) : isLoading || !campusId ? (
        <PageLoader />
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default CampusProfile;

import { PageConfiguration } from '../containers/App/Main/components/Layout';

export const JWT_TOKEN_COOKIE_NAME = `ADMIN_${process.env.REACT_APP_JWT_COOKIE_NAME}` || '';

export const ASCENDING_ORDER_DIRECTION = 'ASC';

export const DESCENDING_ORDER_DIRECTION = 'DESC';

export const LOGIN_PATH = '/auth/login';

export type OrderDirection = 'ASC' | 'DESC';

export interface BasicResponseDTO {
  message: string;
}

export interface DefaultResponseDTO<T> {
  message: string;
  data: T;
}

export interface Resource {
  key: number;
  label: string;
  shortLabel?: string;
}

export interface Status extends Resource {
  color?: string;
}

export interface Country extends Resource {
  id: number;
}

export interface Pagination {
  page: number;
  limit: number;
  totalCount: number;
  totalPages: number;
}

export interface SortOrder {
  order: OrderDirection;
  sortBy: string;
}

export interface PagedResponseDTO<T> extends DefaultResponseDTO<T> {
  pagination: Pagination;
  sortOrder: SortOrder;
}

export interface TableListData<T> {
  data: T[];
  errorMessage?: string;
  filter?: PageFilterConfig;
  pagination: Pagination;
  search?: string;
  sortOrder: SortOrder;
}

export type PageProps = PageConfiguration;

export interface PageFilterConfig {
  [key: string]: string[];
}

export interface PageSearchQueryConfig {
  filter?: PageFilterConfig;
  limit?: number;
  order?: OrderDirection;
  page?: number;
  sortBy?: string;
  totalPages?: number;
  search?: string;
  roles?: string[];
  statuses?: number[];
}

export const DEFAULT_TIMEZONE = 'Europe/London';

export const GMT_TIMEZONE = 'Etc/GMT';

export const DEFAULT_DATE_FORMAT = 'MMM DD YYYY';

export const SEARCH_DEBOUNCE_TIME = 500;

export const FORBIDDEN_PATH = '/forbidden';

export const SERVER_UNAVAILABLE_PATH = '/server-unavailable';

export const DATETIME_FORMAT_REGEX =
  /(19|20)[0-9][0-9]-(0[0-9]|1[0-2])-(0[1-9]|([12][0-9]|3[01]))T([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]/;

export const DATE_FORMAT_REGEX = /(0[1-9]|([12][0-9]|3[01])\/(0[0-9]|1[0-2])\/(19|20)[0-9][0-9])/;

export const THIRTY_DAYS_IN_MILLISECONDS = 2592000000;

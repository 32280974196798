import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button, { ButtonIcons, ButtonSizes } from '../../../components/Button';
import Modal from '../../../components/Modal';
import TabsNavigation from '../../../components/TabsNavigation';
import Typography, { TypographyVariants } from '../../../components/Typography';
import { selectRequestStatus } from '../../../shared/state/global-request';
import { PageRouter } from '../../App/Main/components/Layout';
import Active from './Active';
import CreateMessageForm from './CreateMessageForm';
import ErrorMessage from './ErrorMessage';
import History from './History';
import { ADD_MESSAGE } from './onsite-messaging.types';
import { ONSITE_MESSAGING_MAIN_ROUTE, ONSITE_MESSAGING_SUB_NAVIGATION } from './OnsiteMessaging.constants';

const componentsMap = {
  active: Active,
  history: History,
};

const OnsiteMessaging: FunctionComponent = () => {
  const tabsNavigation = Object.values(ONSITE_MESSAGING_SUB_NAVIGATION).map(({ label, route }) => ({
    label,
    route: `${ONSITE_MESSAGING_MAIN_ROUTE}${route}`,
  }));
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const addMessageError = useSelector(selectRequestStatus(ADD_MESSAGE));

  useEffect(() => {
    setIsErrorModalOpen(addMessageError.errorMessage === 'The message overlaps another existing message');
  }, [addMessageError]);

  const openMessageModal = (): void => {
    setIsMessageModalOpen(true);
  };

  const closeMessageModal = (): void => {
    setIsMessageModalOpen(false);
  };

  const closeErrorMessageModal = (): void => {
    setIsErrorModalOpen(false);
  };

  return (
    <>
      <div className="mba-heading--wrapper">
        <Typography component="h1" variant={TypographyVariants.h1}>
          Onsite messaging
        </Typography>
        <Button
          text="Create new message"
          size={ButtonSizes.big}
          icon={ButtonIcons.plus}
          disabled={isMessageModalOpen}
          primary
          onClick={openMessageModal}
        />
      </div>
      <TabsNavigation tabs={tabsNavigation} big />
      <PageRouter
        mainRoute={ONSITE_MESSAGING_MAIN_ROUTE}
        navigation={ONSITE_MESSAGING_SUB_NAVIGATION}
        componentsMap={componentsMap}
      />
      <Modal title="Create new message" isOpen={isMessageModalOpen} onClose={closeMessageModal}>
        <CreateMessageForm onFormClose={closeMessageModal} />
      </Modal>
      <Modal title="Create new message" isOpen={isErrorModalOpen} onClose={closeErrorMessageModal}>
        <ErrorMessage closeErrorMessageModal={closeErrorMessageModal} />
      </Modal>
    </>
  );
};

export default OnsiteMessaging;

import React, { FunctionComponent } from 'react';

import { FormControlElementProps } from '../Form.constants';
import Typography, { TypographyVariants } from '../../Typography';

type FormGroupTitleProps = {
  control: FormControlElementProps;
};

const FormGroupTitle: FunctionComponent<FormGroupTitleProps> = ({ control }) => {
  const { id, error, fieldType, label, touched, valid } = control;
  const classes = ['form-group-title'];

  if (error && touched) {
    classes.push('o-forms-input--invalid');
  } else if (touched && valid) {
    classes.push('o-forms-input--valid');
  }

  return (
    <div key={`${fieldType}-${id}`} className={classes.join(' ')}>
      <Typography component="span" variant={TypographyVariants.h5}>
        {label}
      </Typography>
      <hr />
      <Typography component="p">
        <span className="o-forms-input__error">{touched ? error : ''}</span>
      </Typography>
    </div>
  );
};

export default FormGroupTitle;

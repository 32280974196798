import React, { FunctionComponent } from 'react';

import {
  CATEGORIZATION_DETAILS_ROUTES,
  CATEGORIZATION_NAVIGATION,
  RANKINGS_CATEGORIZATION_MAIN_ROUTE,
} from './Categorization.constants';
import RankingCategoryProfile from './RankingCategoryProfile/components';
import RankingCategoriesList from './RankingCategoriesList/components';
import RankingTypeProfile from './RankingTypeProfile/components';
import RankingTypesList from './RankingTypesList/components';
import { PageRouter } from '../../App/Main/components/Layout';
import TabsNavigation from '../../../components/TabsNavigation';
import Typography, { TypographyVariants } from '../../../components/Typography';

const componentsMap = {
  rankingCategories: RankingCategoriesList,
  rankingCategoryDetails: RankingCategoryProfile,
  rankingTypes: RankingTypesList,
  rankingTypeDetails: RankingTypeProfile,
};
const Categorization: FunctionComponent = () => {
  const tabsNavigation = Object.values(CATEGORIZATION_NAVIGATION).map(({ label, route }) => ({
    label,
    route: `${RANKINGS_CATEGORIZATION_MAIN_ROUTE}${route}`,
  }));

  return (
    <>
      <Typography component="h1" variant={TypographyVariants.h1}>
        Categorization
      </Typography>
      <TabsNavigation tabs={tabsNavigation} big />
      <PageRouter
        mainRoute={RANKINGS_CATEGORIZATION_MAIN_ROUTE}
        navigation={{
          ...CATEGORIZATION_NAVIGATION,
          ...CATEGORIZATION_DETAILS_ROUTES,
        }}
        componentsMap={componentsMap}
      />
    </>
  );
};

export default Categorization;

import { FormConfig, FormControlFieldType } from '../../../../../../../../components/Form';
import { GROUP_OPTIONS } from '../../../RankingData.constants';
import { RankingFieldType } from '../../ranking-fields-list.types';

export const FIELD_FORM_CONFIG = (type: RankingFieldType): FormConfig => ({
  type: {
    fieldType: 'radio' as FormControlFieldType,
    inline: true,
    options: [
      { value: 'Column', label: 'Column' },
      { value: 'Filter', label: 'Filter' },
    ],
    value: 'Column',
  },
  name: {
    label: 'Name',
    placeholder: `Enter ${type} name`,
    fieldType: 'text' as FormControlFieldType,
    validations: {
      required: {
        errorText: `Please enter ${type}'s name!`,
      },
    },
  },
  shortName: {
    label: 'Short name',
    placeholder: `Enter ${type} name`,
    fieldType: 'text' as FormControlFieldType,
  },
  group: {
    label: 'Group',
    fieldType: 'select' as FormControlFieldType,
    options: GROUP_OPTIONS(type),
    // value: undefined,
    validations: {
      required: {
        errorText: `Please select group`,
      },
    },
  },
  visibility: {
    label: 'Visibility',
    fieldType: 'select' as FormControlFieldType,
    options: [
      { value: 'Default', label: 'Default' },
      { value: 'Featured', label: 'Featured' },
      { value: 'Not displayed', label: 'Not displayed' },
    ],
    value: 'default',
  },
  description: {
    fieldType: 'textarea',
    label: 'Description',
    placeholder: 'Enter description',
    rows: 6,
  },
});

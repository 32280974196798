import React from 'react';

interface CustomIconModel {
  width?: string;
  height?: string;
  fill?: string;
}

export const IconDelete = ({ width, height, fill }: CustomIconModel): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M13.4224 15.2329L15.2329 13.4224L9.80127 7.99072L15.2329 2.55908L13.4224 0.748535L7.99072 6.18018L2.55908 0.748535L0.748535 2.55908L6.18018 7.99072L0.748535 13.4224L2.55908 15.2329L7.99072 9.80127L13.4224 15.2329Z"
        fill={fill || '#990F3D'}
      />
    </svg>
  );
};

export const IconEdit = ({ width, height, fill }: CustomIconModel): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 14L2 11.75L13.25 0.5L15.5 2.75L4.25 14ZM3.5 14.75L1.25 12.5L0.5 15.5L3.5 14.75Z"
        fill={fill || '#00757F'}
      />
    </svg>
  );
};

export const IconSave = ({ width, height, fill }: CustomIconModel): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '13'}
      height={height || '11'}
      viewBox="0 0 13 11"
      fill="none"
    >
      <path
        d="M12.5469 2.27344L11.043 0.769531L4.61719 7.19287L1.94141 4.51953L0.4375 6.02344L4.61719 10.2031L12.5469 2.27344Z"
        fill={fill || '#00757F'}
      />
    </svg>
  );
};

export const IconArrowDown = ({ width, height, fill }: CustomIconModel): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '13'}
      height={height || '11'}
      viewBox="262 412 500 200"
      fill="none"
    >
      <path d="M759.2 419.8L697.4 358 512 543.4 326.6 358l-61.8 61.8L512 667z" fill={fill || '#00757F'} />
    </svg>
  );
};

export const IconArrowUp = ({ width, height, fill }: CustomIconModel): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '13'}
      height={height || '11'}
      viewBox="262 412 500 200"
      fill="none"
    >
      <path d="M264.8 604.7l61.8 61.8L512 481.1l185.4 185.4 61.8-61.8L512 357.5z" fill={fill || '#00757F'} />
    </svg>
  );
};

export const IconInsertImage = ({ width, height, fill }: CustomIconModel): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill={fill || 'none'}
      stroke="#000000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect x="3" y="3" width="18" height="18" rx="2" />
      <circle cx="8.5" cy="8.5" r="1.5" />
      <path d="M20.4 14.5L16 10 4 20" />
    </svg>
  );
};

import { FormControl } from '../../../../../../components/Form';

export interface PartnershipFormData {
  schoolId: number;
}

export const SCHOOL_ID_FIELD_CONFIG: FormControl = {
  label: 'School to add',
  placeholder: 'Select a school',
  fieldType: 'select',
  searchable: true,
  validations: {
    required: {
      errorText: 'Please select a school',
    },
  },
  options: [],
};

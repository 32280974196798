import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

import { SchoolWidgetGroupDto } from '../../../containers/Commercial/SchoolWidgetGroups/school-widget-groups.dto';
import { DefaultResponseDTO, Resource } from '../../constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../global-request';
import { setSchoolsDropdownAction, setSingleSchoolWidgetGroupsAction } from './school-resources.actions';
import { getSchoolsDropdownRequest, getSingleSchoolWidgetGroupRequest } from './school-resources.api';
import {
  LOAD_SCHOOL_DROPDOWN,
  LOAD_SINGLE_SCHOOL_WIDGET_GROUPS,
  LoadSchoolsDropdownAction,
  LoadSingleSchoolWidgetGroupsAction,
  SchoolResourcesActionTypes,
} from './school-resources.types';

function* loadSchoolsDropdownData(
  action: LoadSchoolsDropdownAction,
): Generator<
  CallEffect<DefaultResponseDTO<Resource[]>> | PutEffect<SchoolResourcesActionTypes> | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getSchoolsDropdownRequest)) as DefaultResponseDTO<Resource[]>;
    yield put(setSchoolsDropdownAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* loadSingleSchoolWidgetGroupsData(
  action: LoadSingleSchoolWidgetGroupsAction,
): Generator<
  | CallEffect<DefaultResponseDTO<SchoolWidgetGroupDto[]>>
  | PutEffect<SchoolResourcesActionTypes>
  | PutEffect<GlobalRequestActions>
> {
  const {
    type: actionType,
    payload: { schoolId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getSingleSchoolWidgetGroupRequest, schoolId)) as DefaultResponseDTO<
      SchoolWidgetGroupDto[]
    >;
    yield put(setSingleSchoolWidgetGroupsAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* schoolsDropdownSaga(): Generator {
  yield takeLatest(LOAD_SCHOOL_DROPDOWN, loadSchoolsDropdownData);
  yield takeLatest(LOAD_SINGLE_SCHOOL_WIDGET_GROUPS, loadSingleSchoolWidgetGroupsData);
}

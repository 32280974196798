import React, { FunctionComponent } from 'react';
import InputWrapper from '../../InputWrapper';

import { FormControlElementProps } from '../Form.constants';

type TextInputFieldProps = {
  control: FormControlElementProps;
};

const StaticLinkField: FunctionComponent<TextInputFieldProps> = ({ control }) => {
  const { id, fieldType, label, value } = control;

  return (
    <div key={`${fieldType}-${id}`}>
      <InputWrapper title={label}>
        <br />
        <div dangerouslySetInnerHTML={{ __html: (value || '').toString() }} />
      </InputWrapper>
    </div>
  );
};

export default StaticLinkField;

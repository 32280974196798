import React, { FunctionComponent } from 'react';
import { Switch, useLocation } from 'react-router';
import { Route } from 'react-router-dom';

import TemplateFieldList from './components/TemplateFieldList';
import { SPREADSHEET_TEMPLATE_MAIN_ROUTE, SPREADSHEET_TEMPLATE_SUB_NAVIGATION } from './spreadsheet-template.constants';
import { PageRouter } from '../../App/Main/components/Layout';
import LayoutNavigation from '../../../components/LayoutNavigation';
import NotFound from '../../../components/NotFound';
import Typography, { TypographyVariants } from '../../../components/Typography';

import styles from './SpreadsheetTemplate.module.scss';

const componentsMap = {
  alumni: TemplateFieldList,
  faculty: TemplateFieldList,
  research: TemplateFieldList,
  embargo: TemplateFieldList,
  'custom-executive-education': TemplateFieldList,
  'open-executive-education': TemplateFieldList,
};

const SpreadsheetTemplate: FunctionComponent = () => {
  const { pathname } = useLocation();

  const navItems = Object.values(SPREADSHEET_TEMPLATE_SUB_NAVIGATION).map(({ label, route }) => ({
    label,
    route: `${SPREADSHEET_TEMPLATE_MAIN_ROUTE}${route}`,
  }));

  return (
    <>
      <div className="mba-heading--wrapper">
        <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title">
          Spreadsheet Templates
        </Typography>
      </div>
      <div className={styles.sideBar}>
        <LayoutNavigation items={navItems} selectedItem={pathname} />
      </div>
      <div className={styles.mainContent}>
        <Switch>
          <PageRouter
            mainRoute={SPREADSHEET_TEMPLATE_MAIN_ROUTE}
            navigation={SPREADSHEET_TEMPLATE_SUB_NAVIGATION}
            componentsMap={componentsMap}
          />
          <Route path="*" component={NotFound} />
        </Switch>
      </div>
    </>
  );
};

export default SpreadsheetTemplate;

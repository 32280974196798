import { del, get, post, put } from '../../../shared/api';
import { DefaultResponseDTO, PagedResponseDTO, PageSearchQueryConfig } from '../../../shared/constants';
import { generateSearchQuery } from '../../../shared/helpers';
import {
  CreateSchoolWidgetGroupsDto,
  UpdateWidgetGroupDto,
} from './components/CreateWidgetGroupModal/CreateWidgetGroupModal.constants';
import { SchoolWidgetGroupDto } from './school-widget-groups.dto';

export const createSchoolWidgetGroupRequest = (
  newWidgetGroup: CreateSchoolWidgetGroupsDto,
): Promise<DefaultResponseDTO<SchoolWidgetGroupDto>> => {
  return post<DefaultResponseDTO<SchoolWidgetGroupDto>, CreateSchoolWidgetGroupsDto>(
    `/school-ranking-widget-group`,
    newWidgetGroup,
  );
};

export const getSchoolWidgetGroupsRequest = (
  pageConfig: PageSearchQueryConfig = {},
): Promise<PagedResponseDTO<SchoolWidgetGroupDto[]>> => {
  const query = generateSearchQuery(pageConfig);
  return get<PagedResponseDTO<SchoolWidgetGroupDto[]>>(`/school-ranking-widget-group${query}`);
};

export const updateSchoolWidgetGroupRequest = (
  id: number,
  updateWidgetGroupDto: UpdateWidgetGroupDto,
): Promise<DefaultResponseDTO<undefined>> => {
  return put<DefaultResponseDTO<undefined>, UpdateWidgetGroupDto>(
    `/school-ranking-widget-group/${id}`,
    updateWidgetGroupDto,
  );
};

export const deleteSchoolWidgetGroupRequest = (id: number): Promise<DefaultResponseDTO<undefined>> => {
  return del<DefaultResponseDTO<undefined>>(`/school-ranking-widget-group/${id}`);
};

import { FiltersConfig } from '../../../../../../components/FilterGroup';

export const RANKINGS_TABLE_FILTERS: FiltersConfig = {
  year: {
    isDropdown: true,
    title: 'Year',
    options: [],
  },
  rankingTypeId: {
    isDropdown: true,
    title: 'Ranking',
    options: [],
  },
  status: {
    title: 'Status',
    options: [],
  },
};

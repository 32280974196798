import React, { FunctionComponent } from 'react';

import Button, { ButtonSizes } from '../../../../../../components/Button';
import Typography from '../../../../../../components/Typography';

type SchoolDeleteConfirmationProps = {
  error: string;
  id: number;
  name: string;
  onCancel: () => void;
  onConfirm: () => void;
};

const SchoolDeleteConfirmation: FunctionComponent<SchoolDeleteConfirmationProps> = ({
  error,
  id,
  name,
  onCancel,
  onConfirm,
}) => (
  <>
    <Typography>
      {error ? (
        <span className="mba-text--error">
          {error}
          <br />
        </span>
      ) : (
        <>
          Are you sure you want to delete:
          <br />
          <strong>
            &quot;{name} (SID: {id})&quot;
          </strong>
          <br />
          <br />
          All campuses and school users, including relations with accreditations, partnership schools and rankings will
          be deleted as well!
        </>
      )}
    </Typography>
    <div className="mba-actions">
      <Button onClick={onCancel} danger text="Cancel" size={ButtonSizes.big} />
      {!error && <Button onClick={onConfirm} primary danger text="Confirm" size={ButtonSizes.big} />}
    </div>
  </>
);

export default SchoolDeleteConfirmation;

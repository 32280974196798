import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PartnershipFormData, SCHOOL_ID_FIELD_CONFIG } from './PartnershipForm.constants';
import { addPartnershipAction } from '../../partnerships.actions';
import { ADD_PARTNERSHIP } from '../../partnerships.types';
import { SCHOOL_TYPES } from '../../../../SchoolsList/components/SchoolsList.constants';
import Form, { FormConfig } from '../../../../../../components/Form';
import { SelectOption } from '../../../../../../components/Select';
import {
  selectAreRequestsLoading,
  selectRequestErrorMessage,
  selectRequestFormErrors,
} from '../../../../../../shared/state/global-request';
import { selectResources } from '../../../../../../shared/state/resources';

export interface PartnershipFormProps {
  onFormClose: () => void;
  schoolId: number;
  type: SCHOOL_TYPES;
}

const PartnershipForm: FunctionComponent<PartnershipFormProps> = ({ onFormClose, schoolId, type }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectAreRequestsLoading([ADD_PARTNERSHIP]));
  const formErrors = useSelector(selectRequestFormErrors(ADD_PARTNERSHIP));
  const errorMessage = useSelector(selectRequestErrorMessage(ADD_PARTNERSHIP));
  const singleSchoolsResource = useSelector(selectResources(`schools/type-${SCHOOL_TYPES.SINGLE}`));
  const joinedSchoolsResource = useSelector(selectResources(`schools/type-${SCHOOL_TYPES.JOINED}`));

  let schoolsOptions: SelectOption[] = [];
  if (type) {
    if (type === SCHOOL_TYPES.SINGLE) {
      schoolsOptions = joinedSchoolsResource.map(({ key, label }) => ({ label, value: key.toString() }));
    } else {
      schoolsOptions = singleSchoolsResource.map(({ key, label }) => ({ label, value: key.toString() }));
    }
  }

  const defaultFormConfig: FormConfig = {
    schoolId: {
      ...SCHOOL_ID_FIELD_CONFIG,
      options: schoolsOptions,
    },
  };

  const handleSaveSchool = (data: PartnershipFormData): void => {
    const { schoolId: schoolToAdd } = data;
    let mainSchoolId = schoolToAdd;
    let joinedSchoolId = schoolId;

    if (type === SCHOOL_TYPES.SINGLE) {
      mainSchoolId = schoolId;
      joinedSchoolId = schoolToAdd;
    }

    dispatch(addPartnershipAction(mainSchoolId, joinedSchoolId, schoolId, type));
  };

  return (
    <Form
      id="add-to-partnership-form"
      config={defaultFormConfig}
      onSubmit={handleSaveSchool}
      submitButtonText="Save"
      isLoading={isLoading}
      actions={[
        {
          label: 'Cancel',
          onClick: onFormClose,
        },
      ]}
      initialValues={{}}
      errors={{
        schoolId:
          Object.keys(formErrors).length === 0 && errorMessage ? [errorMessage] : formErrors?.joinedSchoolId || [],
      }}
    />
  );
};

export default PartnershipForm;

import { ColumnDefinition, TableHeaderColumnTypes } from '../../../../../../../../components/Table';

export const CREATE_RANKING_FIELD = 'CREATE_RANKING_FIELD';
export const EDIT_RANKING_FIELD = 'EDIT_RANKING_FIELD';
export const DELETE_RANKING_FIELD = 'DELETE_RANKING_FIELD';
export const SET_RANKING_FIELD = 'SET_RANKING_FIELD';

export const RANKING_DATA_TABLE_COLUMN_DEFINITION: ColumnDefinition[] = [
  {
    id: 'name',
    title: 'Name',
    type: TableHeaderColumnTypes.link,
    path: '/rankings/profile/:rankingId/ranking-data/:id',
    sorting: true,
    width: '160px',
  },
  {
    id: 'shortName',
    title: 'Short name',
    sorting: true,
    width: '160px',
  },
  {
    id: 'type',
    sorting: true,
    title: 'Type',
    width: '160px',
  },
  {
    id: 'group',
    sorting: true,
    title: 'Group',
    width: '160px',
  },
  {
    id: 'visibility',
    sorting: true,
    title: 'Visibility',
    width: '160px',
  },
];

import {
  EditRankingTimelineStepDTO,
  RankingSpreadsheetDTO,
  RankingTimelineStepDTO,
  TimelineAttachmentDTO,
  TimelineAttachmentUploadDTO,
  TimelineSpreadsheetDTO,
} from './ranking-timeline-steps.dto';
import {
  CLEAR_CURRENT_RANKING_TIMELINE_STEPS,
  ClearCurrentRankingTimelineStepsAction,
  DOWNLOAD_TEMPLATE,
  DownloadSpreadsheetTemplateAction,
  UPLOAD_SPREADSHEET,
  UploadSpreadsheetAction,
  DOWNLOAD_SPREADSHEET,
  DownloadSpreadsheetAction,
  EDIT_RANKING_TIMELINE_STEP,
  EditRankingTimelineStepAction,
  LOAD_RANKING_TIMELINE_STEPS,
  LoadRankingTimelineStepsAction,
  SET_RANKING_TIMELINE_STEPS,
  SetRankingTimelineStepsAction,
  UPLOAD_FINAL_RANK_SPREADSHEET,
  UPLOAD_SURVEY_LINKS,
  UploadSurveyLinksAction,
  DELETE_STEP_ATTACHMENT,
  DeleteStepAttachmentAction,
  UPLOAD_STEP_ATTACHMENT,
  UploadStepAttachmentAction,
  DOWNLOAD_STEP_ATTACHMENT,
  DownloadStepAttachmentAction,
  CLEAR_CURRENT_RANKING_TIMELINE_STEP,
  SET_CURRENT_RANKING_TIMELINE_STEP,
  SetCurrentRankingTimelineStepAction,
  ClearCurrentRankingTimelineStepAction,
} from './ranking-timeline-steps.types';

export const loadRankingTimelineStepsAction = (rankingId: number): LoadRankingTimelineStepsAction => ({
  type: LOAD_RANKING_TIMELINE_STEPS,
  payload: { rankingId },
});

export const editRankingTimelineStepAction = (
  rankingId: number,
  data: EditRankingTimelineStepDTO,
): EditRankingTimelineStepAction => ({
  type: EDIT_RANKING_TIMELINE_STEP,
  payload: { data, rankingId },
});

export const setRankingTimelineStepsAction = (data: RankingTimelineStepDTO[]): SetRankingTimelineStepsAction => ({
  type: SET_RANKING_TIMELINE_STEPS,
  payload: { data },
});

export const clearCurrentRankingTimelineStepsAction = (): ClearCurrentRankingTimelineStepsAction => ({
  type: CLEAR_CURRENT_RANKING_TIMELINE_STEPS,
});

export const clearCurrentRankingTimelineStepAction = (): ClearCurrentRankingTimelineStepAction => ({
  type: CLEAR_CURRENT_RANKING_TIMELINE_STEP,
});

export const setCurrentRankingTimelineStepAction = (
  data: RankingTimelineStepDTO,
): SetCurrentRankingTimelineStepAction => ({
  type: SET_CURRENT_RANKING_TIMELINE_STEP,
  payload: { data },
});

export const downloadSpreadsheetTemplateAction = (
  rankingId: number,
  spreadsheetType: string,
): DownloadSpreadsheetTemplateAction => ({
  type: DOWNLOAD_TEMPLATE,
  payload: { rankingId, spreadsheetType },
});

export const uploadSpreadsheetAction = (
  data: RankingSpreadsheetDTO,
  type: typeof UPLOAD_SPREADSHEET | typeof UPLOAD_FINAL_RANK_SPREADSHEET = UPLOAD_SPREADSHEET,
): UploadSpreadsheetAction => ({
  type,
  payload: data,
});

export const uploadSurveyLinksAction = (
  data: RankingSpreadsheetDTO,
  type: typeof UPLOAD_SURVEY_LINKS = UPLOAD_SURVEY_LINKS,
): UploadSurveyLinksAction => ({
  type,
  payload: data,
});

export const downloadSpreadsheetAction = (data: TimelineSpreadsheetDTO): DownloadSpreadsheetAction => ({
  type: DOWNLOAD_SPREADSHEET,
  payload: data,
});

export const uploadAttachmentAction = (data: TimelineAttachmentUploadDTO): UploadStepAttachmentAction => ({
  type: UPLOAD_STEP_ATTACHMENT,
  payload: data,
});

export const downloadStepAttachmentAction = (data: TimelineAttachmentDTO): DownloadStepAttachmentAction => ({
  type: DOWNLOAD_STEP_ATTACHMENT,
  payload: data,
});

export const deleteStepAttachmentAction = (data: TimelineAttachmentDTO): DeleteStepAttachmentAction => ({
  type: DELETE_STEP_ATTACHMENT,
  payload: data,
});

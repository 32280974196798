import { call, CallEffect, put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects';

import {
  loadSpreadsheetTemplateFieldsAction,
  setSpreadsheetTemplateFieldsAction,
} from './spreadsheet-template.actions';
import {
  createSpreadsheetTemplateFieldRequest,
  deleteSpreadsheetTemplateFieldRequest,
  getSpreadsheetTemplateFieldsRequest,
  updateSpreadsheetTemplateFieldRequest,
} from './spreadsheet-template.api';
import { SpreadsheetTemplateFieldDTO } from './spreadsheet-template.dto';
import { selectSpreadsheetTemplateFieldsPageConfiguration } from './spreadsheet-template.selectors';
import {
  CREATE_SPREADSHEET_TEMPLATE_FIELD,
  CreateSpreadsheetTemplateFieldAction,
  DELETE_SPREADSHEET_TEMPLATE_FIELD,
  DeleteSpreadsheetTemplateFieldAction,
  LOAD_SPREADSHEET_TEMPLATE_FIELDS,
  LoadSpreadsheetTemplateFieldsAction,
  SpreadsheetTemplateFieldActionTypes,
  UPDATE_SPREADSHEET_TEMPLATE_FIELD,
  UpdateSpreadsheetTemplateFieldAction,
} from './spreadsheet-template.types';
import { BasicResponseDTO, PagedResponseDTO, PageSearchQueryConfig } from '../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../shared/state/global-request';

function* loadSpreadsheetTemplateFieldTableData(
  action: LoadSpreadsheetTemplateFieldsAction,
): Generator<
  | CallEffect<PagedResponseDTO<SpreadsheetTemplateFieldDTO[]>>
  | PutEffect<SpreadsheetTemplateFieldActionTypes>
  | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig, type } = action.payload;
    const { data, pagination, sortOrder } = (yield call(
      getSpreadsheetTemplateFieldsRequest,
      type,
      pageConfig,
    )) as PagedResponseDTO<SpreadsheetTemplateFieldDTO[]>;
    yield put(setSpreadsheetTemplateFieldsAction(type, { data, pagination, sortOrder }));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* createSpreadsheetTemplateField(
  action: CreateSpreadsheetTemplateFieldAction,
): Generator<
  | CallEffect<BasicResponseDTO>
  | PutEffect<SpreadsheetTemplateFieldActionTypes>
  | PutEffect<GlobalRequestActions>
  | SelectEffect
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data, type } = action.payload;
    yield call(createSpreadsheetTemplateFieldRequest, type, data);
    yield put(setRequestSucceededAction(actionType));
    const pageConfig = (yield select(selectSpreadsheetTemplateFieldsPageConfiguration(type))) as PageSearchQueryConfig;
    yield put(loadSpreadsheetTemplateFieldsAction(type, pageConfig));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* updateSpreadsheetTemplateField(
  action: UpdateSpreadsheetTemplateFieldAction,
): Generator<
  | CallEffect<BasicResponseDTO>
  | PutEffect<SpreadsheetTemplateFieldActionTypes>
  | PutEffect<GlobalRequestActions>
  | SelectEffect
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data, id, type } = action.payload;
    yield call(updateSpreadsheetTemplateFieldRequest, id, data);
    yield put(setRequestSucceededAction(actionType));
    const pageConfig = (yield select(selectSpreadsheetTemplateFieldsPageConfiguration(type))) as PageSearchQueryConfig;
    yield put(loadSpreadsheetTemplateFieldsAction(type, pageConfig));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* deleteSpreadsheetTemplateField(
  action: DeleteSpreadsheetTemplateFieldAction,
): Generator<
  | CallEffect<BasicResponseDTO>
  | PutEffect<SpreadsheetTemplateFieldActionTypes>
  | PutEffect<GlobalRequestActions>
  | SelectEffect
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { id, type } = action.payload;
    yield call(deleteSpreadsheetTemplateFieldRequest, id);
    yield put(setRequestSucceededAction(actionType));
    const pageConfig = (yield select(selectSpreadsheetTemplateFieldsPageConfiguration(type))) as PageSearchQueryConfig;
    yield put(loadSpreadsheetTemplateFieldsAction(type, pageConfig));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* rankingSpreadsheetsSaga(): Generator {
  yield takeLatest(LOAD_SPREADSHEET_TEMPLATE_FIELDS, loadSpreadsheetTemplateFieldTableData);
  yield takeLatest(DELETE_SPREADSHEET_TEMPLATE_FIELD, deleteSpreadsheetTemplateField);
  yield takeLatest(CREATE_SPREADSHEET_TEMPLATE_FIELD, createSpreadsheetTemplateField);
  yield takeLatest(UPDATE_SPREADSHEET_TEMPLATE_FIELD, updateSpreadsheetTemplateField);
}

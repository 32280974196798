import React, { FunctionComponent, useEffect, useState } from 'react';

import FilterInput, { FilterData } from './FilterInput';
import { DEFAULT_SHOW_ALL_GROUP_KEY, FiltersConfig } from './FilterGroup.constants';
import { PageFilterConfig } from '../../shared/constants';

type FilterGroupProps = {
  defaultFilters?: PageFilterConfig;
  filtersConfig: FiltersConfig;
  onFiltersChanged: (filters: PageFilterConfig) => void;
};

const FilterGroup: FunctionComponent<FilterGroupProps> = ({ defaultFilters = {}, filtersConfig, onFiltersChanged }) => {
  const [initialFilters, setInitialFilters] = useState({} as PageFilterConfig);

  useEffect(() => {
    setInitialFilters(defaultFilters);
  }, [defaultFilters]);

  const handleFilterChange = (data: FilterData): void => {
    let currentFilterGroup = [] as string[];
    const updatedFilter = { ...initialFilters };
    const { name, value } = data;

    if (value !== DEFAULT_SHOW_ALL_GROUP_KEY) {
      currentFilterGroup = initialFilters[name] || [];
      const currentValueIndex = currentFilterGroup.indexOf(value);

      if (currentValueIndex >= 0) {
        currentFilterGroup.splice(currentValueIndex, 1);
      } else {
        currentFilterGroup.push(value);
      }
    }

    onFiltersChanged({
      ...updatedFilter,
      [name]: [...currentFilterGroup],
    });
  };

  return (
    <>
      {Object.entries(filtersConfig).map(([key, { isDropdown, options, title, subTitle }]) => (
        <FilterInput
          key={key}
          name={key}
          id={`user-${key}-filter`}
          isDropdown={isDropdown}
          title={title}
          subTitle={subTitle}
          options={options}
          onFilterChanged={handleFilterChange}
        />
      ))}
    </>
  );
};

export default FilterGroup;

import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RANKING_CATEGORY_FORM_CONFIG } from '../RankingCategoriesList.constants';
import {
  CREATE_RANKING_CATEGORY,
  createNewRankingCategoryAction,
  CreateRankingCategoryDTO,
  EDIT_RANKING_CATEGORY,
  editRankingCategoryAction,
  EditRankingCategoryDTO,
  selectCurrentRankingCategory,
} from '../../../RankingCategoryProfile';
import Form from '../../../../../../components/Form';
import { selectAreRequestsLoading, selectRequestErrors } from '../../../../../../shared/state/global-request';

type RankingCategoryFormProps = {
  isEdit?: boolean;
  onFormClose: () => void;
};

const RankingCategoryForm: FunctionComponent<RankingCategoryFormProps> = ({ onFormClose, isEdit = false }) => {
  const dispatch = useDispatch();
  const currentRankingCategory = useSelector(selectCurrentRankingCategory);
  const formErrors = useSelector(selectRequestErrors(isEdit ? EDIT_RANKING_CATEGORY : CREATE_RANKING_CATEGORY));
  const isLoading = useSelector(selectAreRequestsLoading([CREATE_RANKING_CATEGORY, EDIT_RANKING_CATEGORY]));

  const onRankingCategorySaveSubmit = (data: CreateRankingCategoryDTO | EditRankingCategoryDTO): void => {
    if (isEdit) {
      const { id } = currentRankingCategory as EditRankingCategoryDTO;
      dispatch(editRankingCategoryAction({ ...data, id }));
    } else {
      dispatch(createNewRankingCategoryAction({ ...data } as CreateRankingCategoryDTO));
    }
  };

  return (
    <Form
      id="ranking-category-form"
      isLoading={isLoading}
      config={RANKING_CATEGORY_FORM_CONFIG}
      onSubmit={onRankingCategorySaveSubmit}
      submitButtonText={isEdit ? 'Save' : 'Create ranking category'}
      actions={[
        {
          label: 'Cancel',
          onClick: onFormClose,
        },
      ]}
      initialValues={currentRankingCategory}
      errors={formErrors}
    />
  );
};

export default RankingCategoryForm;

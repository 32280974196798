import { call, CallEffect, put, PutEffect, SelectEffect, takeEvery, takeLatest } from 'redux-saga/effects';

import { BasicResponseDTO, DefaultResponseDTO } from '../../../../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../../../../shared/state/global-request';
import { loadRankingTimelineStepsAction, setRankingTimelineStepsAction } from './ranking-timeline-steps.actions';
import {
  downloadSpreadsheetTemplateRequest,
  uploadSpreadsheetRequest,
  editRankingTimelineStepRequest,
  getRankingTimelineStepsRequest,
  downloadSpreadsheetRequest,
  uploadSurveyLinksRequest,
  uploadAttachmentRequest,
  deleteAttachmentRequest,
  downloadAttachmentRequest,
} from './ranking-timeline-steps.api';
import { RankingTimelineStepDTO, SaveRankingTimelineStepDTO } from './ranking-timeline-steps.dto';
import {
  CLEAR_CURRENT_RANKING_TIMELINE_STEPS,
  DOWNLOAD_TEMPLATE,
  DownloadSpreadsheetTemplateAction,
  UPLOAD_SPREADSHEET,
  UploadSpreadsheetAction,
  DOWNLOAD_SPREADSHEET,
  DownloadSpreadsheetAction,
  EDIT_RANKING_TIMELINE_STEP,
  EditRankingTimelineStepAction,
  LOAD_RANKING_TIMELINE_STEPS,
  LoadRankingTimelineStepsAction,
  RankingTimelineStepsActionTypes,
  UPLOAD_FINAL_RANK_SPREADSHEET,
  UPLOAD_SURVEY_LINKS,
  UploadSurveyLinksAction,
  DELETE_STEP_ATTACHMENT,
  DeleteStepAttachmentAction,
  UPLOAD_STEP_ATTACHMENT,
  UploadStepAttachmentAction,
  DOWNLOAD_STEP_ATTACHMENT,
  DownloadStepAttachmentAction,
} from './ranking-timeline-steps.types';
import moment from 'moment';
import { getParsedDate } from '../../../../../../shared/helpers';

function* loadRankingTimelineStepsSaga(
  action: LoadRankingTimelineStepsAction,
): Generator<
  | CallEffect<DefaultResponseDTO<RankingTimelineStepDTO[]>>
  | PutEffect<GlobalRequestActions | RankingTimelineStepsActionTypes>
  | SelectEffect
> {
  const {
    type: actionType,
    payload: { rankingId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getRankingTimelineStepsRequest, rankingId)) as DefaultResponseDTO<
      RankingTimelineStepDTO[]
    >;
    yield put(setRankingTimelineStepsAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* editRankingTimelineStepSaga(
  action: EditRankingTimelineStepAction,
): Generator<PutEffect<GlobalRequestActions | RankingTimelineStepsActionTypes> | CallEffect<BasicResponseDTO>> {
  const {
    type: actionType,
    payload: { data, rankingId },
  } = action;

  const { startDate: rawStartDate, endDate: rawEndDate, isOptional, ...stepData } = data;
  let rankingStepData: SaveRankingTimelineStepDTO = { ...stepData };

  if (typeof isOptional === 'boolean') {
    rankingStepData = {
      ...rankingStepData,
      isOptional,
    };
  }

  if (rawStartDate) {
    const startDate = getParsedDate(rawStartDate);
    const utcStartDate = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());

    rankingStepData = {
      ...rankingStepData,
      startDate: utcStartDate,
    };
  }

  if (rawEndDate) {
    const endDate = getParsedDate(rawEndDate);
    const utcEndDate = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 0);

    rankingStepData = {
      ...rankingStepData,
      endDate: utcEndDate,
    };
  }

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(editRankingTimelineStepRequest, rankingId, rankingStepData);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadRankingTimelineStepsAction(rankingId));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* downloadSpreadsheetTemplateSaga(
  action: DownloadSpreadsheetTemplateAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<GlobalRequestActions | RankingTimelineStepsActionTypes>> {
  const {
    type: actionType,
    payload: { rankingId, spreadsheetType },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(downloadSpreadsheetTemplateRequest, rankingId, spreadsheetType);
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* uploadSpreadsheetSaga(
  action: UploadSpreadsheetAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<GlobalRequestActions | RankingTimelineStepsActionTypes>> {
  const {
    type: actionType,
    payload: { rankingId, spreadsheet, type },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(uploadSpreadsheetRequest, rankingId, spreadsheet, type);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadRankingTimelineStepsAction(rankingId));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* downloadSpreadsheetSaga(
  action: DownloadSpreadsheetAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<GlobalRequestActions | RankingTimelineStepsActionTypes>> {
  const {
    type: actionType,
    payload: { rankingId, type },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(downloadSpreadsheetRequest, rankingId, type);
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* uploadSurveyLinksSaga(
  action: UploadSurveyLinksAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<GlobalRequestActions | RankingTimelineStepsActionTypes>> {
  const {
    type: actionType,
    payload: { rankingId, spreadsheet },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(uploadSurveyLinksRequest, rankingId, spreadsheet);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadRankingTimelineStepsAction(rankingId));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* resetRankingTimelineStepErrors(): Generator<PutEffect<GlobalRequestActions>> {
  yield put(setRequestSucceededAction(EDIT_RANKING_TIMELINE_STEP));
}

function* downloadStepAttachmentSaga(
  action: DownloadStepAttachmentAction,
): Generator<CallEffect<Blob> | PutEffect<GlobalRequestActions>> {
  const {
    type: actionType,
    payload: { rankingId, stepId, attachmentName },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const attachment = (yield call(downloadAttachmentRequest, rankingId, stepId)) as Blob;
    const url = window.URL.createObjectURL(attachment);

    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.target = '_blank';
    anchor.download = attachmentName || '';
    anchor.click();
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* uploadStepAttachmentSaga(
  action: UploadStepAttachmentAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<GlobalRequestActions | RankingTimelineStepsActionTypes>> {
  const {
    type: actionType,
    payload: { rankingId, spreadsheet, stepId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(uploadAttachmentRequest, rankingId, spreadsheet, stepId);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadRankingTimelineStepsAction(rankingId));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* deleteStepAttachmentSaga(
  action: DeleteStepAttachmentAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<GlobalRequestActions | RankingTimelineStepsActionTypes>> {
  const {
    type: actionType,
    payload: { stepId, rankingId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(deleteAttachmentRequest, rankingId, stepId);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadRankingTimelineStepsAction(rankingId));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* rankingTimelineStepsSaga(): Generator {
  yield takeLatest(LOAD_RANKING_TIMELINE_STEPS, loadRankingTimelineStepsSaga);
  yield takeLatest(EDIT_RANKING_TIMELINE_STEP, editRankingTimelineStepSaga);
  yield takeEvery(CLEAR_CURRENT_RANKING_TIMELINE_STEPS, resetRankingTimelineStepErrors);
  yield takeEvery(DOWNLOAD_TEMPLATE, downloadSpreadsheetTemplateSaga);
  yield takeEvery(UPLOAD_SPREADSHEET, uploadSpreadsheetSaga);
  yield takeEvery(UPLOAD_FINAL_RANK_SPREADSHEET, uploadSpreadsheetSaga);
  yield takeLatest(DOWNLOAD_SPREADSHEET, downloadSpreadsheetSaga);
  yield takeEvery(UPLOAD_SURVEY_LINKS, uploadSurveyLinksSaga);
  yield takeEvery(UPLOAD_STEP_ATTACHMENT, uploadStepAttachmentSaga);
  yield takeEvery(DOWNLOAD_STEP_ATTACHMENT, downloadStepAttachmentSaga);
  yield takeEvery(DELETE_STEP_ATTACHMENT, deleteStepAttachmentSaga);
}

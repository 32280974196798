import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import {
  AddFaqAction,
  ADD_FAQ,
  DeleteFaqAction,
  DELETE_FAQ,
  EditFaqAction,
  EDIT_FAQ,
  FaqDto,
  FrequentlyAskedQuestionsTypes,
  loadFaqAction,
  LoadFaqAction,
  LOAD_FAQ,
  setFaqAction,
} from '.';
import { BasicResponseDTO, DefaultResponseDTO } from '../../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../../shared/state/global-request';
import { addFAQRequest, deleteFAQRequest, getFAQsRequest, putFAQRequest } from './frequently-asked-questions.api';

function* loadFaqData(
  action: LoadFaqAction,
): Generator<
  CallEffect<DefaultResponseDTO<FaqDto[]>> | PutEffect<FrequentlyAskedQuestionsTypes> | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;
  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getFAQsRequest)) as DefaultResponseDTO<FaqDto[]>;

    yield put(setFaqAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* addFaqData(
  action: AddFaqAction,
): Generator<
  CallEffect<DefaultResponseDTO<FaqDto>> | PutEffect<FrequentlyAskedQuestionsTypes> | PutEffect<GlobalRequestActions>
> {
  const { type: actionType, payload } = action;
  try {
    yield put(setRequestStartedAction(actionType));
    yield call(addFAQRequest, payload.data);

    yield put(setRequestSucceededAction(actionType));
    yield put(loadFaqAction());
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* updateFaqData(
  action: EditFaqAction,
): Generator<
  CallEffect<DefaultResponseDTO<FaqDto>> | PutEffect<FrequentlyAskedQuestionsTypes> | PutEffect<GlobalRequestActions>
> {
  const { type: actionType, payload } = action;
  try {
    yield put(setRequestStartedAction(actionType));
    yield call(putFAQRequest, Number(payload.data.id), {
      categoryTitle: payload.data.categoryTitle,
      description: payload.data.description,
    });

    yield put(setRequestSucceededAction(actionType));
    yield put(loadFaqAction());
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* deleteFaq(
  action: DeleteFaqAction,
): Generator<
  CallEffect<BasicResponseDTO> | PutEffect<FrequentlyAskedQuestionsTypes> | PutEffect<GlobalRequestActions>
> {
  const { type: actionType, payload } = action;
  try {
    yield put(setRequestStartedAction(actionType));
    yield call(deleteFAQRequest, payload.id);

    yield put(setRequestSucceededAction(actionType));
    yield put(loadFaqAction());
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* faqSaga(): Generator {
  yield takeLatest(LOAD_FAQ, loadFaqData);
  yield takeLatest(ADD_FAQ, addFaqData);
  yield takeLatest(EDIT_FAQ, updateFaqData);
  yield takeLatest(DELETE_FAQ, deleteFaq);
}

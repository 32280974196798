import { SchoolWidgetGroupDto } from '../../../containers/Commercial/SchoolWidgetGroups/school-widget-groups.dto';
import { get } from '../../api';
import { DefaultResponseDTO, Resource } from '../../constants';

export const getSchoolsDropdownRequest = (): Promise<DefaultResponseDTO<Resource[]>> => {
  return get<DefaultResponseDTO<Resource[]>>(`/schools/dropdown`);
};

export const getSingleSchoolWidgetGroupRequest = (
  schoolId: number,
): Promise<DefaultResponseDTO<SchoolWidgetGroupDto[]>> => {
  return get<DefaultResponseDTO<SchoolWidgetGroupDto[]>>(`/school-ranking-widget-group/school/${schoolId}/groups`);
};

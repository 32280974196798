import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import TabsNavigation from '../../../../components/TabsNavigation';
import Typography, { TypographyVariants } from '../../../../components/Typography';
import { PageRouter } from '../../../App/Main/components/Layout';
import { SchoolLeads } from '../SchoolLeads';
import SchoolWithLeadDetails from '../SchoolWithLeadDetails/SchoolWithLeadDetails';
import { SCHOOLS_LEADS_MAIN_ROUTE, SCHOOLS_LEADS_TABS_NAVIGATION } from './SchoolLeadsProfile.constants';
import SchoolLeadsDetails from '../../SchoolLeadsDetails/components/SchoolLeadsDetails';
import { selectSchoolById } from '../../SchoolsWithLeadGen';
import NotFound from '../../../../components/NotFound';

const componentsMap = {
  schoolWithLeadDetails: SchoolWithLeadDetails,
  schoolLeads: SchoolLeads,
  schoolLeadsDetails: SchoolLeadsDetails,
};

const SchoolLeadsProfile: FunctionComponent = () => {
  const { schoolId } = useParams<{ schoolId: string }>();
  const schoolData = useSelector((state) => selectSchoolById(state as any, schoolId)) ?? null;
  const tabsNavigation = Object.values(SCHOOLS_LEADS_TABS_NAVIGATION).map(({ label, route }) => ({
    label,
    route: `${SCHOOLS_LEADS_MAIN_ROUTE.replace(':schoolId', schoolId)}${route}`,
  }));

  return (
    <>
      {schoolData ? (
        <div className="mba-heading-wrapper">
          <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title">
            {schoolData.schoolName}
          </Typography>
          <TabsNavigation tabs={tabsNavigation} big />
          <PageRouter
            mainRoute={SCHOOLS_LEADS_MAIN_ROUTE}
            navigation={{
              ...SCHOOLS_LEADS_TABS_NAVIGATION,
              schoolLeadsDetails: {
                label: '',
                route: '/leads/:leadId',
              },
            }}
            componentsMap={componentsMap}
          />
        </div>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default SchoolLeadsProfile;

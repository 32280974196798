import { TableListData } from '../../../shared/constants';
import { getTableListDefaultState } from '../../../shared/helpers';
import { SchoolsWithLeadGenDTO } from './schools-with-lead-gen.dto';
import { LeadGenTypes, LOAD_SCHOOLS_WITH_LEAD_GEN, SET_SCHOOLS_WITH_LEAD_GEN } from './schools-with-lead-gen.types';

const initialState: TableListData<SchoolsWithLeadGenDTO> = getTableListDefaultState<SchoolsWithLeadGenDTO>();

const schoolsWithleadGenReducer = (
  state = initialState,
  action: LeadGenTypes,
): TableListData<SchoolsWithLeadGenDTO> => {
  switch (action.type) {
    case LOAD_SCHOOLS_WITH_LEAD_GEN: {
      return {
        ...state,
        filter: action.payload.pageConfig?.filter || {},
        search: action.payload.pageConfig?.search || '',
      };
    }
    case SET_SCHOOLS_WITH_LEAD_GEN: {
      return {
        ...state,
        ...action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default schoolsWithleadGenReducer;

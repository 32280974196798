import { SCHOOL_TYPE_JOINED, SCHOOL_TYPE_SINGLE } from '../../../SchoolsList/components/SchoolsList.constants';
import { FormConfig } from '../../../../../components/Form';

export const SCHOOL_DETAILS_CONFIG: FormConfig = {
  name: {
    fieldType: 'text',
    placeholder: `Enter school's name`,
    required: true,
    title: 'Name',
    validations: {
      required: {
        errorText: `Please enter a name for the school!`,
      },
    },
  },
  email: {
    fieldType: 'email',
    title: 'E-mail',
    placeholder: `Enter school's main e-mail address`,
  },
  slug: {
    fieldType: 'text',
    placeholder: 'Enter slug',
    required: true,
    title: 'Slug',
    validations: {
      required: {
        errorText: `Please enter a valid slug!`,
      },
    },
  },
  phone: {
    fieldType: 'phone',
    title: 'Phone',
    placeholder: `Enter phone number`,
  },
  type: {
    disabled: false,
    fieldType: 'select',
    options: [
      { value: SCHOOL_TYPE_SINGLE, label: 'Single school' },
      { value: SCHOOL_TYPE_JOINED, label: 'Joint school' },
    ],
    required: true,
    title: 'School type',
  },
  website: {
    fieldType: 'text',
    title: 'Website',
    placeholder: `Enter school's website URL`,
  },
  status: {
    fieldType: 'select',
    placeholder: 'Choose a status',
    required: true,
    options: [],
    title: 'Status',
    validations: {
      required: {
        errorText: `Please choose a status!`,
      },
    },
  },
  address: {
    fieldType: 'textarea',
    title: 'Address',
    placeholder: `Enter school's main address`,
  },
};

import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import EditableGroup from '../../../../components/EditableGroup';
import Icon from '../../../../components/Icon';
import Label, { LabelStatus } from '../../../../components/Label';
import { Status } from '../../../../shared/constants';
import { loadSchoolSingleLead } from '../school-leads-details.actions';
import { SchoolLeadsUser } from '../school-leads-details.dto';
import { selectSchoolSingleLead } from '../school-leads-details.selectors';
import { convertToLeadDetails, getFieldsConfig } from './helpers';
import {
  SchoolLeadsDetailsDTO,
  SCHOOL_LEAD_DETAILS_CONFIG_FIRST,
  SCHOOL_LEAD_MAIN_ROUTE,
} from './SchoolLeadsDetails.constants';
import SchoolLeadsSingleView from './SchoolLeadsSingleView/SchoolLeadsSingleView';

const SchoolLeadsDetails: FunctionComponent = () => {
  const dispatch = useDispatch();
  const currentLead = useSelector(selectSchoolSingleLead);
  const { leadId, schoolId } = useParams<{ leadId: string; schoolId: string }>();

  useEffect(() => {
    dispatch(loadSchoolSingleLead(leadId));
  }, [dispatch, leadId]);

  const { status = {} as Status, user = {} as SchoolLeadsUser } = currentLead ?? {};

  return (
    <>
      <div className="mba-background--white">
        <EditableGroup<SchoolLeadsDetailsDTO>
          columnSize={4}
          data={convertToLeadDetails(currentLead ?? {})}
          editButtonLabel="Edit"
          formConfig={SCHOOL_LEAD_DETAILS_CONFIG_FIRST}
          headingType="back"
          heading={
            <div>
              <Link
                className="o-layout__unstyled-element mba-back-arrow"
                title="Go back to the school profile"
                to={SCHOOL_LEAD_MAIN_ROUTE.replace(':schoolId', schoolId.toString())}
              >
                <Icon name="arrow-left" size={30} />
              </Link>
              <span className="mba-center--vertically">
                {user?.firstName && `${user?.firstName} ${user?.lastName}`}
                &nbsp;
                {status.color && (
                  <Label color={status?.color} status={LabelStatus.active}>
                    {status?.label}
                  </Label>
                )}
              </span>
            </div>
          }
          isEditable={false}
        />
        <hr className="mba-separator" />
        <SchoolLeadsSingleView
          data={convertToLeadDetails(currentLead ?? {})}
          formConfig={getFieldsConfig(currentLead ?? {})}
        />
        <hr className="mba-separator" />
      </div>
    </>
  );
};

export default SchoolLeadsDetails;

import React, { FunctionComponent, useState, useEffect, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';

import { CheckboxInputOption } from '../../../../../components/Checkbox';
import DatePicker from '../../../../../components/DatePicker';
import FilterGroup, { FiltersConfig, mapInitialValuesToFiltersConfig } from '../../../../../components/FilterGroup';
import SearchInput from '../../../../../components/SearchInput';
import { PageFilterConfig, PageSearchQueryConfig, Resource } from '../../../../../shared/constants';
import { loadSchoolWidgetGroupsAction } from '../../school-widget-groups.actions';
import { defaultRankingOption, WIDGETS_TABLE_FILTERS } from './WidgetsFiltersAndSearch.constants';

type FiltersAndSearchProps = {
  pageConfig: PageSearchQueryConfig;
  filtersData: { rankingTypes: Resource[] };
};

const WidgetsFiltersAndSearch: FunctionComponent<FiltersAndSearchProps> = ({ pageConfig, filtersData }) => {
  const dispatch = useDispatch();

  const { filter, search } = pageConfig;
  const [widgetsFilters, setWidgetsFilters] = useState({} as FiltersConfig);

  useEffect(() => {
    const { rankingTypes } = filtersData;

    setWidgetsFilters(
      mapInitialValuesToFiltersConfig(
        WIDGETS_TABLE_FILTERS,
        {
          rankingType: rankingTypes,
          status: [
            { label: 'Active', shortLabel: 'active only', key: 1 },
            { label: 'Inactive', shortLabel: 'inactive only', key: 0 },
            { label: 'Expired', shortLabel: 'expired only', key: 2 },
          ],
        },
        filter,
        true,
        defaultRankingOption,
      ),
    );
  }, [filter, filtersData]);

  const handleSearchChange = (newPageConfig: PageSearchQueryConfig): void => {
    dispatch(loadSchoolWidgetGroupsAction(newPageConfig));
  };

  const handleFiltersChange = (newFilters: PageFilterConfig): void => {
    let processingFilters = newFilters;
    if (filter && filter.rankingType?.length === 0 && newFilters.rankingType?.length === 0) {
      const options = widgetsFilters.rankingType.options.slice(1);
      processingFilters = {
        ...newFilters,
        rankingType: [...options.map((option: CheckboxInputOption) => option.name)],
      };
    }

    dispatch(
      loadSchoolWidgetGroupsAction({
        ...pageConfig,
        filter: processingFilters,
      }),
    );
  };

  const handleDateFiltersChange = (event: ChangeEvent<HTMLInputElement>): void => {
    let newFilter = { ...filter };
    const { name, value } = event.target;
    if (value) {
      newFilter = {
        ...filter,
        [name]: [value],
      };
    } else {
      delete newFilter[name];
    }
    dispatch(
      loadSchoolWidgetGroupsAction({
        ...pageConfig,
        filter: newFilter,
      }),
    );
  };

  return (
    <div className="sticky-sidebar-container mba-z-index-1">
      <SearchInput
        id="admin-widgets-search"
        pageConfig={pageConfig}
        placeholder="Search by name or email"
        onSearchChanged={handleSearchChange}
        searchTerm={search}
      />
      <FilterGroup
        defaultFilters={filter || {}}
        filtersConfig={widgetsFilters}
        onFiltersChanged={handleFiltersChange}
      />
      <div style={{ display: 'flex' }}>
        <DatePicker
          title="Active from"
          placeholder="DD/MM/YY"
          name="activeFromDate"
          onChange={handleDateFiltersChange}
        />
        <div style={{ width: 20 }} />
        <DatePicker title="Active to" placeholder="DD/MM/YY" name="activeToDate" onChange={handleDateFiltersChange} />
      </div>
    </div>
  );
};

export default WidgetsFiltersAndSearch;

import { call, CallEffect, put, PutEffect, takeEvery, takeLatest } from 'redux-saga/effects';

import { loadPartnerSchoolsAction, loadPartnershipsAction, setPartnershipsAction } from './partnerships.actions';
import {
  addSchoolToPartnershipRequest,
  getPartnerSchools,
  getPartnerships,
  removePartnershipRequest,
} from './partnerships.api';
import {
  ADD_PARTNERSHIP,
  AddPartnershipAction,
  CLEAR_CURRENT_PARTNERSHIP,
  LOAD_PARTNER_SCHOOLS,
  LOAD_PARTNERSHIPS,
  LoadPartnerSchoolsAction,
  LoadPartnershipsAction,
  PartnershipDTO,
  PartnershipsActionTypes,
  REMOVE_PARTNERSHIP,
  RemovePartnershipsAction,
} from './partnerships.types';
import { SCHOOL_TYPES } from '../../SchoolsList/components/SchoolsList.constants';
import { BasicResponseDTO, PagedResponseDTO } from '../../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../../shared/state/global-request';

function* loadPartnershipsTableData(
  action: LoadPartnershipsAction,
): Generator<
  CallEffect<PagedResponseDTO<PartnershipDTO[]>> | PutEffect<PartnershipsActionTypes> | PutEffect<GlobalRequestActions>
> {
  const {
    type: actionType,
    payload: { schoolId, pageConfig },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data, pagination, sortOrder } = (yield call(getPartnerships, schoolId, pageConfig)) as PagedResponseDTO<
      PartnershipDTO[]
    >;
    yield put(setPartnershipsAction({ data, pagination, sortOrder }));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* loadPartnerSchoolsTableData(
  action: LoadPartnerSchoolsAction,
): Generator<
  CallEffect<PagedResponseDTO<PartnershipDTO[]>> | PutEffect<PartnershipsActionTypes> | PutEffect<GlobalRequestActions>
> {
  const {
    type: actionType,
    payload: { schoolId, pageConfig },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getPartnerSchools, schoolId, pageConfig)) as PagedResponseDTO<PartnershipDTO[]>;
    yield put(
      setPartnershipsAction({
        data,
        pagination: {
          limit: 10,
          totalPages: 1,
          totalCount: 0,
          page: 1,
        },
        sortOrder: {
          sortBy: '',
          order: 'DESC',
        },
      }),
    );
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* removePartnership(
  action: RemovePartnershipsAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<PartnershipsActionTypes> | PutEffect<GlobalRequestActions>> {
  const {
    type: actionType,
    payload: { currentSchoolId, currentSchoolType, joinedSchoolId, schoolId },
  } = action;

  let currentPartnershipId = joinedSchoolId;
  let schoolToBeRemovedId = schoolId;

  if (currentSchoolType === SCHOOL_TYPES.JOINED) {
    currentPartnershipId = schoolId;
    schoolToBeRemovedId = joinedSchoolId;
  }

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(removePartnershipRequest, schoolToBeRemovedId, currentPartnershipId);
    yield put(setRequestSucceededAction(actionType));

    if (currentSchoolType === SCHOOL_TYPES.SINGLE) {
      yield put(loadPartnershipsAction(currentSchoolId));
    } else {
      yield put(loadPartnerSchoolsAction(currentSchoolId));
    }
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* addSchoolToPartnership(
  action: AddPartnershipAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<PartnershipsActionTypes> | PutEffect<GlobalRequestActions>> {
  const {
    type: actionType,
    payload: { currentSchoolId, currentSchoolType, joinedSchoolId, mainSchoolId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(addSchoolToPartnershipRequest, mainSchoolId, joinedSchoolId);
    yield put(setRequestSucceededAction(actionType));

    if (currentSchoolType === SCHOOL_TYPES.SINGLE) {
      yield put(loadPartnershipsAction(currentSchoolId));
    } else {
      yield put(loadPartnerSchoolsAction(currentSchoolId));
    }
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* resetPartnershipErrors(): Generator<PutEffect<GlobalRequestActions>> {
  yield put(setRequestSucceededAction(ADD_PARTNERSHIP));
  yield put(setRequestSucceededAction(REMOVE_PARTNERSHIP));
}

export default function* partnershipsSaga(): Generator {
  yield takeLatest(LOAD_PARTNERSHIPS, loadPartnershipsTableData);
  yield takeLatest(LOAD_PARTNER_SCHOOLS, loadPartnerSchoolsTableData);
  yield takeLatest(REMOVE_PARTNERSHIP, removePartnership);
  yield takeLatest(ADD_PARTNERSHIP, addSchoolToPartnership);
  yield takeEvery(CLEAR_CURRENT_PARTNERSHIP, resetPartnershipErrors);
}

import React, { FunctionComponent, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFaqAction, editFaqAction, EDIT_FAQ, FaqDto } from '../..';
import Button, { ButtonSizes } from '../../../../../../components/Button';
import Form, { FormDataValues } from '../../../../../../components/Form';
import { getHtmlFromJSON, getRawFromHTML } from '../../../../../../components/RichTextEditor/RichTextEditor.helpers';
import { selectRequestFormErrors, selectRequestIsLoading } from '../../../../../../shared/state/global-request';
import styles from '../FAQs.module.scss';
import { FAQ_FORM_CONFIG } from '../FAQs.constants';
import Modal from '../../../../../../components/Modal';
import FaqDeleteConfirmation from '../FaqDeleteConfirmation';

type FAQProps = {
  id: string;
  categoryTitle: string;
  description: string;
};

const FAQ: FunctionComponent<FAQProps> = ({ id, categoryTitle, description }) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const formErrors = useSelector(selectRequestFormErrors(EDIT_FAQ));
  const isLoading = useSelector(selectRequestIsLoading(EDIT_FAQ));
  const [isDeleteFaqModalOpen, setIsDeleteFaqModalOpen] = useState(false);

  const toggleEdit = (): void => {
    setIsEdit(true);
  };

  const resetFormState = useCallback((): void => {
    setIsEdit(false);
  }, []);

  const updateFaq = (data: FormDataValues): void => {
    dispatch(
      editFaqAction({
        id,
        categoryTitle: data.categoryTitle,
        description: data.description,
      } as FaqDto),
    );
    resetFormState();
  };

  const openDeleteFaqModal = (): void => {
    setIsDeleteFaqModalOpen(true);
  };

  const closeDeleteFaqModal = (): void => {
    setIsDeleteFaqModalOpen(false);
  };

  const deleteFaq = (): void => {
    dispatch(deleteFaqAction(Number(id)));
    resetFormState();
  };

  return (
    <>
      {isEdit ? (
        <>
          <div className={styles.editFaqContainer}>
            <Form
              id="edit-faq-form"
              isLoading={isLoading}
              config={FAQ_FORM_CONFIG}
              onSubmit={updateFaq}
              submitButtonText="Save changes"
              initialValues={{ categoryTitle, description: getRawFromHTML(description) }}
              actions={[
                {
                  label: 'Cancel',
                  onClick: resetFormState,
                },
              ]}
              errors={formErrors}
              actionsContainerWrapperClass={styles.formActionsContainer}
            />
            <div>
              <Button
                text="Delete"
                size={ButtonSizes.big}
                danger
                primary
                onClick={openDeleteFaqModal}
                wrapperClass={styles.deleteFaqButton}
              />
            </div>
          </div>
          <hr className="mba-separator" />
        </>
      ) : (
        <div className={styles.faq}>
          <div className={styles.faqHeadingContainer}>
            <p className={styles.faqTitle}>{categoryTitle}</p>
            <Button text="Edit" size={ButtonSizes.normal} primary onClick={toggleEdit} />
          </div>
          <div dangerouslySetInnerHTML={{ __html: getHtmlFromJSON(description) }} className={styles.faqDescripton} />
          <hr className="mba-separator" />
        </div>
      )}
      <Modal title="Notification" isOpen={isDeleteFaqModalOpen} onClose={closeDeleteFaqModal}>
        <FaqDeleteConfirmation id={Number(id)} onCancel={closeDeleteFaqModal} onConfirm={deleteFaq} />
      </Modal>
    </>
  );
};

export default FAQ;

import { SchoolUserDTO } from '../SchoolUserProfile';
import { PageSearchQueryConfig, TableListData } from '../../../shared/constants';

export const LOAD_SCHOOL_ACCOUNTS = 'LOAD_SCHOOL_ACCOUNTS';
export const SET_SCHOOL_ACCOUNTS = 'SET_SCHOOL_ACCOUNTS';
export const DOWNLOAD_SCHOOL_ACCOUNTS_SPREADSHEET = 'DOWNLOAD_SCHOOL_ACCOUNTS_SPREADSHEET';

export interface LoadSchoolAccountsAction {
  type: typeof LOAD_SCHOOL_ACCOUNTS;
  payload: { pageConfig?: PageSearchQueryConfig };
}

export interface SetSchoolAccountsAction {
  type: typeof SET_SCHOOL_ACCOUNTS;
  payload: { tableData: TableListData<SchoolUserDTO> };
}

export interface DownloadSchoolAccountsSpreadsheetAction {
  type: typeof DOWNLOAD_SCHOOL_ACCOUNTS_SPREADSHEET;
  payload: { pageConfig?: PageSearchQueryConfig };
}

export type SchoolAccountsActionTypes =
  | LoadSchoolAccountsAction
  | SetSchoolAccountsAction
  | DownloadSchoolAccountsSpreadsheetAction;

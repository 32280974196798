import { SpreadsheetTemplateFieldDTO } from './spreadsheet-template.dto';
import { PageSearchQueryConfig } from '../../../shared/constants';
import { RootState } from '../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const selectSpreadsheetTemplateFieldsTableData = (type: string) =>
  createDeepEqualSelector(
    (state: RootState) => state.rankings.spreadsheetTemplateField[type],
    (spreadsheetType) => {
      return spreadsheetType ? spreadsheetType.data : [];
    },
  );

export const selectSpreadsheetTemplateFieldsPageConfiguration = (type: string) =>
  createDeepEqualSelector(
    (state: RootState) => state.rankings.spreadsheetTemplateField[type],
    (spreadsheetType) => {
      const pageConfig = {
        filter: spreadsheetType?.filter,
        limit: spreadsheetType?.pagination?.limit,
        order: spreadsheetType?.sortOrder?.order,
        page: spreadsheetType?.pagination?.page,
        search: spreadsheetType?.search,
        sortBy: spreadsheetType?.sortOrder?.sortBy,
        totalPages: spreadsheetType?.pagination?.totalPages,
      };
      return spreadsheetType ? pageConfig : ({} as PageSearchQueryConfig);
    },
  );

export const selectCurrentSpreadsheetTemplateField =
  (type: string) =>
  (state: RootState): SpreadsheetTemplateFieldDTO | undefined => {
    const currentField = state.rankings.spreadsheetTemplateField[type].currentField;
    if (currentField) {
      return currentField;
    }

    return undefined;
  };

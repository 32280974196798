import { LOAD_RANKINGS, LoadRankingsAction, SET_RANKINGS, SetRankingsAction } from './rankings.types';
import { RankingDTO } from '../RankingProfile/RankingInfo';
import { PageSearchQueryConfig, TableListData } from '../../../../shared/constants';

export const loadRankingsAction = (pageConfig?: PageSearchQueryConfig): LoadRankingsAction => ({
  type: LOAD_RANKINGS,
  payload: { pageConfig },
});

export const setRankingsAction = (tableData: TableListData<RankingDTO>): SetRankingsAction => ({
  type: SET_RANKINGS,
  payload: { tableData },
});

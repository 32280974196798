import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';

import { DatePickerProps, DEFAULT_DATE_FORMAT } from './DatePicker.constants';
import InputWrapper, { InputWrapperProps } from '../InputWrapper';

import styles from './DatePicker.module.scss';

type Props = DatePickerProps & InputWrapperProps;

const DatePicker: FunctionComponent<Props> = ({
  disabled = false,
  error,
  id,
  name,
  onChange,
  onBlur,
  onFocus,
  optional,
  placeholder,
  required,
  shrunken,
  small,
  subTitle,
  title,
  touched,
  type,
  valid,
  value,
  verticallyCentered,
  customDateFormat,
  showTimeSelect = false,
  showTimeSelectOnly = false,
  timeIntervals = 30,
}) => {
  const [dateValue, setDateValue] = useState<Date | null>(moment().toDate());
  const dateInputClasses = [styles.inputElement];
  const inputClasses = ['o-forms-input', 'o-forms-input--text'];

  useEffect(() => {
    if (!value) {
      setDateValue(null);
    } else {
      const updatedDateValue = moment(value || '');

      if (updatedDateValue.isValid()) {
        setDateValue(updatedDateValue.toDate());
      }
    }
  }, [value]);

  if (error || (touched && !valid)) {
    inputClasses.push('o-forms-input--invalid');
  } else if (touched && valid) {
    inputClasses.push('o-forms-input--valid');
  }

  if (small) {
    inputClasses.push('o-forms-input--small');
  }

  if (error) {
    dateInputClasses.push(styles.error);
  }

  const handleDateChange = (value: Date): void => {
    setDateValue(value);
    let val: number | string = value?.getTime();

    if (type === 'month') {
      val = moment(value).format('yyyy-MM');
    }

    onChange && onChange({ target: { name, value: val } } as unknown as ChangeEvent<HTMLInputElement>);
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
    onFocus && onFocus(event);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    onBlur && onBlur(event);
  };

  return (
    <InputWrapper
      id={id}
      optional={optional}
      required={required}
      shrunken={shrunken}
      subTitle={subTitle}
      title={title}
      verticallyCentered={verticallyCentered}
    >
      <div className={inputClasses.join(' ')} data-testid="datepicker">
        <ReactDatePicker
          name={name}
          disabled={disabled}
          className={dateInputClasses.join(' ')}
          dateFormat={customDateFormat ? customDateFormat : DEFAULT_DATE_FORMAT[type || 'date']}
          selected={dateValue}
          onChange={handleDateChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          showMonthYearPicker={type === 'month'}
          placeholderText={placeholder}
          autoComplete="off"
          showTimeSelect={showTimeSelect}
          showTimeSelectOnly={showTimeSelectOnly}
          timeFormat={'HH:mm'}
          timeIntervals={timeIntervals}
        />
        {error && <span className="o-forms-input__error">{error}</span>}
      </div>
    </InputWrapper>
  );
};

export default DatePicker;

import React, { FunctionComponent, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFaqAction, AddFaqDto, ADD_FAQ } from '../..';
import Form, { FormDataValues } from '../../../../../../components/Form';
import {
  clearRequestFailedAction,
  selectRequestFormErrors,
  selectRequestIsLoading,
} from '../../../../../../shared/state/global-request';
import { FAQ_FORM_CONFIG } from '../FAQs.constants';

type Props = {
  onFormClose: () => void;
};

const AddFAQForm: FunctionComponent<Props> = ({ onFormClose }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectRequestIsLoading(ADD_FAQ));
  const formErrors = useSelector(selectRequestFormErrors(ADD_FAQ));
  const [formData, setFormData] = useState({} as FormDataValues);
  const errorCount = Object.keys(formErrors).length;

  const handleChange = (values: FormDataValues): void => {
    setFormData(values);
  };

  const onClose = useCallback((): void => {
    setFormData({} as FormDataValues);
    onFormClose();
    dispatch(clearRequestFailedAction(ADD_FAQ));
  }, [dispatch, onFormClose]);

  const addFAQ = (data: FormDataValues): void => {
    dispatch(
      addFaqAction({
        categoryTitle: data.categoryTitle,
        description: data.description,
      } as AddFaqDto),
    );
    if (!isLoading && errorCount === 0) {
      onClose();
    }
  };

  return (
    <div>
      <Form
        id="add-faq-form"
        isLoading={isLoading}
        config={FAQ_FORM_CONFIG}
        onValuesChanged={handleChange}
        onSubmit={addFAQ}
        submitButtonText="Save"
        actions={[
          {
            label: 'Cancel',
            onClick: onClose,
          },
        ]}
        initialValues={formData}
        errors={formErrors}
      />
    </div>
  );
};

export default AddFAQForm;

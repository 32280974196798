import React, { FunctionComponent } from 'react';

import Button, { ButtonSizes, ButtonTypes } from '../Button';
import Typography, { TypographyVariants } from '../Typography';

type UserDeleteConfirmationProps = {
  errorMessage?: string;
  isLoading?: boolean;
  onConfirmDelete: () => void;
  onRejectDelete: () => void;
  userName?: string;
};

const UserDeleteConfirmation: FunctionComponent<UserDeleteConfirmationProps> = ({
  errorMessage,
  isLoading = false,
  onConfirmDelete,
  onRejectDelete,
  userName,
}) => {
  let confirmButton = null;
  let content = (
    <span>
      Are you sure you want to delete <strong>{userName}</strong> from the list of users?
    </span>
  );

  if (errorMessage) {
    content = <span>{errorMessage}</span>;
  } else {
    confirmButton = (
      <Button
        text="Yes"
        isLoading={isLoading}
        size={ButtonSizes.big}
        primary
        type={ButtonTypes.submit}
        onClick={onConfirmDelete}
        disabled={isLoading}
      />
    );
  }

  return (
    <>
      <Typography component="p" variant={TypographyVariants.body}>
        {content}
      </Typography>
      <div className="mba-actions">
        <Button
          text={errorMessage ? 'Ok' : 'Cancel'}
          primary={!!errorMessage}
          size={ButtonSizes.big}
          onClick={onRejectDelete}
        />
        {confirmButton}
      </div>
    </>
  );
};

export default UserDeleteConfirmation;

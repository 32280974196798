import { PageSearchQueryConfig, TableListData } from '../../../shared/constants';

export const LOAD_LOGS = 'LOAD_LOGS';
export const SET_LOGS = 'SET_LOGS';

export interface LoadLogsAction {
  type: typeof LOAD_LOGS;
  payload: { pageConfig?: PageSearchQueryConfig };
}

export interface SetLogsAction {
  type: typeof SET_LOGS;
  payload: { tableData: TableListData<any> };
}

export type LoadLogsActionTypes = LoadLogsAction | SetLogsAction;

import { getTableListDefaultState } from '../../../../shared/helpers';
import { LOAD_PARTNERSHIPS, PartnershipsActionTypes, SET_PARTNERSHIPS, PartnershipDTO } from './partnerships.types';
import { TableListData } from '../../../../shared/constants';

const initialState: TableListData<PartnershipDTO> = getTableListDefaultState<PartnershipDTO>();

const partenershipReducer = (state = initialState, action: PartnershipsActionTypes): TableListData<PartnershipDTO> => {
  switch (action.type) {
    case LOAD_PARTNERSHIPS: {
      return {
        ...state,
        filter: action.payload.pageConfig?.filter || {},
        search: action.payload.pageConfig?.search || '',
      };
    }
    case SET_PARTNERSHIPS: {
      return {
        ...state,
        ...action.payload.tableData,
      };
    }
    default: {
      return state;
    }
  }
};

export default partenershipReducer;

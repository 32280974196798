import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

import { DefaultResponseDTO, Resource } from '../../constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../global-request';
import { setRankingTypesDropdownAction } from './ranking-types-dropdown.actions';
import { getRankingTypesDropdownRequest } from './ranking-types-dropdown.api';
import {
  LOAD_RANKING_TYPES_DROPDOWN,
  LoadRankingTypesDropdownAction,
  RankingTypesActionDropDownTypes,
} from './ranking-types-dropdown.types';

function* loadRankingTypesDropdownData(
  action: LoadRankingTypesDropdownAction,
): Generator<
  | CallEffect<DefaultResponseDTO<Resource[]>>
  | PutEffect<RankingTypesActionDropDownTypes>
  | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getRankingTypesDropdownRequest)) as DefaultResponseDTO<Resource[]>;
    yield put(setRankingTypesDropdownAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* rankingTypesDropdownSaga(): Generator {
  yield takeLatest(LOAD_RANKING_TYPES_DROPDOWN, loadRankingTypesDropdownData);
}

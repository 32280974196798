import { push, RouterAction } from 'react-router-redux';
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

import {
  RankingFieldDTO,
  LoadRankingFieldAction,
  RankingFieldProfileActionTypes,
  EditRankingFieldDetailsAction,
  LOAD_RANKING_FIELD,
  EDIT_RANKING_FIELD_DETAILS,
  getColumnRequest,
  updateColumnRequest,
  loadRankingFieldAction,
  setRankingFieldAction,
  DeleteRankingFieldAction,
  DELETE_RANKING_FIELD,
  deleteColumnRequest,
  CreateRankingFieldAction,
  createColumnRequest,
} from '.';
import { BasicResponseDTO, DefaultResponseDTO } from '../../../../../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../../../../../shared/state/global-request';
import { LoadRankingFieldsAction, loadRankingFieldsAction } from '../../RankingFieldsList';
import { CREATE_RANKING_FIELD, EDIT_RANKING_FIELD } from '../../RankingFieldsList/components/RankingFieldsList';

function* createRankingFieldSaga(
  action: CreateRankingFieldAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<GlobalRequestActions | LoadRankingFieldsAction>> {
  const {
    type: actionType,
    payload: { rankingId, data },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(createColumnRequest, rankingId, data);
    yield put(loadRankingFieldsAction(rankingId));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* editRankingFieldSaga(
  action: CreateRankingFieldAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<GlobalRequestActions | LoadRankingFieldsAction>> {
  const {
    type: actionType,
    payload: { rankingId, data },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateColumnRequest, rankingId, data);
    yield put(loadRankingFieldsAction(rankingId));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* loadRankingFieldSaga(
  action: LoadRankingFieldAction,
): Generator<
  CallEffect<DefaultResponseDTO<RankingFieldDTO>> | PutEffect<GlobalRequestActions | RankingFieldProfileActionTypes>
> {
  const {
    type: actionType,
    payload: { rankingId, rankingFieldId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getColumnRequest, rankingId, rankingFieldId)) as DefaultResponseDTO<RankingFieldDTO>;
    yield put(setRankingFieldAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* editRankingFieldDetailsSaga(
  action: EditRankingFieldDetailsAction,
): Generator<
  | PutEffect<GlobalRequestActions | RankingFieldProfileActionTypes | LoadRankingFieldAction>
  | CallEffect<BasicResponseDTO>
> {
  const {
    type: actionType,
    payload: { data, rankingId },
  } = action;
  const updatedData: RankingFieldDTO = { ...data };

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateColumnRequest, rankingId, updatedData);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadRankingFieldAction(rankingId, data.id as number));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* deleteColumnSaga(
  action: DeleteRankingFieldAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<GlobalRequestActions | RouterAction>> {
  const {
    type: actionType,
    payload: { rankingId, rankingFieldId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(deleteColumnRequest, rankingId, rankingFieldId);
    yield put(push(`/rankings/profile/${rankingId}/ranking-data`));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* rankingFieldProfileSaga(): Generator {
  yield takeLatest(CREATE_RANKING_FIELD, createRankingFieldSaga);
  yield takeLatest(EDIT_RANKING_FIELD, editRankingFieldSaga);
  yield takeLatest(LOAD_RANKING_FIELD, loadRankingFieldSaga);
  yield takeLatest(EDIT_RANKING_FIELD_DETAILS, editRankingFieldDetailsSaga);
  yield takeLatest(DELETE_RANKING_FIELD, deleteColumnSaga);
}

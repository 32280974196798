import { SchoolWidgetGroupDto } from '../SchoolWidgetGroups/school-widget-groups.dto';
import { NewWidgetDTO, WidgetDTO, WidgetOrderDTO } from './components/EditableWidget/EditableWidget.constants';
import {
  ADD_WIDGET_TO_LIST,
  AddWidgetToListAction,
  CREATE_SCHOOL_WIDGET,
  CreateSchoolWidgetAction,
  DELETE_SCHOOL_WIDGET,
  DELETE_WIDGET_FROM_LIST,
  DeleteSchoolWidgetAction,
  DeleteWidgetFromListAction,
  LOAD_SCHOOL_WIDGET_GROUP,
  LOAD_SCHOOL_WIDGETS,
  LoadSchoolWidgetGroupAction,
  LoadSchoolWidgetsAction,
  SET_SCHOOL_WIDGET_GROUP,
  SET_SCHOOL_WIDGETS,
  SetSchoolWidgetGroupAction,
  SetSchoolWidgetsAction,
  UPDATE_SCHOOL_WIDGET,
  UPDATE_SCHOOL_WIDGET_ORDER,
  UpdateSchoolWidgetAction,
  UpdateSchoolWidgetOrderAction,
} from './school-widget-details.types';

export const loadSchoolWidgetsAction = (schoolWidgetGroupId: string): LoadSchoolWidgetsAction => ({
  type: LOAD_SCHOOL_WIDGETS,
  payload: { schoolWidgetGroupId },
});

export const setSchoolWidgetsAction = (widgets: WidgetDTO[]): SetSchoolWidgetsAction => ({
  type: SET_SCHOOL_WIDGETS,
  payload: widgets,
});

export const updateSchoolWidgetAction = (widget: WidgetDTO): UpdateSchoolWidgetAction => ({
  type: UPDATE_SCHOOL_WIDGET,
  payload: widget,
});

export const updateSchoolWidgetOrderAction = (
  groupId: string,
  widgets: WidgetOrderDTO[],
): UpdateSchoolWidgetOrderAction => ({
  type: UPDATE_SCHOOL_WIDGET_ORDER,
  payload: { schoolWidgetGroupId: groupId, widgets },
});

export const createSchoolWidgetAction = (widget: NewWidgetDTO): CreateSchoolWidgetAction => ({
  type: CREATE_SCHOOL_WIDGET,
  payload: widget,
});

export const deleteSchoolWidgetAction = (id: number): DeleteSchoolWidgetAction => ({
  type: DELETE_SCHOOL_WIDGET,
  payload: id,
});

export const addWidgetToListAction = (widget: WidgetDTO): AddWidgetToListAction => ({
  type: ADD_WIDGET_TO_LIST,
  payload: widget,
});

export const deleteWidgetFromListAction = (id: number): DeleteWidgetFromListAction => ({
  type: DELETE_WIDGET_FROM_LIST,
  payload: id,
});

export const loadSchoolWidgetGroupAction = (id: number): LoadSchoolWidgetGroupAction => ({
  type: LOAD_SCHOOL_WIDGET_GROUP,
  payload: { id },
});

export const setSchoolWidgetGroupAction = (data: SchoolWidgetGroupDto): SetSchoolWidgetGroupAction => ({
  type: SET_SCHOOL_WIDGET_GROUP,
  payload: { data },
});

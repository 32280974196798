import { call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';

import { clearCacheAction, setSettingsAction } from './settings.actions';
import { clearCacheRequest, getSettingRequest, updateSettingRequest } from './settings.api';
import {
  CLEAR_CACHE,
  ClearCacheAction,
  LOAD_SETTING,
  LoadSettingAction,
  SettingDto,
  SettingsActionTypes,
  UPDATE_SETTING,
  UpdateSettingAction,
} from './settings.types';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../global-request';
import { BasicResponseDTO, DefaultResponseDTO } from '../../constants';

function* loadSettingData(
  action: LoadSettingAction,
): Generator<
  CallEffect<DefaultResponseDTO<SettingDto>> | PutEffect<SettingsActionTypes> | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;
  const { key } = action.payload;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getSettingRequest, key)) as DefaultResponseDTO<SettingDto>;
    yield put(setSettingsAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* updateSettingData(
  action: UpdateSettingAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<SettingsActionTypes> | PutEffect<GlobalRequestActions>> {
  const { type: actionType } = action;
  const data = action.payload;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateSettingRequest, data);
    yield put(setSettingsAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* clearCacheSaga(
  action: ClearCacheAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<SettingsActionTypes> | PutEffect<GlobalRequestActions>> {
  const { type: actionType } = action;

  try {
    yield call(clearCacheRequest);
    yield put(clearCacheAction());
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* settingsSaga(): Generator {
  yield takeEvery(LOAD_SETTING, loadSettingData);
  yield takeEvery(UPDATE_SETTING, updateSettingData);
  yield takeEvery(CLEAR_CACHE, clearCacheSaga);
}

import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import AddRankingCalendarForm from './AddRankingCalendarForm';
import CalendarForm from './CalendarForm';
import { loadRankingCalendarsAction } from '../calendar.actions';
import { RankingTypeDTO } from '../calendar.dto';
import { selectCalendarsData } from '../calendar.selectors';
import Button, { ButtonSizes } from '../../../../../components/Button';
import Modal from '../../../../../components/Modal';
import Typography, { TypographyVariants } from '../../../../../components/Typography';
import { Resource } from '../../../../../shared/constants';
import {
  loadRankingTypesDropdownAction,
  selectRankingTypesListForDropdown,
} from '../../../../../shared/state/ranking-types-dropdown';

const CalendarList: FunctionComponent = () => {
  const dispatch = useDispatch();
  const calendars = useSelector(selectCalendarsData);
  const rankingTypesForDropdown = useSelector(selectRankingTypesListForDropdown);
  const [isAddFormModalOpen, setIsAddFormModalOpen] = useState(false);
  const [isEditFormModalOpen, setIsEditFormModalOpen] = useState(false);

  useEffect(() => {
    dispatch(loadRankingCalendarsAction());
    dispatch(loadRankingTypesDropdownAction());
  }, [dispatch]);

  const openAddFormModal = (): void => {
    setIsAddFormModalOpen(true);
  };

  const closeAddFormModal = (): void => {
    setIsAddFormModalOpen(false);
  };

  const openEditFormModal = (): void => {
    setIsEditFormModalOpen(true);
  };

  const closeEditFormModal = (): void => {
    setIsEditFormModalOpen(false);
  };

  const rankingTypes: RankingTypeDTO[] = useMemo(
    () =>
      rankingTypesForDropdown.map((rankingType: Resource) => ({
        id: rankingType.key,
        name: rankingType.label,
        shortName: rankingType.shortLabel || rankingType.label,
      })),
    [rankingTypesForDropdown],
  );

  const calendarsWithRankingType = useMemo(
    () =>
      calendars.map((calendar) => ({
        ...calendar,
        rankingType: rankingTypes.find((rankingType) => rankingType.id === calendar.rankingTypeId),
      })),
    [calendars, rankingTypes],
  );

  const rankingTypeOptions = rankingTypes.map((rankingType: RankingTypeDTO) => ({
    label: rankingType.name,
    value: rankingType.id,
  }));

  return (
    <>
      <div className="mba-heading--wrapper">
        <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title mba-heading--calendar">
          Calendar
        </Typography>
        <div className="mba-heading--actions">
          <Button text="Edit calendar" size={ButtonSizes.big} primary onClick={(): void => openEditFormModal()} />
          <Button
            text="Add new ranking to calendar"
            size={ButtonSizes.big}
            primary
            onClick={(): void => openAddFormModal()}
          />
        </div>
      </div>
      <div className="mba-calendar-container">
        {calendarsWithRankingType.map((calendar) => (
          <div key={calendar.id} className="mba-calendar">
            <span className="mba-calendar-name">{calendar.rankingType?.name}</span>
            <div className="mba-calendar-content">
              <span className="mba-calendar-list">
                <span className="label">Open</span>
                <span>{moment(calendar.openDate).format('MMMM yyyy')}</span>
              </span>
              <span className="mba-calendar-list right">
                <span className="label">Publication</span>
                <span>{moment(calendar.publicationDate).format('MMMM yyyy')}</span>
              </span>
            </div>
          </div>
        ))}
      </div>
      <Modal title="Edit calendar" isOpen={isEditFormModalOpen} onClose={closeEditFormModal}>
        <CalendarForm
          calendars={calendarsWithRankingType}
          rankingTypes={rankingTypeOptions}
          onFormClose={closeEditFormModal}
        />
      </Modal>
      <Modal title="Add new ranking to calendar" isOpen={isAddFormModalOpen} onClose={closeAddFormModal}>
        <AddRankingCalendarForm rankingTypes={rankingTypeOptions} onFormClose={closeAddFormModal} />
      </Modal>
    </>
  );
};

export default CalendarList;

import moment from 'moment-timezone';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Form, { FormConfig, FormDataValues, FormErrors } from '../../../../../../components/Form';
import { THIRTY_DAYS_IN_MILLISECONDS } from '../../../../../../shared/constants';
import { selectAreRequestsLoading, selectRequestFormErrors } from '../../../../../../shared/state/global-request';
import { selectResources } from '../../../../../../shared/state/resources';
import { loadStatusesAction, selectStatuses } from '../../../../../../shared/state/statuses';
import {
  CREATE_RANKING,
  createNewRankingAction,
  CreateRankingDTO,
  EDIT_RANKING,
  editRankingAction,
  EditRankingDTO,
  RankingDTO,
  selectCurrentRanking,
} from '../../../RankingProfile/RankingInfo';
import { RANKING_CREATE_FORM_CONFIG, RANKING_EDIT_FORM_CONFIG } from '../RankingsList.constants';

type RankingFormProps = {
  isEdit?: boolean;
  onFormClose: () => void;
};

const RankingForm: FunctionComponent<RankingFormProps> = ({ onFormClose, isEdit = false }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectAreRequestsLoading([CREATE_RANKING, EDIT_RANKING]));
  const currentRanking = useSelector(selectCurrentRanking);
  const currentErrors = useSelector(selectRequestFormErrors(isEdit ? EDIT_RANKING : CREATE_RANKING));
  const rankingStatuses = useSelector(selectStatuses('ranking'));
  const rankingTypes = useSelector(selectResources('ranking-types'));

  const [formValues, setFormValues] = useState<FormDataValues>({});
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  useEffect(() => {
    dispatch(loadStatusesAction('ranking-types'));
  }, [dispatch]);

  useEffect(() => {
    if (currentRanking) {
      setFormValues({ status: (currentRanking as RankingDTO).status?.key });
    }
  }, [currentRanking]);

  useEffect(() => {
    if (currentErrors) {
      setFormErrors(currentErrors);
    }
  }, [currentErrors]);

  const onRankingSaveSubmit = (data: CreateRankingDTO | EditRankingDTO): void => {
    if (isEdit) {
      const { id } = currentRanking as EditRankingDTO;
      const { status } = data as EditRankingDTO;
      dispatch(
        editRankingAction({
          ...data,
          id,
          status: +status,
        }),
      );
    } else {
      dispatch(
        createNewRankingAction({
          ...data,
          publicationDate: data.publicationDate || new Date(Date.now() + THIRTY_DAYS_IN_MILLISECONDS),
        } as CreateRankingDTO),
      );
    }
  };

  const onValueChange = (data: FormDataValues): void => {
    setFormValues(data);
    setFormErrors({});
  };

  const defaultFormConfig: FormConfig = isEdit
    ? {
        ...RANKING_EDIT_FORM_CONFIG,
        status: {
          ...RANKING_EDIT_FORM_CONFIG.status,
          options: rankingStatuses.map(({ key, label }) => ({ label, value: key })),
        },
        rankingTypeId: {
          ...RANKING_EDIT_FORM_CONFIG.rankingTypeId,
          options: rankingTypes.map(({ key, label }) => ({ label, value: key })),
        },
      }
    : {
        ...RANKING_CREATE_FORM_CONFIG,
        rankingTypeId: {
          ...RANKING_EDIT_FORM_CONFIG.rankingTypeId,
          options: rankingTypes.map(({ key, label }) => ({ label, value: key })),
        },
      };

  const rankingInitialData = isEdit
    ? {
        ...currentRanking,
        status: (currentRanking as RankingDTO).status?.key,
        rankingTypeId: currentRanking.rankingTypeId,
        ...formValues,
      }
    : {
        ...currentRanking,
        ...formValues,
      };

  return (
    <Form
      id="ranking-form"
      isLoading={isLoading}
      config={defaultFormConfig}
      onValuesChanged={onValueChange}
      onSubmit={onRankingSaveSubmit}
      submitButtonText={isEdit ? 'Save' : 'Create ranking'}
      actions={[
        {
          label: 'Cancel',
          onClick: onFormClose,
        },
      ]}
      initialValues={rankingInitialData}
      errors={formErrors}
    />
  );
};

export default RankingForm;

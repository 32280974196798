import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import Button, { ButtonSizes } from '../../../../../components/Button';
import Typography from '../../../../../components/Typography';
import { selectAreRequestsLoading } from '../../../../../shared/state/global-request';
import { DELETE_SCHOOL_WIDGET } from '../../school-widget-details.types';

type RemoveWidgetPopupProps = {
  onCancel: () => void;
  onConfirm: (id: number) => void;
  widgetId: number;
};

const RemoveWidgetPopup: FunctionComponent<RemoveWidgetPopupProps> = ({ widgetId, onCancel, onConfirm }) => {
  const isLoading = useSelector(selectAreRequestsLoading([DELETE_SCHOOL_WIDGET]));

  return (
    <>
      <Typography>Are you sure you want to delete this widget?</Typography>
      <div className="mba-actions">
        <Button onClick={onCancel} text="Cancel" size={ButtonSizes.big} />
        <Button
          onClick={(): void => onConfirm(widgetId)}
          primary
          text="Yes"
          isLoading={isLoading}
          size={ButtonSizes.big}
        />
      </div>
    </>
  );
};

export default RemoveWidgetPopup;

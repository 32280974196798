import { FormConfig } from '../../../../../../../../../../components/Form';

export const RANKING_FIELD_DATA_FORM_CONFIG: FormConfig = {
  value: {
    label: 'FT Research rank',
    placeholder: 'Enter rank',
    fieldType: 'text',
    validations: {
      required: {
        errorText: `Please enter rank!`,
      },
      // regex: {
      //   rule: /^(\d|\.|,|)+$/,
      //   errorText: 'Please enter a number.',
      // },
    },
  },
};

import React, { FunctionComponent } from 'react';

import styles from './Message.module.scss';

type MessageProps = {
  messageContent: string;
  onClose: () => void;
};

const Message: FunctionComponent<MessageProps> = ({ messageContent, onClose }) => {
  return (
    <div className={`o-message o-message--alert o-message--success ${styles.message}`} data-o-component="o-message">
      <div className="o-message__container">
        <div className="o-message__content">
          <p className={`o-message__content-main ${styles.main}`}>{messageContent}</p>
          <div className="o-message__actions"></div>
        </div>
        <button
          onClick={onClose}
          className={`o-message__close ${styles.closeButton}`}
          aria-label="close"
          title="Close"
        ></button>
      </div>
    </div>
  );
};

export default Message;

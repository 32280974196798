import { ColumnDefinition, TableHeaderColumnTypes } from '../../../../components/Table';

export const SCHOOLS_WITH_LEAD_GEN_TABLE_COLUMN_DEFINITION: ColumnDefinition[] = [
  {
    id: 'status',
    title: 'Status',
    type: TableHeaderColumnTypes.status,
    sorting: true,
  },
  {
    id: 'id',
    title: 'ID',
    sorting: true,
  },
  {
    id: 'schoolName',
    title: 'Name',
    path: '/commercial/lead-gen/:id/leads',
    type: TableHeaderColumnTypes.compositeLink,
    sorting: true,
  },
  {
    id: 'leadCount',
    title: 'Number of leads',
    type: TableHeaderColumnTypes.numeric,
    sorting: true,
  },
  {
    id: 'activeFrom',
    title: 'Active from',
    type: TableHeaderColumnTypes.date,
    sorting: true,
  },
  {
    id: 'activeTo',
    title: 'Active to',
    type: TableHeaderColumnTypes.date,
    sorting: true,
  },
];

import { FormConfig } from '../../../../components/Form';
import { DATE_FORMAT_REGEX } from '../../../../shared/constants';
import { STATUS_LIST } from '../../SchoolWidgetGroups/components/CreateWidgetGroupModal/CreateWidgetGroupModal.constants';

export enum SchoolWithLeadDetailsStatus {
  Active,
  Inactive,
  Expired,
}

export interface UpdateSchoolWithLeadDetailsDto {
  activeFromDate?: string;
  activeToDate?: string;
  schoolName?: string;
  status?: number;
}

export const SCHOOL_WITH_LEAD_DETAILS_STATUS_LIST = [
  { label: 'Active', color: '#006B36', value: SchoolWithLeadDetailsStatus.Active },
  { label: 'Inactive', color: '#6B6B6B', value: SchoolWithLeadDetailsStatus.Inactive },
  { label: 'Expired', color: '#686868', value: SchoolWithLeadDetailsStatus.Expired, isDisabled: true },
];

export const SCHOOL_WITH_LEAD_DETAILS_CONFIG: FormConfig = {
  schoolName: {
    fieldType: 'text',
    title: 'School name',
    disabled: true,
  },
  activeFrom: {
    fieldType: 'date',
    placeholder: 'Select date',
    required: true,
    title: 'Active from',
    previewFormat: 'Do MMM YYYY',
    validations: {
      regex: {
        errorText: 'Please select a date',
        rule: DATE_FORMAT_REGEX,
      },
    },
  },
  leads: {
    fieldType: 'text',
    title: 'Leads',
    disabled: true,
  },
  status: {
    fieldType: 'select',
    placeholder: 'Choose a status',
    required: true,
    options: SCHOOL_WITH_LEAD_DETAILS_STATUS_LIST,
    title: 'Status',
    validations: {
      required: {
        errorText: 'Please choose a status!',
      },
    },
  },
  activeTo: {
    fieldType: 'date',
    placeholder: 'Select date',
    required: true,
    title: 'Active to',
    previewFormat: 'Do MMM YYYY',
    validations: {
      regex: {
        errorText: 'Please select a date',
        rule: DATE_FORMAT_REGEX,
      },
    },
  },
};

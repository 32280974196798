import { SchoolWidgetGroupDto } from '../../../containers/Commercial/SchoolWidgetGroups/school-widget-groups.dto';
import { Resource } from '../../constants';
import {
  LOAD_SCHOOL_DROPDOWN,
  LOAD_SINGLE_SCHOOL_WIDGET_GROUPS,
  LoadSchoolsDropdownAction,
  LoadSingleSchoolWidgetGroupsAction,
  SET_SCHOOL_DROPDOWN,
  SET_SINGLE_SCHOOL_WIDGET_GROUPS,
  SetSchoolsDropdownAction,
  SetSingleSchoolWidgetGroupsAction,
} from './school-resources.types';

export const loadSchoolsDropdownAction = (): LoadSchoolsDropdownAction => ({
  type: LOAD_SCHOOL_DROPDOWN,
});

export const setSchoolsDropdownAction = (schools: Resource[]): SetSchoolsDropdownAction => ({
  type: SET_SCHOOL_DROPDOWN,
  payload: { schools },
});

export const loadSingleSchoolWidgetGroupsAction = (schoolId: number): LoadSingleSchoolWidgetGroupsAction => ({
  type: LOAD_SINGLE_SCHOOL_WIDGET_GROUPS,
  payload: { schoolId },
});

export const setSingleSchoolWidgetGroupsAction = (
  schoolWidgetGroupsDto: SchoolWidgetGroupDto[],
): SetSingleSchoolWidgetGroupsAction => ({
  type: SET_SINGLE_SCHOOL_WIDGET_GROUPS,
  payload: { schoolWidgetGroupsDto },
});

import { FormConfig, FormControl, FormControlFieldType } from '../../../../components/Form';
import { SelectOptionType } from '../../../../components/Select';
import { ColumnDefinition, TableHeaderColumnTypes } from '../../../../components/Table';
import { DATE_FORMAT_REGEX, Resource } from '../../../../shared/constants';
import {
  CreateSchoolWidgetGroupScope,
  CreateSchoolWidgetGroupStatus,
} from './CreateWidgetGroupModal/CreateWidgetGroupModal.constants';

export const WIDGETS_TABLE_COLUMN_DEFINITION: ColumnDefinition[] = [
  {
    id: 'status',
    title: 'Status',
    type: TableHeaderColumnTypes.status,
    sorting: true,
  },
  {
    id: 'id',
    title: 'ID',
    sorting: true,
  },
  {
    id: 'school',
    title: 'Name',
    path: '/commercial/school-widget-groups/detail/:id',
    type: TableHeaderColumnTypes.compositeLink,
    sorting: true,
  },
  {
    id: 'scope',
    title: 'Scope',
    sorting: true,
  },
  {
    id: 'widgetCount',
    title: 'Widgets',
  },
  {
    id: 'activeFromDate',
    title: 'Active from',
    sorting: true,
    type: TableHeaderColumnTypes.date,
  },
  {
    id: 'activeToDate',
    title: 'Active to',
    sorting: true,
    type: TableHeaderColumnTypes.date,
  },
];

export type WidgetGroupFormValues = { school?: number; action?: string; group?: string; scope?: string };

export const WIDGET_GROUP_FORM_CONFIG = (
  wgfv: WidgetGroupFormValues,
  rankingTypes: Resource[],
  schools: SelectOptionType[] = [],
  schoolWidgetGroupsOption: SelectOptionType[] = [],
): FormConfig => ({
  action: actionField(wgfv.action),
  school: schoolField(schools),
  ...(wgfv.action === 'duplicate'
    ? {
        ...(wgfv.school ? { group: groupField(schoolWidgetGroupsOption) } : {}),
        ...(wgfv.group ? { activeFromDate: activeFromDate, activeToDate: activeToDate, status } : {}),
      }
    : {
        scope,
        ...(wgfv.scope === 'rankingType' ? { rankingTypeId: getRankingTypeControl(rankingTypes) } : {}),
        activeFromDate: activeFromDate,
        activeToDate: activeToDate,
        status,
      }),
});

const actionField = (action = 'duplicate'): FormControl => ({
  fieldType: 'radio',
  inline: true,
  shrunken: true,
  value: action,
  options: [
    {
      checked: action === 'duplicate',
      label: 'Duplicate existing',
      value: 'duplicate',
    },
    {
      checked: action === 'create',
      label: 'Create new',
      value: 'create',
    },
  ],
});

const schoolField = (schools: SelectOptionType[]): FormControl => ({
  label: 'School name',
  placeholder: `Enter school's name`,
  fieldType: 'select' as FormControlFieldType,
  options: schools,
  searchable: true,
  validations: {
    required: {
      errorText: `Please enter school's name!`,
    },
  },
});

const groupField = (schoolWidgetGroupsOption: SelectOptionType[]): FormControl => ({
  label: 'School widget group',
  placeholder: `Select widget group`,
  fieldType: 'select' as FormControlFieldType,
  options: schoolWidgetGroupsOption,
  validations: {
    required: {
      errorText: `Please select a group!`,
    },
  },
});

const activeFromDate: FormControl = {
  fieldType: 'date',
  placeholder: 'Enter date DD/MM/YYYY',
  previewFormat: 'Do MMM YYYY',
  required: true,
  title: 'Active from',
  validations: {
    regex: {
      errorText: 'Please enter a valid date DD/MM/YYYY',
      rule: DATE_FORMAT_REGEX,
    },
  },
};

const activeToDate: FormControl = {
  fieldType: 'date',
  placeholder: 'Enter date DD/MM/YYYY',
  previewFormat: 'Do MMM YYYY',
  required: true,
  title: 'Active to',
  validations: {
    regex: {
      errorText: 'Please enter a valid date DD/MM/YYYY',
      rule: DATE_FORMAT_REGEX,
    },
  },
};

const status: FormControl = {
  label: 'Status',
  placeholder: `Select status`,
  fieldType: 'select' as FormControlFieldType,
  options: [
    { label: 'Active', value: CreateSchoolWidgetGroupStatus.Active },
    { label: 'Inactive', value: CreateSchoolWidgetGroupStatus.Inactive },
    { label: 'Expired', value: CreateSchoolWidgetGroupStatus.Expired },
  ],
  validations: {
    required: {
      errorText: `Please select a status!`,
    },
  },
};

const scope: FormControl = {
  label: 'Scope',
  placeholder: `Select scope`,
  fieldType: 'select' as FormControlFieldType,
  options: [
    { label: 'All Rankings', value: CreateSchoolWidgetGroupScope.all },
    { label: 'Only for specific ranking type', value: CreateSchoolWidgetGroupScope.rankingType },
    { label: 'Only for specific ranking', value: CreateSchoolWidgetGroupScope.ranking },
  ],
  validations: {
    required: {
      errorText: `Please select a scope!`,
    },
  },
  value: 'all',
};

const getRankingTypeControl = (rankingTypes: Resource[]): FormControl => ({
  label: 'Specific ranking type',
  placeholder: `Select specific ranking type`,
  fieldType: 'select' as FormControlFieldType,
  options: rankingTypes.map(({ key, label }) => ({ label, value: key })),
  validations: {
    required: {
      errorText: `Please select a scope!`,
    },
  },
});

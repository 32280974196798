import React, { FunctionComponent, ReactNode, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button, { ButtonSizes } from '../Button';
import Modal, { MODAL_ANIMATION_DURATION } from '../Modal';
import { FileUploadInput } from './FileUploadInput';

import { clearRequestFailedAction } from '../../shared/state/global-request';
import { UPLOAD_SPREADSHEET } from '../../containers/Rankings/Rankings/RankingProfile/RankingDetails/TimelineSteps';

import styles from './FileUpload.module.scss';
import { getRenamedFile } from '../../shared/helpers';

export interface FileUploadProps {
  isOpen: boolean;
  isLoading: boolean;
  errorMessage?: ReactNode;
  title?: string;
  showPreview?: boolean;
  onClose: () => void;
  onUpload: (file: File) => void;
  onSelect?: (file: File) => void;
}

const FileUpload: FunctionComponent<FileUploadProps> = ({
  errorMessage,
  isOpen,
  isLoading,
  title,
  showPreview,
  onClose,
  onUpload,
  onSelect,
}) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState({} as File);
  const [imagePreview, setImagePreview] = useState('');
  const [isFileNameValid, setIsFileNameValid] = useState(true);

  const handleChange = (files: FileList): void => {
    const file = files[0];
    if (checkIsFileNameValid(file)) {
      setIsFileNameValid(true);
      const renamedFile = file.name.indexOf('–') > 0 ? getRenamedFile(file, encodeURIComponent(file.name)) : file;
      setFile(renamedFile);
      showPreview && setImagePreview(URL.createObjectURL(renamedFile || ''));
      dispatch(clearRequestFailedAction(UPLOAD_SPREADSHEET));
      onSelect && onSelect(file);
    } else {
      setIsFileNameValid(false);
      setFile({} as File);
    }
  };

  const handleClose = (): void => {
    onClose();
    dispatch(clearRequestFailedAction(UPLOAD_SPREADSHEET));
    setTimeout(() => {
      setFile({} as File);
      showPreview && setImagePreview('');
    }, MODAL_ANIMATION_DURATION);
  };

  const checkIsFileNameValid = (file: File): boolean => {
    if (file.name) {
      const validFileNamePattern = /^[a-zA-Z0-9-\s–]+$/;
      const fileName = file.name.split('.')[0];
      return validFileNamePattern.test(fileName);
    }
    return false;
  };

  return (
    <Modal title={title} isOpen={isOpen} onClose={handleClose}>
      <label htmlFor="file-input" className={styles.label}>
        Choose file
      </label>
      <FileUploadInput id="file-input" handleChange={handleChange} errorMessage={errorMessage} display={isOpen} />
      {imagePreview && <img src={imagePreview} alt="" />}
      {!isFileNameValid && (
        <div className={styles.invalidNameMessage}>
          The filename cannot contain special characters. Please use uppercase letters, lowercase letters, numbers,
          spaces and hyphens only.
        </div>
      )}
      <div className="mba-actions">
        <Button text="Cancel" size={ButtonSizes.big} onClick={handleClose} />
        <Button
          isLoading={isLoading}
          primary
          text="Upload"
          size={ButtonSizes.big}
          onClick={(): void => onUpload(file)}
          disabled={!isFileNameValid}
        />
      </div>
    </Modal>
  );
};

export default FileUpload;

import {
  DEFAULT_GRID_COLSPAN_SIZE,
  GRID_COLSPAN_SIZES,
  GridColSpanSize,
  MAX_GRID_COLSPAN_SIZE_FOR_SINGLE_ROW,
} from '../Grid';
import { GridOffsetType } from '../Grid/Grid';

export const calculateGroupColumnSize = (
  prefixSize: boolean | GridColSpanSize = false,
  defaultValue: boolean | GridColSpanSize = false,
): boolean | GridColSpanSize => {
  if (typeof prefixSize === 'number' && prefixSize <= MAX_GRID_COLSPAN_SIZE_FOR_SINGLE_ROW) {
    return (DEFAULT_GRID_COLSPAN_SIZE - prefixSize) as GridColSpanSize;
  }

  return defaultValue;
};

export const getColumnDetailClassName = (
  columnSize: GridColSpanSize,
  index: number,
  offset?: GridOffsetType,
): string => {
  let columnClassName = '';
  const offsetValue = offset?.sm || offset?.md || offset?.lg || offset?.xl;
  const columnPosition = offsetValue ? (offsetValue.includes('push') ? 'last' : 'first') : '';
  if (columnSize >= 7) {
    columnClassName = 'mba-details-column--single';
  } else if (
    index === 0 ||
    ((columnSize === GRID_COLSPAN_SIZES.SIZE_6 || columnSize === GRID_COLSPAN_SIZES.SIZE_5) &&
      index % GRID_COLSPAN_SIZES.SIZE_2 === 0) ||
    (columnSize === GRID_COLSPAN_SIZES.SIZE_3 && index % GRID_COLSPAN_SIZES.SIZE_4 === 0) ||
    (columnSize === GRID_COLSPAN_SIZES.SIZE_4 && index % GRID_COLSPAN_SIZES.SIZE_3 === 0) ||
    (columnSize === GRID_COLSPAN_SIZES.SIZE_2 && index % GRID_COLSPAN_SIZES.SIZE_6 === 0) ||
    (columnSize === GRID_COLSPAN_SIZES.SIZE_1 && index % GRID_COLSPAN_SIZES.SIZE_12 === 0)
  ) {
    columnClassName = `mba-details-column--${columnPosition ? columnPosition : 'first'}`;
  } else if (
    ((columnSize === GRID_COLSPAN_SIZES.SIZE_6 || columnSize === GRID_COLSPAN_SIZES.SIZE_5) &&
      index % GRID_COLSPAN_SIZES.SIZE_2 !== 0) ||
    (columnSize === GRID_COLSPAN_SIZES.SIZE_4 && (index + 1) % GRID_COLSPAN_SIZES.SIZE_3 === 0) ||
    (columnSize === GRID_COLSPAN_SIZES.SIZE_3 && (index + 1) % GRID_COLSPAN_SIZES.SIZE_4 === 0) ||
    (columnSize === GRID_COLSPAN_SIZES.SIZE_2 && (index + 1) % GRID_COLSPAN_SIZES.SIZE_6 === 0) ||
    (columnSize === GRID_COLSPAN_SIZES.SIZE_1 && (index + 1) % GRID_COLSPAN_SIZES.SIZE_12 === 0)
  ) {
    columnClassName = `mba-details-column--${columnPosition ? columnPosition : 'last'}`;
  }

  return columnClassName;
};

import { CREATE_RANKING_FIELD, EDIT_RANKING_FIELD } from '../../RankingFieldsList/components/RankingFieldsList';
import { RankingFieldDTO } from './ranking-field-details.dto';

export const LOAD_RANKING_FIELD = 'LOAD_RANKING_FIELD';
export const SET_RANKING_FIELD = 'SET_RANKING_FIELD';
export const EDIT_RANKING_FIELD_DETAILS = 'EDIT_RANKING_FIELD_DETAILS';
export const DELETE_RANKING_FIELD = 'DELETE_RANKING_FIELD';

export interface RankingFieldProfileState {
  profile: RankingFieldDTO;
}

export interface RankingFieldProfileRouteParams {
  rankingFieldId: string;
  rankingId: string;
}

export interface CreateRankingFieldAction {
  type: typeof CREATE_RANKING_FIELD;
  payload: { rankingId: number; data: RankingFieldDTO };
}

export interface EditRankingFieldAction {
  type: typeof EDIT_RANKING_FIELD;
  payload: { rankingId: number; data: RankingFieldDTO };
}

export interface LoadRankingFieldAction {
  type: typeof LOAD_RANKING_FIELD;
  payload: { rankingId: number; rankingFieldId: number };
}

export interface EditRankingFieldDetailsAction {
  type: typeof EDIT_RANKING_FIELD_DETAILS;
  payload: { rankingId: number; data: RankingFieldDTO };
}

export interface SetRankingFieldAction {
  type: typeof SET_RANKING_FIELD;
  payload: { data: RankingFieldDTO };
}

export interface DeleteRankingFieldAction {
  type: typeof DELETE_RANKING_FIELD;
  payload: { rankingId: number; rankingFieldId: number };
}

export type RankingFieldProfileActionTypes =
  | CreateRankingFieldAction
  | LoadRankingFieldAction
  | SetRankingFieldAction
  | EditRankingFieldDetailsAction
  | DeleteRankingFieldAction;

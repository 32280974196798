import React, { FunctionComponent, ReactNode } from 'react';

import Grid from '../../../../../../components/Grid';
import { formatDateString } from '../../../../../../shared/helpers';

interface TimelineStepDatesProps {
  endDate?: string;
  endDateLabel?: string;
  isEndDateRequired?: boolean;
  isStartDateRequired?: boolean;
  startDate?: string;
  startDateLabel?: string;
}

const TimelineStepDates: FunctionComponent<TimelineStepDatesProps> = ({
  endDate,
  endDateLabel,
  isEndDateRequired = true,
  startDate,
  startDateLabel,
  isStartDateRequired = false,
}) => {
  let formattedStartDate: ReactNode = startDate ? formatDateString(startDate, 'Do MMM YYYY', true) : '';
  let formattedEndDate: ReactNode = endDate ? formatDateString(endDate, 'Do MMM YYYY', true) : '';

  if (isStartDateRequired && !formattedStartDate) {
    formattedStartDate = <span className="mba-text--error">Not set!</span>;
  }

  if (isEndDateRequired && !formattedEndDate) {
    formattedEndDate = <span className="mba-text--error">Not set!</span>;
  }

  return (
    <>
      {startDateLabel && (
        <Grid item xs={12} md={6} className="mba-text--right">
          <p className="mba-no-margin mba-font--14">{startDateLabel}</p>
          <p className="mba-text--semi-bold mba-font--16">{formattedStartDate}</p>
        </Grid>
      )}
      <Grid item xs={12} md={!startDateLabel ? 12 : 6} className="mba-text--right">
        <p className="mba-no-margin mba-font--14">{endDateLabel}</p>
        <p className="mba-text--semi-bold mba-font--16">{formattedEndDate}</p>
      </Grid>
    </>
  );
};

export default TimelineStepDates;

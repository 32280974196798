import { UpdateSchoolWithLeadDetailsDto } from './SchoolWithLeadDetails.constants';

export const UPDATE_SCHOOL_WITH_LEAD_DETAILS = 'UPDATE_SCHOOL_WITH_LEAD_DETAILS';

export const UPDATE_SCHOOL_LEAD_ACTIVE = 'UPDATE_SCHOOL_LEAD_ACTIVE';

export interface UpdateSchoolWithLeadDetailsAction {
  type: typeof UPDATE_SCHOOL_WITH_LEAD_DETAILS;
  payload: { id: number; updatedData: UpdateSchoolWithLeadDetailsDto };
}

export interface UpdateSchoolLeadActiveAction {
  type: typeof UPDATE_SCHOOL_LEAD_ACTIVE;
  payload: { id: number; hasLeadGen: boolean };
}

export type SchoolWithLeadDetailsActionTypes = UpdateSchoolWithLeadDetailsAction;

import { FiltersConfig } from '../../../../../components/FilterGroup';

export const ADMIN_USERS_TABLE_FILTERS: FiltersConfig = {
  role: {
    title: 'Role',
    options: [],
  },
  status: {
    title: 'Status',
    options: [],
  },
};

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button, { ButtonSizes, ButtonTypes } from '../../../../../components/Button';
import Form, { FormConfig, FormDataValues } from '../../../../../components/Form';
import Grid from '../../../../../components/Grid';
import { Resource } from '../../../../../shared/constants';
import { capitalizeText } from '../../../../../shared/helpers';
import {
  selectRequestErrors,
  selectRequestIsLoading,
  setRequestSucceededAction,
} from '../../../../../shared/state/global-request';
import { updateSchoolUserRoleAndPermissionsAction } from '../../school-user-profile.actions';
import { UPDATE_SCHOOL_USER_ROLES_AND_PERMISSIONS } from '../../school-user-profile.types';

type SchoolUserRolesAndPermissionsProps = {
  label: string;
  partnershipId: number;
  permissions?: number[];
  permissionsSubTitle?: string;
  rankingTypes?: Resource[];
  role?: string;
  rolesSubTitle?: string;
  schoolId: number;
  schoolUserRoles?: string[];
  userId: number;
};

const SchoolUserRolesAndPermissions: FunctionComponent<SchoolUserRolesAndPermissionsProps> = ({
  label,
  partnershipId,
  permissions = [],
  permissionsSubTitle,
  rankingTypes = [],
  role,
  rolesSubTitle,
  schoolId,
  schoolUserRoles = [],
  userId,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectRequestIsLoading(UPDATE_SCHOOL_USER_ROLES_AND_PERMISSIONS));
  const formErrors = useSelector(selectRequestErrors(UPDATE_SCHOOL_USER_ROLES_AND_PERMISSIONS));
  const [isEditing, setIsEditing] = useState(false);
  const [currentRole, setCurrentRole] = useState(role);
  const [currentPermissions, setCurrentPermissions] = useState(permissions);

  useEffect(() => {
    if (permissions.sort().toString() === currentPermissions.sort().toString() && currentRole === role) {
      setIsEditing(false);
    }
    if (role && !currentRole) {
      setCurrentRole(role);
      setCurrentPermissions(permissions);
    }
  }, [currentRole, currentPermissions, role, permissions]);

  const handleDiscardChangesClick = (): void => {
    dispatch(setRequestSucceededAction(UPDATE_SCHOOL_USER_ROLES_AND_PERMISSIONS));

    setIsEditing(false);
    setCurrentRole(role);
    setCurrentPermissions(permissions);
  };

  const handleFormSubmit = (values: FormDataValues): void => {
    dispatch(
      updateSchoolUserRoleAndPermissionsAction(
        {
          role: values.role.toString(),
          permissions:
            values.permissions && Array.isArray(values.permissions)
              ? values.permissions.map((permission) => +permission)
              : currentPermissions,
        },
        partnershipId,
        schoolId,
        userId,
      ),
    );
  };

  const handleValuesChanged = (values: FormDataValues): void => {
    if (!isEditing) {
      setIsEditing(true);
    }

    const { role, permissions: newPermissions } = values;
    let updatedPermissions: number[] = permissions || [];

    if (role === 'admin') {
      updatedPermissions = rankingTypes?.map((rankingType) => rankingType.key);
    } else if (role === currentRole && newPermissions && Array.isArray(newPermissions)) {
      updatedPermissions = newPermissions.map((permission) => +permission);
    }

    setCurrentRole(role.toString());
    setCurrentPermissions(updatedPermissions);
  };

  const formConfig: FormConfig = {
    role: {
      inline: true,
      label,
      subTitle: rolesSubTitle,
      fieldType: 'radio',
      options: schoolUserRoles.map((roleName: string) => ({
        label: capitalizeText(roleName),
        value: roleName,
      })),
    },
    permissions: {
      id: `school-${partnershipId}-permissions`,
      disabled: currentRole === 'admin',
      fieldType: 'checkbox',
      subTitle: permissionsSubTitle,
      options: rankingTypes.map(({ key, shortLabel, label }) => ({
        text: capitalizeText(shortLabel || label),
        name: key.toString(),
      })),
    },
  };

  const formActions = isEditing ? (
    <div className="mba-actions mba-actions--top-right">
      <Button isLoading={isLoading} type={ButtonTypes.submit} text="Save" primary size={ButtonSizes.big} />
      <Button text="Discard" size={ButtonSizes.big} onClick={handleDiscardChangesClick} />
    </div>
  ) : (
    <div />
  );

  return (
    <Grid container compact className="mba-column-group mba-no-margin">
      <Grid item>
        <Form
          id={`school-${partnershipId}-roles-and-permissions`}
          onSubmit={handleFormSubmit}
          config={formConfig}
          onValuesChanged={handleValuesChanged}
          customActions={formActions}
          initialValues={{
            role: currentRole,
            permissions: currentPermissions,
          }}
          errors={formErrors}
        />
      </Grid>
    </Grid>
  );
};

export default SchoolUserRolesAndPermissions;

import { NavigationConfiguration } from '../../../../App/Main/components/Layout';
import { RankingFieldType } from './RankingFieldsList';

export const RANKING_PROFILE_DATA_MAIN_ROUTE = '/rankings/profile/:rankingId/ranking-data';

export const RANKING_PROFILE_DATA_NAVIGATION: NavigationConfiguration = {
  list: {
    label: 'List',
    route: '/',
  },
  profile: {
    label: 'Profile',
    route: '/:rankingFieldId',
  },
};

export const GROUP_OPTIONS = (type: RankingFieldType): Array<{ value: string; label: string }> => [
  { value: 'General', label: 'General' },
  { value: 'Career progression', label: 'Career progression' },
  { value: 'Programme assessment', label: 'Programme assessment' },
  { value: 'International opportunities', label: 'International opportunities' },
  { value: 'Faculty expertise', label: 'Faculty expertise' },
  { value: 'Diversity assessment', label: 'Diversity assessment' },
];

import React, { FunctionComponent, MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TimelineIndicator from './TimelineIndicator';
import TimelineStepDates from './TimelineStepDates';
import { RankingTimelineStepBaseProps, TimelineExpandStepStatus } from '../RankingTimeline.constants';
import {
  downloadSpreadsheetAction,
  RankingSpreadsheetDTO,
  TimelineSpreadsheetDTO,
  UPLOAD_FINAL_RANK_SPREADSHEET,
  uploadSpreadsheetAction,
} from '../../RankingDetails/TimelineSteps';
import { PUBLISH_RANKING, publishRankingAction, RankingDTO, selectCurrentRanking } from '../../RankingInfo';
import Button, { ButtonIcons, ButtonSizes } from '../../../../../../components/Button';
import FileUpload from '../../../../../../components/FileUpload';
import Grid from '../../../../../../components/Grid';
import Modal, { MODAL_ANIMATION_DURATION } from '../../../../../../components/Modal';
import Typography from '../../../../../../components/Typography';
import { formatDateString, goToPublicPortal } from '../../../../../../shared/helpers';
import {
  selectRequestErrorMessage,
  selectRequestFormErrors,
  selectRequestStatus,
  setRequestSucceededAction,
} from '../../../../../../shared/state/global-request';

const PublicationStep: FunctionComponent<RankingTimelineStepBaseProps> = ({
  endDate,
  name,
  rankingId,
  slug,
  status,
  spreadsheet,
}) => {
  const dispatch = useDispatch();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [publishStep, setPublishStep] = useState(0);
  const [expand, setExpand] = useState<boolean>(false);
  const { name: rankName, status: rankStatus } = useSelector(selectCurrentRanking) as RankingDTO;

  // TODO add errors when BED is ready
  const errorMessage = useSelector(selectRequestErrorMessage(UPLOAD_FINAL_RANK_SPREADSHEET));
  const errors = useSelector(selectRequestFormErrors(UPLOAD_FINAL_RANK_SPREADSHEET));
  const uploadStatus = useSelector(selectRequestStatus(UPLOAD_FINAL_RANK_SPREADSHEET));
  const publishStatus = useSelector(selectRequestStatus(PUBLISH_RANKING));
  const uploadTitle = `${spreadsheet ? 'Update' : 'Upload'} ranking's final table`;

  useEffect(() => {
    setExpand(TimelineExpandStepStatus[status]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (!uploadStatus.isLoading && uploadStatus.isSuccess && isUploadModalOpen && !isNotificationOpen) {
      setIsUploadModalOpen(false);
      setIsNotificationOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadStatus]);

  useEffect(() => {
    if (!publishStatus.isLoading && publishStatus.isSuccess && publishStep === 1) {
      setPublishStep(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publishStatus]);

  const uploadRanking = (file: File): void => {
    // TODO clear errors from upload action
    dispatch(setRequestSucceededAction(UPLOAD_FINAL_RANK_SPREADSHEET));

    const formData = new FormData();
    formData.append('file', file);

    // TODO send the file to be uploaded
    const spreadsheetData: RankingSpreadsheetDTO = { rankingId, spreadsheet: formData, type: 'publication' };
    dispatch(uploadSpreadsheetAction(spreadsheetData, UPLOAD_FINAL_RANK_SPREADSHEET));
  };

  const closeUploadModal = (): void => {
    setIsUploadModalOpen(false);
  };

  const closePublishModal = (): void => {
    setPublishStep(0);
  };

  const closeNotificationModal = (): void => {
    setIsNotificationOpen(false);

    setTimeout(() => {
      // TODO clear errors from upload action
      dispatch(setRequestSucceededAction(UPLOAD_FINAL_RANK_SPREADSHEET));
    }, MODAL_ANIMATION_DURATION);
  };

  const handleDownload = (): void => {
    const spreadsheetData: TimelineSpreadsheetDTO = { rankingId, type: 'publication' };
    dispatch(downloadSpreadsheetAction(spreadsheetData));
  };

  const handlePublish = (): void => {
    dispatch(publishRankingAction(rankingId));
    // setPublishStep(2);
  };

  const navigateToRankingsSpreadsheets = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    goToPublicPortal(`rankings/${rankingId}/${slug}`);
  };

  const previewRanking = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    goToPublicPortal(`rankings/${rankingId}/${slug}`);
  };

  const publishModal = (
    <Modal title={'Notification'} isOpen={publishStep > 0} onClose={closePublishModal}>
      {publishStep === 1 && (
        <>
          <Typography className="mba-font--16">
            <b>Ranking publication</b>
          </Typography>
          <Typography className="mba-font--16">
            You are about to publish {rankName}. This means that the ranking will be visible for anyone.
          </Typography>

          <div className="mba-actions">
            <Button text="Cancel" size={ButtonSizes.big} onClick={(): void => setPublishStep(0)} />
            <Button text="Publish" primary size={ButtonSizes.big} onClick={handlePublish} />
          </div>
        </>
      )}
      {publishStep === 2 && (
        <>
          <Typography className="mba-text--b2b">
            <b>Ranking has been successfully published.</b>
          </Typography>

          <div>
            <button className="mba-button--link" onClick={navigateToRankingsSpreadsheets}>
              Click here to show
            </button>
          </div>

          <div className="mba-actions">
            <Button text="Ok" size={ButtonSizes.big} onClick={(): void => setPublishStep(0)} />
          </div>
        </>
      )}
    </Modal>
  );

  const unPublishedButtons = (
    <>
      <Button text={uploadTitle} onClick={(): void => setIsUploadModalOpen(true)} wrapperClass={'mba-mb-20'} />
      {spreadsheet && (
        <>
          {rankStatus.label !== 'Published' && (
            <Button text="Preview ranking" onClick={previewRanking} wrapperClass={'mba-mb-20'} />
          )}
          {rankStatus.label !== 'Published' && (
            <Button primary text="Publish ranking" onClick={(): void => setPublishStep(1)} />
          )}
        </>
      )}
    </>
  );

  return (
    <>
      <Grid container key={name}>
        <Grid item xs={5}>
          <TimelineStepDates endDate={endDate} endDateLabel="Date" isEndDateRequired={true} />
        </Grid>
        <Grid item xs={1} className="mba-no-padding">
          <TimelineIndicator isLast timeIndication={status} />
        </Grid>
        <Grid item xs={6} className="mba-no-padding">
          <button
            className="mba-text--accent mba-no-margin mba-text--semi-bold mba-timeline-button--link"
            onClick={(): void => setExpand(!expand)}
          >
            {name}
          </button>
          {expand && (
            <>
              <br />
              {spreadsheet && (
                <div className="mba-download-spreadsheet">
                  <Button text={spreadsheet.originalName} icon={ButtonIcons.download} onClick={handleDownload} />
                </div>
              )}

              <br />
              <div className="mba-actions">
                {unPublishedButtons}
                {rankStatus.label === 'Published' && (
                  <Button primary text="Visit ranking page" onClick={navigateToRankingsSpreadsheets} />
                )}
              </div>
              <br />
            </>
          )}
        </Grid>
      </Grid>

      <FileUpload
        title={uploadTitle}
        isLoading={uploadStatus.isLoading}
        isOpen={isUploadModalOpen}
        onClose={closeUploadModal}
        onUpload={uploadRanking}
        errorMessage={(errors['spreadsheet'] && errors.spreadsheet[0]) || errorMessage}
      />

      <Modal title={'Notification'} isOpen={isNotificationOpen}>
        <Typography className="mba-font--16 mba-text--b2b">
          <b>Your file has been successfully uploaded</b>
        </Typography>
        <Typography className="mba-font--16">
          You can update another file if necessary <span className="mba-text--semi-bold">not later than</span>{' '}
          {formatDateString(endDate, 'Do MMM YYYY')}.
        </Typography>

        <div className="mba-actions">
          <Button text="Ok" size={ButtonSizes.big} onClick={closeNotificationModal} />
        </div>
      </Modal>

      {publishModal}
    </>
  );
};

export default PublicationStep;

import { RootState } from '../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const adminSelector = (state: RootState) => state.admin;
const adminUsersSelector = (state: RootState) => state.admin.users;

export const selectCurrentUser = createDeepEqualSelector(adminSelector, (admin) => {
  return admin.user;
});

export const selectUsersTableData = createDeepEqualSelector(adminUsersSelector, (usersList) => {
  return usersList.data;
});

export const selectUsersPageConfiguration = createDeepEqualSelector(adminUsersSelector, (usersList) => {
  return {
    filter: usersList.filter,
    limit: usersList.pagination.limit,
    order: usersList.sortOrder.order,
    page: usersList.pagination.page,
    search: usersList.search,
    sortBy: usersList.sortOrder.sortBy,
    totalPages: usersList.pagination.totalPages,
  };
});

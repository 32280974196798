import { EditorState, convertFromRaw, convertToRaw, RawDraftContentState } from 'draft-js';
import React, { FunctionComponent, useState, useEffect } from 'react';

import { FormControlElementProps } from '../Form.constants';
import RichTextEditor from '../../RichTextEditor';
import { decorator } from '../../RichTextEditor/RichTextEditor.constants';

import 'draft-js/dist/Draft.css';
import Grid from '../../Grid';

type RichTextFieldProps = {
  control: FormControlElementProps;
  inputChangedHandler: (name: string, value: string) => void;
};

const RichTextField: FunctionComponent<RichTextFieldProps> = (props) => {
  return <RichTextEditorWrapper {...props} key={`${props.control.fieldType}-${props.control.id}`} />;
};

const RichTextEditorWrapper: FunctionComponent<RichTextFieldProps> = ({ control, inputChangedHandler }) => {
  const { id, error, fieldType, label, required, touched, valid, validations, value, name, ...elementProps } = control;

  const getState = (value: string): EditorState =>
    value
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(value) as RawDraftContentState), decorator)
      : EditorState.createEmpty(decorator);

  const [editorState, setEditorState] = useState(() => getState(value as string));

  useEffect(() => {
    setEditorState(getState(value as string));
  }, [value]);

  const handleOnChangeOnBlur = (): void => {
    inputChangedHandler(name, JSON.stringify(convertToRaw(editorState.getCurrentContent())));
  };
  return (
    <>
      <div className="o-forms-title">
        <span className="o-forms-title__main">{control.label}</span>
      </div>
      <RichTextEditor
        {...elementProps}
        editorState={editorState}
        onChange={setEditorState}
        onBlur={handleOnChangeOnBlur}
      />
    </>
  );
};

export default RichTextField;

import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import Footer from './Footer';
import Header from './Header';
import { DEFAULT_HOME_ROUTE } from './Layout.constants';
import { logOutUser, selectUserNavigation, VALIDATE_USER_TOKEN } from '../../../Auth';
import Admin from '../../../../Admin';
import Commercial from '../../../../Commercial';
import Home from '../../../../Home';
import Rankings from '../../../../Rankings';
import Schools from '../../../../Schools';
import NotFound from '../../../../../components/NotFound';
import { PageProps } from '../../../../../shared/constants';
import Forbidden from '../../../../../components/Forbidden';

import {
  REQUEST_FAILED_NETWORK,
  selectRequestErrorMessage,
  selectRequestIsLoading,
} from '../../../../../shared/state/global-request';
import ServerUnavailable from '../../../../../components/ServerUnavailable';
import Typography, { TypographyVariants } from '../../../../../components/Typography';

interface RouteComponents {
  [key: string]: FunctionComponent<PageProps>;
}

const routeComponentsMap: RouteComponents = {
  admin: Admin,
  commercial: Commercial,
  home: Home,
  rankings: Rankings,
  schools: Schools,
};

const Layout: FunctionComponent = () => {
  const dispatch = useDispatch();
  const navigation = useSelector(selectUserNavigation);
  const requestNetworkErrorMessage = useSelector(selectRequestErrorMessage(REQUEST_FAILED_NETWORK));
  const { addToast } = useToasts();

  const authRequestIsLoading = useSelector(selectRequestIsLoading(VALIDATE_USER_TOKEN));

  useEffect(() => {
    if (requestNetworkErrorMessage) {
      addToast(requestNetworkErrorMessage, { appearance: 'error' });
    }
  }, [dispatch, requestNetworkErrorMessage, addToast]);

  const handleLogoutClick = (): void => {
    dispatch(logOutUser());
  };

  const loadingComponent = (): React.ReactNode => (
    <Typography variant={TypographyVariants.h1} component={'h1'}>
      Loading...
    </Typography>
  );

  return (
    <>
      <div className="o-layout o-layout--landing" data-o-component="o-layout">
        <div className="o-layout__header">
          <Header
            title="Product & Tech"
            tagline="Business school rankings"
            routes={navigation}
            onLogoutClick={handleLogoutClick}
          />
        </div>
        <div className="o-layout__main o-layout-typography" data-o-component="o-syntax-highlight">
          <Switch>
            {Object.entries(navigation).map(([key, { label, route, navigation }]) => {
              const MainComponent: FunctionComponent<PageProps> = routeComponentsMap[key];
              const renderPage = (): React.ReactNode => (
                <MainComponent label={label} route={route} navigation={navigation} />
              );

              return (
                <Route
                  key={key}
                  exact={route === DEFAULT_HOME_ROUTE}
                  path={route}
                  component={renderPage as FunctionComponent}
                />
              );
            })}
            <Redirect from="/login" to="/" />
            <Route path="/forbidden" component={Forbidden} />
            <Route path="/server-unavailable" component={ServerUnavailable} />
            <Route path="/" exact component={loadingComponent as FunctionComponent} />
            {!authRequestIsLoading && <Route path="*" component={NotFound} />}
          </Switch>
        </div>
        <div className="o-layout__footer">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;

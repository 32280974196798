import { ColumnDefinition, TableHeaderColumnTypes } from '../../../../../components/Table';

export const SCHOOL_RANKINGS_TABLE_COLUMN_DEFINITION: ColumnDefinition[] = [
  {
    id: 'status',
    title: 'Status',
    type: TableHeaderColumnTypes.status,
    sorting: true,
    width: '90px',
  },
  {
    id: 'name',
    sorting: true,
    title: 'Name',
    type: TableHeaderColumnTypes.link,
    path: '/rankings/profile/:id/timeline',
  },
  {
    id: 'publicationDate',
    title: 'Date of ranking',
    type: TableHeaderColumnTypes.date,
    sorting: true,
    width: '160px',
  },
];

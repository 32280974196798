import { RankingSchoolDTO } from './ranking-schools.dto';
import { LOAD_RANKING_SCHOOLS, RankingSchoolsActionTypes, SET_RANKING_SCHOOLS } from './ranking-schools.types';
import { TableListData } from '../../../../../shared/constants';
import { getTableListDefaultState } from '../../../../../shared/helpers';

const initialState: TableListData<RankingSchoolDTO> = getTableListDefaultState<RankingSchoolDTO>();

const rankingSchoolsReducer = (
  state = initialState,
  action: RankingSchoolsActionTypes,
): TableListData<RankingSchoolDTO> => {
  switch (action.type) {
    case LOAD_RANKING_SCHOOLS: {
      return {
        ...state,
        filter: action.payload.pageConfig?.filter || {},
        search: action.payload.pageConfig?.search || '',
      };
    }
    case SET_RANKING_SCHOOLS: {
      return {
        ...state,
        ...action.payload.tableData,
      };
    }
    default: {
      return state;
    }
  }
};

export default rankingSchoolsReducer;

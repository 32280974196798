import {
  CLEAR_CACHE,
  ClearCacheAction,
  LOAD_SETTING,
  LoadSettingAction,
  SET_SETTINGS,
  SetSettingsAction,
  SettingDto,
  UPDATE_SETTING,
  UpdateSettingAction,
} from './settings.types';

export const loadSettingAction = (key: string): LoadSettingAction => ({
  type: LOAD_SETTING,
  payload: { key },
});

export const setSettingsAction = (data: SettingDto): SetSettingsAction => ({
  type: SET_SETTINGS,
  payload: data,
});

export const updateSettingAction = (data: SettingDto): UpdateSettingAction => ({
  type: UPDATE_SETTING,
  payload: data,
});

export const clearCacheAction = (): ClearCacheAction => ({
  type: CLEAR_CACHE,
});

import React, { FunctionComponent, MouseEvent, ReactNode, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import EmbargoTableStep from './components/EmbargoTableStep';
import ParticipationStep from './components/ParticipationStep';
import PublicationStep from './components/PublicationStep';
import SpreadsheetStep from './components/SpreadsheetStep';
import SchoolSurveyStep from './components/SchoolSurveyStep';
import SurveyStep from './components/SurveyStep';
import { getTimelineIndicatorStatus } from './RankingTimeline.helpers';
import {
  loadRankingTimelineStepsAction,
  RankingTimelineStepDTO,
  selectCurrentTimelineSteps,
} from '../RankingDetails/TimelineSteps';
import { RankingDTO, selectCurrentRanking } from '../RankingInfo';
import Button, { ButtonSizes } from '../../../../../components/Button';
import { TimelineStepStatus } from './components/TimelineIndicator';
import { ScheduleRankingModal } from './components/ScheduleRankingModal';
import Modal from '../../../../../components/Modal';
import Grid from '../../../../../components/Grid';
import { deleteRankingSpreadsheetRequest } from '../Spreadsheets/spreadsheets.api';

import styles from './RankingTimeline.module.scss';
import AlumniSurveyDataStep from './components/AlumniSurveyDataStep';
import AlumniSurveyLetterStep from './components/AlumniSurveyLetterStep';

const DELETE_SPREADSHEET_CONFIRMATION = 'All spreadsheets successfully deleted';
const DELETE_WARNING_TEXT = 'Are you sure you want to delete all spreadsheets?';

interface DeleteModalProps {
  infoText: string;
  openModal: boolean;
  type: string;
  isDeleted: boolean;
}

const defaultModalProps = {
  openModal: false,
  type: '',
  isDeleted: false,
  infoText: DELETE_WARNING_TEXT,
};

const RankingTimeline: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: rankingId, slug, publicationDate } = useSelector(selectCurrentRanking) as RankingDTO;
  const timelineSteps = useSelector(selectCurrentTimelineSteps) as RankingTimelineStepDTO[];
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [deleteModalProps, setDeleteModalProps] = useState<DeleteModalProps>({ ...defaultModalProps });

  useEffect(() => {
    if (rankingId) {
      dispatch(loadRankingTimelineStepsAction(rankingId));
    }
  }, [dispatch, rankingId]);

  const navigateToTimelineEdit = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    history.push(`/rankings/profile/${rankingId}/details/instructions-and-deadlines`);
  };

  const toggleModal = (open: boolean): void => {
    setIsModalOpened(open);
  };

  const alertDeleteSpreadsheets = (spreadsheetType: string): void => {
    setDeleteModalProps({ ...defaultModalProps, openModal: true, type: spreadsheetType });
  };

  const handleDeleteSpreadsheets = (): void => {
    deleteRankingSpreadsheetRequest(rankingId, deleteModalProps.type).then(() => {
      dispatch(loadRankingTimelineStepsAction(rankingId));
      setDeleteModalProps({ ...deleteModalProps, infoText: DELETE_SPREADSHEET_CONFIRMATION, isDeleted: true });
    });
  };

  const closeDeleteModal = (): void => {
    setDeleteModalProps(defaultModalProps);
  };

  const steps = useMemo(
    () =>
      Object.values(timelineSteps).map((stepData: RankingTimelineStepDTO, index): ReactNode => {
        const { isOptional } = stepData;
        const data = {
          ...stepData,
          rankingId,
          slug,
        };

        switch (stepData.id) {
          case 1:
            return (
              !isOptional && (
                <ParticipationStep
                  key={`timeline-step-${index}`}
                  {...data}
                  status={getTimelineIndicatorStatus(data.endDate)}
                />
              )
            );
          case 2:
            return (
              !isOptional && (
                <SpreadsheetStep
                  key={`timeline-step-${index}`}
                  {...data}
                  spreadsheetType="alumni"
                  status={getTimelineIndicatorStatus(data.endDate)}
                  onDeleteSpreadsheets={alertDeleteSpreadsheets}
                />
              )
            );
          case 3:
            return (
              !isOptional && (
                <SpreadsheetStep
                  {...data}
                  spreadsheetType="faculty"
                  key={`timeline-step-${index}`}
                  status={getTimelineIndicatorStatus(data.endDate)}
                  onDeleteSpreadsheets={alertDeleteSpreadsheets}
                />
              )
            );
          case 4:
            return (
              !isOptional && (
                <SpreadsheetStep
                  {...data}
                  spreadsheetType="research"
                  key={`timeline-step-${index}`}
                  status={getTimelineIndicatorStatus(data.endDate)}
                  onDeleteSpreadsheets={alertDeleteSpreadsheets}
                />
              )
            );
          case 5:
            return (
              !isOptional && (
                <SchoolSurveyStep
                  {...data}
                  surveyType="school"
                  key={`timeline-step-${index}`}
                  status={getTimelineIndicatorStatus(data.endDate, data.startDate)}
                />
              )
            );
          case 6:
            return (
              !isOptional && (
                <SurveyStep
                  {...data}
                  surveyType="alumni"
                  key={`timeline-step-${index}`}
                  status={getTimelineIndicatorStatus(data.endDate, data.startDate)}
                />
              )
            );
          case 7:
            return (
              <EmbargoTableStep
                {...data}
                key={`timeline-step-${index}`}
                status={getTimelineIndicatorStatus(data.endDate, data.startDate)}
              />
            );
          case 8:
            return (
              <PublicationStep
                {...data}
                key={`timeline-step-${index}`}
                endDate={publicationDate}
                status={
                  getTimelineIndicatorStatus(publicationDate) !== TimelineStepStatus.completed
                    ? TimelineStepStatus.upcoming
                    : TimelineStepStatus.completed
                }
              />
            );
          case 9:
            return !isOptional && <AlumniSurveyDataStep {...data} withDateMarker />;
          case 10:
            return !isOptional && <AlumniSurveyDataStep {...data} />;
          case 11:
            return !isOptional && <AlumniSurveyLetterStep {...data} />;
          default:
            return null;
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timelineSteps, publicationDate, rankingId],
  );

  return (
    <>
      <div className={['mba-text--center', styles.timelineWrapper].join(' ')}>
        <div className={styles.buttonWrapper}>
          <div className={styles.actionButtons}>
            <Button size={ButtonSizes.big} text="Edit timeline" onClick={navigateToTimelineEdit} />
            <br />
            <br />
            <Button size={ButtonSizes.big} text="Schedule ranking" onClick={(): void => toggleModal(true)} />
          </div>
        </div>
        <section className={styles.timeline}>{steps}</section>
      </div>
      <ScheduleRankingModal toggleModal={toggleModal} isModalOpened={isModalOpened} />
      <Modal
        title={`Delete ${deleteModalProps.type} spreadsheets`}
        isOpen={deleteModalProps.openModal}
        onClose={closeDeleteModal}
      >
        <p>{deleteModalProps.infoText}</p>
        <Grid item md={12} xs={12} className="mba-no-padding mba-mt-20">
          <Button text="Cancel" size={ButtonSizes.big} onClick={closeDeleteModal} wrapperClass="mba-mr-10" />
          <Button
            disabled={deleteModalProps.isDeleted}
            text="Yes"
            size={ButtonSizes.big}
            danger
            onClick={handleDeleteSpreadsheets}
          />
        </Grid>
      </Modal>
    </>
  );
};

export default RankingTimeline;

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button, { ButtonSizes, ButtonIcons } from '../../../../../../../../components/Button';
import Modal from '../../../../../../../../components/Modal';
import Pager, { INITIAL_PAGE } from '../../../../../../../../components/Pager';
import Table, { TableHeaderColumnTypes } from '../../../../../../../../components/Table';
import Typography, { TypographyVariants } from '../../../../../../../../components/Typography';
import { ASCENDING_ORDER_DIRECTION, DESCENDING_ORDER_DIRECTION } from '../../../../../../../../shared/constants';
import { RankingDTO, selectCurrentRanking } from '../../../../RankingInfo';
import { loadRankingFieldsAction } from '../../ranking-fields-list.actions';
import { RankingFieldDTO } from '../../ranking-fields-list.dto';
import { selectRankingFieldsPageConfiguration, selectRankingFieldsData } from '../../ranking-fields-list.selectors';
import RankingFieldForm from '../RankingFieldForm';
import { RANKING_DATA_TABLE_COLUMN_DEFINITION } from './RankingFieldsList.constants';

const RankingFieldsList: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id: rankingId } = useSelector(selectCurrentRanking) as RankingDTO;
  const pageConfig = useSelector(selectRankingFieldsPageConfiguration);
  const rankingFieldsData = useSelector(selectRankingFieldsData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentRankingField, setCurrentRankingField] = useState<RankingFieldDTO | undefined>(undefined);

  useEffect(() => {
    if (rankingId) {
      dispatch(loadRankingFieldsAction(rankingId));
    }
  }, [dispatch, rankingId]);

  useEffect(() => {
    if (isModalOpen) {
      closeModal();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rankingFieldsData]);

  const openPopup = (selectedId?: number): void => {
    if (selectedId !== undefined) {
      const selectedRankingField = rankingFieldsData.find(({ id }) => id === selectedId);
      if (selectedRankingField) {
        setCurrentRankingField(selectedRankingField);
      }
    }
    setIsModalOpen(true);
  };

  const closeModal = (): void => {
    setIsModalOpen(false);
    setTimeout(() => setCurrentRankingField(undefined), 300);
  };

  const rankingDataTableConfig = [
    ...RANKING_DATA_TABLE_COLUMN_DEFINITION,
    {
      id: 'actions',
      title: 'Action',
      type: TableHeaderColumnTypes.actions,
      width: '90px',
      actions: [
        {
          label: 'Edit',
          handleOnClick: openPopup,
        },
      ],
    },
  ].map(({ path, ...tableColumn }) => ({ ...tableColumn, path: path?.replace(':rankingId', rankingId.toString()) }));

  const handlePageChanged = (page: number): void => {
    dispatch(loadRankingFieldsAction(rankingId, { ...pageConfig, page }));
  };

  const handleSortByChanged = (fieldName: string): void => {
    // TODO
    // Duplicate code found in 15 files
    // should be added as a Helper function
    // example usage : const orderDirection = getSortDirection(fileName, pageConfig)
    const orderDirection =
      pageConfig.sortBy !== fieldName
        ? DESCENDING_ORDER_DIRECTION
        : pageConfig.order === ASCENDING_ORDER_DIRECTION
        ? DESCENDING_ORDER_DIRECTION
        : ASCENDING_ORDER_DIRECTION;
    dispatch(loadRankingFieldsAction(rankingId, { ...pageConfig, sortBy: fieldName, order: orderDirection }));
  };

  return (
    <>
      <div className="mba-heading--wrapper mba-heading--table">
        <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title-2">
          Columns & Filters
        </Typography>
        <Button
          text="Create new"
          size={ButtonSizes.big}
          icon={ButtonIcons.plus}
          disabled={isModalOpen}
          primary
          onClick={(): void => openPopup()}
        />
      </div>
      <div className="mba-table-wrapper">
        <Table
          id="rankings-data-list"
          columns={rankingDataTableConfig}
          data={rankingFieldsData}
          sortingOptions={{
            order: pageConfig.order || DESCENDING_ORDER_DIRECTION,
            sortBy: pageConfig.sortBy || '',
          }}
          onSortByColumn={handleSortByChanged}
        />
      </div>
      {pageConfig && pageConfig.totalPages !== INITIAL_PAGE && (
        <Pager
          currentPage={pageConfig.page}
          totalPages={pageConfig.totalPages || INITIAL_PAGE}
          onPageChange={handlePageChanged}
        />
      )}
      <Modal title={`${currentRankingField ? 'Edit' : 'Create new'}`} isOpen={isModalOpen} onClose={closeModal}>
        <RankingFieldForm
          onFormClose={closeModal}
          key={JSON.stringify(currentRankingField)}
          rankingField={currentRankingField}
        />
      </Modal>
    </>
  );
};

export default RankingFieldsList;

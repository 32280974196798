import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import Button, { ButtonSizes } from '../../../../../../components/Button';
import Typography from '../../../../../../components/Typography';
import { selectRequestIsLoading } from '../../../../../../shared/state/global-request';
import { DELETE_CAMPUS } from '../../../CampusProfile';
import { CAMPUS_CONFIRMATION_MESSAGE, DEFAULT_CAMPUS_CONFIRMATION_MESSAGE } from './RemoveCampusPopup.constants';

type RemoveCampusPopupProps = {
  onCancel: () => void;
  onConfirm: (id: number) => void;
  campusId: number;
  isDefaultCampus: boolean;
};

const getConfirmationMessage = (isDefaultCampus: boolean): string => {
  return isDefaultCampus ? DEFAULT_CAMPUS_CONFIRMATION_MESSAGE : CAMPUS_CONFIRMATION_MESSAGE;
};

const RemoveCampusPopup: FunctionComponent<RemoveCampusPopupProps> = ({
  campusId,
  isDefaultCampus,
  onCancel,
  onConfirm,
}) => {
  const isLoading = useSelector(selectRequestIsLoading(DELETE_CAMPUS));

  return (
    <>
      <Typography>{getConfirmationMessage(isDefaultCampus)}</Typography>
      <div className="mba-actions">
        <Button onClick={onCancel} text="Cancel" size={ButtonSizes.big} />
        <Button
          primary
          text="Yes"
          size={ButtonSizes.big}
          isLoading={isLoading}
          onClick={(): void => onConfirm(campusId)}
        />
      </div>
    </>
  );
};

export default RemoveCampusPopup;

import { LOAD_SCHOOLS, LoadSchoolsAction, SET_SCHOOLS, SetSchoolsAction } from './schools.types';
import { PageSearchQueryConfig, TableListData } from '../../../shared/constants';
import { SchoolDTO } from '../SchoolProfile/SchoolDetails';

export const loadSchoolsAction = (pageConfig?: PageSearchQueryConfig): LoadSchoolsAction => ({
  type: LOAD_SCHOOLS,
  payload: { pageConfig },
});

export const setSchoolsAction = (tableData: TableListData<SchoolDTO>): SetSchoolsAction => ({
  type: SET_SCHOOLS,
  payload: { tableData },
});

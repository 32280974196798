import { RootState } from '../../../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const rankingSchoolsSelector = (state: RootState) => state.rankings.schools;

export const selectRankingSchoolsTableData = createDeepEqualSelector(rankingSchoolsSelector, (schools) => {
  return schools.data;
});

export const selectRankingSchoolsPageConfiguration = createDeepEqualSelector(rankingSchoolsSelector, (schools) => {
  return {
    filter: schools.filter,
    limit: schools.pagination.limit,
    order: schools.sortOrder.order,
    page: schools.pagination.page,
    search: schools.search,
    sortBy: schools.sortOrder.sortBy,
    totalPages: schools.pagination.totalPages,
  };
});

import { NavigationConfiguration, PageConfiguration } from '../../App/Main/components/Layout';

export const ONSITE_MESSAGING_NAV: PageConfiguration = {
  label: 'Onsite Messaging',
  route: '/onsite-messaging',
};

export const ONSITE_MESSAGING_SUB_NAVIGATION: NavigationConfiguration = {
  active: {
    exact: true,
    label: 'Active',
    route: '/active',
  },
  history: {
    exact: true,
    label: 'History',
    route: '/history',
  },
};

export const ONSITE_MESSAGING_MAIN_ROUTE = '/admin/onsite-messaging';

import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

import { setSchoolRankingAction } from './school-rankings.actions';
import { getSchoolRankingRequest } from './school-rankings.api';
import { SchoolRankingsDto } from './school-rankings.dto';
import { LOAD_SCHOOL_RANKINGS, LoadSchoolRankingsAction, SchoolRankingsActionTypes } from './school-rankings.types';
import { PagedResponseDTO } from '../../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../../shared/state/global-request';

function* loadSchoolRankingsTableData(
  action: LoadSchoolRankingsAction,
): Generator<
  | CallEffect<PagedResponseDTO<SchoolRankingsDto[]>>
  | PutEffect<SchoolRankingsActionTypes>
  | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig, schoolId } = action.payload;
    const { data, pagination, sortOrder } = (yield call(
      getSchoolRankingRequest,
      schoolId,
      pageConfig,
    )) as PagedResponseDTO<SchoolRankingsDto[]>;
    yield put(setSchoolRankingAction({ data, pagination, sortOrder }));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* SchoolRankingsSaga(): Generator {
  yield takeLatest(LOAD_SCHOOL_RANKINGS, loadSchoolRankingsTableData);
}

import { SchoolRankingsDto } from './school-rankings.dto';
import { LOAD_SCHOOL_RANKINGS, SchoolRankingsActionTypes, SET_SCHOOL_RANKINGS } from './school-rankings.types';
import { TableListData } from '../../../../shared/constants';
import { getTableListDefaultState } from '../../../../shared/helpers';

const initialState: TableListData<SchoolRankingsDto> = getTableListDefaultState<SchoolRankingsDto>();

const schoolRankingReducer = (
  state = initialState,
  action: SchoolRankingsActionTypes,
): TableListData<SchoolRankingsDto> => {
  switch (action.type) {
    case LOAD_SCHOOL_RANKINGS: {
      return {
        ...state,
        filter: action.payload.pageConfig?.filter || {},
        search: action.payload.pageConfig?.search || '',
      };
    }
    case SET_SCHOOL_RANKINGS: {
      return {
        ...state,
        ...action.payload.tableData,
      };
    }
    default: {
      return state;
    }
  }
};

export default schoolRankingReducer;

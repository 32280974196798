import { RankingSpreadsheetDTO } from './spreadsheets.dto';
import { get, del } from '../../../../../shared/api';
import { BasicResponseDTO, PagedResponseDTO, PageSearchQueryConfig } from '../../../../../shared/constants';
import { generateSearchQuery } from '../../../../../shared/helpers';

export const getRankingSpreadsheetsRequest = (
  rankingId: number,
  type: string,
  pageConfig: PageSearchQueryConfig = {},
): Promise<PagedResponseDTO<RankingSpreadsheetDTO[]>> => {
  let query = generateSearchQuery(pageConfig);
  query = query ? `${query}&type=${type}` : `?type=${type}`;
  return get<PagedResponseDTO<RankingSpreadsheetDTO[]>>(`/rankings/${rankingId}/schools/spreadsheets${query}`);
};

export const downloadRankingSpreadsheetRequest = (
  rankingId: number,
  type: string,
  pageConfig: PageSearchQueryConfig = {},
): Promise<BasicResponseDTO> => {
  let query = generateSearchQuery(pageConfig);
  query = query ? `${query}&type=${type}` : `?type=${type}`;
  return get<BasicResponseDTO>(`/rankings/${rankingId}/schools/spreadsheets/download${query}&includeSchoolInfo=1`, {
    responseType: 'blob',
  });
};

export const deleteSpreadsheetRequest = (rankingId: number, id: string | undefined): Promise<BasicResponseDTO> => {
  return del<BasicResponseDTO>(`/rankings/${rankingId}/spreadsheets/by-id/${id}`);
};

export const deleteAllSpreadsheetsRequest = (rankingId: number, type: string): Promise<BasicResponseDTO> => {
  return del<BasicResponseDTO>(`/rankings/${rankingId}/spreadsheets/${type}`);
};

export const downloadRankingSpreadsheetContactsRequest = (
  rankingId: number,
  type: string,
  pageConfig: PageSearchQueryConfig = {},
): Promise<BasicResponseDTO> => {
  let query = generateSearchQuery(pageConfig);
  query = query ? `${query}&type=${type}` : `?type=${type}`;
  return get<BasicResponseDTO>(
    `/rankings/${rankingId}/schools/participant/contacts/download${query}&includeSchoolInfo=1`,
    {
      responseType: 'blob',
    },
  );
};

export const deleteRankingSpreadsheetRequest = (rankingId: number, type: string): Promise<BasicResponseDTO> => {
  return del<BasicResponseDTO>(`/rankings/${rankingId}/spreadsheets/${type}`);
};

import moment from 'moment-timezone';

import { TimelineStepStatus } from './components/TimelineIndicator';
import {
  MAX_DAYS_FOR_ACTIVE_MEDIUM_STATUS,
  MAX_DAYS_FOR_ACTIVE_SHORT_STATUS,
  MIN_DAYS_FOR_ACTIVE_STATUS,
} from './RankingTimeline.constants';

export const getTimelineIndicatorStatus = (end?: string, start?: string): TimelineStepStatus => {
  const now = moment();

  if (start && end) {
    const startDate = moment(start);

    return now.isBefore(startDate) ? TimelineStepStatus.upcoming : getTimelineIndicatorStatus(end);
  } else if (end) {
    const endDate = moment(end);
    const dayDifference = endDate.diff(now, 'days');

    switch (true) {
      case dayDifference >= MIN_DAYS_FOR_ACTIVE_STATUS && dayDifference <= MAX_DAYS_FOR_ACTIVE_SHORT_STATUS:
        return TimelineStepStatus['active-short'];
      case dayDifference > MAX_DAYS_FOR_ACTIVE_SHORT_STATUS && dayDifference <= MAX_DAYS_FOR_ACTIVE_MEDIUM_STATUS:
        return TimelineStepStatus['active-medium'];
      case dayDifference > MAX_DAYS_FOR_ACTIVE_MEDIUM_STATUS:
        return TimelineStepStatus['active-long'];
      case dayDifference < MIN_DAYS_FOR_ACTIVE_STATUS:
        return TimelineStepStatus.completed;
      default:
        return TimelineStepStatus.upcoming;
    }
  }

  return TimelineStepStatus.upcoming;
};

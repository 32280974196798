import React, { ChangeEvent, FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import SearchInput from '../../../../../components/SearchInput';
import { PageSearchQueryConfig } from '../../../../../shared/constants';
import DatePicker from '../../../../../components/DatePicker';
import { loadLogsAction } from '../../logs.actions';
import moment from 'moment';

type FiltersAndSearchProps = {
  pageConfig: PageSearchQueryConfig;
};

const LogsFiltersAndSearch: FunctionComponent<FiltersAndSearchProps> = ({ pageConfig }) => {
  const dispatch = useDispatch();
  const { filter, search } = pageConfig;

  const handleSearchChange = (newPageConfig: PageSearchQueryConfig): void => {
    dispatch(loadLogsAction(newPageConfig));
  };

  const handleDateFiltersChange = (event: ChangeEvent<HTMLInputElement>): void => {
    let newFilter = { ...filter };
    const { name, value } = event.target;
    if (value) {
      newFilter = {
        ...filter,
        [name]: [moment(value).toISOString()],
      };
    } else {
      delete newFilter[name];
    }
    dispatch(
      loadLogsAction({
        ...pageConfig,
        filter: newFilter,
      }),
    );
  };

  return (
    <div className="sticky-sidebar-container">
      <SearchInput
        id="logs-search"
        pageConfig={pageConfig}
        placeholder="Search"
        onSearchChanged={handleSearchChange}
        searchTerm={search}
      />

      <DatePicker
        title="Date from"
        placeholder="DD/MM/YY"
        name="from"
        onChange={handleDateFiltersChange}
        showTimeSelect={true}
        type="dateTime"
        timeIntervals={1}
      />
      <DatePicker
        title="Date to"
        placeholder="DD/MM/YY"
        name="to"
        onChange={handleDateFiltersChange}
        showTimeSelect={true}
        type="dateTime"
        timeIntervals={1}
      />
    </div>
  );
};

export default LogsFiltersAndSearch;

import React, { FunctionComponent, MouseEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import TimelineIndicator from './TimelineIndicator';
import TimelineProgressBar from './TimelineProgressBar';
import TimelineStepDates from './TimelineStepDates';
import { RankingTimelineStepBaseProps, TimelineExpandStepStatus } from '../RankingTimeline.constants';
import Button from '../../../../../../components/Button';
import Grid from '../../../../../../components/Grid';

const ParticipationStep: FunctionComponent<RankingTimelineStepBaseProps> = ({
  endDate,
  name,
  progress,
  rankingId,
  status,
}) => {
  const history = useHistory();
  const [expand, setExpand] = useState<boolean>(false);

  const navigateToRankingsSchools = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    history.push(`/rankings/profile/${rankingId}/schools`);
  };

  useEffect(() => {
    setExpand(TimelineExpandStepStatus[status]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <Grid container key={name}>
      <Grid item xs={5}>
        <TimelineStepDates endDate={endDate} endDateLabel="Deadline" isEndDateRequired={true} />
      </Grid>
      <Grid item xs={1} className="mba-no-padding">
        <TimelineIndicator timeIndication={status} />
      </Grid>
      <Grid item xs={6} className="mba-no-padding">
        <button
          className="mba-text--accent mba-no-margin mba-text--semi-bold mba-timeline-button--link"
          onClick={(): void => setExpand(!expand)}
        >
          {name}
        </button>
        {expand && (
          <>
            <div>
              <TimelineProgressBar label="Schools confirmed participation" progress={progress} />
            </div>
            <br />
            <div className="mba-actions">
              <Button primary text="Go to participants" onClick={navigateToRankingsSchools} />
            </div>
            <br />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ParticipationStep;

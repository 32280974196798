import { ImportSchoolUsersDTO } from './school-users.dto';
import {
  IMPORT_SCHOOL_USERS,
  ImportSchoolUsersAction,
  LOAD_SCHOOL_USERS_FOR_SCHOOL,
  LoadSchoolUsersForSchoolAction,
  SET_SCHOOL_USERS_FOR_SCHOOL,
  SetSchoolUsersForSchoolAction,
} from './school-users.types';
import { SchoolUserDTO } from '../../SchoolUserProfile';
import { PageSearchQueryConfig, TableListData } from '../../../../shared/constants';

export const loadSchoolUsersForSchoolAction = (
  schoolId: number,
  pageConfig?: PageSearchQueryConfig,
): LoadSchoolUsersForSchoolAction => ({
  type: LOAD_SCHOOL_USERS_FOR_SCHOOL,
  payload: { pageConfig, schoolId },
});

export const setSchoolUsersForSchoolAction = (
  tableData: TableListData<SchoolUserDTO>,
): SetSchoolUsersForSchoolAction => ({
  type: SET_SCHOOL_USERS_FOR_SCHOOL,
  payload: { tableData },
});

export const importSchoolUsersAction = (data: ImportSchoolUsersDTO, schoolId: number): ImportSchoolUsersAction => ({
  type: IMPORT_SCHOOL_USERS,
  payload: { data, schoolId },
});

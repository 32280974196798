import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UserFiltersAndSearch from './UserFiltersAndSearch';
import UserForm from './UserForm';
import { USERS_TABLE_COLUMN_DEFINITION, USER_CREATION_CONFIRM_MSG } from './UsersList.constants';
import { deleteUserAction, loadUsersAction } from '../users.actions';
import { UserDTO } from '../users.dto';
import { selectUsersPageConfiguration, selectUsersTableData } from '../users.selectors';
import { CREATE_USER, DELETE_USER } from '../users.types';
import Button, { ButtonIcons, ButtonSizes } from '../../../../components/Button';
import Modal, { MODAL_ANIMATION_DURATION } from '../../../../components/Modal';
import Pager, { INITIAL_PAGE } from '../../../../components/Pager';
import Table, { TableHeaderColumnTypes } from '../../../../components/Table';
import Typography, { TypographyVariants } from '../../../../components/Typography';
import UserDeleteConfirmation from '../../../../components/UserDeleteConfirmation';
import { ASCENDING_ORDER_DIRECTION, DESCENDING_ORDER_DIRECTION } from '../../../../shared/constants';
import {
  clearGivenRequestAction,
  selectAreRequestsLoading,
  selectRequestErrorMessage,
  selectRequestStatus,
} from '../../../../shared/state/global-request';
import { loadRolesAction, selectRoles } from '../../../../shared/state/roles';
import { loadStatusesAction, selectStatuses } from '../../../../shared/state/statuses';
import Message from '../../../../components/Message';

const UsersList: FunctionComponent = () => {
  const dispatch = useDispatch();
  const pageConfig = useSelector(selectUsersPageConfiguration);
  const usersData = useSelector(selectUsersTableData);
  const isDeleteInProgress = useSelector(selectAreRequestsLoading([DELETE_USER]));
  const errorsFromDeleteAction = useSelector(selectRequestErrorMessage(DELETE_USER));

  const userRoles = useSelector(selectRoles('users'));
  const userStatuses = useSelector(selectStatuses('user'));

  const createUserReqStatus = useSelector(selectRequestStatus(CREATE_USER));

  const [isUserEdit, setIsUserEdit] = useState(false);
  const [currentUser, setCurrentUser] = useState({} as UserDTO);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isConfirmationMsgShown, setIsConfirmationMsgShown] = useState(false);

  useEffect(() => {
    dispatch(loadRolesAction('users'));
    dispatch(loadStatusesAction('user'));
    dispatch(loadUsersAction());
  }, [dispatch]);

  useEffect(() => {
    closeModal();
    closeDeleteModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersData]);

  useEffect(() => {
    if (!createUserReqStatus.isLoading && createUserReqStatus.isSuccess) {
      closeModal();
      const showMsgTimeout = setTimeout(showConfirmationMsg, 1000);
      const hideMsgTimeout = setTimeout(hideConfirmationMsg, 3000);
      return () => {
        clearTimeout(showMsgTimeout);
        clearTimeout(hideMsgTimeout);
      };
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createUserReqStatus]);

  const handlePageChanged = (page: number): void => {
    dispatch(loadUsersAction({ ...pageConfig, page }));
  };

  const handleSortByChanged = (fieldName: string): void => {
    const orderDirection =
      pageConfig.sortBy !== fieldName
        ? DESCENDING_ORDER_DIRECTION
        : pageConfig.order === ASCENDING_ORDER_DIRECTION
        ? DESCENDING_ORDER_DIRECTION
        : ASCENDING_ORDER_DIRECTION;
    dispatch(loadUsersAction({ ...pageConfig, sortBy: fieldName, order: orderDirection }));
  };

  const openModal = (id?: number): void => {
    if (id) {
      const currentUser = usersData.find((user: UserDTO) => user.id === id);
      if (currentUser) {
        setCurrentUser(currentUser);
        setIsUserEdit(true);
      } else {
        setIsUserEdit(false);
      }
    }

    setIsModalOpen(true);
  };

  const openDeleteModal = (id?: number): void => {
    const currentUser = usersData.find((user: UserDTO) => user.id === id);
    if (currentUser) {
      setCurrentUser(currentUser);
      setIsDeleteModalOpen(true);
    }
  };

  const closeDeleteModal = (): void => {
    setIsDeleteModalOpen(false);
    clearCurrentUser();
  };

  const onConfirmDelete = (): void => {
    const { id } = currentUser as UserDTO;
    dispatch(deleteUserAction(id));
  };

  const closeModal = (): void => {
    setIsModalOpen(false);
    clearCurrentUser();
  };

  const clearCurrentUser = (): void => {
    setCurrentUser({} as UserDTO);
  };

  const userTableConfig = [
    ...USERS_TABLE_COLUMN_DEFINITION,
    {
      id: 'actions',
      title: 'Action',
      type: TableHeaderColumnTypes.actions,
      width: '150px',
      actions: [
        {
          label: 'Edit',
          handleOnClick: openModal,
        },
        {
          label: 'Delete',
          handleOnClick: openDeleteModal,
        },
      ],
    },
  ];

  const showConfirmationMsg = () => {
    setIsConfirmationMsgShown(true);
  };

  const hideConfirmationMsg = () => {
    setIsConfirmationMsgShown(false);
    dispatch(clearGivenRequestAction(CREATE_USER));
  };

  return (
    <>
      <div className="mba-heading--wrapper">
        <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title">
          User roles
        </Typography>
        {isConfirmationMsgShown && <Message messageContent={USER_CREATION_CONFIRM_MSG} onClose={hideConfirmationMsg} />}
        <Button
          text="Create new user"
          size={ButtonSizes.big}
          icon={ButtonIcons.plus}
          disabled={isModalOpen}
          primary
          onClick={openModal as any}
        />
      </div>
      <div className="mba-table-wrapper">
        <div className="mba-table-filters">
          <UserFiltersAndSearch pageConfig={pageConfig} filtersData={{ userStatuses, userRoles }} />
        </div>
        <div className="mba-table-container">
          <Table
            id="admin-users"
            columns={userTableConfig}
            data={usersData}
            sortingOptions={{
              order: pageConfig.order || DESCENDING_ORDER_DIRECTION,
              sortBy: pageConfig.sortBy || '',
            }}
            onSortByColumn={handleSortByChanged}
          />
        </div>
      </div>
      {pageConfig && pageConfig.totalPages !== INITIAL_PAGE && (
        <Pager
          currentPage={pageConfig.page}
          totalPages={pageConfig.totalPages || INITIAL_PAGE}
          onPageChange={handlePageChanged}
        />
      )}
      {isModalOpen && (
        <Modal title={`${isUserEdit ? 'Edit' : 'Create new'} user`} isOpen={isModalOpen} onClose={closeModal}>
          <UserForm onFormClose={closeModal} currentUser={currentUser} isEdit={isUserEdit} />
        </Modal>
      )}

      {isDeleteModalOpen && (
        <Modal title="Delete user" isOpen={isDeleteModalOpen} onClose={closeDeleteModal}>
          <UserDeleteConfirmation
            onConfirmDelete={onConfirmDelete}
            onRejectDelete={closeDeleteModal}
            isLoading={isDeleteInProgress}
            errorMessage={errorsFromDeleteAction}
            userName={currentUser ? currentUser.name : ''}
          />
        </Modal>
      )}
    </>
  );
};

export default UsersList;

import { AddSchoolsToRankingDTO, RankingSchoolDTO, UpdateParticipationStatusDTO } from './ranking-schools.dto';
import {
  ADD_SCHOOLS_TO_RANKING,
  AddSchoolsToRankingAction,
  DOWNLOAD_PARTICIPATION_SPREADSHEET,
  DownloadParticipationSpreadsheetAction,
  IMPORT_SCHOOLS_TO_RANKING,
  ImportSchoolsToRankingAction,
  LOAD_RANKING_SCHOOLS,
  LoadRankingSchoolsAction,
  SET_RANKING_SCHOOLS,
  SetRankingSchoolsAction,
  UPDATE_PARTICIPATION_STATUS,
  UpdateParticipationStatusAction,
} from './ranking-schools.types';
import { PageSearchQueryConfig, TableListData } from '../../../../../shared/constants';

export const loadRankingSchoolsAction = (
  rankingId: number,
  pageConfig?: PageSearchQueryConfig,
): LoadRankingSchoolsAction => ({
  type: LOAD_RANKING_SCHOOLS,
  payload: { rankingId, pageConfig },
});

export const setRankingSchoolsAction = (tableData: TableListData<RankingSchoolDTO>): SetRankingSchoolsAction => ({
  type: SET_RANKING_SCHOOLS,
  payload: { tableData },
});

export const downloadParticipationSpreadsheetAction = (
  rankingId: number,
  pageConfig?: PageSearchQueryConfig,
): DownloadParticipationSpreadsheetAction => ({
  type: DOWNLOAD_PARTICIPATION_SPREADSHEET,
  payload: { rankingId, pageConfig },
});

export const addSchoolsToRankingAction = (
  data: AddSchoolsToRankingDTO,
  rankingId: number,
): AddSchoolsToRankingAction => ({
  type: ADD_SCHOOLS_TO_RANKING,
  payload: { data, rankingId },
});

export const importSchoolsToRankingAction = (rankingId: number): ImportSchoolsToRankingAction => ({
  type: IMPORT_SCHOOLS_TO_RANKING,
  payload: { rankingId },
});

export const updateParticipationStatusAction = (
  rankingId: number,
  schoolId: number,
  data: UpdateParticipationStatusDTO,
): UpdateParticipationStatusAction => ({
  type: UPDATE_PARTICIPATION_STATUS,
  payload: { rankingId, schoolId, data },
});

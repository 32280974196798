import { call, CallEffect, put, PutEffect, SelectEffect, takeLatest } from 'redux-saga/effects';
import { BasicResponseDTO, DefaultResponseDTO } from '../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../shared/state/global-request';
import {
  EDIT_PRIVACY_DATA,
  EditPrivacyAction,
  LOAD_PRIVACY_DATA,
  LoadPrivacyAction,
  PrivacyActionTypes,
} from './privacy.types';
import { setPrivacyAction } from './privacy.actions';
import { getPrivacyRequest, updatePrivacyRequest } from './privacy.api';

function* loadPrivacySaga(
  action: LoadPrivacyAction,
): Generator<CallEffect<DefaultResponseDTO<string>> | PutEffect<GlobalRequestActions | PrivacyActionTypes>> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getPrivacyRequest)) as DefaultResponseDTO<string>;
    yield put(setPrivacyAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* editPrivacySaga(
  action: EditPrivacyAction,
): Generator<PutEffect<GlobalRequestActions | PrivacyActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect> {
  const { type: actionType, payload: data } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updatePrivacyRequest, data);
    yield put(setRequestSucceededAction(actionType));
    yield put(setPrivacyAction(data.description as string));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* privacySaga(): Generator {
  yield takeLatest(LOAD_PRIVACY_DATA, loadPrivacySaga);
  yield takeLatest(EDIT_PRIVACY_DATA, editPrivacySaga);
}

import {
  CLEAR_CURRENT_RANKING_CATEGORY,
  CREATE_RANKING_CATEGORY,
  EDIT_RANKING_CATEGORY,
  RankingCategoryActionTypes,
  RankingCategoryProfileState,
  SET_RANKING_CATEGORY,
} from './ranking-category-profile.types';

const initialState: RankingCategoryProfileState = {} as RankingCategoryProfileState;

const rankingCategoryProfileReducer = (
  state = initialState,
  action: RankingCategoryActionTypes,
): RankingCategoryProfileState => {
  switch (action.type) {
    case CLEAR_CURRENT_RANKING_CATEGORY: {
      return initialState;
    }
    case CREATE_RANKING_CATEGORY:
    case EDIT_RANKING_CATEGORY:
    case SET_RANKING_CATEGORY: {
      return {
        ...action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default rankingCategoryProfileReducer;

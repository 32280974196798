import { LeadsDetailProfile } from './school-leads-details.dto';
import { SET_SINGLE_SCHOOL_LEAD, SingleSchoolLeadActionTypes } from './school-leads-details.types';

const initialState = {} as LeadsDetailProfile;

const schoolSingleLeadReducer = (state = initialState, action: SingleSchoolLeadActionTypes): LeadsDetailProfile => {
  switch (action.type) {
    case SET_SINGLE_SCHOOL_LEAD: {
      return {
        ...state,
        lead: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default schoolSingleLeadReducer;

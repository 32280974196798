import React, { FunctionComponent, MouseEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import TimelineIndicator from './TimelineIndicator';
import TimelineProgressBar from './TimelineProgressBar';
import TimelineStepDates from './TimelineStepDates';
import { RankingTimelineStepBaseProps, TimelineExpandStepStatus } from '../RankingTimeline.constants';
import { downloadSpreadsheetTemplateAction } from '../../RankingDetails/TimelineSteps';
import Button from '../../../../../../components/Button';
import Grid from '../../../../../../components/Grid';

interface SpreadsheetStepProps extends RankingTimelineStepBaseProps {
  spreadsheetType: string;
  onDeleteSpreadsheets?: (type: string) => void;
}

const SpreadsheetStep: FunctionComponent<SpreadsheetStepProps> = ({
  endDate,
  name,
  progress,
  rankingId,
  spreadsheetType,
  status,
  templateId,
  onDeleteSpreadsheets,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [expand, setExpand] = useState<boolean>(false);

  const navigateToRankingsSpreadsheets = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    history.push(`/rankings/profile/${rankingId}/spreadsheets/${spreadsheetType}`);
  };

  const handleDeleteSpreadsheets = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    onDeleteSpreadsheets && onDeleteSpreadsheets(spreadsheetType);
  };

  const downloadTemplate = (event: MouseEvent): void => {
    event.preventDefault();
    dispatch(downloadSpreadsheetTemplateAction(rankingId, spreadsheetType));
  };

  useEffect(() => {
    setExpand(TimelineExpandStepStatus[status]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <Grid container key={name + spreadsheetType}>
      <Grid item xs={5}>
        <TimelineStepDates endDate={endDate} endDateLabel="Deadline" isEndDateRequired={true} />
      </Grid>
      <Grid item xs={1} className="mba-no-padding">
        <TimelineIndicator timeIndication={status} />
      </Grid>
      <Grid item xs={6} className="mba-no-padding">
        <button
          className="mba-text--accent mba-no-margin mba-text--semi-bold mba-timeline-button--link"
          onClick={(): void => setExpand(!expand)}
        >
          {name}
        </button>
        {expand && (
          <>
            <div>
              <TimelineProgressBar label="Schools uploaded documents" progress={progress} />
            </div>
            <br />
            <div className="mba-actions">
              <Button
                text="Download template"
                onClick={downloadTemplate}
                disabled={!templateId}
                wrapperClass={'mba-mb-20'}
              />
              <Button
                primary
                text="Go to spreadsheets"
                onClick={navigateToRankingsSpreadsheets}
                wrapperClass={'mba-mb-20'}
              />
            </div>
            {progress?.current && +progress?.current > 0 && (
              <Button danger text="Delete" onClick={handleDeleteSpreadsheets} wrapperClass={'mba-mb-20'} />
            )}
            <br />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default SpreadsheetStep;

import { PageSearchQueryConfig, TableListData } from '../../../../../../shared/constants';
import { RankingFieldDTO } from './ranking-fields-list.dto';

export const LOAD_RANKING_FIELDS = 'LOAD_RANKING_FIELDS';
export const SET_RANKING_FIELDS = 'SET_RANKING_FIELDS';

export interface LoadRankingFieldsAction {
  type: typeof LOAD_RANKING_FIELDS;
  payload: {
    rankingId: number;
    pageConfig?: PageSearchQueryConfig;
  };
}

export interface SetRankingFieldsAction {
  type: typeof SET_RANKING_FIELDS;
  payload: { tableData: TableListData<RankingFieldDTO> };
}

export type RankingFieldsActionTypes = LoadRankingFieldsAction | SetRankingFieldsAction;

export type RankingFieldType = 'Column' | 'Filter';

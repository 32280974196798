import { RankingFieldDTO } from './ranking-fields-list.dto';
import {
  LOAD_RANKING_FIELDS,
  LoadRankingFieldsAction,
  SET_RANKING_FIELDS,
  SetRankingFieldsAction,
} from './ranking-fields-list.types';
import { PageSearchQueryConfig, TableListData } from '../../../../../../shared/constants';

export const loadRankingFieldsAction = (
  rankingId: number,
  pageConfig?: PageSearchQueryConfig,
): LoadRankingFieldsAction => ({
  type: LOAD_RANKING_FIELDS,
  payload: { rankingId, pageConfig },
});

export const setRankingFieldsAction = (tableData: TableListData<RankingFieldDTO>): SetRankingFieldsAction => ({
  type: SET_RANKING_FIELDS,
  payload: { tableData },
});

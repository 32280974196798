import React, { FunctionComponent } from 'react';

import {
  ADDITIONAL_INFORMATION_NAVIGATION,
  RANKINGS_ADDITIONAL_INFORMATION_MAIN_ROUTE,
} from './AdditionalInformation.constants';
import Calendar from './Calendar';
import FAQs from './FAQs';
import Methodology from './Methodology';
import { PageRouter } from '../../App/Main/components/Layout';
import TabsNavigation from '../../../components/TabsNavigation';
import Typography, { TypographyVariants } from '../../../components/Typography';

const componentsMap = {
  calendar: Calendar,
  methodology: Methodology,
  faqs: FAQs,
};

const AdditionalInformation: FunctionComponent = () => {
  const tabsNavigation = Object.values(ADDITIONAL_INFORMATION_NAVIGATION).map(({ label, route }) => ({
    label,
    route: `${RANKINGS_ADDITIONAL_INFORMATION_MAIN_ROUTE}${route}`,
  }));

  return (
    <>
      <Typography component="h1" variant={TypographyVariants.h1}>
        Additional Information
      </Typography>
      <TabsNavigation tabs={tabsNavigation} big />
      <PageRouter
        mainRoute={RANKINGS_ADDITIONAL_INFORMATION_MAIN_ROUTE}
        navigation={{
          ...ADDITIONAL_INFORMATION_NAVIGATION,
        }}
        componentsMap={componentsMap}
      />
    </>
  );
};

export default AdditionalInformation;

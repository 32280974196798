import { RootState } from '../../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const rankingCategoriesSelector = (state: RootState) => state.rankings.categories;

export const selectRankingCategoriesTableData = createDeepEqualSelector(rankingCategoriesSelector, (categories) => {
  return categories.data;
});

export const selectRankingCategoriesPageConfiguration = createDeepEqualSelector(
  rankingCategoriesSelector,
  (categories) => {
    return {
      filter: categories.filter,
      limit: categories.pagination.limit,
      order: categories.sortOrder.order,
      page: categories.pagination.page,
      search: categories.search,
      sortBy: categories.sortOrder.sortBy,
      totalPages: categories.pagination.totalPages,
    };
  },
);

import { DefaultResponseDTO } from '../../../../shared/constants';
import { registrationPut } from '../../../../shared/registrationApi/api';
import { SchoolsWithLeadGenDTO } from '../../SchoolsWithLeadGen';
import { UpdateSchoolWithLeadDetailsDto } from './SchoolWithLeadDetails.constants';

export const updateSchoolWithLeadDetailsRequest = (
  schoolId: number,
  updatedData: UpdateSchoolWithLeadDetailsDto,
): Promise<DefaultResponseDTO<SchoolsWithLeadGenDTO>> => {
  return registrationPut<DefaultResponseDTO<SchoolsWithLeadGenDTO>, UpdateSchoolWithLeadDetailsDto>(
    `/school/${schoolId}`,
    updatedData,
  );
};

import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button, { ButtonSizes, ButtonTypes } from '../../../../../components/Button';
import Form, { FormConfig, FormDataValues } from '../../../../../components/Form';
import Grid from '../../../../../components/Grid';
import Modal from '../../../../../components/Modal';
import { SelectOptionType } from '../../../../../components/Select';
import { PageSearchQueryConfig, Resource } from '../../../../../shared/constants';
import { selectRequestErrors, selectRequestIsLoading } from '../../../../../shared/state/global-request';
import {
  loadSingleSchoolWidgetGroupsAction,
  selectSingleSchoolWidgetGroups,
} from '../../../../../shared/state/school-resources';
import { loadSchoolRankingsAction } from '../../../../Schools/SchoolProfile/SchoolRankings';
import { createSchoolWidgetGroupsAction } from '../../school-widget-groups.actions';
import { SchoolWidgetGroupDto } from '../../school-widget-groups.dto';
import { CREATE_SCHOOL_WIDGET_GROUP } from '../../school-widget-groups.types';
import { WIDGET_GROUP_FORM_CONFIG } from '../SchoolWidgetGroupsList.constants';
import { CreateSchoolWidgetGroupsDto } from './CreateWidgetGroupModal.constants';

interface CreateWidgetGroupProps {
  handleModalClose: () => void;
  isModalOpen: boolean;
  rankingTypes: Resource[];
  schools: Resource[];
}

export const CreateWidgetGroupModal: FunctionComponent<CreateWidgetGroupProps> = ({
  handleModalClose,
  isModalOpen,
  rankingTypes,
  schools,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectRequestIsLoading(CREATE_SCHOOL_WIDGET_GROUP));
  const schoolWidgetGroups: SchoolWidgetGroupDto[] = useSelector(selectSingleSchoolWidgetGroups);
  const formRequestErrors = useSelector(selectRequestErrors(CREATE_SCHOOL_WIDGET_GROUP));

  const [formConfig, setFormConfig] = useState<FormConfig>({});
  const [formValues, setFormValues] = useState<FormDataValues>({
    action: 'duplicate',
  });

  const schoolsOption: SelectOptionType[] = useMemo(
    () => schools.map((school: Resource) => ({ label: school.label, value: school.key })),
    [schools],
  );

  const schoolWidgetGroupsOption: SelectOptionType[] = useMemo(
    () =>
      schoolWidgetGroups.map((group: SchoolWidgetGroupDto) => ({
        label: `${group.school} - ${group.rankingType}`,
        value: group.id || 0,
      })),
    [schoolWidgetGroups],
  );

  useEffect(() => {
    const schoolId = parseInt(formValues.school?.toString());
    const pageConfig: PageSearchQueryConfig = { limit: Number.MAX_SAFE_INTEGER };
    if (!isNaN(schoolId)) {
      dispatch(loadSchoolRankingsAction(schoolId, pageConfig));
      dispatch(loadSingleSchoolWidgetGroupsAction(schoolId));
    }
  }, [dispatch, formValues]);

  useEffect(() => {
    setFormConfig(
      WIDGET_GROUP_FORM_CONFIG(
        {
          action: formValues.action.toString(),
          school: parseInt(formValues.school?.toString()),
          group: formValues.group?.toString(),
          scope: formValues.scope?.toString(),
        },
        rankingTypes,
        schoolsOption,
        schoolWidgetGroupsOption,
      ),
    );
  }, [rankingTypes, schoolsOption, schoolWidgetGroupsOption, formValues]);

  const handleValueChange = (data: FormDataValues): void => {
    setFormValues(data);
  };

  const handleSubmit = (data: CreateSchoolWidgetGroupsDto): void => {
    dispatch(createSchoolWidgetGroupsAction(data));
  };

  const customAction = (
    <Grid item md={12} xs={12} className="mba-no-padding mba-mt-20">
      <Button text="Cancel" size={ButtonSizes.big} onClick={handleModalClose} wrapperClass="mba-mr-10" />
      <Button text="Create" type={ButtonTypes.submit} isLoading={isLoading} size={ButtonSizes.big} primary />
    </Grid>
  );

  return (
    <Modal title="Create widget group" isOpen={isModalOpen} onClose={handleModalClose}>
      <Form
        id="form"
        config={formConfig}
        errors={formRequestErrors}
        initialValues={formValues}
        isLoading={isLoading}
        onValuesChanged={handleValueChange}
        onSubmit={handleSubmit}
        customActions={customAction}
      />
    </Modal>
  );
};

import React, { FunctionComponent } from 'react';

import { RankingTimelineProgressDto } from '../../RankingDetails/TimelineSteps';

import styles from './TimelineProgressBar.module.scss';

interface TimelineProgressBar {
  label: string;
  progress?: RankingTimelineProgressDto;
}

const TimelineProgressBar: FunctionComponent<TimelineProgressBar> = ({
  label,
  progress = { current: 0, total: 0 },
}) => {
  const percents = progress.total > 0 ? (100 * progress.current) / progress.total : 0;

  return (
    <div>
      <p className={[styles.progressLabel, 'o-layout__unstyled-element'].join(' ')}>{label}</p>
      <div className={styles.progressWrapper}>
        <div className={styles.progressBar}>
          <div className={styles.progressBarFilled} style={{ width: `${percents}%` }} />
          <div className={styles.progressBox} />
          <div className={styles.progressBox} />
          <div className={styles.progressBox} />
          <div className={styles.progressBox} />
          <div className={styles.progressBox} />
          <div className={styles.progressBox} />
          <div className={styles.progressBox} />
          <div className={styles.progressBox} />
        </div>
        <div className={styles.progressText}>
          {percents.toFixed(1)}% ({progress.current} out of {progress.total})
        </div>
      </div>
    </div>
  );
};

export default TimelineProgressBar;

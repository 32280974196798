import { SCHOOL_TYPES } from '../../SchoolsList/components/SchoolsList.constants';
import { PageSearchQueryConfig, TableListData } from '../../../../shared/constants';

export const ADD_PARTNERSHIP = 'ADD_PARTNERSHIP';
export const REMOVE_PARTNERSHIP = 'REMOVE_PARTNERSHIP';
export const CLEAR_CURRENT_PARTNERSHIP = 'CLEAR_CURRENT_PARTNERSHIP';
export const LOAD_PARTNERSHIPS = 'LOAD_PARTNERSHIPS';
export const LOAD_PARTNER_SCHOOLS = 'LOAD_PARTNER_SCHOOLS';
export const SET_PARTNERSHIPS = 'SET_PARTNERSHIPS';

export interface PartnershipDTO {
  id: string;
  name: string;
  schools: string[];
}

export interface PartnershipDataRequestDTO {
  joinedSchoolId: number;
}

export interface AddPartnershipAction {
  type: typeof ADD_PARTNERSHIP;
  payload: {
    currentSchoolId: number;
    currentSchoolType: SCHOOL_TYPES;
    joinedSchoolId: number;
    mainSchoolId: number;
  };
}

export interface RemovePartnershipsAction {
  type: typeof REMOVE_PARTNERSHIP;
  payload: {
    currentSchoolId: number;
    currentSchoolType: SCHOOL_TYPES;
    joinedSchoolId: number;
    schoolId: number;
  };
}

export interface LoadPartnershipsAction {
  type: typeof LOAD_PARTNERSHIPS;
  payload: { schoolId: number; pageConfig?: PageSearchQueryConfig };
}

export interface LoadPartnerSchoolsAction {
  type: typeof LOAD_PARTNER_SCHOOLS;
  payload: { schoolId: number; pageConfig?: PageSearchQueryConfig };
}

export interface SetPartnershipsAction {
  type: typeof SET_PARTNERSHIPS;
  payload: { tableData: TableListData<PartnershipDTO> };
}

export interface ClearCurrentPartnershipAction {
  type: typeof CLEAR_CURRENT_PARTNERSHIP;
}

export type PartnershipsActionTypes =
  | AddPartnershipAction
  | ClearCurrentPartnershipAction
  | RemovePartnershipsAction
  | LoadPartnershipsAction
  | LoadPartnerSchoolsAction
  | SetPartnershipsAction;

import { CreateRankingCategoryDTO, EditRankingCategoryDTO, RankingCategoryDTO } from './ranking-category-profile.dto';

export const CREATE_RANKING_CATEGORY = 'CREATE_RANKING_CATEGORY';
export const LOAD_RANKING_CATEGORY = 'LOAD_RANKING_CATEGORY';
export const EDIT_RANKING_CATEGORY = 'EDIT_RANKING_CATEGORY';
export const EDIT_RANKING_CATEGORY_DETAILS = 'EDIT_RANKING_CATEGORY_DETAILS';
export const DELETE_RANKING_CATEGORY = 'DELETE_RANKING_CATEGORY';
export const CLEAR_CURRENT_RANKING_CATEGORY = 'CLEAR_CURRENT_RANKING_CATEGORY';
export const SET_RANKING_CATEGORY = 'SET_RANKING_CATEGORY';

export type RankingCategoryProfileState = CreateRankingCategoryDTO | EditRankingCategoryDTO | RankingCategoryDTO;

export interface RankingCategoryProfileRouteParams {
  id: string;
}

export interface CreateRankingCategoryAction {
  type: typeof CREATE_RANKING_CATEGORY;
  payload: { data: CreateRankingCategoryDTO };
}

export interface LoadRankingCategoryAction {
  type: typeof LOAD_RANKING_CATEGORY;
  payload: { id: number };
}

export interface EditRankingCategoryAction {
  type: typeof EDIT_RANKING_CATEGORY;
  payload: { data: EditRankingCategoryDTO };
}

export interface EditRankingCategoryDetailsAction {
  type: typeof EDIT_RANKING_CATEGORY_DETAILS;
  payload: { data: EditRankingCategoryDTO };
}

export interface DeleteRankingCategoryAction {
  type: typeof DELETE_RANKING_CATEGORY;
  payload: { id: number };
}

export interface ClearCurrentRankingCategoryAction {
  type: typeof CLEAR_CURRENT_RANKING_CATEGORY;
}

export interface SetRankingCategoryAction {
  type: typeof SET_RANKING_CATEGORY;
  payload: { data: RankingCategoryDTO };
}

export type RankingCategoryActionTypes =
  | ClearCurrentRankingCategoryAction
  | CreateRankingCategoryAction
  | EditRankingCategoryAction
  | EditRankingCategoryDetailsAction
  | LoadRankingCategoryAction
  | SetRankingCategoryAction;

import React, { FunctionComponent, SyntheticEvent, useRef, useState } from 'react';
import {
  AtomicBlockUtils,
  DraftBlockType,
  DraftEditorCommand,
  DraftHandleValue,
  EditorState,
  getDefaultKeyBinding,
  RichUtils,
} from 'draft-js';
import { styleMap } from './RichTextEditor.constants';
import './RichTexEditor.css';
import BlockStyleControls from './BlockStyleControls';
import InlineStyleControls from './InlineStyleControls';
import InputWrapper, { InputWrapperProps } from '../InputWrapper';
import { getBlockStyle } from './RichTextEditor.helpers';

import Editor from '@draft-js-plugins/editor';
import createImagePlugin from '@draft-js-plugins/image';
import '@draft-js-plugins/image/lib/plugin.css';
import { uploadFaqImage } from '../../containers/Rankings/AdditionalInformation/FAQs/frequently-asked-questions.api';
import { IconInsertImage } from '../CustomIcons';

const imagePlugin = createImagePlugin();

// TODO find how to use DraftEditorProps instead of RichTextEditorProps
type RichTextEditorProps = {
  editorState: EditorState;
  onChange(editorState: EditorState): void;
  onBlur?(e: SyntheticEvent): void;
};

const RichTextEditor: FunctionComponent<RichTextEditorProps & InputWrapperProps> = ({
  editorState,
  onChange,
  id,
  inline,
  optional,
  required,
  shrunken,
  subTitle,
  title,
  verticallyCentered,
  isFileUploadEnabled,
  ...elementProps
}) => {
  const editor = useRef(null) as any;
  const [showURLInput, setShowURLInput] = useState(false);
  const [urlValue, setUrlValue] = useState('');

  const handleKeyCommand = (command: DraftEditorCommand, editorState: EditorState): DraftHandleValue => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const mapKeyToEditorCommand = (e: React.KeyboardEvent<{}>): DraftEditorCommand | null => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, editorState, 4 /* maxDepth */);
      if (newEditorState !== editorState) {
        onChange(newEditorState);
      }
      return null;
    }
    return getDefaultKeyBinding(e);
  };

  const toggleBlockType = (blockType: DraftBlockType): void =>
    onChange(RichUtils.toggleBlockType(editorState, blockType));

  const toggleInlineStyle = (inlineStyle: string): void =>
    onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));

  const promptForLink = (e: SyntheticEvent): void => {
    e.preventDefault();
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
      let url = '';
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }
      setShowURLInput(true);
      setUrlValue(url);
    }
  };

  const confirmLink = (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLInputElement>): void => {
    e.preventDefault();
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url: urlValue });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    onChange(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));
    setShowURLInput(false);
    setUrlValue('');
  };

  const onLinkInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      confirmLink(e);
    }
  };

  const removeLink = (e: SyntheticEvent): void => {
    e.preventDefault();
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      onChange(RichUtils.toggleLink(editorState, selection, null));
    }
  };

  let className = 'RichEditor-editor';
  const contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      className += ' RichEditor-hidePlaceholder';
    }
  }

  const insertImage = (uploadedImageUrl: any): void => {
    const contentState = editorState.getCurrentContent();
    const newEditorState = EditorState.forceSelection(
      editorState,
      EditorState.moveSelectionToEnd(editorState).getSelection(),
    );
    const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', {
      src: uploadedImageUrl,
    });

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorStateWithImage = EditorState.set(newEditorState, {
      currentContent: contentStateWithEntity,
    });
    onChange(AtomicBlockUtils.insertAtomicBlock(newEditorStateWithImage, entityKey, ' '));
  };

  const uploadImage = async (e: any): Promise<void> => {
    e.preventDefault();
    if (e.target && e.target.files) {
      const imageFile = e.target.files[0];
      if (imageFile.type === 'image/png' || imageFile.type === 'image/jpeg') {
        try {
          const formData = new FormData();
          formData.append('file', imageFile);
          const imageData = await uploadFaqImage(formData);
          insertImage(imageData.content);
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  return (
    <InputWrapper
      id={id}
      inline={shrunken}
      optional={optional}
      required={required}
      shrunken={shrunken}
      subTitle={subTitle}
      title={title}
      verticallyCentered={verticallyCentered}
      groupedElement={true} // label wrapper focuses the buttons and prevents texting in the editor
    >
      <div className="RichEditor-root">
        <BlockStyleControls editorState={editorState} onToggle={toggleBlockType} />
        <InlineStyleControls editorState={editorState} onToggle={toggleInlineStyle} />
        <div>
          <button onMouseDown={promptForLink} style={{ marginRight: 10 }} type="button">
            Add Link
          </button>
          <button onMouseDown={removeLink} type="button">
            Remove Link
          </button>
          {isFileUploadEnabled && (
            <div style={{ display: 'inline-block', paddingLeft: '10px' }}>
              <label htmlFor="image-upload" style={{ position: 'relative', top: '7.5px' }}>
                <IconInsertImage />
              </label>
              <input
                onChange={uploadImage}
                id="image-upload"
                type="file"
                accept="image/png, image/jpeg"
                style={{ display: 'none' }}
              />
            </div>
          )}
        </div>
        {showURLInput && (
          <div>
            <input
              onChange={(e): void => setUrlValue(e.target.value)}
              type="text"
              value={urlValue}
              onKeyDown={onLinkInputKeyDown}
            />
            <button onMouseDown={confirmLink} type="button">
              Confirm
            </button>
          </div>
        )}
        <div className={className}>
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            onChange={onChange}
            ref={editor}
            spellCheck={true}
            {...elementProps}
            plugins={[imagePlugin]}
          />
        </div>
      </div>
    </InputWrapper>
  );
};
export default RichTextEditor;

import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadRegistrationsAction, selectRegistrationsPageConfiguration } from '../..';
import DatePicker from '../../../../../components/DatePicker';
import FilterGroup, { mapInitialValuesToFiltersConfig } from '../../../../../components/FilterGroup';
import { PageFilterConfig } from '../../../../../shared/constants';
import { REGISTRATIONS_FILTERS, REGISTRATIONS_ROLE_FILTER_OPTIONS } from './RegistartionsFilters.constants';
import { formatDateString } from '../../../../../shared/helpers';

const RegistrationsFilters: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [registrationsFilters, setRegistrationsFilters] = useState({});
  const pageConfig = useSelector(selectRegistrationsPageConfiguration);
  const filter = pageConfig.filter;

  useEffect(() => {
    setRegistrationsFilters(
      mapInitialValuesToFiltersConfig(
        REGISTRATIONS_FILTERS,
        {
          role: REGISTRATIONS_ROLE_FILTER_OPTIONS,
        },
        filter,
        true,
      ),
    );
  }, [filter]);

  const handleDateFilterChange = (event: ChangeEvent<HTMLInputElement>): void => {
    let newFilters = { ...filter };
    const { name, value } = event.target;
    if (value) {
      const formattedDate = formatDateString(new Date(value).toString(), 'YYYY-MM-DD');
      newFilters = {
        ...filter,
        [name]: [formattedDate],
      };
    } else {
      delete newFilters[name];
    }
    dispatch(loadRegistrationsAction({ filter: newFilters }));
  };

  const handleFiltersChange = (newFilters: PageFilterConfig): void => {
    dispatch(loadRegistrationsAction({ filter: newFilters }));
  };

  return (
    <div className="sticky-sidebar-container">
      <div style={{ display: 'flex' }}>
        <DatePicker title="From" placeholder="DD/MM/YY" name="from" onChange={handleDateFilterChange} />
        <div style={{ width: 20 }} />
        <DatePicker title="To" placeholder="DD/MM/YY" name="to" onChange={handleDateFilterChange} />
      </div>
      <FilterGroup
        defaultFilters={filter || {}}
        filtersConfig={registrationsFilters}
        onFiltersChanged={handleFiltersChange}
      />
    </div>
  );
};

export default RegistrationsFilters;

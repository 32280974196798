import { SchoolLeadsDetails } from './school-leads-details.dto';

export const LOAD_SINGLE_SCHOOL_LEAD = 'LOAD_SINGLE_SCHOOL_LEAD';
export const SET_SINGLE_SCHOOL_LEAD = 'SET_SINGLE_SCHOOL_LEAD';

export interface loadSchoolSingleLeadAction {
  type: typeof LOAD_SINGLE_SCHOOL_LEAD;
  payload: { leadId: string };
}

export interface SetSchoolSingleLeadAction {
  type: typeof SET_SINGLE_SCHOOL_LEAD;
  payload: SchoolLeadsDetails;
}

export type SingleSchoolLeadActionTypes = loadSchoolSingleLeadAction | SetSchoolSingleLeadAction;

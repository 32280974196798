import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { importSchoolsToRankingAction } from '../../ranking-schools.actions';
import { IMPORT_SCHOOLS_TO_RANKING } from '../../ranking-schools.types';
import { RankingDTO, selectCurrentRanking } from '../../../RankingInfo';
import Button, { ButtonSizes } from '../../../../../../../components/Button';
import Typography, { TypographyVariants } from '../../../../../../../components/Typography';
import { selectRequestErrorMessage, setRequestSucceededAction } from '../../../../../../../shared/state/global-request';

import styles from './ImportSchoolsToRanking.module.scss';

type ImportSchoolsToRankingProps = {
  onManualAddClick: () => void;
};

const ImportSchoolsToRanking: FunctionComponent<ImportSchoolsToRankingProps> = ({ onManualAddClick }) => {
  const dispatch = useDispatch();
  const { id: rankingId } = useSelector(selectCurrentRanking) as RankingDTO;
  const importSchoolsToRankingMessage = useSelector(selectRequestErrorMessage(IMPORT_SCHOOLS_TO_RANKING));

  const handleImportSchoolsClick = (): void => {
    dispatch(importSchoolsToRankingAction(rankingId));
  };

  useEffect(() => {
    return (): void => {
      dispatch(setRequestSucceededAction(IMPORT_SCHOOLS_TO_RANKING));
    };
  }, [dispatch, rankingId]);

  return (
    <div className={styles.wrapper}>
      <Typography component="p" variant={TypographyVariants.h2} className="mba-heading--title-2 pad2">
        No records for participating schools in this ranking
      </Typography>
      <div className={styles.actions}>
        <div className={styles.button}>
          <Button
            text="Import list from previous published ranking"
            size={ButtonSizes.big}
            primary
            onClick={handleImportSchoolsClick}
          />
          {importSchoolsToRankingMessage && <div className="mba-text--error">{importSchoolsToRankingMessage}</div>}
        </div>
        <div className={styles.button}>
          <Button text="Add schools manually" size={ButtonSizes.big} onClick={onManualAddClick} />
        </div>
      </div>
    </div>
  );
};

export default ImportSchoolsToRanking;

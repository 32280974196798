import { SaveSchoolUserDTO, SchoolUserDTO, SchoolUserRolesAndPermissionsDTO } from './school-user-profile.dto';
import { del, get, post, put } from '../../../shared/api';
import { BasicResponseDTO, DefaultResponseDTO } from '../../../shared/constants';

export const createSchoolUserRequest = (
  schoolUserData: SaveSchoolUserDTO,
  schoolId: number,
): Promise<DefaultResponseDTO<SchoolUserDTO>> => {
  return post<DefaultResponseDTO<SchoolUserDTO>, SaveSchoolUserDTO>(`/schools/${schoolId}/accounts`, schoolUserData);
};

export const getSchoolUserRequest = (
  schoolUserId: number,
  schoolId: number,
): Promise<DefaultResponseDTO<SchoolUserDTO>> => {
  return get<DefaultResponseDTO<SchoolUserDTO>>(`/schools/${schoolId}/accounts/${schoolUserId}`);
};

export const updateSchoolUserRoleAndPermissionsRequest = (
  roleAndPermissions: SchoolUserRolesAndPermissionsDTO,
  schoolId: number,
  userId: number,
): Promise<BasicResponseDTO> => {
  return put<BasicResponseDTO, SchoolUserRolesAndPermissionsDTO>(
    `/schools/${schoolId}/accounts/${userId}/partnerships`,
    roleAndPermissions,
  );
};

export const deleteSchoolUserRequest = (
  schoolUserId: number,
  schoolId: number,
  ignoreWarnings = true,
): Promise<BasicResponseDTO> => {
  return del<BasicResponseDTO>(`/schools/${schoolId}/accounts/${schoolUserId}?ignoreWarnings=${ignoreWarnings}`);
};

export const editSchoolUserRequest = (data: SaveSchoolUserDTO, schoolId: number): Promise<BasicResponseDTO> => {
  return put<BasicResponseDTO, SaveSchoolUserDTO>(`/schools/${schoolId}/accounts/${data.id}`, data);
};

import { SpreadsheetTemplateFieldDTO } from './spreadsheet-template.dto';
import {
  SpreadsheetTemplateFieldActionTypes,
  SET_SPREADSHEET_TEMPLATE_FIELDS,
  SpreadsheetTemplateFieldState,
  SET_CURRENT_SPREADSHEET_TEMPLATE_FIELDS,
  CLEAR_CURRENT_SPREADSHEET_TEMPLATE_FIELDS,
} from './spreadsheet-template.types';
import { getTableListDefaultState } from '../../../shared/helpers';

const initialState: SpreadsheetTemplateFieldState = {
  alumni: getTableListDefaultState<SpreadsheetTemplateFieldDTO>(),
  faculty: getTableListDefaultState<SpreadsheetTemplateFieldDTO>(),
  research: getTableListDefaultState<SpreadsheetTemplateFieldDTO>(),
};

const spreadsheetTemplateReducer = (
  state = initialState,
  action: SpreadsheetTemplateFieldActionTypes,
): SpreadsheetTemplateFieldState => {
  switch (action.type) {
    case SET_SPREADSHEET_TEMPLATE_FIELDS: {
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          ...action.payload.tableData,
        },
      };
    }
    case SET_CURRENT_SPREADSHEET_TEMPLATE_FIELDS: {
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          currentField: action.payload.data,
        },
      };
    }
    case CLEAR_CURRENT_SPREADSHEET_TEMPLATE_FIELDS: {
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          currentField: undefined,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default spreadsheetTemplateReducer;

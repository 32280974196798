import { ColumnDefinition } from '../../../../../../../../../components/Table';

export const RANKING_FIELD_DATA_TABLE_COLUMN_DEFINITION: ColumnDefinition[] = [
  {
    id: 'schoolName',
    title: 'School name',
    sorting: true,
  },
  {
    id: 'value',
    sorting: true,
    title: 'Value',
  },
];

import { CreateSchoolDTO, EditSchoolDTO, EditSchoolLogoDTO, SchoolDTO } from './school-details.dto';
import {
  CLEAR_CURRENT_SCHOOL,
  ClearCurrentSchoolAction,
  CREATE_SCHOOL,
  CreateSchoolAction,
  DELETE_SCHOOL,
  DeleteSchoolAction,
  EDIT_SCHOOL,
  EDIT_SCHOOL_ACCREDITATIONS,
  EDIT_SCHOOL_DETAILS,
  EDIT_SCHOOL_LOGO,
  EditSchoolAccreditationsAction,
  EditSchoolAction,
  EditSchoolDetailsAction,
  EditSchoolLogoAction,
  LOAD_SCHOOL,
  LoadSchoolAction,
  SET_SCHOOL,
  SetSchoolAction,
} from './school-details.types';

export const createNewSchoolAction = (data: CreateSchoolDTO): CreateSchoolAction => ({
  type: CREATE_SCHOOL,
  payload: { data },
});

export const editSchoolLogoAction = (data: EditSchoolLogoDTO): EditSchoolLogoAction => ({
  type: EDIT_SCHOOL_LOGO,
  payload: { data },
});

export const loadSchoolAction = (id: number): LoadSchoolAction => ({
  type: LOAD_SCHOOL,
  payload: { id },
});

export const editSchoolAction = (data: EditSchoolDTO): EditSchoolAction => ({
  type: EDIT_SCHOOL,
  payload: { data },
});

export const editSchoolDetailsAction = (data: EditSchoolDTO): EditSchoolDetailsAction => ({
  type: EDIT_SCHOOL_DETAILS,
  payload: { data },
});

export const editSchoolAccreditationsAction = (
  schoolId: number,
  accreditationIds: number[],
): EditSchoolAccreditationsAction => ({
  type: EDIT_SCHOOL_ACCREDITATIONS,
  payload: { accreditationIds, schoolId },
});

export const deleteSchoolAction = (id: number): DeleteSchoolAction => ({
  type: DELETE_SCHOOL,
  payload: { id },
});

export const setSchoolAction = (data: SchoolDTO): SetSchoolAction => ({
  type: SET_SCHOOL,
  payload: { data },
});

export const clearCurrentSchoolAction = (): ClearCurrentSchoolAction => ({
  type: CLEAR_CURRENT_SCHOOL,
});

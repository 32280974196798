import { FormErrors } from '../Form';
import React, { FC, Fragment } from 'react';

const ErrorMessage: FC<{ errors?: FormErrors; errorMessage?: string }> = ({ errors, errorMessage }) => {
  return errors?.spreadsheet?.length || errorMessage ? (
    <>
      Failed to process the file. Please fix the following error(s):
      {errorMessage ? (
        <>
          <br />
          <br />
          {errorMessage}
        </>
      ) : (
        <></>
      )}
      {errors?.spreadsheet?.length ? (
        <>
          <br />
          <br />
          {errors.spreadsheet.map((error) => (
            <Fragment key={error}>
              {error}
              <br />
            </Fragment>
          ))}
        </>
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  );
};

export default ErrorMessage;

import { CreateUserDTO, EditUserDTO, UserDTO } from './users.dto';
import { del, get, post, put } from '../../../shared/api';
import {
  BasicResponseDTO,
  DefaultResponseDTO,
  PagedResponseDTO,
  PageSearchQueryConfig,
} from '../../../shared/constants';
import { generateSearchQuery } from '../../../shared/helpers';

export const createUserRequest = (userData: CreateUserDTO): Promise<DefaultResponseDTO<UserDTO>> => {
  return post<DefaultResponseDTO<UserDTO>, CreateUserDTO>('/users', userData);
};

export const updateUserRequest = (userData: EditUserDTO): Promise<BasicResponseDTO> => {
  const { id } = userData;
  return put<BasicResponseDTO, EditUserDTO>(`/users/${id}`, userData);
};

export const deleteUserRequest = (id: number): Promise<BasicResponseDTO> => {
  return del<BasicResponseDTO>(`/users/${id}`);
};

export const getUsersRequest = (pageConfig: PageSearchQueryConfig = {}): Promise<PagedResponseDTO<UserDTO[]>> => {
  const query = generateSearchQuery(pageConfig);
  return get<PagedResponseDTO<UserDTO[]>>(`/users${query}`);
};

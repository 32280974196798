import React, { FunctionComponent } from 'react';

import { FilterData } from './FilterInput.constants';
import { CheckboxGroup, CheckboxInputOption } from '../../Checkbox';

type FilterInputProps = {
  id: string;
  isDropdown?: boolean;
  name: string;
  onFilterChanged: (data: FilterData) => void;
  options: CheckboxInputOption[];
  title?: string;
  subTitle?: string;
};

const FilterInput: FunctionComponent<FilterInputProps> = ({
  id,
  isDropdown,
  name,
  onFilterChanged,
  options,
  title,
  subTitle,
}) => {
  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name: optionName } = event.target;
    onFilterChanged({
      name,
      value: optionName,
    });
  };

  return (
    <CheckboxGroup
      name={name}
      id={id}
      isDropdown={isDropdown}
      title={title}
      subTitle={subTitle}
      onChange={onValueChange}
      options={options}
    />
  );
};

export default FilterInput;

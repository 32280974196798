import { AddSchoolsToRankingDTO, RankingSchoolDTO, UpdateParticipationStatusDTO } from './ranking-schools.dto';
import { get, post, put } from '../../../../../shared/api';
import { BasicResponseDTO, PagedResponseDTO, PageSearchQueryConfig } from '../../../../../shared/constants';
import { generateSearchQuery } from '../../../../../shared/helpers';

export const getRankingSchoolsRequest = (
  rankingId: number,
  pageConfig: PageSearchQueryConfig = {},
): Promise<PagedResponseDTO<RankingSchoolDTO[]>> => {
  const query = generateSearchQuery(pageConfig);
  return get<PagedResponseDTO<RankingSchoolDTO[]>>(`/rankings/${rankingId}/schools/participations${query}`);
};

export const downloadParticipationSpreadsheetRequest = (
  rankingId: number,
  pageConfig: PageSearchQueryConfig = {},
): Promise<BasicResponseDTO> => {
  const query = generateSearchQuery(pageConfig);
  return get<BasicResponseDTO>(`/rankings/${rankingId}/schools/participations/spreadsheets${query}`, {
    responseType: 'blob',
  });
};

export const addSchoolsToRankingRequest = (
  addSchoolsToRankingData: AddSchoolsToRankingDTO,
  rankingId: number,
): Promise<BasicResponseDTO> => {
  return post<BasicResponseDTO, AddSchoolsToRankingDTO>(`rankings/${rankingId}/schools/`, addSchoolsToRankingData);
};

export const importSchoolsToRankingRequest = (rankingId: number): Promise<BasicResponseDTO> => {
  return post<BasicResponseDTO, null>(`rankings/${rankingId}/import`);
};

export const updateParticipationStatusRequest = (
  rankingId: number,
  schoolId: number,
  updateParticipationStatusData: UpdateParticipationStatusDTO,
): Promise<BasicResponseDTO> => {
  return put<BasicResponseDTO, UpdateParticipationStatusDTO>(
    `rankings/${rankingId}/schools/${schoolId}/participation`,
    updateParticipationStatusData,
  );
};

import React, { FunctionComponent, useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';

import { IconDelete, IconEdit, IconSave } from '../../../../../components/CustomIcons';
import Grid from '../../../../../components/Grid';
import { jsonParse } from '../../../../../shared/helpers';
import { EditableWidgetContent } from '../EditableWidgetContent/EditableWidgetContent';
import { WIDGET_TYPE } from '../SchoolWidgetDetails.constants';
import { WidgetDTO, WidgetElements } from './EditableWidget.constants';

import styles from './EditableWidget.module.scss';
import Checkbox from '../../../../../components/Checkbox';

interface EditableWidgetProps {
  orderChangeable: boolean;
  selectedForOrder: boolean;
  widget: WidgetDTO;
  onEdit: (data: WidgetDTO) => void;
  onDelete: (data: WidgetDTO) => void;
  onSelectForOrder: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const EditableWidget: FunctionComponent<EditableWidgetProps> = ({
  orderChangeable,
  selectedForOrder,
  widget,
  onEdit,
  onDelete,
  onSelectForOrder,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [widgetLinksList, setWidgetLinksList] = useState<Element[]>([]);
  const [widgetElements, setWidgetElements] = useState<WidgetElements>({} as WidgetElements);

  const [editedWidgetElements, setEditedWidgetElements] = useState<WidgetElements>({} as WidgetElements);

  useEffect(() => {
    const createWidgetElements = (): void => {
      const widgetHtml = document.createElement('DIV').cloneNode() as Element;
      widgetHtml.innerHTML = widget.html;

      const element = widgetHtml.querySelector('.widgetwrapper')?.cloneNode(true) as Element;
      if (!element) return;

      const title = element.querySelector('.widgettitle');
      const icon = element.querySelector('.iconelement');
      const content = element.querySelector('.htmlcontents');
      const linksList = Array.from(element.querySelectorAll('.linklist li a') || []);

      let createdWidgetElements: WidgetElements = {
        details: { ...jsonParse(widget.details) },
        title: title || addTitleElement(),
        icon,
        content,
        element,
        linksList,
      };

      title?.remove();
      icon?.remove();
      element.remove();

      if (widget.type === WIDGET_TYPE.HTML || widget.type === WIDGET_TYPE.VIDEO) {
        (content as Element).textContent = content?.innerHTML.toString() || '';
        createdWidgetElements = { ...createdWidgetElements, content };
      }

      setWidgetElements({ ...createdWidgetElements });
      setWidgetLinksList(linksList);
    };

    createWidgetElements();
  }, [widget]);

  const addTitleElement = (): Element => {
    const titleElement = document.createElement('h3');
    titleElement.classList.add('widgettitle');
    return titleElement;
  };

  const onSaveChanges = (): void => {
    if (!editedWidgetElements.element || !editedWidgetElements.content) return;

    const htmlElement = editedWidgetElements.element.cloneNode(true) as Element;
    const htmlContent = editedWidgetElements.content.cloneNode(true) as Element;

    if (!htmlElement || !htmlContent) return;
    if (widget.type === WIDGET_TYPE.VIDEO || widget.type === WIDGET_TYPE.HTML) {
      htmlContent.innerHTML = htmlContent.textContent || '';
    }
    const content = (htmlElement as Element).querySelector('.htmlcontents');

    if (!content || !content.parentNode) return;
    if (editedWidgetElements.title) {
      htmlContent.insertBefore(editedWidgetElements?.title, htmlContent.firstChild);
    }

    if (editedWidgetElements.icon) {
      htmlContent.insertBefore(editedWidgetElements?.icon, htmlContent.firstChild);
    }

    content.parentNode.replaceChild(htmlContent, content);
    onEdit({ ...widget, html: htmlElement.outerHTML, details: JSON.stringify(widgetElements.details) });
    setIsEditing(false);
  };

  const onValueChange = (value: WidgetElements): void => {
    setEditedWidgetElements({ ...value });
  };

  const onEditMode = (): void => {
    setEditedWidgetElements({
      details: { ...widgetElements.details },
      title: widgetElements.title?.cloneNode(true) as Element,
      icon: widgetElements.icon?.cloneNode(true) as Element,
      content: widgetElements.content?.cloneNode(true) as Element,
      element: widgetElements.element?.cloneNode(true) as Element,
      linksList: widgetElements.linksList ? [...widgetElements.linksList] : [],
    });
    setIsEditing(true);
  };

  const onCancelEdit = (): void => {
    setEditedWidgetElements({ ...widgetElements });
    setIsEditing(false);
  };

  const handleDeleteWidget = (): void => {
    onDelete(widget);
  };

  const onRemoveListItem = (index: number): void => {
    editedWidgetElements.content?.querySelectorAll('.linklist li')[index].remove();
    setEditedWidgetElements({ ...editedWidgetElements });
  };

  const onAddListItem = (): void => {
    const elementContent = editedWidgetElements.content?.cloneNode(true) as Element;
    const listElement = elementContent?.querySelector('.linklist');
    if (!listElement) return;
    const newElement = document.createElement('li');
    const newLinkElement = document.createElement('a');
    newElement.appendChild(newLinkElement);
    listElement?.appendChild(newElement);
    setWidgetLinksList([...widgetLinksList, newLinkElement]);
    setEditedWidgetElements({ ...editedWidgetElements, content: elementContent });
  };

  const getImagesUrl = (): { original: string }[] => {
    const widgetContent = isEditing ? editedWidgetElements : widgetElements;
    const sliderImages = widgetContent.content?.querySelectorAll('.slidecontainer .slidecontainerinner img');
    if (!sliderImages) return [];

    return Array.from(sliderImages)
      .map((item) => item.getAttribute('src') || '')
      .map((image) => ({ original: image }));
  };

  return (
    <>
      {widgetElements && (
        <Grid container>
          <Grid item md={12} xs={12} className="mba-no-padding">
            <Grid item md={6} xs={12} className="mba-no-padding">
              <div className={styles.widgetWrapper}>
                <p className={`${styles.widgetType} mba-mr-10`}>{widget.type}</p>
                {orderChangeable && (
                  <Checkbox
                    id={widget.id.toString()}
                    className={styles.widgetCheckbox}
                    name={widget.id.toString()}
                    value={selectedForOrder}
                    onChange={onSelectForOrder}
                  />
                )}
              </div>

              <h3 className={styles.widgetTitle}>
                {isEditing ? editedWidgetElements?.title?.textContent : widgetElements?.title?.textContent}
              </h3>
            </Grid>
          </Grid>
          <Grid item md={5} xs={12} className="mba-no-padding mba-mb-20">
            <div className={styles.editableWidget}>
              <div className={styles.widgetContent}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: isEditing
                      ? editedWidgetElements?.title?.outerHTML || ''
                      : widgetElements?.title?.outerHTML || '',
                  }}
                />
                {widget.type === WIDGET_TYPE.SLIDESHOW && (
                  <ImageGallery
                    showBullets={false}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    autoPlay={true}
                    showThumbnails={false}
                    items={getImagesUrl()}
                  />
                )}
                {(widget.type === WIDGET_TYPE.HTML || widget.type === WIDGET_TYPE.VIDEO) && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: isEditing
                        ? editedWidgetElements?.content?.textContent || ''
                        : widgetElements?.content?.textContent || '',
                    }}
                  />
                )}
                {widget.type === WIDGET_TYPE.FILELIST && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: isEditing
                        ? editedWidgetElements?.content?.innerHTML || ''
                        : widgetElements?.content?.innerHTML || '',
                    }}
                  />
                )}
              </div>
            </div>
          </Grid>
          <Grid item md={7} xs={12} className="mba-no-padding">
            {isEditing && (
              <Grid item md={9} xs={9} className="mba-no-padding">
                <EditableWidgetContent
                  type={widget.type}
                  onValueChange={onValueChange}
                  widget={editedWidgetElements}
                  widgetLinksList={widgetLinksList}
                  onAddListItem={onAddListItem}
                  onRemoveListItem={onRemoveListItem}
                />
              </Grid>
            )}
            <Grid item md={!isEditing ? 4 : 3} xs={!isEditing ? 4 : 3} className="mba-no-padding">
              {!isEditing ? (
                <div className={styles.widgetButtonsWrapper}>
                  <button className={`${styles.widgetButton} ${styles.primary}`} onClick={onEditMode}>
                    <IconEdit width="12" height="12" />
                    <span>Edit</span>
                  </button>
                  <button className={`${styles.widgetButton} ${styles.danger}`} onClick={handleDeleteWidget}>
                    <IconDelete width="12" height="12" />
                    <span>Delete</span>
                  </button>
                </div>
              ) : (
                <>
                  <button className={`${styles.widgetButton} ${styles.primary}`} onClick={onSaveChanges}>
                    <IconSave />
                    <span>Save</span>
                  </button>
                  <button className={`${styles.widgetButton}`} onClick={onCancelEdit}>
                    <IconDelete width="12" height="12" fill="#000000" />
                    <span>Cancel</span>
                  </button>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default EditableWidget;

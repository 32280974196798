import { RankingSpreadsheetDTO } from './spreadsheets.dto';
import {
  DELETE_ALL_SPREADSHEETS,
  DELETE_SPREADSHEET,
  DOWNLOAD_RANKING_SPREADSHEET,
  DOWNLOAD_RANKING_SPREADSHEET_CONTACTS,
  DeleteAllSpreadsheetsAction,
  DeleteSpreadsheetAction,
  DownloadRankingSpreadsheetAction,
  DownloadRankingSpreadsheetContactsAction,
  LOAD_RANKING_SPREADSHEETS,
  LoadRankingSpreadsheetsAction,
  SET_RANKING_SPREADSHEETS,
  SetRankingSpreadsheetsAction,
} from './spreadsheets.types';
import { PageSearchQueryConfig, TableListData } from '../../../../../shared/constants';

export const loadRankingSpreadsheetsAction = (
  rankingId: number,
  type: string,
  pageConfig?: PageSearchQueryConfig,
): LoadRankingSpreadsheetsAction => ({
  type: LOAD_RANKING_SPREADSHEETS,
  payload: { rankingId, type, pageConfig },
});

export const setRankingSpreadsheetsAction = (
  type: string,
  tableData: TableListData<RankingSpreadsheetDTO>,
): SetRankingSpreadsheetsAction => ({
  type: SET_RANKING_SPREADSHEETS,
  payload: { type, tableData },
});

export const downloadRankingSpreadsheetAction = (
  rankingId: number,
  type: string,
  pageConfig?: PageSearchQueryConfig,
): DownloadRankingSpreadsheetAction => ({
  type: DOWNLOAD_RANKING_SPREADSHEET,
  payload: { rankingId, type, pageConfig },
});

export const downloadRankingSpreadsheetContactsAction = (
  rankingId: number,
  type: string,
  pageConfig?: PageSearchQueryConfig,
): DownloadRankingSpreadsheetContactsAction => ({
  type: DOWNLOAD_RANKING_SPREADSHEET_CONTACTS,
  payload: { rankingId, type, pageConfig },
});

export const deleteSpreadsheetAction = (rankingId: number, id: string | undefined): DeleteSpreadsheetAction => ({
  type: DELETE_SPREADSHEET,
  payload: { rankingId, id },
});

export const deleteAllSpreadsheetAction = (rankingId: number, type: string): DeleteAllSpreadsheetsAction => ({
  type: DELETE_ALL_SPREADSHEETS,
  payload: { rankingId, type },
});

import { FiltersConfig } from '../../../../components/FilterGroup';

export const SCHOOL_LEADS_TABLE_FILTERS: FiltersConfig = {
  countryId: {
    isDropdown: true,
    title: 'Country',
    options: [],
  },
  status: {
    title: 'Status',
    options: [],
  },
};

export const schoolLeadStatuses = [
  {
    color: '#006B36',
    key: 0,
    label: 'New',
  },
  {
    color: '#006B36',
    key: 3,
    label: 'Pending',
  },
  {
    color: '#006B36',
    key: 1,
    label: 'Contacted',
  },
  {
    color: '#006B36',
    key: 2,
    label: 'Rejected',
  },
];

import { ColumnDefinition, TableHeaderColumnTypes } from '../../../../components/Table';

export const SCHOOL_ACCOUNTS_TABLE_COLUMN_DEFINITION: ColumnDefinition[] = [
  {
    id: 'status',
    title: 'Status',
    type: TableHeaderColumnTypes.status,
    sorting: true,
  },
  {
    id: 'name',
    path: '/schools/profile/:mainSchoolId/users/:id',
    sorting: true,
    title: 'Name',
    type: TableHeaderColumnTypes.compositeLink,
    width: '180px',
  },
  {
    id: 'role',
    title: 'Role',
    type: TableHeaderColumnTypes.capitalize,
    width: '110px',
  },
  {
    id: 'email',
    title: 'E-mail',
    sorting: true,
  },
  {
    id: 'school',
    title: 'School',
    type: TableHeaderColumnTypes.compositeLink,
    path: '/schools/profile/:school.key/details',
  },
];

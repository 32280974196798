import { FormConfig } from '../../../../components/Form';

export const RANKING_CATEGORY_DETAILS_CONFIG: FormConfig = {
  name: {
    fieldType: 'text',
    placeholder: 'Enter name',
    required: true,
    title: 'Name',
    validations: {
      required: {
        errorText: `Please enter a name!`,
      },
    },
  },
  shortName: {
    fieldType: 'text',
    placeholder: 'Enter short name',
    required: true,
    title: 'Short name',
    validations: {
      required: {
        errorText: `Please enter a short name!`,
      },
    },
  },
  slug: {
    fieldType: 'text',
    placeholder: 'Enter slug',
    required: true,
    title: 'Slug',
    validations: {
      required: {
        errorText: `Please enter a valid slug!`,
      },
    },
  },
};

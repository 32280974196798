import { getTableListDefaultState } from '../../../../../../../shared/helpers';
import { RankingFieldDataActionTypes, RankingFieldDataState, SET_RANKING_FIELD_DATA } from './ranking-field-data.types';
import { RankingFieldDataItemDTO } from './ranking-field-data.dto';

const initialState: RankingFieldDataState = {
  rankingFieldData: getTableListDefaultState<RankingFieldDataItemDTO>(),
};

const rankingFieldReducer = (state = initialState, action: RankingFieldDataActionTypes): RankingFieldDataState => {
  switch (action.type) {
    case SET_RANKING_FIELD_DATA: {
      return {
        ...state,
        rankingFieldData: { ...action.payload.data },
      };
    }
    default: {
      return state;
    }
  }
};

export default rankingFieldReducer;

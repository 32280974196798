import {
  CLEAR_CURRENT_SCHOOL,
  CREATE_SCHOOL,
  EDIT_SCHOOL,
  SchoolActionTypes,
  SchoolProfileState,
  SET_SCHOOL,
} from './school-details.types';

const initialState: SchoolProfileState = {} as SchoolProfileState;

const schoolDetailsReducer = (state = initialState, action: SchoolActionTypes): SchoolProfileState => {
  switch (action.type) {
    case CLEAR_CURRENT_SCHOOL: {
      return initialState;
    }
    case CREATE_SCHOOL:
    case EDIT_SCHOOL: {
      return {
        ...action.payload.data,
      };
    }
    case SET_SCHOOL: {
      return action.payload.data;
    }
    default: {
      return state;
    }
  }
};

export default schoolDetailsReducer;

import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from '../../../../../components/DatePicker';
import FilterGroup, { mapInitialValuesToFiltersConfig } from '../../../../../components/FilterGroup';
import SearchInput from '../../../../../components/SearchInput';
import { PageFilterConfig, PageSearchQueryConfig } from '../../../../../shared/constants';
import { formatDateString } from '../../../../../shared/helpers';
import { loadSchoolsWithLeadGenAction } from '../../schools-with-lead-gen.actions';
import { selectSchoolWithLeadGenPageConfiguration } from '../../schools-with-lead-gen.selectors';
import {
  SCHOOLS_WITH_LEAD_GEN_FILTER_OPTIONS,
  SCHOOLS_WITH_LEAD_GEN_TABLE_FILTERS,
} from './SchoolsWithLeadGenSearchAndFilters.constants';

const SchoolsWithLeadGenSearchAndFilters: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [leadGenFilters, setLeadGenFilters] = useState({});
  const pageConfig = useSelector(selectSchoolWithLeadGenPageConfiguration);
  const { filter, search } = pageConfig;

  useEffect(() => {
    setLeadGenFilters(
      mapInitialValuesToFiltersConfig(
        SCHOOLS_WITH_LEAD_GEN_TABLE_FILTERS,
        {
          status: SCHOOLS_WITH_LEAD_GEN_FILTER_OPTIONS,
        },
        filter,
        true,
      ),
    );
  }, [filter]);

  const handleSearchChange = (newPageConfig: PageSearchQueryConfig): void => {
    dispatch(loadSchoolsWithLeadGenAction(newPageConfig));
  };

  const handleFiltersChange = (newFilters: PageFilterConfig): void => {
    dispatch(loadSchoolsWithLeadGenAction({ ...pageConfig, filter: newFilters }));
  };

  const handleDateFilterChange = (event: ChangeEvent<HTMLInputElement>): void => {
    let newFilters = { ...filter };
    const { name, value } = event.target;
    if (value) {
      const formattedDate = formatDateString(new Date(value).toString(), 'YYYY-MM-DD');
      newFilters = {
        ...filter,
        [name]: [formattedDate],
      };
    } else {
      delete newFilters[name];
    }
    dispatch(loadSchoolsWithLeadGenAction({ ...pageConfig, filter: newFilters }));
  };

  return (
    <div className="sticky-sidebar-container">
      <SearchInput
        id="lead-gen-search"
        pageConfig={pageConfig}
        placeholder="Search by name or ID"
        onSearchChanged={handleSearchChange}
        searchTerm={search}
      />
      <FilterGroup
        defaultFilters={filter || {}}
        filtersConfig={leadGenFilters}
        onFiltersChanged={handleFiltersChange}
      />
      <div style={{ display: 'flex' }}>
        <DatePicker title="Active from" placeholder="DD/MM/YY" name="activeFrom" onChange={handleDateFilterChange} />
        <div style={{ width: 20 }} />
        <DatePicker title="Active to" placeholder="DD/MM/YY" name="activeTo" onChange={handleDateFilterChange} />
      </div>
    </div>
  );
};

export default SchoolsWithLeadGenSearchAndFilters;

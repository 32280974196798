import { AddRankingCalendarDto, RankingCalendarDto, RankingCalendarUpdateDTO } from './calendar.dto';

export const LOAD_RANKING_CALENDARS = 'LOAD_RANKING_CALENDARS';
export const SET_RANKING_CALENDARS = 'SET_RANKING_CALENDARS';
export const CREATE_RANKING_CALENDAR = 'CREATE_RANKING_CALENDAR';
export const ADD_RANKING_CALENDAR = 'ADD_RANKING_CALENDAR';
export const UPDATE_RANKING_CALENDARS = 'UPDATE_RANKING_CALENDARS';

export interface LoadRankingCalendarsAction {
  type: typeof LOAD_RANKING_CALENDARS;
}

export interface SetRankingCalendarsAction {
  type: typeof SET_RANKING_CALENDARS;
  payload: { data: RankingCalendarDto[] };
}

export interface CreateRankingCalendarAction {
  type: typeof CREATE_RANKING_CALENDAR;
  payload: { data: AddRankingCalendarDto };
}

export interface AddRankingCalendarAction {
  type: typeof ADD_RANKING_CALENDAR;
  payload: { data: RankingCalendarDto };
}

export interface UpdateRankingCalendarsAction {
  type: typeof UPDATE_RANKING_CALENDARS;
  payload: { data: RankingCalendarUpdateDTO };
}

export type CalendarActionTypes =
  | LoadRankingCalendarsAction
  | CreateRankingCalendarAction
  | AddRankingCalendarAction
  | UpdateRankingCalendarsAction
  | SetRankingCalendarsAction;

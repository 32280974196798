import React, { FunctionComponent, useState } from 'react';

import { clearCacheRequest } from '../../../../../../../shared/state/settings';

import styles from './ClearCacheButton.module.scss';
import Button from '../../../../../../../components/Button';
import { useToasts } from 'react-toast-notifications';

type ClearCacheButtonProps = {
  handleNavElementClick?: () => void;
};

export const ClearCacheButton: FunctionComponent<ClearCacheButtonProps> = ({ handleNavElementClick }) => {
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onClearCache = async (): Promise<void> => {
    setIsLoading(true);
    if (handleNavElementClick) {
      handleNavElementClick();
    }

    try {
      await clearCacheRequest();
      setIsLoading(false);
      addToast('Cache Cleared', { appearance: 'success' });
    } catch (e: any) {
      setIsLoading(false);
      addToast(`Failed! ${e.message}`, { appearance: 'error' });
    }
  };

  return (
    <Button
      isLoading={isLoading}
      wrapperClass={styles.clearCacheButton}
      onClick={onClearCache}
      text={'Clear Cache'}
    ></Button>
  );
};

import { FormConfig } from '../../../../../components/Form';
import { ColumnDefinition, TableHeaderColumnTypes } from '../../../../../components/Table';

export const RANKING_CATEGORIES_MAIN_TABLE_ROUTE = '/rankings/categorization/ranking-categories';

export const RANKING_CATEGORY_FORM_CONFIG: FormConfig = {
  name: {
    label: 'Name',
    placeholder: 'Enter name',
    fieldType: 'text',
    validations: {
      required: {
        errorText: `Please enter a name!`,
      },
    },
  },
  shortName: {
    label: 'Short name',
    placeholder: 'Enter short name',
    fieldType: 'text',
    validations: {
      required: {
        errorText: `Please enter a short name!`,
      },
    },
  },
};

export const RANKING_CATEGORIES_TABLE_COLUMN_DEFINITION: ColumnDefinition[] = [
  {
    id: 'name',
    sorting: true,
    title: 'Name',
    type: TableHeaderColumnTypes.link,
    path: '/rankings/categorization/ranking-categories/:id/details',
  },
  {
    id: 'shortName',
    sorting: true,
    title: 'Short name',
    width: '160px',
  },
  {
    id: 'count',
    sorting: true,
    title: 'Rankings',
    type: TableHeaderColumnTypes.numeric,
    width: '60px',
  },
  {
    id: 'updatedAt',
    sorting: true,
    title: 'Last updated',
    type: TableHeaderColumnTypes.date,
    width: '160px',
  },
];

import { RankingFieldDataItemDTO } from './ranking-field-data.dto';
import { RootState } from '../../../../../../../store/reducers';
import { PageSearchQueryConfig } from '../../../../../../../shared/constants';

export const selectRankingFieldData = (state: RootState): RankingFieldDataItemDTO[] =>
  state.rankings.rankingFieldData.rankingFieldData.data;

export const selectRankingFieldDataPageConfiguration = (state: RootState): PageSearchQueryConfig => ({
  filter: state.rankings.rankingFieldData.rankingFieldData?.filter,
  limit: state.rankings.rankingFieldData.rankingFieldData.pagination?.limit,
  order: state.rankings.rankingFieldData.rankingFieldData.sortOrder?.order,
  page: state.rankings.rankingFieldData.rankingFieldData.pagination?.page,
  search: state.rankings.rankingFieldData.rankingFieldData?.search,
  sortBy: state.rankings.rankingFieldData.rankingFieldData.sortOrder?.sortBy,
  totalPages: state.rankings.rankingFieldData.rankingFieldData.pagination?.totalPages,
});

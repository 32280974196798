import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

import { setSchoolAccountsAction } from './school-accounts.actions';
import { downloadSchoolAccountsSpreadsheetRequest, getSchoolAccountsRequest } from './school-accounts.api';
import {
  DOWNLOAD_SCHOOL_ACCOUNTS_SPREADSHEET,
  DownloadSchoolAccountsSpreadsheetAction,
  LOAD_SCHOOL_ACCOUNTS,
  LoadSchoolAccountsAction,
  SchoolAccountsActionTypes,
} from './school-accounts.types';
import { SchoolUserDTO } from '../SchoolUserProfile';
import { BasicResponseDTO, PagedResponseDTO } from '../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../shared/state/global-request';

function* loadSchoolAccountsTableData(
  action: LoadSchoolAccountsAction,
): Generator<
  CallEffect<PagedResponseDTO<SchoolUserDTO[]>> | PutEffect<SchoolAccountsActionTypes> | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig } = action.payload;
    const { data, pagination, sortOrder } = (yield call(getSchoolAccountsRequest, pageConfig)) as PagedResponseDTO<
      SchoolUserDTO[]
    >;
    yield put(setSchoolAccountsAction({ data, pagination, sortOrder }));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* downloadSchoolAccountsSpreadsheet(
  action: DownloadSchoolAccountsSpreadsheetAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<SchoolAccountsActionTypes> | PutEffect<GlobalRequestActions>> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig } = action.payload;
    yield call(downloadSchoolAccountsSpreadsheetRequest, pageConfig);
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* schoolAccountsListSaga(): Generator {
  yield takeLatest(LOAD_SCHOOL_ACCOUNTS, loadSchoolAccountsTableData);
  yield takeLatest(DOWNLOAD_SCHOOL_ACCOUNTS_SPREADSHEET, downloadSchoolAccountsSpreadsheet);
}

import React, { FC, useState } from 'react';
import { RankingTimelineStepDTO } from '../../RankingDetails/TimelineSteps';
import Grid from '../../../../../../components/Grid';
import TimelineIndicator, { TimelineStepStatus } from './TimelineIndicator';
import Button from '../../../../../../components/Button';
import { getHtmlFromJSON } from '../../../../../../components/RichTextEditor/RichTextEditor.helpers';
import styles from '../RankingTimeline.module.scss';
import TimelineStepDates from './TimelineStepDates';

const AlumniSurveyLetterStep: FC<RankingTimelineStepDTO> = ({ name, content, rankingId, startDate, endDate }) => {
  const [expand, setExpand] = useState<boolean>(false);
  const [isWholeContentShown, setIsWholeContentShown] = useState(false);

  const handleAccordionToggleClick = (): void => {
    setIsWholeContentShown(!isWholeContentShown);
  };

  const handleAlumniLetterEdit = () => {
    window.location.href = `/rankings/profile/${rankingId}/details/instructions-and-deadlines#alumini-letter-step?edit=true`;
  };

  const __html = content ? getHtmlFromJSON(content) : '-';
  const contentClassName = isWholeContentShown ? '' : styles.shortenedAlumniLetter;

  return (
    <Grid container key={name}>
      <Grid item xs={5}>
        <TimelineStepDates
          endDate={endDate}
          endDateLabel="End"
          isEndDateRequired={true}
          startDate={startDate}
          startDateLabel="Start"
          isStartDateRequired={true}
        />
      </Grid>
      <Grid item xs={1} className="mba-no-padding">
        <TimelineIndicator timeIndication={content ? TimelineStepStatus.completed : undefined} />
      </Grid>
      <Grid item xs={6} className="mba-no-padding">
        <button
          className="mba-text--accent mba-no-margin mba-text--semi-bold mba-timeline-button--link"
          onClick={(): void => setExpand(!expand)}
        >
          {name}
        </button>
        {expand && (
          <>
            <br />
            {content && (
              <>
                <div
                  dangerouslySetInnerHTML={{ __html }}
                  style={{ marginBottom: '8px' }}
                  className={contentClassName}
                />

                <button className="mba-button--link" onClick={handleAccordionToggleClick}>
                  {!isWholeContentShown ? 'Show more' : 'Show less'}
                </button>
              </>
            )}
            <br />
            <div className="mba-actions">
              <Button primary text={`${content ? 'Edit' : 'Add'} alumni letter`} onClick={handleAlumniLetterEdit} />
            </div>
            <br />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default AlumniSurveyLetterStep;

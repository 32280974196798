import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import CampusProfile from './CampusProfile';
import SchoolArticles from './SchoolArticles';
import SchoolDetails, { LOAD_SCHOOL, loadSchoolAction, SchoolDTO, selectCurrentSchool } from './SchoolDetails';
import SchoolPartnerships from './SchoolPartnerships';
import {
  SCHOOL_PROFILE_MAIN_ROUTE,
  SCHOOL_PROFILE_TABS_NAVIGATION,
  SchoolProfileRouteParams,
} from './SchoolProfile.constants';
import SchoolRankings from './SchoolRankings';
import { PageRouter } from '../../App/Main/components/Layout';
import SchoolUserProfile from '../SchoolUserProfile';
import SchoolUsers from './SchoolUsersList';
import Label, { LabelStatus } from '../../../components/Label';
import PageLoader from '../../../components/PageLoader';
import TabsNavigation from '../../../components/TabsNavigation';
import Typography, { TypographyVariants } from '../../../components/Typography';
import { selectRequestIsLoading, selectRequestStatus } from '../../../shared/state/global-request';
import NotFound from '../../../components/NotFound';

const componentsMap = {
  articles: SchoolArticles,
  campus: CampusProfile,
  details: SchoolDetails,
  partnerships: SchoolPartnerships,
  rankings: SchoolRankings,
  userProfile: SchoolUserProfile,
  users: SchoolUsers,
};

const SchoolProfile: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id: schoolId } = useParams<SchoolProfileRouteParams>();
  const { id, name, status } = useSelector(selectCurrentSchool) as SchoolDTO;
  const requestStatus = useSelector(selectRequestStatus(LOAD_SCHOOL));
  const tabsNavigation = Object.values(SCHOOL_PROFILE_TABS_NAVIGATION).map(({ label, route }) => ({
    label,
    route: `${SCHOOL_PROFILE_MAIN_ROUTE.replace(':id', schoolId)}${route}`,
  }));
  const isLoading = useSelector(selectRequestIsLoading(LOAD_SCHOOL));

  useEffect(() => {
    if (schoolId) {
      dispatch(loadSchoolAction(parseInt(schoolId)));
    }
  }, [dispatch, schoolId]);

  return (
    <>
      {id ? (
        <>
          <Typography component="h1" variant={TypographyVariants.h1} className="mba-center--vertically">
            (ID: {id})&nbsp;{name}&nbsp;
            {status && (
              <Label color={status.color} status={LabelStatus.active}>
                {status.label}
              </Label>
            )}
          </Typography>
          <TabsNavigation tabs={tabsNavigation} big />
          <PageRouter
            mainRoute={SCHOOL_PROFILE_MAIN_ROUTE}
            navigation={{
              ...SCHOOL_PROFILE_TABS_NAVIGATION,
              campus: {
                label: 'Campus details',
                route: '/campus/:campusId',
              },
              userProfile: {
                label: 'Accounts',
                route: '/users/:userId',
              },
            }}
            componentsMap={componentsMap}
          />
        </>
      ) : isLoading || !schoolId ? (
        <PageLoader />
      ) : (
        <h3>
          <NotFound />
        </h3>
      )}
    </>
  );
};

export default SchoolProfile;

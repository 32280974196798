import { SchoolWidgetGroupDto } from '../../../containers/Commercial/SchoolWidgetGroups/school-widget-groups.dto';
import { RootState } from '../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const schoolResourcesSelector = (state: RootState) => state.schoolResources;

export const selectSchoolListForDropdown = createDeepEqualSelector(schoolResourcesSelector, (resources) => {
  return resources.schools;
});

export const selectSingleSchoolWidgetGroups = (state: RootState): SchoolWidgetGroupDto[] =>
  state.schoolResources.schoolWidgetGroupsDto;

import { call, CallEffect, PutEffect, put, takeLatest } from 'redux-saga/effects';

import {
  LoadRankingFieldDataAction,
  LOAD_RANKING_FIELD_DATA,
  getRankingFieldDataRequest,
  RankingFieldDataItemDTO,
  RankingFieldDataActionTypes,
  setRankingFieldDataAction,
  EditRankingFieldDataItemAction,
  editRankingFieldDataItemRequest,
  loadRankingFieldDataAction,
  EDIT_RANKING_DATA_ITEM,
} from '../RankingFieldData';
import {
  GlobalRequestActions,
  setRequestStartedAction,
  setRequestSucceededAction,
  setRequestFailedAction,
} from '../../../../../../../shared/state/global-request';
import { BasicResponseDTO, DefaultResponseDTO, TableListData } from '../../../../../../../shared/constants';

function* loadRankingFieldDataSaga(
  action: LoadRankingFieldDataAction,
): Generator<
  | CallEffect<DefaultResponseDTO<RankingFieldDataItemDTO[]>>
  | PutEffect<GlobalRequestActions | RankingFieldDataActionTypes>
> {
  const {
    type: actionType,
    payload: { rankingFieldId, pageConfig },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const data = (yield call(getRankingFieldDataRequest, {
      rankingFieldId,
      pageConfig,
    })) as TableListData<RankingFieldDataItemDTO>;
    yield put(setRankingFieldDataAction(data));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* editRankingFieldDataItemSaga(
  action: EditRankingFieldDataItemAction,
): Generator<CallEffect<BasicResponseDTO> | PutEffect<GlobalRequestActions | RankingFieldDataActionTypes>> {
  const {
    type: actionType,
    payload: { rankingFieldId, rankingFieldDataItemId, value },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(editRankingFieldDataItemRequest, rankingFieldDataItemId, value);
    yield put(loadRankingFieldDataAction(rankingFieldId));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* rankingFieldProfileSaga(): Generator {
  yield takeLatest(LOAD_RANKING_FIELD_DATA, loadRankingFieldDataSaga);
  yield takeLatest(EDIT_RANKING_DATA_ITEM, editRankingFieldDataItemSaga);
}

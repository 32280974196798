import { Resource } from '../../constants';

export const LOAD_RANKING_TYPES_DROPDOWN = 'LOAD_RANKING_TYPES_DROPDOWN';
export const SET_RANKING_TYPES_DROPDOWN = 'SET_RANKING_TYPES_DROPDOWN';

export interface LoadRankingTypesDropdownAction {
  type: typeof LOAD_RANKING_TYPES_DROPDOWN;
}

export interface SetRankingTypesDropdownAction {
  type: typeof SET_RANKING_TYPES_DROPDOWN;
  payload: { rankingTypes: Resource[] };
}

export type RankingTypesActionDropDownTypes = LoadRankingTypesDropdownAction | SetRankingTypesDropdownAction;

export interface RankingTypeDropdownState {
  rankingTypes: Resource[];
}

import { call, CallEffect, put, PutEffect, select, SelectEffect, takeEvery, takeLatest } from 'redux-saga/effects';

import { BasicResponseDTO, DefaultResponseDTO } from '../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../shared/state/global-request';
import { loadResourcesAction, ResourcesActionTypes } from '../../../shared/state/resources';
import {
  loadSchoolUsersForSchoolAction,
  SchoolUsersActionTypes,
  selectCurrentSchoolUser,
} from '../SchoolProfile/SchoolUsersList';
import { loadSchoolUserAction, setSchoolUserAction } from './school-user-profile.actions';
import {
  createSchoolUserRequest,
  deleteSchoolUserRequest,
  editSchoolUserRequest,
  getSchoolUserRequest,
  updateSchoolUserRoleAndPermissionsRequest,
} from './school-user-profile.api';
import { SaveSchoolUserDTO, SchoolUserDTO } from './school-user-profile.dto';
import {
  CLEAR_CURRENT_SCHOOL_USER,
  CREATE_SCHOOL_USER,
  CreateSchoolUserAction,
  DELETE_SCHOOL_USER,
  DeleteSchoolUserAction,
  EDIT_SCHOOL_USER,
  EditSchoolUserAction,
  LOAD_SCHOOL_USER,
  LoadSchoolUserAction,
  SchoolUserActionTypes,
  UPDATE_SCHOOL_USER_ROLES_AND_PERMISSIONS,
  UpdateSchoolUserRolesAndPermissionsAction,
} from './school-user-profile.types';

function* createNewSchoolUserSaga(
  action: CreateSchoolUserAction,
): Generator<
  | PutEffect<GlobalRequestActions | SchoolUserActionTypes | SchoolUsersActionTypes>
  | CallEffect<DefaultResponseDTO<SchoolUserDTO>>
> {
  const {
    type: actionType,
    payload: { data, schoolId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(createSchoolUserRequest, data, schoolId);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadSchoolUsersForSchoolAction(schoolId));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* loadSchoolUserSaga(
  action: LoadSchoolUserAction,
): Generator<CallEffect<DefaultResponseDTO<SchoolUserDTO>> | PutEffect<GlobalRequestActions | SchoolUserActionTypes>> {
  const {
    type: actionType,
    payload: { id, schoolId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { data } = (yield call(getSchoolUserRequest, id, schoolId)) as DefaultResponseDTO<SchoolUserDTO>;
    yield put(setSchoolUserAction(data, schoolId));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* updateSchoolUserRoleAndPermissionsSaga(
  action: UpdateSchoolUserRolesAndPermissionsAction,
): Generator<
  | PutEffect<GlobalRequestActions | SchoolUserActionTypes | SchoolUsersActionTypes>
  | CallEffect<BasicResponseDTO>
  | SelectEffect
> {
  const {
    type: actionType,
    payload: { data, partnershipId, schoolId, userId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(updateSchoolUserRoleAndPermissionsRequest, data, partnershipId, userId);

    const { data: schoolUser } = (yield call(
      getSchoolUserRequest,
      userId,
      schoolId,
    )) as DefaultResponseDTO<SchoolUserDTO>;

    const currentSchoolUser = (yield select(selectCurrentSchoolUser)) as SchoolUserDTO;
    currentSchoolUser.permissions = schoolUser.permissions;
    currentSchoolUser.role = schoolUser.role;
    yield put(setSchoolUserAction(currentSchoolUser, schoolId));

    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* deleteSchoolUser(
  action: DeleteSchoolUserAction,
): Generator<
  | PutEffect<GlobalRequestActions | SchoolUserActionTypes | SchoolUsersActionTypes | ResourcesActionTypes>
  | CallEffect<BasicResponseDTO>
> {
  const {
    type: actionType,
    payload: { id, schoolId },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(deleteSchoolUserRequest, id, schoolId);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadSchoolUsersForSchoolAction(schoolId));
    yield put(loadResourcesAction(`schools/${schoolId}/accounts`));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* editSchoolUser(
  action: EditSchoolUserAction,
): Generator<
  PutEffect<GlobalRequestActions | SchoolUserActionTypes | SchoolUsersActionTypes> | CallEffect<BasicResponseDTO>
> {
  const {
    type: actionType,
    payload: { data, schoolId },
  } = action;
  const schoolUser = { ...data, status: data.status?.key ? data.status?.key : data.status } as SaveSchoolUserDTO;

  try {
    yield put(setRequestStartedAction(actionType));
    yield call(editSchoolUserRequest, schoolUser, schoolId);
    yield put(setRequestSucceededAction(actionType));
    yield put(loadSchoolUserAction(data.id, schoolId));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* resetSchoolUserErrors(): Generator<PutEffect<GlobalRequestActions>> {
  yield put(setRequestSucceededAction(CREATE_SCHOOL_USER));
  yield put(setRequestSucceededAction(EDIT_SCHOOL_USER));
  yield put(setRequestSucceededAction(DELETE_SCHOOL_USER));
  yield put(setRequestSucceededAction(UPDATE_SCHOOL_USER_ROLES_AND_PERMISSIONS));
}

export default function* schoolUserProfileSaga(): Generator {
  yield takeLatest(CREATE_SCHOOL_USER, createNewSchoolUserSaga);
  yield takeLatest(LOAD_SCHOOL_USER, loadSchoolUserSaga);
  yield takeLatest(EDIT_SCHOOL_USER, editSchoolUser);
  yield takeLatest(DELETE_SCHOOL_USER, deleteSchoolUser);
  yield takeLatest(UPDATE_SCHOOL_USER_ROLES_AND_PERMISSIONS, updateSchoolUserRoleAndPermissionsSaga);
  yield takeEvery(CLEAR_CURRENT_SCHOOL_USER, resetSchoolUserErrors);
}

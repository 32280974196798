import React, { FunctionComponent } from 'react';
import { INLINE_STYLES, StyleControlsProps } from './RichTextEditor.constants';
import StyleButton from './StyleButton';

const InlineStyleControls: FunctionComponent<StyleControlsProps> = ({ editorState, onToggle }) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};
export default InlineStyleControls;

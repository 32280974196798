import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RankingCategoryForm from './RankingCategoryForm';
import { RANKING_CATEGORIES_TABLE_COLUMN_DEFINITION } from './RankingCategoriesList.constants';
import { loadRankingCategoriesAction } from '../ranking-categories.actions';
import {
  selectRankingCategoriesPageConfiguration,
  selectRankingCategoriesTableData,
} from '../ranking-categories.selectors';
import {
  clearCurrentRankingCategoryAction,
  RankingCategoryDTO,
  setRankingCategoryAction,
} from '../../RankingCategoryProfile';
import Button, { ButtonIcons, ButtonSizes } from '../../../../../components/Button';
import Modal, { MODAL_ANIMATION_DURATION } from '../../../../../components/Modal';
import Pager, { INITIAL_PAGE } from '../../../../../components/Pager';
import Table, { TableHeaderColumnTypes } from '../../../../../components/Table';
import Typography, { TypographyVariants } from '../../../../../components/Typography';
import { ASCENDING_ORDER_DIRECTION, DESCENDING_ORDER_DIRECTION } from '../../../../../shared/constants';

const RankingCategoriesList: FunctionComponent = () => {
  const dispatch = useDispatch();
  const pageConfig = useSelector(selectRankingCategoriesPageConfiguration);
  const rankingCategoriesData = useSelector(selectRankingCategoriesTableData);
  const [isRankingCategoryEdit, setIsRankingCategoryEdit] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  useEffect(() => {
    dispatch(loadRankingCategoriesAction());
  }, [dispatch]);

  useEffect(() => {
    if (isFormModalOpen) {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rankingCategoriesData]);

  const handlePageChanged = (page: number): void => {
    dispatch(loadRankingCategoriesAction({ ...pageConfig, page }));
  };

  const handleSortByChanged = (fieldName: string): void => {
    const orderDirection =
      pageConfig.sortBy !== fieldName
        ? DESCENDING_ORDER_DIRECTION
        : pageConfig.order === ASCENDING_ORDER_DIRECTION
        ? DESCENDING_ORDER_DIRECTION
        : ASCENDING_ORDER_DIRECTION;
    dispatch(loadRankingCategoriesAction({ ...pageConfig, sortBy: fieldName, order: orderDirection }));
  };

  const openFormModal = (id?: number): void => {
    if (id) {
      const currentRankingCategory = rankingCategoriesData.find((ranking: RankingCategoryDTO) => ranking.id === id);
      if (currentRankingCategory) {
        dispatch(setRankingCategoryAction(currentRankingCategory));
        setIsRankingCategoryEdit(true);
      }
    } else {
      setIsRankingCategoryEdit(false);
    }

    setIsFormModalOpen(true);
  };

  const closeModal = (): void => {
    setIsFormModalOpen(false);

    setTimeout(() => {
      dispatch(clearCurrentRankingCategoryAction());
    }, MODAL_ANIMATION_DURATION);
  };

  const rankingCategoriesTableConfig = [
    ...RANKING_CATEGORIES_TABLE_COLUMN_DEFINITION,
    {
      id: 'actions',
      title: 'Action',
      type: TableHeaderColumnTypes.actions,
      width: '90px',
      actions: [
        {
          disableIfDefault: true,
          label: 'Edit',
          handleOnClick: openFormModal,
        },
      ],
    },
  ];

  return (
    <>
      <div className="mba-heading--wrapper mba-heading--table">
        <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title">
          Ranking Categories
        </Typography>
        <Button
          text="Create new category"
          size={ButtonSizes.big}
          icon={ButtonIcons.plus}
          disabled={isFormModalOpen}
          primary
          onClick={(): void => openFormModal()}
        />
      </div>
      <div className="mba-table-wrapper">
        <Table
          id="ranking-categories-list"
          columns={rankingCategoriesTableConfig}
          data={rankingCategoriesData}
          sortingOptions={{
            order: pageConfig.order || DESCENDING_ORDER_DIRECTION,
            sortBy: pageConfig.sortBy || '',
          }}
          onSortByColumn={handleSortByChanged}
        />
      </div>
      {pageConfig && pageConfig.totalPages !== INITIAL_PAGE && (
        <Pager
          currentPage={pageConfig.page}
          totalPages={pageConfig.totalPages || INITIAL_PAGE}
          onPageChange={handlePageChanged}
        />
      )}
      <Modal
        title={`${isRankingCategoryEdit ? 'Edit' : 'Create new'} ranking category`}
        isOpen={isFormModalOpen}
        onClose={closeModal}
      >
        <RankingCategoryForm onFormClose={closeModal} isEdit={isRankingCategoryEdit} />
      </Modal>
    </>
  );
};

export default RankingCategoriesList;

import { AddFaqDto, FaqDto } from './frequently-asked-questions.dto';

export const LOAD_FAQ = 'LOAD_FAQ';
export const SET_FAQ = 'SET_FAQ';
export const EDIT_FAQ = 'EDIT_FAQ';
export const ADD_FAQ = 'ADD_FAQ';
export const DELETE_FAQ = 'DELETE_FAQ';
export const GET_FAQ_IMAGE_LINK = 'GET_FAQ_IMAGE_LINK';
export const UPLOAD_FAQ_IMAGE = 'UPLOAD_FAQ_IMAGE';

export interface LoadFaqAction {
  type: typeof LOAD_FAQ;
}

export interface SetFaqAction {
  type: typeof SET_FAQ;
  payload: { data: FaqDto[] };
}

export interface AddFaqAction {
  type: typeof ADD_FAQ;
  payload: { data: AddFaqDto };
}

export interface EditFaqAction {
  type: typeof EDIT_FAQ;
  payload: { data: FaqDto };
}

export interface DeleteFaqAction {
  type: typeof DELETE_FAQ;
  payload: { id: number };
}

export type FrequentlyAskedQuestionsTypes =
  | LoadFaqAction
  | SetFaqAction
  | EditFaqAction
  | AddFaqAction
  | DeleteFaqAction;

import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button, { ButtonSizes } from '../../../../components/Button';
import Form, { FormDataValues } from '../../../../components/Form';
import { getHtmlFromJSON, getRawFromHTML } from '../../../../components/RichTextEditor/RichTextEditor.helpers';
import Typography, { TypographyVariants } from '../../../../components/Typography';
import { selectRequestIsLoading, selectRequestFormErrors } from '../../../../shared/state/global-request';
import {
  loadSettingAction,
  selectSetting,
  SettingDto,
  SettingKeys,
  UPDATE_SETTING,
  updateSettingAction,
} from '../../../../shared/state/settings';
import { METHODOLOGY_CONFIG } from './Methodology.constants';

const Methodology: FunctionComponent = () => {
  const dispatch = useDispatch();
  const methodology = useSelector(selectSetting(SettingKeys.methodology));
  const isLoading = useSelector(selectRequestIsLoading(UPDATE_SETTING));
  const formErrors = useSelector(selectRequestFormErrors(UPDATE_SETTING));
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(loadSettingAction(SettingKeys.methodology));
  }, [dispatch]);

  const resetFormState = useCallback((): void => {
    setIsEdit(false);
  }, []);

  const errorCount = Object.keys(formErrors).length;
  useEffect(() => {
    if (!isLoading && errorCount === 0) {
      resetFormState();
    }
  }, [errorCount, isLoading, resetFormState]);

  const updateMethodology = (data: FormDataValues): void => {
    dispatch(
      updateSettingAction({
        key: SettingKeys.methodology,
        value: data.description,
      } as SettingDto),
    );
  };

  return (
    <>
      <div className="mba-heading--wrapper mba-heading--table">
        <Typography component="p" variant={TypographyVariants.h1} className="mba-heading--title">
          Methodology
        </Typography>

        {!isEdit && <Button text="Edit" size={ButtonSizes.big} primary onClick={(): void => setIsEdit(true)} />}
      </div>

      {isEdit ? (
        <Form
          id="methodology-form"
          config={METHODOLOGY_CONFIG}
          onSubmit={updateMethodology}
          submitButtonText="Save changes"
          isLoading={isLoading}
          actions={[
            {
              label: 'Discard',
              onClick: resetFormState,
            },
          ]}
          initialValues={{ description: getRawFromHTML(methodology) }}
          errors={formErrors}
        />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: methodology ? getHtmlFromJSON(methodology) : '-' }} />
      )}
    </>
  );
};

export default Methodology;

import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { editRankingFieldDataItemAction } from '../../../../RankingFieldData';
import { selectRequestFormErrors } from '../../../../../../../../../../shared/state/global-request';
import { RankingFieldProfileRouteParams, selectCurrentRankingField } from '../../../../RankingFieldDetails';
import { RankingFieldDataItemDTO } from '../../../ranking-field-data.dto';
import { EDIT_RANKING_DATA_ITEM } from '../../../../RankingFieldData';
import Form from '../../../../../../../../../../components/Form';
import { RANKING_FIELD_DATA_FORM_CONFIG } from './RankingFieldDataForm.constants';

type RankingFormProps = {
  onFormClose: () => void;
  rankingFieldDataItem: RankingFieldDataItemDTO;
};

const RankingFieldDataForm: FunctionComponent<RankingFormProps> = ({ onFormClose, rankingFieldDataItem }) => {
  const { id: rankingFieldDataItemId } = rankingFieldDataItem;
  const { rankingFieldId } = useParams<RankingFieldProfileRouteParams>();
  const dispatch = useDispatch();
  const rankingFields = useSelector(selectCurrentRankingField);
  const formErrors = useSelector(selectRequestFormErrors(EDIT_RANKING_DATA_ITEM));

  const onRankingFieldDataSaveSubmit = (data: { value: number }): void => {
    dispatch(editRankingFieldDataItemAction(parseInt(rankingFieldId), rankingFieldDataItemId, data.value));
  };

  return (
    <Form
      id="ranking-field-data-item-form"
      config={{
        ...RANKING_FIELD_DATA_FORM_CONFIG,
        value: { ...RANKING_FIELD_DATA_FORM_CONFIG.value, label: rankingFields.name },
      }}
      onSubmit={onRankingFieldDataSaveSubmit}
      submitButtonText="Save"
      actions={[
        {
          label: 'Cancel',
          onClick: onFormClose,
        },
      ]}
      initialValues={rankingFieldDataItem}
      errors={formErrors}
    />
  );
};

export default RankingFieldDataForm;

import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { IconDelete } from '../../../../../components/CustomIcons';
import { FileUploadInput } from '../../../../../components/FileUpload';
import { FormValue } from '../../../../../components/Form';
import Grid from '../../../../../components/Grid';
import ViewInput from '../../../../../components/ViewInput';
import { createSlideshowImageAttachment } from '../../../../../shared/state/attachments/attachments.api';
import { WidgetElements } from '../EditableWidget/EditableWidget.constants';
import styles from '../EditableWidget/EditableWidget.module.scss';
import { WIDGET_TYPE } from '../SchoolWidgetDetails.constants';

interface EditableWidgetContentProps {
  type: string;
  widget: WidgetElements;
  widgetLinksList?: Element[];
  onValueChange?: (value: WidgetElements) => void;
  onRemoveListItem?: (index: number, item: Element, attr?: string) => void;
  onAddListItem?: () => void;
}

export const EditableWidgetContent: FunctionComponent<EditableWidgetContentProps> = ({
  type,
  widget,
  onValueChange,
  onAddListItem,
  onRemoveListItem,
}) => {
  const [widgetElements, setWidgetElements] = useState<WidgetElements>(widget);
  const [slideshowImages, setSlideshowImages] = useState<string[]>([]);

  const setData = useCallback(() => {
    const links = widget.content?.querySelectorAll('.linklist li a');
    setWidgetElements({
      ...widget,
      linksList: Array.from(links || []),
    });

    if (type === WIDGET_TYPE.SLIDESHOW) {
      const slideshowContent = widget.content?.querySelectorAll('.slidecontainer .slidecontainerinner img');
      if (!slideshowContent) return;
      const carouselImages = Array.from(slideshowContent).map((slide) => slide.getAttribute('src')) as string[];
      setSlideshowImages(carouselImages);
    }
  }, [widget, type]);

  useEffect(() => {
    setData();
  }, [setData]);

  const getListItemUrl = (item: Element): string => {
    return item.getAttribute('href') || '';
  };

  const onInputValueChange = (name: string, value: FormValue): void => {
    if (name === 'title' || name === 'content') {
      const newValues = widgetElements[name] as HTMLElement;
      if (typeof value === 'string') {
        if (type === WIDGET_TYPE.VIDEO || type === WIDGET_TYPE.HTML) {
          newValues.innerHTML = newValues.textContent || '';
        }
        (newValues as HTMLElement).textContent = value;

        onValueChange &&
          onValueChange({
            ...widgetElements,
            [name]: newValues,
            details: { ...widget.details, title: name === 'title' ? value.toString() : widgetElements.details.title },
          });
      }
    }
  };

  const onLinkValueChange = (value: FormValue, index: number, attr?: string): void => {
    if (typeof value !== 'string') return;
    const content = widgetElements.content?.querySelectorAll('.linklist li a');
    if (!content) return;
    const child = content[index] as Element;
    if (!child) return;
    !attr ? (child.textContent = value) : child.setAttribute(attr, value);
    widgetElements.linksList.splice(index, 1, child);
    setWidgetElements({ ...widgetElements });
    onValueChange && onValueChange({ ...widgetElements });
  };

  const onRemoveRow = (item: Element, index: number): void => {
    widgetElements.linksList.splice(index, 1);
    onRemoveListItem && onRemoveListItem(index, item);
  };

  const handleChange = async (files: FileList): Promise<void> => {
    const sliderInner = widgetElements.content?.querySelector('.slidecontainer .slidecontainerinner');

    if (!sliderInner) return;

    for (const key of Object.keys(files)) {
      const formData = new FormData();
      formData.append('file', files[+key]);
      const { data } = await createSlideshowImageAttachment(formData);

      const image = document.createElement('img');
      image.setAttribute('src', data.s3Url);
      image.setAttribute('data-image-type', files[+key].type);
      image.setAttribute('data-image-name', files[+key].name);
      image.setAttribute('data-image-id', key);
      image.classList.add(`image${key}`);
      sliderInner.appendChild(image);
    }

    onValueChange && onValueChange({ ...widgetElements });
  };

  const onRemoveFile = (file: File, index: number): void => {
    slideshowImages.splice(index, 1);
    const imagesElement = widgetElements.content?.querySelectorAll('.slidecontainer .slidecontainerinner img');
    imagesElement?.item(index).remove();
    onValueChange && onValueChange({ ...widgetElements });
  };

  return (
    <>
      {type === WIDGET_TYPE.HTML && (
        <Grid item md={12} xs={12}>
          <ViewInput
            name="title"
            onChange={onInputValueChange}
            title="Widget name"
            fieldType="text"
            isEdit={true}
            value={widgetElements?.title?.textContent || ''}
          />
          <ViewInput
            className={styles.widgetInputTextarea}
            name={'content'}
            onChange={onInputValueChange}
            title={'HTML code'}
            fieldType="textarea"
            isEdit={true}
            value={widgetElements?.content?.textContent || ''}
          />
        </Grid>
      )}
      {type === WIDGET_TYPE.VIDEO && (
        <Grid item md={12} xs={12}>
          <ViewInput
            name="title"
            onChange={onInputValueChange}
            title="Widget name"
            fieldType="text"
            isEdit={true}
            value={widgetElements?.title?.innerHTML || ''}
          />
          <ViewInput
            className={styles.widgetInputTextarea}
            name={'content'}
            onChange={onInputValueChange}
            title={'Video embed code'}
            fieldType="textarea"
            isEdit={true}
            value={widgetElements?.content?.textContent || ''}
          />
        </Grid>
      )}
      {type === WIDGET_TYPE.FILELIST && (
        <>
          <Grid item md={10} xs={10}>
            <ViewInput
              name="title"
              onChange={onInputValueChange}
              title="Widget name"
              fieldType="text"
              isEdit={true}
              value={widgetElements?.title?.innerHTML || ''}
            />
          </Grid>
          {widgetElements.linksList.map((item, index) => (
            <div key={`${index.toString()}`}>
              <Grid item md={10} xs={10}>
                <ViewInput
                  name="title"
                  onChange={(_, value): void => onLinkValueChange(value, index)}
                  title={`Link ${index + 1} title`}
                  fieldType="text"
                  isEdit={true}
                  value={item.textContent || ''}
                />
              </Grid>
              <Grid item md={2} xs={2}>
                <button onClick={(): void => onRemoveRow(item, index)} className={styles.widgetButton}>
                  <IconDelete width={'20'} height={'20'} />
                </button>
              </Grid>
              <Grid item md={10} xs={10}>
                <ViewInput
                  name="title"
                  onChange={(_, value): void => onLinkValueChange(value, index, 'href')}
                  title={`Link ${index + 1} URL`}
                  fieldType="text"
                  isEdit={true}
                  value={getListItemUrl(item)}
                />
              </Grid>
            </div>
          ))}
          <Grid item md={12} xs={12}>
            <button
              onClick={(): void => onAddListItem && onAddListItem()}
              className={`${styles.widgetButton} ${styles.primary} mba-no-margin`}
            >
              + Add link
            </button>
          </Grid>
        </>
      )}
      {type === WIDGET_TYPE.SLIDESHOW && (
        <Grid item md={12} xs={12}>
          <ViewInput
            name="title"
            onChange={onInputValueChange}
            title="Widget name"
            fieldType="text"
            isEdit={true}
            value={widgetElements?.title?.innerHTML || ''}
          />
          <FileUploadInput
            defaultPreviewImages={slideshowImages}
            handleChange={handleChange}
            onRemoveFile={onRemoveFile}
            enablePreview={true}
            multiple={true}
          />
        </Grid>
      )}
    </>
  );
};

import React, { PropsWithChildren } from 'react';
import Grid, { DEFAULT_GRID_COLSPAN_SIZE } from '../../../../../components/Grid';
import { initializeFields } from '../helpers';
import { SchoolLeadsDetailsDTO, SchoolLeadsSingleViewDTO } from '../SchoolLeadsDetails.constants';
import styles from './SchoolLeadsSingleView.module.scss';

export interface SchoolLeadsSingleView {
  data: SchoolLeadsDetailsDTO;
  formConfig: SchoolLeadsSingleViewDTO;
}

function SchoolLeadsSingleView({ data, formConfig }: PropsWithChildren<SchoolLeadsSingleView>): JSX.Element {
  const groupColumns = Object.entries(initializeFields(formConfig, data)).map(([key, configValue]) => {
    const value = configValue as string;
    return (
      <Grid item xs={DEFAULT_GRID_COLSPAN_SIZE} sm={6} key={key} className={styles.inner}>
        <div className="o-forms-field">
          <div className="o-forms-title">
            <span className="o-forms-title__main">{key}&nbsp;</span>
          </div>
          <div>{value}</div>
        </div>
      </Grid>
    );
  });

  return (
    <>
      <Grid container compact className="mba-column-group">
        <Grid item xs={DEFAULT_GRID_COLSPAN_SIZE} sm={8} className={styles.wrapper}>
          {groupColumns}
        </Grid>
      </Grid>
    </>
  );
}

export default SchoolLeadsSingleView;

import { RootState } from '../../../../../store/reducers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const rankingsSelector = (state: RootState) => state.rankings;

export const selectCurrentRanking = createDeepEqualSelector(rankingsSelector, (rankings) => {
  return rankings.details;
});

import React, { FC, useEffect, useState } from 'react';
import Grid from '../../../../../../components/Grid';
import TimelineStepDates from './TimelineStepDates';
import TimelineIndicator, { TimelineStepStatus } from './TimelineIndicator';
import Button from '../../../../../../components/Button';
import FileUpload from '../../../../../../components/FileUpload';
import {
  RankingTimelineStepDTO,
  UPLOAD_STEP_ATTACHMENT,
  uploadAttachmentAction,
} from '../../RankingDetails/TimelineSteps';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectRequestErrorMessage,
  selectRequestFormErrors,
  selectRequestIsLoading,
  setRequestSucceededAction,
} from '../../../../../../shared/state/global-request';
import { MODAL_ANIMATION_DURATION } from '../../../../../../components/Modal';
import { UPLOAD_STEP_ATTACHMENT_SUCCESS } from '../../RankingDetails/TimelineSteps/components/TimelineSteps.constants';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment-timezone';
import ErrorMessage from '../../../../../../components/ErrorMessage/ErrorMessage';

const AlumniSurveyDataStep: FC<RankingTimelineStepDTO & { withDateMarker?: boolean }> = ({
  name,
  progress,
  rankingId,
  id,
  withDateMarker,
  startDate,
  updatedAt,
  endDate,
}) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [expand, setExpand] = useState<boolean>(false);

  const errors = useSelector(selectRequestFormErrors(UPLOAD_STEP_ATTACHMENT));
  const errorMessage = useSelector(selectRequestErrorMessage(UPLOAD_STEP_ATTACHMENT));
  const isLoading = useSelector(selectRequestIsLoading(UPLOAD_STEP_ATTACHMENT));

  const closeUploadModal = (): void => {
    setIsUploadModalOpen(false);

    setTimeout(() => {
      dispatch(setRequestSucceededAction(UPLOAD_STEP_ATTACHMENT));
    }, MODAL_ANIMATION_DURATION);
  };

  const handleSpreadsheetUpload = (file: File) => {
    dispatch(setRequestSucceededAction(UPLOAD_STEP_ATTACHMENT));

    const formData = new FormData();
    formData.append('file', file);

    dispatch(uploadAttachmentAction({ rankingId, stepId: id, spreadsheet: formData }));
  };

  useEffect(() => {
    if (!isLoading && !errors?.spreadsheet?.length && isUploadModalOpen && !errorMessage) {
      setIsUploadModalOpen(false);
      addToast(UPLOAD_STEP_ATTACHMENT_SUCCESS, { appearance: 'success' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, errorMessage]);

  return (
    <>
      <Grid container key={name}>
        <Grid item xs={5}>
          {withDateMarker ? (
            <TimelineStepDates
              endDate={endDate}
              endDateLabel="End"
              isEndDateRequired={true}
              startDate={startDate}
              startDateLabel="Start"
              isStartDateRequired={true}
            />
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={1} className="mba-no-padding">
          <TimelineIndicator timeIndication={progress && TimelineStepStatus.completed} />
        </Grid>
        <Grid item xs={6} className="mba-no-padding">
          <button
            className="mba-text--accent mba-no-margin mba-text--semi-bold mba-timeline-button--link"
            onClick={(): void => setExpand(!expand)}
          >
            {name}
          </button>
          {expand && (
            <>
              <br />
              <br />
              <div className="mba-actions">
                <Button
                  primary={!progress || progress.current === 0}
                  text={`Upload ${progress && progress.current > 0 ? 'new ' : ''}spreadsheet`}
                  onClick={(): void => setIsUploadModalOpen(true)}
                />
              </div>
              <span className="mba-font--16" style={{ color: '#afafaf' }}>
                {progress && progress.current > 0
                  ? `Last updated at ${moment(updatedAt).tz('Etc/GMT').format('Do MMM YYYY HH:mm')} GMT`
                  : `There were no updates so far`}
              </span>
              <br />
              <br />
            </>
          )}
        </Grid>
      </Grid>
      <FileUpload
        title={`Upload ${name.toLowerCase()}`}
        isLoading={isLoading}
        isOpen={isUploadModalOpen}
        onClose={closeUploadModal}
        onUpload={handleSpreadsheetUpload}
        errorMessage={<ErrorMessage errorMessage={errorMessage} errors={errors} />}
      />
    </>
  );
};

export default AlumniSurveyDataStep;

import { TableListData } from '../../../shared/constants';
import { getTableListDefaultState } from '../../../shared/helpers';
import { SchoolWidgetGroupDto } from './school-widget-groups.dto';
import {
  ADD_SCHOOL_WIDGET_GROUP_TO_LIST,
  DELETE_SCHOOL_WIDGET_GROUP,
  LOAD_SCHOOL_WIDGET_GROUPS,
  SchoolWidgetGroupsActionTypes,
  SET_SCHOOL_WIDGET_GROUPS,
} from './school-widget-groups.types';

const initialState: TableListData<SchoolWidgetGroupDto> = getTableListDefaultState<SchoolWidgetGroupDto>();

const schoolWidgetGroupsReducer = (
  state = initialState,
  action: SchoolWidgetGroupsActionTypes,
): TableListData<SchoolWidgetGroupDto> => {
  switch (action.type) {
    case ADD_SCHOOL_WIDGET_GROUP_TO_LIST: {
      return {
        ...state,
        data: [...state.data, action.payload.newWidgetGroup],
      };
    }
    case LOAD_SCHOOL_WIDGET_GROUPS: {
      return {
        ...state,
        filter: action.payload.pageConfig?.filter || {},
        search: action.payload.pageConfig?.search || '',
      };
    }
    case SET_SCHOOL_WIDGET_GROUPS: {
      return {
        ...state,
        ...action.payload.tableData,
      };
    }
    case DELETE_SCHOOL_WIDGET_GROUP: {
      // TODO: implement logic
      return state;
    }
    default: {
      return state;
    }
  }
};

export default schoolWidgetGroupsReducer;

import React, { FunctionComponent, useState, ReactElement } from 'react';
import { Link } from 'react-router-dom';

import styles from './LayoutNavigation.module.scss';

export type LayoutNavigationProps = {
  items: {
    label: string;
    route: string;
    subitems?: {
      label: string;
      route: string;
    }[];
  }[];
  title?: {
    label: string;
    route: string;
  };
  selectedItem: string;
};

const LayoutNavigation: FunctionComponent<LayoutNavigationProps> = ({ items, title, selectedItem }): ReactElement => {
  const getAriaCurrent = (route: number | string): 'location' | 'false' => {
    return route === selectedItem ? 'location' : 'false';
  };

  return (
    <nav className={`o-layout__navigation ${styles.navStyle}`}>
      <ol className="o-layout__unstyled-element">
        {title !== undefined && (
          <li className="o-layout__unstyled-element o-layout__navigation-title">
            <Link className={styles.borderBottomNone} to={title.route} aria-current={getAriaCurrent('title')}>
              {title.label}
            </Link>
          </li>
        )}
        {items.map((item, index) => (
          <li key={`${item.label} ${index}`} className="o-layout__unstyled-elements">
            <Link
              className={styles.borderBottomNone}
              key={index}
              to={item.route}
              aria-current={getAriaCurrent(item.route)}
            >
              {item.label}
            </Link>
            {item.subitems && (
              <ol className="o-layout__unstyled-element">
                {item.subitems.map((subitem, subindex) => (
                  <li key={subindex}>
                    <Link
                      className={styles.borderBottomNone}
                      key={subindex}
                      to={subitem.route}
                      aria-current={getAriaCurrent(subitem.route)}
                    >
                      {subitem.label}
                    </Link>
                  </li>
                ))}
              </ol>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default LayoutNavigation;

import React, { ReactNode } from 'react';

import { CheckboxInputOption } from '../Checkbox';
import { FormControl, FormControlElementProps, FormValue } from '../Form';
import {
  renderCheckboxField,
  renderDatePickerInputField,
  renderFormGroupTitle,
  renderRadioButtons,
  renderRichTextField,
  renderSelectField,
  renderTextAreaField,
  renderTextInputField,
  renderStaticLinkField,
  renderPhoneEmailInputField,
} from '../Form/FormFields';
import InputWrapper from '../InputWrapper';
import { getHtmlFromJSON } from '../RichTextEditor/RichTextEditor.helpers';
import { SelectOption } from '../Select';
import { formatDateString } from '../../shared/helpers';

export interface ViewInputProps extends FormControl {
  isEdit: boolean;
  onChange: (name: string, value: FormValue) => void;
}

const ViewInput: React.FunctionComponent<ViewInputProps> = ({
  id,
  fieldType,
  isEdit,
  onChange,
  options,
  previewFormat,
  title,
  value,
  ...control
}) => {
  const currentControl = {
    ...control,
    fieldType,
    label: title,
    options,
    value,
  } as FormControlElementProps;

  const getSelectLabel = (value?: FormValue): ReactNode => {
    let label = typeof value === 'boolean' ? value.toString() : value || '-';
    if (typeof value === 'boolean') {
      return value === true ? 'Yes' : 'No';
    }

    if (options && options.length > 0) {
      const selectedOption = options.find((option) => option.value === value);
      if (selectedOption) {
        label = (selectedOption as SelectOption).label || (selectedOption as CheckboxInputOption).text || '-';
        return label;
      }
    }

    if (previewFormat && typeof value === 'string') {
      label = formatDateString(value, previewFormat) || '-';
    }

    try {
      let __html = '-';
      if (fieldType === 'email' || fieldType === 'phone') {
        const values = currentControl.value ? currentControl.value.toString().split(',') : [];
        if (values.length > 0) {
          __html = '';
          values.forEach((item) => (__html += `<span>${item}</span><br>`));
        }
        return <div dangerouslySetInnerHTML={{ __html }} />;
      }

      if (fieldType === 'richText' || fieldType === 'staticLink') {
        __html = value ? getHtmlFromJSON(value as string) : '-';
        return <div dangerouslySetInnerHTML={{ __html }} />;
      }
    } catch (e: any) {}

    return label.toString();
  };

  let field: ReactNode;

  switch (fieldType) {
    case 'select':
      field = renderSelectField({ control: currentControl, inputChangedHandler: onChange });
      break;
    case 'text':
      field = renderTextInputField({ control: currentControl, inputChangedHandler: onChange });
      break;
    case 'textarea':
      field = renderTextAreaField({ control: currentControl, inputChangedHandler: onChange });
      break;
    case 'formGroupTitle':
      field = renderFormGroupTitle({ control: currentControl });
      break;
    case 'staticLink':
      field = renderStaticLinkField({ control: currentControl });
      break;
    case 'radio':
      field = renderRadioButtons({ control: currentControl, inputChangedHandler: onChange });
      break;
    case 'checkbox':
      field = renderCheckboxField({ control: currentControl, inputChangedHandler: onChange });
      break;
    case 'date':
      field = renderDatePickerInputField({ control: currentControl, inputChangedHandler: onChange });
      break;
    case 'email':
      field = renderPhoneEmailInputField({ control: currentControl, inputChangedHandler: onChange });
      break;
    case 'phone':
      field = renderPhoneEmailInputField({ control: currentControl, inputChangedHandler: onChange });
      break;
    case 'richText':
      field = renderRichTextField({ control: currentControl, inputChangedHandler: onChange });
      break;
    default:
      field = null;
  }

  return isEdit ? <div>{field}</div> : <InputWrapper title={title}>{getSelectLabel(value)}</InputWrapper>;
};

export default ViewInput;

import React, { FunctionComponent, ReactNode } from 'react';

import Button, { ButtonSizes } from '../../../../../../../components/Button';
import Typography from '../../../../../../../components/Typography';

type UpdateParticipationStatusConfirmationProps = {
  isLoading: boolean;
  message: ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
};

const UpdateParticipationStatusConfirmation: FunctionComponent<UpdateParticipationStatusConfirmationProps> = ({
  isLoading,
  message,
  onCancel,
  onConfirm,
}) => (
  <>
    <Typography>{message}</Typography>
    <div className="mba-actions">
      <Button onClick={onCancel} text="Cancel" size={ButtonSizes.big} />
      <Button onClick={onConfirm} primary text="Confirm" isLoading={isLoading} size={ButtonSizes.big} />
    </div>
  </>
);

export default UpdateParticipationStatusConfirmation;

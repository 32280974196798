import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pager, { INITIAL_PAGE } from '../../../../components/Pager';
import Table from '../../../../components/Table';
import { ASCENDING_ORDER_DIRECTION, DESCENDING_ORDER_DIRECTION } from '../../../../shared/constants';
import { loadMessageHistoryAction } from '../onsite-messaging.actions';
import { selectMessageHistory, selectMessageHistoryPageConfiguration } from '../onsite-messaging.selectors';
import { ONSITE_MESSAGING_HISTORY_TABLE_COLUMN_DEFINITION } from './History.constants';

const History: FunctionComponent = () => {
  const dispatch = useDispatch();
  const pageConfig = useSelector(selectMessageHistoryPageConfiguration);
  const messageHistoryData = useSelector(selectMessageHistory);

  useEffect(() => {
    dispatch(loadMessageHistoryAction());
  }, [dispatch]);

  const handlePageChanged = (page: number): void => {
    dispatch(loadMessageHistoryAction({ ...pageConfig, page }));
  };

  const handleSortByChanged = (fieldName: string): void => {
    const orderDirection =
      pageConfig.sortBy !== fieldName
        ? DESCENDING_ORDER_DIRECTION
        : pageConfig.order === ASCENDING_ORDER_DIRECTION
        ? DESCENDING_ORDER_DIRECTION
        : ASCENDING_ORDER_DIRECTION;
    dispatch(loadMessageHistoryAction({ ...pageConfig, sortBy: fieldName, order: orderDirection }));
  };

  return (
    <>
      <div className="mba-table-wrapper mba-mt-20">
        {/* <div className="mba-table-filters">
          <HistorySearchAndFilters />
        </div> */}
        <div className="mba-table-container">
          <Table
            id="onsiteMessagingHistory"
            columns={ONSITE_MESSAGING_HISTORY_TABLE_COLUMN_DEFINITION}
            data={messageHistoryData}
            sortingOptions={{
              order: pageConfig.order || DESCENDING_ORDER_DIRECTION,
              sortBy: pageConfig.sortBy || '',
            }}
            onSortByColumn={handleSortByChanged}
          />
        </div>
        {pageConfig && pageConfig.totalPages !== INITIAL_PAGE && (
          <Pager
            currentPage={pageConfig.page}
            totalPages={pageConfig.totalPages || INITIAL_PAGE}
            onPageChange={handlePageChanged}
          />
        )}
      </div>
    </>
  );
};

export default History;

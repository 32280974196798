import { RootState } from '../../../../../../store/reducers';
import { RankingTimelineStepDTO } from './ranking-timeline-steps.dto';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const rankingsSelector = (state: RootState) => state.rankings;

export const timelineStepsSelector = createDeepEqualSelector(rankingsSelector, (rankings) => {
  return rankings.timelineSteps;
});

export const selectCurrentTimelineSteps = createDeepEqualSelector(timelineStepsSelector, (timelineSteps) => {
  return timelineSteps.steps;
});

export const selectCurrentTimelineStep = createDeepEqualSelector(timelineStepsSelector, (timelineSteps) => {
  return timelineSteps.currentStep;
});

import { AccreditationDTO, CreateAccreditationDTO, EditAccreditationDTO } from './accreditation-profile.dto';

export const CREATE_ACCREDITATION = 'CREATE_ACCREDITATION';
export const LOAD_ACCREDITATION = 'LOAD_ACCREDITATION';
export const EDIT_ACCREDITATION = 'EDIT_ACCREDITATION';
export const DELETE_ACCREDITATION = 'DELETE_ACCREDITATION';
export const CLEAR_CURRENT_ACCREDITATION = 'CLEAR_CURRENT_ACCREDITATION';
export const SET_ACCREDITATION = 'SET_ACCREDITATION';

export type AccreditationProfileState = CreateAccreditationDTO | EditAccreditationDTO | AccreditationDTO;

export interface AccreditationProfileRouteParams {
  id: string;
}

export interface CreateAccreditationAction {
  type: typeof CREATE_ACCREDITATION;
  payload: { data: CreateAccreditationDTO };
}

export interface LoadAccreditationAction {
  type: typeof LOAD_ACCREDITATION;
  payload: { id: number };
}

export interface EditAccreditationAction {
  type: typeof EDIT_ACCREDITATION;
  payload: { data: EditAccreditationDTO };
}

export interface DeleteAccreditationAction {
  type: typeof DELETE_ACCREDITATION;
  payload: { id: number };
}

export interface ClearCurrentAccreditationAction {
  type: typeof CLEAR_CURRENT_ACCREDITATION;
}

export interface SetAccreditationAction {
  type: typeof SET_ACCREDITATION;
  payload: { data: AccreditationDTO };
}

export type AccreditationActionTypes =
  | ClearCurrentAccreditationAction
  | CreateAccreditationAction
  | EditAccreditationAction
  | LoadAccreditationAction
  | SetAccreditationAction;

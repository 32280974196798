import { CampusDTO } from '../CampusProfile';
import { CampusesActionTypes, LOAD_CAMPUSES, SET_CAMPUSES } from './campuses.types';
import { getTableListDefaultState } from '../../../../shared/helpers';
import { TableListData } from '../../../../shared/constants';

const initialState: TableListData<CampusDTO> = getTableListDefaultState<CampusDTO>();

const campusReducer = (state = initialState, action: CampusesActionTypes): TableListData<CampusDTO> => {
  switch (action.type) {
    case LOAD_CAMPUSES: {
      return {
        ...state,
        filter: action.payload.pageConfig?.filter || {},
        search: action.payload.pageConfig?.search || '',
      };
    }
    case SET_CAMPUSES: {
      return {
        ...state,
        ...action.payload.tableData,
      };
    }
    default: {
      return state;
    }
  }
};

export default campusReducer;

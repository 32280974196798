import {
  AccreditationActionTypes,
  AccreditationProfileState,
  CLEAR_CURRENT_ACCREDITATION,
  CREATE_ACCREDITATION,
  EDIT_ACCREDITATION,
  SET_ACCREDITATION,
} from './accreditation-profile.types';

const initialState: AccreditationProfileState = {} as AccreditationProfileState;

const accreditationProfileReducer = (
  state = initialState,
  action: AccreditationActionTypes,
): AccreditationProfileState => {
  switch (action.type) {
    case CLEAR_CURRENT_ACCREDITATION: {
      return initialState;
    }
    case CREATE_ACCREDITATION:
    case EDIT_ACCREDITATION:
    case SET_ACCREDITATION: {
      return {
        ...action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default accreditationProfileReducer;
